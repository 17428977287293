import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EwlRoutingModule } from './ewl-routing.module';
import { SharedModule } from '../shared/shared.module';
import { Ewlsel100Component } from './ewlsel/ewlsel100/ewlsel100.component';

@NgModule({
  declarations: [Ewlsel100Component],
  imports: [
    CommonModule,
    EwlRoutingModule,
    SharedModule
  ]
})
export class EwlModule { }
