import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Dshfna100Component } from './dshfna/dshfna100/dshfna100.component';
import { Dshfna200Component } from './dshfna/dshfna200/dshfna200.component';
import { Dshlgt100Component } from './dshlgt/dshlgt100/dshlgt100.component';
import { Dshlgt110Component } from './dshlgt/dshlgt110/dshlgt110.component';
import { Dshova100Component } from './dshova/dshova100/dshova100.component';
import { Dshovafs100Component } from './dshovafs/dshovafs100/dshovafs100.component';
import { Dshsal100Component } from './dshsal/dshsal100/dshsal100.component';
import { Dshsalfs100Component } from './dshsalfs/dshsalfs100/dshsalfs100.component';
import { Dshstk100Component } from './dshstk/dshstk100/dshstk100.component';
import { Dshstkfs100Component } from './dshstkfs/dshstkfs100/dshstkfs100.component';


const routes: Routes = [
  { path: 'dshova/dshova100', component: Dshova100Component },
  { path: 'dshsal/dshsal100', component: Dshsal100Component },
  { path: 'dshstk/dshstk100', component: Dshstk100Component },
  { path: 'dshlgt/dshlgt100', component: Dshlgt100Component },
  { path: 'dshlgt/dshlgt110/:country/:deliveryNoteNo/:seller', component: Dshlgt110Component },
  { path: 'dshfna/dshfna100', component: Dshfna100Component },
  { path: 'dshfna/dshfna200', component: Dshfna200Component },
  { path: 'dshovafs/dshovafs100', component: Dshovafs100Component },
  { path: 'dshsalfs/dshsalfs100', component: Dshsalfs100Component },
  { path: 'dshstkfs/dshstkfs100', component: Dshstkfs100Component },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DshRoutingModule { }
