import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http/';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { Parameter } from '../model/paramete.rmodel';
import { VParameter } from '../model/vparameter';
import { DropDownListModel } from '../model/dropdownlist.model';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  PRM_URL: string = `${this.evmService.getApiUrl()}/prm` as string;

  constructor(private http: HttpClient,
              private evmService: EnvironmentService,
              private autService: AuthenticationService) { }

  getvparameterDetailToLable(prmNo): Observable<Array<DropDownListModel>> {
    const URL = `${this.PRM_URL}/getvprmdtltolable/${prmNo}`;
    return this.http.get<Array<DropDownListModel>>(URL, {headers: this.autService.tokenHeader()});
  }

  getDiscoutPrice(tableNo,country,prmCode):Observable<VParameter>{
    const URL = `${this.PRM_URL}/getDiscount/${tableNo}/${country}/${prmCode}`;
    return this.http.get<VParameter>(URL, {headers: this.autService.tokenHeader()});
  }

  getParameterCheckbox(tableNo){
    const URL = `${this.PRM_URL}/getvprmdtltolableCheckBox/${tableNo}`;
    return this.http.get(URL, {headers: this.autService.tokenHeader()});
  }

  getvprmdtltolableByCountry(tableNo,country){
    const URL = `${this.PRM_URL}/getvprmdtltolableByCountry/${tableNo}/${country}`;
    return this.http.get(URL, {headers: this.autService.tokenHeader()});
    
  }

  getvprmdtltolableByCountryV2(tableNo,country){
    const URL = `${this.PRM_URL}/getvprmdtltolableByCountryV2/${tableNo}/${country}`;
    return this.http.get(URL, {headers: this.autService.tokenHeader()});
  }

  getDataParameterDetail(tableNo,prdentcd){
    const URL = `${this.PRM_URL}/getDataParameterDetail/${tableNo}/${prdentcd}`;
    return this.http.get(URL, {headers: this.autService.tokenHeader()});
  }

  getDataMapflagBynumv1(tableNo,numv1){
    const URL = `${this.PRM_URL}/getDataMapflagBynumv1/${tableNo}/${numv1}`;
    return this.http.get(URL, {headers: this.autService.tokenHeader()});
  }

  getParameterDetailToLableValueCode(tableNo){
    const URL = `${this.PRM_URL}/getParameterDetailToLableValueCode/${tableNo}`;
    return this.http.get(URL, {headers: this.autService.tokenHeader()});
  } 

  getvprmdtltolableByCountryTxtv1(tableNo,country,txtv1){
    const URL = `${this.PRM_URL}/getvprmdtltolableByCountryAndTxtv1/${tableNo}/${country}/${txtv1}`;
    return this.http.get(URL, {headers: this.autService.tokenHeader()});
  }

  getvprmdtltolableByPmhtbhtbnoAndTxtv2(tableNo,txtv2){
    const URL = `${this.PRM_URL}/getvprmdtltolableByPmhtbhtbnoAndTxtv2/${tableNo}/${txtv2}`;
    return this.http.get(URL, {headers: this.autService.tokenHeader()});
  }
}
