import { Component, OnInit } from '@angular/core';
import { footerCrossBorder, footerCustomerHelpCenter, footerJoinUs, footerMarketingEditingAndTranslate, footerOtherService, footerSalesPolicy, footerSaleTeamAndCRM, footerSellerHelpCenter, footerServicePolicy, footerStockAndOrder, footerThailangFb, footerThailangLine, hyperLink, Sale_Channel_Cambodia_Klangthai_Android, Sale_Channel_Cambodia_Klangthai_Facebook, Sale_Channel_Cambodia_Klangthai_IG, Sale_Channel_Cambodia_Klangthai_IOS, Sale_Channel_Cambodia_Klangthai_web, Sale_Channel_Laos_Klangthai_Facebook } from '../../constant/footer.constant';
import { ParameterService } from '../../services/parameter.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  hyperLink_contactSeller : string;
  hyperLink_contactCustomer : string;
  hyperLink_joinWithUs : string;
  hyperLink_crossBorder : string;
  hyperLink_stockAndOrder : string;
  hyperLink_saleTeam : string;
  hyperLink_marketing : string;
  hyperLink_other: string;
  hyperLink_Cambodia_web : string;
  hyperLink_google_play : string;
  hyperLink_app_store : string;
  hyperLink_CambodiaFb : string;
  hyperLink_CambodiaIg : string;
  hyperLink_LoasFb : string;
  hyperLink_ThailangFb: string;
  hyperLink_ThailangLine: string;
  hyperLink_ServicePolicy: string;
  hyperLink_SalesPolicy: string;

  constructor(private parameterService: ParameterService) { }

  ngOnInit() {
   this.parameterService.getDataParameterDetail(hyperLink, footerSellerHelpCenter).subscribe((response: any) => {
     this.hyperLink_contactSeller = response.pmdtbdtxtv1;
   }, err => {
console.log(err);
   });

   this.parameterService.getDataParameterDetail(hyperLink, footerCustomerHelpCenter).subscribe((response: any) => {
    this.hyperLink_contactCustomer = response.pmdtbdtxtv1;
  }, err => {
console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, footerJoinUs).subscribe((response: any) => {
    this.hyperLink_joinWithUs = response.pmdtbdtxtv1;
  }, err => {
console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, footerCrossBorder).subscribe((response: any) => {
    this.hyperLink_crossBorder = response.pmdtbdtxtv1;
  }, err => {
console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, footerStockAndOrder).subscribe((response: any) => {
    this.hyperLink_stockAndOrder = response.pmdtbdtxtv1;
  }, err => {
console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, footerSaleTeamAndCRM).subscribe((response: any) => {
    this.hyperLink_saleTeam = response.pmdtbdtxtv1;
  }, err => {
console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, footerMarketingEditingAndTranslate).subscribe((response: any) => {
    this.hyperLink_marketing = response.pmdtbdtxtv1;
  }, err => {
console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, footerOtherService).subscribe((response: any) => {
    this.hyperLink_other = response.pmdtbdtxtv1;
  }, err => {
console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, Sale_Channel_Cambodia_Klangthai_web).subscribe((response: any) => {
    this.hyperLink_Cambodia_web = response.pmdtbdtxtv1;
  }, err => {
    console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, Sale_Channel_Cambodia_Klangthai_Android).subscribe((response: any) => {
    this.hyperLink_google_play = response.pmdtbdtxtv1;
  }, err => {
    console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, Sale_Channel_Cambodia_Klangthai_IOS).subscribe((response: any) => {
    this.hyperLink_app_store = response.pmdtbdtxtv1;
  }, err => {
    console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, Sale_Channel_Cambodia_Klangthai_Facebook).subscribe((response: any) => {
    this.hyperLink_CambodiaFb = response.pmdtbdtxtv1;
  }, err => {
    console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, Sale_Channel_Cambodia_Klangthai_IG).subscribe((response: any) => {
    this.hyperLink_CambodiaIg = response.pmdtbdtxtv1;
  }, err => {
    console.log(err);
  });

  this.parameterService.getDataParameterDetail(hyperLink, Sale_Channel_Laos_Klangthai_Facebook).subscribe((response: any) => {
    this.hyperLink_LoasFb = response.pmdtbdtxtv1;
  }, err => {
    console.log(err);
  });
  this.parameterService.getDataParameterDetail(hyperLink,footerThailangFb).subscribe((response: any) => {
    this.hyperLink_ThailangFb = response.pmdtbdtxtv1;
  }, err =>{
console.log(err);
  });
  this.parameterService.getDataParameterDetail(hyperLink,footerThailangLine).subscribe((response: any) => {
    this.hyperLink_ThailangLine = response.pmdtbdtxtv1;
  }, err =>{
console.log(err);
  });
  this.parameterService.getDataParameterDetail(hyperLink,footerServicePolicy).subscribe((response: any) => {
    this.hyperLink_ServicePolicy = response.pmdtbdtxtv1;
  }, err =>{
console.log(err);
  });
  this.parameterService.getDataParameterDetail(hyperLink,footerSalesPolicy).subscribe((response: any) => {
    this.hyperLink_SalesPolicy = response.pmdtbdtxtv1;
  }, err =>{
console.log(err);
  });
  }

}
