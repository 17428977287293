import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { Admatr200Model, AttributeClass, AttributeClassRequest, AttributeValue, AttributeValueRequest, FormModel } from '../../model/admatr/admatr200.model';

@Injectable({
    providedIn: 'root'
})
export class Admatr200Service {

    PRM_URL: string = `${this.evmService.getApiUrl()}/adm/atr` as string;
    admatr200: Admatr200Model = {} as Admatr200Model;

    constructor(private http: HttpClient,
        private evmService: EnvironmentService,
        private autService: AuthenticationService,
        private fb: FormBuilder) { 
        this.init();
    }

    init(): void {
        this.admatr200.attributeValue = [];
        this.admatr200.acvclscd;
        this.admatr200.attributeValueDelete = [];
        this.admatr200.flagButtonDelete = true;
        this.admatr200.display = false;
        this.admatr200.flagButtonSave = true;
        this.admatr200.flagButtonAdd = true;
        this.admatr200.attributeValueRequest = {} as AttributeValueRequest;
        this.admatr200.user;
        this.admatr200.attributeClass = {} as AttributeClass;
        this.admatr200.flagButtonSaveAtrcls = true;
        this.admatr200.attributeClassRequest = {} as AttributeClassRequest;
        this.admatr200.checkflagSaveOrUpdateAtrval = true;
        this.admatr200.checkFlagDelete = true;
        this.admatr200.form_model = {} as FormModel;
        this.admatr200.form_model.gridAttributeValueForm = this.fb.group({
            acvatrcd: [null, [Validators.required, Validators.maxLength(5)]],
            acvdefault: (false),
            acvcddesc: [null, Validators.required],
            acvcdldesc: (null),
            acvaddval: (null)
        });
        this.admatr200.form_model.gridAttributeClassForm = this.fb.group({
            aclclscd: [null, [Validators.required, Validators.maxLength(5)]],
            aclclsedesc: [null, Validators.required],
            aclonecd: (false),
            aclclsldesc: (null),
            aclmandatory: (false),
            aclapptousr: (false),
            acleopdat: (null),
            aclapptoitem: (false),
            aclapptoven: (false),
            aclapptosite: (false),
            aclapptomdh: (false)
        });
    }

    get load(): Admatr200Model {
        return this.admatr200;
    }

    getAttributeValueByAcvclsCode(acvclscd): Observable<AttributeValue>{
        const URL = `${this.PRM_URL}/getAttributeValueByAcvclsCode/${acvclscd}`;
        return this.http.get<AttributeValue>(URL, {headers: this.autService.tokenHeader()});
    }

    daleteAttributeValue(acvclscd, acvatrcd): Observable<AttributeValue>{
        const URL = `${this.PRM_URL}/deleteAttributeValue/${acvclscd}/${acvatrcd}`;
        return this.http.delete<AttributeValue>(URL, {headers: this.autService.tokenHeader()});
    }

    saveAttributeValue(attributeValueRequest): Observable<AttributeValue>{
        const URL = `${this.PRM_URL}/saveAttributeValue`;
        return this.http.post<AttributeValue>(URL, attributeValueRequest, {headers: this.autService.tokenHeader()});
    }

    getAttributeClassByAcvclsCode(acvclscd): Observable<AttributeClass>{
        const URL = `${this.PRM_URL}/getAttributeClassByAcvclsCode/${acvclscd}`;
        return this.http.get<AttributeClass>(URL, {headers: this.autService.tokenHeader()});
    }

    saveAndUpdateAttributeClass(attributeClassRequest): Observable<AttributeClass>{
        const URL = `${this.PRM_URL}/saveAndUpdateAttributeClass`;
        return this.http.post<AttributeClass>(URL, attributeClassRequest, {headers: this.autService.tokenHeader()});
    }
}