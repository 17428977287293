import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  // private httpError = [{errorCode:500, errorType: "E", message: "Internal Server Error"}];
  // private httpError = [{errorCode:401, errorType: "E", message: "Unauthorized" }];
  // private httpError = [{errorCode:403, errorType: "E", message: "Unauthorized" }];
  private httpError = [{errorCode:200, errorType: "S"}];
  // private httpError = [{}]

  constructor() { }

  getHttpRequest() {
    return this.httpError;
  }
}
