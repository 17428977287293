import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { OptionGroup, ParameterHeader } from '../../model/admprm/admprm100.model';
import { Admprm200Model, FormModel, ParameterDetailDelete, ParameterDetailList } from '../../model/admprm/admprm200.model';

@Injectable({
    providedIn: 'root'
})
export class Admprm200Service {

    PRM_URL: string = `${this.evmService.getApiUrl()}/prm` as string;
    admprm200: Admprm200Model = {} as Admprm200Model;

    constructor(private http: HttpClient,
        private evmService: EnvironmentService,
        private autService: AuthenticationService,
        private fb: FormBuilder) { 
        this.init();
    }

    init(): void {
        this.admprm200.pmhtbhtbno;
        this.admprm200.parameterHeader;
        this.admprm200.pmhtbhgroupHeader = [];
        this.admprm200.parameterDetailList = [];
        this.admprm200.parameterDetail = [];
        this.admprm200.parameterDetailDelete = [];
        this.admprm200.optionCountry;
        this.admprm200.parameterDetailFilter = [];
        this.admprm200.parameterDetailSmartSearch = [];
        this.admprm200.checkFlagCountry = true;
        this.admprm200.checkFlagDelete;
        this.admprm200.checkFlagButtonDelete = true;
        this.admprm200.parameterHeaderUpdate;
        this.admprm200.usrlanguage;
        this.admprm200.checkFlagInputPrmhdr = true;
        this.admprm200.checkFlagButtonAdd;
        this.admprm200.smartSearch = '';
        this.admprm200.firstPage = 0;
        this.admprm200.flagEmptyTable = true;
        this.admprm200.form_model = {} as FormModel;
        this.admprm200.form_model.gridParameterHeaderPanel = this.fb.group({
            country: (null)
        });
        this.admprm200.form_model.gridParameterHeaderForm = this.fb.group({
            pmhtbhtbno: (null),
            pmhtbhgroup: (null),
            pmhtbhsys: (null),
            pmhtbhacc: (null),
            pmhtbhedesc: [null, Validators.required],
            pmhtbhlangcode: (null),
            pmhcmnt: (null),
            pmhtbhldesc: (null)
        });
    }

    get load(): Admprm200Model {
        return this.admprm200;
    }
    
    getParameterHeaderByPmhtbhtbno(pmhtbhtbno): Observable<ParameterHeader> {
        const URL = `${this.PRM_URL}/getvprmtblhdrByPmhtbhtbno/${pmhtbhtbno}`;
        return this.http.get<ParameterHeader>(URL, {headers: this.autService.tokenHeader()});
    }

    getvparameterDetailByPmhtbhtbno(pmhtbhtbno): Observable<ParameterDetailList> {
        const URL = `${this.PRM_URL}/getvprmtbldtlByPmhtbhtbno/${pmhtbhtbno}`;;
        return this.http.get<ParameterDetailList>(URL, {headers: this.autService.tokenHeader()});
    }

    deleteparameterDetail(pmdtbdtbno, pmdtbdtbdentcd, pmdtbddms): Observable<ParameterDetailDelete>{
        const URL = `${this.PRM_URL}/deletevprmtbldtl/${pmdtbdtbno}/${pmdtbdtbdentcd}/${pmdtbddms}`;
        return this.http.delete<ParameterDetailDelete>(URL, {headers: this.autService.tokenHeader()});
    }

    updateparameterHeader(parameterHeaderRequest): Observable<ParameterHeader>{
        const URL = `${this.PRM_URL}/updatevprmtblhdr`;
        return this.http.post<ParameterHeader>(URL, parameterHeaderRequest, {headers: this.autService.tokenHeader()});
    }

    getParameterHeaderGroup(): Observable<OptionGroup> {
        const URL = `${this.PRM_URL}/getvprmtblhdrGroup`;
        return this.http.get<OptionGroup>(URL, {headers: this.autService.tokenHeader()});
    }
}
