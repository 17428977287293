import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Apierrhis100Component } from './apierrhis/apierrhis100/apierrhis100.component';
import { Ord100Component } from './ordsor/ordsor100/ord100component';
import { Ord200Component } from './ordsor/ordsor200/ord200.component';
import { Ord300Component } from './ordsor/ordsor300/ord300.component';

const routes: Routes = [
    { path: 'ordsor/ordsor100', component: Ord100Component },
    { path: 'ordsor/ordsor200', component: Ord200Component },
    { path: 'ordsor/ordsor200/:saleOrderNo/:warehouseCode', component: Ord200Component },
    { path: 'ordsor/ordsor300/:uuid', component: Ord300Component },
    { path: 'apierrhis/apierrhis100', component: Apierrhis100Component }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })

  export class OrdRoutingModule { }