import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';


@Component({
  selector: 'app-ss-dropdown',
  templateUrl: './ss-dropdown.component.html',
  styleUrls: ['./ss-dropdown.component.scss']
})
export class SsDropdownComponent implements OnInit {
  input: InputTextModule;
  selectedValue: any;
  item: string;
  disabled: boolean = true;
  description: any;
  errorMsg: any = false;;
  errorBorder: any = true;
  @Input() flag: any;
  @Input() dataList: any;
  @Output() returnEvent = new EventEmitter<string>();

  constructor() {

  }

  filter(even) {
    if (even.value == this.dataList.value) {
      // console.log('wow');
    }
  }

  returnData(event) {
    if (!this.selectedValue) {
      this.returnEvent.emit(event.value);
      this.description = "";
      if (this.flag == 'y') {
        this.errorMsg = true;
        this.errorBorder = true;
        // console.log('flag', this.flag);
      } else {
        this.errorMsg = false;
        this.errorBorder = true;
        // console.log('flag', this.flag);
      }
      // console.log('selectedValue', this.selectedValue);
    } else {
      this.returnEvent.emit(event.value);
      this.description = event.value.name;
      this.errorBorder = false;
      if (this.flag == 'y') {
        this.errorMsg = false;
        this.errorBorder = true;
        // console.log('flag', this.flag);
      } else {
        this.errorBorder = true;
        // console.log('flag', this.flag);
      }
      // console.log('flag',this.flag);
      // console.log('description', this.description);
    }
  }
  ngOnInit() {

  }




}
