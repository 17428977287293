import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Prisal100Component } from './prisal100/prisal100.component';
import { Prisal200Component } from './prisal200/prisal200.component';

const routes: Routes = [
    { path: 'prisal/prisal100', component: Prisal100Component },
    { path: 'prisal/prisal200/:salePriceNo', component: Prisal200Component },
    { path: 'prisal/prisal200', component: Prisal200Component }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })

  export class PsaRoutingModule { }
