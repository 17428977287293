import { formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { parameter_entrycode_ihitem, parameter_entrycode_item, parameter_upload, parameter_upload_template } from 'src/app/shared/constant/parameter.constant';
import { VParameter } from 'src/app/shared/model/vparameter';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { ResponseUpload, Upload300Model } from './model/upload300.model';
import { Upload300Service } from './service/upload300.service';

@Component({
  selector: 'app-upload300',
  templateUrl: './upload300.component.html',
  styleUrls: ['./upload300.component.scss']
})

export class Upload300Component extends PermissionComponent {

  @ViewChild('fileInputFileItem', { static: false })
  fileInputFileItem: ElementRef;
  @ViewChild('fileInputFileIHItem', { static: false })
  fileInputFileIHItem: ElementRef;
  public page_model: Upload300Model;

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  constructor(private upload300Service: Upload300Service,
    private parameterService: ParameterService,
    private uploadService: UploadService,
    private messageService: MessageService,
    private translateService: TranslateService,
    renderer2: Renderer2) {
    super('P33003', renderer2);
    this.page_model = this.upload300Service.load;
  }

  ngOnInit() {
    this.parameterService.getDataParameterDetail(parameter_upload, parameter_entrycode_item).subscribe(response => {
      this.page_model.format_file_option_item = JSON.parse(JSON.stringify(response));
      this.onSelectedFormatFileItem(this.page_model.format_file_option_item);
    }, err => {
      console.log(err);
    });
    this.parameterService.getDataParameterDetail(parameter_upload, parameter_entrycode_ihitem).subscribe(response => {
      this.page_model.format_file_option_ihitem = JSON.parse(JSON.stringify(response));
      this.onSelectedFormatFileIHItem(this.page_model.format_file_option_ihitem);
    }, err => {
      console.log(err);
    });
//-------------- Get URL Google sheet Item--------------------
    this.parameterService.getDataParameterDetail(parameter_upload_template, parameter_entrycode_item).subscribe(response=>{
      let result = JSON.parse(JSON.stringify(response));
      if(result){
        this.page_model.template_url_item = result.pmdtbdtxtv1;
      }
    });
//-------------- Get URL Google sheet Item Inhouse--------------------
    this.parameterService.getDataParameterDetail(parameter_upload_template, parameter_entrycode_ihitem).subscribe(response=>{
      let result = JSON.parse(JSON.stringify(response));
      if(result){
        this.page_model.template_url_item_ihitem = result.pmdtbdtxtv1;
      }
    });
  }

  onSelectFileItem(file_item_info) {
    this.page_model.file_item = file_item_info.target.files[0];
    let format_file_flag: boolean = this.uploadService.formatFile(this.page_model.file_item);
    if (format_file_flag) {
      this.page_model.form_model.upload_form_item.controls['format_file'].setValue(this.page_model.file_item.name);
      this.page_model.flag_upload_file_item = false;
      if (this.formatFile(this.page_model.file_item, this.page_model.format_file_item, this.page_model.form_model.upload_form_item.controls['upload_date'].value)) {
        this.page_model.flag_upload_file_item = false;
      } else {
        this.page_model.flag_upload_file_item = true;
      }
    } else {
      this.warningAlert('alert', 'warn', this.translateService.instant('UPLOAD300.M00017'));
      this.page_model.form_model.upload_form_item.controls['format_file'].reset();
      this.page_model.flag_upload_file_item = true;
      this.fileInputFileItem.nativeElement.value = "";
    }
  }

  onUploadFileItem() {
    if (this.page_model.file_item) {
      this.uploadService.uploadItemCSVFile(this.page_model.file_item, this.page_model.form_model.upload_form_item.controls['upload_date'].value).subscribe((response: ResponseUpload) => {
        if (response.status == "1") {
          this.successAlert(this.translateService.instant('UPLOAD300.M00010'));
          setTimeout(() => {
            window.location.reload();
            window.scroll(0, 0);
          }, 500);
        } else {
          this.warningAlert('alert', 'error', this.translateService.instant('UPLOAD300.M00017'));
        }
      }, err => {
        console.log(err);
      });
    }
  }

  onSelectedFormatFileItem(parameter_upload_item: VParameter) {
    this.page_model.format_file_item = parameter_upload_item.pmdtbdtxtv1;
    this.page_model.flag_browse_file_item = false;
    if (parameter_upload_item.pmdtbdtxtv6 == 'Y') {
      this.page_model.form_model.upload_form_item.controls['upload_date'].setValue(this.page_model.to_day);
      this.page_model.flag_upload_date_item = false;
    } else {
      this.page_model.form_model.upload_form_item.controls['upload_date'].setValue(this.page_model.to_day);
      this.page_model.flag_upload_date_item = true;
    }
  }

  onSelectedUploadDateItem(upload_date_item: Date) {
    this.page_model.form_model.upload_form_item.controls['upload_date'].setValue(formatDate(upload_date_item, 'dd/MM/yyyy', 'en-US'));
    if (this.formatFile(this.page_model.file_item, this.page_model.format_file_item, this.page_model.form_model.upload_form_item.controls['upload_date'].value)) {
      this.page_model.flag_upload_file_item = false;
    } else {
      this.page_model.flag_upload_file_item = true;
    }
  }

  onSelectFileIHItem(file_ihitem_info) {
    this.page_model.file_ihitem = file_ihitem_info.target.files[0];
    let format_file_flag: boolean = this.uploadService.formatFile(this.page_model.file_ihitem);
    if (format_file_flag) {
      this.page_model.form_model.upload_form_ihitem.controls['format_file'].setValue(this.page_model.file_ihitem.name);
      this.page_model.flag_upload_file_ihitem = false;
      if (this.formatFile(this.page_model.file_ihitem, this.page_model.format_file_ihitem, this.page_model.form_model.upload_form_ihitem.controls['upload_date'].value)) {
        this.page_model.flag_upload_file_ihitem = false;
      } else {
        this.page_model.flag_upload_file_ihitem = true;
      }
    } else {
      this.warningAlert('alert', 'warn', this.translateService.instant('UPLOAD300.M00017'));
      this.page_model.form_model.upload_form_ihitem.controls['format_file'].reset();
      this.page_model.flag_upload_file_ihitem = true;
      this.fileInputFileIHItem.nativeElement.value = "";
    }
  }

  onUploadFileIHItem() {
    if (this.page_model.file_ihitem) {
      this.uploadService.uploadIHItemCSVFile(this.page_model.file_ihitem, this.page_model.form_model.upload_form_ihitem.controls['upload_date'].value).subscribe((response: ResponseUpload) => {
        if (response.status == "1") {
          this.successAlert(this.translateService.instant('UPLOAD300.M00010'));
          setTimeout(() => {
            window.location.reload();
            window.scroll(0, 0);
          }, 500);
        } else {
          this.warningAlert('alert', 'error', this.translateService.instant('UPLOAD300.M00017'));
        }
      }, err => {
        console.log(err);
      });
    }
  }

  onSelectedFormatFileIHItem(parameter_upload_ihitem: VParameter) {
    this.page_model.format_file_ihitem = parameter_upload_ihitem.pmdtbdtxtv1;
    this.page_model.flag_browse_file_ihitem = false;
    if (parameter_upload_ihitem.pmdtbdtxtv6 == 'Y') {
      this.page_model.form_model.upload_form_ihitem.controls['upload_date'].setValue(this.page_model.to_day);
      this.page_model.flag_upload_date_ihitem = false;
    } else {
      this.page_model.form_model.upload_form_ihitem.controls['upload_date'].setValue(this.page_model.to_day);
      this.page_model.flag_upload_date_ihitem = true;
    }
  }

  onSelectedUploadDateIHItem(upload_date_ihitem: Date) {
    this.page_model.form_model.upload_form_ihitem.controls['upload_date'].setValue(formatDate(upload_date_ihitem, 'dd/MM/yyyy', 'en-US'));
    if (this.formatFile(this.page_model.file_ihitem, this.page_model.format_file_ihitem, this.page_model.form_model.upload_form_ihitem.controls['upload_date'].value)) {
      this.page_model.flag_upload_file_ihitem = false;
    } else {
      this.page_model.flag_upload_file_ihitem = true;
    }
  }

  onGoToUploadHistory() {
    let url = 'upload/upload200'
    window.open(url);
  }

  formatFile(file: File, format_file: String, upload_date: String) {
    if (file && format_file && upload_date) {
      let format_filename_flag: boolean = this.uploadService.formatFileName(file, format_file);
      let format_filedate_flag: boolean = this.uploadService.formatFileDate(file, format_file, upload_date);
      if (format_filename_flag && format_filedate_flag) {
        return true;
      } else if (format_filename_flag && !format_filedate_flag) {
        this.warningAlert('alert', 'warn', this.translateService.instant('UPLOAD300.M00011'));
        return false;
      } else if (!format_filename_flag && format_filedate_flag) {
        this.warningAlert('alert', 'warn', this.translateService.instant('UPLOAD300.M00012'));
        return false;
      } else {
        this.warningAlert('alert', 'warn', this.translateService.instant('UPLOAD300.M00013'));
        return false;
      }
    } else {
      return false;
    }
  }

  warningAlert(key: string, severityInfo: string, detaiInfo: string) {
    this.messageService.clear();
    this.messageService.add({ key: key, sticky: true, severity: severityInfo, summary: this.translateService.instant('UPLOAD300.M00014'), detail: detaiInfo });
  }

  successAlert(detaiInfo: string) {
    this.messageService.clear();
    this.messageService.add({ key: 'alertsuccess', sticky: true, severity: 'success', detail: detaiInfo });
  }

  onConfirm() {
    this.messageService.clear();
  }

  onReject() {
    this.messageService.clear();
  }

  linkURL(module){
    if(module == 'ITEM'){
      let url = this.page_model.template_url_item;
      window.open(url);
    }else if(module == 'IHITEM'){
      let url = this.page_model.template_url_item_ihitem;
      window.open(url);
    }
  }
}
