import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { ParameterWms, ParameteSeller,ParameterEntity } from '../model/parameterwmsmodel';

@Injectable({
  providedIn: 'root'
})
export class ParameterWmsSerive {

  PRM_WMS_URL: string = `${this.evmService.getApiUrl()}/prm/parameterwms` as string;

  constructor(private http: HttpClient,
    private evmService: EnvironmentService,
    private authService: AuthenticationService) { }

  // getParameterWmsByCountry(country):Observable<ParameterWms>{
  //     const URL = `${this.PRM_WMS_URL}/getParameterWMSByCountry/${country}`;
  //     return this.http.get<ParameterWms>(URL, {headers: this.authService.tokenHeader()})
  // }

  // getParameterSeller():Observable<ParameteSeller>{
  //   const URL = `${this.PRM_WMS_URL}/getParameterSeller`;
  //   return this.http.get<ParameteSeller>(URL, {headers: this.authService.tokenHeader()})
  // }

  getAddressWms(tableNo, code) {
    const URL = `${this.PRM_WMS_URL}/getAddressWms/${tableNo}/${code}`;
    return this.http.get(URL, { headers: this.authService.tokenHeader() })
  }
  
  getParameterWms(tableNo) :Observable<ParameterEntity>{
    const URL = `${this.PRM_WMS_URL}/getParameterWms/${tableNo}`;
    return this.http.get<ParameterEntity>(URL, { headers: this.authService.tokenHeader() })
  }

  getParameterWmsCheckbox(tableNo) {
    const URL = `${this.PRM_WMS_URL}/getParameterWmsCheckbox/${tableNo}`;
    return this.http.get(URL, { headers: this.authService.tokenHeader() })
  }

  getParameterBytableNoAndCode(tableNo,code){
    const URL = `${this.PRM_WMS_URL}/getParameterBytableNoAndCode/${tableNo}/${code}`;
    return this.http.get(URL, { headers: this.authService.tokenHeader() })
  }

  getParameterWmsBytableNoAndCode(tableNo,code){
    const URL = `${this.PRM_WMS_URL}/getParameterWmsBytableNoAndCode/${tableNo}/${code}`;
    return this.http.get(URL, { headers: this.authService.tokenHeader() })
  }
  getParameterCategory(){
    const URL = `${this.PRM_WMS_URL}/getParameterCategory`;
    return this.http.get(URL, { headers: this.authService.tokenHeader() })
  }


}