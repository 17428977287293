import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';  
import * as XLSX from 'xlsx';  
import * as moment from 'moment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';  
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {
  constructor() { }


  public exportAsExcelFile(json: any[], excelFileName: string, header: any, title: any, subTitle: any): void {
    json.unshift(header);
    let worksheet: XLSX.WorkSheet;

    if (title) {
      const ws = XLSX.utils.aoa_to_sheet(title);
      worksheet = XLSX.utils.sheet_add_json(ws, json, { origin: -2, skipHeader: true });
    } else {
      const st = XLSX.utils.aoa_to_sheet(subTitle);
      worksheet = XLSX.utils.sheet_add_json(st,json,{ origin: 2, skipHeader: true });
    }
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, `${fileName}_${moment().format('YYYYMMDDHHmmssS')}${EXCEL_EXTENSION}`);
  }

}
