import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { TempDDLModel } from 'src/app/acc/acccdn/model/acccdn';

@Injectable({
  providedIn: 'root'
})
export class CreditNoteService {


  constructor(private http: HttpClient,
              private environmentService: EnvironmentService,
              private authenticationService: AuthenticationService) { }

  private CREDIT_NOTE_URL: string = `${this.environmentService.getApiUrl()}/api/acc/cnd` as string;

  getCreditNoteInquiry(creditNoteRequest) {
    const URL: string = `${this.CREDIT_NOTE_URL}/getCreditNoteInquiry` as string;
    return this.http.post(URL,creditNoteRequest,{ headers: this.authenticationService.tokenHeader()});
  }

  getCreditNoteData(creditNoteHeaderId:number){
    const URL: string = `${this.CREDIT_NOTE_URL}/getCreditNoteInfo/${creditNoteHeaderId}` as string;
    return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
  }

  getItemByDocument(itemRequest){
    const URL: string = `${this.CREDIT_NOTE_URL}/getItemByDocument` as string;
    return this.http.post(URL,itemRequest,{ headers: this.authenticationService.tokenHeader()});
  }

  getParameterPortal(prmNo:number,country:string){
    const URL: string = `${this.CREDIT_NOTE_URL}/getParameter/${prmNo}/${country}` as string;
    return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
  }

  saveCreditNote(creditNoteData){
    const URL: string = `${this.CREDIT_NOTE_URL}/saveCreditNote/` as string;
    return this.http.post(URL,creditNoteData,{ headers: this.authenticationService.tokenHeader()});
  }

  updateCreditNote(creditNoteData){
    const URL: string = `${this.CREDIT_NOTE_URL}/updateCreditNote/` as string;
    return this.http.post(URL,creditNoteData,{ headers: this.authenticationService.tokenHeader()});
  }

  getSummaryEarnings(country,startDate,endDate){
    const URL: string = `${this.CREDIT_NOTE_URL}/getSummaryEarnings/${country}/${startDate}/${endDate}` as string;
    return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
  }

  getSummaryIncome(country,startDate,endDate){
    const URL: string = `${this.CREDIT_NOTE_URL}/getSummaryIncome/${country}/${startDate}/${endDate}` as string;
    return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
  }

  getSummaryExpenses(country,startDate,endDate){
    const URL: string = `${this.CREDIT_NOTE_URL}/getSummaryExpenses/${country}/${startDate}/${endDate}` as string;
    return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
  }

  getSummaryCard(country,prm){
    const URL: string = `${this.CREDIT_NOTE_URL}/getSummaryCard/${country}/${prm}` as string;
    return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
  }

}
