import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { CustomerInquiry, profileCustomer } from './model/search-customer-model';
import { SalesOrderrService } from '../../services/sales-order-service';
import { CustomerModel } from 'src/app/ord/ordsor/ordsor200/model/sales-order-maintenance-model';

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.scss']
})
export class SearchCustomerComponent implements OnInit {
  @Input()displayCustomer: boolean;
  @Input() sellerCode: string;
  @Output() dataCustomer: EventEmitter<CustomerModel> = new EventEmitter<CustomerModel>();
  hiddenButton: Boolean = true;
  hightLight:any;
  name:String;
  index:any
  phone:String;
  memberKlangOne:String;
  memberKlangthai:String;
  kpmemid: String;
  kpdfmemid: String;
  dataNotFoundGridTab1: Boolean = true;
  customerDetail:CustomerModel = {} as CustomerModel;
  profileModel:Array<CustomerInquiry> = [] as Array<CustomerInquiry>;
  customerInq: Array<CustomerInquiry> = [] as Array<CustomerInquiry>;
  firstRow: number = 0 as number;
  constructor(private saleorderService : SalesOrderrService) { }

  ngOnInit() {
    this.getCustomerInqAll();
    // this.infoItem = [
    //   {
    //     name: 'พัฒนากร',
    //     surname:'คงรักษา',
    //     phone: '087-3212345',
    //     codeKlangOne:'',
    //     codeKlangthai:'',
    //     tax: '',
    //     address: '',
    //     postNumber: '',
    //     email: '',
    //     memberKlangOne: 'KO02345',
    //     memberKlangthai: 'KT02456'
    //   },
    //   {
    //     name: 'สมนึก',
    //     surname:'บุษศา',
    //     phone: '083-1256759',
    //     codeKlangOne:'',
    //     codeKlangthai:'',
    //     tax: '',
    //     address: '',
    //     postNumber: '',
    //     email: '',
    //     memberKlangOne: 'KO01235',
    //     memberKlangthai: 'KT09856'
    //   }
    // ]
    // this.profileModel = this.customerInq;
  }

  saveInfoItem(): void{
    this.saleorderService.getCustomerDetail(this.profileModel[this.index].customerId).subscribe(res=>{
      this.customerDetail = JSON.parse(JSON.stringify(res));
      console.log("this.customerDetail", this.customerDetail)
      this.dataCustomer.emit({
          customer_id:this.customerDetail.customer_id,
          customer_code:this.customerDetail.customer_code,
          customer_sellerCode:this.customerDetail.customer_sellerCode,
          customer_country:this.customerDetail.customer_country,
          customer_name: this.customerDetail.customer_name,
          customer_lastname: this.customerDetail.customer_lastname,
          customer_klangone_code: this.customerDetail.customer_klangone_code,
          customer_klangthai_code: this.customerDetail.customer_klangthai_code,
          customer_phone: this.customerDetail.customer_phone,
          customer_tax_id: this.customerDetail.customer_tax_id,
          customer_address: this.customerDetail.customer_address,
          customer_subdistrict: this.customerDetail.customer_subdistrict,
          customer_district: this.customerDetail.customer_district,
          customer_province: this.customerDetail.customer_province,
          customer_postal_code: this.customerDetail.customer_postal_code,
          customer_email: this.customerDetail.customer_email,
          customer_kpdfmemid: this.customerDetail.customer_kpdfmemid,
          customer_kpmemid: this.customerDetail.customer_kpmemid,
          customer_provinceDes: this.customerDetail.customer_provinceDes,
          customer_subdistrictDes: this.customerDetail.customer_subdistrictDes,
          customer_districtDes: this.customerDetail.customer_districtDes,
          customer_occupertion: this.customerDetail.customer_occupertion,
          customer_occupertionDes: this.customerDetail.customer_occupertionDes,
          customer_occupertionCdDes: this.customerDetail.customer_occupertionCdDes,
          customer_status: this.customerDetail.customer_status,
          customer_sex: this.customerDetail.customer_sex,
          customer_sexDes: this.customerDetail.customer_sexDes,
          customer_age: this.customerDetail.customer_age,
          customer_url_facebook: this.customerDetail.customer_url_facebook,
          customer_nationality: this.customerDetail.customer_nationality,
          customer_nationalityDes: this.customerDetail.customer_nationalityDes,
          customer_birth: this.customerDetail.customer_birth,
          customer_phone2: this.customerDetail.customer_phone2,
        });
    },err=>{
      console.log(err);

    })
    this.displayCustomer = false;
  }

  closeDialog(){
    this.displayCustomer = false;
    this.dataCustomer.emit();
  }

  selectRow(index){
    // this.hightLight = true;
    this.hightLight = index;
    this.hiddenButton = false;
    this.index = index;

  }

  getCustomerInq(){

    this.profileModel = this.customerInq;
    if(this.name){
      this.profileModel = this.profileModel.filter(data => data.firstname.toLowerCase().indexOf(this.name.toLowerCase()) > -1);
      if(this.profileModel.length == 0){
        this.dataNotFoundGridTab1 = true;
        // this.profileModel = this.customerInq;
        // this.profileModel = this.profileModel.filter(data => data.lastname.toLowerCase().indexOf(this.name.toLowerCase()) > -1);
      }
    }

    if(this.phone){
      this.profileModel = this.profileModel.filter(data =>data.phoneNumber.toLowerCase().indexOf(this.phone.toLowerCase()) > -1);
      if(this.profileModel.length == 0){
        this.dataNotFoundGridTab1 = true;
      }
    }

    if(this.kpmemid){
      this.profileModel = this.profileModel.filter(data => {
        if(data.kpmemid && data.kpmemid.toLowerCase().includes(this.kpmemid.toLowerCase())){
          return true;
        }
        return false;
      });
    }

    if(this.kpdfmemid){
      this.profileModel = this.profileModel.filter(data => {
        if(data.kpdfmemid && data.kpdfmemid.toLowerCase().includes(this.kpdfmemid.toLowerCase())){
          return true;
        }
        return false;
      });
      if(this.profileModel.length == 0){
        this.dataNotFoundGridTab1 = true;
      }
    }

    if (this.profileModel.length == 0) {
      this.dataNotFoundGridTab1 = true;
    } else {
      this.dataNotFoundGridTab1 = false;
      this.firstRow = 0;
    }
  }

  getCustomerInqAll() {
    this.profileModel = this.customerInq;
    let sellCode = null;
    this.saleorderService.getCustomerInquiry(sellCode).subscribe(res=>{ // ลบ this.sellerCode
      this.customerInq = JSON.parse(JSON.stringify(res.data));
      // this.profileModel = this.customerInq;
      if (this.profileModel.length == 0) {
        this.dataNotFoundGridTab1 = true;
      } else {
        this.dataNotFoundGridTab1 = false;
      }
    },err=>{
      console.log(err);

    })
  }

}
