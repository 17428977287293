import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { FormModel, Prisal200Model } from '../model/prisal200.model';

@Injectable({
  providedIn: 'root'
})
export class Prisal200Service {

  prisal200: Prisal200Model = {} as Prisal200Model;

  constructor(private http: HttpClient,
    private evmService: EnvironmentService,
    private autService: AuthenticationService,
    private fb: FormBuilder) { 
    this.init();
  } 

  init(): void {
    this.prisal200.form_model = {} as FormModel;
    this.prisal200.form_model.gridDataForm = this.fb.group({
      countryCode: [null, Validators.required],
      currency: (null),
      endDate: [null, Validators.required],
      fullPrice: (null),
      iemDesc: (null),
      itemCodeFromKO: (null),
      itemCodeFromKT: [null, Validators.required],
      itemCodeFromWMS: (null),
      saleChanelCode: [[], Validators.required],
      saleChanelDesc: (null),
      salePrice: [null, Validators.required],
      salePriceNo: (null),
      sellerCode: [null, Validators.required],
      sellerDesc: (null),
      startDate: [null, Validators.required],
      freeShipmentFlag: (null),
    });
  }

  get load(): Prisal200Model {
      return this.prisal200;
  }
}