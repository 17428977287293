import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ChartColor } from "src/app/shared/constant/chartcolor.constant";
import { TranslateLablePipe } from "src/app/shared/pipes/translateLable.pipe";
import { DashboardOverallService } from "src/app/shared/services/dashboard-overall.service";
import { DecimalService } from "src/app/shared/services/decimal.service";
import { DecimalData, Dshovafs100Model, FormModel, StockCardByPeriod } from "../model/dshovafs100.model";

@Injectable({
    providedIn: 'root'
  })
  export class Dshovafs100Service {
    dshovafs100: Dshovafs100Model = {} as Dshovafs100Model;
    translateLablePipe = new TranslateLablePipe();

    constructor(private fb: FormBuilder,
        private dshovaService: DashboardOverallService,
        private translateService: TranslateService,
        private decimalService: DecimalService,
        private decimalPipe: DecimalPipe) {
        this.init();
      }

      init(): void {
        this.dshovafs100.form_model = {} as FormModel;
        this.dshovafs100.form_model.gridSearchCriteriaForm = this.fb.group({
          country: (null),
          seller: (null),
          sellerLength: (null),
        });
        this.dshovafs100.form_model.gridHeaderCriteriaForm = this.fb.group({
          countinv: (null),
          countitem: (null),
          defaultPeriod: (null),
        });
        this.dshovafs100.form_model.gridAdvanceSearchForm = this.fb.group({
          itemCodeCriteria: (null),
          onlineCodeCriteria: (null),
          dateCriteria: [null],
          saleCat: (null)
        });
        this.dshovafs100.uuid = "";
        this.dshovafs100.form_model.gridOverviewForm = this.fb.group({
          sale_doc_today: (0),
          sale_doc_this_month: (0),
          sale_doc_three_month: (0),
          sale_doc_this_year: (0),
          sale_qty_today: (0),
          sale_qty_this_month: (0),
          sale_qty_three_month: (0),
          sale_qty_this_year: (0),
          sale_doc_total: (0),
          sale_item_avg_sku: (0)
        });
        this.dshovafs100.tempData = [
          {
            'header': this.translateService.instant('DSHOVAfs100.M00048'),
            'order': 0,
            'icon': 'pi pi-shopping-cart',
          },
          {
            'header': this.translateService.instant('DSHOVAfs100.M00049'),
            'order': 0,
            'icon': 'pi pi-clock',
          },
          {
            'header': this.translateService.instant('DSHOVAfs100.M00050'),
            'order': 0,
            'icon': 'fa fa-hourglass-half',
          },
          {
            'header': this.translateService.instant('DSHOVAfs100.M00051'),
            'order': 0,
            'icon': 'fa fa-calendar',
          }
        ];
        this.dshovafs100.DatatoChartStock;
        this.dshovafs100.stockBal = [] as Array<StockCardByPeriod>;
        this.dshovafs100.showStockChart;
        this.dshovafs100.showDataNotFoundStockChart = true;
        this.dshovafs100.optionPie;
        this.dshovafs100.stockBalanceChart;
        this.dshovafs100.dataToshowChart;
        this.dshovafs100.select;
        this.dshovafs100.showDataNotFound = true;
        this.dshovafs100.showSaleChart = true;
        this.dshovafs100.salesByItemGrid;
        this.dshovafs100.showGrid;
        this.dshovafs100.showDataNotFoundGrid = true;
        this.dshovafs100.showAdvanceSearch;
        this.dshovafs100.showSaleAmount;
        this.dshovafs100.showToday;
        this.dshovafs100.showNotToday = true;
        this.dshovafs100.periodSaleShow;
        this.dshovafs100.disButtonChart = false;
        this.dshovafs100.isRight = false;
        this.dshovafs100.showDetail = false;
        this.dshovafs100.parameterDecimal = [];
        this.dshovafs100.decimalData = {} as DecimalData;
        this.dshovafs100.typeDecimalPurchase = '1';
        this.dshovafs100.typeDecimalSelling = '2';
        this.dshovafs100.typeDecimalRoundMethod = '3';
        this.dshovafs100.typeDecimalRoundToValue = '4';
        this.dshovafs100.method;
        this.dshovafs100.roundToDecimal;
        this.dshovafs100.decimal;
      }

      get load(): Dshovafs100Model {
        return this.dshovafs100;
      }

      loadOverviewData(page_model: Dshovafs100Model, country: string, seller: string, uuid: string, period: string, datetype: string, periodtype: string) {
        if (country != null && seller != null) {
          this.dshovaService.getSaleSummaryByPeriod(country, seller, uuid).subscribe(response => {
            page_model.uuid = response.uuid;
          });
        }
      }

      loadAllChartDataData(page_model: Dshovafs100Model, country: string, seller: string, period: string, uuid: string, datetype: string, periodtype: string) {
        if (country != null && seller != null && period != null) {
          this.dshovaService.getDashbordOverallByPeriod(country, seller, period, page_model.uuid).subscribe(response => {
            page_model.uuid = response.uuid;
            page_model.DatatoChartStock = response.stockCradByPeriod;
            if (page_model.DatatoChartStock != null) {
              if (page_model.DatatoChartStock.length > 0) {
                page_model.stockBal = page_model.DatatoChartStock;
                if (page_model.stockBal.length == 0 || !page_model.stockBal) {
                  page_model.showStockChart = false;
                  page_model.showDataNotFoundStockChart = true;
                } else {
                  const listLabel = new Array();
                  const listQty = new Array();
    
                  page_model.stockBal.forEach(element => {
                    listLabel.push(this.translateLablePipe.transform(element.item_english_desc, element.item_local_desc));
                    listQty.push(element.ending_balance_qty);
                  });
    
                  let legendChart: any;
                  if (window.screen.width >= 640 && window.screen.width < 1025) {
                    legendChart = false;
                  } else if (window.screen.width > 1024) {
                    legendChart = 'right';
                  }
    
                  page_model.stockBalanceChart = {
                    labels: listLabel,
                    datasets: [{
                      label: this.translateService.instant('DSHOVA100.M00046'),
                      data: listQty,
                      backgroundColor: ChartColor.backgroundColorChart,
                      hoverBackgroundColor: ChartColor.backgroundColorChart
                    }]
                  };
    
                  page_model.optionPie = {
                    legend: {
                      position: legendChart,
                    }
                  };
    
                  page_model.showStockChart = true;
                  page_model.showDataNotFoundStockChart = false;
                }
              }else{
                page_model.showStockChart = false;
                page_model.showDataNotFoundStockChart = true;
              }
            } else {
              page_model.showStockChart = false;
              page_model.showDataNotFoundStockChart = true;
            }
    
            page_model.dataToshowChart = response.saleOrderDetailByPeriod;
            page_model.select = { datetype, period: periodtype, typeBar: 'single', showText: this.translateService.instant('DSHOVA100.M00047') };
            if (page_model.dataToshowChart != null) {
              if (page_model.dataToshowChart.length == 0) {
                page_model.showDataNotFound = true;
              } else {
                page_model.showDataNotFound = false;
              }
            } else {
              page_model.showDataNotFound = true;
            }
    
            if (page_model.select.datetype == 'D') {
              page_model.select.period == 0 || page_model.select.period == 1 ? page_model.showSaleChart = true : page_model.showSaleChart = false;
            } else {
              page_model.showSaleChart = false;
            }
    
            page_model.salesByItemGrid = response.tableSaleOrderDetailByPeriod;
            if (page_model.salesByItemGrid != null) {
              if (page_model.salesByItemGrid.length == 0) {
                page_model.showGrid = false;
                page_model.showDataNotFoundGrid = true;
                page_model.showAdvanceSearch = false;
              } else {
                page_model.showDataNotFoundGrid = false;
                page_model.showGrid = true;
                page_model.backupGridData = page_model.salesByItemGrid;
              }
            } else {
              page_model.showGrid = false;
              page_model.showDataNotFoundGrid = true;
              page_model.showAdvanceSearch = false;
            }
          });
    
        }
      }
  }