import { Component, OnInit, Renderer2, TemplateRef, EmbeddedViewRef, ElementRef, ViewChild, Injectable, Inject } from '@angular/core';
import { PremissionService } from 'src/app/shared/services/premission.service';
import { PermissionModel, RequestData, ActionData } from 'src/app/shared/model/premission-model';
import { NgxPermissionsService, NgxPermissionsConfigurationService, NgxRolesService } from 'ngx-permissions';
import { AppInjector } from 'src/app/shared/services/app-injecter-service';
import { actionData } from 'src/app/shared/constant/permission.constant';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
@Injectable()
export class PermissionComponent {

  permissionData: PermissionModel;
  premissionService: PremissionService;
  ngxPermissionsService: NgxPermissionsService;
  ngxPermissionsConfigurationService: NgxPermissionsConfigurationService;
  ngxRolesService: NgxRolesService;
  injector = AppInjector.getInjector();
  requestData = {} as RequestData;
  actionData: ActionData;
  constructor(
    @Inject('menuCode') private menuCode: string,
    private renderer2: Renderer2,
  ) {
    this.premissionService = this.injector.get(PremissionService);
    this.ngxPermissionsService = this.injector.get(NgxPermissionsService);
    this.ngxRolesService = this.injector.get(NgxRolesService);
    this.ngxPermissionsConfigurationService = this.injector.get(NgxPermissionsConfigurationService);
    this.actionData = actionData;
    this.requestData.menuCode = this.menuCode;
    this.permission();

  }

  ngOnInit() {

  }

  permission(){
    this.premissionService.getPermission(this.requestData).subscribe((res: PermissionModel) => {
      this.permissionData = res;
      this.ngxPermissionsService.addPermission(res.accActionFalse);
      this.ngxPermissionsConfigurationService.setDefaultOnUnauthorizedStrategy('disable');
      this.ngxPermissionsConfigurationService.setDefaultOnAuthorizedStrategy('enable');
    });

    this.ngxPermissionsConfigurationService.addPermissionStrategy('disable', (templateRef: TemplateRef<any>) => {
      if (this.permissionData.accActionFalse[0] != 'Not find false') {
        this.renderer2.addClass(templateRef.elementRef.nativeElement.nextSibling, 'disable-permission');
      } else {
        this.renderer2.removeClass(templateRef.elementRef.nativeElement.nextSibling, 'disable-permission');
      }
    });
  }

}
