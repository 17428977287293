import { Attribute, Component, OnInit ,Renderer2 } from '@angular/core';
import { Admatr100Model, AttributeModel, SearchFormGroup} from '../../model/admatr/admatr100.model';
import { MessageService } from 'primeng/api';
import { Admatr100Service } from '../../service/admatr/admatr100.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { Router } from '@angular/router';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import {  AttributeDeleteRequest, AttributeInquiryRequest } from 'src/app/shared/model/attribute.model';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-admatr100',
  templateUrl: './admatr100.component.html',
  styleUrls: ['./admatr100.component.scss']
})
export class Admatr100Component  extends PermissionComponent {

  admatr100: Admatr100Model;
  Serchsubmit : SearchFormGroup ;
  public attributeClassDelete: Array<AttributeModel> = []  ;
  constructor(renderer2: Renderer2,
              private router: Router,
             private admatr100Service: Admatr100Service,
             private translateService: TranslateService,
             private messageService: MessageService,
             private exportExcelService: ExportExcelService,) 
             {
              super('P01001', renderer2);
              this.admatr100 = this.admatr100Service.load;
              this.admatr100.flagButtonAdd = false ;
              this.admatr100.btuClear = true ;
              this.admatr100.checkFlagDelete = true ;
              this.admatr100.disableExportReport = true;
              this.admatr100.firstRow = 0;
              }
            
  ngOnInit() {
   setTimeout (() =>
   this.admatr100Service.load
  , 500); 
  this.admatr100.form.searchFormGroup.form.valueChanges.subscribe(data => {
    this.admatr100.attributesList = data;    
    this.admatr100.submitSearch = false;
    this.admatr100.btuClear = false;
  })

  }
 
  searchSubmit() {
      this.admatr100.submitSearch = true;
      this.admatr100Service.searchSubmit().subscribe((attributeList : Array<AttributeModel>) => {
      this.admatr100.attributeList = attributeList; 
      if(this.admatr100.attributeList != this.admatr100.attributesList){
        this.admatr100.disableExportReport = true;
      }
      this.admatr100.attributeList.forEach((element : AttributeModel) => {
      element.classes = element.classes ;
      element.enDesc = element.enDesc ;
      element.localDesc = element.localDesc;
      if(this.admatr100.attributeList.length > 0){
            this.admatr100.temp_allAttribute = attributeList;
            this.admatr100.disableExportReport = false;
            this.admatr100.firstRow = 0;
            
          }
      })
    }, error => {
      console.log("Error");
      } );
  }

 

  checkedAll(isChecker: boolean){
    this.attributeClassDelete =[] ;
    if (isChecker) {
      this.attributeClassDelete = this.admatr100.attributeClass;
      this.admatr100.checkFlagDelete = false;
    } else {
      this.attributeClassDelete = [];
      this.admatr100.checkFlagDelete = true;
    }
  }
  checked(isChecker: boolean ,attribute : AttributeModel){

    if (isChecker == true) {
      this.admatr100.attributeList.forEach((data, index) => {    
        if(data.classes == attribute.classes){
          this.attributeClassDelete.push(data);
        }
      });
      
      if(this.attributeClassDelete.length > 0){
        this.admatr100.checkFlagDelete = false;
      }else{
        this.admatr100.checkFlagDelete = true;
      }
    }else{
      this.attributeClassDelete = this.attributeClassDelete.filter(data => data.classes != attribute.classes);
      
      if(this.attributeClassDelete.length > 0){
        this.admatr100.checkFlagDelete = false;
      }else{
        this.admatr100.checkFlagDelete = true;
      }
    }
  }
  clearSearchForm() {
    this.admatr100.form.searchFormGroup.form.reset();
    this.admatr100.btuClear = true ;
  }

  changeSearch(){
    if(this.admatr100.form.searchFormGroup.form.dirty){
      this.admatr100.submitSearch = false;
    } else {
      this.admatr100.submitSearch = true;
    }
  }

  createAttribuite(){
    this.router.navigate(['admatr/admatr200']);
  }

  onReject() {
    this.messageService.clear();
  }
  
  onConfirm(){
    let attribute_list : AttributeDeleteRequest = {} as AttributeDeleteRequest ;
    attribute_list.classesNo = [];
    this.attributeClassDelete.forEach(data => {
      attribute_list.classesNo.push(data.classes);

      this.admatr100.attributeList.forEach((art,index) => {
        if(art.classes == data.classes){
          this.admatr100.attributeList.splice(index, 1);
        }
      
      })
    });
    
    this.admatr100Service.deleteFunction(attribute_list).subscribe(success => {
      let dataResponse = JSON.parse(JSON.stringify(success));
      if(dataResponse != null){
        this.successAlert( this.translateService.instant('ADMATR100.M00023'));
        setTimeout(() => {
          this.onReject();
        }, 500);
      }else{
        this.messageService.clear();
      }
      }, error => {
        console.log("Error");
        } );
        this.admatr100.checkFlagDelete = true ;

  }
  
  exportReport() {
    let exportAttribute = [];
    this.admatr100.attributeList.forEach(atr=>{
      exportAttribute.push({
        classes : atr.classes,
        enDesc : atr.enDesc,
        localDesc : atr.localDesc 
        })
    });

    let subTitle = [];
    subTitle.push([this.translateService.instant('ADMATR100.M00001')]);
    let header: any = {
      classes: this.translateService.instant('ADMATR100.M00003'),
      enDesc: this.translateService.instant('ADMATR100.M00005'),
      localDesc: this.translateService.instant('ADMATR100.M00007'),
    
    }
    this.exportExcelService.exportAsExcelFile(exportAttribute, this.translateService.instant('ADMATR100.M00001'), header, null, subTitle);
    this.admatr100.disableExportReport = true ;
    this.admatr100.btuClear = true;
  }
  deleteAtrFunction( detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: 'alert', sticky: true, severity: 'warn', summary: this.translateService.instant('ADMATR100.M00026'), detail: detaiInfo});
  }

  successAlert(detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: 'success', sticky: true, severity: 'success', summary: this.translateService.instant('ADMATR100.M00021'), detail: detaiInfo});
  }

  navigateToAttributeMaintenance(acvclscd: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['admatr/admatr200', acvclscd]).then(() => {
        window.location.reload();
        window.scroll(0, 0);
      })
    );
  }

}