import { Injectable } from '@angular/core';
import { UserData, ActionData } from 'src/app/adm/model/admusr/admusr200.model';


@Injectable({
  providedIn: 'root'
})

export class AdmusrLocalStorageService {

  public storageName: string = 'UserData';

  /* Status Page is 'EDIT' --> Program will load new user data 
     Status Page is 'EDITED' --> Program will set user data from Local Storage and change status page is 'EDIT' after set data */

  constructor() { }

  addUserData(data: UserData) {
    localStorage.setItem(this.storageName, JSON.stringify(data));
  }

  updateActionByDim(dimension, index, actionData: Array<ActionData>, statusPage) {
    let user: UserData = this.getUserData();
    user.statusPage = statusPage;
    if (actionData) {
      if (dimension == 'saleChannel') {    
        user.saleChannelData[index].actionData = actionData;
        user.dimToActPage.actionData = actionData;
      } else {
        user.flowTypeData[index].actionData = actionData;
        user.dimToActPage.actionData = actionData;
      }
    }
    localStorage.setItem(this.storageName, JSON.stringify(user));
  }

  getUserData() {
    let data = localStorage.getItem(this.storageName);
    return JSON.parse(data);
  }

  clearUserData() {
    localStorage.removeItem(this.storageName);
  }

}