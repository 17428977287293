import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { DeleteSalePriceRequest, SalePriceInquiryModel, SalePriceInquiryRequest } from '../model/sales-price.model';

@Injectable({
    providedIn: 'root'
  })
export class PriceSalePriceService {
    
    SALE_PRICE_URL: string = `${this.evmService.getApiUrl()}/pri/prisal`;

    constructor(
            private http: HttpClient,
            private evmService: EnvironmentService,
            private autService: AuthenticationService) {

    }

    getPriceSaleInquiry(sqlepriceInquiryRequest: SalePriceInquiryRequest): Observable<Array<SalePriceInquiryModel>> {
        const URL = `${this.SALE_PRICE_URL}/getPriceSaleInquiry`;
        return this.http.post <Array<SalePriceInquiryModel>>(URL, sqlepriceInquiryRequest, { headers: this.autService.tokenHeader() });
    }

    delSalePrice(deleteSalePriceRequest: DeleteSalePriceRequest): Observable<any> {
        const URL = `${this.SALE_PRICE_URL}/deletePriceSale`;
        return this.http.post(URL, deleteSalePriceRequest, { headers: this.autService.tokenHeader() });
    }
}