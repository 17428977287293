import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ParameterEntity } from 'src/app/shared/model/paramete.rmodel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ddl-seller',
  templateUrl: './ddl-seller.component.html',
  styleUrls: ['./ddl-seller.component.scss']
})
export class DdlSellerComponent implements OnInit {
  @Input() display: boolean;
  @Input() sellerValue: string;
  @Input() param: any = { country: null, warehouse: null };
  @Input() requireds: boolean;
  @Output() seller = new EventEmitter<any>();
  @Output() sellerLength = new EventEmitter<number>();

  sellerData: ParameterEntity[];

  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.getSeller();
  }

  ngOnChanges() {
    this.getSeller();
   }

  getSeller(){
    this.clearData();
    this.authenticationService.getParameterWmsSeller(this.param.country, this.param.warehouse).subscribe((res: ParameterEntity[]) => {
      this.sellerData = res;
      if (this.sellerData.length == 1) {
        this.display = true;
        this.sellerValue = this.sellerData[0].value;
        this.seller.emit({ seller: this.sellerValue, length: this.sellerData.length });
      }else if(this.sellerData.length == 0) {
        this.display = true;
      }else if(this.param.country != null) {
        this.display = false;
      }else{
        this.display = false;
      }
    })
  }

  clearData(){
    this.sellerData = [];
    this.display = true;
  }

  selectedSeller(event) {
    this.seller.emit({ seller: event.value, length: this.sellerData.length });
  }

}
