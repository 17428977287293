import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { PermissionModel } from '../model/premission-model'

@Injectable({
  providedIn: 'root'
})
export class PremissionService {

  PREMISSION_URL: string = `${this.evmService.getApiUrl()}/permission` as string;

    constructor(private http: HttpClient,
      private evmService: EnvironmentService,
      private autService: AuthenticationService){}

      getPermission(requestData): Observable<PermissionModel> {
        const URL = `${this.PREMISSION_URL}/getPremissionMenu`;
        return this.http.post <PermissionModel>(URL, requestData, { headers: this.autService.tokenHeader()});
      }

}