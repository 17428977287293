import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { AboutComponent } from './application/about/about.component';
import { Autaut100Component } from './authentication/autaut100/autaut100.component';
import { Autaut200Component } from './authentication/autaut200/autaut200.component';
import { Autaut300Component } from './authentication/autaut300/autaut300.component';
import { Synsyn100Component } from './synchronize/synsyn100/synsyn100.component';

const routes: Routes = [
  { path: 'syn/synsyn100', component:  Synsyn100Component },
  { path: 'app/fe8c2f62-a1a9-471e-a69d-fedf1a63195b', component: AboutComponent},
  { path: 'aut/autaut100', component: Autaut100Component},
  { path: 'aut/autaut200', component: Autaut200Component},
  { path: 'aut/autaut300', component: Autaut300Component},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRouthModuleModule { }
