import { Injectable } from '@angular/core';
import { FormControl, FormGroup ,FormBuilder} from '@angular/forms';
import { Admatr100Model, AttributeModel, FormModel, SearchFormGroup } from '../../model/admatr/admatr100.model';
import { AttributeDeleteRequest, AttributeInquiryRequest } from 'src/app/shared/model/attribute.model';
import {AttributeService} from 'src/app/shared/services/attribute.service' ;
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class Admatr100Service {

     admatr100: Admatr100Model = {} as Admatr100Model;

     constructor(private attributeService: AttributeService ,
        private fb: FormBuilder
        ){
            this.inti();
        }

        inti(): void {

            this.admatr100.menuCode ='' ;
            this.admatr100.form = {} as FormModel;
            this.admatr100.form.searchFormGroup = {} as SearchFormGroup;
        this.admatr100.form.searchFormGroup.form = this.fb.group({
                classes: [null],
                enDesc:[null],
                localDesc: [null]
           });

          this.admatr100.orignalData = {};
          this.admatr100.orignalData.attributeList = [];
          this.admatr100.orignalData.attributeList = this.admatr100.attributeList;
       }
       get load(): Admatr100Model{

          return this.admatr100;
       }

      searchSubmit() :  Observable<Array<AttributeModel>>{
          let attributesInquiry: AttributeInquiryRequest = {} as AttributeInquiryRequest ;
          attributesInquiry.classes = this.admatr100.form.searchFormGroup.form.get('classes').value;
          attributesInquiry.enDesc = this.admatr100.form.searchFormGroup.form.get('enDesc').value;
          attributesInquiry.localDesc = this.admatr100.form.searchFormGroup.form.get('localDesc').value;
          return this.attributeService.getAttributeInquiry(attributesInquiry);
      }

      deleteFunction(attributeDelete : AttributeDeleteRequest ) : Observable<any> {

          
        return this.attributeService.deleteAttribute(attributeDelete);
      }
}
