import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { DeliveryItem, DeliveryNoteHeader, DeliveryNoteModel } from 'src/app/shared/model/dashboard-logistic-delivery-note.model';
import { DashboardLogisticService } from 'src/app/shared/services/dashboard-logistic.service';
import { Dshlgt110Model, FormModel } from '../model/dshlgt110.model';

@Injectable({
    providedIn: 'root'
})

export class Dshlgt110Service {

    dshlag110: Dshlgt110Model = {} as Dshlgt110Model;

    constructor(private dshlgtService: DashboardLogisticService) {
        this.inti();
    }

    inti(): void {
        // Page information
        this.dshlag110.menuCode = '';
        this.dshlag110.pageCode = 'dshlgt110';
        this.dshlag110.pageName = 'เอกสารใบแนะนำส่งของ (Suggest Delivery Note)';

        // Data in page
        this.dshlag110.deliveryNoteData = {} as DeliveryNoteModel;
        this.dshlag110.deliveryNoteData.header = {} as DeliveryNoteHeader;
        this.dshlag110.deliveryNoteData.itemList = [] as Array<DeliveryItem>;

        // Form in page
        this.dshlag110.form = {} as FormModel;
        this.dshlag110.form.headerForm = new FormGroup({
            no: new FormControl({value: this.dshlag110.deliveryNoteData.header.no, disabled: true}),
            sellerCode: new FormControl({value: this.dshlag110.deliveryNoteData.header.sellerCode, disabled: true}),
            sellerName: new FormControl({value: this.dshlag110.deliveryNoteData.header.sellerName, disabled: true}),
            sellertaxID: new FormControl({value: this.dshlag110.deliveryNoteData.header.sellertaxID, disabled: true}),
            sellerPhoneNo: new FormControl({value: this.dshlag110.deliveryNoteData.header.sellerPhoneNo, disabled: true}),
            sellerEmail: new FormControl({value: this.dshlag110.deliveryNoteData.header.sellerEmail, disabled: true}),
            sellerAddress: new FormControl({value: this.dshlag110.deliveryNoteData.header.sellerAddress, disabled: true}),
            creatorName: new FormControl({value: this.dshlag110.deliveryNoteData.header.creatorName, disabled: true}),
            createDate: new FormControl({value: this.dshlag110.deliveryNoteData.header.createDate, disabled: true}),
            transportRoute: new FormControl({value: this.dshlag110.deliveryNoteData.header.transportRoute, disabled: true}),
            trackingNo: new FormControl({value: this.dshlag110.deliveryNoteData.header.trackingNo, disabled: true}),
            isFulfillment: new FormControl({value: this.dshlag110.deliveryNoteData.header.isFulfillment, disabled: true}),
            logisticStatus: new FormControl({value: this.dshlag110.deliveryNoteData.header.logisticStatus, disabled: true}),
            remark: new FormControl({value: this.dshlag110.deliveryNoteData.header.remark, disabled: true})
        });
    }

    get loadPage(): Dshlgt110Model {

        // const ddate: Date = moment('2013-01-02', 'YYYY-MM-DD', true).creationData();

        // const mockupData = {
        //     header: {
        //         no: 'DNSKH0001',
        //         sellerCode: 'S00001',
        //         sellerName: 'Mild company',
        //         sellertaxID: '123456',
        //         sellerPhoneNo: '085123456',
        //         sellerEmail: 'cnk.com',
        //         sellerAddress: '5/7 rangsit',
        //         creatorName: 'Laos CSA',
        //         createDate: moment('01/01/2020', 'DD/MM/YYYY', false),
        //         transportRoute: 'กรุงเทพ-พนม',
        //         trackingNo: 'KLXKH123456',
        //         isFulfillment: [],
        //         logisticStatus: 'rcv. at logis.',
        //         remark: 'note note'
        //     }  as DeliveryNoteHeader,
        //     itemList: [
        //       {
        //         sellerItemCode: 'item02',
        //         koItemCode: 'itemko02',
        //         name: 'item item item 2',
        //         qty: 4,
        //         unit: 'ชื้น'
        //       } as DeliveryItem
        //     ] as Array<DeliveryItem>
        // } as DeliveryNoteModel;

        this.inti();

        this.dshlag110.form.headerForm.controls.no.setValue(this.dshlag110.deliveryNoteData.header.no);
        this.dshlag110.form.headerForm.controls.sellerCode.setValue(this.dshlag110.deliveryNoteData.header.sellerCode);
        this.dshlag110.form.headerForm.controls.sellerName.setValue(this.dshlag110.deliveryNoteData.header.sellerName);
        this.dshlag110.form.headerForm.controls.sellertaxID.setValue(this.dshlag110.deliveryNoteData.header.sellertaxID);
        this.dshlag110.form.headerForm.controls.sellerPhoneNo.setValue(this.dshlag110.deliveryNoteData.header.sellerPhoneNo);
        this.dshlag110.form.headerForm.controls.sellerEmail.setValue(this.dshlag110.deliveryNoteData.header.sellerEmail);
        this.dshlag110.form.headerForm.controls.sellerAddress.setValue(this.dshlag110.deliveryNoteData.header.sellerAddress);
        this.dshlag110.form.headerForm.controls.creatorName.setValue(this.dshlag110.deliveryNoteData.header.creatorName);
        this.dshlag110.form.headerForm.controls.createDate.setValue(this.dshlag110.deliveryNoteData.header.createDate ?
        this.dshlag110.deliveryNoteData.header.createDate.format('DD/MM/YYYY') : null);
        this.dshlag110.form.headerForm.controls.transportRoute.setValue(this.dshlag110.deliveryNoteData.header.transportRoute);
        this.dshlag110.form.headerForm.controls.trackingNo.setValue(this.dshlag110.deliveryNoteData.header.trackingNo);
        this.dshlag110.form.headerForm.controls.isFulfillment.setValue(this.dshlag110.deliveryNoteData.header.isFulfillment);
        this.dshlag110.form.headerForm.controls.logisticStatus.setValue(this.dshlag110.deliveryNoteData.header.logisticStatus);
        this.dshlag110.form.headerForm.controls.remark.setValue(this.dshlag110.deliveryNoteData.header.remark);

        return this.dshlag110;
    }

    loadDeliveryNote(country: string, deviveryNoteNo: string, seller: string) {
        if (country && deviveryNoteNo) {
            this.dshlgtService.getDeliveryNoteDetail(country, deviveryNoteNo,seller).subscribe((deliveryNoteModel: DeliveryNoteModel) => {
                if (deliveryNoteModel) {
                    if (deliveryNoteModel.header) {
                        this.dshlag110.deliveryNoteData = deliveryNoteModel;

                        this.dshlag110.deliveryNoteData.header.createDate = moment(deliveryNoteModel.header.createDate, 'DD/MM/YYYY', false);

                        // Set Form controller
                        this.dshlag110.form.headerForm.controls.no.setValue(this.dshlag110.deliveryNoteData.header.no);
                        this.dshlag110.form.headerForm.controls.sellerCode.setValue(this.dshlag110.deliveryNoteData.header.sellerCode);
                        this.dshlag110.form.headerForm.controls.sellerName.setValue(this.dshlag110.deliveryNoteData.header.sellerName);
                        this.dshlag110.form.headerForm.controls.sellertaxID.setValue(this.dshlag110.deliveryNoteData.header.sellertaxID);
                        this.dshlag110.form.headerForm.controls.sellerPhoneNo.setValue(this.dshlag110.deliveryNoteData.header.sellerPhoneNo);
                        this.dshlag110.form.headerForm.controls.sellerEmail.setValue(this.dshlag110.deliveryNoteData.header.sellerEmail);
                        this.dshlag110.form.headerForm.controls.sellerAddress.setValue(this.dshlag110.deliveryNoteData.header.sellerAddress);
                        this.dshlag110.form.headerForm.controls.creatorName.setValue(this.dshlag110.deliveryNoteData.header.creatorName);
                        this.dshlag110.form.headerForm.controls.createDate.setValue(this.dshlag110.deliveryNoteData.header.createDate ?
                        this.dshlag110.deliveryNoteData.header.createDate.format('DD/MM/YYYY') : null);
                        this.dshlag110.form.headerForm.controls.transportRoute.setValue(this.dshlag110.deliveryNoteData.header.transportRoute);
                        this.dshlag110.form.headerForm.controls.trackingNo.setValue(this.dshlag110.deliveryNoteData.header.trackingNo);
                        this.dshlag110.form.headerForm.controls.isFulfillment.setValue(this.dshlag110.deliveryNoteData.header.isFulfillment);
                        this.dshlag110.form.headerForm.controls.logisticStatus.setValue(this.dshlag110.deliveryNoteData.header.logisticStatus);
                        this.dshlag110.form.headerForm.controls.remark.setValue(this.dshlag110.deliveryNoteData.header.remark);

                    } else {
                        this.inti();
                    }
                }
            }, error => {
                this.inti();
            }) ;
        }
    }

}
