import { Component, Renderer2 } from "@angular/core";
import { ActivatedRoute , Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UserCriteria, UserData, AttributeData, OtherModel, SearchAutoData, TempDimension, DimToActPage, UserAll } from "src/app/adm/model/admusr/admusr200.model";
import { TranslateService } from '@ngx-translate/core';
import { Admusr200Service } from "src/app/adm/service/admusr/admusr200.service";
import { Admusr100Service } from "src/app/adm/service/admusr/admusr100.service";
import { AdmusrLocalStorageService } from "src/app/adm/service/admusr/admusrLocalStorage.service";
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import * as moment from 'moment';
import { TranslateCalendarPipe } from "src/app/shared/pipes/translate-calendar.pipe";

@Component({
  selector: 'app-admusr200',
  templateUrl: './admusr200.component.html',
  styleUrls: ['./admusr200.component.scss']
})
export class Admusr200Component extends PermissionComponent {

  userCriteriaDDL: UserCriteria = {} as UserCriteria;
  userData: UserData = {} as UserData;
  tempUserData: UserData = {} as UserData;
  tempDimensionData: TempDimension = {} as TempDimension;
  otherModel: OtherModel = {} as OtherModel;
  searchAutoData: SearchAutoData = {} as SearchAutoData;
  userAll: Array<UserAll> = []  as Array<UserAll>;
  lableToggleBut : string = null;
  disablePermission: boolean;
  disableAtrDelete: boolean;

  IsSearchAdvanced: boolean;
  checkedSwitchCreateFb: boolean;
  checkedSwitchEditFb: boolean;
  checkedSwitchDeleteFb: boolean;
  checkedSwitchConfirmFb: boolean;
  checkedSwitchCreateIg: boolean;
  checkedSwitchEditIg: boolean;
  checkedSwitchDeleteIg: boolean;
  checkedSwitchConfirmIg: boolean;
  checkedSwitchCreateKl: boolean;
  checkedSwitchEditKl: boolean;
  checkedSwitchDeleteKl: boolean;
  checkedSwitchConfirmKl: boolean;
  statusPage: string;

  checkedAllCountryFlag: boolean;
  checkedAllSellerFlag: boolean;
  checkedAllWarehouseFlag: boolean;
  checkedAllSaleChannelFlag: boolean;
  checkedAllFlowTypeFlag: boolean;
  checkUserDuplicate:boolean = false;

  translateCalendarPipe = new TranslateCalendarPipe();

  userHeaderForm = new FormGroup({
    username: new FormControl('', Validators.required),
    userDesc: new FormControl('', Validators.required),
    userPass: new FormControl('', Validators.required),
    userCfPass: new FormControl('', Validators.required),
    profileId: new FormControl('', Validators.required),
    organization: new FormControl(''),
    organizationLev: new FormControl('',Validators.required),
    callCenter: new FormControl(false),
    email: new FormControl(''),
    btuPass: new FormControl(false),
    btuCfPass: new FormControl(false),
    phonNumber: new FormControl(''),
    contactName: new FormControl(''),
  });

  userAttributeForm = new FormGroup({
    atrClass: new FormControl('', Validators.required),
    atrClassDesc: new FormControl(''),
    atrValueCode: new FormControl('', Validators.required),
    atrValueCodeDesc: new FormControl(''),
    startDate: new FormControl(null, Validators.required),
    endDate: new FormControl(moment('2049-12-31').toDate(), Validators.required),
    value: new FormControl('', Validators.required),
  });


  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translateService: TranslateService,
    private admusr200Service: Admusr200Service,
    private admusr100Service: Admusr100Service,
    private localStorageService: AdmusrLocalStorageService,
    private router: Router,
    renderer2: Renderer2
  ) {
    super('P01003', renderer2);
    this.userData.userId = Number(this.route.snapshot.paramMap.get('userId'));
  }

  ngOnInit() {
    this.otherModel.requiredOrg = null;
    this.otherModel.setLocaleDate = this.translateCalendarPipe.transform();

    setTimeout(() => {
      this.admusr200Service.getUserCriteria().subscribe((res: UserCriteria) => {
        this.userCriteriaDDL = res;
        this.userCriteriaDDL.profileData.sort((a, b) => (a.value - b.value));
        if (this.userData.userId == 0 || this.userData.userId == null) {
        this.userData.attributeData = res.attributeData;
        }
      });
    }, 4000);

    // case create page
    if (this.userData.userId == 0) {

      this.userData.attributeData = [] as Array<AttributeData>;
      this.otherModel.dataNotFoundSalechannel = true;
      this.otherModel.dataNotFoundSeller = true;
      this.otherModel.dataNotFoundWarehouse = true;
      this.otherModel.dataNotFoundCountry = true;
      this.otherModel.dataNotFoundFloeType = true;
      this.otherModel.dataNotFoundGridAtr = true;
      this.otherModel.disableButSaveAtr = true;

      if (this.localStorageService.getUserData() == null) {
        this.admusr200Service.getcreateUserData().subscribe((res: UserData) => {
          this.userData = res;
          this.tempUserData = res;
          this.tempDimensionData.countryData = this.userData.countryData;
          this.tempDimensionData.sellerData = this.userData.sellerData;
          this.tempDimensionData.warehouseData = this.userData.warehouseData;
          this.tempDimensionData.flowTypeData = this.userData.flowTypeData;
          this.tempDimensionData.saleChannelData = this.userData.saleChannelData;
          this.checkCheckBooxAll();
        });
      } else {
        this.setDataLocalStorage();
      }

     //case edit page
    } else {
      this.otherModel.disabledSeePass = true;
      this.otherModel.showResult = true;
      this.otherModel.disabledCal = true;

      if (this.localStorageService.getUserData() == null) {
        this.admusr200Service.getUserData(this.userData.userId).subscribe((res: UserData) => {
          this.userData = res;
          this.setValueUserFormControl();
          this.tempDimensionData.countryData = this.userData.countryData;
          this.tempDimensionData.sellerData = this.userData.sellerData;
          this.tempDimensionData.warehouseData = this.userData.warehouseData;
          this.tempDimensionData.flowTypeData = this.userData.flowTypeData;
          this.tempDimensionData.saleChannelData = this.userData.saleChannelData;
          this.otherModel.disableButSaveUsr = true;
          this.checkCheckBooxAll();
          if (this.userData.attributeData.length == 0) {
            this.otherModel.dataNotFoundGridAtr = true;
          }
        });

      } else {
        this.setDataLocalStorage();
      }
    }

    this.userAttributeForm.valueChanges.subscribe(data => {

      if (this.userAttributeForm.valid) {
        this.otherModel.disableButSaveAtr = false;
      } else {
        this.otherModel.disableButSaveAtr = true;
      }

    });

    this.userAttributeForm.get("atrClass").valueChanges.subscribe(data => {

      this.userCriteriaDDL.atrClassData.filter(atr => {
        if (atr.value == data) {
          this.userCriteriaDDL.atrValueData = atr.atrValueData;
        }
      });
    });

    this.userAttributeForm.get("atrValueCode").valueChanges.subscribe(data => {
      let atrValueData = this.userCriteriaDDL.atrValueData.filter(atr => atr.value == this.userAttributeForm.get('atrValueCode').value);
      if (atrValueData.length > 0) {
        this.userAttributeForm.controls['atrValueCodeDesc'].setValue(atrValueData[0].label);
        if (atrValueData[0].value) {
          this.userAttributeForm.controls['value'].setValue(atrValueData[0].defaultValue);
        }
      }
    });

    this.userAttributeForm.get("startDate").valueChanges.subscribe(data => {
      this.otherModel.minEndDate = new Date(data);
      if (new Date(data) > new Date(this.userAttributeForm.get('endDate').value)) {
        this.userAttributeForm.controls['endDate'].setValue(null);
      }
    });

    this.userHeaderForm.get("userPass").valueChanges.subscribe(data => {

      if (data.length < 8) {
        this.otherModel.errorPass = true;
      }
      else {
        this.otherModel.errorPass = false;
      }

      if(data.length < this.userHeaderForm.get('userCfPass').value.length){
        this.userHeaderForm.controls['userCfPass'].setValue('');
      }

      if (data.length == 0) {
        this.otherModel.disabledSeePass = false;
      }

    });

    this.userHeaderForm.get("organizationLev").valueChanges.subscribe(data => {
        if(data == '200'){
          this.otherModel.requiredOrg = true;
          this.otherModel.disabledOrg = false;
        }else{
          this.otherModel.requiredOrg = null;
          this.otherModel.disabledOrg = true;
          this.userHeaderForm.controls['organization'].setValue(null);
        }
    });

    this.userHeaderForm.valueChanges.subscribe(data => {
      if(this.userHeaderForm.dirty){
        this.otherModel.disableButSaveUsr = false;
        this.userData.checkDirty = true;
      }
    });


    this.userHeaderForm.get("userCfPass").valueChanges.subscribe(data => {

      if (data != this.userHeaderForm.get('userPass').value && data != '') {
        this.otherModel.errorPassCf = true;
      } else {
        this.otherModel.errorPassCf = false;
      }

    });

    setTimeout(() => {
      this.checkPermission();
    }, 5000);

    let paramUserAll = {username:null, userDesc:null, profileId:[], organization: []}
    this.admusr100Service.getUserInquiry(paramUserAll).subscribe((res: Array<UserAll>) => {
      this.userAll = res;
    });
  }

  checkPermission() {

    let checkEdit = this.permissionData.accActionFalse.filter(act => act == '1');
    if (checkEdit.length > 0) {
      this.userHeaderForm.controls['userPass'].disable();
      this.userHeaderForm.controls['userCfPass'].disable();
      this.disablePermission = true;
    }

    let checkEditDelete = this.permissionData.accActionFalse.filter(act => act == '3');
    if (checkEditDelete.length > 0) {
      this.disableAtrDelete = true;
    }

  }

  setValueUserFormControl() {
    this.userHeaderForm.controls['username'].setValue(this.userData.username);
    this.userHeaderForm.controls['userDesc'].setValue(this.userData.userDesc);
    this.userHeaderForm.controls['userPass'].setValue(this.userData.userPass);
    this.userHeaderForm.controls['userCfPass'].setValue(this.userData.userPass);
    this.userHeaderForm.controls['profileId'].setValue(this.userData.profileId.toString());
    this.userHeaderForm.controls['organization'].setValue(this.userData.organization);
    this.userHeaderForm.controls['organizationLev'].setValue(this.userData.organizationLev);
    this.userHeaderForm.controls['callCenter'].setValue(this.userData.callCenter);
    this.userHeaderForm.controls['email'].setValue(this.userData.email);
    this.userHeaderForm.controls['phonNumber'].setValue(this.userData.phonNumber);
    this.userHeaderForm.controls['contactName'].setValue(this.userData.contactName);
    if(this.userHeaderForm.get('organizationLev').value == '200'){
      this.otherModel.requiredOrg = true;
      this.otherModel.disabledOrg = false;
    }else{
      this.otherModel.requiredOrg = null;
      this.otherModel.disabledOrg = true;
    }

    if (this.userHeaderForm.get('userCfPass').value != this.userHeaderForm.get('userPass').value && this.userHeaderForm.get('userCfPass').value != '') {
      this.otherModel.errorPassCf = true;
    } else {
      this.otherModel.errorPassCf = false;
    }

    if (this.userHeaderForm.get('userPass').value.length < 8) {
      this.otherModel.errorPass = true;
    }
    else {
      this.otherModel.errorPass = false;
    }
  }

  setDataLocalStorage() {
    if (this.localStorageService.getUserData().statusPage == 'EDITED') {
      this.localStorageService.updateActionByDim(null, null, null, 'EDIT');
    }

    this.userData = this.localStorageService.getUserData();

    if(this.userData.checkDirty == true){
      this.otherModel.disableButSaveUsr = false;
    }else{
      this.otherModel.disableButSaveUsr = true;
    }

    if (this.userData.attributeData == null || this.userData.attributeData.length == 0) {
      this.otherModel.dataNotFoundGridAtr = true;
    }
    this.setValueUserFormControl();
    this.checkCheckBooxAll();
    this.otherModel.showResult = true;
  }

  backBtn() {

    if (this.userHeaderForm.dirty || this.userData.checkDirty == true) {
      this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'ADMUSR200.M00067', detail: 'ADMUSR200.M00059' });
    } else {
      // this.router.navigate(['admusr/admusr100']);
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate(['admusr/admusr100']);
    });
    }

  }

  checkedAll(isChecker: any, flag) {
    this.userData.checkDirty = true;
    if (flag == "country") {
      this.userData.countryData.forEach((data) => {
        data.access = isChecker;
        this.otherModel.showResult = true;
        if (data.access == false) {
          this.otherModel.dataNotFoundCountry = true;
        } else {
          this.otherModel.dataNotFoundCountry = false;
        }
      });
    } else if (flag == "seller") {
      this.userData.sellerData.forEach((data) => {
        data.access = isChecker;
        this.otherModel.showResult = true;
        if (data.access == false) {
          this.otherModel.dataNotFoundSeller = true;
        } else {
          this.otherModel.dataNotFoundSeller = false;
        }
      });
    } else if (flag == "warehouse") {
      this.userData.warehouseData.forEach((data) => {
        data.access = isChecker;
        this.otherModel.showResult = true;
        if (data.access == false) {
          this.otherModel.dataNotFoundWarehouse = true;
        } else {
          this.otherModel.dataNotFoundWarehouse = false;
        }
      });
    } else if (flag == "salechannel") {
      this.userData.saleChannelData.forEach((data) => {
        data.access = isChecker;
        data.actionData.forEach(act => {
          act.access = isChecker
        });
        this.otherModel.showResult = true;
        if (data.access == false) {
          this.otherModel.dataNotFoundSalechannel = true;
        } else {
          this.otherModel.dataNotFoundSalechannel = false;
        }
      });
    } else {
      this.userData.flowTypeData.forEach((data) => {
        data.access = isChecker;
        data.actionData.forEach(act => {
          act.access = isChecker
        });
        this.otherModel.showResult = true;
        if (data.access == false) {
          this.otherModel.dataNotFoundFloeType = true;
        } else {
          this.otherModel.dataNotFoundFloeType = false;
        }
      });
    }
    this.otherModel.disableButSaveUsr = false;
  }

  checkEach(access, flag, index) {
    this.otherModel.disableButSaveUsr = false;
    this.otherModel.showResult = true;
    this.userData.checkDirty = true;
    let countFalse = 0;
    let countTrue = 0;
    if (flag == "country") {

      for (var i = 0; i < this.userData.countryData.length; i++) {
        if (this.userData.countryData[i].access == false) {
          this.otherModel.dataNotFoundCountry = true;
          this.checkedAllCountryFlag = false;
          countFalse++;
        }
        if (this.userData.countryData[i].access == true) {
          this.otherModel.dataNotFoundCountry = false;
          this.checkedAllCountryFlag = true;
          countTrue++;
        }
      }

      if (countFalse != this.userData.countryData.length) {
        this.otherModel.dataNotFoundCountry = false;
        this.checkedAllCountryFlag = false;
      }

      if (countTrue == this.userData.countryData.length) {
        this.otherModel.dataNotFoundCountry = false;
        this.checkedAllCountryFlag = true;
      }

    } else if (flag == "seller") {

      for (var i = 0; i < this.userData.sellerData.length; i++) {
        if (this.userData.sellerData[i].access == false) {
          this.otherModel.dataNotFoundSeller = true;
          this.checkedAllSellerFlag = false;
          countFalse++;
        }
        if (this.userData.sellerData[i].access == true) {
          this.otherModel.dataNotFoundSeller = false;
          this.checkedAllSellerFlag = true;
          countTrue++;
        }
      }
      if (countFalse != this.userData.sellerData.length) {
        this.otherModel.dataNotFoundSeller = false;
        this.checkedAllSellerFlag = false;
      }

      if (countTrue == this.userData.sellerData.length) {
        this.otherModel.dataNotFoundSeller = false;
        this.checkedAllSellerFlag = true;
      }

    } else if (flag == "warehouse") {

      for (var i = 0; i < this.userData.warehouseData.length; i++) {
        if (this.userData.warehouseData[i].access == false) {
          this.otherModel.dataNotFoundWarehouse = true;
          this.checkedAllWarehouseFlag = false;
          countFalse++;
        }
        if (this.userData.warehouseData[i].access == true) {
          this.otherModel.dataNotFoundWarehouse = false;
          this.checkedAllWarehouseFlag = true;
          countTrue++;
        }
      }

      if (countFalse != this.userData.warehouseData.length) {
        this.otherModel.dataNotFoundWarehouse = false;
        this.checkedAllWarehouseFlag = false;
      }

      if (countTrue == this.userData.warehouseData.length) {
        this.otherModel.dataNotFoundWarehouse = false;
        this.checkedAllWarehouseFlag = true;
      }
    } else if (flag == "salechannel") {

      for (var i = 0; i < this.userData.saleChannelData.length; i++) {
        if (this.userData.saleChannelData[i].access == false) {
          this.otherModel.dataNotFoundSalechannel = true;
          this.checkedAllSaleChannelFlag = false;
          countFalse++;
        }

        if (this.userData.saleChannelData[i].access == true) {
          this.otherModel.dataNotFoundSalechannel = false;
          this.checkedAllSaleChannelFlag = true;
          countTrue++;
        }
      }

      if (countFalse != this.userData.saleChannelData.length) {
        this.otherModel.dataNotFoundSalechannel = false;
        this.checkedAllSaleChannelFlag = false;
      }

      if (countTrue == this.userData.saleChannelData.length) {
        this.otherModel.dataNotFoundSalechannel = false;
        this.checkedAllSaleChannelFlag = true;
      }

      this.userData.saleChannelData[index].actionData.forEach(data => {
        data.access = access;
      });

    } else {

      for (var i = 0; i < this.userData.flowTypeData.length; i++) {
        if (this.userData.flowTypeData[i].access == false) {
          this.otherModel.dataNotFoundFloeType = true;
          this.checkedAllFlowTypeFlag = false;
          countFalse++;
        }

        if (this.userData.flowTypeData[i].access == true) {
          this.otherModel.dataNotFoundFloeType = false;
          this.checkedAllFlowTypeFlag = true;
          countTrue++;
        }
      }

      if (countFalse != this.userData.flowTypeData.length) {
        this.otherModel.dataNotFoundFloeType = false;
        this.checkedAllFlowTypeFlag = false;
      }

      if (countTrue == this.userData.flowTypeData.length) {
        this.otherModel.dataNotFoundFloeType = false;
        this.checkedAllFlowTypeFlag = true;
      }

      this.userData.flowTypeData[index].actionData.forEach(data => {
        data.access = access;
      });

    }
  }

  checkCheckBooxAll() {

    let countFalseCT = 0;
    let countTrueCT = 0;
    let countFalseSL = 0;
    let countTrueSL = 0;
    let countFalseWH = 0;
    let countTrueWH = 0;
    let countFalseSC = 0;
    let countTrueSC = 0;
    let countFalseFT = 0;
    let countTrueFT = 0;

    for (var i = 0; i < this.tempDimensionData.countryData.length; i++) {
      if (this.tempDimensionData.countryData[i].access == false) {
        this.otherModel.dataNotFoundCountry = true;
        this.checkedAllCountryFlag = false;
        countFalseCT++;
      }
      if (this.tempDimensionData.countryData[i].access == true) {
        this.otherModel.dataNotFoundCountry = false;
        this.checkedAllCountryFlag = true;
        countTrueCT++;
      }

    }

    if (countFalseCT != this.tempDimensionData.countryData.length) {
      this.otherModel.dataNotFoundCountry = false;
      this.checkedAllCountryFlag = false;
    }

    if (countTrueCT == this.tempDimensionData.countryData.length) {
      this.otherModel.dataNotFoundCountry = false;
      this.checkedAllCountryFlag = true;
    }


    for (var i = 0; i < this.tempDimensionData.sellerData.length; i++) {
      if (this.tempDimensionData.sellerData[i].access == false) {
        this.otherModel.dataNotFoundSeller = true;
        this.checkedAllSellerFlag = false;
        countFalseSL++;
      }
      if (this.tempDimensionData.sellerData[i].access == true) {
        this.otherModel.dataNotFoundSeller = false;
        this.checkedAllSellerFlag = true;
        countTrueSL++;
      }
    }
    if (countFalseSL != this.tempDimensionData.sellerData.length) {
      this.otherModel.dataNotFoundSeller = false;
      this.checkedAllSellerFlag = false;
    }

    if (countTrueSL == this.tempDimensionData.sellerData.length) {
      this.otherModel.dataNotFoundSeller = false;
      this.checkedAllSellerFlag = true;
    }

    for (var i = 0; i < this.tempDimensionData.warehouseData.length; i++) {
      if (this.tempDimensionData.warehouseData[i].access == false) {
        this.otherModel.dataNotFoundWarehouse = true;
        this.checkedAllWarehouseFlag = false;
        countFalseWH++;
      }
      if (this.tempDimensionData.warehouseData[i].access == true) {
        this.otherModel.dataNotFoundWarehouse = false;
        this.checkedAllWarehouseFlag = true;
        countTrueWH++;
      }
    }

    if (countFalseWH != this.tempDimensionData.warehouseData.length) {
      this.otherModel.dataNotFoundWarehouse = false;
      this.checkedAllWarehouseFlag = false;
    }

    if (countTrueWH == this.tempDimensionData.warehouseData.length) {
      this.otherModel.dataNotFoundWarehouse = false;
      this.checkedAllWarehouseFlag = true;
    }

    for (var i = 0; i < this.tempDimensionData.saleChannelData.length; i++) {
      if (this.tempDimensionData.saleChannelData[i].access == false) {
        this.otherModel.dataNotFoundSalechannel = true;
        this.checkedAllSaleChannelFlag = false;
        countFalseSC++;
      }

      if (this.tempDimensionData.saleChannelData[i].access == true) {
        this.otherModel.dataNotFoundSalechannel = false;
        this.checkedAllSaleChannelFlag = true;
        countTrueSC++;
      }
    }

    if (countFalseSC != this.tempDimensionData.saleChannelData.length) {
      this.otherModel.dataNotFoundSalechannel = false;
      this.checkedAllSaleChannelFlag = false;
    }

    if (countTrueSC == this.tempDimensionData.saleChannelData.length) {
      this.otherModel.dataNotFoundSalechannel = false;
      this.checkedAllSaleChannelFlag = true;
    }

    for (var i = 0; i < this.tempDimensionData.flowTypeData.length; i++) {
      if (this.tempDimensionData.flowTypeData[i].access == false) {
        this.otherModel.dataNotFoundFloeType = true;
        this.checkedAllFlowTypeFlag = false;
        countFalseFT++;
      }

      if (this.tempDimensionData.flowTypeData[i].access == true) {
        this.otherModel.dataNotFoundFloeType = false;
        this.checkedAllFlowTypeFlag = true;
        countTrueFT++;
      }
    }

    if (countFalseFT != this.tempDimensionData.flowTypeData.length) {
      this.otherModel.dataNotFoundFloeType = false;
      this.checkedAllFlowTypeFlag = false;
    }

    if (countTrueFT == this.tempDimensionData.flowTypeData.length) {
      this.otherModel.dataNotFoundFloeType = false;
      this.checkedAllFlowTypeFlag = true;
    }
  }


  searchAuto(eventName) {

    if (eventName == "country") {
      this.userData.countryData = this.tempDimensionData.countryData;
      this.userData.countryData = this.userData.countryData.filter(data => {
        return (data.countryDesc_eng.toString().toLowerCase().indexOf(this.searchAutoData.country.toLowerCase()) > -1)
        ||(data.countryDesc_local.toString().toLowerCase().indexOf(this.searchAutoData.country.toLowerCase()) > -1);
      });
    } else if (eventName == "seller") {
      this.userData.sellerData = this.tempDimensionData.sellerData;
      this.userData.sellerData = this.userData.sellerData.filter(data => {
        return (data.sellerDesc_eng.toString().toLowerCase().indexOf(this.searchAutoData.seller.toLowerCase()) > -1)
        || (data.sellerDesc_local.toString().toLowerCase().indexOf(this.searchAutoData.seller.toLowerCase()) > -1);
      });
    } else if (eventName == "warehouse") {
      this.userData.warehouseData = this.tempDimensionData.warehouseData;
      this.userData.warehouseData = this.userData.warehouseData.filter(data => {
        return (data.warehouseDesc_eng.toString().toLowerCase().indexOf(this.searchAutoData.warehouse.toLowerCase()) > -1)
        ||(data.warehouseDesc_local.toString().toLowerCase().indexOf(this.searchAutoData.warehouse.toLowerCase()) > -1);
      });
    } else if (eventName == "saleChannel") {
      this.userData.saleChannelData = this.tempDimensionData.saleChannelData;
      this.userData.saleChannelData = this.userData.saleChannelData.filter(data => {
        return (data.salechannelDesc_eng.toString().toLowerCase().indexOf(this.searchAutoData.saleChannel.toLowerCase()) > -1)
        ||(data.salechannelDesc_local.toString().toLowerCase().indexOf(this.searchAutoData.saleChannel.toLowerCase()) > -1);
      });
    } else {
      this.userData.flowTypeData = this.tempDimensionData.flowTypeData;
      this.userData.flowTypeData = this.userData.flowTypeData.filter(data => {
        return (data.flowTypeDesc_eng.toString().toLowerCase().indexOf(this.searchAutoData.flowType.toLowerCase()) > -1)
        ||(data.flowTypeDesc_local.toString().toLowerCase().indexOf(this.searchAutoData.flowType.toLowerCase()) > -1);
      });
    }
    this.checkCheckBooxAll();
  }

  saveUser() {

    if (this.userHeaderForm.valid
      && this.otherModel.errorPassCf == false
      && this.otherModel.errorPass == false
      && this.checkUserDuplicate == false
      && ((this.otherModel.requiredOrg == true && this.userHeaderForm.get('organization').value != null )
      || (this.otherModel.requiredOrg == null && this.userHeaderForm.get('organization').value == null ))) {

      this.setUserData();

      if (this.userData.userId == null) {

        //case save user
        this.admusr200Service.saveUser(this.userData).subscribe((res: number) => {
          if (res) {
            this.userData.userId = res;
            this.messageService.clear();
            this.messageService.add({
              key: 'save',
              severity: 'success',
              detail: 'ADMUSR200.M00060',
            });

            setTimeout(() => {
              this.localStorageService.clearUserData();
              window.location.href = 'admusr/admusr200/' + this.userData.userId;
            }, 1000);

          } else {
            this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMUSR200.M00058', detail: 'ADMUSR200.M00061' });
          }
        });
      } else {
        // case update user

        this.userData.countryData = this.tempDimensionData.countryData;
        this.userData.sellerData = this.tempDimensionData.sellerData;
        this.userData.warehouseData = this.tempDimensionData.warehouseData;
        this.userData.saleChannelData = this.tempDimensionData.saleChannelData;
        this.userData.flowTypeData = this.tempDimensionData.flowTypeData;
        this.checkCheckBooxAll();

        this.admusr200Service.updateUser(this.userData).subscribe((res: number) => {
          if (res) {
            this.userData.userId = res;
            this.messageService.clear();
            this.messageService.add({
              key: 'save',
              severity: 'success',
              detail: 'ADMUSR200.M00060',
            });

            setTimeout(() => {
              this.localStorageService.clearUserData();
              window.location.href = 'admusr/admusr200/' + this.userData.userId;
            }, 1000);

          } else {
            this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMUSR200.M00058', detail: 'ADMUSR200.M00061' });
          }
        });
      }
    } else {
      this.messageService.clear();
      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMUSR200.M00058', detail: 'ADMUSR200.M00062' });
    }

  }

  clickAfterAlert(){
    this.messageService.clear();
  }

  clickOkBack(message) {
    if (message == "แจ้งเตือน") {
      this.router.navigate(['admusr/admusr100']);
    }

  }

  setUserData() {

    this.userData.username = this.userHeaderForm.get('username').value;
    this.userData.userDesc = this.userHeaderForm.get('userDesc').value;
    this.userData.userPass = this.userHeaderForm.get('userPass').value;
    this.userData.userCfPass = this.userHeaderForm.get('userCfPass').value;
    this.userData.profileId = this.userHeaderForm.get('profileId').value;
    this.userData.organization = this.userHeaderForm.get('organization').value;
    this.userData.organizationLev = this.userHeaderForm.get('organizationLev').value;
    this.userData.callCenter = this.userHeaderForm.get('callCenter').value;
    this.userData.email = this.userHeaderForm.get('email').value;
    this.userData.phonNumber = this.userHeaderForm.get('phonNumber').value;
    this.userData.contactName = this.userHeaderForm.get('contactName').value;
  }

  editAction(dataName, dimensionType, index, data) {
    this.setUserData();
    this.userData.dimToActPage = {} as DimToActPage;
    this.userData.dimToActPage.userId = this.userData.userId;
    this.userData.dimToActPage.indexDim = index;
    this.userData.dimToActPage.dimName = dataName;
    this.userData.dimToActPage.dimType = dimensionType;
    this.userData.dimToActPage.actionData = data.actionData;
    this.userData.statusPage = 'EDIT'
    this.localStorageService.addUserData(this.userData);
    window.location.href = 'admusr/admusr300';
  }

  /* ------------------------------------------set Attribute------------------------------------------------*/

  addAttribute() {
    this.otherModel.displayDialogAtr = true;
    this.otherModel.statusDialogAtr = 'ADD';
    this.otherModel.minStartDate = new Date();
    this.userData.checkDirty == true;
    this.userAttributeForm.controls['endDate'].setValue(moment('2049-12-31').toDate());
  }

  editAttribute(index) {
    this.otherModel.displayDialogAtr = true;
    this.otherModel.statusDialogAtr = 'EDIT';
    this.otherModel.minStartDate = new Date();
    this.otherModel.indexEditAtr = index;
    this.otherModel.disableMandatory = this.userData.attributeData[index].mandatory;

    this.userAttributeForm.controls['atrClass'].setValue(this.userData.attributeData[index].attributeClass);
    this.userAttributeForm.controls['atrValueCode'].setValue(this.userData.attributeData[index].attributeCode);
    this.userAttributeForm.controls['startDate'].setValue(new Date(this.userData.attributeData[index].startDate));
    this.userAttributeForm.controls['endDate'].setValue(new Date(this.userData.attributeData[index].endDate));
    this.userAttributeForm.controls['value'].setValue(this.userData.attributeData[index].value);
    if(new Date(this.userData.attributeData[index].endDate) < new Date() ){
      this.otherModel.disableEndDate = true;
    }else{
      this.otherModel.disableEndDate = false;
    }
  }

  deleteAttribute(index) {

    if (this.userData.attributeData[index].userAtrId) {
      if (this.userData.deleteUserAtr == null) {
        this.userData.deleteUserAtr = [] as Array<number>;
      }
      this.userData.deleteUserAtr.push(this.userData.attributeData[index].userAtrId);
    }
    this.userData.attributeData.splice(index, 1);

    if (this.userData.attributeData.length == 0) {
      this.otherModel.dataNotFoundGridAtr == true;
    }

    this.userData.checkDirty == true;

  }

  saveAttribute() {

    let atrClassData = this.userCriteriaDDL.atrClassData.filter(atr => atr.value == this.userAttributeForm.get('atrClass').value);
    this.userAttributeForm.controls['atrClassDesc'].setValue(atrClassData[0].label);

    // check case single period and overlab in period
    let singlePeriod = this.userCriteriaDDL.atrClassData.filter(data => data.value == this.userAttributeForm.get('atrClass').value);
    let checkPeriod = this.userData.attributeData.filter((data,index) =>
      index != this.otherModel.indexEditAtr
      && data.attributeClass == this.userAttributeForm.get('atrClass').value
      && data.attributeCode == this.userAttributeForm.get('atrValueCode').value
      && new Date(this.userAttributeForm.get('startDate').value) <= new Date(data.endDate))

    let ceeckOneCoed  = this.userData.attributeData.filter((data,index) =>
      data.attributeClass == this.userAttributeForm.get('atrClass').value
      && index != this.otherModel.indexEditAtr
      && singlePeriod[0].singlePeriod == 1);

    if (singlePeriod[0].singlePeriod == 1 && checkPeriod.length > 0){ //single period and overlab in period
      this.messageService.clear();
      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMUSR200.M00058', detail: 'ADMUSR200.M00066' });
    } else if(singlePeriod[0].singlePeriod == 1 && checkPeriod.length == 0 && ceeckOneCoed.length > 0){ //single period and same code
      this.messageService.clear();
      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMUSR200.M00058', detail: 'ADMUSR200.M00065' });
    } else if(singlePeriod[0].singlePeriod == 0 && checkPeriod.length > 0){ //not single period and overlab in period
      this.messageService.clear();
      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMUSR200.M00058', detail: 'ADMUSR200.M00066' });
    }else{
      this.otherModel.disableButSaveUsr = false;
      if (this.otherModel.statusDialogAtr == 'ADD') {

        if (!this.userData.attributeData) {
          this.userData.attributeData = [] as Array<AttributeData>
        }

        this.userData.attributeData.push({
          attributeClass: this.userAttributeForm.get('atrClass').value,
          attributeClassDesc: this.userAttributeForm.get('atrClassDesc').value,
          attributeCode: this.userAttributeForm.get('atrValueCode').value,
          attributeCodeDesc: this.userAttributeForm.get('atrValueCodeDesc').value,
          userAtrId: null,
          endDate: this.userAttributeForm.get('endDate').value,
          startDate: this.userAttributeForm.get('startDate').value,
          value: this.userAttributeForm.get('value').value,
          singlePeriod: singlePeriod[0].singlePeriod,
          mandatory: null,
          disableEdit: false
        });

      } else { //case edit
        if(this.otherModel.disableMandatory == true){
          this.userData.attributeData[this.otherModel.indexEditAtr].attributeClass = this.userAttributeForm.get('atrClass').value;
          this.userData.attributeData[this.otherModel.indexEditAtr].attributeClassDesc = this.userAttributeForm.get('atrClassDesc').value;
          this.userData.attributeData[this.otherModel.indexEditAtr].attributeCode = this.userAttributeForm.get('atrValueCode').value;
          this.userData.attributeData[this.otherModel.indexEditAtr].attributeCodeDesc = this.userAttributeForm.get('atrValueCodeDesc').value;
          this.userData.attributeData[this.otherModel.indexEditAtr].startDate = this.userAttributeForm.get('startDate').value;
        }
        this.userData.attributeData[this.otherModel.indexEditAtr].endDate = this.userAttributeForm.get('endDate').value;
        this.userData.attributeData[this.otherModel.indexEditAtr].value = this.userAttributeForm.get('value').value;
      }
      this.otherModel.disableButSaveUsr = false;
      this.userAttributeForm.reset();
      this.otherModel.displayDialogAtr = false;
    }
  }

  cancelArt() {
    this.userAttributeForm.reset();
    this.otherModel.displayDialogAtr = false;
  }

  checkUserDup(){

    let checkUser = this.userAll.filter(user => user.username == this.userHeaderForm.get('username').value);

    if(checkUser.length > 0){
      this.checkUserDuplicate = true;
    }else{
      this.checkUserDuplicate = false;
    }

  }
}
