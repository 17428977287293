import { Component, OnInit ,Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeAgentService } from 'src/app/shared/services/employee-agent.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { AgentInfoDetail,SearchAgenInquiry } from 'src/app/shared/model/agent.model';
import { AgentInquiryModel,DataListModel,ExportAgentInq } from './model/empagt100.model';
import { SalesOrderrService } from 'src/app/shared/services/sales-order-service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';

@Component({
  selector: 'app-empagt100',
  templateUrl: './empagt100.component.html',
  styleUrls: ['./empagt100.component.scss']
})
export class Empagt100Component extends PermissionComponent {

  agentInquiryModel= {} as AgentInquiryModel;
  ddlAgentModel = {} as DataListModel;
  public exportAgrntInq : ExportAgentInq = {} as ExportAgentInq;
  translateLablePipe = new TranslateLablePipe();

  searchAgentGroupForm = new FormGroup({
    searchAgentCode: new FormControl(),
    searchTeamLeader: new FormControl(),
    searchAgentLevel: new FormControl(),
    searchPhoneNumber: new FormControl(),
    searchEmail: new FormControl(),
    searchShoptype: new FormControl(),
    searchStatus1: new FormControl(),
    searchStatus2: new FormControl(),
  });

  constructor(public route: Router,renderer2: Renderer2, private empagtService: EmployeeAgentService , private translateService: TranslateService , private exportExcelService: ExportExcelService, private parameterService: ParameterService,private saleorderService: SalesOrderrService) {
    super('P70001', renderer2);
    this.saleorderService.getparameterMasterAgent().subscribe(response => {
      this.ddlAgentModel.masterTeam = JSON.parse(JSON.stringify(response));      
    }, err => {
      console.log(err);
    });

    this.parameterService.getParameterDetailToLableValueCode(1401).subscribe(response=>{
      this.ddlAgentModel.shoptype = JSON.parse(JSON.stringify(response));

    });

    this.parameterService.getParameterDetailToLableValueCode(1400).subscribe(response=>{
      this.ddlAgentModel.agentlevel = JSON.parse(JSON.stringify(response));

    });
    

  }
  
  ngOnInit() {
    this.agentInquiryModel.hideSearch = true;
    this.agentInquiryModel.dataNotFound = true;
    this.agentInquiryModel.agentInquirySeach = {} as SearchAgenInquiry;
    this.agentInquiryModel.firstRow = 0;

  }
  
  searchHide() {
    this.agentInquiryModel.hideSearch = this.agentInquiryModel.hideSearch == true ? false : true;
  }

  ClearData() {
    this.agentInquiryModel.Search = null;
    this.agentInquiryModel.agentinfo = null;
    this.agentInquiryModel.dataNotFound = true;
    this.searchAgentGroupForm.reset();

  }

  searchAgentInquiry() {
    this.agentInquiryModel.agentInquirySeach.agentCode = this.searchAgentGroupForm.controls['searchAgentCode'].value;
    this.agentInquiryModel.agentInquirySeach.email = this.searchAgentGroupForm.controls['searchEmail'].value;
  this.agentInquiryModel.agentInquirySeach.phoneNumber = this.searchAgentGroupForm.controls['searchPhoneNumber'].value;
  this.agentInquiryModel.agentInquirySeach.teamLeader = this.searchAgentGroupForm.controls['searchTeamLeader'].value;
    this.agentInquiryModel.agentInquirySeach.agentLevel = this.searchAgentGroupForm.controls['searchAgentLevel'].value;
    this.agentInquiryModel.agentInquirySeach.shoptype = this.searchAgentGroupForm.controls['searchShoptype'].value;
    if(this.searchAgentGroupForm.controls['searchStatus1'].value){
      this.agentInquiryModel.agentInquirySeach.agentFlag = 'true';
    }else if(this.searchAgentGroupForm.controls['searchStatus2'].value){
      this.agentInquiryModel.agentInquirySeach.agentFlag = 'false';
    }
    this.searchAgentGroupForm.controls['searchStatus1'].setValue(null);
    this.searchAgentGroupForm.controls['searchStatus2'].setValue(null);
    this.empagtService.getAgentInquiry(this.agentInquiryModel.agentInquirySeach).subscribe(res=>{
      this.agentInquiryModel.agentinfo = JSON.parse(JSON.stringify(res));
      this.agentInquiryModel.defalutAgentinfo = JSON.parse(JSON.stringify(res));
      if(this.agentInquiryModel.agentinfo.length == 0){
        this.agentInquiryModel.dataNotFound = true;
      }else{
        this.agentInquiryModel.dataNotFound = false;
        this.agentInquiryModel.firstRow = 0;
      }
    })
    
  }

  filterAgent() {
    this.agentInquiryModel.dataTableSearchShort = this.agentInquiryModel.defalutAgentinfo;
    if(this.agentInquiryModel.Search){
      this.agentInquiryModel.agentinfo = this.agentInquiryModel.dataTableSearchShort.filter(data => {
        data.agentLevel = this.translateLablePipe.transform(data.agentLevelDesc_eng,data.agentLevelDesc_local);
        if(data.agentCode && data.agentCode.toLowerCase().includes(this.agentInquiryModel.Search.toLowerCase()) ||
        data.agentName && data.agentName.toLowerCase().includes(this.agentInquiryModel.Search.toLowerCase()) ||
        data.agentPhone && data.agentPhone.toLowerCase().includes(this.agentInquiryModel.Search.toLowerCase()) ||
        data.agentEmail && data.agentEmail.toLowerCase().includes(this.agentInquiryModel.Search.toLowerCase()) ||
        data.agentLevel && data.agentLevel.toLowerCase().includes(this.agentInquiryModel.Search.toLowerCase()) ||
        data.agentTeamleaderName && data.agentTeamleaderName.toLowerCase().includes(this.agentInquiryModel.Search.toLowerCase())
        ){
          return true;
        }
        return false;
    });
  }
  else{
    this.agentInquiryModel.agentinfo = this.agentInquiryModel.dataTableSearchShort;
  }

  
    if (this.agentInquiryModel.agentinfo.length == 0) {
      this.agentInquiryModel.dataNotFound = true;
    }
    else {
      this.agentInquiryModel.dataNotFound = false;
      this.agentInquiryModel.firstRow = 0;
    }
  }

  regisPage() {
    this.route.navigate(['/empagt/empagt100']);
  }

  agentInfoPage() {
    this.route.navigate(['/empagt/empagt200']);
  }

  checkMobile(){
    if (window.screen.width >= 200 && window.screen.width < 1025) {
      this.agentInquiryModel.toggleSearch = true;
    } else if (window.screen.width > 1024){
      this.agentInquiryModel.toggleSearch = false;
    }
  }

  exportReport(){
    let exportAgent = [];
    this.agentInquiryModel.agentinfo.forEach(datatable =>{
      exportAgent.push({
        agent_id: datatable.agentCode,
        agent_name: datatable.agentName,
        agent_lavel: this.translateLablePipe.transform(datatable.agentLevelDesc_eng,datatable.agentLevelDesc_local),
        agent_leader: datatable.agentTeamleaderName,
        agent_phone_number: datatable.agentPhone,
        agent_email: datatable.agentEmail
      })
    });

    let subTitle = [];
    subTitle.push([this.translateService.instant('EMPAGT100.M00000')]);

    let header: any = {
        agent_id: this.translateService.instant('EMPAGT100.M00003'),
        agent_name: this.translateService.instant('EMPAGT100.M00014'),
        agent_lavel: this.translateService.instant('EMPAGT100.M00008'),
        agent_leader: this.translateService.instant('EMPAGT100.M00009'),
        agent_phone_number: this.translateService.instant('EMPAGT100.M00004'),
        agent_email: this.translateService.instant('EMPAGT100.M00005')
    }
    this.exportExcelService.exportAsExcelFile(exportAgent, this.translateService.instant('EMPAGT100.M00000'), header, null, subTitle);
  }

}
