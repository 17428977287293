import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Ewlsel100Component } from './ewlsel/ewlsel100/ewlsel100.component';

const routes: Routes = [
  {path : 'ewl/ewlsel100',component: Ewlsel100Component}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EwlRoutingModule { }
