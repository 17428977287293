import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { ExcRoutingModule } from './exc-routing.module';
import { Excexc100Component } from './excexc/excexc100/excexc100.component';
import { Excexc200Component } from './excexc/excexc200/excexc200.component';

@NgModule({
  declarations: [Excexc100Component, Excexc200Component],
  imports: [
    CommonModule,
    ExcRoutingModule,
    SharedModule
  ]
})
export class ExcModule { }
