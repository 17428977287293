import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SellerService } from 'src/app/shared/services/seller.service';
import { TranslateService } from '@ngx-translate/core';
import { sellerInquiry, sellerInquiryPage, sellerResponse} from './model/serser.model';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { th } from 'src/app/shared/constant/date.constant';
import * as moment from 'moment';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-serser100',
  templateUrl: './serser100.component.html',
  styleUrls: ['./serser100.component.scss'],
})
export class Serser100Component implements OnInit {

  public sellerModel: sellerInquiry = {} as sellerInquiry;
  public sellerPage: sellerInquiryPage = {} as sellerInquiryPage;
  public sellerTable: Array<sellerInquiry> = [] as Array<sellerInquiry>
  public th: any;
  translateLablePipe = new TranslateLablePipe();
  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  sellerForm = new FormGroup({
    sellerCode: new FormControl(),
    sellerDesc: new FormControl(),
    sellerTaxId: new FormControl(),
    sellerCountryConnect: new FormControl(),
    sellerTel: new FormControl(),
    sellerEmail: new FormControl(),
    pkgKlangOne: new FormControl(),
    statPkgDate: new FormControl(),
    endPkgDate: new FormControl(),
  });

  searchForm = new FormGroup({
    keySearch: new FormControl(),
  });

  constructor(
    private sellerService: SellerService,
    private translateService: TranslateService,
    private exportExcelService: ExportExcelService) {
  }

  ngOnInit() {
    this.sellerPage.cols = [
      { field: 'sellerCode', header: 'SERSER100.M00003', type: 'string', link: '/serser/serser200/' },
      { field: 'sellerDesc', header: 'SERSER100.M00040', type: 'string' },
      { field: 'sellerTaxId', header: 'SERSER100.M00005', type: 'string' },
      { field: 'sellerTel', header: 'SERSER100.M00006', type: 'string' },
      { field: 'sellerEmail', header: 'SERSER100.M00009', type: 'string' },
      { field: 'pkgKlangOne', header: 'SERSER100.M00038', type: 'string' },
      { field: 'sellerCountryConnect', header: 'SERSER100.M00039', type: 'string' },
      { field: 'statPkgDate', header: 'SERSER100.M00041', type: 'string', position: 'center' },
      { field: 'endPkgDate', header: 'SERSER100.M00042', type: 'string', position: 'center' },
    ];
    this.sellerPage.disabledReport = true;
    this.sellerPage.formatDate = this.translateCalendar;
    let pkgOne : any = [];
    this.sellerService.getSellerInquiry(this.sellerModel).subscribe(response => {
      JSON.parse(JSON.stringify(response)).forEach(element => {
        if(element.pkgKlangOne != null){
          pkgOne.push({
            label: element.pkgKlangOne,
            value: element.pkgKlangOne,
            engDesc: element.pkgKlangOne,
            localDesc: element.pkgKlangOne,
          })
        }
      });
      this.sellerPage.pkgOne = pkgOne;
    }, err => {
      console.log(err);
    });

    this.searchForm.valueChanges.subscribe( val => {
      if(this.searchForm.valid) {
        this.advanceSearchFunction();
      } else {
          this.sellerPage.sellerData = this.sellerPage.sellerTemp;
      }
    });

  }

  filterData() {
    this.sellerPage.searchDisabled = true;
    this.sellerModel.sellerCode = this.sellerForm.controls['sellerCode'].value;
    this.sellerModel.sellerDesc = this.sellerForm.controls['sellerDesc'].value;
    this.sellerModel.sellerTaxId = this.sellerForm.controls['sellerTaxId'].value;
    this.sellerModel.sellerCountryConnect = this.sellerForm.controls['sellerCountryConnect'].value;
    this.sellerModel.sellerTel = this.sellerForm.controls['sellerTel'].value;
    this.sellerModel.sellerEmail = this.sellerForm.controls['sellerEmail'].value;
    this.sellerModel.pkgKlangOne = this.sellerForm.controls['pkgKlangOne'].value;
    if(this.sellerForm.controls['statPkgDate'].value != null){
      this.sellerModel.statPkgDate = moment(this.sellerForm.controls['statPkgDate'].value).format('DD/MM/YYYY');
    } else {
      this.sellerModel.statPkgDate = this.sellerForm.controls['statPkgDate'].value;
    }
    if(this.sellerForm.controls['endPkgDate'].value != null){
      this.sellerModel.endPkgDate = moment(this.sellerForm.controls['endPkgDate'].value).format('DD/MM/YYYY');
    } else {
      this.sellerModel.endPkgDate = this.sellerForm.controls['endPkgDate'].value;
    }

    this.sellerService.getSellerInquiry(this.sellerModel).subscribe((response:sellerResponse[]) => {
      if(response != null){
        response.forEach(data => {
          data.sellerDesc = this.translateLablePipe.transform(data.sellerDesc_eng,data.sellerDesc_local);
        })
      }
      this.sellerPage.sellerData = JSON.parse(JSON.stringify(response));
      this.sellerPage.sellerTemp = this.sellerPage.sellerData;

        if(this.sellerPage.sellerData != null){
          this.sellerPage.disabledReport = false;
        } else {
          this.sellerPage.disabledReport = true;
        }
    }, err => {
      console.log(err);
    });
  }

  advanceSearchFunction() {
    if(this.searchForm.valid) {
      const key_search: string = this.searchForm.get('keySearch').value;
      if(key_search) {
        this.sellerPage.sellerData = this.sellerPage.sellerTemp.filter((seller: sellerInquiry) => {
          const concatValue: string = `${seller.sellerCode}${seller.sellerDesc}${seller.sellerTaxId}${seller.sellerCountryConnect}${seller.sellerTel}${seller.sellerEmail}${seller.pkgKlangOne}${seller.statPkgDate}${seller.endPkgDate}`;
          return concatValue.toLowerCase().indexOf(key_search.toLowerCase()) !== -1;
        });
    }
    else{
      this.sellerPage.sellerData = this.sellerPage.sellerTemp;
    }
  }

  }

  exportReport() {
    let sub_title = [];
    sub_title.push([this.translateService.instant('SERSER100.M00043')]);
    let header: any = {
      sellerCode: this.translateService.instant('SERSER100.M00003'),
      sellerDesc: this.translateService.instant('SERSER100.M00040'),
      sellerTaxId: this.translateService.instant('SERSER100.M00005'),
      sellerTel: this.translateService.instant('SERSER100.M00006'),
      sellerEmail: this.translateService.instant('SERSER100.M00009'),
      pkgKlangOne: this.translateService.instant('SERSER100.M00038'),
      sellerCountryConnect: this.translateService.instant('SERSER100.M00039'),
      statPkgDate: this.translateService.instant('SERSER100.M00041'),
      endPkgDate: this.translateService.instant('SERSER100.M00042'),
    };
    let export_data_list = [];
    this.sellerPage.sellerTemp.forEach((seller: sellerInquiry) => {
      let export_data = {
        sellerCode: seller.sellerCode,
        sellerDesc: seller.sellerDesc,
        sellerTaxId: seller.sellerTaxId,
        sellerTel: seller.sellerTel,
        sellerEmail: seller.sellerEmail,
        pkgKlangOne: seller.pkgKlangOne,
        sellerCountryConnect: seller.sellerCountryConnect,
        statPkgDate: seller.statPkgDate,
        endPkgDate: seller.endPkgDate
      }
      export_data_list.push(export_data);
    });
    this.exportExcelService.exportAsExcelFile(export_data_list, this.translateService.instant('SERSER100.M00043'), header, null, sub_title);
    export_data_list.shift();
  }

  changeSearch(){
    if(this.sellerForm.dirty){
      this.sellerPage.searchDisabled = false;
    } else {
      this.sellerPage.searchDisabled = true;
    }
  }

  clearData() {
    this.sellerPage.searchDisabled = false;
    this.sellerForm.reset();
    this.searchForm.reset();
    if(this.sellerPage.sellerData == null){
      this.sellerPage.disabledReport = true;
    } else {
      this.sellerPage.disabledReport = false;
    }
  }

}
