import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';

import { Admprm100Component } from './admprm/admprm100/admprm100.component';
import { Admprm200Component } from './admprm/admprm200/admprm200.component';
import { Admprm300Component } from './admprm/admprm300/admprm300.component';
import { Admatr100Component } from './admatr/admatr100/admatr100.component';
import { Admatr200Component } from './admatr/admatr200/admatr200.component';
import { Admpro100Component } from './admpro/admpro100/admpro100.component';
import { Admpro200Component}  from './admpro/admpro200/admpro200.component';
import { Admpro300Component}  from './admpro/admpro300/admpro300.component'; 
import { Admusr100Component } from './admusr/admusr100/admusr100.component';
import { Admusr110Component } from './admusr/admusr110/admusr110.component';
import { Admusr200Component } from './admusr/admusr200/admusr200.component';
import { Admusr300Component } from './admusr/admusr300/admusr300.component';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  { path: 'admprm/admprm100', component: Admprm100Component },
  { path: 'admprm/admprm200/:pmhtbhtbno', component: Admprm200Component },
  { path: 'admprm/admprm300/:pmdtbdtbno', component: Admprm300Component },
  { path: 'admprm/admprm300/:pmdtbdtbno/:pmdtbdtbdentcd', component: Admprm300Component },
  { path: 'admprm/admprm300/:pmdtbdtbno/:pmdtbdtbdentcd/:pmdtbddms', component: Admprm300Component },
  { path: 'admatr/admatr100', component: Admatr100Component},
  { path: 'admatr/admatr200', component: Admatr200Component},
  { path: 'admatr/admatr200/:acvclscd', component: Admatr200Component},
  { path: 'admpro/admpro100', component: Admpro100Component},
  { path: 'admpro/admpro200', component: Admpro200Component},
  { path: 'admpro/admpro200/:profId', component: Admpro200Component},
  { path: 'admpro/admpro300', component: Admpro300Component},
  { path: 'admusr/admusr100', component: Admusr100Component},
  { path: 'admusr/admusr110', component: Admusr110Component},
  { path: 'admusr/admusr200', component: Admusr200Component},
  { path: 'admusr/admusr200/:userId', component: Admusr200Component},
  { path: 'admusr/admusr300', component: Admusr300Component}, 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  
})

export class AdmRoutingModule { }
