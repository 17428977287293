import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Homhom100Component } from './homhom100/homhom100.component';


const routes: Routes = [
  { path: 'homhom/homhom100', component: Homhom100Component }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomRoutingModule { }
