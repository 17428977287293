import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [
  { path: '', loadChildren: () => import('./lan/lan.module').then(m => m.LanModule) },
  { path: '', loadChildren: () => import('./core/core.module').then(m => m.CoreModule) }
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false,
        scrollPositionRestoration: 'top'  // if change 'top' to 'enable'  mean when going backwards it restores the last position
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
