import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MenuComponent } from '../menu/menu.component';
import { MenuV2Component } from '../menu-v2/menu-v2.component';
import { MenuItem, SelectItem } from 'primeng/api';
import { AuthDataModel } from '../../model/authentication.model';
import { Menu } from '../../model/menu.model';
import { AdministrationMenuService } from '../../services/administration-menu.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ParameterService } from '../../services/parameter.service';
import { AdministrationLanguageService } from '../../services/administration-language.service';
import { ParameterCountryList } from '../../model/parameter.model';
import { DropDownListModel } from '../../model/dropdownlist.model';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {

  // @ViewChild(MenuComponent, {static: true}) menuComponent: MenuComponent;
  @ViewChild(MenuV2Component, { static: true }) menuComponent: MenuV2Component;

  @Output() menuSize = new EventEmitter();

  public isShowMenu: boolean;
  public isMenu: boolean;
  visibleSidebar;


  public userfn: any;
  public userln: any;
  public selcd: any;

  public user = {} as AuthDataModel;
  items: MenuItem[];
  public menuData: any = [];
  public groupMenuData: Array<Menu>;
  public bgColor: any;

  public optionCountry: Array<ParameterCountryList> = [] as Array<ParameterCountryList>;
  public selectedCountry: ParameterCountryList = {} as ParameterCountryList;
  public Country: string;
  labelSignOut: String;
  public checkDisplay: boolean;

  @Input() languageCode:string;
  constructor(private router: Router,
    private autService: AuthenticationService,
    private admmnuService: AdministrationMenuService,
    private admLangService: AdministrationLanguageService,
    private translateService: TranslateService,
    private titleService: Title,
    private sharedService: SharedService) {
    this.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel;
    this.Country = localStorage.getItem('languageData') as string;
  }

  ngOnInit() {
    this.admLangService.getvparameterCountry().subscribe(response => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.Country) {
        let rusult = this.optionCountry.filter(data => data.value.entryCode.toUpperCase() == this.Country.toUpperCase());
        this.selectedCountry = rusult[0];
      } else {
        let rusult = this.optionCountry.filter(data => data.value.entryDefault == 'Y');
        this.selectedCountry = rusult[0];
      }
      this.useLanguage(this.selectedCountry.value.entryCode);
    }, err => {
      console.log(err);
    })
    if (window.screen.width <= 640) {
      this.isMenu = false;
      this.checkDisplay = false;
    }
    else {
      this.isMenu = true;
      this.checkDisplay = true;
    }
    let userDesc = this.user.userDesc ? this.user.userDesc : '';
    let userName = this.user.userName ? this.user.userName : this.user.userDesc;
    let email = this.user.email ? this.user.email : '';
    this.admmnuService.getLabel(this.Country).subscribe(response =>{
      this.menuData = [];
      this.menuData.push({ label: response.labelName + ' : ' + userName });
      this.menuData.push({ label: response.labelDesc + ' : ' + userDesc });
      this.menuData.push({ label: response.labelEmail + ' : ' + email });
      this.menuData.push({ separator: true });
      this.labelSignOut= response.labelSignOut
    });
    this.admmnuService.getMenuUser(this.Country).subscribe((res: Menu[]) => {
      this.groupMenuData = res;
      this.groupMenuData.forEach((element: Menu) => {
        const menusTemp: any = { label: '', icon: '', routerLink: '', ordid: '', items: [] };
        menusTemp.label = element.menuDesc;
        menusTemp.command = () => {
          this.sendMenuName(menusTemp.label);
        };
        menusTemp.icon = element.menuIcon;
        menusTemp.ordid = element.menuOrbid;
        menusTemp.routerLink = element.menuRout;
        if (element.subMenu.length > 0) {
          element.subMenu.forEach(submenu => {
            delete menusTemp["routerLink"];
            const itemTemp: any = { label: '', icon: '', routerLink: '', ordid: '', items: [] };
            itemTemp.label = submenu.menuDesc;
            itemTemp.routerLink = submenu.menuRout;
            itemTemp.icon = submenu.menuIcon;
            itemTemp.ordid = submenu.menuOrbid;
            menusTemp.items.push(itemTemp);
            menusTemp.items.sort((a: { ordid: number; }, b: { ordid: number; }) => a.ordid - b.ordid);
            if (element.subMenu.length > 0) {
              delete itemTemp["items"];
              submenu.subMenu.forEach(onSubmenu => {
                const itemSubTemp: any = {};
                itemSubTemp.label = onSubmenu.menuDesc;
                itemSubTemp.routerLink = onSubmenu.menuRout;
                itemSubTemp.icon = onSubmenu.menuIcon;
                itemSubTemp.ordid = onSubmenu.menuOrbid;
                itemTemp.items.push(itemSubTemp);
                itemTemp.items.sort((a: { ordid: number; }, b: { ordid: number; }) => a.ordid - b.ordid);
              });
            }
          });
        }
        else {
          delete menusTemp["items"];
        }
        this.menuData.push(menusTemp);
      });
      this.menuData.sort((a: { ordid: number; }, b: { ordid: number; }) => a.ordid - b.ordid);
      this.menuData.push({ separator: true });
      this.menuData.push({ label: this.labelSignOut, icon: "fas fa-sign-out-alt", command: () => this.backTologin() });
    });
    this.autService.ckeckExpOrRefreshToken();
  }

  sendMenuName(name: string): void {
    localStorage.setItem('menuName', name);
  }

  backTologin() {
    this.autService.doLogout().subscribe(() => {
      localStorage.clear();
    });
    this.router.navigate(['/']);
  }

  clickFunction() {
    const result = this.menuComponent.visibleSidebarFunction();
    if (result) {
      this.menuSize.emit('0');
    } else {
      this.menuSize.emit('0');
    }
    return result;
  }

  selectedCountryFunction(event) {
    this.useLanguage(event.value.value.entryCode);
    localStorage.setItem('languageData', event.value.value.entryCode);
    window.location.reload();
  }

  useLanguage(language: string) {
    this.sharedService.getLanguageJsonfile(language).subscribe(response =>{
      if(response){
        this.translateService.use(language);
      }
    }, err =>{
      setTimeout(() => {
        this.translateService.use('EN');
      }, 1500);
    });
  }

}
