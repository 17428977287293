import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-export-pdf',
  templateUrl: './export-pdf.component.html',
  styleUrls: ['./export-pdf.component.scss']
})
export class ExportPdfComponent implements OnInit {
  @Input()pdfSrc: string;
  @Output() rtnDialog = new EventEmitter();
  show: boolean = true;

  constructor() { 
  }

  ngOnInit() {
  }

  closeDialog(){
    this.show = false
    this.rtnDialog.emit(false);
  }
}
