import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DocumentData, DocumentDataModel, DocumentTypeSaleOrderRequest } from 'src/app/shared/components/search-document/search-document/model/search-document-model'
import { CreditNoteService } from 'src/app/shared/services/creditNote.service';
import { TranslateService } from '@ngx-translate/core';
import { SalesOrderrService } from 'src/app/shared/services/sales-order-service';
import { DataTabelSaleOrderInq, OR100Search } from 'src/app/ord/model/ord100.model';

@Component({
  selector: 'app-search-document',
  templateUrl: './search-document.component.html',
  styleUrls: ['./search-document.component.scss']
})
export class SearchDocumentComponent implements OnInit {

  @Input() displayDocument: boolean;
  @Input() requestParam: any;
  @Input() documentDeleteData = [] as Array<DocumentDataModel>;
  @Output() sendDocumentData: EventEmitter<DocumentData> = new EventEmitter<DocumentData>();
  hiddenButton: boolean = true;
  hightLight: any;
  dataNotFoundGridTab: boolean;
  documentData = [] as Array<DocumentData>;
  searchDocumentData = [] as Array<DocumentData>;
  documentTypeSaleOrder = {} as DocumentTypeSaleOrderRequest;
  tempDocumentData = {} as DocumentData;
  returnToCutomerNo: string;
  returnToSeller: string;
  saleOrderNumber: string;
  documentType: string;
  constructor(private creditNoteService: CreditNoteService) { }

  ngOnInit() {
    if(this.requestParam.documentType != '300'){
      this.creditNoteService.getReturnDocumentInquiry(this.requestParam.seller, this.requestParam.warehouse).subscribe((response: DocumentData[]) => {
        this.documentType = this.requestParam.documentType == '100' ? '2' : '1';
        this.documentData = response.filter(data => data.document_type_code == this.documentType);
        this.searchDocumentData = this.documentData;
        if (this.documentDeleteData) {
          this.documentDeleteData.forEach(element => {
            let checkDup = response.filter(data => data.return_req_no == element.documentNo);
            if (checkDup.length == 0) {
              let momentVariable = moment(element.documentDate, 'YYYY-MM-DD');
              let stringDate = momentVariable.format('DD/MM/YYYY');
              this.documentData.push({
                warehouse_code: this.requestParam.warehouse,
                return_req_no: element.documentNo,
                return_req_date: stringDate,
                flow_type_code: element.flowTypeCode,
                flow_type_desc_eng: element.flowTypeDesc_eng,
                flow_type_desc_local: element.flowTypeDesc_local,
                sale_order_ref: element.saleOrderNo,
                returning_cost: null,
                return_confime_no: null,
                return_confime_date: null,
                no_of_line: element.totalQty,
                document_type_code: this.documentType,
                document_type_desc_eng: null,
                document_type_desc_local: null,
              })
            }
          });
        }
        if (this.documentData.length == 0) {
          this.dataNotFoundGridTab = true;
        }
      }, err => {
        console.log(err);
      });
    }else{  
        this.documentTypeSaleOrder.country = this.requestParam.country;
        this.documentTypeSaleOrder.warehouseCode = this.requestParam.warehouse;
        this.documentTypeSaleOrder.sellerCode = this.requestParam.seller;
        this.creditNoteService.getDocumentTypeSaleOrder(this.documentTypeSaleOrder).subscribe(response=>{
          this.documentType = '3';
          this.documentData = JSON.parse(JSON.stringify(response));
          this.documentData.forEach(response=>{
            let momentVariable = moment(response.return_req_date, 'YYYY-MM-DD');
            let stringDate = momentVariable.format('DD/MM/YYYY');
            response.return_req_date = stringDate;
            response.document_type_code = this.documentType;
            this.searchDocumentData = this.documentData;
            if (this.documentDeleteData) {
              this.documentDeleteData.forEach(element => {
                let checkDup = response.sale_order_ref == element.documentNo ? true : false;
                if (!checkDup) {
                  let momentVariable = moment(element.documentDate, 'YYYY-MM-DD');
                  let stringDate = momentVariable.format('DD/MM/YYYY');
                  let itemIndex = this.documentData.findIndex(item => item.return_req_no == element.documentNo);
                  this.documentData[itemIndex].no_of_line = element.totalQty;
                  this.documentData[itemIndex].return_req_date = stringDate;
                }
              });
            }
          });
          if(this.documentData.length == 0){
            this.dataNotFoundGridTab = true;
          }
        });
    }   
  }

  save(): void {
    this.sendDocumentData.emit(this.tempDocumentData)
    this.displayDocument = false;
  }

  close() {
    this.displayDocument = false;
    this.sendDocumentData.emit();
  }

  selectRow(data) {
    this.tempDocumentData = data;
    this.hightLight = data;
    this.hiddenButton = false;
  }

  SearchAuto() {
    this.documentData = this.searchDocumentData;
    if (this.returnToCutomerNo || this.returnToSeller || this.saleOrderNumber) {

      this.returnToCutomerNo = this.returnToCutomerNo == undefined ? '' : this.returnToCutomerNo;
      this.returnToSeller = this.returnToSeller == undefined ? '' : this.returnToSeller;
      this.saleOrderNumber = this.saleOrderNumber == undefined ? '' : this.saleOrderNumber;
      if (this.returnToSeller) {
        this.documentData = this.documentData.filter(data => {
          if (data.document_type_code == '1') {
            return data.return_req_no.toString().toLowerCase().indexOf(this.returnToSeller.toLowerCase()) > -1
          }
        });
      } else if (this.returnToCutomerNo) {
        this.documentData = this.documentData.filter(data => {
          if (data.document_type_code == '2') {
            return data.return_req_no.toString().toLowerCase().indexOf(this.returnToCutomerNo.toLowerCase()) > -1
          }
        });
      }

      this.documentData = this.documentData.filter(data => data.sale_order_ref.toString().toLowerCase().indexOf(this.saleOrderNumber.toLowerCase()) > -1);

      if (this.documentData.length == 0) {
        this.dataNotFoundGridTab = true;
      } else {
        this.dataNotFoundGridTab = false;
      }

    }
  }
}
