import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { truncateSync } from 'fs';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { date_format, date_format_system, th } from 'src/app/shared/constant/date.constant';
import { DropDownListModel } from 'src/app/shared/model/dropdownlist.model';
import { DeleteSalePriceRequest, SalePriceInquiryModel, SalePriceInquiryRequest } from 'src/app/shared/model/sales-price.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { PriceSalePriceService } from 'src/app/shared/services/price-sale-price.service';
import { Prisal100Model, FormModel } from '../model/prisal100.model';

@Injectable({
    providedIn: 'root'
})

export class Prisal100Service {

    prisal100: Prisal100Model = {} as Prisal100Model;

    constructor(private prisalService: PriceSalePriceService,
        private autService: AuthenticationService,
        private fb: FormBuilder) {
        this.inti();
    }

    inti(): void {
        this.prisal100.menu_code = 'P50001';
        this.prisal100.page_code = 'prisal100';
        this.prisal100.page_name = 'ราคาขายสินค้า (Sale Price)';

        this.prisal100.form_model = {} as FormModel;
        // Criterial Form
        this.prisal100.form_model.criterial_form = this.fb.group({
            country: [null, Validators.required]
        });
        // Search Form
        this.prisal100.form_model.search_form = this.fb.group({
            item_code: [null],
            item_name: [null],
            sale_channel: [null],
            seller: [null],
            freeShipping: [null],
            start_date: [null],
            end_date: [null],
            period: [["2"]]
        });

        //Advance Search Form
        this.prisal100.form_model.advance_search_form = this.fb.group({
            key: [null, Validators.required]
        });

        this.prisal100.date = {};
        this.prisal100.date.locale = th;
        this.prisal100.date.date_format = date_format;

        this.prisal100.data_list = {};
        this.prisal100.data_list.checkbox_data = {};
        this.prisal100.data_list.checkbox_data.period_list = [
            { label: 'PRISAL100.M00019', value: '1', isChecked: false },
            { label: 'PRISAL100.M00020', value: '2', isChecked: false },
            { label: 'PRISAL100.M00021', value: '3', isChecked: false }];

        this.prisal100.data_list.country_data = [{label:'ไม่พบข้อมูล', value: '', engDesc:'No Data Found', localDesc:'ไม่พบข้อมูล'}];
        this.prisal100.data_list.sale_channel_data = [{label:'ไม่พบข้อมูล', value: '', engDesc:'No Data Found', localDesc:'ไม่พบข้อมูล'}];

        this.prisal100.sale_price_list = [];
        this.prisal100.searchFormSummitFlag = true;

    }

    get load(): Prisal100Model {
        return this.prisal100;
    }

    loadComponanceData(model: Prisal100Model) {
        this.autService.getParameterWmsCountry().subscribe((response: Array<DropDownListModel>) => {

            model.data_list.country_data = response;
            // model.data_list.country_data.push({label: 'กรุณาเลือกประเทศที่ต้องการเพื่อดูรายละเอียด', value: null});
            // Check country has 1 record then auto set value
            if (model.data_list.country_data.length === 1) {
                model.form_model.criterial_form.controls['country'].setValue(model.data_list.country_data[0].value);
                model.form_model.criterial_form.get('country').disable();
                this.autService.getParameterSaleChannel(model.data_list.country_data[0].value).subscribe((response: Array<DropDownListModel>) => {

                    model.data_list.sale_channel_data = response;

                    this.prisal100.searchFormSummitFlag = false;
                    // model.data_list.sale_channel_data.push({label: 'ค้นหาช่องทางการขาย', value: null});
                    if (model.data_list.sale_channel_data.length === 1) {
                        model.form_model.search_form.controls['sale_channel'].setValue(model.data_list.sale_channel_data[0].value);
                        model.form_model.search_form.get('sale_channel').disable();
                    } else if (model.data_list.country_data.length === 0) {
                        // model.form_model.search_form.get('country').setValue(null);
                        model.form_model.search_form.get('sale_channel').disable();
                    } else {
                        // model.form_model.search_form.get('country').setValue(null);
                        model.form_model.search_form.get('sale_channel').enable(); 
                    }
    
                }, err => {
                    console.log(err);
                });
            } else if (model.data_list.country_data.length === 0) {
                // model.form_model.criterial_form.get('country').setValue(null);
                model.form_model.criterial_form.get('country').disable();
            } else {
                // model.form_model.criterial_form.get('country').setValue(null);
                model.form_model.criterial_form.get('country').enable();
            }
        }, err => {
            console.log(err);
        });
        
    }

    searchFunction(): Observable<Array<SalePriceInquiryModel>> {
        let salePriceInquiry: SalePriceInquiryRequest = {} as SalePriceInquiryRequest;
        salePriceInquiry.country = this.prisal100.form_model.criterial_form.get('country').value;
        salePriceInquiry.seller = this.prisal100.form_model.search_form.get('seller').value;
        salePriceInquiry.itemCode = this.prisal100.form_model.search_form.get('item_code').value;
        salePriceInquiry.itemName = this.prisal100.form_model.search_form.get('item_name').value;
        salePriceInquiry.saleChanel = this.prisal100.form_model.search_form.get('sale_channel').value;
        salePriceInquiry.free_shipment = this.prisal100.form_model.search_form.get('freeShipping').value;
        if (this.prisal100.form_model.search_form.get('start_date').value) {
            salePriceInquiry.startDate = moment(this.prisal100.form_model.search_form.get('start_date').value).format(date_format_system);
        } else {
            salePriceInquiry.startDate = '';
        }

        if (this.prisal100.form_model.search_form.get('end_date').value) {
            salePriceInquiry.endDate = moment(this.prisal100.form_model.search_form.get('end_date').value).format(date_format_system);
        } else {
            salePriceInquiry.endDate = '';
        }

        if (this.prisal100.form_model.search_form.get('period').value) {
            salePriceInquiry.period = this.prisal100.form_model.search_form.get('period').value[0];
        } else {
            salePriceInquiry.period = '';
        }

        return this.prisalService.getPriceSaleInquiry(salePriceInquiry);
    }

    deleteFunction(deleteList: DeleteSalePriceRequest): Observable<any> {
        return this.prisalService.delSalePrice(deleteList);
    }
}