import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdRoutingModule } from './ord-routing.module';
import { Ord100Component } from './ordsor/ordsor100/ord100component';
import { Ord200Component } from './ordsor/ordsor200/ord200.component';

import { SharedModule } from '../shared/shared.module';
import { Ord300Component } from './ordsor/ordsor300/ord300.component';
import { Apierrhis100Component } from './apierrhis/apierrhis100/apierrhis100.component';


@NgModule({
    declarations: [
        Ord100Component,
        Ord200Component,
        Ord300Component,
        Apierrhis100Component
    ],
    imports: [
      CommonModule,
      OrdRoutingModule,
      SharedModule
    ]
  })
export class OrdModule { }