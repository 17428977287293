import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreditNoteMaintenancePage, CreditNoteModel, DocumentDataModel, DocumentData, ItemRequest, ItemToReturnModel, DecimalData, HeaderDataDDL } from '../model/acccdn';
import { CreditNoteService } from 'src/app/acc/service/acccdn';
import { MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import * as moment from 'moment';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { TranslateService } from '@ngx-translate/core';
import { ParameterService } from 'src/app/shared/services/parameter.service'
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { SalesOrderrService } from 'src/app/shared/services/sales-order-service';

@Component({
  selector: 'app-acccdn200',
  templateUrl: './acccdn200.component.html',
  styleUrls: ['./acccdn200.component.scss']
})
export class Acccdn200Component extends PermissionComponent {

  public cnDataAllPage: CreditNoteMaintenancePage = {} as CreditNoteMaintenancePage;
  public requestParamSearchDoc: any;
  public user: any;
  public decimalData: DecimalData = {} as DecimalData;
  selectedRowData: any;
  translateLablePipe = new TranslateLablePipe();
  flagChenge : boolean;
  flagVatSO : boolean;
  flagDiffVatAmt: boolean = false;
  saleOrderDetail:any ;
  SOData:DocumentData;
  constructor(private creditNoteService: CreditNoteService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private autService: AuthenticationService,
    private parameterWmsSerive: ParameterWmsSerive,
    private translateService: TranslateService,
    private parameterService: ParameterService,
    private decimalService: DecimalService,
    private saleorderService: SalesOrderrService,
    renderer2: Renderer2) {
    super('P40001', renderer2);
    this.user = JSON.parse(localStorage.getItem('userData'));
    this.cnDataAllPage.creditNoteHeaderId = this.route.snapshot.paramMap.get('creditNoteHeaderId');
    this.cnDataAllPage.decimal = 0;
  }

  ngOnInit() {
  
    this.cnDataAllPage.creditNoteData = {} as CreditNoteModel;
    this.cnDataAllPage.tempHeaderDataDDL = {} as HeaderDataDDL;
    this.creditNoteService.getParameterPortal(4400, null).subscribe((response) => {
      this.cnDataAllPage.documentTypeData = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.parameterService.getDataParameterDetail(4402, '1').subscribe((response: any) => {
      this.cnDataAllPage.checkReturnAmount = response.pmdtbdtxtv1;
    }, err => {
      console.log(err);

    });

    // case create page
    if (this.cnDataAllPage.creditNoteHeaderId == null) {
      this.cnDataAllPage.creditNoteData = {} as CreditNoteModel;
      this.cnDataAllPage.creditNoteData.documentData = [] as Array<DocumentDataModel>;
      this.cnDataAllPage.creditNoteData.creditNoteDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      this.cnDataAllPage.creditNoteData.updateUser = this.user.userName;
      this.cnDataAllPage.creditNoteData.createBy = this.user.userName;
      this.cnDataAllPage.dataNotFoundGridTab1 = true;
      this.cnDataAllPage.dataNotFoundGridTab2 = true;
      this.cnDataAllPage.disableBtuSave = true;
      this.cnDataAllPage.disableBtuAdd = true;
      this.cnDataAllPage.disableCancelBtu = true;
      this.cnDataAllPage.disableConfirmBtu = true;
      this.cnDataAllPage.disableDeleteBtu = true;
      this.cnDataAllPage.creditNoteData.excludingVatAmt = 0;
      this.cnDataAllPage.creditNoteData.vatAmt = 0;
      this.cnDataAllPage.creditNoteData.includingVatAmt = 0;
      this.cnDataAllPage.numberOfDocument = 0;
      this.getCountryData();
      this.parameterService.getvparameterDetailToLable(4401).subscribe(response => {
        let status = JSON.parse(JSON.stringify(response));
        let statusFilter = status.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == '100');
        this.cnDataAllPage.creditNoteData.statusDesc_eng = statusFilter[0].engDesc;
        this.cnDataAllPage.creditNoteData.statusDesc_local = statusFilter[0].localDesc;
      }, err => {
        console.log(err);
      });

      // case edit page
    } else {

      this.getCreditNoteData();
      this.cnDataAllPage.dataNotFoundGridTab2 = true;
    }

    setTimeout(() => {
      this.checkPermission();
    }, 1200);

  }

  checkPermission(){
    let checkPermission = this.permissionData.accActionFalse.filter(act =>act == "1");
    if(checkPermission.length > 0){
      this.cnDataAllPage.disablePermission = true;
      this.cnDataAllPage.disableCheckbox = true;
    }

    this.btuChangeStatus();
  }

  eventChangeDDL(data, eventName) {
    if (eventName == 'changeCountry') {

      if (this.cnDataAllPage.creditNoteData.documentData.length == 0) {
        this.cnDataAllPage.tempHeaderDataDDL.country = data;
        this.changeCountry();
        this.cnDataAllPage.creditNoteData.warehouseCode = null;
        this.cnDataAllPage.creditNoteData.sellerCode = null;
        this.cnDataAllPage.creditNoteData.vatCode = null;
      } else {
        this.cnDataAllPage.nameChangDDL = eventName;
      }

    } else if (eventName == 'changeWarehouse') {
      this.cnDataAllPage.disableHdrStatusOpen = false;

      if (this.cnDataAllPage.creditNoteData.documentData.length == 0) {
        this.cnDataAllPage.tempHeaderDataDDL.warehouseCode = data;
        this.changeWarehouse();
        this.cnDataAllPage.creditNoteData.sellerCode = null;
      } else {
        this.cnDataAllPage.nameChangDDL = eventName;
      }

    } else if (eventName == 'changeSeller') {
      this.cnDataAllPage.disableHdrStatusOpen = false;

      if (this.cnDataAllPage.creditNoteData.documentData.length == 0) {
        this.cnDataAllPage.tempHeaderDataDDL.sellerCode = data;
        this.changeSeller();
      } else {
        this.cnDataAllPage.nameChangDDL = eventName;
      }

    } else if (eventName == 'changeType') {
      this.cnDataAllPage.disableHdrStatusOpen = false;


      if (this.cnDataAllPage.creditNoteData.documentData.length == 0) {
        this.cnDataAllPage.tempHeaderDataDDL.documentTypeCode = data;
        this.changeType();
      } else {
        this.cnDataAllPage.nameChangDDL = eventName;
      }

    }
    this.checkDisableBtuAdd();
  }

  getCountryData() {
    this.autService.getParameterWmsCountry().subscribe(response => {
      this.cnDataAllPage.countryData = JSON.parse(JSON.stringify(response));
      if (this.cnDataAllPage.countryData.length == 1) {
        this.cnDataAllPage.creditNoteData.country = this.cnDataAllPage.countryData[0].value;
        this.getCurrency(this.cnDataAllPage.creditNoteData.country);
        this.changeCountry();
        this.cnDataAllPage.disabledCountry = true;
      }else if(this.cnDataAllPage.countryData.length == 0){
        this.cnDataAllPage.disabledCountry = true;
      }
    });
  }

  changeCountry() {
    this.getCurrency(this.cnDataAllPage.creditNoteData.country);
    this.getWarehouse();
    this.getDefaultVatData();
    this.getDecimal(this.cnDataAllPage.creditNoteData.country);
  }

  getDecimal(country) {
    this.parameterService.getvprmdtltolableByCountry(104, country).subscribe(response => {
      this.cnDataAllPage.decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.purchasePriceDecimal = this.cnDataAllPage.decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == '1');
      this.decimalData.sellingPriceDecimal = this.cnDataAllPage.decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == '2');
      this.decimalData.roundingMethod = this.cnDataAllPage.decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == '3');
      this.decimalData.roundToValue = this.cnDataAllPage.decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == '4');
      this.cnDataAllPage.decimal = this.decimalData.sellingPriceDecimal[0].value.pmdtbdnumv1;
      this.cnDataAllPage.method = this.decimalData.roundingMethod[0].value.pmdtbdtxtv1
      this.cnDataAllPage.roundToDecimal = this.decimalData.roundToValue[0].value.pmdtbdnumv1;
    }, err => {
      console.log(err);

    });
  }

  getCurrency(country) {
    this.parameterWmsSerive.getParameterWmsBytableNoAndCode(105, country).subscribe((responseParam) => {
      let respones = JSON.parse(JSON.stringify(responseParam));
      this.parameterWmsSerive.getParameterWmsBytableNoAndCode(9146, respones[0].parameterData.parameter_v6).subscribe((currencyResult) => {
        let currency = JSON.parse(JSON.stringify(currencyResult));
        this.cnDataAllPage.creditNoteData.currency_eng = currency[0].engDesc;
        this.cnDataAllPage.creditNoteData.currency_local = currency[0].localDesc;
      });
    });
  }

  getWarehouse() {
    this.autService.getParameterWmsWareHouse(this.cnDataAllPage.creditNoteData.country).subscribe(response => {
      this.cnDataAllPage.warehouseData = JSON.parse(JSON.stringify(response));
      if (this.cnDataAllPage.warehouseData.length == 1) {
        this.cnDataAllPage.creditNoteData.warehouseCode = this.cnDataAllPage.warehouseData[0].value;
        this.cnDataAllPage.disabledWarehouse = true;
        this.changeWarehouse();
      } else {
        if(this.cnDataAllPage.warehouseData.length == 0){
          this.cnDataAllPage.disabledWarehouse = true;     
        }else{ 
          if (this.cnDataAllPage.creditNoteHeaderId == null) {         
            this.cnDataAllPage.disabledWarehouse = false;
          }
        }
      }
    }, err => {
      console.log(err);
    });
  }

  changeWarehouse() {
    let selectData = this.cnDataAllPage.warehouseData.filter(data => data.value == this.cnDataAllPage.creditNoteData.warehouseCode)
    this.cnDataAllPage.creditNoteData.warehouseDesc_eng = selectData[0].engDesc;
    this.cnDataAllPage.creditNoteData.warehouseDesc_local = selectData[0].localDesc;
    this.cnDataAllPage.creditNoteData.warehouseDesc = this.translateLablePipe.transform(selectData[0].engDesc,selectData[0].localDesc);
    this.requestParamSearchDoc = { country: this.cnDataAllPage.creditNoteData.country, seller: this.cnDataAllPage.creditNoteData.sellerCode, warehouse: this.cnDataAllPage.creditNoteData.warehouseCode, documentType: this.cnDataAllPage.creditNoteData.documentTypeCode }
    this.getSeller();
  }

  getSeller() {
    this.autService.getParameterWmsSeller(this.cnDataAllPage.creditNoteData.country, this.cnDataAllPage.creditNoteData.warehouseCode).subscribe(response => {
      this.cnDataAllPage.sellerData = JSON.parse(JSON.stringify(response));
      if (this.cnDataAllPage.sellerData.length == 1) {
        this.cnDataAllPage.creditNoteData.sellerCode = this.cnDataAllPage.sellerData[0].value;
        this.cnDataAllPage.disabledSeller = true;
        this.changeSeller();
      } else {
        if(this.cnDataAllPage.sellerData.length == 0){
          this.cnDataAllPage.disabledSeller = true;
        }
      }
    })
  }

  changeSeller() {
    let selectData = this.cnDataAllPage.sellerData.filter(data => data.value == this.cnDataAllPage.creditNoteData.sellerCode);
    this.cnDataAllPage.creditNoteData.sellerDesc_eng = selectData[0].engDesc;
    this.cnDataAllPage.creditNoteData.sellerDesc_local = selectData[0].localDesc;
    this.cnDataAllPage.creditNoteData.sellerDesc = this.translateLablePipe.transform(selectData[0].engDesc,selectData[0].localDesc);
    this.requestParamSearchDoc = { country: this.cnDataAllPage.creditNoteData.country, seller: this.cnDataAllPage.creditNoteData.sellerCode, warehouse: this.cnDataAllPage.creditNoteData.warehouseCode, documentType: this.cnDataAllPage.creditNoteData.documentTypeCode }
  }

  getVatData() {
    this.creditNoteService.getParameterPortal(200, this.cnDataAllPage.creditNoteData.country).subscribe((response) => {
      this.cnDataAllPage.vatData = JSON.parse(JSON.stringify(response));
      if (this.cnDataAllPage.creditNoteHeaderId != null) {
        let vatSelect = this.cnDataAllPage.vatData.filter(data => data.value == this.cnDataAllPage.creditNoteData.vatCode)
        this.cnDataAllPage.vatValue = vatSelect[0].parameterData.pmdtbdnumv1;
      }
    }, err => {
      console.log(err);
    });
  }

  getDefaultVatData() {
    this.creditNoteService.getParameterPortal(200, this.cnDataAllPage.creditNoteData.country).subscribe((response) => {
      this.cnDataAllPage.vatData = JSON.parse(JSON.stringify(response));
      this.setDefaultVat();
      if (this.cnDataAllPage.creditNoteHeaderId != null) {
        let vatSelect = this.cnDataAllPage.vatData.filter(data => data.value == this.cnDataAllPage.creditNoteData.vatCode)
        this.cnDataAllPage.vatValue = vatSelect[0].parameterData.pmdtbdnumv1;
      }
    }, err => {
      console.log(err);
    });
  }

  changeVat() {
    this.cnDataAllPage.nameChangDDL = 'changeVat';
    let vatSelect = this.cnDataAllPage.vatData.filter(data => data.value == this.cnDataAllPage.creditNoteData.vatCode)
    this.cnDataAllPage.vatValue = vatSelect[0].parameterData.pmdtbdnumv1;
    this.requestParamSearchDoc = { country: this.cnDataAllPage.creditNoteData.country, seller: this.cnDataAllPage.creditNoteData.sellerCode, warehouse: this.cnDataAllPage.creditNoteData.warehouseCode, documentType: this.cnDataAllPage.creditNoteData.documentTypeCode }
    if (this.cnDataAllPage.creditNoteData.documentData.length > 0) {
      this.cnDataAllPage.creditNoteData.vatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.excludingVatAmt * this.cnDataAllPage.vatValue, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
      this.cnDataAllPage.creditNoteData.includingVatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.excludingVatAmt + this.cnDataAllPage.creditNoteData.vatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
      this.cnDataAllPage.creditNoteData.documentData.forEach(data => {
        data.vatAmt = Number(this.decimalService.getRoundingDecimal(data.excludingVatAmt * this.cnDataAllPage.vatValue, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
        data.includingVatAmt = Number(this.decimalService.getRoundingDecimal(data.excludingVatAmt + data.vatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
      });

    }
    this.checkDisableBtuAdd();
    if (this.cnDataAllPage.creditNoteData.statusCode == '100') {
      this.cnDataAllPage.disableBtuSave = false;
    }
    this.flagChenge = true;
  }

  changeType() {
    this.requestParamSearchDoc = { country: this.cnDataAllPage.creditNoteData.country, seller: this.cnDataAllPage.creditNoteData.sellerCode, warehouse: this.cnDataAllPage.creditNoteData.warehouseCode, documentType: this.cnDataAllPage.creditNoteData.documentTypeCode }
  }

  checkNote() {
    if (this.cnDataAllPage.creditNoteData.statusCode =='100' ) 
    {
      if (this.cnDataAllPage.creditNoteData.note && this.flagChenge == true )
      {
        this.cnDataAllPage.disableBtuSave = false; 
      }
      else if (this.cnDataAllPage.creditNoteData.note && this.flagChenge != true )
      {
        this.cnDataAllPage.disableBtuSave = false; 
      }
      else if (!this.cnDataAllPage.creditNoteData.note && this.flagChenge != true )
      {
        this.cnDataAllPage.disableBtuSave = true; 
      }
    }
  }

  getCreditNoteData() {
    this.creditNoteService.getCreditNoteData(Number(this.cnDataAllPage.creditNoteHeaderId)).subscribe((response: CreditNoteModel) => {
      setTimeout(() => {
        this.cnDataAllPage.creditNoteData = response;
        this.cnDataAllPage.tempNote = this.cnDataAllPage.creditNoteData.note;
        this.cnDataAllPage.creditNoteData.documentData = response.documentData;
        
          if(response.documentTypeCode == "300"){
            this.cnDataAllPage.disableVat = true;
          }
      
        this.cnDataAllPage.creditNoteData.documentData.forEach(data =>{
          if(data.returnMonetDate != null){
            data.returnMonetDate = moment(data.returnMonetDate).format('YYYY-MM-DD HH:mm:ss');
            data.returnMonetDateData = new Date(moment(data.returnMonetDate).format('YYYY-MM-DD HH:mm:ss'));
          }        
        });
        this.cnDataAllPage.numberOfDocument = this.cnDataAllPage.creditNoteData.documentData.length;
        this.cnDataAllPage.creditNoteData.country = response.country
        this.requestParamSearchDoc = { country: this.cnDataAllPage.creditNoteData.country, seller: this.cnDataAllPage.creditNoteData.sellerCode, warehouse: this.cnDataAllPage.creditNoteData.warehouseCode, documentType: this.cnDataAllPage.creditNoteData.documentTypeCode }
        this.getCountryData();
        this.getDecimal(this.cnDataAllPage.creditNoteData.country);
        this.getCurrency(this.cnDataAllPage.creditNoteData.country);
        this.getWarehouse();
        this.getSeller();
        this.getVatData();
        this.checkDisableByStatus();
        this.btuChangeStatus();
        this.cnDataAllPage.creditNoteData.dtlIdDeleteData = [] as Array<number>
        this.cnDataAllPage.disableBtuSave = true;
      }, 1500);
    }, err => {
      console.log(err);
    });
  }

  checkDisableByStatus() {
    this.cnDataAllPage.disableInfo = true;
    if (this.cnDataAllPage.creditNoteData.statusCode == '100') { // Open

      if (this.cnDataAllPage.creditNoteData.documentData.length == 0) {
        this.cnDataAllPage.disableDeleteBtu = true;
      } else {
        this.cnDataAllPage.disableDeleteBtu = false;
      }

      this.cnDataAllPage.disableBtuSave = false;
      this.cnDataAllPage.disableBtuAdd = false;
      this.cnDataAllPage.disableHdrStatusOpen = false;
      this.cnDataAllPage.disableCancelBtu = false;
      this.cnDataAllPage.disableConfirmBtu = false;
      this.cnDataAllPage.disableDeleteBtu = true;
      this.cnDataAllPage.disabledCountry = true;
      this.cnDataAllPage.disabledWarehouse = true;
      this.cnDataAllPage.disabledSeller = true;
      this.cnDataAllPage.disableNote = false;

    } else { // Confirm And Cencel

      this.cnDataAllPage.disableBtuSave = true;
      this.cnDataAllPage.disableBtuAdd = true;
      this.cnDataAllPage.disableCancelBtu = true;
      this.cnDataAllPage.disableConfirmBtu = true;
      this.cnDataAllPage.disableDeleteBtu = true;
      this.cnDataAllPage.disableCheckbox = true;
      this.cnDataAllPage.disableHdrStatusOpen = true;
      this.cnDataAllPage.disabledCountry = true;
      this.cnDataAllPage.disabledWarehouse = true;
      this.cnDataAllPage.disabledSeller = true;
      this.cnDataAllPage.disableNote = true;
    }
  }

  addDocument() {
    this.cnDataAllPage.displayDocument = true;
  }

  deleteDocument() {
    this.cnDataAllPage.creditNoteData.dtlIdDeleteData = [] as Array<number>;
    this.cnDataAllPage.documentDeleteData = [] as Array<DocumentDataModel>;
    //Calculate Vat Cerdit Note
    this.cnDataAllPage.documentDeleteData = this.cnDataAllPage.creditNoteData.documentData.filter(data => {
      if (data.checkbox == true) {
        this.cnDataAllPage.creditNoteData.excludingVatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.excludingVatAmt - data.excludingVatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
        this.cnDataAllPage.creditNoteData.vatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.vatAmt - data.vatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
        this.cnDataAllPage.creditNoteData.includingVatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.includingVatAmt - data.includingVatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
        if (data.creditNoteDtlId) {
          this.cnDataAllPage.creditNoteData.dtlIdDeleteData.push(data.creditNoteDtlId);
        }
        return data;
      }
    });
    // Delete Data
    this.cnDataAllPage.creditNoteData.documentData = this.cnDataAllPage.creditNoteData.documentData.filter(data => data.checkbox != true);
    this.selectedRowData = [];
    
    this.cnDataAllPage.dataNotFoundGridTab2 = true;
    this.cnDataAllPage.itemByDocument = [] as Array<ItemToReturnModel>;

    if (this.cnDataAllPage.creditNoteData.documentData.length == 0) {
      this.cnDataAllPage.dataNotFoundGridTab1 = true;
      this.cnDataAllPage.disableDeleteBtu = true;
      this.cnDataAllPage.disableBtuSave = true;
      this.cnDataAllPage.disableConfirmBtu = true;
      this.cnDataAllPage.disableCancelBtu = true;
      this.btuChangeStatus();
    } else {
      this.cnDataAllPage.dataNotFoundGridTab1 = false;
      this.cnDataAllPage.disableBtuSave = false;
      this.cnDataAllPage.creditNoteData.vatCode = this.cnDataAllPage.creditNoteData.documentData[0].vatCode;
    }
  }

  selectAllRow(checkboxAll) {
    if (checkboxAll == true) {
      this.cnDataAllPage.disableDeleteBtu = false;
    } else {
      this.cnDataAllPage.disableDeleteBtu = true;
    }

    this.cnDataAllPage.creditNoteData.documentData.forEach(data => {
      data.checkbox = checkboxAll;
    })
  }

  onRowSelect(data, i, checkbox) {
    this.cnDataAllPage.rowHightLight = i;
    this.cnDataAllPage.itemByDocument = data.itemByDocument;
    this.cnDataAllPage.dataNotFoundGridTab2 = false;
    this.cnDataAllPage.creditNoteData.documentData[i].checkbox = checkbox;

    if (this.cnDataAllPage.creditNoteData.documentData.filter(data => data.checkbox == true).length > 0) {
      this.cnDataAllPage.disableDeleteBtu = false;
    } else {
      this.cnDataAllPage.disableDeleteBtu = true;
    }
  }

  changeExcVatAmt(excludingVatAmt, index) {
    if (this.cnDataAllPage.checkReturnAmount == 'E') {
      if (excludingVatAmt < 0) {
        this.cnDataAllPage.creditNoteData.documentData[index].excludingVatAmt = 0;
      }
    } else if (this.cnDataAllPage.checkReturnAmount == 'M') {
      if (excludingVatAmt <= 0) {
        this.cnDataAllPage.creditNoteData.documentData[index].excludingVatAmt = null;
      }
    }
    this.calculateVat(this.cnDataAllPage.creditNoteData.documentData[index].excludingVatAmt, index);
    this.cnDataAllPage.disableBtuSave = false;
  }

  changReturnMoneyDate(date,index){
      this.cnDataAllPage.creditNoteData.documentData[index].returnMonetDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
      this.cnDataAllPage.disableBtuSave = false;
  }

  calculateVat(excludingVatAmt, index) {
    this.cnDataAllPage.changeExcVatAmt = excludingVatAmt;
    this.cnDataAllPage.creditNoteData.excludingVatAmt = 0;
    this.cnDataAllPage.creditNoteData.vatAmt = 0;
    this.cnDataAllPage.creditNoteData.includingVatAmt = 0;

    this.cnDataAllPage.creditNoteData.documentData[index].vatAmt = Number(this.decimalService.getRoundingDecimal(excludingVatAmt * this.cnDataAllPage.vatValue, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
    this.cnDataAllPage.creditNoteData.documentData[index].includingVatAmt = Number(this.decimalService.getRoundingDecimal(excludingVatAmt + this.cnDataAllPage.creditNoteData.documentData[index].vatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));

    this.cnDataAllPage.creditNoteData.documentData.forEach(data => {
      this.cnDataAllPage.creditNoteData.excludingVatAmt += Number(this.decimalService.getRoundingDecimal(data.excludingVatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
      this.cnDataAllPage.creditNoteData.vatAmt += Number(this.decimalService.getRoundingDecimal(data.vatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
      this.cnDataAllPage.creditNoteData.includingVatAmt += Number(this.decimalService.getRoundingDecimal(data.includingVatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
    });
  }

  getDocument(data) {

    this.cnDataAllPage.displayDocument = false;
    if (data != undefined) {

      if (this.cnDataAllPage.creditNoteData.documentData == undefined) {
        this.cnDataAllPage.creditNoteData.documentData = [] as Array<DocumentDataModel>;
      }

      let tempData:DocumentData = data;
      this.SOData = data;
      //Get Return Document
      if(tempData.document_type_code != '3'){
        this.cnDataAllPage.itemRequest = {} as ItemRequest;
        this.cnDataAllPage.itemRequest.document_no = tempData.return_req_no;
        this.cnDataAllPage.itemRequest.document_type = tempData.document_type_code;
        this.cnDataAllPage.itemRequest.warehouse_code = this.cnDataAllPage.creditNoteData.warehouseCode;
        this.cnDataAllPage.itemRequest.saleOrderNo = tempData.sale_order_ref;
        this.cnDataAllPage.itemRequest.sellerCode = this.cnDataAllPage.creditNoteData.sellerCode;
        this.cnDataAllPage.itemRequest.country = this.cnDataAllPage.creditNoteData.country;
        this.cnDataAllPage.itemRequest.saleChannel = 'S';

        this.creditNoteService.getItemByDocument(this.cnDataAllPage.itemRequest).subscribe((response: ItemToReturnModel[]) => {
          this.addReturnDocument(tempData, response);
        }, err => {
          console.log(err);
        });
      }else{
        //Get SO Document
        this.saleorderService.getSaleOrderDetail( this.SOData.sale_order_ref,  this.SOData.warehouse_code).subscribe(response => {
          this.saleOrderDetail = JSON.parse(JSON.stringify(response));
          this.cnDataAllPage.creditNoteData.country = response.country_code;
          if(this.saleOrderDetail.vat){
            this.creditNoteService.getParameterPortal(200, this.cnDataAllPage.creditNoteData.country).subscribe((response) => {
                let vat = JSON.parse(JSON.stringify(response));
                let vatSelect = vat.filter(data => data.parameterData.pmdtbdtxtv2 == this.saleOrderDetail.vat);
                let creditNoteVat = this.cnDataAllPage.vatData.filter(data => data.value == this.cnDataAllPage.creditNoteData.vatCode)
                this.cnDataAllPage.vatValue = creditNoteVat[0].parameterData.pmdtbdnumv1;
                //check Different VAT SO
                let checkDiffVat;
                if(vatSelect[0].parameterData.pmdtbdtxtv2 == this.saleOrderDetail.vat){
                  checkDiffVat = this.cnDataAllPage.vatValue != vatSelect[0].parameterData.pmdtbdnumv1 ? true : false;
                }else{
                  checkDiffVat = true;
                }
                //Add Sale Order Document
                if(checkDiffVat == true && this.cnDataAllPage.creditNoteData.documentData.length != 0){
                  this.messageService.clear();
                  this.messageService.add({ key: 'addDoc', sticky: true, severity: 'warn', summary: this.translateService.instant('ACCCDN200.M00044'), detail: this.translateService.instant('ACCCDN200.M00074')});
                }else{
                  this.cnDataAllPage.vatData = JSON.parse(JSON.stringify(response));                
                  this.cnDataAllPage.creditNoteData.vatCode = vatSelect[0].parameterData.parameterDetailPK.pmdtbdtbdentcd;
                  this.cnDataAllPage.vatValue = vatSelect[0].parameterData.pmdtbdnumv1;
                  this.cnDataAllPage.disableHdrStatusOpen = true;
                  this.flagVatSO = true;
                  this.addSaleOrderDocument( this.SOData,this.saleOrderDetail);
                }
            }, err => {
                console.log(err);
            });
                
          }     
        }, err => {
          console.log(err);
        });
      }
    }
    this.flagDiffVatAmt = false;
  }

  addSaleOrderDocument(tempData, itemToReturnData){
    let excludingVatAmt: number = 0;
    let vatAmt: number;
    let includVatAmt: number;
    let sumQty: number = 0;
    let itemData = [] as Array<ItemToReturnModel>;

    if(this.flagVatSO){
      //Calculate Vat Document
      itemToReturnData.item.forEach(itm => {
        excludingVatAmt += Number(this.decimalService.getRoundingDecimal(itm.item_price_per_piece * itm.item_quantity, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
        sumQty = itm.item_quantity + sumQty;
        itemData.push({
          koItemCode: itm.item_KlangOneCode,
          itemCode:itm.item_sellerCode,
          itemDesc_eng: itm.item_desc_eng,
          itemDesc_local: itm.item_desc_local,
          itemStatus: null,
          itemQty: itm.item_quantity,
          item_price: itm.item_price_per_piece,
          returnReasonCode: itemToReturnData.status_order_code,
          returnReasonDesc_eng: this.translateService.instant('ACCCDN200.M00073'),
          returnReasonDesc_local: this.translateService.instant('ACCCDN200.M00073'),
          itemSerialNo: null
        });
      });
      excludingVatAmt += Number(this.decimalService.getRoundingDecimal(itemToReturnData.shippment.shipping_price_customer, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
      vatAmt = Number(this.decimalService.getRoundingDecimal(itemToReturnData.discountSo.vat, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
      includVatAmt = Number(this.decimalService.getRoundingDecimal(excludingVatAmt + vatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
      
      //check COD service
      if(itemToReturnData.payment.code_charge == 'C'){
        excludingVatAmt += Number(this.decimalService.getRoundingDecimal(itemToReturnData.payment.cod_price, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
        includVatAmt = Number(this.decimalService.getRoundingDecimal(excludingVatAmt + vatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
      }
      //check Duplicate Document
      let checkDup = this.cnDataAllPage.creditNoteData.documentData.filter(data => data.documentNo == tempData.return_req_no);
      if (checkDup.length != 0) {
        this.messageService.clear();
        this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: this.translateService.instant('ACCCDN200.M00044'), detail: this.translateService.instant('ACCCDN200.M00045') });
      } else {
        let total_price: Number = 0;
        itemToReturnData.paymentinfo.forEach(e=>{
          total_price = total_price + e.total_price;
        });
        if(includVatAmt == total_price || this.flagDiffVatAmt){
            //Add SO data to DocumentData
          let momentVariable = moment(tempData.return_req_date, 'DD/MM/YYYY');
          let stringDate = momentVariable.format('YYYY-MM-DD');
          let vatCode = this.cnDataAllPage.vatData.filter(data => data.parameterData.pmdtbdtxtv2 == itemToReturnData.vat);
          if(vatCode.length != 0){
            this.cnDataAllPage.creditNoteData.documentData.push({
              creditNoteDtlId: null,
              documentNo: tempData.return_req_no,
              documentDate: stringDate,
              returnMonetDate: null,
              returnMonetDateData: null,
              recordDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
              flowTypeCode: itemToReturnData.movement_type_code,
              flowTypeDesc_eng: itemToReturnData.movement_type_desc_eng,
              flowTypeDesc_local: itemToReturnData.movement_type_desc_local,
              saleOrderNo: tempData.sale_order_ref,
              totalQty: sumQty,
              excludingVatAmt: excludingVatAmt,
              vatAmt: vatAmt,
              vatCode: vatCode[0].value,
              includingVatAmt: includVatAmt,
              checkbox: false,
              itemByDocument: itemData
            });

            //Calculate Vat Cerdit Note
              this.cnDataAllPage.creditNoteData.excludingVatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.excludingVatAmt + excludingVatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
              this.cnDataAllPage.creditNoteData.vatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.vatAmt + vatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
              this.cnDataAllPage.creditNoteData.includingVatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.includingVatAmt + includVatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
              this.cnDataAllPage.dataNotFoundGridTab1 = false;
          }    
        }else{
          this.messageService.clear();
          this.messageService.add({ key: 'addDocDiffVatAmount', sticky: true, severity: 'warn', summary: this.translateService.instant('ACCCDN200.M00044'), detail: this.translateService.instant('ACCCDN200.M00075')});
        }
        
      }
      this.selectedRowData = [];
      this.cnDataAllPage.disableBtuSave = false;
      if (this.cnDataAllPage.creditNoteHeaderId != null) {
        this.checkDisableByStatus();
      }
      this.btuChangeStatus();
    }
    this.flagVatSO = false;
  }

  addReturnDocument(tempData, itemToReturnData) {
    let excludingVatAmt: number = 0;
    let vatAmt: number;
    let includVatAmt: number;
    let sumQty: number = 0;

    //Calculate Vat Document
    itemToReturnData.forEach(item => {
      excludingVatAmt += Number(this.decimalService.getRoundingDecimal(item.item_price * item.itemQty, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal))
      sumQty = item.itemQty + sumQty;
    });
    vatAmt = Number(this.decimalService.getRoundingDecimal(excludingVatAmt * this.cnDataAllPage.vatValue, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
    includVatAmt = Number(this.decimalService.getRoundingDecimal(excludingVatAmt + vatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
    let checkDup = this.cnDataAllPage.creditNoteData.documentData.filter(data => data.documentNo == tempData.return_req_no);
    if (checkDup.length != 0) {
      this.messageService.clear();
      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: this.translateService.instant('ACCCDN200.M00044'), detail: this.translateService.instant('ACCCDN200.M00045') });
    } else {
     
      let momentVariable = moment(tempData.return_req_date, 'DD/MM/YYYY');
      let stringDate = momentVariable.format('YYYY-MM-DD');
      this.cnDataAllPage.creditNoteData.documentData.push({
        creditNoteDtlId: null,
        documentNo: tempData.return_req_no,
        documentDate: stringDate,
        returnMonetDate: null,
        returnMonetDateData: null,
        recordDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        flowTypeCode: tempData.flow_type_code,
        flowTypeDesc_eng: tempData.flow_type_desc_eng,
        flowTypeDesc_local: tempData.flow_type_desc_local,
        saleOrderNo: tempData.sale_order_ref,
        totalQty: sumQty,
        excludingVatAmt: excludingVatAmt,
        vatAmt: vatAmt,
        vatCode: this.cnDataAllPage.creditNoteData.vatCode,
        includingVatAmt: includVatAmt,
        checkbox: false,
        itemByDocument: itemToReturnData
      })
    }

    this.selectedRowData = [];

    //Calculate Vat Cerdit Note
    this.cnDataAllPage.creditNoteData.excludingVatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.excludingVatAmt + excludingVatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
    this.cnDataAllPage.creditNoteData.vatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.vatAmt + vatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
    this.cnDataAllPage.creditNoteData.includingVatAmt = Number(this.decimalService.getRoundingDecimal(this.cnDataAllPage.creditNoteData.includingVatAmt + includVatAmt, this.cnDataAllPage.method, this.cnDataAllPage.roundToDecimal, this.cnDataAllPage.decimal));
    this.cnDataAllPage.dataNotFoundGridTab1 = false;

    this.cnDataAllPage.disableBtuSave = false;
    if (this.cnDataAllPage.creditNoteHeaderId != null) {
      this.checkDisableByStatus();
    }
    this.btuChangeStatus();
  }

  goBack() {

    if (this.cnDataAllPage.creditNoteHeaderId == null) {
      if (this.cnDataAllPage.creditNoteData.country
        || this.cnDataAllPage.creditNoteData.warehouseCode
        || this.cnDataAllPage.creditNoteData.sellerCode
        || this.cnDataAllPage.creditNoteData.documentTypeCode
        || this.cnDataAllPage.creditNoteData.vatCode
        || this.cnDataAllPage.numberOfDocument != this.cnDataAllPage.creditNoteData.documentData.length) {

        this.alertWhenBlackPage();

      } else {
        this.router.navigate(['acccdn/acccdn100']);
      }

    } else {
      if (this.cnDataAllPage.numberOfDocument != this.cnDataAllPage.creditNoteData.documentData.length
        || this.cnDataAllPage.changeExcVatAmt
        || this.cnDataAllPage.creditNoteData.dtlIdDeleteData.length > 0
        || this.cnDataAllPage.tempNote != this.cnDataAllPage.creditNoteData.note
        || this.flagChenge == true) {
        this.alertWhenBlackPage();
      } else {
        this.router.navigate(['acccdn/acccdn100']);
      }
    }
  }

  alertWhenBlackPage() {
    this.messageService.clear();
    this.messageService.add({ key: 'backpage', sticky: true, severity: 'warn', summary: this.translateService.instant('ACCCDN200.M00069'), detail: this.translateService.instant('ACCCDN200.M00046') });
  }

  saveBtu() {
    if (!this.cnDataAllPage.creditNoteData.country
      || !this.cnDataAllPage.creditNoteData.warehouseCode
      || !this.cnDataAllPage.creditNoteData.sellerCode
      || !this.cnDataAllPage.creditNoteData.documentTypeCode
      || !this.cnDataAllPage.creditNoteData.vatCode
      || this.cnDataAllPage.creditNoteData.documentData.length == 0) {

      this.messageService.clear();
      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: this.translateService.instant('ACCCDN200.M00044'), detail: this.translateService.instant('ACCCDN200.M00047') });

    } else {
      this.cnDataAllPage.creditNoteData.documentData.forEach(data =>{
        if(data.returnMonetDate != null){
          data.returnMonetDate = moment(data.returnMonetDate).format('YYYY-MM-DD HH:mm:ss');
        }        
      });
      if (this.cnDataAllPage.creditNoteHeaderId == null) {
        this.creditNoteService.saveCreditNote(this.cnDataAllPage.creditNoteData).subscribe((response: any) => {
          if (response.status == 1) {
            this.cnDataAllPage.creditNoteHeaderId = response.data.toString();
            this.messageService.add({
              key: "save",
              severity: "success",
              detail: this.translateService.instant('ACCCDN200.M00048'),
            });

            setTimeout(() => {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(['acccdn/acccdn200/' + this.cnDataAllPage.creditNoteHeaderId]).then(() => {
                  window.location.reload();
                  window.scroll(0, 0);
                }));
              }, 1500);

          } else {
            this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: this.translateService.instant('ACCCDN200.M00049'), detail: this.translateService.instant('ACCCDN200.M00050') });
          }
        }, err => {
          console.log(err);
        });
      } else {
        this.updateCreditNoteData('100')
      }
    }
  }

  confirmBtu() {
    this.cnDataAllPage.creditNoteData.creditNoteHeaderId = Number(this.cnDataAllPage.creditNoteHeaderId);
    this.cnDataAllPage.creditNoteData.statusCode = '200';
    this.cnDataAllPage.creditNoteData.confirmDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    this.messageService.clear();
    this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: this.translateService.instant('ACCCDN200.M00051'), detail: this.translateService.instant('ACCCDN200.M00052') });
  }

  cancelBtu() {
    this.cnDataAllPage.creditNoteData.statusCode = '300';
    this.messageService.clear();
    this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: this.translateService.instant('ACCCDN200.M00053'), detail: this.translateService.instant('ACCCDN200.M00054') });
  }

  updateCreditNoteData(statusCode) {
    this.cnDataAllPage.creditNoteData.updateUser = this.user.userName;
    this.cnDataAllPage.creditNoteData.statusCode = statusCode;
    if (this.cnDataAllPage.creditNoteData.dtlIdDeleteData.length == 0) {
      this.cnDataAllPage.creditNoteData.dtlIdDeleteData.push(0);
    }
    this.creditNoteService.updateCreditNote(this.cnDataAllPage.creditNoteData).subscribe((response) => {

      if (response) {
        this.messageService.add({
          key: "save",
          severity: "success",
          detail: 'ACCCDN200.M00048',
        });

        setTimeout(() => {
          window.location.reload();
          }, 1000);
          
      } else {
        this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: this.translateService.instant('ACCCDN200.M00049'), detail: this.translateService.instant('ACCCDN200.M00050') });
      }

    }, err => {
      console.log(err);
    });
  }

  clickOkAddDoc(message){
    this.messageService.clear();
    this.flagVatSO = true;
    this.addSaleOrderDocument( this.SOData,this.saleOrderDetail);
  }

  clickCancelAddDoc(message){
    this.messageService.clear();
    this.flagVatSO = false;
  }

  clickOk(message) {
    this.messageService.clear();
    if (message == this.translateService.instant('ACCCDN200.M00051')) {
      this.updateCreditNoteData('200')
    } else if (message == this.translateService.instant('ACCCDN200.M00053')) {
      this.updateCreditNoteData('300')
    } else if (message == this.translateService.instant('ACCCDN200.M00044')) {
      this.router.navigate(['acccdn/acccdn100']);
    } else {
      this.clearDetail('requireds');
      this.setHeaderDataDDL('clickOk');
    }
  }

  clickCancel(message) {
    this.messageService.clear();
    if (message == this.translateService.instant('ACCCDN200.M00055')) {
      this.setHeaderDataDDL('clickCancel');
    }

  }

  setHeaderDataDDL(eventClick) {
    if (this.cnDataAllPage.nameChangDDL == 'changeCountry') {
      if (eventClick == 'clickOk') {

        this.changeCountry();
        this.cnDataAllPage.creditNoteData.warehouseCode = null;
        this.cnDataAllPage.creditNoteData.sellerCode = null;
        this.cnDataAllPage.creditNoteData.vatCode = null;
        this.cnDataAllPage.tempHeaderDataDDL.country = this.cnDataAllPage.creditNoteData.country;
        this.cnDataAllPage.disableBtuAdd = true;
        this.cnDataAllPage.disableBtuSave = true;
      } else {
        this.cnDataAllPage.creditNoteData.country = this.cnDataAllPage.tempHeaderDataDDL.country;
      }

    } else if (this.cnDataAllPage.nameChangDDL == 'changeWarehouse') {

      if (eventClick == 'clickOk') {
        this.changeWarehouse();
        this.cnDataAllPage.creditNoteData.sellerCode = null;
        this.cnDataAllPage.tempHeaderDataDDL.warehouseCode = this.cnDataAllPage.creditNoteData.warehouseCode;
        this.cnDataAllPage.disableBtuAdd = true;
        this.cnDataAllPage.disableBtuSave = true;
      } else {
        this.cnDataAllPage.creditNoteData.warehouseCode = this.cnDataAllPage.tempHeaderDataDDL.warehouseCode;
      }

    } else if (this.cnDataAllPage.nameChangDDL == 'changeSeller') {

      if (eventClick == 'clickOk') {
        this.changeSeller();
        this.cnDataAllPage.tempHeaderDataDDL.sellerCode = this.cnDataAllPage.creditNoteData.sellerCode;
      } else {
        this.cnDataAllPage.creditNoteData.sellerCode = this.cnDataAllPage.tempHeaderDataDDL.sellerCode;
      }

    } else if (this.cnDataAllPage.nameChangDDL == 'changeType') {

      if (eventClick == 'clickOk') {
        this.changeType();
        this.cnDataAllPage.tempHeaderDataDDL.documentTypeCode = this.cnDataAllPage.creditNoteData.documentTypeCode;
      } else {
        this.cnDataAllPage.creditNoteData.documentTypeCode = this.cnDataAllPage.tempHeaderDataDDL.documentTypeCode;
      }

    }
  }

  checkDisableBtuAdd() {
    if (this.cnDataAllPage.creditNoteData.country
      && this.cnDataAllPage.creditNoteData.warehouseCode
      && this.cnDataAllPage.creditNoteData.sellerCode
      && this.cnDataAllPage.creditNoteData.documentTypeCode
      && this.cnDataAllPage.creditNoteData.vatCode) {

      if (this.cnDataAllPage.creditNoteHeaderId == null) {

        this.cnDataAllPage.disableBtuAdd = false;

        if (this.cnDataAllPage.creditNoteData.documentData.length > 0) {
          if (this.cnDataAllPage.nameChangDDL != 'changeVat') {
            this.messageService.clear();
            this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: this.translateService.instant('ACCCDN200.M00055'), detail: this.translateService.instant('ACCCDN200.M00056') });
          } else {
            this.cnDataAllPage.disableBtuSave = false;
          }

          this.btuChangeStatus();
        }
      }
    } else {
      this.cnDataAllPage.disableBtuAdd = true;
    }
  }

  clearDetail(requireds) {

    if (this.cnDataAllPage.creditNoteData.documentData.length > 0 && this.cnDataAllPage.creditNoteHeaderId == null) {
      this.cnDataAllPage.creditNoteData.documentData = [] as Array<DocumentDataModel>
      this.cnDataAllPage.dataNotFoundGridTab1 = true;
      this.cnDataAllPage.dataNotFoundGridTab2 = true;
      this.cnDataAllPage.disableBtuSave = true;
      this.cnDataAllPage.creditNoteData.excludingVatAmt = 0;
      this.cnDataAllPage.creditNoteData.vatAmt = 0;
      this.cnDataAllPage.creditNoteData.includingVatAmt = 0;
      this.cnDataAllPage.numberOfDocument = 0;
      if (requireds == 'requireds') {
        this.cnDataAllPage.disableBtuAdd = false;
      } else {
        this.cnDataAllPage.disableBtuAdd = true;
      }

      this.btuChangeStatus();
    }
  }

  btuChangeStatus() {
    this.cnDataAllPage.dataChangeStatus = [{ label: this.translateService.instant('ACCCDN200.M00066'), command: () => { this.confirmBtu(); }, disabled: this.cnDataAllPage.disableConfirmBtu || this.cnDataAllPage.disablePermission },
    { label: this.translateService.instant('ACCCDN200.M00067'), command: () => { this.saveBtu(); }, disabled: this.cnDataAllPage.disableBtuSave || this.cnDataAllPage.disablePermission },
    { label: this.translateService.instant('ACCCDN200.M00068'), command: () => { this.cancelBtu(); }, disabled: this.cnDataAllPage.disableCancelBtu || this.cnDataAllPage.disablePermission }]
  }

  clickConfirm(){
    this.messageService.clear();
    this.router.navigate(['acccdn/acccdn100']);
  }

  setDefaultVat(){
    this.parameterService.getDiscoutPrice(201,this.cnDataAllPage.creditNoteData.country,100).subscribe(response=>{
      let result = JSON.parse(JSON.stringify(response));
      if(result.pmdtbdtxtv1 == 'Y'){
        this.cnDataAllPage.creditNoteData.vatCode = result.pmdtbdtxtv2;
        let vatSelect = this.cnDataAllPage.vatData.filter(data => data.value == this.cnDataAllPage.creditNoteData.vatCode)
        this.cnDataAllPage.vatValue = vatSelect[0].parameterData.pmdtbdnumv1;
      }
    });
  }

  clickOkDiffVatAmt(){
    this.messageService.clear();
    this.flagVatSO = true;
    this.flagDiffVatAmt = true;
    this.addSaleOrderDocument( this.SOData,this.saleOrderDetail);
  }

  clickCancelDiffVatAmt(){
    this.messageService.clear();
    this.flagVatSO = false;
    this.flagDiffVatAmt = false;
  }
}
