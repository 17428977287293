import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Itmitm100Component } from './itmitm100/itmitm100.component';
import { Itmitm200Component } from './itmitm200/itmitm200.component';


const routes: Routes = [
  { path: 'itmitm/itmitm100', component: Itmitm100Component },
  { path: 'itmitm/itmitm200/:itemCode', component: Itmitm200Component }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ItmRoutingModule { }
