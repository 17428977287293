import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class Admpro200Service {

  constructor(private http: HttpClient,
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService) { }

    private PROFILE_URL: string = `${this.environmentService.getApiUrl()}/adm/prof` as string;  

  getMenuProfileByProfileID(profileId) {    
    const URL: string = `${this.PROFILE_URL}/getMenuProfileByProfileID/${profileId}` as string;
    return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
  }


  createMenuByProfile() {    
    const URL: string = `${this.PROFILE_URL}/createMenuByProfile` as string;
    return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
  }

  saveProfile(profileData){
    const URL: string = `${this.PROFILE_URL}/saveProfile` as string;
    return this.http.post(URL,profileData,{ headers: this.authenticationService.tokenHeader()});
  }

  updateProfile(profileData){
    const URL: string = `${this.PROFILE_URL}/updateProfile` as string;
    return this.http.post(URL,profileData,{ headers: this.authenticationService.tokenHeader()});
  }

 
}
