import { Renderer2 } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { Admatr200Model, AttributeValue } from '../../model/admatr/admatr200.model';
import { Admatr200Service } from '../../service/admatr/admatr200.service';

@Component({
  selector: 'app-admatr200',
  templateUrl: './admatr200.component.html',
  styleUrls: ['./admatr200.component.scss']
})
export class Admatr200Component extends PermissionComponent {

  public page_model: Admatr200Model;

  constructor(private admatr200Service: Admatr200Service,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    renderer2:Renderer2) { 
    super('P01001',renderer2);
    this.page_model = this.admatr200Service.load;
    this.route.paramMap.subscribe(params => {
      if (params) {
        this.page_model.acvclscd = params.get('acvclscd');
      }
    });
    this.page_model.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel;
  }

  ngOnInit() {
    this.admatr200Service.getAttributeValueByAcvclsCode(this.page_model.acvclscd).subscribe(response => {
      this.page_model.attributeValue = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    if(this.page_model.acvclscd != null){
      this.admatr200Service.getAttributeClassByAcvclsCode(this.page_model.acvclscd).subscribe(response => {
        this.page_model.attributeClass = JSON.parse(JSON.stringify(response));
        this.page_model.form_model.gridAttributeClassForm.controls['aclclscd'].setValue(this.page_model.attributeClass.aclclscd);
        this.page_model.form_model.gridAttributeClassForm.controls['aclclsedesc'].setValue(this.page_model.attributeClass.aclclsedesc);
        this.page_model.form_model.gridAttributeClassForm.controls['aclonecd'].setValue(this.page_model.attributeClass.aclonecd);
        this.page_model.form_model.gridAttributeClassForm.controls['aclclsldesc'].setValue(this.page_model.attributeClass.aclclsldesc);
        this.page_model.form_model.gridAttributeClassForm.controls['aclmandatory'].setValue(this.page_model.attributeClass.aclmandatory);
        this.page_model.form_model.gridAttributeClassForm.controls['aclapptousr'].setValue(this.page_model.attributeClass.aclapptousr);
        this.page_model.form_model.gridAttributeClassForm.controls['acleopdat'].setValue(this.page_model.attributeClass.acleopdat);
        this.page_model.form_model.gridAttributeClassForm.controls['aclapptoitem'].setValue(this.page_model.attributeClass.aclapptoitem);
        this.page_model.form_model.gridAttributeClassForm.controls['aclapptoven'].setValue(this.page_model.attributeClass.aclapptoven);
        this.page_model.form_model.gridAttributeClassForm.controls['aclapptosite'].setValue(this.page_model.attributeClass.aclapptosite);
        this.page_model.form_model.gridAttributeClassForm.controls['aclapptomdh'].setValue(this.page_model.attributeClass.aclapptomdh);
        this.page_model.form_model.gridAttributeClassForm.controls['aclclscd'].disable();
        this.page_model.flagButtonSaveAtrcls = true;
        this.page_model.flagButtonAdd = false;
      }, err => {
        console.log(err);
      });
    }
    if(this.page_model.acvclscd == null){
      this.page_model.form_model.gridAttributeClassForm.controls['aclclscd'].enable();
      this.page_model.form_model.gridAttributeClassForm.reset();
      this.page_model.flagButtonSaveAtrcls = true;
    }
  }

 
  checkCriteriaAttributeClass(){
    if(this.page_model.form_model.gridAttributeClassForm.valid){
      this.page_model.flagButtonSaveAtrcls = false;
    }else{
      this.page_model.flagButtonSaveAtrcls = true;
    }
  }

  saveAttributeClass(){
    this.page_model.attributeClassRequest.aclclscd = this.page_model.form_model.gridAttributeClassForm.controls['aclclscd'].value == ''? null: this.page_model.form_model.gridAttributeClassForm.controls['aclclscd'].value;
    this.page_model.attributeClassRequest.aclclsedesc = this.page_model.form_model.gridAttributeClassForm.controls['aclclsedesc'].value == ''? null: this.page_model.form_model.gridAttributeClassForm.controls['aclclsedesc'].value;
    this.page_model.attributeClassRequest.aclclsldesc = this.page_model.form_model.gridAttributeClassForm.controls['aclclsldesc'].value == ''? null: this.page_model.form_model.gridAttributeClassForm.controls['aclclsldesc'].value;
    this.page_model.attributeClassRequest.aclonecd = Number(this.page_model.form_model.gridAttributeClassForm.controls['aclonecd'].value);
    this.page_model.attributeClassRequest.aclmandatory = this.page_model.form_model.gridAttributeClassForm.controls['aclmandatory'].value;
    this.page_model.attributeClassRequest.aclapptousr = this.page_model.form_model.gridAttributeClassForm.controls['aclapptousr'].value;
    this.page_model.attributeClassRequest.acleopdat = this.page_model.form_model.gridAttributeClassForm.controls['acleopdat'].value == null? new Date(): this.page_model.form_model.gridAttributeClassForm.controls['acleopdat'].value;
    this.page_model.attributeClassRequest.aclapptoitem = this.page_model.form_model.gridAttributeClassForm.controls['aclapptoitem'].value == null? false: this.page_model.form_model.gridAttributeClassForm.controls['aclapptoitem'].value;
    this.page_model.attributeClassRequest.aclapptoven = this.page_model.form_model.gridAttributeClassForm.controls['aclapptoven'].value == null? false: this.page_model.form_model.gridAttributeClassForm.controls['aclapptoven'].value;
    this.page_model.attributeClassRequest.aclapptosite = this.page_model.form_model.gridAttributeClassForm.controls['aclapptosite'].value == null? false: this.page_model.form_model.gridAttributeClassForm.controls['aclapptosite'].value;
    this.page_model.attributeClassRequest.aclapptomdh = this.page_model.form_model.gridAttributeClassForm.controls['aclapptomdh'].value == null? false: this.page_model.form_model.gridAttributeClassForm.controls['aclapptomdh'].value;
    this.page_model.attributeClassRequest.aclusr = this.page_model.user.userName;
    if(this.page_model.attributeClassRequest.aclclscd != this.page_model.attributeClass.aclclscd ||
      this.page_model.attributeClassRequest.aclclsedesc != this.page_model.attributeClass.aclclsedesc ||
      this.page_model.attributeClassRequest.aclclsldesc != this.page_model.attributeClass.aclclsldesc ||
      this.page_model.attributeClassRequest.aclonecd != this.page_model.attributeClass.aclonecd ||
      this.page_model.attributeClassRequest.aclmandatory != this.page_model.attributeClass.aclmandatory ||
      this.page_model.attributeClassRequest.aclapptousr != this.page_model.attributeClass.aclapptousr){
        this.admatr200Service.saveAndUpdateAttributeClass(this.page_model.attributeClassRequest).subscribe(response => {
          let dataResponse = JSON.parse(JSON.stringify(response));
          if(dataResponse != null){
            this.successAlert(this.translateService.instant('ADMATR200.M00022'));
            setTimeout(() => {
              this.onConfirmSuccess();
            }, 500);
          }else{
            this.warningAlert('error', this.translateService.instant('ADMATR200.M00023'));
          }
        }, err => {
          console.log(err);
        });
    }else{
      this.successAlert(this.translateService.instant('ADMATR200.M00022'));
    }
  }

  checkedAll(isChecker: boolean) {
    this.page_model.attributeValueDelete = [];
    if (isChecker) {
      this.page_model.attributeValueDelete = this.page_model.attributeValue;
      this.page_model.flagButtonDelete = false;
    } else {
      this.page_model.attributeValueDelete = [];
      this.page_model.flagButtonDelete = true;
    }
  }

  checked(isChecker: boolean, attribute: AttributeValue) {
    if (isChecker) {
      this.page_model.attributeValueDelete.push(attribute);
      this.page_model.flagButtonDelete = false;
    } else {
      let attributeValueDeleteCheck : Array<AttributeValue> = [] as Array<AttributeValue>;
      this.page_model.attributeValueDelete.forEach((data, index) => {
        if(data.acvatrcd != attribute.acvatrcd){
          attributeValueDeleteCheck.push(data);
        }
      });
      this.page_model.attributeValueDelete = attributeValueDeleteCheck;
      if(this.page_model.attributeValueDelete.length > 0){
        this.page_model.flagButtonDelete = false;
      }else{
        this.page_model.flagButtonDelete = true;
      }
    }
  }

  deleteAttributeValue(){
    this.deleteAlert(this.translateService.instant('ADMATR200.M00038'), this.translateService.instant('ADMATR200.M00031'));
  }

  showDialogSaveAndUpdate(attribute) {
    if(attribute != null){
      this.page_model.form_model.gridAttributeValueForm.controls['acvatrcd'].setValue(attribute.acvatrcd);
      this.page_model.form_model.gridAttributeValueForm.controls['acvcddesc'].setValue(attribute.acvcddesc);
      this.page_model.form_model.gridAttributeValueForm.controls['acvaddval'].setValue(attribute.acvaddval);
      this.page_model.form_model.gridAttributeValueForm.controls['acvdefault'].setValue(attribute.acvdefault);
      this.page_model.form_model.gridAttributeValueForm.controls['acvcdldesc'].setValue(attribute.acvcdldesc);
      this.page_model.form_model.gridAttributeValueForm.controls['acvatrcd'].disable();
      this.page_model.checkflagSaveOrUpdateAtrval = false;
      this.page_model.display = true;
    }else{
      this.page_model.checkflagSaveOrUpdateAtrval = true;
      this.page_model.display = true;
    }
  }

  checkCriteriaAttributeValue(){
    if(this.page_model.form_model.gridAttributeValueForm.valid){
      this.page_model.flagButtonSave = false;
    }else{
      this.page_model.flagButtonSave = true;
    }
  }

  saveAttributeValue(){
    this.page_model.attributeValueRequest.acvclscd = this.page_model.acvclscd;
    this.page_model.attributeValueRequest.acvatrcd = this.page_model.form_model.gridAttributeValueForm.controls['acvatrcd'].value == ''? null: this.page_model.form_model.gridAttributeValueForm.controls['acvatrcd'].value;
    this.page_model.attributeValueRequest.acvcddesc = this.page_model.form_model.gridAttributeValueForm.controls['acvcddesc'].value == ''? null: this.page_model.form_model.gridAttributeValueForm.controls['acvcddesc'].value;
    this.page_model.attributeValueRequest.acvaddval = this.page_model.form_model.gridAttributeValueForm.controls['acvaddval'].value == ''? null: this.page_model.form_model.gridAttributeValueForm.controls['acvaddval'].value;
    this.page_model.attributeValueRequest.acvdefault = this.page_model.form_model.gridAttributeValueForm.controls['acvdefault'].value;
    this.page_model.attributeValueRequest.acvcdldesc = this.page_model.form_model.gridAttributeValueForm.controls['acvcdldesc'].value == ''? null: this.page_model.form_model.gridAttributeValueForm.controls['acvcdldesc'].value;
    this.page_model.attributeValueRequest.acveopdat =  new Date();
    this.page_model.attributeValueRequest.acvusr = this.page_model.user.userName;
    let attributeValueDuplicate: Array<AttributeValue> = [] as Array<AttributeValue>;
    let attributeValueNonChange: Array<AttributeValue> = [] as Array<AttributeValue>;
    let attributeValueAcvdefault: Array<AttributeValue> = [] as Array<AttributeValue>;
    if(this.page_model.attributeValueRequest.acvdefault){
      attributeValueAcvdefault = this.page_model.attributeValue.filter(data => {
        return ((data.acvdefault.toString().toLowerCase().indexOf("true".toLowerCase()) > -1) );
      });
    }
    if(attributeValueAcvdefault.length <= 0){
      if(this.page_model.checkflagSaveOrUpdateAtrval){
        attributeValueDuplicate = this.page_model.attributeValue.filter(data => {
          return ((data.acvatrcd.toString().toLowerCase() == this.page_model.attributeValueRequest.acvatrcd.toString().toLowerCase()));
        });
      }else{
        attributeValueNonChange = this.page_model.attributeValue.filter(data => {
          return ((data.acvatrcd.toString().toLowerCase() == this.page_model.attributeValueRequest.acvatrcd.toString().toLowerCase()));
        });
      }
      if(attributeValueDuplicate.length > 0){
        this.warningAlert('warn', this.translateService.instant('ADMATR200.M00027'));
      }else{
        if(this.page_model.checkflagSaveOrUpdateAtrval){
          this.admatr200Service.saveAttributeValue(this.page_model.attributeValueRequest).subscribe(response =>{
            let dataResponse = JSON.parse(JSON.stringify(response));
            if(dataResponse != null){
              this.successAlert(this.translateService.instant('ADMATR200.M00022'));
              setTimeout(() => {
                this.onConfirmSuccess();
              }, 500);
            }else{
              this.warningAlert('error', this.translateService.instant('ADMATR200.M00023'));
            }
          });
        }else{
          if(this.page_model.attributeValueRequest.acvcddesc != attributeValueNonChange[0].acvcddesc ||
            this.page_model.attributeValueRequest.acvaddval != attributeValueNonChange[0].acvaddval ||
            this.page_model.attributeValueRequest.acvdefault != attributeValueNonChange[0].acvdefault ||
            this.page_model.attributeValueRequest.acvcdldesc != attributeValueNonChange[0].acvcdldesc){
              this.admatr200Service.saveAttributeValue(this.page_model.attributeValueRequest).subscribe(response =>{
                let dataResponse = JSON.parse(JSON.stringify(response));
                if(dataResponse != null){
                  this.successAlert(this.translateService.instant('ADMATR200.M00022'));
                  setTimeout(() => {
                    this.onConfirmSuccess();
                  }, 500);
                }else{
                  this.warningAlert('error', this.translateService.instant('ADMATR200.M00023'));
                }
              });
          }else{
            this.warningAlert('warn', this.translateService.instant('ADMATR200.M00024'));
          }
        }
      }
    }else{
      this.warningAlert('warn', this.translateService.instant('ADMATR200.M00029'));
    }
   
  }

  closeDialog(){
    this.page_model.form_model.gridAttributeValueForm.reset();
    this.page_model.form_model.gridAttributeValueForm.controls['acvatrcd'].enable();
  }

  warningAlert(severityInfo: string, detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: 'alert', sticky: true, severity: severityInfo, summary: this.translateService.instant('ADMATR200.M00028'), detail: detaiInfo});
    
  }

  successAlert(detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: 'alertsuccess', sticky: true, severity: 'success', /*summary: this.translateService.instant('ADMATR200.M00028'),*/ detail: detaiInfo});
  }

  deleteAlert(summary: string, detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: 'alertdelete', sticky: true, severity: 'warn', summary: summary, detail: detaiInfo});
  }

  onReject(){
    this.messageService.clear();
  }

  onConfirm(){
    this.messageService.clear();
  }

  onConfirmSuccess(){
    setTimeout(() => {
      this.messageService.clear();
      this.page_model.display = false;
    }, 600);
    if(this.page_model.acvclscd == null){
        this.router.navigate(['admatr/admatr100']);
    }else{
      window.location.reload();
    }
  }

  onConfirmBack(){
    this.messageService.clear();
    this.router.navigate(['admatr/admatr100']);
  }

  goBack() {
    this.page_model.attributeClassRequest.aclclscd = this.page_model.form_model.gridAttributeClassForm.controls['aclclscd'].value == ''? null: this.page_model.form_model.gridAttributeClassForm.controls['aclclscd'].value;
    this.page_model.attributeClassRequest.aclclsedesc = this.page_model.form_model.gridAttributeClassForm.controls['aclclsedesc'].value == ''? null: this.page_model.form_model.gridAttributeClassForm.controls['aclclsedesc'].value;
    this.page_model.attributeClassRequest.aclclsldesc = this.page_model.form_model.gridAttributeClassForm.controls['aclclsldesc'].value == ''? null: this.page_model.form_model.gridAttributeClassForm.controls['aclclsldesc'].value;
    this.page_model.attributeClassRequest.aclonecd = Number(this.page_model.form_model.gridAttributeClassForm.controls['aclonecd'].value);
    this.page_model.attributeClassRequest.aclmandatory = this.page_model.form_model.gridAttributeClassForm.controls['aclmandatory'].value;
    this.page_model.attributeClassRequest.aclapptousr = this.page_model.form_model.gridAttributeClassForm.controls['aclapptousr'].value;
    if(this.page_model.acvclscd != null){
      if(this.page_model.attributeClassRequest.aclclscd != this.page_model.attributeClass.aclclscd ||
        this.page_model.attributeClassRequest.aclclsedesc != this.page_model.attributeClass.aclclsedesc ||
        this.page_model.attributeClassRequest.aclclsldesc != this.page_model.attributeClass.aclclsldesc ||
        this.page_model.attributeClassRequest.aclonecd != this.page_model.attributeClass.aclonecd ||
        this.page_model.attributeClassRequest.aclmandatory != this.page_model.attributeClass.aclmandatory ||
        this.page_model.attributeClassRequest.aclapptousr != this.page_model.attributeClass.aclapptousr){
        this.messageService.add({key: 'goback', sticky: true, severity: 'warn', summary: this.translateService.instant('ADMATR200.M00037'), detail: this.translateService.instant('ADMATR200.M00030')});
      }else{
        this.onConfirmBack();
      }
    }else{
      if(this.page_model.attributeClassRequest.aclclscd != null ||
        this.page_model.attributeClassRequest.aclclsedesc != null ||
        this.page_model.attributeClassRequest.aclclsldesc != null ||
        this.page_model.attributeClassRequest.aclonecd != 0 ||
        this.page_model.attributeClassRequest.aclmandatory != false ||
        this.page_model.attributeClassRequest.aclapptousr != false){
        this.messageService.add({key: 'goback', sticky: true, severity: 'warn', summary: this.translateService.instant('ADMATR200.M00037'), detail: this.translateService.instant('ADMATR200.M00030')});
      }else{
        this.onConfirmBack();
      }
    }
  }

  async onConfirmDelete(){
    await this.page_model.attributeValueDelete.forEach((data, index) => {
      let acvclscd = data.acvclscd;
      let acvatrcd = data.acvatrcd;
      this.admatr200Service.daleteAttributeValue(acvclscd, acvatrcd).subscribe(response =>{
        let dataResponse = JSON.parse(JSON.stringify(response));
        if(dataResponse != null){
          this.successAlert(this.translateService.instant('ADMATR200.M00025'));
          setTimeout(() => {
            this.messageService.clear();
            this.page_model.display = false;
            window.location.reload();
          }, 600);
        }else{
          this.warningAlert('error', this.translateService.instant('ADMATR200.M00026'));
        }
      });
    });
  }

}
