import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class Admusr110Service {

  constructor(private http: HttpClient,
    private environmentService: EnvironmentService,
    private authService: AuthService) { };

private USER_URL: string = `${this.environmentService.getApiUrl()}/adm/usr` as string;

  getuser() {
    const URL: string = `${this.USER_URL}/getuser` as string;
    return this.http.get(URL, { headers: this.authService.tokenHeader() });
  }

  updateuser(userData) {
    const URL: string = `${this.USER_URL}/updateuser` as string;
    return this.http.post(URL, userData, { headers: this.authService.tokenHeader() });
  }

}