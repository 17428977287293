import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AdministrationUserService } from 'src/app/shared/services/administration-user.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { AdministrationLanguageService } from 'src/app/shared/services/administration-language.service';
import { ParameterCountryList } from 'src/app/shared/model/parameter.model';

@Component({
  selector: 'app-autaut300',
  templateUrl: './autaut300.component.html',
  styleUrls: ['./autaut300.component.scss']
})
export class Autaut300Component implements OnInit {

  programId: string = 'P60006' as string;

  public language: string;
  public optionCountry: Array<ParameterCountryList> = [] as Array<ParameterCountryList>;
  public selectedCountry: ParameterCountryList = {} as ParameterCountryList;
  public changePasswordFrom: FormGroup = this.formBuilder.group({
    oldPassword: new FormControl('', Validators.compose([Validators.required])),
    newPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
    confirmNewPassword: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(8),
    ]))
  }, { validators: this.checkConfirmNewPassword });

  pageName: string;

  loadingFlag: boolean = false as boolean;

  isSuccess: boolean = false as boolean;

  isIncorrectOldPassword: boolean = false as boolean;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private admusrService: AdministrationUserService,
    private translateService: TranslateService,
    private admLangService: AdministrationLanguageService,
    private autService: AuthenticationService
  ) {
    this.language = localStorage.getItem('languageData') as string;
    this.pageName = localStorage.getItem(this.programId);
  }

  useLanguage(language: string) {
    this.translateService.use(language);
  }

  ngOnInit() {
    this.admLangService.getvparameterCountry().subscribe(response => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.language) {
        let rusult = this.optionCountry.filter(data => data.value.entryCode.toUpperCase() == this.language.toUpperCase());
        this.selectedCountry = rusult[0];
      } else {
        let rusult = this.optionCountry.filter(data => data.value.entryDefault == 'Y');
        this.selectedCountry = rusult[0];
      }
      this.useLanguage(this.selectedCountry.value.entryCode);
    }, err => {
      console.log(err);
    })
   }

  checkConfirmNewPassword(group: FormGroup) {
    const newPassword = group.get('newPassword').value;
    const confirmNewPassword = group.get('confirmNewPassword').value;

    return newPassword === confirmNewPassword ? null : { compare: true };
  }

  onSavePassword() {
    this.loadingFlag = true;
    if (this.changePasswordFrom.valid) {
      this.admusrService.changePwd(
        this.changePasswordFrom.controls.oldPassword.value,
        this.changePasswordFrom.controls.newPassword.value,
        this.changePasswordFrom.controls.confirmNewPassword.value).subscribe(res => {
          const responesMessage = JSON.parse(JSON.stringify(res)).message;
          if (responesMessage) {
            if (responesMessage === 'T0002') {
              this.isIncorrectOldPassword = true;
              this.loadingFlag = false;
            } else if (responesMessage === 'T0001') {
              console.log(responesMessage);
              this.isSuccess = true;
              this.autService.doLogout();
              this.loadingFlag = false;
            } else if (responesMessage === 'T0003') {
              this.changePasswordFrom.controls.confirmNewPassword.setErrors({ compare: true });
              this.loadingFlag = false;
            }
          } else {
            this.isSuccess = false;
            this.loadingFlag = false;
          }
        }, err => {
          this.isSuccess = false;
        });
    } else {
      this.isSuccess = false;
      this.loadingFlag = false;
    }
  }

  backTologin() {
    this.autService.doLogout().subscribe(() => {
      localStorage.clear();
    });
    this.router.navigate(['/']);
  }

  backToHome() {
    this.router.navigate(['/homhom/homhom100']);
  }

  clearStateIncorrectOldPassword() {
    this.isIncorrectOldPassword = false;
  }
}



