import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DropDownListModel } from 'src/app/shared/model/dropdownlist.model';
import { FormModel, Upload200Model } from '../model/upload200.model';


@Injectable({
  providedIn: 'root'
})
export class Upload200Service {

  upload200: Upload200Model = {} as Upload200Model;

  constructor(private fb: FormBuilder) {
    this.inti();
  }

  inti(): void {
    this.upload200.form_model = {} as FormModel;

    this.upload200.form_model.groupHistory = this.fb.group({
      uploadCode: [null, Validators.required],
      startendDate: (null)
    });
    this.upload200.form_model.advance_search_form= this.fb.group({
      key: (null)
    });
    
    this.upload200.country = localStorage.getItem('languageData') as string;
    this.upload200.data_list = [] as Array<DropDownListModel>;
    this.upload200.searchFormSummitFlag = true;
    this.upload200.firstRow = 0;
  }

  clearFromControll(){
    this.upload200.form_model.groupHistory.reset();
  }

  get load(): Upload200Model {
    return this.upload200;
  }

}
