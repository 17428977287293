import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { CustomerInquiryDetailModel, CustomerInquiryRequest, CustomerMoreInfoModel, CustomerSellerModel } from 'src/app/shared/model/customer-inquiry.model';
import { ParameterEntity } from 'src/app/shared/model/paramete.rmodel';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { CustomerDetail, CustomerManagement, InfoData, OtherModel, SellerData, TempDDL, customerHistory, parameterDetail } from './model/cuscus200.model';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';
import { concat } from 'rxjs';
@Component({
  selector: 'app-cuscus200',
  templateUrl: './cuscus200.component.html',
  styleUrls: ['./cuscus200.component.scss']
})
export class Cuscus200Component implements OnInit {

  public countryDDL: SelectItem[];
  public dataTableSeller: Array<SellerData> = [] as Array<SellerData>;
  public dataTableIInfo: Array<InfoData> = [] as Array<InfoData>;
  public customerDetailList: Array<CustomerInquiryDetailModel> = [] as Array<CustomerInquiryDetailModel>;

  customerHistoryList: Array<customerHistory> = [] as Array<customerHistory>;
  customerHistory: customerHistory = {} as customerHistory;
  customerMain = {} as CustomerDetail;
  dataCustomer: CustomerManagement = {} as CustomerManagement;
  tempData: CustomerManagement = {} as CustomerManagement;
  otherModel: OtherModel = {} as OtherModel;
  types: any[];
  selectedType: any;
  optionSex: parameterDetail[];
  optionOccupation: parameterDetail[];
  optionNationality: parameterDetail[];
  subDistrictCustomer: TempDDL[];
  districtCustomer: TempDDL[];
  provinceCustomer: TempDDL[];
  sellerDDL: ParameterEntity[];
  selectedInfo!: InfoData[] | null;
  selectedSell!: SellerData[] | null;
  InfoData!: InfoData[];
  districtCustomerTemp: TempDDL[];
  provinceCustomerTemp: TempDDL[];
  subDistrictCustomerTemp: TempDDL[];
  flagGetPostcode: string;
  row: any;
  isChecker: any;
  disable: boolean = true;
  sex_data: string;
  nation_data: string;
  Seller_data: any;
  Seller_dataDisplay: any;
  tempPhoneNumber : string;
  tempPhoneNumber2 : string;
  tempPhoneNumber3 : string;
  tempPhoneNumber4 : string;
  tempPhoneNumber5 : string;
  occu_data: string;
  param = { country: null, warehouse: null };
  customerId: string;
  isCheckDupplicateSeller: boolean = true;
  isCheckDupplicateInfo: boolean = true;
  ischange: boolean = true;
  firstRow: number = 0 as number;
  checkNationlity: boolean = true;


  isDataButtonSave: boolean = true;

  CustomerGroupForm = new FormGroup({
    customerId: new FormControl(),
    kpDefaultMemberId: new FormControl({ value: '', disabled: false }),
    kpMemberId: new FormControl(),
    CreateBy: new FormControl(),
    CusName: new FormControl({ value: '', disabled: false }, Validators.required),
    CusLastName: new FormControl({ value: '', disabled: false }, Validators.required),
    Birth: new FormControl({ value: '', disabled: false }, Validators.required),
    Sex: new FormControl({ value: null, disabled: false }, Validators.required),
    Age: new FormControl({ value: null, disabled: true }),
    TaxID: new FormControl(),
    Occu: new FormControl({ value: null, disabled: false }),
    OccuDes: new FormControl({ value: null, disabled: true }),
    Email: new FormControl(),
    Status: new FormControl(),
    Facebook: new FormControl(),
    Klangthai: new FormControl(),
    Nationality: new FormControl({ value: null, disabled: false })
  });

  AddressGroupForm = new FormGroup({
    country: new FormControl({ value: null }, Validators.required),
    Address: new FormControl({ value: null, disabled: false }, Validators.required),
    Province: new FormControl({ value: null, disabled: false }, Validators.required),
    District: new FormControl({ value: null, disabled: false }),
    SubDi: new FormControl({ value: null, disabled: false }),
    PostalCode: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.pattern(/^[0-9]*$/)]),
    Phone1: new FormControl({ value: null, disabled: false }, Validators.required),
    Phone2: new FormControl({ value: null, disabled: false }),
    Phone3: new FormControl({ value: null, disabled: false }),
    Phone4: new FormControl({ value: null, disabled: false }),
    Phone5: new FormControl({ value: null, disabled: false }),
  });

  SellerForm = new FormGroup({
    SellerDisplaytable: new FormControl(),
    Seller: new FormControl({ value: null, disabled: false }, Validators.required),
    SellerMemberID: new FormControl({ value: '', disabled: false }, Validators.required),
  });

  MoreInformationGroupForm = new FormGroup({
    cusDescription: new FormControl({ value: '', disabled: false }, Validators.required),
    cusValue: new FormControl({ value: '', disabled: false }, Validators.required),
  });

  public user = {} as AuthDataModel;
  userCode: any;
  userDesc: any;

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar: any = this.translateCalendarPipe.transform();

  calendarLanguage?: any;


  constructor(
    private autService: AuthenticationService,
    private messageService: MessageService,
    private parameterService: ParameterService,
    private parameterWmsSerive: ParameterWmsSerive,
    private parameterwms: ParameterWmsSerive,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private customerService: CustomerService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.customerId = route.snapshot.paramMap.get('customerId');
  }

  ngOnInit() {
    this.dataCustomer.infoData = [];
    this.tempData.infoData = [];
    this.dataCustomer.sellerData = [];
    this.tempData.sellerData = [];
    this.calendarLanguage = this.translateCalendar;
    this.checkNationlity = true;

    setTimeout(() => {
      this.types = [];
      this.types.push({ label: this.translateService.instant('CUSCUS200.M00038'), value: 'true' });
      this.types.push({ label: this.translateService.instant('CUSCUS200.M00037'), value: 'false' });
      if (!this.tempData.customer_status) {
        this.CustomerGroupForm.controls.Status.setValue('true');
      }
    }, 3000);

    this.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel
    this.userCode = this.user.userName ? this.user.userName : this.user.userDesc

    this.autService.getParameterWmsCountry().subscribe(response => {
      this.countryDDL = JSON.parse(JSON.stringify(response));
      if (this.countryDDL.length == 1) {
        this.AddressGroupForm.controls.country.setValue(this.countryDDL[0].value);
        this.changeCountry(this.countryDDL[0]);
        this.getCountryCurrency(this.countryDDL[0]);
      }
    });

    this.autService.getParameterWmsSeller(this.param.country, this.param.warehouse).subscribe((res: ParameterEntity[]) => {
      this.sellerDDL = JSON.parse(JSON.stringify(res));
    });

    this.parameterService.getvparameterDetailToLable(4501).subscribe((response) => {
      this.optionSex = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.parameterService.getvparameterDetailToLable(4502).subscribe((response) => {
      this.optionOccupation = response.map(detail => ({
        ...detail,
      })).sort((a, b) => {
        const numA = parseInt(a.label.split(' - ')[0], 10);
        const numB = parseInt(b.label.split(' - ')[0], 10);
        return numA - numB;
      });

      if (this.customerId) {
        let customerInquiry: CustomerInquiryRequest = {} as CustomerInquiryRequest;
        customerInquiry.customerId = this.customerId
        this.customerService.getCustomerDetailInquiry(customerInquiry).subscribe((customerList: Array<CustomerInquiryDetailModel>) => {
          customerList.forEach(data => {
            this.tempData.customer_id = data.customerId;
            this.tempData.customer_name = data.firstname;
            this.tempData.customer_lastname = data.lastname;
            this.tempData.customer_kp_default_memberID = data.kpdfmemid;
            this.tempData.customer_createby = data.createBy;
            if  (data.kpmenId == null) {
              data.kpmenId = "";
            }
            this.tempData.customer_kp_memberID = data.kpmenId;
            this.tempData.customer_Klangthai = data.klangThaitMemberCode;
            this.tempData.customer_tax_id = data.taxId;
            this.tempData.customer_birth = data.birth;
            this.tempData.customer_email = data.email;
            this.tempData.customer_occupertion = data.occupation;
            this.tempData.customer_occupertionDes = data.occupationDesc;
            this.tempData.customer_status = data.stat;
            this.tempData.customer_sex = data.sex;
            this.tempData.customer_url_facebook = data.facebook;
            this.tempData.customer_nationality = data.nationality;
            this.tempData.customer_address = data.address;
            this.tempData.customer_country = data.country;
            this.tempData.customer_subdistrict = data.subdistrict;
            this.tempData.customer_district = data.district;
            this.tempData.customer_province = data.province;
            this.tempData.customer_postal_code = data.postCode;
            this.tempData.customer_phone1 = data.phoneNumber;
            this.tempData.customer_phone2 = data.phoneNumber2;
            this.tempData.customer_phone3 = data.phoneNumber3;
            this.tempData.customer_phone4 = data.phoneNumber4;
            this.tempData.customer_phone5 = data.phoneNumber5;
          })
          this.populateCustomer(customerList[0])
        });
      } else {
        this.CustomerGroupForm.controls['CreateBy'].setValue(this.userCode);
      }
    }, err => {
      console.log(err);
    });

    this.parameterService.getvparameterDetailToLable(4503).subscribe((response) => {
      this.optionNationality = JSON.parse(JSON.stringify(response));
      if (this.countryDDL.length == 1 && this.AddressGroupForm.controls['country'].value) {
        if (this.AddressGroupForm.controls['country'].value === 'KH') {
          this.nation_data = '2';
        } else if (this.AddressGroupForm.controls['country'].value === 'TH')  {
        this.nation_data = '1';
        } else if (this.AddressGroupForm.controls['country'].value === 'LA')  {
          this.nation_data = '3';
        }  else if (this.AddressGroupForm.controls['country'].value === 'MM')  {
          this.nation_data = '4';
        }
        console.log('this.optionNationality',this.optionNationality);
        this.optionNationality.forEach((data) => {
          if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.nation_data) {
            this.CustomerGroupForm.controls.Nationality.setValue(data.value);
          }
        });
      }
    }, err => {
      console.log(err);
    });


    if (this.customerId) {
      let customerInquiry: CustomerInquiryRequest = {} as CustomerInquiryRequest;
      customerInquiry.customerId = this.customerId
      this.customerService.getCustomerSeller(customerInquiry).subscribe((CustomerList: Array<CustomerSellerModel>) => {
        this.dataCustomer.sellerData = JSON.parse(JSON.stringify(CustomerList));
        this.tempData.sellerData = JSON.parse(JSON.stringify(CustomerList));
        this.firstRow = 0;
      });
    }

    if (this.customerId) {
      let customerInquiry: CustomerInquiryRequest = {} as CustomerInquiryRequest;
      customerInquiry.customerId = this.customerId
      this.customerService.getCustomerMoreInfo(customerInquiry).subscribe((CustomerList: Array<CustomerMoreInfoModel>) => {
        this.dataCustomer.infoData = JSON.parse(JSON.stringify(CustomerList));
        this.tempData.infoData = JSON.parse(JSON.stringify(CustomerList));
      });
    }

    this.CustomerGroupForm.valueChanges.subscribe(() => {
      this.isDataButtonSave = this.checkDifference();
    });

    this.AddressGroupForm.valueChanges.subscribe(() => {
      this.isDataButtonSave = this.checkDifference();
    });

    this.CustomerGroupForm.get('Birth').valueChanges.subscribe(() => {
      if (this.CustomerGroupForm.get('Birth').value) {
        this.calculateAge();
      }
    });

    this.ischange = true;

  }

  KeyPress(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const currentValue: string = inputElement.value;
    const keyPressed: string = event.key;
    const isDigitOrPlus: boolean = (keyPressed >= '0' && keyPressed <= '9') || keyPressed === '+';
    const isFirstPlus: boolean = keyPressed === '+' && !currentValue.includes('+') && inputElement.selectionStart === 0;
    if (!isDigitOrPlus || (!isFirstPlus && keyPressed === '+')) {
        event.preventDefault();
    }
  }

  checkDifference() {
    if (this.tempData.customer_id) {
      // prepare data
      if (this.CustomerGroupForm.controls['CusName'].value == '') {
        this.CustomerGroupForm.controls['CusName'].setValue(null);
      }
      if (this.CustomerGroupForm.controls['CusName'].value == '') {
        this.CustomerGroupForm.controls['CusLastName'].setValue(null);
      }
      if (this.CustomerGroupForm.controls['Klangthai'].value == '') {
        this.CustomerGroupForm.controls['Klangthai'].setValue(null);
      }
      if (typeof this.CustomerGroupForm.controls['Birth'].value == 'string') {
        const birthDateString: string = this.CustomerGroupForm.controls['Birth'].value;
        const [dayStr, monthStr, yearStr] = birthDateString.split('/');
        const date = new Date(`${yearStr}-${monthStr}-${dayStr}`);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        var customer_birth = `${day}/${month}/${year}`;
      } else {
        const date = new Date(this.CustomerGroupForm.controls['Birth'].value);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        var customer_birth = `${day}/${month}/${year}`;
      }
      if (this.CustomerGroupForm.controls['TaxID'].value == null) {
        this.CustomerGroupForm.controls['TaxID'].setValue('');
      }
      if (this.CustomerGroupForm.controls['Email'].value == '') {
        this.CustomerGroupForm.controls['Email'].setValue(null);
      }
      if (this.CustomerGroupForm.controls['Facebook'].value == '') {
        this.CustomerGroupForm.controls['Facebook'].setValue(null);
      }
      if (this.AddressGroupForm.controls['Phone1'].value == '') {
        this.AddressGroupForm.controls['Phone1'].setValue(null);
      }
      if (this.AddressGroupForm.controls['Phone2'].value == '') {
        this.AddressGroupForm.controls['Phone2'].setValue(null);
      }
      if (this.AddressGroupForm.controls['Phone3'].value == '') {
        this.AddressGroupForm.controls['Phone3'].setValue(null);
      }
      if (this.AddressGroupForm.controls['Phone4'].value == '') {
        this.AddressGroupForm.controls['Phone4'].setValue(null);
      }
      if (this.AddressGroupForm.controls['Phone5'].value == '') {
        this.AddressGroupForm.controls['Phone5'].setValue(null);
      }
        // check change
        if (this.CustomerGroupForm.valid && this.AddressGroupForm.valid) {
          if (this.CustomerGroupForm.controls['Occu'].value) {
            if (this.tempData.customer_occupertion !== this.CustomerGroupForm.controls['Occu'].value.parameterDetailPK.pmdtbdtbdentcd) {
              return false;
            }
          }
          if (this.CustomerGroupForm.controls['Nationality'].value) {
            if (this.tempData.customer_nationality !== this.CustomerGroupForm.controls['Nationality'].value.parameterDetailPK.pmdtbdtbdentcd) {
              return false;
            }
          }
          if (this.InfoDataDifferent(this.tempData.infoData, this.dataCustomer.infoData)) {
            return false;
          } else if (this.SellerDataDifferent(this.tempData.sellerData, this.dataCustomer.sellerData)) {
            return false;
          } else if (this.tempData.customer_kp_default_memberID !== this.CustomerGroupForm.controls['kpDefaultMemberId'].value) {
            return false;
          } else if (this.tempData.customer_name !== this.CustomerGroupForm.controls['CusName'].value) {
            return false;
          } else if (this.tempData.customer_lastname !== this.CustomerGroupForm.controls['CusLastName'].value) {
            return false;
          } else if (this.tempData.customer_Klangthai !== this.CustomerGroupForm.controls['Klangthai'].value) {
            return false;
          } else if (this.tempData.customer_sex !== this.CustomerGroupForm.controls['Sex'].value.parameterDetailPK.pmdtbdtbdentcd) {
            return false;
          } else if (this.tempData.customer_birth !==customer_birth) {
            return false;
          } else if (this.tempData.customer_tax_id !== (this.CustomerGroupForm.controls['TaxID'].value == ''?null:this.CustomerGroupForm.controls['TaxID'].value)) {
            return false;
          } else if (this.tempData.customer_status !== this.CustomerGroupForm.controls['Status'].value) {
            return false;
          } else if (this.tempData.customer_occupertionDes !== this.CustomerGroupForm.controls['OccuDes'].value) {
            return false;
          } else if (this.tempData.customer_email !== this.CustomerGroupForm.controls['Email'].value) {
            return false;
          } else if (this.tempData.customer_url_facebook !==this.CustomerGroupForm.controls['Facebook'].value) {
            return false;
          } else if (this.tempData.customer_address !== this.AddressGroupForm.controls['Address'].value) {
            return false;
          } else if (this.tempData.customer_country !== this.AddressGroupForm.controls['country'].value) {
            return false;
          } else if (this.tempData.customer_province !== this.AddressGroupForm.controls['Province'].value) {
            return false;
          } else if (this.tempData.customer_district !== this.AddressGroupForm.controls['District'].value) {
            return false;
          } else if (this.tempData.customer_subdistrict !== this.AddressGroupForm.controls['SubDi'].value) {
            return false;
          } else if (this.tempData.customer_postal_code !== this.AddressGroupForm.controls['PostalCode'].value) {
            return false;
          } else if (this.tempData.customer_phone1 !== this.AddressGroupForm.controls['Phone1'].value) {
            return false;
          } else if (this.tempData.customer_phone2 !== this.AddressGroupForm.controls['Phone2'].value) {
            return false;
          } else if (this.tempData.customer_phone3 !== this.AddressGroupForm.controls['Phone3'].value) {
            return false;
          } else if (this.tempData.customer_phone4 !== this.AddressGroupForm.controls['Phone4'].value) {
            return false;
          } else if (this.tempData.customer_phone5 !== this.AddressGroupForm.controls['Phone5'].value) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
    } else {
      if (this.CustomerGroupForm.valid && this.AddressGroupForm.valid) {
        return false;
      } else {
        return true;
      }
    }
  }

  InfoDataDifferent(array1: InfoData[], array2: InfoData[]): boolean {
    if (array1.length !== array2.length) {
      return true;
    }

    for (let i = 0; i < array1.length; i++) {
      if (
        array1[i].customer_id !== array2[i].customer_id ||
        array1[i].customer_name !== array2[i].customer_name ||
        array1[i].infoId !== array2[i].infoId ||
        array1[i].cusDescription !== array2[i].cusDescription ||
        array1[i].cusValue !== array2[i].cusValue
      ) {
        return true;
      }
    }

    return false;
  }

  SellerDataDifferent(array1: SellerData[], array2: SellerData[]): boolean {
    if (array1.length !== array2.length) {
      return true;
    }

    for (let i = 0; i < array1.length; i++) {
      if (
        array1[i].customer_id !== array2[i].customer_id ||
        array1[i].customer_name !== array2[i].customer_name ||
        array1[i].sellerId !== array2[i].sellerId ||
        array1[i].seller !== array2[i].seller ||
        array1[i].sellerMemberId !== array2[i].sellerMemberId
      ) {
        return true;
      }
    }

    return false;
  }

  backBtn() {
    this.ischange = this.checkDifference();

    if (this.isDataButtonSave === true && !this.ischange === true) {
      this.router.navigate(['/cuscus/cuscus100'])
    } else if (!this.ischange) {
      this.messageService.add({ key: 'backpage', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00182', detail: 'ORDSOR200.M00183' });
    } else {
      this.router.navigate(['/cuscus/cuscus100'])
    }
  }

  populateCustomer(data: CustomerInquiryDetailModel) {
    let defaultCountry = { value: data.country }
    let defaultProvince = { value: data.province }
    let defaultDistrict = { value: data.district }
    this.getCountryCurrency(defaultCountry);
    this.changeProvince(defaultProvince, null);
    this.changeDistrict(defaultDistrict, null);
    this.CustomerGroupForm.controls.customerId.setValue(data.customerId.toString());
    this.CustomerGroupForm.controls.kpDefaultMemberId.setValue(data.kpdfmemid);
    this.CustomerGroupForm.controls.kpMemberId.setValue(data.kpmenId.toString());
    this.CustomerGroupForm.controls.CreateBy.setValue(data.createBy);
    this.CustomerGroupForm.controls.CusName.setValue(data.firstname);
    this.CustomerGroupForm.controls.CusLastName.setValue(data.lastname);
    this.CustomerGroupForm.controls.Birth.setValue(data.birth);
    this.CustomerGroupForm.controls.TaxID.setValue(data.taxId);
    this.CustomerGroupForm.controls.Klangthai.setValue(data.klangThaitMemberCode);
    this.CustomerGroupForm.controls.OccuDes.setValue(data.occupationDesc);
    this.CustomerGroupForm.controls.Email.setValue(data.email);
    this.CustomerGroupForm.controls.Status.setValue(data.stat);
    this.CustomerGroupForm.controls.Age.setValue(data.age);
    this.CustomerGroupForm.controls.Facebook.setValue(data.facebook);

    this.AddressGroupForm.controls.country.setValue(data.country);
    this.AddressGroupForm.controls.Address.setValue(data.address);
    this.AddressGroupForm.controls.Province.setValue(data.province);
    this.AddressGroupForm.controls.District.setValue(data.district);
    this.AddressGroupForm.controls.SubDi.setValue(data.subdistrict);
    this.AddressGroupForm.controls.PostalCode.setValue(data.postCode);
    this.AddressGroupForm.controls.Phone1.setValue(data.phoneNumber);
    this.AddressGroupForm.controls.Phone2.setValue(data.phoneNumber2);
    this.AddressGroupForm.controls.Phone3.setValue(data.phoneNumber3);
    this.AddressGroupForm.controls.Phone4.setValue(data.phoneNumber4);
    this.AddressGroupForm.controls.Phone5.setValue(data.phoneNumber5);

    if (data.sex) {
      const defaultSex = this.optionSex.find(item => item.value.parameterDetailPK.pmdtbdtbdentcd === data.sex);
      if (defaultSex) {
          this.CustomerGroupForm.controls.Sex.setValue(defaultSex.value);
          this.sex_data = data.sex;
      }
    }

    if (data.nationality) {
      this.checkNationlity = false;
      const defaultnNtionality = this.optionNationality.find(item => item.value.parameterDetailPK.pmdtbdtbdentcd === data.nationality);
      if (defaultnNtionality) {
          this.CustomerGroupForm.controls.Nationality.setValue(defaultnNtionality.value);
          this.nation_data = data.nationality;
      }
    }

    if (data.occupation) {
      const defaultOccupation = this.optionOccupation.find(option => option.value.parameterDetailPK.pmdtbdtbdentcd === data.occupation);
      if (defaultOccupation) {
        if (data.occupation == '22') {
          this.CustomerGroupForm.controls.OccuDes.enable();
        }
        this.CustomerGroupForm.controls.Occu.setValue(defaultOccupation.value);
      }
    }
    this.parameterwms.getAddressWms(102, data.country).subscribe(response => {
      this.provinceCustomerTemp = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.parameterwms.getAddressWms(103, data.province).subscribe(response => {
      this.districtCustomerTemp = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.parameterwms.getAddressWms(104, data.district).subscribe(response => {
      this.subDistrictCustomerTemp = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.isDataButtonSave = true;
  }

  changeDataCustomer(subdistrict) {
    if (subdistrict) {
        this.parameterWmsSerive.getParameterWmsBytableNoAndCode(104, subdistrict.value).subscribe((response) => {
          this.dataCustomer.customer_postal_code = response[0].parameterData.parameter_v2;
          this.AddressGroupForm.controls.PostalCode.setValue(this.dataCustomer.customer_postal_code);
        }, err => {
          console.log(err);
        });
    }
  }

  changeCountry(page) {
    this.messageService.clear();
    this.subDistrictCustomer = null;
    this.districtCustomer = null;

    this.AddressGroupForm.controls.PostalCode.setValue(this.subDistrictCustomer);

    this.parameterwms.getParameterWmsBytableNoAndCode(105, page.value).subscribe((responseParam) => {
      let respones = JSON.parse(JSON.stringify(responseParam));
      this.flagGetPostcode = respones[0].parameterData.parameter_v2;
    }, err => {
      console.log(err);
    });
  }

  deleteItemInfoSelect() {
    this.messageService.clear();
    this.messageService.add({ key: 'deleteInformation', sticky: true, severity: 'warn', detail: 'CUSCUS200.M000331' });
  }

  deleteItemInfoConfirm() {
    this.messageService.clear();
    this.dataCustomer.infoData = this.dataCustomer.infoData.filter((val) => !this.selectedInfo.some(selectedVal => (selectedVal.cusDescription === val.cusDescription && selectedVal.cusValue === val.cusValue)));
    this.isDataButtonSave = this.checkDifference();
    this.selectedInfo = null;
    this.messageService.clear('deleteInformation');
  }

  deleteItemInfoCancel() {
    this.messageService.clear('deleteInformation');
  }

  deleteItemSellSelect() {
    this.messageService.clear();
    this.messageService.add({ key: 'deleteBySeller', sticky: true, severity: 'warn', detail: 'CUSCUS200.M000331' });
  }

  deleteItemSellConfirm() {
    this.messageService.clear();
    this.dataCustomer.sellerData = this.dataCustomer.sellerData.filter((val) => !this.selectedSell.some(selectedVal => (selectedVal.seller === val.seller && selectedVal.sellerMemberId === val.sellerMemberId)));
    this.isDataButtonSave = this.checkDifference();
    this.selectedSell = null;
    this.messageService.clear('deleteBySeller');
  }

  deleteItemSellCancel() {
    this.messageService.clear('deleteBySeller');
  }

  backCancel() {
    this.messageService.clear('backpage');
    this.messageService.clear('createCustomer')
  }

  backpage() {
    this.router.navigate(['/cuscus/cuscus100'])
  }

  closeError() {
    this.messageService.clear('error');
  }

  closeErrorPostal() {
    this.messageService.clear('errorpostal');
  }

  closeNumError() {
    this.messageService.clear('errornumber');
  }


  // validate
  calculateAge() {
    if (this.CustomerGroupForm.controls.Birth.value) {
      let ageTmp;
      var timeDiff = Math.abs(Date.now() - this.CustomerGroupForm.controls.Birth.value);
      //Used Math.floor instead of Math.ceil
      //so 26 years and 140 days would be considered as 26, not 27.
      ageTmp = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      this.CustomerGroupForm.controls.Age.setValue(ageTmp);
    }
  }

  setTempPhoneNumber() {
    this.tempPhoneNumber = this.AddressGroupForm.controls['Phone1'].value
    this.tempPhoneNumber2 = this.AddressGroupForm.controls['Phone2'].value
    this.tempPhoneNumber3 = this.AddressGroupForm.controls['Phone3'].value
    this.tempPhoneNumber4 = this.AddressGroupForm.controls['Phone4'].value
    this.tempPhoneNumber5 = this.AddressGroupForm.controls['Phone5'].value
  }

  focusOutValidatePhoneNumber(field: string) {

    if (this.tempPhoneNumber != this.AddressGroupForm.controls['Phone1'].value ||
      this.tempPhoneNumber2 != this.AddressGroupForm.controls['Phone2'].value ||
      this.tempPhoneNumber3 != this.AddressGroupForm.controls['Phone3'].value ||
      this.tempPhoneNumber4 != this.AddressGroupForm.controls['Phone4'].value ||
      this.tempPhoneNumber5 != this.AddressGroupForm.controls['Phone5'].value ) {
      setTimeout(() => {
        const phoneFields = {
          Phone1: this.AddressGroupForm.controls['Phone1'].value ? this.AddressGroupForm.controls['Phone1'].value.replace(/^\+/, '') : null,
          Phone2: this.AddressGroupForm.controls['Phone2'].value ? this.AddressGroupForm.controls['Phone2'].value.replace(/^\+/, '') : null,
          Phone3: this.AddressGroupForm.controls['Phone3'].value ? this.AddressGroupForm.controls['Phone3'].value.replace(/^\+/, '') : null,
          Phone4: this.AddressGroupForm.controls['Phone4'].value ? this.AddressGroupForm.controls['Phone4'].value.replace(/^\+/, '') : null,
          Phone5: this.AddressGroupForm.controls['Phone5'].value ? this.AddressGroupForm.controls['Phone5'].value.replace(/^\+/, '') : null
        };

        delete phoneFields[field];

        const currentPhoneValue = this.AddressGroupForm.controls[field].value ? this.AddressGroupForm.controls[field].value.replace(/^\+/, '') : null;

        if (currentPhoneValue) {
          let isDuplicate = Object.values(phoneFields).includes(currentPhoneValue);

          let customerInquiry: CustomerInquiryRequest = {} as CustomerInquiryRequest;
          customerInquiry.phoneNumber = currentPhoneValue;
          this.customerService.getCustomerPhone(customerInquiry).subscribe((CustomerList: Array<CustomerInquiryDetailModel>) => {
            this.customerDetailList = CustomerList;
            let temp_phone_number = this.customerDetailList

            if (this.customerId) {
              temp_phone_number = temp_phone_number.filter(customer => customer.customerId !== this.customerId);
            }

            temp_phone_number.forEach(
              (customer) => {

                const temp = {
                  Phone1: customer.phoneNumber ? customer.phoneNumber.replace(/^\+/, '') : null,
                  Phone2: customer.phoneNumber2 ? customer.phoneNumber2.replace(/^\+/, '') : null,
                  Phone3: customer.phoneNumber3 ? customer.phoneNumber3.replace(/^\+/, '') : null,
                  Phone4: customer.phoneNumber4 ? customer.phoneNumber4.replace(/^\+/, '') : null,
                  Phone5: customer.phoneNumber5 ? customer.phoneNumber5.replace(/^\+/, '') : null
                };
                if (!isDuplicate) {
                  isDuplicate = Object.values(temp).includes(currentPhoneValue);
                }
              }
            )

            if (isDuplicate && currentPhoneValue) {
              this.messageService.add({ key: 'errornumber', sticky: true, severity: 'error', detail: this.translateService.instant('CUSCUS200.M00329') });
              const excludedFields = ['Phone1', 'Phone2', 'Phone3', 'Phone4', 'Phone5'];
              if (!excludedFields.includes(field)) {
                this.AddressGroupForm.controls[field].reset();
              }
            }

          });
        }
      }, 300);
    }
  }

  onPaste(event: ClipboardEvent, controlName: string): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');

    const sanitizedText = pastedText.replace(/[^0-9+]/g, '');

    event.preventDefault();
    const input = event.target as HTMLInputElement;
    input.value = sanitizedText;

    this.AddressGroupForm.controls[controlName].setValue(input.value);
  }

  validateDupplicateInfo(): boolean {
    this.isCheckDupplicateInfo = true;
    if (this.dataCustomer.infoData) {
      this.dataCustomer.infoData.forEach((val) => {
        if (val.cusDescription == this.MoreInformationGroupForm.get('cusDescription').value) {
          this.isCheckDupplicateInfo = false;
        }
      });
    }
    if (!this.isCheckDupplicateInfo) {
      this.messageService.clear();
      this.messageService.add({ key: 'error', sticky: true, severity: 'error', detail: this.translateService.instant('CUSCUS200.M000329') });
      this.MoreInformationGroupForm.get('cusDescription').reset();
      this.MoreInformationGroupForm.get('cusValue').reset();
      return false;
    }
    return true;
  }

  validateDupplicateInfoModeEdit(index: any): boolean {
    this.isCheckDupplicateInfo = true;
    if (this.dataCustomer.infoData) {
      this.dataCustomer.infoData.forEach((val) => {
        if (val.cusDescription !== this.dataCustomer.infoData[index].cusDescription) {
          if (this.MoreInformationGroupForm.get('cusDescription').value == val.cusDescription) {
            this.isCheckDupplicateInfo = false;
          }
        }
      });
    }
    if (!this.isCheckDupplicateInfo) {
      this.messageService.clear();
      this.messageService.add({ key: 'error', sticky: true, severity: 'error', detail: this.translateService.instant('CUSCUS200.M000329') });
      this.MoreInformationGroupForm.get('cusDescription').reset();
      this.MoreInformationGroupForm.get('cusValue').reset();
      return false;
    }
    return true;
  }

  validateDupplicateSeller(): boolean {
    this.isCheckDupplicateSeller = true;
    if (this.dataCustomer.sellerData) {
      this.dataCustomer.sellerData.forEach((val) => {
        if (val.seller == this.SellerForm.get('Seller').value) {
          this.isCheckDupplicateSeller = false;
        }
      });
      if (!this.isCheckDupplicateSeller) {
        this.messageService.clear();
        this.messageService.add({ key: 'error', sticky: true, severity: 'error', detail: this.translateService.instant('CUSCUS200.M000329') });
        this.SellerForm.get('Seller').reset();
        return false;
      }
    }
    return true;
  }

  //---------------------- dialog info ----------------------------
  checkedInfo(isChecker: any, row) {
    this.row = row;
    if (isChecker) {
      this.dataTableIInfo[row].info_check = true;
    } else {
      this.dataTableIInfo[row].info_check = false;
    }

    for (let i = 0; i < this.dataTableIInfo.length; i++) {
      let index = this.dataTableIInfo.findIndex(x => x.info_check === true);
      if (index !== -1) {
        this.isChecker = true;

      } else {
        this.isChecker = false;
      }
    }
  }

  checkedAllInfo(isChecker: any, row) {
    this.row = row;
    if (isChecker) {
      this.isChecker = isChecker;
      this.dataTableIInfo.forEach(x => x.info_check = true);
    } else {
      this.isChecker = isChecker;
      this.dataTableIInfo.forEach(x => x.info_check = false);
    }
  }
  addItemInfo() {
    this.otherModel.displayDialogInfo = true;
    this.otherModel.statusDialogInfo = 'ADD';
  }
  editInfo(index) {
    this.otherModel.displayDialogInfo = true;
    this.otherModel.statusDialogInfo = 'EDIT';
    this.otherModel.indexEditInfo = index;

    this.MoreInformationGroupForm.controls['cusDescription'].setValue(this.dataCustomer.infoData[index].cusDescription);
    this.MoreInformationGroupForm.controls['cusValue'].setValue(this.dataCustomer.infoData[index].cusValue);

  }
  deleteInfo(index) {
    if (this.dataCustomer.infoData[index].infoId) {
      if (this.dataCustomer.deleteInfo == null) {
        this.dataCustomer.deleteInfo = [] as Array<number>;
      }
      this.dataCustomer.deleteInfo.push(this.dataCustomer.infoData[index].infoId);
    }
    this.dataCustomer.infoData.splice(index, 1);
    if (this.dataCustomer.infoData.length == 0) {
      this.otherModel.dataNotFoundGridAtr == true;
    }
  }

  saveInfo() {
    if (this.MoreInformationGroupForm.valid) {
      if (this.otherModel.statusDialogInfo == 'ADD') {
        if (this.validateDupplicateInfo()) {
          if (!this.dataCustomer.infoData) {
            this.dataCustomer.infoData = [] as Array<InfoData>
          }
          this.dataCustomer.infoData.push({
            customer_id: this.CustomerGroupForm.controls['customerId'].value,
            customer_name: this.userCode,
            infoId: null,
            cusDescription: this.MoreInformationGroupForm.get('cusDescription').value,
            cusValue: this.MoreInformationGroupForm.get('cusValue').value,
            info_check: null,
          });
          this.isDataButtonSave = this.checkDifference();
          this.MoreInformationGroupForm.reset();
          this.otherModel.displayDialogInfo = false;
        }
      } else if (this.otherModel.statusDialogInfo == 'EDIT') {
        if (this.validateDupplicateInfoModeEdit(this.otherModel.indexEditInfo)) {
          this.dataCustomer.infoData[this.otherModel.indexEditInfo].cusDescription = this.MoreInformationGroupForm.get('cusDescription').value;
          this.dataCustomer.infoData[this.otherModel.indexEditInfo].cusValue = this.MoreInformationGroupForm.get('cusValue').value;
          this.MoreInformationGroupForm.reset();
          this.otherModel.displayDialogInfo = false;
          this.isDataButtonSave = this.checkDifference();
        }
      }
    } else {
      this.messageService.clear();
      this.messageService.add({ key: 'error', sticky: true, severity: 'error', detail: 'ADMUSR200.M00062' });
    }
  }

  cancelInfo() {
    this.MoreInformationGroupForm.reset();
    this.otherModel.displayDialogInfo = false;
  }

  // ---------------------- dialog seller-------------------------
  checked(isChecker: any, row) {
    this.row = row;
    if (isChecker) {
      this.dataTableSeller[row].seller_check = true;
    } else {
      this.dataTableSeller[row].seller_check = false;
    }

    for (let i = 0; i < this.dataTableSeller.length; i++) {
      let index = this.dataTableSeller.findIndex(x => x.seller_check === true);
      if (index !== -1) {
        this.isChecker = true;
      } else {
        this.isChecker = false;
      }
    }
  }
  checkedAll(isChecker: any, row) {
    this.row = row;
    if (isChecker) {
      this.isChecker = isChecker;
      this.dataTableSeller.forEach(x => x.seller_check = true);
    } else {
      this.isChecker = isChecker;
      this.dataTableSeller.forEach(x => x.seller_check = false);
    }
  }
  addItemSell() {
    this.otherModel.displayDialogAtr = true;
    this.otherModel.statusDialogSel = 'ADD';
  }
  editSeller(index) {
    this.otherModel.displayDialogAtr = true;
    this.otherModel.statusDialogSel = 'EDIT';
    this.otherModel.indexEditAtr = index;

    this.SellerForm.controls['Seller'].setValue(this.dataCustomer.sellerData[index].seller);
    this.SellerForm.controls['SellerMemberID'].setValue(this.dataCustomer.sellerData[index].sellerMemberId);
  }

  deleteSeller(index) {
    if (this.dataCustomer.sellerData[index].sellerId) {
      if (this.dataCustomer.deleteSeller == null) {
        this.dataCustomer.deleteSeller = [] as Array<number>;
      }
      this.dataCustomer.deleteSeller.push(this.dataCustomer.sellerData[index].sellerId);
    }
    this.dataCustomer.sellerData.splice(index, 1);
    if (this.dataCustomer.sellerData.length == 0) {
      this.otherModel.dataNotFoundGridAtr == true;
    }
  }

  saveSeller() {
    if (this.SellerForm.valid) {
      if (this.otherModel.statusDialogSel == 'ADD') {
        if (this.validateDupplicateSeller()) {
          if (!this.dataCustomer.sellerData) {
            this.dataCustomer.sellerData = [] as Array<SellerData>
          }
          this.dataCustomer.sellerData.push({
            customer_id: this.CustomerGroupForm.controls['customerId'].value,
            customer_name: this.userCode,
            sellerId: null,
            seller: this.SellerForm.get('Seller').value,
            sellerMemberId: this.SellerForm.get('SellerMemberID').value,
            seller_check: false,
          });
          this.isDataButtonSave = this.checkDifference();
        }
      } else if (this.otherModel.statusDialogSel == 'EDIT') {
        this.dataCustomer.sellerData[this.otherModel.indexEditAtr].seller = this.SellerForm.get('Seller').value;
        this.dataCustomer.sellerData[this.otherModel.indexEditAtr].sellerMemberId = this.SellerForm.get('SellerMemberID').value;
        this.isDataButtonSave = this.checkDifference();
      }
      this.Seller_dataDisplay = this.Seller_data;
      this.SellerForm.reset();
      this.otherModel.displayDialogAtr = false;
    } else {
      this.messageService.clear();
      this.messageService.add({ key: 'error', sticky: true, severity: 'error', detail: 'ADMUSR200.M00062' });
    }
  }

  cancelArt() {
    this.SellerForm.reset();
    this.otherModel.displayDialogAtr = false;
  }
  // ------------------------------------------------------------------------------------------------------------------------------
  getSex(e) {
    this.sex_data = e.value.parameterDetailPK.pmdtbdtbdentcd;
  }

  getNationality(e) {
    this.nation_data = e.value.parameterDetailPK.pmdtbdtbdentcd;
    this.checkNationlity = false;
  }

  getSellerCode(seller) {
    this.isCheckDupplicateSeller = true;
    if (seller) {
      if (this.dataCustomer.sellerData) {
        this.dataCustomer.sellerData.forEach((val) => {
          if (val.seller == seller.value) {
            this.isCheckDupplicateSeller = false;
          }
        });
      }
      if (!this.isCheckDupplicateSeller) {
        this.messageService.clear();
        this.messageService.add({ key: 'error', sticky: true, severity: 'error', detail: this.translateService.instant('CUSCUS200.M000329') });
        this.SellerForm.get('Seller').reset();
      } else {
        this.Seller_data = this.sellerDDL.filter((val) => val.value == seller.value);
        this.Seller_data = this.Seller_data[0].label;
        this.SellerForm.controls['Seller'].setValue(seller.value);
      }
    }
  }

  getOccupation(e) {
    this.occu_data = e.value.parameterDetailPK.pmdtbdtbdentcd;
    if (e.value.parameterDetailPK.pmdtbdtbdentcd === '22') {
      this.CustomerGroupForm.controls.OccuDes.enable();
    } else {
      this.CustomerGroupForm.controls.OccuDes.disable();
      this.CustomerGroupForm.controls.OccuDes.setValue("");
    }

  }

  changeProvince(page, flagChange) {
    this.parameterwms.getAddressWms(103, page.value).subscribe(response => {
      this.districtCustomer = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
  }

  changeDistrict(page, flagChange) {
    this.parameterwms.getAddressWms(104, page.value).subscribe(response => {
      this.subDistrictCustomer = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    if (this.flagGetPostcode) {
      if (this.flagGetPostcode.toLowerCase() == 'district') {
        this.parameterWmsSerive.getParameterWmsBytableNoAndCode(103, page.value).subscribe((response) => {
          this.dataCustomer.customer_postal_code = response[0].parameterData.parameter_v2;
          this.AddressGroupForm.controls.PostalCode.setValue(this.dataCustomer.customer_postal_code);
        }, err => {
          console.log(err);
        });
      }
    }
  }

  getCountryCurrency(page) {
    this.seachProvinceByCoutry(page);
    if (this.checkNationlity) {
      if (this.AddressGroupForm.controls['country'].value === 'KH') {
        this.nation_data = '2';
      } else if (this.AddressGroupForm.controls['country'].value === 'TH')  {
      this.nation_data = '1';
      } else if (this.AddressGroupForm.controls['country'].value === 'LA')  {
        this.nation_data = '3';
      }  else if (this.AddressGroupForm.controls['country'].value === 'MM')  {
        this.nation_data = '4';
      }
      this.optionNationality.forEach((data) => {
        if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.nation_data) {
          this.CustomerGroupForm.controls.Nationality.setValue(data.value);
        }
      });
    }
  }

  seachProvinceByCoutry(page) {
    this.parameterwms.getAddressWms(102, page.value).subscribe(response => {
      this.provinceCustomer = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
  }

  formatDate(date: string): string {
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  }

  SaveCustomer() {
    if (this.CustomerGroupForm.valid && this.AddressGroupForm.valid) {
      this.dataCustomer.customer_id = this.CustomerGroupForm.controls['customerId'].value;
      this.dataCustomer.customer_kp_default_memberID = this.CustomerGroupForm.controls['kpDefaultMemberId'].value;
      this.dataCustomer.customer_kp_memberID = this.CustomerGroupForm.controls['kpMemberId'].value;
      this.dataCustomer.customer_createby = this.CustomerGroupForm.controls['CreateBy'].value;
      this.dataCustomer.customer_updateby = this.userCode
      this.dataCustomer.customer_name = this.CustomerGroupForm.controls['CusName'].value;
      this.dataCustomer.customer_lastname = this.CustomerGroupForm.controls['CusLastName'].value;
      this.dataCustomer.customer_birth = this.CustomerGroupForm.controls['Birth'].value;
      this.dataCustomer.customer_sex = this.sex_data !== undefined && this.sex_data !== null ? this.sex_data.toString() : null;
      if (this.CustomerGroupForm.controls['Occu'].value) {
        this.dataCustomer.customer_occupertion = this.CustomerGroupForm.controls['Occu'].value.parameterDetailPK.pmdtbdtbdentcd !== null ? this.CustomerGroupForm.controls['Occu'].value.parameterDetailPK.pmdtbdtbdentcd : null;
        this.optionOccupation.forEach((data) => {
          if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_occupertion) {
            this.dataCustomer.customer_occupertionCdDes = data.label;
          }
        });
      }
      if (this.dataCustomer.customer_sex) {
        this.optionSex.forEach((data) => {
          if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_sex) {
            this.dataCustomer.customer_sexDes = data.label;
          }
        });
      }
      this.dataCustomer.customer_occupertionDes = this.CustomerGroupForm.controls['OccuDes'].value;
      this.dataCustomer.customer_nationality = this.nation_data !== undefined && this.nation_data !== null ? this.nation_data.toString() : null;
      if (this.dataCustomer.customer_nationality) {
        this.optionNationality.forEach((data) => {
          if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_nationality) {
            this.dataCustomer.customer_nationalityDes = data.label;
          }
        });
      }
      this.dataCustomer.customer_tax_id = this.CustomerGroupForm.controls['TaxID'].value == ''? null: this.CustomerGroupForm.controls['TaxID'].value;
      this.dataCustomer.customer_email = this.CustomerGroupForm.controls['Email'].value;
      this.dataCustomer.customer_status = this.CustomerGroupForm.controls['Status'].value;
      this.dataCustomer.customer_url_facebook = this.CustomerGroupForm.controls['Facebook'].value;
      this.dataCustomer.customer_Klangthai = this.CustomerGroupForm.controls['Klangthai'].value;
      this.dataCustomer.customer_country = this.AddressGroupForm.controls['country'].value;
      this.dataCustomer.customer_address = this.AddressGroupForm.controls['Address'].value;
      this.dataCustomer.customer_province = this.AddressGroupForm.controls['Province'].value;
      this.dataCustomer.customer_occupertionDes = this.CustomerGroupForm.controls['OccuDes'].value;
      if (this.dataCustomer.customer_province) {
        this.provinceCustomer.forEach((data) => {
          if (data.value === this.dataCustomer.customer_province) {
            this.dataCustomer.customer_provinceDes = data.label;
          }
        });
      }
      this.dataCustomer.customer_district = this.AddressGroupForm.controls['District'].value;
      if (this.dataCustomer.customer_district) {
        this.districtCustomer.forEach((data) => {
          if (data.value === this.dataCustomer.customer_district) {
            this.dataCustomer.customer_districtDes = data.label;
          }
        });
      }
      this.dataCustomer.customer_subdistrict = this.AddressGroupForm.controls['SubDi'].value;
      if (this.dataCustomer.customer_subdistrict) {
        this.subDistrictCustomer.forEach((data) => {
          if (data.value === this.dataCustomer.customer_subdistrict) {
            this.dataCustomer.customer_subdistrictDes = data.label;
          }
        });
      }
      this.dataCustomer.customer_postal_code = this.AddressGroupForm.controls['PostalCode'].value;
      this.dataCustomer.customer_phone1 = this.AddressGroupForm.controls['Phone1'].value;
      this.dataCustomer.customer_phone2 = this.AddressGroupForm.controls['Phone2'].value;
      this.dataCustomer.customer_phone3 = this.AddressGroupForm.controls['Phone3'].value;
      this.dataCustomer.customer_phone4 = this.AddressGroupForm.controls['Phone4'].value;
      this.dataCustomer.customer_phone5 = this.AddressGroupForm.controls['Phone5'].value;
      if (this.tempData != null) {
        this.tempData.customer_occupertionDes = this.tempData.customer_occupertionDes == null ? '':this.tempData.customer_occupertionDes;
        this.checkDifferentData(this.tempData, this.dataCustomer)
      }
      setTimeout(() => {
        if (moment(this.CustomerGroupForm.controls['Birth'].value).format('YYYY-MM-DD').toString() == 'Invalid date') {
          this.dataCustomer.customer_birth = this.formatDate(this.CustomerGroupForm.controls['Birth'].value);
        } else {
          this.dataCustomer.customer_birth = moment(this.CustomerGroupForm.controls['Birth'].value).format('YYYY-MM-DD').toString();
        }
      this.customerService.saveCustomerMaintenantDetail(this.dataCustomer).subscribe(res => {
        let result = JSON.parse(JSON.stringify(res));
        this.messageService.clear();
        let url = '/cuscus/cuscus200/' + result.code;
        this.CustomerGroupForm.controls['customerId'].setValue(result.code);
        this.CustomerGroupForm.controls['kpDefaultMemberId'].setValue(result.kp_default_memberID);
        this.CustomerGroupForm.controls['kpMemberId'].setValue(result.kp_memberID);
        this.messageService.add({ key: 'save', severity: 'success', detail: 'ADMUSR200.M00060' });
        this.redirectTo(url);
        window.scroll(0, 0);

      }, err => {
        console.log(err);
        this.messageService.clear();
        this.messageService.add({ key: 'error', sticky: true, severity: 'error', detail: 'CUSCUS200.M000330' });
      });
      }, 500);
    } else if ((this.AddressGroupForm.controls['Phone1'] || this.AddressGroupForm.controls['Phone2'] || this.AddressGroupForm.controls['Phone3'] || this.AddressGroupForm.controls['Phone4'] || this.AddressGroupForm.controls['Phone5']).hasError('pattern')) {
      this.messageService.clear();
      this.messageService.add({ key: 'errornumber', sticky: true, severity: 'error', detail: 'CUSCUS200.M00045' });
    } else if (this.AddressGroupForm.get('PostalCode').hasError('pattern')) {
      this.messageService.clear();
      this.messageService.add({ key: 'errorpostal', sticky: true, severity: 'error', detail: 'CUSCUS200.M00046' });
    } else {
      this.messageService.clear();
      this.messageService.add({ key: 'error', sticky: true, severity: 'error', detail: 'ADMUSR200.M00062' });
    }
  }

  redirectTo(uri: string) {
    this.router.navigate([uri]).then(() => {
      window.location.reload();
    });
  }


  checkDifferentData(obj1: any, obj2: any): void {
    this.customerHistoryList = [];
    // infoData
    // infoData Edit
    if (this.tempData.infoData.length > 0 && this.dataCustomer.infoData.length > 0) {
      this.tempData.infoData.forEach((oldItem) => {
        this.dataCustomer.infoData.forEach((newItem) => {
          if (oldItem.infoId === newItem.infoId) {
            if (oldItem.cusValue !== newItem.cusValue) {
              let newData: customerHistory = {
                customer_id: this.dataCustomer.customer_id as string,
                customer_name: this.dataCustomer.customer_updateby as string,
                customer_field: 'cusValue',
                customer_last_updated: oldItem.cusValue || '',
                customer_new_updated: newItem.cusValue || ''
              };
              this.customerHistoryList.push(newData);
            } else if (oldItem.cusDescription !== newItem.cusDescription) {
              let newData: customerHistory = {
                customer_id: this.dataCustomer.customer_id as string,
                customer_name: this.dataCustomer.customer_updateby as string,
                customer_field: 'cusDescription',
                customer_last_updated: oldItem.cusDescription || '',
                customer_new_updated: newItem.cusDescription || ''
              };
              this.customerHistoryList.push(newData);
            }
          }
        });
      });
    }

    // infoData add
    if (this.dataCustomer.infoData.length > 0) {
      this.dataCustomer.infoData.forEach((newItem) => {
        if (!newItem.infoId) {
          let newCusValueData: customerHistory = {
            customer_id: this.dataCustomer.customer_id as string,
            customer_name: this.dataCustomer.customer_updateby as string,
            customer_field: 'cusValue',
            customer_last_updated: '',
            customer_new_updated: newItem.cusValue || ''
          };
          this.customerHistoryList.push(newCusValueData);
          let newCusDescriptionData: customerHistory = {
            customer_id: this.dataCustomer.customer_id as string,
            customer_name: this.dataCustomer.customer_updateby as string,
            customer_field: 'cusDescription',
            customer_last_updated: '',
            customer_new_updated: newItem.cusDescription || ''
          };
          this.customerHistoryList.push(newCusDescriptionData);
        }
      });
    }

    // infoData Delete
    if (this.tempData.infoData.length > 0) {
      this.tempData.infoData.forEach((oldItem) => {
        const found = this.dataCustomer.infoData.some((newItem) => oldItem.infoId === newItem.infoId);
        if (!found) {
          let newVData: customerHistory = {
            customer_id: this.dataCustomer.customer_id as string,
            customer_name: this.dataCustomer.customer_updateby as string,
            customer_field: 'cusValue',
            customer_last_updated: oldItem.cusValue || '',
            customer_new_updated: 'DELETE'
          };
          this.customerHistoryList.push(newVData);
          let newDesData: customerHistory = {
            customer_id: this.dataCustomer.customer_id as string,
            customer_name: this.dataCustomer.customer_updateby as string,
            customer_field: 'cusDescription',
            customer_last_updated: oldItem.cusDescription || '',
            customer_new_updated: 'DELETE'
          };
          this.customerHistoryList.push(newDesData);
        }
      });
    }

    // sellerData
    // sellerData Edit
    if (this.tempData.sellerData.length > 0 && this.dataCustomer.sellerData.length > 0) {
      this.tempData.sellerData.forEach((oldItem) => {
        this.dataCustomer.sellerData.forEach((newItem) => {
          if (oldItem.seller === newItem.seller) {
            if (oldItem.sellerMemberId !== newItem.sellerMemberId) {
              let seller: customerHistory = {
                customer_id: this.dataCustomer.customer_id as string,
                customer_name: this.dataCustomer.customer_updateby as string,
                customer_field: 'sellerMemberId',
                customer_last_updated: oldItem.sellerMemberId || '',
                customer_new_updated: newItem.sellerMemberId || ''
              };
              this.customerHistoryList.push(seller);
            }
          }
        });
      });
    }

    // sellerData add
    if (this.dataCustomer.sellerData.length > 0) {
      if (this.tempData.sellerData.length > 0) {
        this.dataCustomer.sellerData.forEach((newItem) => {
          const found = this.tempData.sellerData.some((oldItem) => newItem.seller === oldItem.seller && newItem.sellerMemberId === oldItem.sellerMemberId);
          if (!found) {
            const exists = (customer_id: string, customer_field: string) => {
              return this.customerHistoryList.some((historyItem) =>
                historyItem.customer_id === customer_id && historyItem.customer_field === customer_field);
            };

            if (!exists(this.dataCustomer.customer_id as string, 'seller')) {
              let seller: customerHistory = {
                customer_id: this.dataCustomer.customer_id as string,
                customer_name: this.dataCustomer.customer_updateby as string,
                customer_field: 'seller',
                customer_last_updated: '',
                customer_new_updated: newItem.seller || ''
              };
              this.customerHistoryList.push(seller);
            }

            if (!exists(this.dataCustomer.customer_id as string, 'sellerMemberId')) {
              let sellerMemberId: customerHistory = {
                customer_id: this.dataCustomer.customer_id as string,
                customer_name: this.dataCustomer.customer_updateby as string,
                customer_field: 'sellerMemberId',
                customer_last_updated: '',
                customer_new_updated: newItem.sellerMemberId || ''
              };
              this.customerHistoryList.push(sellerMemberId);
            }
          }
        });
      } else if (this.tempData.sellerData.length === 0 && this.dataCustomer.customer_id) {
        this.dataCustomer.sellerData.forEach((newItem) => {
          let seller: customerHistory = {
            customer_id: this.dataCustomer.customer_id as string,
            customer_name: this.dataCustomer.customer_updateby as string,
            customer_field: 'seller',
            customer_last_updated: '',
            customer_new_updated: newItem.seller || ''
          };
          this.customerHistoryList.push(seller);
          let sellerMemberId: customerHistory = {
            customer_id: this.dataCustomer.customer_id as string,
            customer_name: this.dataCustomer.customer_updateby as string,
            customer_field: 'sellerMemberId',
            customer_last_updated: '',
            customer_new_updated: newItem.sellerMemberId || ''
          };
          this.customerHistoryList.push(sellerMemberId);
        });
      }
    }

    // sellerData Delete
    if (this.tempData.sellerData.length > 0) {
      this.tempData.sellerData.forEach((oldItem) => {
        const found = this.dataCustomer.sellerData.some((newItem) => oldItem.seller === newItem.seller);
        if (!found) {
          let newVData: customerHistory = {
            customer_id: this.dataCustomer.customer_id as string,
            customer_name: this.dataCustomer.customer_updateby as string,
            customer_field: 'seller',
            customer_last_updated: oldItem.seller || '',
            customer_new_updated: 'DELETE'
          };
          this.customerHistoryList.push(newVData);
          let newDesData: customerHistory = {
            customer_id: this.dataCustomer.customer_id as string,
            customer_name: this.dataCustomer.customer_updateby as string,
            customer_field: 'sellerMemberId',
            customer_last_updated: oldItem.sellerMemberId || '',
            customer_new_updated: 'DELETE'
          };
          this.customerHistoryList.push(newDesData);
        }
      });
    }

    for (let key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        } else {
          if (obj1[key] !== obj2[key]) {
            let newData: customerHistory = {
              customer_id: this.dataCustomer.customer_id as string,
              customer_name: this.dataCustomer.customer_updateby as string,
              customer_field: key,
              customer_last_updated: obj1[key] || '',
              customer_new_updated: obj2[key]
            };
            this.customerHistoryList.push(newData);
          }
        }
      }
    }


    if (this.customerHistoryList) {
      this.customerHistoryList.forEach((his) => {
        if (his.customer_field === 'customer_province') {
          const matchingDataLast = this.provinceCustomerTemp.find((data) => data.value === his.customer_last_updated);
          if (matchingDataLast) {
            his.customer_last_updated = `${matchingDataLast.label}`;
          }
          const matchingDataNew = this.provinceCustomer.find((data) => data.value === his.customer_new_updated);
          if (matchingDataNew) {
            his.customer_new_updated = `${matchingDataNew.label}`;
          }
        } else if (his.customer_field === 'customer_district') {
          if (this.districtCustomerTemp) {
            const matchingDataLast = this.districtCustomerTemp.find((data) => data.value === his.customer_last_updated);
            if (matchingDataLast) {
              his.customer_last_updated = `${matchingDataLast.label}`;
            }
          }
          const matchingDataNew = this.districtCustomer.find((data) => data.value === his.customer_new_updated);
          if (matchingDataNew) {
            his.customer_new_updated = `${matchingDataNew.label}`;
          }
        } else if (his.customer_field === 'customer_subdistrict') {
          if (this.subDistrictCustomerTemp) {
            const matchingDataLast = this.subDistrictCustomerTemp.find((data) => data.value === his.customer_last_updated);
            if (matchingDataLast) {
              his.customer_last_updated = `${matchingDataLast.label}`;
            }
          }
          const matchingDataNew = this.subDistrictCustomer.find((data) => data.value === his.customer_new_updated);
          if (matchingDataNew) {
            his.customer_new_updated = `${matchingDataNew.label}`;
          }
        } else if (his.customer_field === 'customer_birth') {
            his.customer_new_updated = this.formatDateHis(his.customer_new_updated);
        }
      })
    }
    this.customerService.saveCustomerHistory(this.customerHistoryList).subscribe();
  }

  formatDateHis(date: any): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // เดือนเริ่มจาก 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  checkDifferentDataArray(obj1: any, obj2: any): void {
    for (let key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          let newData: customerHistory = {
            customer_id: this.dataCustomer.customer_id as string,
            customer_name: this.dataCustomer.customer_createby as string,
            customer_field: key,
            customer_last_updated: obj1[key],
            customer_new_updated: obj2[key]
          };
          this.customerHistoryList.push(newData);
        }
      }
    }
  }

  createCustomer() {
    let url = '/cuscus/cuscus200'
    this.messageService.clear('createOreder');
    this.redirectTo(url);
  }

  createCustomerinfo() {
    this.ischange = this.checkDifference();

    if (this.isDataButtonSave === true && !this.ischange === true) {
      this.router.navigate(['/cuscus/cuscus100'])
    } else if (!this.ischange) {
      this.messageService.add({
        key: 'createCustomer',
        sticky: true,
        severity: 'warn',
        summary: 'CUSCUS200.M00052',
        detail: 'CUSCUS200.M00053'
      });
    } else {
      this.createCustomer();
    }
  }
}

