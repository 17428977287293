import { Component, OnInit } from '@angular/core';
import { Menu , MenuList } from '../../model/menu.model';
import { AdministrationMenuService } from '../../services/administration-menu.service';

@Component({
  selector: 'app-menu-v2',
  templateUrl: './menu-v2.component.html',
  styleUrls: ['./menu-v2.component.scss']
})
export class MenuV2Component implements OnInit {


  public groupMenuData: Array<Menu>;

  public visibleSidebar = false;
  public menuData: any = [];
  public profileId: any;
  public Country: String;

  constructor(private admmunService: AdministrationMenuService) {
    this.profileId = JSON.parse(localStorage.getItem('userData')).profileId;
    this.Country = localStorage.getItem('languageData') as String;
  }

  ngOnInit() {
    this.getGroupMenu();
  }

  public visibleSidebarFunction() {
    if (this.visibleSidebar) {
      this.visibleSidebar = false;
    } else {
      this.visibleSidebar = true;
    }
    return this.visibleSidebar;
  }

  getGroupMenu() {
    this.getMenuList();
    this.menuData = [];
    this.admmunService.getMenu(this.Country).subscribe((res: Menu[]) => {
      this.groupMenuData = res;
      this.groupMenuData = this.groupMenuData.filter(x => x.menuCode !== 'P90000');
      this.groupMenuData.forEach((element: Menu) => {
        const menusTemp: any = { label: '', icon: '', ordid: '', items: [] };
        menusTemp.label = element.menuDesc;
        menusTemp.icon = element.menuIcon;
        menusTemp.ordid = element.menuOrbid;

        element.subMenu.forEach(submenu => {
          const itemTemp: any = { label: '', url : '', ordid: '', target:''};
          // if (submenu.menuCode == 'O99999') {
          //   itemTemp.url = submenu.menuRout;
          //   itemTemp.target = '_blank';
          // } else {
          //   itemTemp.routerLink = submenu.menuRout;
          // }
          itemTemp.routerLink = submenu.menuRout;
          itemTemp.url = submenu.menuRout;
          itemTemp.target = '_blank';
          itemTemp.label = submenu.menuDesc;

          // itemTemp.icon = submenu.menuIcon;
          itemTemp.ordid = submenu.menuOrbid;
          itemTemp.command = () => {
            this.sendMenuName(itemTemp.label);
            this.visibleSidebarFunction();
          };
          menusTemp.items.push(itemTemp);
          menusTemp.items.sort((a: { ordid: number; }, b: { ordid: number; }) => a.ordid - b.ordid);

          submenu.subMenu.forEach(onSubmenu => {
            const itemSubTemp: any = {};
            itemSubTemp.label = onSubmenu.menuDesc;
            itemSubTemp.routerLink = onSubmenu.menuRout;
            itemSubTemp.url = onSubmenu.menuRout;
            itemSubTemp.icon = onSubmenu.menuIcon;
            itemSubTemp.ordid = onSubmenu.menuOrbid;
            itemSubTemp.target = '_blank';
            itemTemp.items.push(itemSubTemp);
            itemTemp.items.sort((a: { ordid: number; }, b: { ordid: number; }) => a.ordid - b.ordid);
          });
        });
        this.menuData.push(menusTemp);
      });
      this.menuData.sort((a: { ordid: number; }, b: { ordid: number; }) => a.ordid - b.ordid);
    });
  }

  sendMenuName(name: string): void {
    localStorage.setItem('menuName', name);
  }

  private getMenuList(): void {
    this.admmunService.getMenuList().subscribe((respones: Array<MenuList>) => {
      respones.forEach( (menuList: MenuList) => {
        localStorage.setItem(menuList.menuCode, menuList.menuDescription);
      });
    });
  }
}
