import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import * as moment from "moment";
import { Observable } from "rxjs";
import { date_format, date_format_system, th } from "src/app/shared/constant/date.constant";
import { CustomerHistoryModel, CustomerHistoryRequest } from "src/app/shared/model/customer-inquiry.model";
import { DropDownListModel } from "src/app/shared/model/dropdownlist.model";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { CustomerService } from 'src/app/shared/services/customer.service';
import { Cuscus300Model, FormModel, TempDDL } from "../model/cuscus300.model";

@Injectable({
    providedIn: 'root'
})

export class Cuscus300Service {
    data: any;
    optionSex: TempDDL[];
    optionOccupation: TempDDL[];
    cuscus300: Cuscus300Model = {} as Cuscus300Model;

    constructor(private customerService: CustomerService,
        private autService: AuthenticationService,
        private fb: FormBuilder) {
            this.inti();
        }
    inti(): void {
        this.cuscus300.menu_code = '';
        this.cuscus300.page_code = 'cuscus300';
        this.cuscus300.page_name = 'cuscus300';

        this.cuscus300.form_model = {} as FormModel;
        // Criterial Form
        this.cuscus300.form_model.criterial_form = this.fb.group({
            country: [null, Validators.required]
        });
        // Search Form
        this.cuscus300.form_model.search_form = this.fb.group({
            customerId: "",
            kpmenId: "",
            kpdfmemid: "",
            firstname: "",
            phoneNumber: "",
            updateUser: "",
            updateDate: "",
        });

        this.cuscus300.form_model.advance_search_form = this.fb.group({
            key: [null, Validators.required]
        });

        this.cuscus300.date = {};
        this.cuscus300.date.locale = th;
        this.cuscus300.date.date_format = date_format;

        this.cuscus300.data_list = {};
        this.cuscus300.data_list.country_data = [{label:'ไม่พบข้อมูล', value: '', engDesc:'No Data Found', localDesc:'ไม่พบข้อมูล'}];
        // this.cuscus300.data_list.sale_channel_data = [{label:'ไม่พบข้อมูล', value: '', engDesc:'No Data Found', localDesc:'ไม่พบข้อมูล'}];

        this.cuscus300.customer_inquiry_list = [];
        this.cuscus300.searchFormSummitFlag = true;

    }

    get load(): Cuscus300Model {
        return this.cuscus300;
    }

    loadComponanceData(model: Cuscus300Model): void {
        this.autService.getParameterWmsCountry().subscribe((response: Array<DropDownListModel>) => {
            model.data_list.country_data = response;

            if (model.data_list.country_data.length === 1) {
                model.form_model.criterial_form.controls['country'].setValue(model.data_list.country_data[0].value);
                model.form_model.criterial_form.get('country').disable();
            } else if (model.data_list.country_data.length === 0) {
                model.form_model.criterial_form.get('country').disable();
            } else {
                model.form_model.criterial_form.get('country').enable();
            }
        }, err => {
            console.log(err);
        });
    }

    searchFunction(): Observable<Array<CustomerHistoryModel>> {
        let customerInquiry: CustomerHistoryRequest = {} as CustomerHistoryRequest;
        customerInquiry.kpmenId = this.cuscus300.form_model.search_form.get('kpmenId').value;
        customerInquiry.kpdfmemid = this.cuscus300.form_model.search_form.get('kpdfmemid').value;
        customerInquiry.firstname = this.cuscus300.form_model.search_form.get('firstname').value;
        customerInquiry.phoneNumber = this.cuscus300.form_model.search_form.get('phoneNumber').value;
        customerInquiry.phoneNumber = customerInquiry.phoneNumber.replace(/^\+/, '')
        customerInquiry.updateUser = this.cuscus300.form_model.search_form.get('updateUser').value;
        customerInquiry.updateDate = this.cuscus300.form_model.search_form.get('updateDate').value;
        if (customerInquiry.updateDate) {
          const date: Date = new Date(customerInquiry.updateDate)
          const day: number = date.getDate();
          const month: number = date.getMonth() + 1;
          const year: number = date.getFullYear();
          customerInquiry.updateDate = `${day}-${month}-${year}`;
          console.log(customerInquiry.updateDate)
        }
        console.log(customerInquiry)
        return this.customerService.getCustomerHistory(customerInquiry);
    }
}
