// Angular
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HttpClientModule } from '@angular/common/http/';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { LanModule } from './lan/lan.module';
import { ItmModule } from './itm/itm.module';
import { CoreModule } from './core/core.module';
import { AdmModule } from './adm/adm.module';
import { DshModule } from './dsh/dsh.module';
import { HomModule } from './hom/hom.module';
import { FnaModule } from './fna/fna.module';
import { OrdModule } from './ord/ord.module';
import { AccModule } from './acc/acc.module';
import { PsaModule } from './psa/psa.module';
import { EmpModule } from './emp/emp.module';
import { ExcModule } from './exc/exc.module';
import { CusModule } from './cus/cus.module';
import { SerModule } from './ser/ser.module';
import { UplModule } from './upl/upl.module';
import { EwlModule } from './ewl/ewl.module';

import { HTTP_INTERCEPTORS} from '@angular/common/http/';
import { ConfirmationService, MessageService } from 'primeng/primeng';
import { AppRoutingModule } from './app-routing.module';
import { LoaderInterceptorService } from './shared/services/loader-interceptor.service';
import { ErrorInterceptor } from './shared/interceptors/error-interceptor';
import { SharedModule } from './shared/shared.module';



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    SharedModule,
    AdmModule,
    CoreModule,
    DshModule,
    HomModule,
    ItmModule,
    LanModule,
    FnaModule,
    OrdModule,
    AccModule,
    PsaModule,
    EmpModule,
    ExcModule,
    CusModule,
    SerModule,
    UplModule,
    EwlModule,
    ScrollToModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [MessageService, ConfirmationService ,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor , multi: true  },
    Title
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [
    AppComponent,
    SharedModule
  ]
})
export class AppModule { }


