import { formatDate, formatNumber } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { start } from 'repl';
import { DecimalData, TempDDL } from '../model/accsda100.model';
import { ParameterEntity } from 'src/app/shared/model/paramete.rmodel';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { CreditNoteService } from '../../service/acccdn';
import { decimalTypePurchase, decimalTypeRoundMethod, decimalTypeRoundToValue, decimalTypeSelling } from 'src/app/shared/constant/decimal.constant';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-accsda100',
  templateUrl: './accsda100.component.html',
  styleUrls: ['./accsda100.component.scss']
})
export class Accsda100Component implements OnInit {
  public criteria = new FormGroup({
    country: new FormControl(),
    currency: new FormControl(),
  })
  public period = new FormGroup({
    period: new FormControl(),
  })
  public searchEarnings = new FormGroup({
    earningDate: new FormControl(),
  })
  public searchIncome = new FormGroup({
    paymentDate: new FormControl(),
    comfirmDate: new FormControl(),
    saleOrderDate: new FormControl(),
    saleOrderNo: new FormControl(),
    seller: new FormControl(),
    wareHoues: new FormControl(),
    paymentMethod: new FormControl(),
    paymentChannel: new FormControl(),
  })
  public searchExpenses = new FormGroup({
    cdnDate: new FormControl(),
    cdnNo: new FormControl(),
    warehouse: new FormControl(),
    seller: new FormControl(),
  })
  public decimalData:DecimalData = {} as DecimalData;
  optionData: SelectItem[];
  disablePeriod: boolean = true;
  optionCountry: ParameterEntity[];
  defaultPeriod: any;
  optionValueDefault: any;
  dateStart:string;
  dateLimit:string;
  dataNotFoundGridEarnings: boolean = true;
  dataNotFoundGridIncome: boolean = true;
  dataNotFoundGridExpenses: boolean = true;
  disSearchButtonEarnings: boolean = true;
  disSearchButtonIncome: boolean = true;
  disSearchButtonExpenses: boolean = true;
  disEarnings: boolean = true;
  disIncome: boolean = true;
  disExpenses: boolean = true;
  IsSearchEarnings: boolean = false as boolean;
  IsSreachIncome: boolean = false as boolean;
  IsSreachExpenses: boolean = false as boolean;
  showLabelToday: boolean = false;
  labelDay:string;
  dayLabel: boolean = false;
  sumCard:any;
  showSumCard: boolean = false;
  earnings:any;
  earningTemp:any;
  income:any;
  incomeTemp:any;
  expenses:any;
  expensesTemp:any;
  decimal: any;
  method: any;
  roundToDecimal: any;
  checkCase3mAgo: string;
  disCountry: boolean = false;
  firstRow: number = 0 as number;
  translateLablePipe = new TranslateLablePipe();
  paymentMethodData: TempDDL[];
  paymentChannelData: TempDDL[];

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  // optionSeller: TempDDL[];
  optionWareHoues: TempDDL[];

  constructor(
    private parameterService: ParameterService,
    private parameterWmsSerive: ParameterWmsSerive,
    private autService: AuthenticationService,
    private creditNoteService: CreditNoteService,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService,
    private decimalService: DecimalService,) {
  }

  ngOnInit() {
    this.parameterService.getvparameterDetailToLable(2000).subscribe((response) => {
      this.optionData = JSON.parse(JSON.stringify(response));
      this.optionData = this.optionData.sort((a, b) =>
        a.value.pmdtbdnumv3 < b.value.pmdtbdnumv3 ? -1 : a.value.pmdtbdnumv3 > b.value.pmdtbdnumv3 ? 1 : 0
      );

      const defaultSelectItem: SelectItem[] = this.optionData.filter((selectItem: SelectItem) => {
        return selectItem.value.parameterDetailPK.pmdtbdtbdentcd == '0D';
      });

      this.optionValueDefault = defaultSelectItem[0].value;
      this.defaultPeriod = this.optionValueDefault;
      this.period.controls['period'].setValue(this.defaultPeriod);

    }, err => {
      console.log(err);
    });

    this.autService.getParameterWmsWareHouse(this.criteria.controls['country'].value).subscribe(response =>{
      this.optionWareHoues = JSON.parse(JSON.stringify(response));
    },err=>{
      console.log(err);

    });

    // this.autService.getParameterWmsSeller(this.criteria.controls['country'].value,this.optionWareHoues).subscribe(response =>{
    //   this.optionSeller = JSON.parse(JSON.stringify(response));
    // },err=>{
    //   console.log(err);

    // });

    this.autService.getParameterWmsCountry().subscribe(response => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.optionCountry.length == 1) {
        this.criteria.controls['country'].setValue(this.optionCountry[0].value);
        this.disCountry = true;
        this.getCurrency(this.criteria.controls['country'].value);
        this.getOptionData();
      }
    }, err => {
      console.log(err);
    });

    this.parameterService.getvparameterDetailToLable(4100).subscribe((response) => {
        this.paymentMethodData = JSON.parse(JSON.stringify(response));
    }, err => {
        console.log(err);
    });

    this.parameterService.getvparameterDetailToLable(4300).subscribe((response) => {
        this.paymentChannelData = JSON.parse(JSON.stringify(response));
    }, err => {
        console.log(err);
    });
  }

  getOptionData(){
    this.checkCase3mAgo = this.optionData[5].value.pmdtbdnumv2;
    this.creditNoteService.getSummaryCard(this.criteria.controls['country'].value,this.checkCase3mAgo).subscribe((res) => {
      this.sumCard = res;
    });

    setTimeout(() => {
      this.showSumCard = true;
      this.disablePeriod = false;
    }, 1000);
    this.getCurrency(this.criteria.controls['country'].value);
    this.period.controls['period'].setValue('0D');
    this.showLabelDay('0D');
    this.getData('0D',null);
    this.getFormatNumber();
    // WareHoues
    this.autService.getParameterWmsWareHouse(this.criteria.controls['country'].value).subscribe(response =>{
      this.optionWareHoues = JSON.parse(JSON.stringify(response));
    },err=>{
      console.log(err);

    });
    // Seller
    // this.autService.getParameterWmsSeller(this.criteria.controls['country'].value,this.optionWareHoues).subscribe(response =>{
    //   this.optionSeller = JSON.parse(JSON.stringify(response));
    // },err=>{
    //   console.log(err);

    // });
  }

  getCurrency(country) {
    this.parameterWmsSerive.getParameterWmsBytableNoAndCode(105, country).subscribe((responseParam) => {
      let respones = JSON.parse(JSON.stringify(responseParam));
      this.parameterWmsSerive.getParameterWmsBytableNoAndCode(9146, respones[0].parameterData.parameter_v6).subscribe((currencyResult) => {
        let currency = JSON.parse(JSON.stringify(currencyResult));
        this.criteria.controls['currency'].setValue(this.translateLablePipe.transform(currency[0].engDesc,currency[0].localDesc));
      });
    });
  }

  selectedData(event){
    let selected = event.value.parameterDetailPK.pmdtbdtbdentcd;
    this.showLabelDay(selected);
    this.getData(selected,event.value.pmdtbdnumv2);
  }

  showLabelDay(value){
    if(value == '0D'){
      this.labelDay = this.translateService.instant('ACCSDA100.M00047')
      this.dayLabel = true;
    } else if(value == '1D'){
      this.labelDay = this.translateService.instant('ACCSDA100.M00048')
      this.dayLabel = true;
    } else{
      this.dayLabel = false;
    }
  }
  getData(selected,retrospcCheck){

    this.searchEarnings.reset();
    this.disSearchButtonEarnings = true;
    this.earnings = this.earningTemp;


    this.searchIncome.reset();
    this.disSearchButtonIncome = true;
    this.income = this.incomeTemp;


    this.searchExpenses.reset();
    this.disSearchButtonExpenses = true;
    this.expenses = this.expensesTemp;

    let startDate;
    let endDate;
    endDate = new Date();
    endDate = moment(startDate).format('YYYY-MM-DD');
    if(selected == '0D'){
      startDate = moment(startDate).format('YYYY-MM-DD');


    }
    else if(selected == '1D'){
      startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
      endDate = startDate;

    }
    else if(selected == '7D'){
      startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');


    }
    else if(selected == '1M'){
      startDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      startDate = startDate.substring(0,5) + (startDate.substring(5,7)) + '-01';
      let lastDateofTheMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0)
      endDate = startDate.substring(0,5) + (startDate.substring(5,8)) + lastDateofTheMonth.getDate();


    }
    else if(selected == '0M'){
      startDate = endDate.substring(0,8) + '01';


    }
    else if(selected == '3M'){
      if(retrospcCheck == 0){
        startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
        startDate = startDate.substring(0,8) + '01';
        endDate = moment().subtract(1.3, 'months').format('YYYY-MM-DD');
        let lastDateofTheMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        endDate = endDate.substring(0,8) + lastDateofTheMonth.getDate();


      }else{
        startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
        startDate = startDate.substring(0,8) + '01';


      }
    }
    else if(selected == '0Y'){
      startDate = endDate.substring(0,5) + '01-01';


    }

    this.dateStart = moment(startDate).format('DD/MM/YYYY');
    this.dateLimit = moment(endDate).format('DD/MM/YYYY');

    this.creditNoteService.getSummaryEarnings(this.criteria.controls['country'].value, startDate, endDate).subscribe((res) => {
      this.earnings = res;
      this.earningTemp = res;
      if(this.earningTemp.length > 0){
        this.dataNotFoundGridEarnings = false;
        this.disEarnings = false;
      } else{
      this.dataNotFoundGridEarnings = true;
      this.disEarnings = true;
      }
    })

    this.creditNoteService.getSummaryIncome(this.criteria.controls['country'].value, startDate, endDate).subscribe((res) => {
      this.income = res;
      this.incomeTemp = res;
      if(this.incomeTemp.length > 0){
        this.dataNotFoundGridIncome = false;
        this.disIncome = false;
      } else{
        this.dataNotFoundGridIncome = true;
        this.disIncome = true;
      }
    })

    this.creditNoteService.getSummaryExpenses(this.criteria.controls['country'].value, startDate, endDate).subscribe((res) => {
      this.expenses = res;
      this.expensesTemp = res;
      if(this.expensesTemp.length > 0){
        this.dataNotFoundGridExpenses = false;
        this.disExpenses = false;
      } else{
        this.dataNotFoundGridExpenses = true;
        this.disExpenses = true;
      }
    })
    this.showLabelToday = true;
  }

  advSearchEarningsGrid(){
    this.earnings = this.earningTemp;
    if (this.searchEarnings.controls['earningDate'].value) {
      if (this.searchEarnings.controls['earningDate'].value[0] && this.searchEarnings.controls['earningDate'].value[1] == null) {
        this.searchEarnings.controls['earningDate'].value[1] = this.searchEarnings.controls['earningDate'].value[0];
      }
      const stratYear = moment(this.searchEarnings.controls['earningDate'].value[0]).format('YYYY');
      const endYear = moment(this.searchEarnings.controls['earningDate'].value[1]).format('YYYY');
      const stratMonth = moment(this.searchEarnings.controls['earningDate'].value[0]).format('MM');
      const endMonth = moment(this.searchEarnings.controls['earningDate'].value[1]).format('MM');
      const stratDate = moment(this.searchEarnings.controls['earningDate'].value[0]).format('DD');
      const endDate = moment(this.searchEarnings.controls['earningDate'].value[1]).format('DD');
      this.earnings = this.earnings.filter(data => {
        const dateitemYear = (data.date.substring(6)).toString();
        const dateitemMonth = data.date.substring(3,5).toString();
        const dateitemDate = data.date.substring(0,2).toString();
        if (dateitemYear >= stratYear && dateitemYear <= endYear) {
          if(stratMonth == endMonth){
            if (dateitemMonth >= stratMonth && dateitemMonth <= endMonth) {
              if (dateitemDate >= stratDate && dateitemDate <= endDate) {
                return data;
              }
            }
          }else{
            if (dateitemMonth == stratMonth) {
              if (dateitemDate >= stratDate) {
                return data;
              }
            }else if(dateitemMonth > stratMonth && dateitemMonth < endMonth){
              return data;
            }else if(dateitemMonth == endMonth){
              if (dateitemDate <= endDate) {
                return data;
              }
            }
          }
        }
        return (data.date == this.searchEarnings.controls['earningDate'].value);
      });
    }
    if(this.earnings.length > 0){
      this.dataNotFoundGridEarnings = false;
      this.firstRow = 0;
    } else {
      this.dataNotFoundGridEarnings = true;
    }
  }

  advSearchIncomeGrid(){
    this.income = this.incomeTemp;
    if (this.searchIncome.controls['paymentDate'].value) {
      if (this.searchIncome.controls['paymentDate'].value[0] && this.searchIncome.controls['paymentDate'].value[1] == null) {
        this.searchIncome.controls['paymentDate'].value[1] = this.searchIncome.controls['paymentDate'].value[0];
      }
      const stratYear = moment(this.searchIncome.controls['paymentDate'].value[0]).format('YYYY');
      const endYear = moment(this.searchIncome.controls['paymentDate'].value[1]).format('YYYY');
      const stratMonth = moment(this.searchIncome.controls['paymentDate'].value[0]).format('MM');
      const endMonth = moment(this.searchIncome.controls['paymentDate'].value[1]).format('MM');
      const stratDate = moment(this.searchIncome.controls['paymentDate'].value[0]).format('DD');
      const endDate = moment(this.searchIncome.controls['paymentDate'].value[1]).format('DD');
      this.income = this.income.filter(data => {
        const dateitemYear = (data.paymentDate.substring(6,10)).toString();
        const dateitemMonth = data.paymentDate.substring(3,5).toString();
        const dateitemDate = data.paymentDate.substring(0,2).toString();
        if (dateitemYear >= stratYear && dateitemYear <= endYear) {
          if(stratMonth == endMonth){
            if (dateitemMonth >= stratMonth && dateitemMonth <= endMonth) {
              if (dateitemDate >= stratDate && dateitemDate <= endDate) {
                return data;
              }
            }
          }else{
            if (dateitemMonth == stratMonth) {
              if (dateitemDate >= stratDate) {
                return data;
              }
            }else if(dateitemMonth > stratMonth && dateitemMonth < endMonth){
              return data;
            }else if(dateitemMonth == endMonth){
              if (dateitemDate <= endDate) {
                return data;
              }
            }
          }
        }
        return (data.paymentDate == this.searchIncome.controls['paymentDate'].value);
      });
    }

    if (this.searchIncome.controls['comfirmDate'].value) {
      if (this.searchIncome.controls['comfirmDate'].value[0] && this.searchIncome.controls['comfirmDate'].value[1] == null) {
        this.searchIncome.controls['comfirmDate'].value[1] = this.searchIncome.controls['comfirmDate'].value[0];
      }
      const stratYear = moment(this.searchIncome.controls['comfirmDate'].value[0]).format('YYYY');
      const endYear = moment(this.searchIncome.controls['comfirmDate'].value[1]).format('YYYY');
      const stratMonth = moment(this.searchIncome.controls['comfirmDate'].value[0]).format('MM');
      const endMonth = moment(this.searchIncome.controls['comfirmDate'].value[1]).format('MM');
      const stratDate = moment(this.searchIncome.controls['comfirmDate'].value[0]).format('DD');
      const endDate = moment(this.searchIncome.controls['comfirmDate'].value[1]).format('DD');
      this.income = this.income.filter(data => {
        const dateitemYear = (data.confirmPaymentDate.substring(6,10)).toString();
        const dateitemMonth = data.confirmPaymentDate.substring(3,5).toString();
        const dateitemDate = data.confirmPaymentDate.substring(0,2).toString();
        if (dateitemYear >= stratYear && dateitemYear <= endYear) {
          if(stratMonth == endMonth){
            if (dateitemMonth >= stratMonth && dateitemMonth <= endMonth) {
              if (dateitemDate >= stratDate && dateitemDate <= endDate) {
                return data;
              }
            }
          }else{
            if (dateitemMonth == stratMonth) {
              if (dateitemDate >= stratDate) {
                return data;
              }
            }else if(dateitemMonth > stratMonth && dateitemMonth < endMonth){
              return data;
            }else if(dateitemMonth == endMonth){
              if (dateitemDate <= endDate) {
                return data;
              }
            }
          }
        }
        return (data.confirmPaymentDate == this.searchIncome.controls['comfirmDate'].value);
      });
    }

    if (this.searchIncome.controls['saleOrderDate'].value) {
      if (this.searchIncome.controls['saleOrderDate'].value[0] && this.searchIncome.controls['saleOrderDate'].value[1] == null) {
        this.searchIncome.controls['saleOrderDate'].value[1] = this.searchIncome.controls['saleOrderDate'].value[0];
      }
      const stratYear = moment(this.searchIncome.controls['saleOrderDate'].value[0]).format('YYYY');
      const endYear = moment(this.searchIncome.controls['saleOrderDate'].value[1]).format('YYYY');
      const stratMonth = moment(this.searchIncome.controls['saleOrderDate'].value[0]).format('MM');
      const endMonth = moment(this.searchIncome.controls['saleOrderDate'].value[1]).format('MM');
      const stratDate = moment(this.searchIncome.controls['saleOrderDate'].value[0]).format('DD');
      const endDate = moment(this.searchIncome.controls['saleOrderDate'].value[1]).format('DD');
      this.income = this.income.filter(data => {
        const dateitemYear = (data.soDate.substring(6,10)).toString();
        const dateitemMonth = data.soDate.substring(3,5).toString();
        const dateitemDate = data.soDate.substring(0,2).toString();
        if (dateitemYear >= stratYear && dateitemYear <= endYear) {
          if(stratMonth == endMonth){
            if (dateitemMonth >= stratMonth && dateitemMonth <= endMonth) {
              if (dateitemDate >= stratDate && dateitemDate <= endDate) {
                return data;
              }
            }
          }else{
            if (dateitemMonth == stratMonth) {
              if (dateitemDate >= stratDate) {
                return data;
              }
            }else if(dateitemMonth > stratMonth && dateitemMonth < endMonth){
              return data;
            }else if(dateitemMonth == endMonth){
              if (dateitemDate <= endDate) {
                return data;
              }
            }
          }
        }
        return (data.soDate == this.searchIncome.controls['saleOrderDate'].value);
      });
    }

    if (this.searchIncome.controls['saleOrderNo'].value) {
      this.income = this.income.filter(data => {
        return data.so.toLowerCase().indexOf(this.searchIncome.controls['saleOrderNo'].value.toLowerCase()) > -1;
      });
    }

    if (this.searchIncome.controls['seller'].value) {
      this.income = this.income.filter(data => {
        return data.seller.toString().toLowerCase().indexOf(this.searchIncome.controls['seller'].value.toString().toLowerCase()) > -1;
      });
    }
 
    if (this.searchIncome.controls['wareHoues'].value) {    
      this.income = this.income.filter(val => {
        for (var i = 0; i < this.searchIncome.controls['wareHoues'].value.length; i++) {
          if (val.warehouse.toString().toLowerCase().includes(this.searchIncome.controls['wareHoues'].value[i])) {
            return true;
          }
        }
        return false;
      }); 
    }

    let paymentMethod:any = [];
    if (this.searchIncome.controls['paymentMethod'].value) {
      this.searchIncome.controls['paymentMethod'].value.forEach(data =>{
        paymentMethod.push(data.parameterDetailPK.pmdtbdtbdentcd);
      });
      if (paymentMethod.length > 0) {
        this.income = this.income.filter(data =>
          paymentMethod.includes(data.paymodeLdesc.substring(0,3))
        );
      }
    }

    let paymentChannel:any = [];
    if (this.searchIncome.controls['paymentChannel'].value) {
      this.searchIncome.controls['paymentChannel'].value.forEach(data =>{
        paymentChannel.push(data.parameterDetailPK.pmdtbdtbdentcd);
      });
      if (paymentChannel.length > 0) {
        this.income = this.income.filter(data =>
          paymentChannel.includes(data.paychanLdesc.substring(0,3))
        );
      }
    }

    if(this.income.length > 0){
      this.dataNotFoundGridIncome = false;
      this.firstRow = 0;
    } else {
      this.dataNotFoundGridIncome = true;
    }
  }

  advSearchExpensesGrid(){
    this.expenses = this.expensesTemp;

    if (this.searchExpenses.controls['cdnDate'].value) {
      if (this.searchExpenses.controls['cdnDate'].value[0] && this.searchExpenses.controls['cdnDate'].value[1] == null) {
        this.searchExpenses.controls['cdnDate'].value[1] = this.searchExpenses.controls['cdnDate'].value[0];
      }
      const stratYear = moment(this.searchExpenses.controls['cdnDate'].value[0]).format('YYYY');
      const endYear = moment(this.searchExpenses.controls['cdnDate'].value[1]).format('YYYY');
      const stratMonth = moment(this.searchExpenses.controls['cdnDate'].value[0]).format('MM');
      const endMonth = moment(this.searchExpenses.controls['cdnDate'].value[1]).format('MM');
      const stratDate = moment(this.searchExpenses.controls['cdnDate'].value[0]).format('DD');
      const endDate = moment(this.searchExpenses.controls['cdnDate'].value[1]).format('DD');
      this.expenses = this.expenses.filter(data => {
        const dateitemYear = (data.cdnDate.substring(6,10)).toString();
        const dateitemMonth = data.cdnDate.substring(3,5).toString();
        const dateitemDate = data.cdnDate.substring(0,2).toString();
        if (dateitemYear >= stratYear && dateitemYear <= endYear) {
          if(stratMonth == endMonth){
            if (dateitemMonth >= stratMonth && dateitemMonth <= endMonth) {
              if (dateitemDate >= stratDate && dateitemDate <= endDate) {
                return data;
              }
            }
          }else{
            if (dateitemMonth == stratMonth) {
              if (dateitemDate >= stratDate) {
                return data;
              }
            }else if(dateitemMonth > stratMonth && dateitemMonth < endMonth){
              return data;
            }else if(dateitemMonth == endMonth){
              if (dateitemDate <= endDate) {
                return data;
              }
            }
          }
        }
        return (data.cdnDate == this.searchExpenses.controls['cdnDate'].value);
      });
    }

    if (this.searchExpenses.controls['cdnNo'].value) {
      this.expenses = this.expenses.filter(data => {
        return data.cdnNo.toLowerCase().indexOf(this.searchExpenses.controls['cdnNo'].value.toLowerCase()) > -1;
      });
    }

    if (this.searchExpenses.controls['warehouse'].value) {
      this.expenses = this.expenses.filter(val => {
        for (var i = 0; i < this.searchExpenses.controls['warehouse'].value.length; i++) {
          if (val.warehouseCode.toString().toLowerCase().includes(this.searchExpenses.controls['warehouse'].value[i])) {
            return true;
          }
        }
        return false;
      }); 
    }

    if (this.searchExpenses.controls['seller'].value) {
      this.expenses = this.expenses.filter(data => {
        return data.seller.toString().toLowerCase().indexOf(this.searchExpenses.controls['seller'].value.toString().toLowerCase()) > -1;
      });
    }
    if(this.expenses.length > 0){
      this.dataNotFoundGridExpenses = false;
      this.firstRow = 0;
    } else {
      this.dataNotFoundGridExpenses = true;
    }
  }

  ClearData(page){
    if(page == 1){
      this.searchEarnings.reset();
      this.disSearchButtonEarnings = true;
      this.earnings = this.earningTemp;
    }
    if(page == 2){
      this.searchIncome.reset();
      this.disSearchButtonIncome = true;
      this.income = this.incomeTemp;
    }
    if(page == 3){
      this.searchExpenses.reset();
      this.disSearchButtonExpenses = true;
      this.expenses = this.expensesTemp;
    }
  }

  exportReport(page) {
    if(page == 1){
      let exportEarning = [];
      this.earnings.forEach(dataTable=>{
        exportEarning.push({
            payment_date : dataTable.date,
            income: this.decimalService.getRoundingDecimal(dataTable.income, this.method, this.roundToDecimal, this.decimal),
            payment: this.decimalService.getRoundingDecimal(dataTable.payment, this.method, this.roundToDecimal, this.decimal),
            remain: this.decimalService.getRoundingDecimal(dataTable.remain, this.method, this.roundToDecimal, this.decimal),
          })
      });

      let subTitle = [];
      subTitle.push([this.translateService.instant('ACCSDA100.M00009')]);
      let header: any = {
        payment_date : this.translateService.instant('ACCSDA100.M00018'),
        income: this.translateService.instant('ACCSDA100.M00019'),
        payment: this.translateService.instant('ACCSDA100.M00020'),
        remain: this.translateService.instant('ACCSDA100.M00021'),
      }
      this.exportExcelService.exportAsExcelFile(exportEarning, this.translateService.instant('ACCSDA100.M00009'), header, null, subTitle);
    }else if(page==2){
      let exportIncome = [];
      this.income.forEach(dataTable=>{
        exportIncome.push({
            payment_date : dataTable.paymentDate,
            comfirm_date: dataTable.confirmPaymentDate,
            sale_number: dataTable.so,
            sale_date: dataTable.soDate,
            warehouse : dataTable.warehouse,
            seller : dataTable.seller,
            total_order : this.decimalService.getRoundingDecimal(dataTable.total_order, this.method, this.roundToDecimal, this.decimal),
            vat : this.decimalService.getRoundingDecimal(dataTable.vat, this.method, this.roundToDecimal, this.decimal),
            sum_order_vat : this.decimalService.getRoundingDecimal(dataTable.sum_order_vat, this.method, this.roundToDecimal, this.decimal),
            shipping : this.decimalService.getRoundingDecimal(dataTable.shipping, this.method, this.roundToDecimal, this.decimal),
            cost : this.decimalService.getRoundingDecimal(dataTable.cost, this.method, this.roundToDecimal, this.decimal),
            payment : this.decimalService.getRoundingDecimal(dataTable.payment, this.method, this.roundToDecimal, this.decimal),
            remain : this.decimalService.getRoundingDecimal(dataTable.remain, this.method, this.roundToDecimal, this.decimal),
            paymode_desc : this.translateLablePipe.transform(dataTable.paymodeEdesc, dataTable.paymodeLdesc),
            paychan_desc : this.translateLablePipe.transform(dataTable.paychanEdesc, dataTable.paychanLdesc),
            paybank : dataTable.payBank,
          })
      });

      let subTitle = [];
      subTitle.push([this.translateService.instant('ACCSDA100.M00023')]);
      let header: any = {
        payment_date : this.translateService.instant('ACCSDA100.M00030'),
        comfirm_date: this.translateService.instant('ACCSDA100.M00031'),
        sale_number: this.translateService.instant('ACCSDA100.M00032'),
        sale_date: this.translateService.instant('ACCSDA100.M00033'),
        warehouse : this.translateService.instant('ACCSDA100.M00034'),
        seller : this.translateService.instant('ACCSDA100.M00035'),
        total_order : this.translateService.instant('ACCSDA100.M00035'),
        vat : this.translateService.instant('ACCSDA100.M00049'),
        sum_order_vat : this.translateService.instant('ACCSDA100.M00050'),
        shipping : this.translateService.instant('ACCSDA100.M00051'),
        cost : this.translateService.instant('ACCSDA100.M00052'),
        payment : this.translateService.instant('ACCSDA100.M00037'),
        remain : this.translateService.instant('ACCSDA100.M00038'),
        paymode_desc : this.translateService.instant('ACCSDA100.M00056'),
        paychan_desc : this.translateService.instant('ACCSDA100.M00057'),
        paybank : this.translateService.instant('ACCSDA100.M00058'),
      }
      this.exportExcelService.exportAsExcelFile(exportIncome, this.translateService.instant('ACCSDA100.M00023'), header, null, subTitle);
    }else if(page == 3){
      let exportExpenses = [];
      this.expenses.forEach(dataTable=>{
        exportExpenses.push({
            cdn_date : dataTable.cdnDate,
            cdn_no: dataTable.cdnNo,
            warehouse: dataTable.warehouse,
            seller: dataTable.seller,
            vatExclude: this.decimalService.getRoundingDecimal(dataTable.vatExclude, this.method, this.roundToDecimal, this.decimal),
            vat: this.decimalService.getRoundingDecimal(dataTable.vat, this.method, this.roundToDecimal, this.decimal),
            totalVat: this.decimalService.getRoundingDecimal(dataTable.totalVat, this.method, this.roundToDecimal, this.decimal),
          })
      });

      let subTitle = [];
      subTitle.push([this.translateService.instant('ACCSDA100.M00039')]);
      let header: any = {
        cdn_date : this.translateService.instant('ACCSDA100.M00042'),
        cdn_no: this.translateService.instant('ACCSDA100.M00043'),
        warehouse: this.translateService.instant('ACCSDA100.M00034'),
        seller: this.translateService.instant('ACCSDA100.M00035'),
        vatExclude: this.translateService.instant('ACCSDA100.M00044'),
        vat: this.translateService.instant('ACCSDA100.M00045'),
        totalVat: this.translateService.instant('ACCSDA100.M00046'),
      }
      this.exportExcelService.exportAsExcelFile(exportExpenses, this.translateService.instant('ACCSDA100.M00039'), header, null, subTitle);
    }
  }

  getFormatNumber(){
    this.parameterService.getvprmdtltolableByCountry(104, this.criteria.controls['country'].value).subscribe(response => {
      let decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypePurchase);
      this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeSelling);
      this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd ==  decimalTypeRoundMethod);
      this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundToValue );
      this.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
      this.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
      this.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
    }, err => {
      console.log(err);
    });
  }

  changeSearchButton(value){
    if(value == 1){
      this.disSearchButtonEarnings = false;
    } else if(value == 2){
      this.disSearchButtonIncome = false;
    } else if(value == 3){
      this.disSearchButtonExpenses = false;
    }
  }
}
