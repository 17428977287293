import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Fnapay100Component } from './fnapay/fnapay100/fnapay100.component';
import { Fnapay200Component } from './fnapay/fnapay200/fnapay200.component';

import { SharedModule } from '../shared/shared.module';
import { FnaRoutingModule } from './fna-routing.module';

@NgModule({
  declarations: [
    Fnapay100Component,
    Fnapay200Component,
  ],
  imports: [
    CommonModule,
    FnaRoutingModule,
    SharedModule,
  ]
})
export class FnaModule { }
