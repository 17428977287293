import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { AgentInfoDetail } from '../model/agent.model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeAgentService {

  EMPAGT: string = `${this.environmentService.getApiUrl()}/empagt/agent` as string;

  constructor(private http: HttpClient,
    private environmentService: EnvironmentService,
    private authService: AuthenticationService) { }

  getAgentInquiry(param){
    const URL = `${this.EMPAGT}/getAgentInquiry`;
    return this.http.post(URL,param, {headers: this.authService.tokenHeader()});
  }

  getAgentDetail(agentCode){
    const URL = `${this.EMPAGT}/getAgentDetail/${agentCode}`;
    return this.http.get(URL, {headers: this.authService.tokenHeader()});
  }

  saveAgentDetail(request){
    const URL = `${this.EMPAGT}/saveOrUpdateAgent`;
    return this.http.post(URL,request, {headers: this.authService.tokenHeader()});
  }

  getOrgByUsername(username:string){
    const URL = `${this.EMPAGT}/getOrgByUsername/${username}`;
    return this.http.get(URL,{headers: this.authService.tokenHeader()});
  }
}
