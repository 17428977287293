import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Cusexp100 } from 'src/app/cus/cusext/cusext100/Model/cusext100.model';
import { CustomerHistoryModel, CustomerHistoryRequest, CustomerInquiryDetailModel, CustomerInquiryModel, CustomerInquiryRequest, CustomerMoreInfoModel, CustomerMoreInfoRequest, CustomerSellerModel, CustomerSellerRequest } from "../model/customer-inquiry.model";
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {



  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService) { }

    private CUSEXT : string = `${this.environmentService.getApiUrl()}/cusext/customer` as string;
    private CUSCUS: string = `${this.environmentService.getApiUrl()}/cuscus/customer` as string;

    getCustomerData(param):Observable<Cusexp100>{
      const URL = `${this.CUSEXT}/getCustomerInquiry`;
      return this.http.post<Cusexp100>(URL,param, {headers: this.authenticationService.tokenHeader()});
    }

    // getCustomerInquiry(customerInquiryRequest: CustomerInquiryRequest): Observable<Array<CustomerInquiryModel>> {
    //   const URL = `${this.CUSCUS}/getCustomerInquiry`;
    //   return this.http.post <Array<CustomerInquiryModel>>(URL, customerInquiryRequest, { headers: this.authenticationService.tokenHeader() });
    // }

    saveCustomerMaintenantDetail(param){
      const URL = `${this.CUSCUS}/saveOrUpdateCustomerMaintenance`;
      return this.http.post(URL, param,{headers: this.authenticationService.tokenHeader()});
    }

    saveCustomerHistory(param){
      const URL = `${this.CUSCUS}/saveCustomerHistory`;
      return this.http.post(URL, param,{headers: this.authenticationService.tokenHeader()});
    }

    getCustomerDetailInquiry(customerInquiryRequest: CustomerInquiryRequest): Observable<Array<CustomerInquiryDetailModel>> {
        const URL = `${this.CUSCUS}/getCustomerSearch`;
        return this.http.post <Array<CustomerInquiryDetailModel>>(URL, customerInquiryRequest, { headers: this.authenticationService.tokenHeader() });
    }

    getCustomerPhone(customerInquiryRequest: CustomerInquiryRequest): Observable<Array<CustomerInquiryDetailModel>> {
      const URL = `${this.CUSCUS}/getCustomerPhoneSearch`;
      return this.http.post <Array<CustomerInquiryDetailModel>>(URL, customerInquiryRequest, { headers: this.authenticationService.tokenHeader() });
    }

    getCustomerHistory(customerInquiryRequest: CustomerHistoryRequest): Observable<Array<CustomerHistoryModel>> {
        const URL = `${this.CUSCUS  }/getCustomerHistory`;
        return this.http.post <Array<CustomerHistoryModel>>(URL, customerInquiryRequest, { headers: this.authenticationService.tokenHeader() });
    }

    getCustomerSeller(customerInquiryRequest: CustomerSellerRequest): Observable<Array<CustomerSellerModel>> {
        const URL = `${this.CUSCUS  }/getCustomerSeller`;
        return this.http.post <Array<CustomerSellerModel>>(URL, customerInquiryRequest, { headers: this.authenticationService.tokenHeader() });
    }

    getCustomerMoreInfo(customerInquiryRequest: CustomerMoreInfoRequest): Observable<Array<CustomerMoreInfoModel>> {
        const URL = `${this.CUSCUS  }/getCustomerMoreInformation`;
        return this.http.post <Array<CustomerMoreInfoModel>>(URL, customerInquiryRequest, { headers: this.authenticationService.tokenHeader() });
    }

}
