import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { AboutModel } from '../model/application.model';


@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  private PUBLIC_APP_URL: string = `${this.environmentService.getApiUrl()}/api/klangone` as string;

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService) {
              }

  getEnvironmentAndVersion(): Observable<AboutModel> {
    const URL = `${this.PUBLIC_APP_URL}/info`;
    return this.http.get<AboutModel>(URL, {});
  }
}
