import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { Lanlan100Component } from './lanlan100/lanlan100.component';

const routes: Routes = [{ path: '', component: Lanlan100Component}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LanRouthModule { }
