import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';

@Injectable({
    providedIn: 'root'
})
export class Admusr200Service {

    constructor(private http: HttpClient,
        private environmentService: EnvironmentService,
        private authenticationService: AuthenticationService) {
    }

    private USER_URL: string = `${this.environmentService.getApiUrl()}/adm/usr` as string;

    getUserCriteria(){
        const URL: string = `${this.USER_URL}/getUserCriteria` as string;
        return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
    }

    getUserData(userId){
        const URL: string = `${this.USER_URL}/getUserData/${userId}` as string;
        return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
    }

    getcreateUserData(){
        const URL: string = `${this.USER_URL}/getCreateUserData` as string;
        return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
    }

    saveUser(data){
        const URL: string = `${this.USER_URL}/saveUser` as string;
        return this.http.post(URL,data,{ headers: this.authenticationService.tokenHeader()});
    }

    updateUser(data){
        const URL: string = `${this.USER_URL}/updateUser` as string;
        return this.http.post(URL,data,{ headers: this.authenticationService.tokenHeader()});
    }
}
