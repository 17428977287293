import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AdministrationLanguageService } from './shared/services/administration-language.service';
import { ParameterCountryList } from './shared/model/parameter.model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'KhangOne';
  public showMenu: boolean;
  // public menuData: any = [];
  public parentMessage: any;
  public menuwidth = {};
  private ignoreMenu: Array<string> = [
    '/',
    '/aut/autaut100',
    '/aut/autaut200',
    '/app/fe8c2f62-a1a9-471e-a69d-fedf1a63195b',
    '/ordsor/ordsor300'] as Array<string>;

  constructor(
    location: Location,
    private router: Router,
    private translateService: TranslateService,
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (this.ignoreMenu.filter(s => s.split('/')[2] === event.url.split('/')[2]).length === 1) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      }
    });
  }

  public changeSize(size: string) {
    this.menuwidth = {
      'margin-left': size
    };
  }

  public showMenuSize(menuSize: string) {
    // this.changeSize(menuSize);
    if (!this.showMenu) {
      this.menuwidth = {
        padding: '0'
      };
    }
  }

}
