import { Injectable } from "@angular/core";
import { Cuscus100Model, FormModel, TempDDL } from "../model/cuscus100.model";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { FormBuilder, Validators } from "@angular/forms";
import { date_format, th } from "src/app/shared/constant/date.constant";
import { DropDownListModel } from "src/app/shared/model/dropdownlist.model";
import { Observable } from "rxjs";
import { CustomerInquiryModel, CustomerInquiryRequest } from "src/app/shared/model/customer-inquiry.model";
import { CustomerService } from 'src/app/shared/services/customer.service';

@Injectable({
    providedIn: 'root'
})

export class Cuscus100Service {
    data: any;
    optionSex: TempDDL[];
    optionOccupation: TempDDL[];
    cuscus100: Cuscus100Model = {} as Cuscus100Model;

    constructor(private customerService: CustomerService,
        private autService: AuthenticationService,
        private fb: FormBuilder) {
            this.inti();
        }
    inti(): void {
        this.cuscus100.menu_code = '';
        this.cuscus100.page_code = 'cuscus100';
        this.cuscus100.page_name = 'cuscus100';

        this.cuscus100.form_model = {} as FormModel;
        // Criterial Form
        this.cuscus100.form_model.criterial_form = this.fb.group({
            country: [null, Validators.required],
        });
        // Search Form
        this.cuscus100.form_model.search_form = this.fb.group({
            country: "",
            customerId: "",
            kpmenId: "",
            kpdfmemid: "",
            stat: "",
            firstname: "",
            sex: "",
            occupation: "",
            phoneNumber: "",
            province: "",
            birhdayFilter: "",
            slrmemid: "",
            lastname: "",
            age: "",
            memberCreateDatetime: "",
        });

        this.cuscus100.form_model.advance_search_form = this.fb.group({
            key: [null, Validators.required]
        });

        this.cuscus100.date = {};
        this.cuscus100.date.locale = th;
        this.cuscus100.date.date_format = date_format;

        this.cuscus100.data_list = {};
        this.cuscus100.data_list.country_data = [{label:'ไม่พบข้อมูล', value: '', engDesc:'No Data Found', localDesc:'ไม่พบข้อมูล'}];

        this.cuscus100.customer_inquiry_list = [];
        this.cuscus100.searchFormSummitFlag = true;

    }

    get load(): Cuscus100Model {
        return this.cuscus100;
    }

    loadComponanceData(model: Cuscus100Model): void {
        this.autService.getParameterWmsCountry().subscribe((response: Array<DropDownListModel>) => {
            model.data_list.country_data = response;

            if (model.data_list.country_data.length === 1) {
                model.form_model.criterial_form.controls['country'].setValue(model.data_list.country_data[0].value);
                model.form_model.criterial_form.get('country').disable();
            } else if (model.data_list.country_data.length === 0) {
                model.form_model.criterial_form.get('country').disable();
            } else {
                model.form_model.criterial_form.get('country').enable();
            }
        }, err => {
            console.log(err);
        });
    }

    searchFunction(): Observable<Array<CustomerInquiryModel>> {
        let customerInquiry: CustomerInquiryRequest = {} as CustomerInquiryRequest;
        customerInquiry.country = this.cuscus100.form_model.criterial_form.get('country').value;
        customerInquiry.kpmenId = this.cuscus100.form_model.search_form.get('kpmenId').value;
        customerInquiry.kpdfmemid = this.cuscus100.form_model.search_form.get('kpdfmemid').value;
        customerInquiry.firstname = this.cuscus100.form_model.search_form.get('firstname').value;
        customerInquiry.lastname = this.cuscus100.form_model.search_form.get('lastname').value;
        customerInquiry.province =  this.cuscus100.form_model.search_form.get('province').value;
        customerInquiry.phoneNumber = this.cuscus100.form_model.search_form.get('phoneNumber').value;
        customerInquiry.slrmemid = this.cuscus100.form_model.search_form.get('slrmemid').value;
        customerInquiry.birhdayFilter = this.cuscus100.form_model.search_form.get('birhdayFilter').value;
        customerInquiry.age = this.cuscus100.form_model.search_form.get('age').value;

        if (customerInquiry.age) {
          const currentDate: Date = new Date();
          const birthYear: number = currentDate.getFullYear() - Number(customerInquiry.age);
          const birthDate: Date = new Date(birthYear, currentDate.getMonth(), currentDate.getDate());
          const day: number = birthDate.getDate();
          const month: number = birthDate.getMonth() + 1;
          const year: number = birthDate.getFullYear();
          customerInquiry.age = `${day}-${month}-${year}`;
        }

        if (this.cuscus100.form_model.search_form.get('memberCreateDatetime').value != null && this.cuscus100.form_model.search_form.get('memberCreateDatetime').value != '') {
          customerInquiry.memberCreateDatetimeFrom = this.cuscus100.form_model.search_form.get('memberCreateDatetime').value[0];
          const dateFrom: Date = new Date(customerInquiry.memberCreateDatetimeFrom)
          const dayFrom: number = dateFrom.getDate();
          const monthFrom: number = dateFrom.getMonth() + 1;
          const yearFrom: number = dateFrom.getFullYear();
          customerInquiry.memberCreateDatetimeFrom = `${dayFrom}-${monthFrom}-${yearFrom}`;

          customerInquiry.memberCreateDatetimeTo = this.cuscus100.form_model.search_form.get('memberCreateDatetime').value[1];
          const dateTo: Date = new Date(customerInquiry.memberCreateDatetimeTo)
          const dayTo: number = dateTo.getDate();
          const monthTo: number = dateTo.getMonth() + 1;
          const yearTo: number = dateTo.getFullYear();
          customerInquiry.memberCreateDatetimeTo = `${dayTo}-${monthTo}-${yearTo}`;

          if (dateFrom > dateTo) {
            customerInquiry.memberCreateDatetimeTo = customerInquiry.memberCreateDatetimeFrom
          }
        }


        if (customerInquiry.birhdayFilter) {
          const date: Date = new Date(customerInquiry.birhdayFilter)
          const day: number = date.getDate();
          const month: number = date.getMonth() + 1;
          const year: number = date.getFullYear();
          customerInquiry.birhdayFilter = `${day}-${month}-${year}`;
        }

        const occupationValue = this.cuscus100.form_model.search_form.get('occupation').value;
         if (occupationValue && occupationValue.parameterDetailPK && occupationValue.parameterDetailPK.pmdtbdtbdentcd) {
            customerInquiry.occupation = occupationValue.parameterDetailPK.pmdtbdtbdentcd;
         } else {
            customerInquiry.occupation = '';
         }
        const sexValue = this.cuscus100.form_model.search_form.get('sex').value;
         if (sexValue && sexValue.parameterDetailPK && sexValue.parameterDetailPK.pmdtbdtbdentcd) {
            customerInquiry.sex = sexValue.parameterDetailPK.pmdtbdtbdentcd;
         } else {
            customerInquiry.sex = '';
         }
        if (this.cuscus100.form_model.search_form.get('stat').value == 'Active') {
            customerInquiry.stat = 'true';
        } else if (this.cuscus100.form_model.search_form.get('stat').value == 'Inactive') {
            customerInquiry.stat = 'false';
        } else {
            customerInquiry.stat = '';
        }

        return this.customerService.getCustomerDetailInquiry(customerInquiry);
    }
}
