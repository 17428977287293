import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { SaleOrderNo, UuidSOLandingModel} from '../model/so-landing-model';
import { ParameterEntity } from '../model/paramete.rmodel';
import { SalesOrderrService } from './sales-order-service';
import { SaleOrderDetail } from '../model/saleorder.model';
import { paymentDetail } from 'src/app/fna/model/payment-data-model';

@Injectable({
  providedIn: 'root'
})
export class SoForBuyerService {
  ORDSOR_URL: string = `${this.evmService.getApiUrl()}/api/saleorderlanding` as string;
  constructor(private http: HttpClient ,
    private evmService: EnvironmentService,
    public saleorderService: SalesOrderrService) { }

  getSaleOrderDetail(saleorderNo,warehouseCode):Observable<SaleOrderDetail>{
    const URL = `${this.ORDSOR_URL}/getSaleOrderDetail/${saleorderNo}/${warehouseCode}`;
    return this.http.get<SaleOrderDetail>(URL, {});
  }

  getParameterBytableNoAndCode(tableNo,code){
    const URL = `${this.ORDSOR_URL}/getParameterBytableNoAndCode/${tableNo}/${code}`;
    return this.http.get(URL, {})
  }

  getvprmdtltolableByCountry(tableNo,country){
    const URL = `${this.ORDSOR_URL}/getvprmdtltolableByCountry/${tableNo}/${country}`;
    return this.http.get(URL, {});   
  }

  getSaleOrderStatus(): Observable<ParameterEntity> {
      const URL = `${this.ORDSOR_URL}/getSaleOrderStatusByParameter`;
    return this.http.get<ParameterEntity>(`${URL}`, {});
  }

  getPaymentStatus(): Observable<ParameterEntity> {
    const URL = `${this.ORDSOR_URL}/getPaymentStatusByParameter`;
    return this.http.get<ParameterEntity>(`${URL}`, {});
  }

  getLogisticStatus(): Observable<ParameterEntity> {
    const URL = `${this.ORDSOR_URL}/getLogisticStatusByParameter`;
    return this.http.get<ParameterEntity>(`${URL}`, {});
  }

  findSaleOrderNo(uuid): Observable<SaleOrderNo> {
    const URL = `${this.ORDSOR_URL}/findSaleOrderByUuid/${uuid}`;
    return this.http.get<SaleOrderNo>(`${URL}`, {});
  }

  findSaleOrderNoBySOAndWH(saleOrderNo, warehouseCode): Observable<UuidSOLandingModel>{
    const URL = `${this.ORDSOR_URL}/findSaleOrderBySoNumberAndWHCode/${saleOrderNo}/${warehouseCode}`;
    return this.http.get<UuidSOLandingModel>(`${URL}`, {});
  }

  getpaymentInquiry(saleOrderNo,warehouseCode): Observable<paymentDetail> {
    const URL: string = `${this.ORDSOR_URL}/getPaymentBySaleOrderNo/${saleOrderNo}/${warehouseCode}` as string;
    return this.http.get<paymentDetail>(URL, {});
  }

  getAgentDetail(agentCode){
    const URL = `${this.ORDSOR_URL}/getAgentDetail/${agentCode}`;
    return this.http.get(URL, {});
  }

}
