import { Component, Renderer2 } from '@angular/core';
import { MessageService } from "primeng/primeng";
import { Admpro200Service } from 'src/app/adm/service/admpro/admpro200.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileData, UserByProfileModel, MenuData, DataForSearch, ActionData, MenuSendToActionPage, TempProfileData } from 'src/app/adm/model/admpro/admpro200.model';
import { TranslateService } from '@ngx-translate/core';
import { AdmproLocalStorageService } from 'src/app/adm/service/admpro/admproLocalStorage.service';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';

@Component({
  selector: 'app-admpro200',
  templateUrl: './admpro200.component.html',
  styleUrls: ['./admpro200.component.scss']
})
export class Admpro200Component extends PermissionComponent {

  public userByProfileData: Array<UserByProfileModel>;
  public profileData: ProfileData = {} as ProfileData;
  public tempProfile: TempProfileData = {} as TempProfileData;
  public tempMenu: Array<MenuData>
  public dataForSearch: DataForSearch = {} as DataForSearch;
  public profileId:number;
  public dataNotFoundUserData: boolean;
  public disablePermission: boolean;
  public toggleSearch: boolean;

  statusPermission: any[] = [{ label: 'RO', value: 'RO' }, { label: 'RW', value: 'RW' }];
  constructor(private route: ActivatedRoute,
    private profileservice: Admpro200Service,
    private messageService: MessageService,
    private translateService: TranslateService,
    private localStorageService: AdmproLocalStorageService,
    private router: Router,
    renderer2:Renderer2) {
    super('P01002',renderer2);
    this.profileData.profileId = Number(this.route.snapshot.paramMap.get('profId'));
    this.profileData.checkDirty = false;
  }

  ngOnInit() {

    //case create page
    if (this.profileData.profileId == 0) {

      this.dataNotFoundUserData = true;

      if (this.localStorageService.getProfileData() == null) {

        this.profileservice.createMenuByProfile().subscribe((response: ProfileData) => {
          this.profileData = response;
          this.tempProfile.profileCode = response.profileCode;
          this.tempProfile.profileDesc = response.profileDesc;
          this.profileData.menuData = response.menuData as unknown as Array<MenuData>;
          this.profileData.userData = response.userData as unknown as Array<UserByProfileModel>;
          this.tempMenu = response.menuData as unknown as Array<MenuData>;
          this.profileData.disableBtn = true;
        });

      } else {

        this.setDataLocalStorage();

      }

    //case edit page
    } else {

      if (this.localStorageService.getProfileData() == null) {
        this.profileservice.getMenuProfileByProfileID(this.profileData.profileId).subscribe((response: ProfileData) => {
          this.profileData = response;
          this.tempProfile.profileCode = response.profileCode;
          this.tempProfile.profileDesc = response.profileDesc;
          this.profileData.menuData = response.menuData as unknown as Array<MenuData>;
          this.profileData.userData = response.userData as unknown as Array<UserByProfileModel>;
          this.tempMenu = response.menuData as unknown as Array<MenuData>;
          this.profileData.disableBtn = true;
          if (this.profileData.userData.length > 0) {
            this.dataNotFoundUserData = false;
          } else {
            this.dataNotFoundUserData = true;
          }
          this.profileData.menuData.forEach(data =>{
            this.switchOnMenuHdr(data.access, data.menuCode, 'setChange');
          })
        });
      } else {
        this.setDataLocalStorage();
      }
    }
  }

  onChangeProfile(){
    this.profileData.disableBtn = false;
  }

  setDataLocalStorage() {
    if(this.localStorageService.getProfileData().statusPage == 'EDITED'){
      this.localStorageService.updateActionByMenu(null,null,null,'EDIT');
    }
    let profilelocalStorage = this.localStorageService.getProfileData();
    this.profileData = profilelocalStorage;
    this.profileData.menuData = profilelocalStorage.menuData as unknown as Array<MenuData>;
    this.profileData.userData = profilelocalStorage.userData as unknown as Array<UserByProfileModel>;
    this.tempMenu = profilelocalStorage.menuData as unknown as Array<MenuData>;
    this.tempProfile.profileCode = this.profileData.profileCode;
    this.tempProfile.profileDesc = this.profileData.profileDesc;
    this.profileData.menuData.forEach(data =>{
      this.switchOnMenuHdr(data.access, data.menuCode, 'setChange');
    })
  }

  switchOnMenuHdr(access, menuParentCode, eventName) {

    // case switch change
    if (eventName == 'switchChange') {
      this.profileData.menuData.filter(data => {

        if (menuParentCode == data.menuCode) {

          data.menuChilde.filter(element => {
            if (access) {
              element.access = true;
              element.permission = 'RW';
            } else {
              element.access = false;
              element.permission = 'NULL';
            }
          });
          this.profileData.checkDirty = true;
          this.profileData.disableBtn = false;
        }
      });

     // case set from get profile data
    }else{
      this.profileData.menuData.forEach(data => {
        if (menuParentCode == data.menuCode) {
          let accessTrue = data.menuChilde.filter(data => data.access == true);
          if (accessTrue.length == data.menuChilde.length) {
            data.access = true;
          } else {
            data.access = false;
          }
        }
      });
    }
  }

  switchOnSubMenu(access, indexParent, indexChilde, permission) {
    let accessTrue = this.profileData.menuData[indexParent].menuChilde.filter(data => data.access == true);
    if (accessTrue.length == this.profileData.menuData[indexParent].menuChilde.length) {
      this.profileData.menuData[indexParent].access = true;
    } else {
      this.profileData.menuData[indexParent].access = false;
    }
    this.profileData.checkDirty = true;
    this.profileData.disableBtn = false;
    this.profileData.menuData[indexParent].menuChilde[indexChilde].access = access;

    if (access == true) {
      this.profileData.menuData[indexParent].menuChilde[indexChilde].permission = permission == null ? 'RW' : permission;
    } else {
      this.profileData.menuData[indexParent].menuChilde[indexChilde].permission = 'NULL';
    }

    this.profileData.menuData[indexParent].menuChilde[indexChilde].actionData.forEach(data => {
      data.access = access;
    });

  }

  switchOnPermission(permission, indexParent, indexChilde) {

    this.profileData.checkDirty = true;
    this.profileData.disableBtn = false;
    if (permission == 'RO') {
      this.profileData.menuData[indexParent].menuChilde[indexChilde].actionData.forEach(data => {
        data.access = false;
      });
    } else {
      this.profileData.menuData[indexParent].menuChilde[indexChilde].actionData.forEach(data => {
        data.access = true;
      });
    }

  }

  searchHide() {
    this.dataForSearch.advancedSearch = this.dataForSearch.advancedSearch == true ? false : true;
  }

  ClearData() {
    this.dataForSearch.searchMenuParent = null;
    this.dataForSearch.searchMenuChilde = null;
    this.dataForSearch.dataNotFoundGridTab = false;
    this.profileData.menuData = this.tempMenu;
  }

  searchAuto() {
    this.profileData.menuData = this.tempMenu;
    this.profileData.menuData = this.profileData.menuData.filter(data => {
      return (
        (data.menuDesc.toString().toLowerCase().indexOf(this.dataForSearch.dataAllSearch.toLowerCase()) > -1)
      );
    });
    if (this.profileData.menuData.length == 0) {
      this.dataForSearch.dataNotFoundGridTab = true;

    } else {
      this.dataForSearch.dataNotFoundGridTab = false;
    }
  }

  editAction(menuCode, menuDesc, actionData: ActionData[], indexParent, indexChilde) {
    this.profileData.menuSendToActionPage = {} as MenuSendToActionPage;
    this.profileData.menuSendToActionPage.menuCode = menuCode;
    this.profileData.menuSendToActionPage.menuDesc = menuDesc;
    this.profileData.menuSendToActionPage.actionData = actionData;
    this.profileData.menuSendToActionPage.profileId = this.profileData.profileId;
    this.profileData.menuSendToActionPage.indexParent = indexParent;
    this.profileData.menuSendToActionPage.indexChilde = indexChilde;
    this.profileData.statusPage = 'EDIT'
    this.localStorageService.addProfileData(this.profileData);
    window.location.href = 'admpro/admpro300';
  }

  filterData() {

    this.dataForSearch.searchMenuParent = this.dataForSearch.searchMenuParent == null ? '' : this.dataForSearch.searchMenuParent;
    this.dataForSearch.searchMenuChilde = this.dataForSearch.searchMenuChilde == null ? '' : this.dataForSearch.searchMenuChilde;
    this.profileData.menuData = this.tempMenu;
    this.profileData.menuData = this.profileData.menuData.filter((data) => {
      if (data.menuChilde.filter((data2) => {
        return (
          (data2.menuDesc.toString().toLowerCase().indexOf(this.dataForSearch.searchMenuChilde.toLowerCase()) > -1)
        );
      }).length > 0) {
        return (
          (data.menuDesc.toString().toLowerCase().indexOf(this.dataForSearch.searchMenuParent.toLowerCase()) > -1)
        );
      }
    });
    if (this.profileData.menuData.length == 0) {
      this.dataForSearch.dataNotFoundGridTab = true;

    } else {
      this.dataForSearch.dataNotFoundGridTab = false;
    }
  }

  saveProfile() {

    if (!this.profileData.profileDesc || !this.profileData.profileCode) {

      this.messageService.clear();
      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMPRO200.M00027', detail: 'ADMPRO200.M00028' });

    } else {

      if (this.profileData.profileId == null) {

        //case create data
        this.profileservice.saveProfile(this.profileData).subscribe((res:number) => {
          if (res) {
            this.profileId = res;
            this.messageService.clear();
            this.messageService.add({
              key: 'save',
              severity: 'success',
              detail: 'ADMPRO200.M00029',
            });

            setTimeout(() => {
              this.localStorageService.clearProfileData();
              window.location.href = 'admpro/admpro200/' + this.profileId;
            }, 1500);
          } else {
            this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMPRO200.M00027', detail: 'ADMPRO200.M00030' });
          }
        });

      } else {

        //case update data
        this.profileservice.updateProfile(this.profileData).subscribe((res:number) => {
          if (res) {
            this.profileId = res;
            this.messageService.clear();
            this.messageService.add({
              key: 'save',
              severity: 'success',
              detail: 'ADMPRO200.M00029',
            });

            setTimeout(() => {
              this.localStorageService.clearProfileData();
              window.location.href = 'admpro/admpro200/' + this.profileId;
            }, 1500);
          } else {
            this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMPRO200.M00027', detail: 'ADMPRO200.M00030' });
          }
        });
      }
    }
  }

  backBtn() {

    //Check Dirty
    if (this.profileData.profileId == null) {
      if (this.profileData.profileCode || this.profileData.profileDesc || this.profileData.checkDirty == true) {
        this.alertWhenBlackPage();
      } else {
        this.router.navigate(['admpro/admpro100']);
      }
    } else {
      if (this.profileData.profileCode != this.tempProfile.profileCode || this.profileData.profileDesc != this.tempProfile.profileDesc || this.profileData.checkDirty == true) {
        this.alertWhenBlackPage();
      } else {
        this.router.navigate(['admpro/admpro100']);
      }
    }

  }

  alertWhenBlackPage() {
    this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'ADMPRO200.M00070', detail: 'ADMPRO200.M00031' });
  }

  clickCancel() {
    this.messageService.clear();
  }

  clickOk(message) {

    if (message == 'แจ้งเตือน') {
      this.localStorageService.clearProfileData();
      this.router.navigate(['admpro/admpro100']);
    }
  }

  clickAfterAlert(){
    this.messageService.clear();
  }
}
