import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { SellerService } from 'src/app/shared/services/seller.service';
import { sellerDetailPage } from './model/serser200.model';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';

@Component({
  selector: 'app-serser200',
  templateUrl: './serser200.component.html',
  styleUrls: ['./serser200.component.scss']
})
export class Serser200Component extends PermissionComponent {

  public sellerPage: sellerDetailPage = {} as sellerDetailPage;
  translateLablePipe = new TranslateLablePipe()

  sellerDetailForm = new FormGroup({
    // ข้อมูลผู้ขาย
    sellerCode: new FormControl({ value: '', disabled: true }),
    sellerDescEng: new FormControl({ value: '', disabled: true }),
    sellerDescLocal: new FormControl({ value: '', disabled: true }),
    sellerType: new FormControl({ value: '', disabled: true }),
    sellerShortsellerName: new FormControl({ value: '', disabled: true }),
    sellerTaxId: new FormControl({ value: '', disabled: true }),

    // ช่องทางการติดต่ออื่นๆ
    sellerLine: new FormControl({ value: '', disabled: true }),
    sellerFacebook: new FormControl({ value: '', disabled: true }),
    sellerInstagram: new FormControl({ value: '', disabled: true }),

    // ข้อมูลที่อยู่
    sellerAddress: new FormControl({ value: '', disabled: true }),
    sellerSubDistrict: new FormControl({ value: '', disabled: true }),
    sellerDistrict: new FormControl({ value: '', disabled: true }),
    sellerProvince: new FormControl({ value: '', disabled: true }),
    sellerPostalCode: new FormControl({ value: '', disabled: true }),
    sellerCountry: new FormControl({ value: '', disabled: true }),
    sellerContactName: new FormControl({ value: '', disabled: true }),
    sellerTel1: new FormControl({ value: '', disabled: true }),
    sellerTel2: new FormControl({ value: '', disabled: true }),
    sellerEmail: new FormControl({ value: '', disabled: true }),
    sellerGln: new FormControl({ value: '', disabled: true }),

    // ข้อมูลบัญชีธนาคาร
    sellerBankNo: new FormControl({ value: '', disabled: true }),
    sellerBankAccName: new FormControl({ value: '', disabled: true }),
    sellerBankName: new FormControl({ value: '', disabled: true }),

    // ข้อมูลร้านค้า
    storeNameTH: new FormControl({ value: '', disabled: true }), //ไทย
    storeNameKH: new FormControl({ value: '', disabled: true }), //กัมพูชา
    storeNameLA: new FormControl({ value: '', disabled: true }), //ลาว
    storeNameMM: new FormControl({ value: '', disabled: true }), //พม่า
    storeNameVN: new FormControl({ value: '', disabled: true }), //เวียดนาม
    storeNameMY: new FormControl({ value: '', disabled: true }), //มาเลเซีย

    // ข้อมูลการใช้แพ็คเกจหลัก
    pkgKlangOne: new FormControl({ value: '', disabled: true }),
    statPkgDate: new FormControl({ value: '', disabled: true }),
    endPkgDate: new FormControl({ value: '', disabled: true }),
    sellerCountryConnect: new FormControl({ value: '', disabled: true }),
    updateDate: new FormControl({ value: '', disabled: true }),

  });

  constructor(
    private sellerService: SellerService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    renderer2: Renderer2) {
    super('P15001', renderer2);
    this.sellerPage.sellerCode = this.route.snapshot.paramMap.get('sellerCode');
    this.getSellerDetail();
  }

  ngOnInit() {
  }

  getSellerDetail() {
    let sellerDataTemp;
    this.sellerService.getSellerDetail(this.sellerPage.sellerCode).subscribe(response => {
      sellerDataTemp = JSON.parse(JSON.stringify(response))
      this.sellerDetailForm.patchValue({
        // ข้อมูลผู้ขาย
        sellerCode: sellerDataTemp.sellerCode,
        sellerDescEng: sellerDataTemp.sellerDescEng,
        sellerDescLocal: sellerDataTemp.sellerDescLocal,
        sellerType: sellerDataTemp.sellerType,
        sellerShortsellerName: sellerDataTemp.sellerShortsellerName,
        sellerTaxId: sellerDataTemp.sellerTaxId,

        // ช่องทางการติดต่ออื่นๆ
        sellerLine: sellerDataTemp.sellerLine,
        sellerFacebook: sellerDataTemp.sellerFacebook,
        sellerInstagram: sellerDataTemp.sellerInstagram,

        // ข้อมูลที่อยู่
        sellerAddress: sellerDataTemp.sellerAddress,
        sellerSubDistrict: this.translateLablePipe.transform(sellerDataTemp.sellerSubDistrict_eng,sellerDataTemp.sellerSubDistrict_local),
        sellerDistrict: this.translateLablePipe.transform(sellerDataTemp.sellerDistrict_eng,sellerDataTemp.sellerDistrict_local),
        sellerProvince: this.translateLablePipe.transform(sellerDataTemp.sellerProvince_eng,sellerDataTemp.sellerProvince_local),
        sellerPostalCode: sellerDataTemp.sellerPostalCode,
        sellerCountry: this.translateLablePipe.transform(sellerDataTemp.sellerCountry_eng,sellerDataTemp.sellerCountry_local),
        sellerContactName: sellerDataTemp.sellerContactName,
        sellerTel1: sellerDataTemp.sellerTel1,
        sellerTel2: sellerDataTemp.sellerTel2,
        sellerEmail: sellerDataTemp.sellerEmail,
        sellerGln: sellerDataTemp.sellerGln,

        // ข้อมูลบัญชีธนาคาร
        sellerBankNo: sellerDataTemp.sellerBankNo,
        sellerBankAccName: sellerDataTemp.sellerBankAccName,
        sellerBankName: sellerDataTemp.sellerBankName,

        // ข้อมูลร้านค้า
        storeNameTH: sellerDataTemp.storeNameTH,
        storeNameKH: sellerDataTemp.storeNameKH,
        storeNameLA: sellerDataTemp.storeNameLA,
        storeNameMM: sellerDataTemp.storeNameMM,
        storeNameVN: sellerDataTemp.storeNameVN,
        storeNameMY: sellerDataTemp.storeNameMY,

        // ข้อมูลการใช้แพ็คเกจหลัก
        pkgKlangOne: sellerDataTemp.pkgKlangOne,
        statPkgDate: sellerDataTemp.statPkgDate,
        endPkgDate: sellerDataTemp.endPkgDate,
        sellerCountryConnect: sellerDataTemp.sellerCountryConnect,
        updateDate: sellerDataTemp.updateDate
      });
    })
  }

  backBtn(){
    this.router.navigate(['/serser/serser100']);
  }

}
