import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { DashboardLogisticStatusModel } from '../model/dshboard-logistic.model';
import { DeliveryNoteModel } from '../model/dashboard-logistic-delivery-note.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardLogisticService {

  private DSHLGT_URL = `${this.environmentService.getApiUrl()}/dsh/lgt`;

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService,
              private authenticationService: AuthenticationService) { }

  getDashboardLogisticStatusData(period: string, country: string, seller: string): Observable<DashboardLogisticStatusModel> {
    const URL = `${this.DSHLGT_URL}/logisticstatus/period=${period},country=${country},seller=${seller}`;
    return this.http.get<DashboardLogisticStatusModel>(URL, {headers: this.authenticationService.tokenHeader()});
  }

  getDeliveryNoteDetail(country: string, deliveryNoteNo: string, seller: string): Observable<DeliveryNoteModel> {
    const URL = `${this.DSHLGT_URL}/deliveryNoteDetail?country=${country}&deliveryNoteNo=${deliveryNoteNo}&seller=${seller}`;
    return this.http.get<DeliveryNoteModel>(URL, {headers: this.authenticationService.tokenHeader()});
  }
}
