import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { Synsyn100Component } from './synchronize/synsyn100/synsyn100.component';
import { CoreRouthModuleModule } from './core-routing.module';

import { Autaut100Component } from './authentication/autaut100/autaut100.component';
import { Autaut200Component } from './authentication/autaut200/autaut200.component';
import { Autaut300Component } from './authentication/autaut300/autaut300.component';
import { AboutComponent } from './application/about/about.component';

@NgModule({
  declarations: [
    Synsyn100Component,
    Autaut100Component,
    Autaut200Component,
    Autaut300Component,
    AboutComponent
  ],
  imports: [
    CommonModule,
    CoreRouthModuleModule,
    SharedModule
  ]
})
export class CoreModule { }
