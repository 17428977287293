
import { Injectable } from '@angular/core';
import { excexc100Data } from 'src/app/core/interfaces/excexc100Model';

@Injectable({
    providedIn: 'root'
  })
export class ExchangeRateService{
    exchangerate: Array<excexc100Data> = [
        {
            exchange_rate_code : 'THA00001',
            exchange_rate_country_start : 'Thailand',
            exchange_rate_money_start: '100',
            exchange_rate_Currency_start: 'THB',
            exchange_rate_country_end : 'Lao People'+"'"+'s Democratic Republic',
            exchange_rate_money_end: '29124.57',
            exchange_rate_Currency_end: 'LAK',
            exchange_rate_date_start: '01/10/2020',
            exchange_rate_date_end: '31/10/2020'
        },
        {
            exchange_rate_code : 'LAO00001',
            exchange_rate_country_start : 'Lao People'+"'"+'s Democratic Republic',
            exchange_rate_money_start: '100',
            exchange_rate_Currency_start: 'LAK',
            exchange_rate_country_end : 'Thailand',
            exchange_rate_money_end: '0.34',
            exchange_rate_Currency_end: 'THB',
            exchange_rate_date_start: '01/11/2020',
            exchange_rate_date_end: '30/11/2020'
        },
        {
            exchange_rate_code : 'LAO00002',
            exchange_rate_country_start : 'Lao People'+"'"+'s Democratic Republic',
            exchange_rate_money_start: '100',
            exchange_rate_Currency_start: 'LAK',
            exchange_rate_country_end : 'Thailand',
            exchange_rate_money_end: '0.45',
            exchange_rate_Currency_end: 'THB',
            exchange_rate_date_start: '01/12/2020',
            exchange_rate_date_end: '31/12/2020'
        }
    ]

}