import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import * as moment from 'moment';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { DashboardOverallService } from 'src/app/shared/services/dashboard-overall.service';
import { DashboardSaleService } from 'src/app/shared/services/dashboard-sale.service';
import { DashboardService } from 'src/app/shared/services/dshboard.service';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterEntity } from 'src/app/shared//model/parameterwmsmodel';
import { DecimalData } from 'src/app/dsh/model/dsh-model';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { decimalTypePurchase, decimalTypeRoundMethod, decimalTypeRoundToValue, decimalTypeSelling } from 'src/app/shared/constant/decimal.constant';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Dshova100Service } from './service/dshova100.service';
import { Dshova100Model, SalesByItemGrid, TempDDL } from './model/dshova100.model';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { ThaiDatePipe } from 'src/app/shared/pipes/thaidate.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-dshova100',
  templateUrl: './dshova100.component.html',
  styleUrls: ['./dshova100.component.scss']
})
export class Dshova100Component implements OnInit {
  public decimalData: DecimalData = {} as DecimalData;
  method: string;
  roundToDecimal: number;
  decimal: number = 0;
  // Overall
  programId: string = 'P10001' as string;

  public page_model: Dshova100Model;
  translateLablePipe = new TranslateLablePipe();
  thaidate = new ThaiDatePipe();
  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();
  optionSaleCat: TempDDL[];

  constructor(private dshovaService: DashboardOverallService,
    private dshsalService: DashboardSaleService,
    private dshService: DashboardService,
    private parameterService: ParameterService,
    private parameterWmsSerive: ParameterWmsSerive,
    private decimalService: DecimalService,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService,
    private autService: AuthenticationService,
    private dshova100Service: Dshova100Service,
    
  ) {
    this.page_model = this.dshova100Service.load;
    this.page_model.form_model.gridSearchCriteriaForm.controls['seller'].setValue(JSON.parse(localStorage.getItem('userData')).sellerCode);
    this.pageName = localStorage.getItem(this.programId);
    this.sumQty = 0;
    this.first = 0;
    this.sumQtyPc = 0;
    this.count = 0;
    this.getOptionData();

    this.optionChart = {
      legend: {
        display: false
      }, scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            userCallback: {
              function(label) {
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            },
            suggestedMin: 0,
          }
        }],
        xAxes: [{
          gridLines: {
            color: 'rgb(64,64,64)',
            display: false
          },
          ticks: {
            fontColor: 'rgb(64,64,64)',
          }
        }]
      }
    };
    setTimeout(() => {
      this.translateMessage();
    }, 2000);
  }
  optionData: SelectItem[];
  dataShowChart: any = [];
  chartType = 'bar';
  chartData: any;
  optionChart: any;
  currency = { currencyCd: '', currencyDesc: '' };
  seller = 'KHA-0001';
  periodType = 'D';
  period = 1;
  sellerCriteria: any;
  sumQty: number;
  first: number;
  dateFrom: string;
  dateTo: string;
  todate: string;
  startToMonth: string;
  threeMonthAgo: string;
  sumQtyPc: number;
  count: number;
  optionCountry: ParameterEntity[];
  pageName: string;
  optionValueDefault: any;
  monthList = [];
  paramToSeller: any = { country: true, warehouse: true };
  month = [];
  dataList: any

  @ViewChild('dateFilter', undefined) private dateFilter: any;

  translateMessage() {
    let monthLangParam = ['DSHOVA100.M00033', 'DSHOVA100.M00034', 'DSHOVA100.M00035', 'DSHOVA100.M00036', 'DSHOVA100.M00037', 'DSHOVA100.M00038',
      'DSHOVA100.M00039', 'DSHOVA100.M00040', 'DSHOVA100.M00041', 'DSHOVA100.M00042', 'DSHOVA100.M00043', 'DSHOVA100.M00044'];
    for (let i = 0; i < monthLangParam.length; i++) {
      this.translateService.get(monthLangParam[i]).subscribe(async res => {
        this.monthList.push(await res);
      });
    }
  }

  ngOnInit() {
    if (window.screen.width <= 640) {
      this.page_model.isRight = false;
    } else {
      this.page_model.isRight = true;
    }
    this.autService.getParameterWmsCountry().subscribe((response) => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.optionCountry.length == 1) {
        this.page_model.disableCountry = true;
        this.page_model.form_model.gridSearchCriteriaForm.controls['country'].disable();
        this.page_model.form_model.gridSearchCriteriaForm.controls['country'].setValue(this.optionCountry[0].value);
        this.parameterService.getvprmdtltolableByCountry(104, this.optionCountry[0].value).subscribe(async response => {
          this.page_model.parameterDecimal = await JSON.parse(JSON.stringify(response));
          this.page_model.decimalData.dataTypeDecimalPurchase = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalPurchase);
          this.page_model.decimalData.dataTypeDecimalSelling = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalSelling);
          this.page_model.decimalData.dataTypeDecimalRoundMethod = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalRoundMethod);
          this.page_model.decimalData.dataTypeDecimalRoundToValue = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalRoundToValue);
          this.page_model.method = await this.page_model.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1;
          this.page_model.roundToDecimal = await this.page_model.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
          this.page_model.decimal = await this.page_model.decimalData.dataTypeDecimalPurchase[0].value.pmdtbdnumv1;
        }, err => {
          console.log(err);
        });
        this.autService.getParameterNewSaleChannel(this.page_model.form_model.gridSearchCriteriaForm.controls['country'].value).subscribe((response) => {
          this.optionSaleCat = JSON.parse(JSON.stringify(response));
        }, err => {
          console.log(err);
        });
        this.getCurrency(this.optionCountry[0].parameterData.entry_code);
        this.paramToSeller = { country: this.page_model.form_model.gridSearchCriteriaForm.controls['country'].value, warehouse: null }
      }else if(this.optionCountry.length > 1){
        this.page_model.form_model.gridSearchCriteriaForm.controls['country'].reset();
      }else if(this.optionCountry.length == 0){
        this.page_model.disableCountry = true;
        this.page_model.form_model.gridSearchCriteriaForm.controls['country'].disable();
      }
    }, err => {
      console.log(err);
    });

    this.page_model.tempData = [
      {
        'header': this.translateService.instant('DSHOVA100.M00048'),
        'order': 0,
        'icon': 'pi pi-shopping-cart',
      },
      {
        'header': this.translateService.instant('DSHOVA100.M00049'),
        'order': 0,
        'icon': 'pi pi-clock',
      },
      {
        'header': this.translateService.instant('DSHOVA100.M00050'),
        'order': 0,
        'icon': 'fa fa-hourglass-half',
      },
      {
        'header': this.translateService.instant('DSHOVA100.M00051'),
        'order': 0,
        'icon': 'fa fa-calendar',
      }
    ];
    this.page_model.showDataNotFoundStockChart = true;
    this.page_model.showDataNotFound = true;
    this.page_model.salesByItemGrid = null;
    this.page_model.showDataNotFoundGrid = true;
    this.page_model.showGrid = false;

    const todayDate = new Date();
    this.todate = moment(todayDate).format('YYYY-MM-DD');
    this.startToMonth = moment(todayDate).startOf('month').format('YYYY-MM-DD');
    this.threeMonthAgo = moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DD');
  }

  async getOptionData() {
    this.parameterService.getvparameterDetailToLable(2000).subscribe((response) => {
      this.optionData = JSON.parse(JSON.stringify(response));
      this.optionData = this.optionData.sort((a, b) => a.value.pmdtbdnumv3 < b.value.pmdtbdnumv3 ? -1 : a.value.pmdtbdnumv3 > b.value.pmdtbdnumv3 ? 1 : 0);
      const defaultSelectItem: SelectItem[] = this.optionData.filter((selectItem: SelectItem) => {
        return selectItem.value.parameterDetailPK.pmdtbdtbdentcd == '3M';
      });

      this.optionValueDefault = defaultSelectItem[0].value;
      this.page_model.form_model.gridHeaderCriteriaForm.controls['defaultPeriod'].setValue(this.optionValueDefault);
    }, err => {
      console.log(err);
    });
  }
  async getSeller(seller) {
    if (this.page_model.form_model.gridSearchCriteriaForm.controls['country'].value) {
      this.page_model.form_model.gridSearchCriteriaForm.controls['seller'].setValue(seller.seller);
      this.page_model.form_model.gridSearchCriteriaForm.controls['sellerLength'].setValue(seller.length);
      this.getCurrency(this.page_model.form_model.gridSearchCriteriaForm.controls['country'].value);
      this.getFormatNumber(this.page_model.form_model.gridSearchCriteriaForm.controls['country'].value);
      this.page_model.showDetail = true;
      this.page_model.periodSaleShow = this.translateService.instant('DSHOVA100.M00052');
      this.page_model.showToday = false;
      this.page_model.showNotToday = true;
      this.dateFrom = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD');
      this.dateTo = moment(new Date()).format('YYYY-MM-DD');
      await this.getTotalSale('M', '3');
      this.getCountInvoice();
      this.page_model.form_model.gridHeaderCriteriaForm.controls['defaultPeriod'].setValue(this.optionValueDefault);
      this.month = ["3M", 3, "M"]
      this.ListData(this.month);
    }
  }
  wareHouse(page) {
    this.page_model.form_model.gridSearchCriteriaForm.controls['country'].setValue(page.value);
    this.page_model.form_model.gridSearchCriteriaForm.controls['seller'].setValue(null);
    this.paramToSeller = { country: this.page_model.form_model.gridSearchCriteriaForm.get('country').value, warehouse: null }
    this.parameterService.getvprmdtltolableByCountry(104, page.value).subscribe(async response => {
      this.page_model.parameterDecimal = await JSON.parse(JSON.stringify(response));
      this.page_model.decimalData.dataTypeDecimalPurchase = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalPurchase);
      this.page_model.decimalData.dataTypeDecimalSelling = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalSelling);
      this.page_model.decimalData.dataTypeDecimalRoundMethod = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalRoundMethod);
      this.page_model.decimalData.dataTypeDecimalRoundToValue = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalRoundToValue);
      this.page_model.method = await this.page_model.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1;
      this.page_model.roundToDecimal = await this.page_model.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
      this.page_model.decimal = await this.page_model.decimalData.dataTypeDecimalPurchase[0].value.pmdtbdnumv1;
    }, err => {
      console.log(err);
    });
    this.getCurrency(this.page_model.form_model.gridSearchCriteriaForm.controls['country'].value);
    this.autService.getParameterNewSaleChannel(this.page_model.form_model.gridSearchCriteriaForm.controls['country'].value).subscribe((response) => {
      this.optionSaleCat = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.page_model.showDataNotFoundStockChart = true;
    this.page_model.showDataNotFound = true;
    this.page_model.salesByItemGrid = null;
    this.page_model.showDataNotFoundGrid = true;
    this.page_model.showGrid = false;
    this.page_model.periodSaleShow = null;
    this.dateFrom = null;
    this.dateTo = null;
    this.page_model.form_model.gridHeaderCriteriaForm.reset();
    this.page_model.tempData = [
      {
        'header': this.translateService.instant('DSHOVA100.M00048'),
        'order': 0,
        'icon': 'pi pi-shopping-cart',
      },
      {
        'header': this.translateService.instant('DSHOVA100.M00049'),
        'order': 0,
        'icon': 'pi pi-clock',
      },
      {
        'header': this.translateService.instant('DSHOVA100.M00050'),
        'order': 0,
        'icon': 'fa fa-hourglass-half',
      },
      {
        'header': this.translateService.instant('DSHOVA100.M00051'),
        'order': 0,
        'icon': 'fa fa-calendar',
      }
    ];

  }

  getFormatNumber(country) {
    this.parameterService.getvprmdtltolableByCountry(104, country).subscribe(response => {
      let decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypePurchase);
      this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeSelling);
      this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundMethod);
      this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundToValue);
      this.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
      this.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
      this.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;

    }, err => {
      console.log(err);
    });
  }

  getCurrency(country) {
    this.parameterWmsSerive.getParameterWmsBytableNoAndCode(105, country).subscribe((response) => {
      this.parameterWmsSerive.getParameterWmsBytableNoAndCode(9146, response[0].parameterData.parameter_v6).subscribe((currencyData) => {
        this.currency.currencyCd = currencyData[0].parameterData.parameter_v1;
        this.currency.currencyDesc = response[0].parameterData.parameter_v6;
      });
    });
  }

  changeTypeChart(type) {
    this.chartType = type;
    this.createChart(this.dataShowChart);
    if (type === 'line') {
      this.page_model.disButtonChart = true;
    } else {
      this.page_model.disButtonChart = false;
    }
  }
  selectedData(event: any) {
    this.dataList = [event.value.parameterDetailPK.pmdtbdtbdentcd, event.value.pmdtbdnumv1, event.value.pmdtbdtxtv1]
    this.ListData(this.dataList);
  }

  ListData(event: any) {
    this.sumQtyPc = 0;
    if (event[0] == '0D') {
      this.page_model.periodSaleShow = this.translateService.instant('DSHOVA100.M00053');
    } else if (event[0] == '0M') {
      this.page_model.periodSaleShow = this.translateService.instant('DSHOVA100.M00054');
    } else if (event[0] == '0Y') {
      this.page_model.periodSaleShow = this.translateService.instant('DSHOVA100.M00055');
    } else if (event[0] == '1D') {
      this.page_model.periodSaleShow = this.translateService.instant('DSHOVA100.M00056');
    } else if (event[0] == '1M') {
      this.page_model.periodSaleShow = this.translateService.instant('DSHOVA100.M00057');
    } else if (event[0] == '3M') {
      this.page_model.periodSaleShow = this.translateService.instant('DSHOVA100.M00052');
    } else {
      this.page_model.periodSaleShow = this.translateService.instant('DSHOVA100.M00058');
    }
    this.page_model.exportReportCondition = event[0];
    this.getAllChartData(event[2], event[1]);
    if (event[2] == 'D') {
      if (event[1] == '0' || event[1] == '1') {
        this.page_model.showToday = true;
        this.page_model.showNotToday = false;
        this.page_model.showSaleChart = false;
        this.page_model.showSaleAmount = true;
        if (event[1] == '0') {
          this.todate = moment(new Date()).format('YYYY-MM-DD');
        } else {
          this.todate = moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD');
        }
      } else if ((event[1] == '7')) {
        this.page_model.showToday = false;
        this.page_model.showNotToday = true;
        this.page_model.showSaleChart = true;
        this.page_model.showSaleAmount = false;
        this.dateFrom = moment().subtract(event[1] - 1, 'days').format('YYYY-MM-DD');
        this.dateTo = moment(new Date()).format('YYYY-MM-DD');
      }
    } else if (event[2] == 'Y') {
      if (event[1] == '0' || event[1] == '1') {
        if (event[1] == '0') {
          this.dateFrom = moment().startOf('year').format('YYYY-MM-DD');
          this.todate = moment(new Date()).format('YYYY-MM-DD');
        } else {
          this.dateFrom = moment().startOf('year').format('YYYY-MM-DD');
          this.dateTo = moment().endOf('year').format('YYYY-MM-DD');
        }
      } else {
        this.dateFrom = moment().startOf('year').format('YYYY-MM-DD');
        this.dateTo = moment(new Date()).format('YYYY-MM-DD');
      }
    } else {
      this.page_model.showToday = false;
      this.page_model.showNotToday = true;
      this.page_model.showSaleChart = true;
      this.page_model.showSaleAmount = false;
      if (event[1] == '1') {
        this.dateFrom = moment().subtract(event[1], 'month').startOf('month').format('YYYY-MM-DD');
        this.dateTo = moment().subtract(event[1], 'month').endOf('month').format('YYYY-MM-DD');
      } else {
        this.dateFrom = moment().subtract(event[1], 'month').startOf('month').format('YYYY-MM-DD');
        this.dateTo = moment(new Date()).format('YYYY-MM-DD');
      }
    }
    this.first = 0;
  }

  advanceFilter() {
    this.page_model.showAdvanceSearch = !this.page_model.showAdvanceSearch;
  }

  exportReport() {
    const subTitle = [];
    const labelFrom = moment(this.dateFrom).format('DD/MM/YYYY');
    const labelTo = moment(this.dateTo).format('DD/MM/YYYY');
    if (this.page_model.exportReportCondition == '0D') {
      subTitle.push([`${this.translateService.instant('DSHOVA100.M00045')} ${this.translateService.instant('DSHOVA100.M00010')} ${this.page_model.periodSaleShow} ${labelTo}`]);
    } else if (this.page_model.exportReportCondition == '1D') {
      subTitle.push([`${this.translateService.instant('DSHOVA100.M00045')} ${this.translateService.instant('DSHOVA100.M00010')} ${this.page_model.periodSaleShow} ${moment(new Date()).subtract(1, 'days').format('DD/MM/YYYY')}`]);
    } else {
      subTitle.push([`${this.translateService.instant('DSHOVA100.M00045')} ${this.translateService.instant('DSHOVA100.M00010')} ${this.page_model.periodSaleShow} ${labelFrom} ${this.translateService.instant('DSHOVA100.M00029')} ${labelTo}`]);
    }

    let header: any = {
      // 'เลขที่ออเดอร์'
      invoiceNumber: this.translateService.instant('DSHOVA100.M00013'),
      // 'วันที่ออเดอร์'
      invoiceDate: this.translateService.instant('DSHOVA100.M00014'),
      // 'รหัสสินค้าจาก Seller'
      itemCode: this.translateService.instant('DSHOVA100.M00015'),
      // 'รหัสสินค้า KlangOne'
      onlineCode: this.translateService.instant('DSHOVA100.M00016'),
      //  'ชื่อสินค้า'
      productName: this.translateService.instant('DSHOVA100.M00017'),
      // 'จำนวน'
      pcs: this.translateService.instant('DSHOVA100.M00018'),
      // 'หน่วย'
      unit: this.translateService.instant('DSHOVA100.M00019'),
      // 'ยอดขาย'
      usd: `${this.translateService.instant('DSHOVA100.M00020')}${this.currency.currencyCd ? '(' + this.currency.currencyCd + ')' : ''}`,
      // 'ช่องทางการขาย'
      callcenterId: this.translateService.instant('DSHOVA100.M00021')
    };

    let export_data_list = [];
    this.page_model.salesByItemGrid.forEach((res: SalesByItemGrid) => {
      let export_data = {
        invoiceDate: this.thaidate.transform(res.sales_order_date, 'shortCommonFullTime'),
        pcs: res.order_quantity,
        onlineCode: res.system_item_code,
        productName: res.item_desc_eng,
        itemCode: res.item_code_from_seller,
        sellerId: '',
        usd: this.decimalService.getRoundingDecimal(Number(res.total_amount_inc_vat), this.method, this.roundToDecimal, this.decimal),
        invoiceNumber: res.sales_order_no,
        unit: res.sku_unit,
        callcenterId: res.sales_channel_desc_eng
      }
      export_data_list.push(export_data);
    });
    this.exportExcelService.exportAsExcelFile(export_data_list, this.translateService.instant('DSHOVA100.M00010'), header, null, subTitle);
  }

  searchAdvance() {

    this.page_model.salesByItemGrid = this.page_model.backupGridData;
    if (this.page_model.form_model.gridAdvanceSearchForm.controls['dateCriteria'].value) {
      if (this.page_model.form_model.gridAdvanceSearchForm.controls['dateCriteria'].value[0] && this.page_model.form_model.gridAdvanceSearchForm.controls['dateCriteria'].value[1] == null) {
        this.page_model.form_model.gridAdvanceSearchForm.controls['dateCriteria'].setValue([this.page_model.form_model.gridAdvanceSearchForm.controls['dateCriteria'].value[0], this.page_model.form_model.gridAdvanceSearchForm.controls['dateCriteria'].value[0]]);
      }
    }
    if (this.page_model.form_model.gridAdvanceSearchForm.controls['itemCodeCriteria'].value) {
      this.page_model.salesByItemGrid = this.page_model.salesByItemGrid.filter(sales =>
        sales.item_code_from_seller == null ? '' : sales.item_code_from_seller.toLowerCase()
          .includes(this.page_model.form_model.gridAdvanceSearchForm.controls['itemCodeCriteria'].value.toLowerCase()));
    }
    if (this.page_model.form_model.gridAdvanceSearchForm.controls['onlineCodeCriteria'].value) {
      this.page_model.salesByItemGrid = this.page_model.salesByItemGrid.filter(sales =>
        sales.system_item_code == null ? '' : sales.system_item_code.toLowerCase()
          .includes(this.page_model.form_model.gridAdvanceSearchForm.controls['onlineCodeCriteria'].value.toLowerCase()));
    }
    if (this.page_model.form_model.gridAdvanceSearchForm.controls['dateCriteria'].value) {

      const fromDate = this.page_model.form_model.gridAdvanceSearchForm.controls['dateCriteria'].value[0];
      const toDate = this.page_model.form_model.gridAdvanceSearchForm.controls['dateCriteria'].value[1]; 
       
      this.page_model.salesByItemGrid = this.page_model.salesByItemGrid.filter(sales => {
    
        if((fromDate <= new Date(sales.sales_order_date).setHours(0,0,0,0)) && (new Date(sales.sales_order_date).setHours(0,0,0,0) <= toDate)) {
          return true;
        }
        
      });
    }

    if (this.page_model.form_model.gridAdvanceSearchForm.controls['saleCat'].value) {
      this.page_model.salesByItemGrid = this.page_model.salesByItemGrid.filter(sales => 
        this.page_model.form_model.gridAdvanceSearchForm.controls['saleCat'].value.includes(sales.sales_channel)
      ); 
    }

    if (this.page_model.salesByItemGrid.length == 0 || !this.page_model.salesByItemGrid) {
      this.page_model.showDataNotFoundGrid = true;
      this.page_model.showGrid = false;
    } else {
      this.page_model.showDataNotFoundGrid = false;
      this.page_model.showGrid = true;
    }
    this.first = 0;
  }

  clearSearch() {
    this.count = 0;
    this.page_model.salesByItemGrid = this.page_model.backupGridData;
    this.page_model.showDataNotFoundGrid = false;
    this.page_model.showGrid = true;
    this.page_model.form_model.gridAdvanceSearchForm.reset();
  }

  createChart(data) {
    this.chartData = {
      labels: data.lables,
      datasets: [
        {
          backgroundColor: '#ce2e6c',
          data: data.salesQty,
          fill: false,
          borderColor: '#ce2e6c'
        }
      ]
    }
    this.optionChart = {
      legend: {
        position: false,
      }, scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            userCallback: {
              function(label) {
                if (Math.floor(label) == label) {
                  return label;
                }
              },
              suggestedMin: 0,
            }
          }
        }],
        xAxes: [{
          gridLines: {
            color: 'rgb(64,64,64)',
            display: false
          },
          ticks: {
            fontColor: 'rgb(64,64,64)',
          }
        }]
      }
    };
  }

  getTotalSale(datetype: string, period: string) {
    this.dshova100Service.loadOverviewData(this.page_model, this.page_model.form_model.gridSearchCriteriaForm.controls['country'].value, this.page_model.form_model.gridSearchCriteriaForm.controls['seller'].value, this.page_model.uuid, period + datetype, datetype, period);
  }

  getCountInvoice() {
    this.dshService.getCountInvoice(this.page_model.form_model.gridSearchCriteriaForm.controls['country'].value, this.page_model.form_model.gridSearchCriteriaForm.controls['seller'].value).subscribe(saleMove => {
      this.page_model.form_model.gridHeaderCriteriaForm.controls['countinv'].setValue(saleMove.countinv);
      this.page_model.form_model.gridHeaderCriteriaForm.controls['countitem'].setValue(saleMove.countitem);
    });
  }

  getAllChartData(datetype: string, period: string) {
    this.dshova100Service.loadAllChartDataData(this.page_model, this.page_model.form_model.gridSearchCriteriaForm.controls['country'].value, this.page_model.form_model.gridSearchCriteriaForm.controls['seller'].value, period + datetype, this.page_model.uuid, datetype, period);
  }

  onDatesRangeFilterSelected(_selectedValue: Date) {
    if (this.page_model.form_model.gridAdvanceSearchForm.controls['dateCriteria'].value[1]) {
      this.dateFilter.hideOverlay();
    }
  }
}
