import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ParameterEntity } from 'src/app/shared/model/paramete.rmodel';
import { EtnWorkingLink, TempDDL } from '../../model/ewlsel.model';
import { EwlselService } from '../../service/ewlsel.service'


@Component({
  selector: 'app-ewlsel100',
  templateUrl: './ewlsel100.component.html',
  styleUrls: ['./ewlsel100.component.scss']
})
export class Ewlsel100Component implements OnInit {

  EtnWorkingLinkData: Array<EtnWorkingLink>;
  tempEtnWorkingLinkData:any[];
  sellerCode: string;
  param = { country: null, warehouse: null };
  selectedScopes:any;
  sellerData: TempDDL[];
  sellerLength: any;
  sellerValue: string;
  searchShort: string;
  dataNotFound: boolean = true;
  dataNotFoundBySeller: boolean = true;
  searchDisabled: boolean = true;
  disableSeller: boolean = false;

  EwlForm = new FormGroup({
    seller: new FormControl(),
  });

  constructor(
    private autService: AuthenticationService,
    private ewlselService:EwlselService
  ) { }

  ngOnInit() {
    this.checkDropDown();
  }

  getSellerCode(seller) {
    this.sellerCode = seller.value;    
    this.searchDisabled = false;
  }

  checkDropDown(){
    this.autService.getParameterWmsSeller(this.param.country, this.param.warehouse).subscribe((res: ParameterEntity[]) => {
      this.sellerData = JSON.parse(JSON.stringify(res)); 
      if (this.sellerData.length == 1) {
        this.sellerLength = this.sellerData.length;
        this.sellerCode = this.sellerData[0].value;
        this.selectedScopes  = [];
        this.sellerData.map(seller => this.selectedScopes.push(seller.value));
        this.EwlForm.controls['seller'].setValue(this.selectedScopes);
        this.disableSeller = true;
        this.dataNotFound = false;
        this.searchData()
      }else if (this.sellerData.length == 0) {
        this.sellerLength = this.sellerData.length;
      }
    }, err => {
      console.log(err);
    }
    );
  }

  searchData(){
    this.ewlselService.getExternalLinkBySeller(this.sellerCode).subscribe((res:Array<EtnWorkingLink>) => {
      this.EtnWorkingLinkData = res;
      let sellerCode:any[] = [];
      sellerCode = JSON.parse(JSON.stringify(this.sellerCode)); 

      if( this.EtnWorkingLinkData == null){
        this.EtnWorkingLinkData = [];
        if(typeof sellerCode === 'string'){
          let sellerData = this.sellerData.filter(data => data.value == sellerCode);
            this.EtnWorkingLinkData.push({
              seller_code : sellerCode,
              seller_edesc : sellerData[0].engDesc,
              seller_ldesc : sellerData[0].localDesc,
              smartSearchShort: '',
              attribute:null})
        }else{
          sellerCode.forEach(sel => {
            let sellerData = this.sellerData.filter(data => data.value == sel);
            this.EtnWorkingLinkData.push({
              seller_code : sel,
              seller_edesc : sellerData[0].engDesc,
              seller_ldesc : sellerData[0].localDesc,
              smartSearchShort: '',
              attribute:null})
          });
        }
      } else {       
        let sellerFilterRes = this.EtnWorkingLinkData.map(sel => { return sel.seller_code; });
        let sellerNotFond = sellerCode.filter(sel => !sellerFilterRes.includes(sel)); 
        sellerNotFond.forEach(sel => {
        let sellerData = this.sellerData.filter(data => data.value == sel);
        this.EtnWorkingLinkData.push({
          seller_code : sel,
          seller_edesc : sellerData[0].engDesc,
          seller_ldesc : sellerData[0].localDesc,
          smartSearchShort: '',
          attribute:null})
      });
      }
      this.EtnWorkingLinkData.sort((a, b) => (a.seller_code > b.seller_code) ? 1 : -1)
      this.tempEtnWorkingLinkData = JSON.parse(JSON.stringify(this.EtnWorkingLinkData));
      this.dataNotFound = false;  
    }, err => {
      console.log(err);
    }
    );
  }

  smartSearchShort(index,search){   
    if(search == ''){
      this.EtnWorkingLinkData[index].attribute = this.tempEtnWorkingLinkData[index].attribute
    }else{
      this.EtnWorkingLinkData[index].attribute = this.EtnWorkingLinkData[index].attribute.filter(data =>{
        return (
          (data.attribute_code.toString().toLowerCase().indexOf(search.toLowerCase()) > -1) ||
          (data.attribute_value.toString().toLowerCase().indexOf(search.toLowerCase()) > -1)
        );
      })
    }   
  }

}
