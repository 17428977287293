import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { paymentDetail } from 'src/app/fna/model/payment-data-model';
import { ResentPamApi } from 'src/app/ord/model/ord100.model';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private FNAPAY_URL: string = `${this.environmentService.getApiUrl()}/fna/fnapay` as string;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService
  ) { }

  // detail
  getpaymentInquiry(saleOrderNo,warehouseCode): Observable<paymentDetail> {
    const URL: string = `${this.FNAPAY_URL}/getPaymentBySaleOrderNo/${saleOrderNo}/${warehouseCode}` as string;
    return this.http.get<paymentDetail>(URL, { headers: this.authenticationService.tokenHeader() });
  }

  deletePaymentDetail(paymentID,saleOrderNo,warehouseCode) {
    const URL: string = `${this.FNAPAY_URL}/delete/${paymentID}/${saleOrderNo}/${warehouseCode}` as string;
    return this.http.delete(URL, { headers: this.authenticationService.tokenHeader() });
  }

  confirmShipment(paymentRequest) {
    const URL: string = `${this.FNAPAY_URL}/confirmShipment` as string;
    return this.http.put(URL, paymentRequest, { headers: this.authenticationService.tokenHeader() });
  }

  postPaymentHeader(paymentRequest) {
    const URL: string = `${this.FNAPAY_URL}/saveHeader` as string;
    return this.http.post(URL, paymentRequest, { headers: this.authenticationService.tokenHeader() });
  }

  postPaymentDetail(paymentRequest) {
    const URL: string = `${this.FNAPAY_URL}/saveOrUpdateDetail` as string;
    return this.http.post(URL, paymentRequest, { headers: this.authenticationService.tokenHeader() });
  }

  confirmPayment(paymentRequest) {
    const URL: string = `${this.FNAPAY_URL}/confirmPayment` as string;
    return this.http.put(URL, paymentRequest, { headers: this.authenticationService.tokenHeader() });
  }

  // inquiry
  getPaymentHeader(paymentRequest): Observable<paymentDetail> {
    const URL: string = `${this.FNAPAY_URL}/getPaymentInquiry` as string;
    return this.http.post<paymentDetail>(URL ,paymentRequest,{ headers: this.authenticationService.tokenHeader() });
  }

  getVprmTableByCountry(tableNo,country){
    const URL = `${this.FNAPAY_URL}/getvprmdtltolablebycountry/${tableNo}/${country}`;
    return this.http.get(URL, {headers: this.authenticationService.tokenHeader()});
  }

  getVprmTableByPrmno(tableNo){
    const URL = `${this.FNAPAY_URL}/getvprmdtltolablebyprmno/${tableNo}`;
    return this.http.get(URL, {headers: this.authenticationService.tokenHeader()});
  }

  resentPamApi(param: Array<ResentPamApi>){
    const URL = `${this.FNAPAY_URL}/resentPamsApi`;
    return this.http.post(URL,param ,{headers: this.authenticationService.tokenHeader()});
  }
}


