import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment';
import { FormGroup, FormControl } from '@angular/forms';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { DashboardLogisticStatusModel } from 'src/app/shared/model/dshboard-logistic.model';
import { DashboardLogisticService } from 'src/app/shared/services/dashboard-logistic.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { decimalTypePurchase, decimalTypeSelling, decimalTypeRoundMethod, decimalTypeRoundToValue } from 'src/app/shared/constant/decimal.constant';
import { DecimalData } from '../../model/dsh-model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-dshlgt100',
  templateUrl: './dshlgt100.component.html',
  styleUrls: ['./dshlgt100.component.scss']
})
export class Dshlgt100Component implements OnInit {

  @ViewChild('dateFilter', undefined) private dateFilter: any;

  public decimalData: DecimalData = {} as DecimalData;

  method: string;
  roundToDecimal: number;
  decimal: number = 0;

  dashboardLogisticStatusData: Array<DashboardLogisticStatusModel>;
  optionPeriod: SelectItem[];
  optionCountry: SelectItem[];
  firstTable: number = 0 as number;
  periodLabel: string;
  showLabelToday: boolean = false as boolean;
  labelDateFrom: any;
  labelDateTo: any;
  status: boolean;
  seller: any;
  dataNotFoundGridTab1: boolean = true as boolean;
  IsSearchGrid: boolean = false as boolean;
  getDashboardLogisticStatusData: any;
  criteriaSearchGrid = { shippingNumber: '', route: '', deliveryStatus: '', paymentStatus: '' };
  createDate: Date[];
  searchGridGroupForm = new FormGroup({
    shippingNumber: new FormControl(),
    createDate: new FormControl(),
    route: new FormControl(),
    deliveryStatus: new FormControl(),
    paymentStatus: new FormControl(),

  });
  selectCountry: string;
  defaultPeriod: any;
  sellerLength: number;
  disableCountry:boolean;
  paramToSeller:any = {country:null,warehouse:null};
  firstRow: number = 0 as number;

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  constructor(private dshlgtService: DashboardLogisticService,
    private exportExcelService: ExportExcelService,
    private parameterWmsSerive: ParameterWmsSerive,
    private parameterService: ParameterService,
    private decimalService: DecimalService,
    private translateService: TranslateService,
    private autService: AuthenticationService) {
    this.translateService.use(localStorage.getItem('languageData') as string);
    this.status = false;
    this.getOptionData();
  }


  ngOnInit() {
    this.autService.getParameterWmsCountry().subscribe((response) => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.optionCountry.length == 1) {
        this.disableCountry = true;
        this.selectCountry = this.optionCountry[0].value;
        this.paramToSeller = {country:this.selectCountry,warehouse:null}
      }
    }, err => {
      console.log(err);
    });
  }

  async getOptionData() {
    this.parameterService.getvparameterDetailToLable(2000).subscribe((response) => {
      this.optionPeriod = JSON.parse(JSON.stringify(response));
      this.optionPeriod = this.optionPeriod.sort((a, b) => a.value.pmdtbdnumv3 < b.value.pmdtbdnumv3 ? -1 : a.value.pmdtbdnumv3 > b.value.pmdtbdnumv3 ? 1 : 0);

      const defaultSelectItem: SelectItem[] = this.optionPeriod.filter((selectItem: SelectItem) => {
        return selectItem.value.parameterDetailPK.pmdtbdtbdentcd == '3M';
      });
      this.defaultPeriod = defaultSelectItem[0].value;

    }, err => {
      console.log(err);
    });
  }
  getSeller(seller) {

    this.seller = seller.seller;
    this.sellerLength = seller.length;

    if (this.selectCountry) {
      this.dashboardLogisticStatus('3M');
      this.periodLabel = '3 เดือนย้อนหลัง';
      this.getOptionData();
      this.labelDateFrom = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD');
      this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
    }
  }

  getFormatNumber(selectCountry) {
    this.parameterService.getvprmdtltolableByCountry(104, selectCountry).subscribe(response => {
      let decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypePurchase);
      this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeSelling);
      this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundMethod);
      this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundToValue);
      this.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
      this.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
      this.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
    }, err => {
      console.log(err);
    });
  }

  dashboardLogisticStatus(period: string) {
    this.dshlgtService.getDashboardLogisticStatusData(period, this.selectCountry, this.seller).subscribe((response) => {
      this.dashboardLogisticStatusData = response as unknown as DashboardLogisticStatusModel[];
      this.dashboardLogisticStatusData.forEach(element => {
        delete element.sellerCode;
        delete element.countryCode;
      });

      this.getDashboardLogisticStatusData = this.dashboardLogisticStatusData;
      if (this.dashboardLogisticStatusData.length === 0) {
        this.dataNotFoundGridTab1 = true;
      } else {
        this.dataNotFoundGridTab1 = false;
      }
      this.getDashboardLogisticStatusData = JSON.parse(JSON.stringify(this.dashboardLogisticStatusData)).map(response => {
        for (const value in response) {
          if (response[value] == null) {
            response[value] = '';
          }
        }
        return response;
      });
    });
  }

  selectedCountry(event) {
    this.selectCountry = event.value;
    this.paramToSeller = {country:this.selectCountry,warehouse:null}
    this.getFormatNumber(this.selectCountry);
    if (this.sellerLength > 1) {
      this.seller = null;
    } else {
      this.dashboardLogisticStatus('3M');
      this.periodLabel = '3 เดือนย้อนหลัง';
      this.getOptionData();
      this.labelDateFrom = moment().subtract(3, 'month').startOf('month').format('YYYY-MM-DD');
      this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
    }
  }

  selectedData(event) {

    this.IsSearchGrid = false;
    this.ClearData();
    this.periodLabel = event.value.pmdtbdtbdedesc;
    this.dashboardLogisticStatus(event.value.parameterDetailPK.pmdtbdtbdentcd);

    if (event.value.pmdtbdtxtv1 == 'D') {

      if (event.value.pmdtbdnumv1 == 0) {
        this.showLabelToday = true;
        this.labelDateFrom = moment(new Date()).format('YYYY-MM-DD');
      } else if (event.value.pmdtbdnumv1 == 1) {
        this.showLabelToday = true;
        this.labelDateFrom = moment().subtract(1, 'days').format('YYYY-MM-DD');
      } else if (event.value.pmdtbdnumv1 == 7) {
        this.showLabelToday = false;
        this.labelDateFrom = moment().subtract(event.value.pmdtbdnumv1 - 1, 'days').format('YYYY-MM-DD');
        this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
      }
    } else if (event.value.pmdtbdtxtv1 === 'Y') {
      this.showLabelToday = false;
      if (event.value.pmdtbdnumv1 === '0' || event.value.pmdtbdnumv1 === '1') {
        if (event.value.pmdtbdnumv1 === '0') {
          this.labelDateFrom = moment().startOf('year').format('YYYY-MM-DD');
          this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
        } else {
          this.labelDateFrom = moment().startOf('year').format('YYYY-MM-DD');
          this.labelDateTo = moment().endOf('year').format('YYYY-MM-DD');
        }
      } else {
        this.labelDateFrom = moment().subtract(event.value.pmdtbdnumv1, 'year').startOf('year').format('YYYY-MM-DD');
        this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
      }
    } else {
      this.showLabelToday = false;

      if (event.value.pmdtbdtxtv1 == 'M' && event.value.pmdtbdnumv1 == 1) {
        this.labelDateFrom = moment().subtract(event.value.pmdtbdnumv1, 'month').startOf('month').format('YYYY-MM-DD');
        this.labelDateTo = moment().subtract(event.value.pmdtbdnumv1, 'month').endOf('month').format('YYYY-MM-DD');
      } else {
        this.labelDateFrom = moment().subtract(event.value.pmdtbdnumv1, 'month').startOf('month').format('YYYY-MM-DD');
        this.labelDateTo = moment(new Date()).format('YYYY-MM-DD');
      }

    }
  }

  filterData() {

    if (this.createDate[0] && this.createDate[1] == null) {
      this.createDate[1] = this.createDate[0];
    }

    this.dashboardLogisticStatusData = this.getDashboardLogisticStatusData;
    this.criteriaSearchGrid.shippingNumber = this.criteriaSearchGrid.shippingNumber == null ? '' : this.criteriaSearchGrid.shippingNumber;
    this.criteriaSearchGrid.paymentStatus = this.criteriaSearchGrid.paymentStatus == null ? '' : this.criteriaSearchGrid.paymentStatus;
    this.criteriaSearchGrid.route = this.criteriaSearchGrid.route == null ? '' : this.criteriaSearchGrid.route;
    this.criteriaSearchGrid.deliveryStatus = this.criteriaSearchGrid.deliveryStatus == null ? '' : this.criteriaSearchGrid.deliveryStatus;
    this.dashboardLogisticStatusData = this.dashboardLogisticStatusData.filter(data => {
      return (
        (data.shippingNumber.toString().toLowerCase().indexOf(this.criteriaSearchGrid.shippingNumber.toLowerCase()) > -1) &&
        (data.route != null ? data.route.toString().toLowerCase().indexOf(this.criteriaSearchGrid.route.toLowerCase()) > -1 :
          ''.toString().toLowerCase().indexOf(this.criteriaSearchGrid.route.toLowerCase()) > -1) &&
        (data.paymentStatus.toString().toLowerCase().indexOf(this.criteriaSearchGrid.paymentStatus.toLowerCase()) > -1) &&
        (data.trackingNo.toString().toLowerCase().indexOf(this.criteriaSearchGrid.deliveryStatus.toLowerCase()) > -1));
    });

    if (this.createDate) {
      let strat_y = moment(this.createDate[0]).format('YYYY');
      let end_y = moment(this.createDate[1]).format('YYYY');
      let strat_m = moment(this.createDate[0]).format('MM');
      let end_m = moment(this.createDate[1]).format('MM');
      let strat_d = moment(this.createDate[0]).format('DD');
      let end_d = moment(this.createDate[1]).format('DD');
      this.dashboardLogisticStatusData = this.dashboardLogisticStatusData.filter(data => {
        let dateitem_y = moment(data.createDate).add(1).format('YYYY');
        let dateitem_m = moment(data.createDate).add(1).format('MM');
        let dateitem_d = moment(data.createDate).add(1).format('DD');

        if (dateitem_y >= strat_y && dateitem_y <= end_y) {
          if (dateitem_m >= strat_m && dateitem_m <= end_m) {
            if (dateitem_d >= strat_d && dateitem_d <= end_d) {
              return data;
            }
          }
        }
      });
    }

    if (this.dashboardLogisticStatusData.length == 0) {
      this.dataNotFoundGridTab1 = true;
    } else {
      this.dataNotFoundGridTab1 = false;
      this.firstRow = 0;
    }

    this.firstTable = 0;
  }

  ClearData() {
    this.dataNotFoundGridTab1 = false;
    this.searchGridGroupForm.reset();
    this.dashboardLogisticStatusData = this.getDashboardLogisticStatusData;
  }

  exportReport() {
    let subTitle = [];
    subTitle.push([`${this.translateService.instant('DSHLGT100.M00002')} ${this.periodLabel} ${this.labelDateFrom} ${this.translateService.instant('DSHLGT100.M00003')} ${this.labelDateTo}`]);
    const header: any = {
      //เลขส่งของ
      shippingNumber: this.translateService.instant('DSHLGT100.M00007'),
      //วันที่สร้าง
      createDate: this.translateService.instant('DSHLGT100.M00008'),
      //เส้นทางขนส่ง
      route: this.translateService.instant('DSHLGT100.M00009'),
      //เลขติดตามการส่ง
      trackingNo: this.translateService.instant('DSHLGT100.M00010'),
      //สถานะขนส่ง
      deliveryStatus: this.translateService.instant('DSHLGT100.M00011'),
      //อัปเดตล่าสุด
      updateDate: this.translateService.instant('DSHLGT100.M00012'),
      //Mini
      sizeMini: this.translateService.instant('DSHLGT100.M00013'),
      //S
      sizeS: this.translateService.instant('DSHLGT100.M00014'),
      //S+
      sizeSPlus: this.translateService.instant('DSHLGT100.M00015'),
      //M
      sizeM: this.translateService.instant('DSHLGT100.M00016'),
      //M+
      sizeMPlus: this.translateService.instant('DSHLGT100.M00017'),
      //L
      sizeL: this.translateService.instant('DSHLGT100.M00018'),
      //XL
      sizeXL: this.translateService.instant('DSHLGT100.M00019'),
      //OV1
      sizeOV1: this.translateService.instant('DSHLGT100.M00020'),
      //OV2
      sizeOV2: this.translateService.instant('DSHLGT100.M00021'),
      //OV3
      sizeOV3: this.translateService.instant('DSHLGT100.M00022'),
      //CBM
      sizeCBM: this.translateService.instant('DSHLGT100.M00023'),
      //เลขที่ใช้แจ้งหนี้
      invoiceNumber: this.translateService.instant('DSHLGT100.M00024'),
      //ราคาขนส่ง
      shippingPrice: this.translateService.instant('DSHLGT100.M00025'),
      //สถานะชำระ
      paymentStatus: this.translateService.instant('DSHLGT100.M00026'),
    };

    let export_dshlgt = [];
    this.dashboardLogisticStatusData.forEach((dashboardlgt: DashboardLogisticStatusModel) => {
      let export_data = {
        shippingNumber: dashboardlgt.shippingNumber,
        createDate: dashboardlgt.createDate,
        route: dashboardlgt.route,
        trackingNo: dashboardlgt.trackingNo,
        deliveryStatus: dashboardlgt.deliveryStatus,
        updateDate: dashboardlgt.updateDate,
        sizeMini: dashboardlgt.sizeMini,
        sizeS: dashboardlgt.sizeS,
        sizeSPlus: dashboardlgt.sizeSPlus,
        sizeM: dashboardlgt.sizeM,
        sizeMPlus: dashboardlgt.sizeMPlus,
        sizeL: dashboardlgt.sizeL,
        sizeXL: dashboardlgt.sizeXL,
        sizeOV1: dashboardlgt.sizeOV1,
        sizeOV2: dashboardlgt.sizeOV2,
        sizeOV3: dashboardlgt.sizeOV3,
        sizeCBM: dashboardlgt.sizeCBM,
        invoiceNumber: dashboardlgt.invoiceNumber,
        shippingPrice: this.decimalService.getRoundingDecimal(dashboardlgt.shippingPrice, this.method, this.roundToDecimal, this.decimal),
        paymentStatus: dashboardlgt.paymentStatus
      }
      export_dshlgt.push(export_data);
    });

    this.exportExcelService.exportAsExcelFile(export_dshlgt, this.translateService.instant('DSHLGT100.M00002'), header, null, subTitle);
    this.dashboardLogisticStatusData.shift();
  }

  onDatesRangeFilterSelected(selectedValue: Date) {
    if (this.createDate[1]) {
      this.dateFilter.hideOverlay();
    }
  }

}
