import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as uuid from 'uuid';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { NumberOfOrder, SalesSummarytoChart, SalesSummarytoGrid } from '../model/dashboard-sale.model';
import { ResponseDshPieChartSaleOrderPeriod, ResponseDshSaleOrder, ResponseDshSaleOrderPeriod, ResponseDshTableOrderSaleOrderPeriod, ResponseDshTableSaleOrderPeriod, ResponseSaleSummaryByPeriod } from 'src/app/dsh/dshsal/dshsal100/model/dshsal100.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardSaleService {
  getVprmTableByPrmno(arg0: number) {
      throw new Error('Method not implemented.');
  }

  DSHSAL_URL: string = `${this.evmService.getApiUrl()}/dsh/sal` as string;

  constructor(private http: HttpClient,
              private evmService: EnvironmentService,
              private autService: AuthenticationService) {}

  getSaleSummarytoGroupChart(period: string, country: string, seller: string): Observable<SalesSummarytoChart> {
    const URL = `${this.DSHSAL_URL}/getdatatochartsalsum/period=${period},country=${country},seller=${seller}`;
    return this.http.get<SalesSummarytoChart>(URL, {headers: this.autService.tokenHeader()});
  }

  getSaleSummarytoGroupGrid(period: string, country: string, seller: string): Observable<SalesSummarytoGrid> {
    const URL = `${this.DSHSAL_URL}/getdatatogridsalsum/period=${period},country=${country},seller=${seller}`;
    return this.http.get<SalesSummarytoGrid>(URL, {headers: this.autService.tokenHeader()});
  }

  getNumberOfOrder(country: string, seller: string): Observable<NumberOfOrder> {
    const URL = `${this.DSHSAL_URL}/findNumberOfOrder/country=${country},seller=${seller}`;
    return this.http.get<NumberOfOrder>(URL, {headers: this.autService.tokenHeader()});
  }

  getSaleSummaryPeriod(country: string, seller: string, uuid: uuid): Observable<ResponseSaleSummaryByPeriod> {
    const URL: string = `${this.DSHSAL_URL}/dshSalesummaryperiod/${country}/${seller}?uuid=${uuid}` as string;
    return this.http.get<ResponseSaleSummaryByPeriod>(URL, {headers: this.autService.tokenHeader()});
  }
  //TotalTable
  getTableFullSaleOrderDetailByPeriod(country: string,period: string, seller: string, uuid: uuid):Observable<ResponseDshSaleOrderPeriod> {
    const URL: string = `${this.DSHSAL_URL}/dshTableFullSalederdetailbyperiod?country=${country}&period=${period}&sellerCode=${seller}&uuid=${uuid}` as string;
    return this.http.get<ResponseDshSaleOrderPeriod>(URL, {headers: this.autService.tokenHeader()});
  }
  //OrderTable
  getTableOrderSaleOrderDetailByPeriod(country: string,period: string, seller: string, uuid: uuid):Observable<ResponseDshTableOrderSaleOrderPeriod> {
    const URL: string = `${this.DSHSAL_URL}/dshTableOrderSalederdetailbyperiod?country=${country}&period=${period}&sellerCode=${seller}&uuid=${uuid}` as string;
    return this.http.get<ResponseDshTableOrderSaleOrderPeriod>(URL, {headers: this.autService.tokenHeader()});
  }
  //SaleTable
  getTableSaleOrderDetailByPeriod(country: string,period: string, seller: string, uuid: uuid):Observable<ResponseDshTableSaleOrderPeriod> {
    const URL: string = `${this.DSHSAL_URL}/dshTableSaleSalederdetailbyperiod?country=${country}&period=${period}&sellerCode=${seller}&uuid=${uuid}` as string;
    return this.http.get<ResponseDshTableSaleOrderPeriod>(URL, {headers: this.autService.tokenHeader()});
  }
  //PieChart
  getPieChartSaleOrderDetailByPeriod(country: string,period: string, seller: string, uuid: uuid):Observable<ResponseDshPieChartSaleOrderPeriod> {
    const URL: string = `${this.DSHSAL_URL}/dshPieChartSalederdetailbyperiod?country=${country}&period=${period}&sellerCode=${seller}&uuid=${uuid}` as string;
    return this.http.get<ResponseDshPieChartSaleOrderPeriod>(URL, {headers: this.autService.tokenHeader()});
  }

  getDashbordChartByPeriod(country: string, sellerCode: string, period: string, uuid: uuid): Observable<ResponseDshSaleOrder> {
    const URL: string = `${this.DSHSAL_URL}/dshDashbordChartSaleOrderByPeriod/?country=${country}&sellerCode=${sellerCode}&period=${period}&uuid=${uuid}` as string;
    return this.http.get<ResponseDshSaleOrder>(URL, {headers: this.autService.tokenHeader()});
  }

}
