import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { contactInfo, detailInfo, itemInfo } from './model/dshlgt110Model';
import { ActivatedRoute } from '@angular/router';
import { Dshlgt110Model, DeliveryNoteData } from './model/dshlgt110.model';
import { Dshlgt110Service } from './service/dshlgt110.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dshlgt110',
  templateUrl: './dshlgt110.component.html',
  styleUrls: ['./dshlgt110.component.scss']
})
export class Dshlgt110Component implements OnInit {

  dshlgt110 = {} as Dshlgt110Model;
  deliveryNoteList: DeliveryNoteData = {} as DeliveryNoteData;
  deliveryNoteData: Array<DeliveryNoteData> = [] as Array<DeliveryNoteData>;
  dashboardLogisticData: any;

  private selectCountry: string;
  private selectdeliveryNoteNo: string;
  private seller: string;
  private sellerItemCode: string
  private koItemCode: string
  private itemName: string
  private qty: number
  private unit: string

  constructor(
    private route: ActivatedRoute,
    private dshlgt110Service: Dshlgt110Service,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService
  ) {
    this.translateService.use(localStorage.getItem('languageData') as string);
    this.route.paramMap.subscribe(params => {
      if (params) {
        this.selectCountry = params.get('country');
        this.selectdeliveryNoteNo = params.get('deliveryNoteNo');
        this.seller = params.get('seller');
      }
    });
  }

  ngOnInit() {

    this.dshlgt110 = this.dshlgt110Service.loadPage;
    this.dshlgt110Service.loadDeliveryNote(this.selectCountry, this.selectdeliveryNoteNo,this.seller);

    //   this.dshlgt110.deliveryNoteData.itemList.forEach(element => {

    //     this.deliveryNoteList.sellerName = this.dshlgt110.deliveryNoteData.header.sellerName;
    //     this.deliveryNoteList.taxId = this.dshlgt110.deliveryNoteData.header.sellertaxID;
    //     this.deliveryNoteList.phoneNo = this.dshlgt110.deliveryNoteData.header.sellerPhoneNo;
    //     this.deliveryNoteList.email = this.dshlgt110.deliveryNoteData.header.sellerEmail;
    //     this.deliveryNoteList.address = this.dshlgt110.deliveryNoteData.header.sellerAddress;
    //     this.deliveryNoteList.creator = this.dshlgt110.deliveryNoteData.header.creatorName;
    //     this.deliveryNoteList.createDate = this.dshlgt110.deliveryNoteData.header.createDate;
    //     this.deliveryNoteList.route = this.dshlgt110.deliveryNoteData.header.transportRoute;
    //     this.deliveryNoteList.trackingNo = this.dshlgt110.deliveryNoteData.header.trackingNo;
    //     this.deliveryNoteList.status = this.dshlgt110.deliveryNoteData.header.logisticStatus;
    //     this.deliveryNoteList.note = this.dshlgt110.deliveryNoteData.header.remark;
    //     this.deliveryNoteList.fulfillment = this.dshlgt110.deliveryNoteData.header.isFulfillment;
    //     this.deliveryNoteList.detail = '';
    //     this.deliveryNoteList.sellerItemCd = element.sellerItemCode;
    //     this.deliveryNoteList.koItemCd = element.koItemCode;
    //     this.deliveryNoteList.itemName = element.name;
    //     this.deliveryNoteList.qty = element.qty;
    //     this.deliveryNoteList.unit = element.unit;

    //     this.deliveryNoteData.push(this.deliveryNoteList);

    //   });

    this.deliveryNoteData = [{
      sellerName: 'มานพ แสงทอง',
      taxId: '0505536002843',
      phoneNo: '0656130501',
      email: 'email@email.com',
      address: '25/1 ม.ธ ต.อุทัย จ.อุทัย จ.พระนครศรีอยุธนา 13210',
      creator: 'แก้วใส มนีมรกต',
      createDate: '30/10/2020',
      route: 'J&T EXPRESS สาขาดอนเมือง',
      trackingNo: '12345678901TH',
      status: 'อยู่ระหว่างการจัดส่ง',
      note: '',
      fulfillment: [],
      detail: '',
      sellerItemCd: 'ITM001',
      koItemCd: 'KOITM001',
      itemName: 'Arovera Cream',
      qty: 20,
      unit: 'PCS'
    }]



  }

  exportReport() {
    let subTitle = [];
    subTitle.push([`${this.translateService.instant('DSHLGT110.M00025')} ${this.translateService.instant('DSHLGT110.M00026')}`]);
    const header: any = {
      sellerName: this.translateService.instant('DSHLGT110.M00020'),
      taxId: this.translateService.instant('DSHLGT110.M00024'),
      phoneNo: this.translateService.instant('DSHLGT110.M00004'),
      email: this.translateService.instant('DSHLGT110.M00006'),
      address: this.translateService.instant('DSHLGT110.M00005'),
      creator: this.translateService.instant('DSHLGT110.M00008'),
      createDate: this.translateService.instant('DSHLGT110.M00009'),
      route: this.translateService.instant('DSHLGT110.M00010'),
      trackingNo: this.translateService.instant('DSHLGT110.M00011'),
      status: this.translateService.instant('DSHLGT110.M00012'),
      note: this.translateService.instant('DSHLGT110.M00013'),
      fulfillment: this.translateService.instant('DSHLGT110.M00021'),
      detail: this.translateService.instant('DSHLGT110.M00014'),
      sellerItemCd: this.translateService.instant('DSHLGT110.M00015'),
      koItemCd: this.translateService.instant('DSHLGT110.M00016'),
      itemName: this.translateService.instant('DSHLGT110.M00017'),
      qty: this.translateService.instant('DSHLGT110.M00018'),
      unit: this.translateService.instant('DSHLGT110.M00019')
    };
    this.exportExcelService.exportAsExcelFile(this.deliveryNoteData, 'รายงานเอกสารใบแนะนำส่งของ', header, null, subTitle);
    this.deliveryNoteData.shift();


  }

}
