import { Injectable, OnInit} from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements OnInit {
  private domain: string ;
  private port: string;
  constructor() {
    if (environment.production) {
      this.domain = `https://klangone.com`;
      this.port = `8585`;
    } else if (environment.staging) {
      this.domain =`http://ksp.klangone.com`;
      // `http://kingpinggroup.com`; link uat old
      this.port = `8585`;
    } else if (environment.preuat) {
      this.domain = `http://172.16.0.44`;
      this.port = `8585`;
    } else if (environment.ss) {
      this.domain = `http://172.16.0.57`;
      this.port = `8585`;
    } else {
      this.domain = `http://localhost`;
      this.port = `8585`;
    }
  }

  // tslint:disable-next-line: contextual-lifecycle
  ngOnInit(): void {
  }

  public getApiUrl() {
    const semicolon = ':';
    return  `${this.domain}${semicolon}${this.port}`;
  }
}
