import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserData} from 'src/app/adm/model/admusr/admusr110.model';
import { TranslateService } from '@ngx-translate/core';
import { Admusr110Service } from 'src/app/adm/service/admusr/admusr110.service';
import { MessageService } from "primeng/primeng";
import { Router } from '@angular/router';

@Component({
  selector: 'app-admusr110',
  templateUrl: './admusr110.component.html',
  styleUrls: ['./admusr110.component.scss']
})
export class Admusr110Component implements OnInit {

  visibleSidebar1: boolean;
  visibleSidebar3: boolean;
  visibleSidebar4: boolean;
  visibleSidebar5: boolean;
  visibleSidebar6: boolean;
  visibleSidebar7: boolean;
  count: boolean;
  userform: FormGroup;
  submitted: boolean = true;
  userData: UserData = {} as UserData;

  constructor(

    private admusr110Service: Admusr110Service,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private router: Router,
    private messageService: MessageService,
  ) {
    this.getUser();
  }

  isSuccess: boolean = false as boolean;

  ngOnInit(): void {
    this.userform = this.formBuilder.group({
      username: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      telephone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{9,20}$")]),
      acnumber: new FormControl('', Validators.required),
      acname: new FormControl('', Validators.required),
      bankname: new FormControl('', Validators.required),
      detail : new FormControl('', Validators.required),
      contactname: new FormControl('', Validators.required)
    });
    this.userform.get("email").valueChanges.subscribe(data => {
      if(this.userform.controls['email'].hasError('required')){
        this.userData.messageEmail = 'ADMUSR110.M00055';
      } else if (this.userform.controls['email'].parent){
        this.userData.messageEmail = 'ADMUSR110.M00056';
      }

    });
    this.userform.get("telephone").valueChanges.subscribe(data => {
      if(this.userform.controls['telephone'].hasError('required')){
        this.userData.messageTel = 'ADMUSR110.M00055';
      } else if (this.userform.controls['telephone'].parent){
        this.userData.messageTel = 'ADMUSR110.M00057';
      }

    });
  }

  getUser() {
    this.admusr110Service.getuser().subscribe((res: UserData) => {
      this.userData = res;
    });
  }

  btnClick(event) {
    if (event == 1) {
      this.visibleSidebar1 = true;
      this.count = true;
    }
    else if (event == 3) {
      this.visibleSidebar3 = true;
      this.count = true;
    }
    else if (event == 4) {
      this.visibleSidebar4 = true;
      this.count = true;
    }
    else if (event == 5) {
      this.visibleSidebar5 = true;
      this.count = true;
    }
    else if (event == 6) {
      this.visibleSidebar6 = true;
      this.count = true;
    }
    else if (event == 7) {
      this.visibleSidebar7 = true;
      this.count = true;
    }
  }

  btnPressIn(event) {
    if (event == 1) {
      this.btnClick(1);
    } else if (event == 3) {
      this.btnClick(3);
    } else if (event == 4) {
      this.btnClick(4);
    } else if (event == 5) {
      this.btnClick(5);
    } else if (event == 6) {
      this.btnClick(6);
    } else if (event == 7) {
      this.btnClick(7);
    }
  }

  btnCancel(event) {
    if (event == 1) {
      this.userform.controls["username"].reset(this.userData.userName);
      this.visibleSidebar1 = false;
    } else if (event == 3) {
      this.userform.controls["email"].reset(this.userData.email);
      this.visibleSidebar3 = false;
    } else if (event == 4) {
      this.userform.controls["telephone"].reset(this.userData.tel);
      this.visibleSidebar4 = false;
    } else if (event == 6) {
      this.userform.controls["detail"].reset(this.userData.userDesc);
      this.visibleSidebar6 = false;
    } else if (event == 7) {
      this.userform.controls["contactname"].reset(this.userData.contactName);
      this.visibleSidebar7 = false;
    }
  }

  btnConfirm(event) {
    if (event == 1) {
      this.userData.userName = this.userform.controls["username"].value;
      this.visibleSidebar1 = false;
      this.onSubmit(true);
    } else if (event == 3) {
      this.userData.email = this.userform.controls["email"].value;
      this.visibleSidebar3 = false;
      this.onSubmit(true);
    } else if (event == 4) {
      this.userData.tel = this.userform.controls["telephone"].value;
      this.visibleSidebar4 = false;
      this.onSubmit(true);
    } else if (event == 6) {
      this.userData.userDesc = this.userform.controls["detail"].value;
      this.visibleSidebar6 = false;
      this.onSubmit(true);
    } else if (event == 7) {
      this.userData.contactName = this.userform.controls["contactname"].value;
      this.visibleSidebar7 = false;
      this.onSubmit(true);
    }

  }



  onSubmit(value: boolean) {
    this.submitted = false;
  }

  updateUser() {
    this.admusr110Service.updateuser(this.userData).subscribe(res => {

      if (res) {
        this.messageService.add({ key: 'alertsuccess', sticky: true, severity: 'success', /*summary: this.translateService.instant('ADMUSR110.M00051'),*/ detail: this.translateService.instant('ADMUSR110.M00052') });

        setTimeout(() => {
          this.router.navigate(['/homhom/homhom100']);
        }, 500);
      }else{
        this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: this.translateService.instant('ADMUSR110.M00053'), detail: this.translateService.instant('ADMUSR110.M00054') });
      }

    });
  }

  clickAfterSave(message){

    this.messageService.clear();

    if(message == this.translateService.instant('ADMUSR110.M00051')){
      window.location.href = '/homhom/homhom100';
    }
  }

}
