
import { HttpClient } from '@angular/common/http/';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { BarChartModel, ChartBar,
  ChartPie,
  Month,
  PeriodDropDownListModel,
  PeriodModel,
  PieChartModel,
  SaleSummaryPeriodReponse,
  StockGrid,
  StockListPeriodDataReponse,
  Year} from '../model/dashboard-stock.model';
import { StocCardByPeriodAllReponse, StocCardByPeriodReponse } from '../model/stock-card.model';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardStockService {

  private URL_STK: string = `${this.enverionmentService.getApiUrl()}/dsh/stk` as string;

  constructor(private http: HttpClient,
              private enverionmentService: EnvironmentService,
              private authenticationService: AuthenticationService) {
  }

  getStockValue(warehouse: string , seller: string) {
        const URL: string = `${this.URL_STK}/value/${warehouse}/${seller}` as string;
        return this.http.get(URL, {headers: this.authenticationService.tokenHeader()});
    }

    getStockData(warehouse: string, period: string, seller: string): Observable<StockGrid> {
        const URL: string = `${this.URL_STK}/data/${warehouse}/${period}/${seller}` as string;
        return this.http.get<StockGrid>(URL, {headers: this.authenticationService.tokenHeader()});
    }

    getStockBalance(warehouse: string, seller: string) {
        const URL: string = `${this.URL_STK}/balance/${warehouse}/${seller}` as string;
        return this.http.get(URL, {headers: this.authenticationService.tokenHeader()});
    }

    getPieChart(warehouse: string, period: string, seller: string): Observable<Array<ChartPie>> {
        const URL: string = `${this.URL_STK}/pie/chart/${warehouse}/${period}/${seller}` as string;
        return this.http.get<Array<ChartPie>>(URL, {headers: this.authenticationService.tokenHeader()});
    }

    getBarChart(warehouse: string, period: string, seller: string): Observable<Array<ChartBar>> {
        const URL: string = `${this.URL_STK}/bar/chart/${warehouse}/${period}/${seller}` as string;
        return this.http.get<Array<ChartBar>>(URL, {headers: this.authenticationService.tokenHeader()});

    }

    getYear(country: string, seller: string): Observable<Array<Year>> {
        const URL: string = `${this.URL_STK}/getYear/${country}/${seller}` as string;
        return this.http.get<Array<Year>>(URL, {headers: this.authenticationService.tokenHeader()});

    }

    getMonth(country: string, year: string, seller: string): Observable<Array<Month>> {
        const URL: string = `${this.URL_STK}/getMonth/${country}/${year}/${seller}` as string;
        return this.http.get<Array<Month>>(URL, {headers: this.authenticationService.tokenHeader()});
    }

    getPeriod(seller: string): Observable<PeriodDropDownListModel> {
        const URL = `${this.URL_STK}/getPeriod/${seller}`;
        return this.http.get<PeriodDropDownListModel>(URL, {headers: this.authenticationService.tokenHeader()});
    }

    getStockPeriodData(period: string, seller: string): Observable<StockListPeriodDataReponse> {
        const URL = `${this.URL_STK}/getStockPeriodData/${period}/${seller}`;
        return this.http.get<StockListPeriodDataReponse>(URL, {headers: this.authenticationService.tokenHeader()});
    }

    getStockCardByPeriod(country: string, sellerCode: string, this_month: string,this_year : string, uuid: string): Observable<StocCardByPeriodReponse> {
        const URL = `${this.URL_STK}/getstockcardbyperiod/?country=${country}&sellerCode=${sellerCode}&this_month=${this_month}&this_year=${this_year}&uuid=${uuid}` as string;
        return this.http.get<StocCardByPeriodReponse>(URL, {headers: this.authenticationService.tokenHeader()});
      }

    getSaleSummaryPeriod(country: string, sellerCode:string,uuid: string):Observable<SaleSummaryPeriodReponse>{
        const URL = `${this.URL_STK}/salesummaryperiod/${country}/${sellerCode}/${uuid}`;
        return this.http.get<SaleSummaryPeriodReponse>(URL, {headers: this.authenticationService.tokenHeader()});

    }

    getPeriodData():Observable<PeriodModel>{
        const URL = `${this.URL_STK}/getPeriodData`;
        return this.http.get<PeriodModel>(URL, {headers: this.authenticationService.tokenHeader()});
    }

    getStockCardByPeriodAll(country: string, sellerCode: string, this_month: string,this_year : string, uuid: string): Observable<StocCardByPeriodAllReponse> {
        const URL = `${this.URL_STK}/getstockcardbyperiodall/?country=${country}&sellerCode=${sellerCode}&this_month=${this_month}&this_year=${this_year}&uuid=${uuid}` as string;
        return this.http.get<StocCardByPeriodAllReponse>(URL, {headers: this.authenticationService.tokenHeader()});
      }

      getPieChartSale(country: string, sellerCode: string, this_month: string,this_year : string, uuid: string): Observable<PieChartModel> {
        const URL = `${this.URL_STK}/getstockcardbysaleqty/?country=${country}&sellerCode=${sellerCode}&this_month=${this_month}&this_year=${this_year}&uuid=${uuid}` as string;
        return this.http.get<PieChartModel>(URL, {headers: this.authenticationService.tokenHeader()});
      }

      getBarChartSale(country: string, sellerCode: string, this_month: string,this_year : string, uuid: string, itemType: String[]): Observable<BarChartModel> {
        const URL = `${this.URL_STK}/getBarChartStockCard/?country=${country}&sellerCode=${sellerCode}&this_month=${this_month}&this_year=${this_year}&uuid=${uuid}&itemType=${itemType}` as string;
        return this.http.get<BarChartModel>(URL, {headers: this.authenticationService.tokenHeader()});
      }
}
