import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Admprm300Model, ParameterDetailSave } from '../../model/admprm/admprm300.model';
import { Admprm300Service } from '../../service/admprm/admprm300.service';
import { TranslateService } from '@ngx-translate/core';
import { Renderer2 } from '@angular/core';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { formatDate } from '@angular/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as moment from 'moment';

@Component({
  selector: 'app-admprm300',
  templateUrl: './admprm300.component.html',
  styleUrls: ['./admprm300.component.scss']
})
export class Admprm300Component extends PermissionComponent{

  public page_model: Admprm300Model;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private admprm300Service: Admprm300Service,
              private translateService: TranslateService,
              renderer2:Renderer2,
              private autService: AuthenticationService,
              private fb: FormBuilder) {
    super('P01000',renderer2);
    this.page_model = this.admprm300Service.load;
    this.route.paramMap.subscribe(params => {
      if (params) {
        this.page_model.pmdtbdtbno = params.get('pmdtbdtbno');
        this.page_model.pmdtbdtbdentcd = params.get('pmdtbdtbdentcd');
        this.page_model.pmdtbddms = params.get('pmdtbddms');
      }
    });
    this.page_model.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel;
  }
  ngOnInit() {
    this.admprm300Service.getParameterHeaderByPmhtbhtbnoNoneGroup(this.page_model.pmdtbdtbno).subscribe(async response => {
      this.page_model.parameterHeader = await JSON.parse(JSON.stringify(response));
      if (this.page_model.parameterHeader[0].pmhtbhsys == true ){
        this.page_model.checkDisable = true;     
      }
      else if(this.page_model.parameterHeader[0].pmhtbhsys == false){
      }
      if(this.page_model.pmdtbddms == null && this.page_model.pmdtbdtbdentcd == null){
        if(this.page_model.parameterHeader[0].pmhtbhdms == null){
          this.page_model.OptionPmdtbddms = [{label: this.translateService.instant('ADMPRM300.M00031'), value: null ,engDesc:this.translateService.instant('ADMPRM300.M00031') ,localDesc:this.translateService.instant('ADMPRM300.M00031')}];
          this.page_model.checkFlagCountry = true;
          this.page_model.form_model.gridParameterDetailForm = this.fb.group({
            pmdtbdtbno: (this.page_model.parameterHeader[0].pmhtbhtbno),
            pmdtbdtbdentcd: [null, Validators.required],
            pmdtbdgroup: (this.page_model.parameterHeader[0].pmhtbhgroup),
            pmdtbddms: (null),
            pmdtbdtbdedesc: [null, Validators.required],
            pmdtbdlangcode: (null),
            pmdtbdacces: (this.page_model.parameterHeader[0].pmhtbhacc),
            pmdcmnt: (null),
            pmdtbdtxtv1: (null),
            pmdtbdtxtv2: (null),
            pmdtbdtxtv3: (null),
            pmdtbdtxtv4: (null),
            pmdtbdtxtv5: (null),
            pmdtbdtxtv6: (null),
            pmdtbdtxtv7: (null),
            pmdtbdtxtv8: (null),
            pmdtbdtxtv9: (null),
            pmdtbdtxtv10: (null),
            pmdtbdnumv1: (null),
            pmdtbdnumv2: (null),
            pmdtbdnumv3: (null),
            pmdtbdnumv4: (null),
            pmdtbdnumv5: (null),
            pmdtbddatv1: (null),
            pmdtbddatv2: (null),
            pmdtbdtbdldesc: (null),
            pmhtbdtbdedesc: (this.page_model.parameterHeader[0].pmhtbhedesc),
            pmhtbhldesc: (this.page_model.parameterHeader[0].pmhtbhldesc)
          });
        }else{
          this.page_model.form_model.gridParameterDetailForm = this.fb.group({
            pmdtbdtbno: (this.page_model.parameterHeader[0].pmhtbhtbno),
            pmdtbdtbdentcd: [null, Validators.required],
            pmdtbdgroup: (this.page_model.parameterHeader[0].pmhtbhgroup),
            pmdtbddms: [null, Validators.required],
            pmdtbdtbdedesc: [null, Validators.required],
            pmdtbdlangcode: (null),
            pmdtbdacces: (this.page_model.parameterHeader[0].pmhtbhacc),
            pmdcmnt: (null),
            pmdtbdtxtv1: (null),
            pmdtbdtxtv2: (null),
            pmdtbdtxtv3: (null),
            pmdtbdtxtv4: (null),
            pmdtbdtxtv5: (null),
            pmdtbdtxtv6: (null),
            pmdtbdtxtv7: (null),
            pmdtbdtxtv8: (null),
            pmdtbdtxtv9: (null),
            pmdtbdtxtv10: (null),
            pmdtbdnumv1: (null),
            pmdtbdnumv2: (null),
            pmdtbdnumv3: (null),
            pmdtbdnumv4: (null),
            pmdtbdnumv5: (null),
            pmdtbddatv1: (null),
            pmdtbddatv2: (null),
            pmdtbdtbdldesc: (null),
            pmhtbdtbdedesc: (this.page_model.parameterHeader[0].pmhtbhedesc),
            pmhtbhldesc: (this.page_model.parameterHeader[0].pmhtbhldesc)
          });
          setTimeout(() => {
            this.autService.getParameterWmsCountry().subscribe(response => {
              this.page_model.OptionPmdtbddms = JSON.parse(JSON.stringify(response));
            });
          }, 500);
          this.page_model.checkFlagCountry = false;
        } 
        this.page_model.checkFlagActionSave = true;
      }else{
        if(this.page_model.pmdtbddms == null){
          this.admprm300Service.getparameterDetailByNoDMS(this.page_model.pmdtbdtbno, this.page_model.pmdtbdtbdentcd).subscribe(async response => {
            this.page_model.parameterDetail = await JSON.parse(JSON.stringify(response));
              this.page_model.OptionPmdtbddms = [{label: this.translateService.instant('ADMPRM300.M00031'), value: null,engDesc:this.translateService.instant('ADMPRM300.M00031') ,localDesc:this.translateService.instant('ADMPRM300.M00031') }];
              this.page_model.checkFlagCountry = true;
              this.page_model.form_model.gridParameterDetailForm = this.fb.group({
                pmdtbdtbno: (this.page_model.parameterHeader[0].pmhtbhtbno),
                pmdtbdtbdentcd: [this.page_model.parameterDetail.parameterDetailPK.pmdtbdtbdentcd, Validators.required],
                pmdtbdgroup: (this.page_model.parameterHeader[0].pmhtbhgroup),
                pmdtbddms: (this.page_model.parameterDetail.pmdtbddms),
                pmdtbdtbdedesc: [this.page_model.parameterDetail.pmdtbdtbdedesc, Validators.required],
                pmdtbdlangcode: (this.page_model.parameterDetail.pmdtbdlangcode),
                pmdtbdacces: (this.page_model.parameterHeader[0].pmhtbhacc),
                pmdcmnt: (this.page_model.parameterDetail.pmdcmnt),
                pmdtbdtxtv1: (this.page_model.parameterDetail.pmdtbdtxtv1),
                pmdtbdtxtv2: (this.page_model.parameterDetail.pmdtbdtxtv2),
                pmdtbdtxtv3: (this.page_model.parameterDetail.pmdtbdtxtv3),
                pmdtbdtxtv4: (this.page_model.parameterDetail.pmdtbdtxtv4),
                pmdtbdtxtv5: (this.page_model.parameterDetail.pmdtbdtxtv5),
                pmdtbdtxtv6: (this.page_model.parameterDetail.pmdtbdtxtv6),
                pmdtbdtxtv7: (this.page_model.parameterDetail.pmdtbdtxtv7),
                pmdtbdtxtv8: (this.page_model.parameterDetail.pmdtbdtxtv8),
                pmdtbdtxtv9: (this.page_model.parameterDetail.pmdtbdtxtv9),
                pmdtbdtxtv10: (this.page_model.parameterDetail.pmdtbdtxtv10),
                pmdtbdnumv1: (this.page_model.parameterDetail.pmdtbdnumv1),
                pmdtbdnumv2: (this.page_model.parameterDetail.pmdtbdnumv2),
                pmdtbdnumv3: (this.page_model.parameterDetail.pmdtbdnumv3),
                pmdtbdnumv4: (this.page_model.parameterDetail.pmdtbdnumv4),
                pmdtbdnumv5: (this.page_model.parameterDetail.pmdtbdnumv5),
                pmdtbddatv1: (this.page_model.parameterDetail.pmdtbddatv1 == null? null :moment(this.page_model.parameterDetail.pmdtbddatv1).toDate()),
                pmdtbddatv2: (this.page_model.parameterDetail.pmdtbddatv2 == null? null :moment(this.page_model.parameterDetail.pmdtbddatv2).toDate()),
                pmdtbdtbdldesc: (this.page_model.parameterDetail.pmdtbdtbdldesc),
                pmhtbdtbdedesc: (this.page_model.parameterHeader[0].pmhtbhedesc),
                pmhtbhldesc: (this.page_model.parameterHeader[0].pmhtbhldesc)
              });
          });
        }else{
          this.admprm300Service.getparameterDetailByDMS(this.page_model.pmdtbdtbno, this.page_model.pmdtbdtbdentcd, this.page_model.pmdtbddms).subscribe(async response => {
            this.page_model.parameterDetail = await JSON.parse(JSON.stringify(response));
            this.autService.getParameterWmsCountry().subscribe(response => {
              this.page_model.OptionPmdtbddms = JSON.parse(JSON.stringify(response));
            });
              this.page_model.checkFlagCountry = true;
              this.page_model.form_model.gridParameterDetailForm = this.fb.group({
                pmdtbdtbno: (this.page_model.parameterHeader[0].pmhtbhtbno),
                pmdtbdtbdentcd: [this.page_model.parameterDetail.parameterDetailPK.pmdtbdtbdentcd, Validators.required],
                pmdtbdgroup: (this.page_model.parameterHeader[0].pmhtbhgroup),
                pmdtbddms: [this.page_model.parameterDetail.pmdtbddms, Validators.required],
                pmdtbdtbdedesc: [this.page_model.parameterDetail.pmdtbdtbdedesc, Validators.required],
                pmdtbdlangcode: (this.page_model.parameterDetail.pmdtbdlangcode),
                pmdtbdacces: (this.page_model.parameterHeader[0].pmhtbhacc),
                pmdcmnt: (this.page_model.parameterDetail.pmdcmnt),
                pmdtbdtxtv1: (this.page_model.parameterDetail.pmdtbdtxtv1),
                pmdtbdtxtv2: (this.page_model.parameterDetail.pmdtbdtxtv2),
                pmdtbdtxtv3: (this.page_model.parameterDetail.pmdtbdtxtv3),
                pmdtbdtxtv4: (this.page_model.parameterDetail.pmdtbdtxtv4),
                pmdtbdtxtv5: (this.page_model.parameterDetail.pmdtbdtxtv5),
                pmdtbdtxtv6: (this.page_model.parameterDetail.pmdtbdtxtv6),
                pmdtbdtxtv7: (this.page_model.parameterDetail.pmdtbdtxtv7),
                pmdtbdtxtv8: (this.page_model.parameterDetail.pmdtbdtxtv8),
                pmdtbdtxtv9: (this.page_model.parameterDetail.pmdtbdtxtv9),
                pmdtbdtxtv10: (this.page_model.parameterDetail.pmdtbdtxtv10),
                pmdtbdnumv1: (this.page_model.parameterDetail.pmdtbdnumv1),
                pmdtbdnumv2: (this.page_model.parameterDetail.pmdtbdnumv2),
                pmdtbdnumv3: (this.page_model.parameterDetail.pmdtbdnumv3),
                pmdtbdnumv4: (this.page_model.parameterDetail.pmdtbdnumv4),
                pmdtbdnumv5: (this.page_model.parameterDetail.pmdtbdnumv5),
                pmdtbddatv1: (this.page_model.parameterDetail.pmdtbddatv1 == null? null :moment(this.page_model.parameterDetail.pmdtbddatv1).toDate()),
                pmdtbddatv2: (this.page_model.parameterDetail.pmdtbddatv2 == null? null :moment(this.page_model.parameterDetail.pmdtbddatv2).toDate()),
                pmdtbdtbdldesc: (this.page_model.parameterDetail.pmdtbdtbdldesc),
                pmhtbdtbdedesc: (this.page_model.parameterHeader[0].pmhtbhedesc),
                pmhtbhldesc: (this.page_model.parameterHeader[0].pmhtbhldesc)
              });
          });
        }
        setTimeout (() => 
          this.page_model.form_model.gridParameterDetailForm.controls['pmdtbdtbdentcd'].disable()
        , 500);
        this.page_model.checkFlagActionSave = false;
      }
    });
  }

  checkInputSave(){
    if(this.page_model.checkFlagCountry){
      if(this.page_model.form_model.gridParameterDetailForm.invalid){
        this.page_model.checkFlagEditAndSave = true;
      }else{
        this.page_model.checkFlagEditAndSave = false;
      }
    }else{
      if(this.page_model.form_model.gridParameterDetailForm.invalid){
        this.page_model.checkFlagEditAndSave = true;
      }else{
        this.page_model.checkFlagEditAndSave = false;
      }
    }
  }

  saveAndUpdate(){ 
    let pmdtbdtbdentcd = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdentcd').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdentcd').value;
    let pmdtbddms = this.page_model.form_model.gridParameterDetailForm.get('pmdtbddms').value;
    let pmdtbdtbno = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbno').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbno').value;
    let pmdtbdgroup = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdgroup').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdgroup').value;
    let pmdtbdtbdedesc = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdedesc').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdedesc').value;
    let pmdtbdlangcode = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdlangcode').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdlangcode').value;
    let pmdtbdacces = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdacces').value;
    let pmdcmnt = this.page_model.form_model.gridParameterDetailForm.get('pmdcmnt').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdcmnt').value;
    let pmdtbdtxtv1 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv1').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv1').value;
    let pmdtbdtxtv2 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv2').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv2').value;
    let pmdtbdtxtv3 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv3').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv3').value;
    let pmdtbdtxtv4 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv4').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv4').value;
    let pmdtbdtxtv5 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv5').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv5').value;
    let pmdtbdtxtv6 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv6').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv6').value;
    let pmdtbdtxtv7 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv7').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv7').value;
    let pmdtbdtxtv8 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv8').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv8').value;
    let pmdtbdtxtv9 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv9').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv9').value;
    let pmdtbdtxtv10 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv10').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv10').value;
    let pmdtbdnumv1 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv1').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv1').value;
    let pmdtbdnumv2 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv2').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv2').value;
    let pmdtbdnumv3 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv3').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv3').value;
    let pmdtbdnumv4 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv4').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv4').value;
    let pmdtbdnumv5 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv5').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv5').value;
    let pmdtbddatv1 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbddatv1').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbddatv1').value;
    let pmdtbddatv2 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbddatv2').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbddatv2').value;
    let pmdtbdtbdldesc = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdldesc').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdldesc').value;
    
    this.admprm300Service.getvparameterDetailByPmhtbhtbno(this.page_model.pmdtbdtbno).subscribe(async response => {
      this.page_model.parameterDetailList = await JSON.parse(JSON.stringify(response));
      if(this.page_model.checkFlagActionSave){
        if(pmdtbdtbdentcd != null && pmdtbdtbdedesc != null){
          this.page_model.parameterDetailList = await this.page_model.parameterDetailList.filter(data => {
            return (
              (pmdtbdtbdentcd == data.value.pmdtbdtbdentcd) &&
              (pmdtbddms == data.value.pmdtbddms)
            );
          });
          if(this.page_model.parameterDetailList.length > 0){
            this.warningAlert('alert', 'warn', this.translateService.instant('ADMPRM300.M00037'), this.translateService.instant('ADMPRM300.M00032'));
          }else{
            this.page_model.parameterDetailSaveAndUpdate = {
              pmdtbdtbno: pmdtbdtbno,
              pmdtbdtbdentcd: pmdtbdtbdentcd,
              pmdtbdgroup: pmdtbdgroup,
              pmdtbddms: pmdtbddms,
              pmdtbdtbdedesc: pmdtbdtbdedesc,
              pmdtbdlangcode: pmdtbdlangcode,
              pmdtbdacces: pmdtbdacces?1:0,
              pmdcmnt: pmdcmnt,
              pmdtbdtxtv1: pmdtbdtxtv1,
              pmdtbdtxtv2: pmdtbdtxtv2,
              pmdtbdtxtv3: pmdtbdtxtv3,
              pmdtbdtxtv4: pmdtbdtxtv4,
              pmdtbdtxtv5: pmdtbdtxtv5,
              pmdtbdtxtv6: pmdtbdtxtv6,
              pmdtbdtxtv7: pmdtbdtxtv7,
              pmdtbdtxtv8: pmdtbdtxtv8,
              pmdtbdtxtv9: pmdtbdtxtv9,
              pmdtbdtxtv10: pmdtbdtxtv10,
              pmdtbdnumv1: pmdtbdnumv1,
              pmdtbdnumv2: pmdtbdnumv2,
              pmdtbdnumv3: pmdtbdnumv3,
              pmdtbdnumv4: pmdtbdnumv4,
              pmdtbdnumv5: pmdtbdnumv5,
              pmdtbddatv1: pmdtbddatv1 == null ? null :formatDate(pmdtbddatv1, 'yyyy-MM-dd', 'en_US'),
              pmdtbddatv2: pmdtbddatv2 == null ? null :formatDate(pmdtbddatv2, 'yyyy-MM-dd', 'en_US'),
              pmdcreusr: this.page_model.user.userName,
              pmdcredat: null,
              pmdupdurs: this.page_model.user.userName,
              pmdupddat: null,
              pmdtbdtbdldesc: pmdtbdtbdldesc
            }
            await this.admprm300Service.saveAndUpdatevparameterDetail(this.page_model.parameterDetailSaveAndUpdate).subscribe(response =>{
              let dataResponse: ParameterDetailSave;
              dataResponse = JSON.parse(JSON.stringify(response));
              if(dataResponse){
                  if(dataResponse.flagCheckDup == false){
                      this.successAlert(this.translateService.instant('ADMPRM300.M00033'));
                      setTimeout(() => {
                        this.redirectTo(this.page_model.parameterDetailSaveAndUpdate.pmdtbdtbno, dataResponse.pmdtbdtbdentcd, this.page_model.parameterDetailSaveAndUpdate.pmdtbddms);
                      }, 500);
                  }else{
                    this.warningAlert('alert', 'warn', this.translateService.instant('ADMPRM300.M00037'), this.translateService.instant('ADMPRM300.M00032'));
                  }
              }else{
                this.warningAlert('alert', 'error', this.translateService.instant('ADMPRM300.M00037'), this.translateService.instant('ADMPRM300.M00034'));
              }
            });
          }
        }else{
          this.warningAlert('alert', 'warn', this.translateService.instant('ADMPRM300.M00037'), this.translateService.instant('ADMPRM300.M00035'));
        }
      }else{
        if(pmdtbdtbdedesc != this.page_model.parameterDetail.pmdtbdtbdedesc || pmdtbdtbdldesc != this.page_model.parameterDetail.pmdtbdtbdldesc ||
          pmdcmnt != this.page_model.parameterDetail.pmdcmnt || pmdtbdtxtv1 != this.page_model.parameterDetail.pmdtbdtxtv1 ||
          pmdtbdtxtv2 != this.page_model.parameterDetail.pmdtbdtxtv2 || pmdtbdtxtv3 != this.page_model.parameterDetail.pmdtbdtxtv3 ||
          pmdtbdtxtv4 != this.page_model.parameterDetail.pmdtbdtxtv4 || pmdtbdtxtv5 != this.page_model.parameterDetail.pmdtbdtxtv5 ||
          pmdtbdtxtv6 != this.page_model.parameterDetail.pmdtbdtxtv6 || pmdtbdtxtv7 != this.page_model.parameterDetail.pmdtbdtxtv7 ||
          pmdtbdtxtv8 != this.page_model.parameterDetail.pmdtbdtxtv8 || pmdtbdtxtv9 != this.page_model.parameterDetail.pmdtbdtxtv9 ||
          pmdtbdtxtv10 != this.page_model.parameterDetail.pmdtbdtxtv10 || pmdtbdnumv1 != this.page_model.parameterDetail.pmdtbdnumv1 ||
          pmdtbdnumv2 != this.page_model.parameterDetail.pmdtbdnumv2 || pmdtbdnumv3 != this.page_model.parameterDetail.pmdtbdnumv3 ||
          pmdtbdnumv3 != this.page_model.parameterDetail.pmdtbdnumv4 || pmdtbdnumv3 != this.page_model.parameterDetail.pmdtbdnumv5 ||
          pmdtbddatv1 != this.page_model.parameterDetail.pmdtbddatv1 || pmdtbddatv2 != this.page_model.parameterDetail.pmdtbddatv2){
            this.page_model.parameterDetailSaveAndUpdate = {
              pmdtbdtbno: pmdtbdtbno,
              pmdtbdtbdentcd: pmdtbdtbdentcd,
              pmdtbdgroup: pmdtbdgroup,
              pmdtbddms: pmdtbddms,
              pmdtbdtbdedesc: pmdtbdtbdedesc,
              pmdtbdlangcode: pmdtbdlangcode,
              pmdtbdacces: pmdtbdacces?1:0,
              pmdcmnt: pmdcmnt,
              pmdtbdtxtv1: pmdtbdtxtv1,
              pmdtbdtxtv2: pmdtbdtxtv2,
              pmdtbdtxtv3: pmdtbdtxtv3,
              pmdtbdtxtv4: pmdtbdtxtv4,
              pmdtbdtxtv5: pmdtbdtxtv5,
              pmdtbdtxtv6: pmdtbdtxtv6,
              pmdtbdtxtv7: pmdtbdtxtv7,
              pmdtbdtxtv8: pmdtbdtxtv8,
              pmdtbdtxtv9: pmdtbdtxtv9,
              pmdtbdtxtv10: pmdtbdtxtv10,
              pmdtbdnumv1: pmdtbdnumv1,
              pmdtbdnumv2: pmdtbdnumv2,
              pmdtbdnumv3: pmdtbdnumv3,
              pmdtbdnumv4: pmdtbdnumv4,
              pmdtbdnumv5: pmdtbdnumv5,
              pmdtbddatv1: pmdtbddatv1 == null ? null :formatDate(pmdtbddatv1, 'yyyy-MM-dd', 'en_US'),
              pmdtbddatv2: pmdtbddatv2 == null ? null :formatDate(pmdtbddatv2, 'yyyy-MM-dd', 'en_US'),
              pmdcreusr: this.page_model.user.userName,
              pmdcredat: null,
              pmdupdurs: this.page_model.user.userName,
              pmdupddat: null,
              pmdtbdtbdldesc: pmdtbdtbdldesc
            }
            await this.admprm300Service.saveAndUpdatevparameterDetail(this.page_model.parameterDetailSaveAndUpdate).subscribe(async response =>{
              let dataResponse = JSON.parse(JSON.stringify(response));
              if(dataResponse != null){
                this.successAlert(this.translateService.instant('ADMPRM300.M00033'));
                setTimeout(() => {
                  window.location.reload();
                  window.scroll(0, 0);
                }, 500);
              }else{
                this.warningAlert('alert', 'error', this.translateService.instant('ADMPRM300.M00037'), this.translateService.instant('ADMPRM300.M00034'));
              }
            });
        }else{
          this.warningAlert('alert', 'warn', this.translateService.instant('ADMPRM300.M00037'), this.translateService.instant('ADMPRM300.M00036'));
        }
      }
    });
  }

  warningAlert(key: string, severityInfo: string, summary: string, detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: key, sticky: true, severity: severityInfo, summary: summary, detail: detaiInfo});
  }

  onReject(){
    this.messageService.clear();
  }

  onConfirm(){
    this.messageService.clear();
  }

  async successAlert(detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: 'alertsuccess', sticky: true, severity: 'success', detail: detaiInfo});
  }

  onConfirmSave(){
    this.messageService.clear();
    window.location.reload();
    window.scroll(0, 0);
  }

  goBack() {
    let pmdtbdtbdentcd = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdentcd').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdentcd').value;
    let pmdtbdtbdedesc = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdedesc').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdedesc').value;
    let pmdcmnt = this.page_model.form_model.gridParameterDetailForm.get('pmdcmnt').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdcmnt').value;
    let pmdtbdtxtv1 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv1').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv1').value;
    let pmdtbdtxtv2 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv2').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv2').value;
    let pmdtbdtxtv3 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv3').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv3').value;
    let pmdtbdtxtv4 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv4').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv4').value;
    let pmdtbdtxtv5 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv5').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv5').value;
    let pmdtbdtxtv6 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv6').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv6').value;
    let pmdtbdtxtv7 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv7').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv7').value;
    let pmdtbdtxtv8 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv8').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv8').value;
    let pmdtbdtxtv9 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv9').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv9').value;
    let pmdtbdtxtv10 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv10').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtxtv10').value;
    let pmdtbdnumv1 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv1').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv1').value;
    let pmdtbdnumv2 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv2').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv2').value;
    let pmdtbdnumv3 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv3').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv3').value;
    let pmdtbdnumv4 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv4').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv4').value;
    let pmdtbdnumv5 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv5').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdnumv5').value;
    let pmdtbddatv1 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbddatv1').value == '' || this.page_model.form_model.gridParameterDetailForm.get('pmdtbddatv1').value == null ? null: formatDate(this.page_model.form_model.gridParameterDetailForm.get('pmdtbddatv1').value, 'yyyy-MM-dd', 'en_US');
    let pmdtbddatv2 = this.page_model.form_model.gridParameterDetailForm.get('pmdtbddatv2').value == '' || this.page_model.form_model.gridParameterDetailForm.get('pmdtbddatv2').value == null ? null: formatDate(this.page_model.form_model.gridParameterDetailForm.get('pmdtbddatv2').value, 'yyyy-MM-dd', 'en_US');
    let pmdtbdtbdldesc = this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdldesc').value == '' ? null:this.page_model.form_model.gridParameterDetailForm.get('pmdtbdtbdldesc').value;

    if(pmdtbdtbdentcd != (this.page_model.parameterDetail.parameterDetailPK != null ? this.page_model.parameterDetail.parameterDetailPK.pmdtbdtbdentcd : null) || 
      pmdtbdtbdedesc != this.page_model.parameterDetail.pmdtbdtbdedesc ||
      pmdcmnt != this.page_model.parameterDetail.pmdcmnt || pmdtbdtxtv1 != this.page_model.parameterDetail.pmdtbdtxtv1 ||
      pmdtbdtxtv2 != this.page_model.parameterDetail.pmdtbdtxtv2 || pmdtbdtxtv3 != this.page_model.parameterDetail.pmdtbdtxtv3 ||
      pmdtbdtxtv4 != this.page_model.parameterDetail.pmdtbdtxtv4 || pmdtbdtxtv5 != this.page_model.parameterDetail.pmdtbdtxtv5 ||
      pmdtbdtxtv6 != this.page_model.parameterDetail.pmdtbdtxtv6 || pmdtbdtxtv7 != this.page_model.parameterDetail.pmdtbdtxtv7 ||
      pmdtbdtxtv8 != this.page_model.parameterDetail.pmdtbdtxtv8 || pmdtbdtxtv9 != this.page_model.parameterDetail.pmdtbdtxtv9 ||
      pmdtbdtxtv10 != this.page_model.parameterDetail.pmdtbdtxtv10 || pmdtbdnumv1 != this.page_model.parameterDetail.pmdtbdnumv1 ||
      pmdtbdnumv2 != this.page_model.parameterDetail.pmdtbdnumv2 || pmdtbdnumv3 != this.page_model.parameterDetail.pmdtbdnumv3 ||
      pmdtbdnumv4 != this.page_model.parameterDetail.pmdtbdnumv4 || pmdtbdnumv5 != this.page_model.parameterDetail.pmdtbdnumv5 ||
      pmdtbddatv1 != this.page_model.parameterDetail.pmdtbddatv1 || pmdtbddatv2 != this.page_model.parameterDetail.pmdtbddatv2 ||
      pmdtbdtbdldesc != this.page_model.parameterDetail.pmdtbdtbdldesc){
        this.warningAlert('goback', 'warn', this.translateService.instant('ADMPRM300.M00053'), this.translateService.instant('ADMPRM300.M00038')); 
    }else{
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['admprm/admprm200', this.page_model.pmdtbdtbno]).then(() => {
          window.location.reload();
          window.scroll(0, 0);
        })
      );
    }
  }

  onConfirmGoBack(){
    this.messageService.clear();
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['admprm/admprm200', this.page_model.pmdtbdtbno]).then(() => {
        window.location.reload();
        window.scroll(0, 0);
      })
    );
  }

  redirectTo(pmdtbdtbno: number, pmdtbdtbdentcd: string, country:string) {
    let pmdtbddms = country == null ? 'null' : country;
    if(country == null){
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['admprm/admprm300', pmdtbdtbno, pmdtbdtbdentcd]).then(() => {
        window.location.reload();
        window.scroll(0, 0);
      }));
    }else{
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['admprm/admprm300', pmdtbdtbno, pmdtbdtbdentcd, pmdtbddms]).then(() => {
        window.location.reload();
        window.scroll(0, 0);
      }));
    }
  }
}
