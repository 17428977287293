import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { AdministrationUserService } from 'src/app/shared/services/administration-user.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterCountryList } from 'src/app/shared/model/parameter.model';
import { AdministrationLanguageService } from 'src/app/shared/services/administration-language.service';
@Component({
  selector: 'app-autaut200',
  templateUrl: './autaut200.component.html',
  styleUrls: ['./autaut200.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // providers: [ConfirmationService]
})
export class Autaut200Component implements OnInit {

  public status: any;
  public getPage: any;
  public sellerCode: any;
  public userCode: any;
  public email: any;
  public items: MenuItem[];
  public activeIndex: number;
  public errors: boolean;
  formDisplay: boolean;
  userDisplay: boolean;
  sellerDisplay: boolean;
  emailDisplay: boolean;
  sellerCodeDisplay: boolean;
  emailNonDisplay: boolean;
  showButton: boolean = false as boolean;
  public optionCountry: Array<ParameterCountryList> = [] as Array<ParameterCountryList>;
  public selectedCountry: ParameterCountryList = {} as ParameterCountryList;
  public language: string;
  public responseForgetP: any;
  
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private administrationUserService: AdministrationUserService,
    private admLangService: AdministrationLanguageService,
    private messageService: MessageService,
    ) {
      this.language = localStorage.getItem('languageData') as string;
      this.activeIndex = 0;
      this.errors = false;
    }

  ngOnInit() {
    this.admLangService.getvparameterCountry().subscribe(response => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.language) {
        let rusult = this.optionCountry.filter(data => data.value.entryCode.toUpperCase() == this.language.toUpperCase());
        this.selectedCountry = rusult[0];
      } else {
        let rusult = this.optionCountry.filter(data => data.value.entryDefault == 'Y');
        this.selectedCountry = rusult[0];
      }
      this.useLanguage(this.selectedCountry.value.entryCode);
    }, err => {
      console.log(err);
    })
    this.items = [
    {
      label: this.translateService.instant('AUTAUT200.M00016'), command: (event: any) => {
        this.activeIndex = 0;
      }
    },
    {
      label: this.translateService.instant('AUTAUT200.M00017'), command: (event: any) => {
        this.activeIndex = 1;
      }
    },
    {
      label: this.translateService.instant('AUTAUT200.M00018'), command: (event: any) => {
        this.activeIndex = 2;
      }
    }];

  }


showFormDialog() {
    this.formDisplay = true;
}

showUserDialog() {
  this.userDisplay = true;
}

showSellerDialog() {
  this.sellerDisplay = true;
}

showEmailDialog() {
  this.emailDisplay = true;
}



  forgetPass() {
    if (this.status === '1') {
      this.activeIndex = 1;
      this.getPage = '1';
      this.showButton = true;
    } else if (this.status === '2') {
      this.activeIndex = 1;
      this.getPage = '2';
      this.showButton = true;
    } else if (this.status === '3') {
      this.activeIndex = 1;
      this.getPage = '3';
      this.showButton = true;
    } else {
      this.getPage = undefined;
    }
  }

  getuserCode(userCode: string) {
    this.errors = false;
    this.userCode = userCode;
  }

  getsellerCode(sellerCode: string) {
    this.errors = false;
    this.sellerCode = sellerCode;
  }

  getEmail(email: string) {
    this.errors = false;
    this.email = email;
  }

  backToHome() {
    this.getPage = undefined;
    this.router.navigate(['/aut/autaut100']);
  }

  back() {
    this.getPage = undefined;
    this.formDisplay = false;
    this.router.navigate(['/aut/autaut200']);
    this.showButton = false;
  }

  forgetUserCode() {
    if (this.userCode) {
      this.administrationUserService.forgetUserCd(this.userCode).subscribe(response => {
        // console.log(events);
        this.responseForgetP = JSON.parse(JSON.stringify(response));
        if(this.responseForgetP.status == 1){
          this.getPage = '4';
          this.activeIndex = 2;
        }else{
          if(this.responseForgetP.errorCode == 'M0001'){
            this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'AUTAUT200.M00005', detail: 'AUTAUT200.M00011' });
          }else if(this.responseForgetP.errorCode == 'M0002'){
            this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'AUTAUT200.M00005', detail: 'AUTAUT200.M00019' });
          }else if(this.responseForgetP.errorCode == 'M0003') {
            this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'AUTAUT200.M00005', detail: 'AUTAUT200.M00020' });
          }
          
        } 
      }, err => {
        // console.log('error');
      });
    } else {
      this.errors = true;
    }
  }

  selectedCountryFunction(event) {
    this.useLanguage(event.value.value.entryCode);
    this.items = [
      {
        label: this.translateService.instant('AUTAUT200.M00016'), command: (event: any) => {
          this.activeIndex = 0;
        }
      },
      {
        label: this.translateService.instant('AUTAUT200.M00017'), command: (event: any) => {
          this.activeIndex = 1;
        }
      },
      {
        label: this.translateService.instant('AUTAUT200.M00018'), command: (event: any) => {
          this.activeIndex = 2;
        }
      }];
  }

  useLanguage(language: string) {
    this.translateService.use(language);
  }

  onReject(){
    this.messageService.clear();
  }
  
}
