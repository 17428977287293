import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SelectButtonModule} from 'primeng/selectbutton';
import {InputSwitchModule} from 'primeng/inputswitch';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/components/button/button';
import { LabelComponent } from './components/label/label.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { FieldsetModule } from 'primeng/fieldset';
import { LoaderComponent } from './components/loader/loader.component';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { ChartModule } from 'primeng/chart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ToolbarModule } from 'primeng/toolbar';
import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { BlockUIModule } from 'primeng/blockui';
import { StepsModule } from 'primeng/steps';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { InputTextModule } from 'primeng/inputtext';
import {MultiSelectModule} from 'primeng/multiselect';
import {SplitButtonModule} from 'primeng/splitbutton';
import {LightboxModule} from 'primeng/lightbox';
import {FileUploadModule} from 'primeng/fileupload';
import { PanelMenuModule } from 'primeng/panelmenu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { NgxPermissionsModule } from 'ngx-permissions';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { PasswordModule } from 'primeng/password';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { ConfirmMessageComponent } from './components/confirm-message/confirm-message.component';
import { ButtonComponent } from './components/button/button.component';

import { EnvironmentService } from './services/environment.service';

import { ThaiDatePipe } from './pipes/thaidate.pipe';
import { NumberDecimalPipe } from './pipes/decimal.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { TranslateDDLPipe } from './pipes/translateDDL.pipe';
import { TranslateLablePipe } from './pipes/translateLable.pipe';


import { GroupCardComponent } from './components/group-card/group-card.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { PieChartComponent } from './components/piechart/piechart.component';
import { MenubarComponent } from './components/menubar/menubar.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuV2Component } from './components/menu-v2/menu-v2.component';
import { FooterComponent } from './components/footer/footer.component';
import { SearchItemComponent } from './components/search-item/search-item.component';
import { SearchCustomerComponent } from './components/search-member-klangone/search-customer.component';
import { SearchReceiverComponent } from './components/search-receiver/search-receiver.component';
import { SearchDocumentComponent } from './components/search-document/search-document/search-document.component';
import { PermissionDimensionComponent } from './components/permission-dimension/permission-dimension.component';
import { ExportPdfComponent } from './components/export-pdf/export-pdf.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AdministrationLanguageService } from './services/administration-language.service';
import { DdlSellerComponent } from './components/ddl-seller/ddl-seller.component';

import { TableComponent } from './components/table/table.component';

import { PermissionComponent } from './components/permission/permission.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SsDropdownComponent } from './components/ss-dropdown/ss-dropdown.component';
import { TranslateCalendarPipe } from './pipes/translate-calendar.pipe';


@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        ToastModule,
        SelectButtonModule,
        ButtonModule,
        InputSwitchModule,
        CommonModule,
        RadioButtonModule,
        ConfirmDialogModule,
        CardModule,
        PanelModule,
        FieldsetModule,
        TableModule,
        DialogModule,
        FormsModule,
        BreadcrumbModule,
        AccordionModule,
        DropdownModule,
        CalendarModule,
        CheckboxModule,
        TabViewModule,
        ChartModule,
        OverlayPanelModule,
        ProgressBarModule,
        SlideMenuModule,
        ToolbarModule,
        SidebarModule,
        MenuModule,
        ScrollPanelModule,
        BlockUIModule,
        StepsModule,
        MessagesModule,
        MessageModule,
        InputTextModule,
        MultiSelectModule,
        SplitButtonModule,
        LightboxModule,
        FileUploadModule,
        PanelMenuModule,
        KeyFilterModule,

        NgxPermissionsModule.forRoot(),
        InputTextareaModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: httpLoaderFactory,
              deps: [HttpClient, EnvironmentService]
            }
        }),
        NgxExtendedPdfViewerModule,
        ToggleButtonModule,
        PasswordModule
    ],
    declarations: [
        ConfirmMessageComponent,
        AlertMessageComponent,
        LabelComponent,
        ButtonComponent,
        LoaderComponent,
        GroupCardComponent,
        BarChartComponent,
        PieChartComponent,
        MenubarComponent,
        LoaderComponent,
        MenuComponent,
        MenuV2Component,
        FooterComponent,
        SearchItemComponent,
        SearchCustomerComponent,
        SearchReceiverComponent,
        SearchDocumentComponent,
        PermissionDimensionComponent,
        ThaiDatePipe,
        NumberDecimalPipe,
        ExportPdfComponent,
        TranslatePipe,
        TranslateDDLPipe,
        TranslateLablePipe,
        DdlSellerComponent,
        TableComponent,
        PermissionComponent,
        LoadingComponent,
        NotFoundComponent,
        SsDropdownComponent,
        TranslateCalendarPipe
    ],
    providers: [
        EnvironmentService,
        AdministrationLanguageService,
        DecimalPipe,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        ToastModule,
        ButtonModule,
        SelectButtonModule,
        CommonModule,
        InputSwitchModule,
        RadioButtonModule,
        ConfirmDialogModule,
        CardModule,
        PanelModule,
        FieldsetModule,
        TableModule,
        DialogModule,
        FormsModule,
        BreadcrumbModule,
        AccordionModule,
        DropdownModule,
        CalendarModule,
        CheckboxModule,
        TabViewModule,
        ChartModule,
        PieChartComponent,
        OverlayPanelModule,
        ProgressBarModule,
        SlideMenuModule,
        ToolbarModule,
        SidebarModule,
        MenuModule,
        ScrollPanelModule,
        BlockUIModule,
        StepsModule,
        MessagesModule,
        MessageModule,
        InputTextModule,
        MultiSelectModule,
        SplitButtonModule,
        LightboxModule,
        FileUploadModule,
        InputTextModule,
        PanelMenuModule,
        KeyFilterModule,
        TranslateModule,
        NgxPermissionsModule,
        NgxExtendedPdfViewerModule,
        InputTextareaModule,

        ConfirmMessageComponent,
        AlertMessageComponent,
        LabelComponent,
        ButtonComponent,
        LoaderComponent,
        GroupCardComponent,
        BarChartComponent,
        MenubarComponent,
        LoaderComponent,
        MenuComponent,
        FooterComponent,
        SearchItemComponent,
        SearchCustomerComponent,
        SearchReceiverComponent,
        SearchDocumentComponent,
        DdlSellerComponent,
        ThaiDatePipe,
        NumberDecimalPipe,
        // TranslatePipe,
        TranslateDDLPipe,
        TranslateLablePipe,
        ExportPdfComponent,
        TableComponent,
        ToggleButtonModule,
        PasswordModule
    ]
  })

  export class SharedModule { }

// export function httpLoaderFactory(http: HttpClient, empService: EnvironmentService) {
//     const URL = `${empService.getApiUrl()}/api/lang/translation/get/all/`;
//     return new TranslateHttpLoader(http, URL, '');
// }

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}
