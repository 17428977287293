import { HttpClient } from '@angular/common/http';
import { Renderer2 } from '@angular/core';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { Admprm300Component } from '../../admprm/admprm300/admprm300.component';
import { ParameterDetailList } from '../../model/admprm/admprm200.model';
import { Admprm300Model, FormModel, ParameterDetailData, ParameterDetailSave, ParameterHeaderNoneGroup } from '../../model/admprm/admprm300.model';

@Injectable({
    providedIn: 'root'
})
export class Admprm300Service {
    PRM_URL: string = `${this.evmService.getApiUrl()}/prm` as string;
    admprm300: Admprm300Model = {} as Admprm300Model;

    constructor(private http: HttpClient,
        private evmService: EnvironmentService,
        private autService: AuthenticationService,
        private fb: FormBuilder) { 
        this.init();
    }

    init(): void {
        this.admprm300.pmdtbdtbno;
        this.admprm300.pmdtbdtbdentcd;
        this.admprm300.pmdtbddms;
        this.admprm300.parameterDetail = {
            parameterDetailPK: null,
            pmdtbdgroup: null,
            pmdtbddms: null,
            pmdtbdtbdedesc: null,
            pmdtbdlangcode: null,
            pmdtbdacces: null,
            pmdcmnt: null,
            pmdcreusr: null,
            pmdcredat: null,
            pmdupdurs: null,
            pmdupddat: null,
            pmdtbdtxtv1: null,
            pmdtbdtxtv2: null,
            pmdtbdtxtv3: null,
            pmdtbdtxtv4: null,
            pmdtbdtxtv5: null,
            pmdtbdtxtv6: null,
            pmdtbdtxtv7: null,
            pmdtbdtxtv8: null,
            pmdtbdtxtv9: null,
            pmdtbdtxtv10: null,
            pmdtbdnumv1: null,
            pmdtbdnumv2: null,
            pmdtbdnumv3: null,
            pmdtbdnumv4: null,
            pmdtbdnumv5: null,
            pmdtbddatv1: null,
            pmdtbddatv2: null,
            pmdtbdtbdldesc: null
        } as ParameterDetailData;
        this.admprm300.parameterHeader = [];
        this.admprm300.checkFlagEditAndSave = true;
        this.admprm300.OptionPmdtbddms = [];
        this.admprm300.checkFlagCountry = true;
        this.admprm300.parameterDetailList = [];
        this.admprm300.parameterDetailSaveAndUpdate;
        this.admprm300.checkFlagActionSave = true;
        this.admprm300.user;
        this.admprm300.form_model = {} as FormModel;
        this.admprm300.form_model.gridParameterDetailForm = this.fb.group({
            pmdtbdtbno: (null),
            pmdtbdtbdentcd: [null, Validators.required],
            pmdtbdgroup: (null),
            pmdtbddms: (null),
            pmdtbdtbdedesc: [null, Validators.required],
            pmdtbdlangcode: (null),
            pmdtbdacces: (null),
            pmdcmnt: (null),
            pmdtbdtxtv1: (null),
            pmdtbdtxtv2: (null),
            pmdtbdtxtv3: (null),
            pmdtbdtxtv4: (null),
            pmdtbdtxtv5: (null),
            pmdtbdtxtv6: (null),
            pmdtbdtxtv7: (null),
            pmdtbdtxtv8: (null),
            pmdtbdtxtv9: (null),
            pmdtbdtxtv10: (null),
            pmdtbdnumv1: (null),
            pmdtbdnumv2: (null),
            pmdtbdnumv3: (null),
            pmdtbdnumv4: (null),
            pmdtbdnumv5: (null),
            pmdtbddatv1: (null),
            pmdtbddatv2: (null),
            pmdtbdtbdldesc: (null),
            pmhtbdtbdedesc: (null),
            pmhtbhldesc: (null)
        });
    }

    get load(): Admprm300Model {
        return this.admprm300;
    }
    
    getparameterDetailByDMS(pmdtbdtbno, pmdtbdtbdentcd, pmdtbddms): Observable<ParameterDetailData>{
        const URL = `${this.PRM_URL}/getDiscount/${pmdtbdtbno}/${pmdtbddms}/${pmdtbdtbdentcd}`;
        return this.http.get<ParameterDetailData>(URL, {headers: this.autService.tokenHeader()});
    }

    getparameterDetailByNoDMS(pmdtbdtbno, pmdtbdtbdentcd): Observable<ParameterDetailData>{
        const URL = `${this.PRM_URL}/getDataParameterDetail/${pmdtbdtbno}/${pmdtbdtbdentcd}`;
        return this.http.get<ParameterDetailData>(URL, {headers: this.autService.tokenHeader()});
    }

    getParameterHeaderByPmhtbhtbnoNoneGroup(pmhtbhtbno): Observable<ParameterHeaderNoneGroup> {
        const URL = `${this.PRM_URL}/getvprmtblhdrByPmhtbhtbnoNoneGroup/${pmhtbhtbno}`;
        return this.http.get<ParameterHeaderNoneGroup>(URL, {headers: this.autService.tokenHeader()});
    }

    getvparameterDetailByPmhtbhtbno(pmhtbhtbno): Observable<ParameterDetailList> {
        const URL = `${this.PRM_URL}/getvprmtbldtlByPmhtbhtbno/${pmhtbhtbno}`;
        return this.http.get<ParameterDetailList>(URL, {headers: this.autService.tokenHeader()});
    }

    saveAndUpdatevparameterDetail(paramaterDetail): Observable<ParameterDetailSave> {
        const URL = `${this.PRM_URL}/saveAndUpdatevPrmtbldtl`;
        return this.http.post<ParameterDetailSave>(URL, paramaterDetail, {headers: this.autService.tokenHeader()});
    }

}
