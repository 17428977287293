import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Big from 'big.js';

@Injectable({
    providedIn: 'root'
  })
  export class DecimalService {
    public numberDecimal: number;
    public numberRoundToDecimal: number;
    public result: Big;
    constructor(){}
    
    getRoundingDecimal(value: number, method: string, round_to_decimal: number, decimal: number): Big {
        switch (method) {
            case '0':
                this.numberRoundToDecimal = Number((value * round_to_decimal).toFixed(decimal)) / round_to_decimal;
                this.numberDecimal = Math.trunc(Number((this.numberRoundToDecimal * Math.pow(10, decimal)).toFixed(decimal))) / Math.pow(10, decimal);
                break;
            case '1':
                this.numberRoundToDecimal = Number((value * round_to_decimal).toFixed(decimal)) / round_to_decimal;
                this.numberDecimal = Math.ceil(Number((this.numberRoundToDecimal * Math.pow(10, decimal)).toFixed(decimal))) / Math.pow(10, decimal);
                break;
            case '2':
                this.numberRoundToDecimal = Number((value * round_to_decimal).toFixed(decimal)) / round_to_decimal;
                this.numberDecimal = Math.floor(Number((this.numberRoundToDecimal * Math.pow(10, decimal)).toFixed(decimal))) / Math.pow(10, decimal);
                break;
            case '3':
                this.numberRoundToDecimal = Number((value * round_to_decimal).toFixed(decimal)) / round_to_decimal;
                this.numberDecimal = Math.round(Number((this.numberRoundToDecimal * Math.pow(10, decimal)).toFixed(decimal))) / Math.pow(10, decimal);
                break;
            default:
                this.numberDecimal = value;
                break;
        }
        this.result = new Big(this.numberDecimal).toFixed(decimal);
        return this.result;
    }
 
  }