import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { LanguageType } from '../enum/language-type.enum';
import { AdministrationLanguageService } from '../services/administration-language.service';


@Pipe({
    name: 'translates',
    pure: true
})
export class TranslatePipe implements PipeTransform {

    private message: string = null;
    private prolangCode: string = null;

    constructor(private admlagService: AdministrationLanguageService) {

    }

    transform(code: string, type: LanguageType, program: string, country: string): string {
        console.log("code", code);
        if (code != this.prolangCode) {
            // this.admlagService.getProgram(code, program, country).subscribe(val => this.message = val);

            if (!this.message) {
                this.message = code;
            }

            console.log(this.message);
        }

        return this.message;
    }

}
