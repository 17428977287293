import { Component, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { Excexc200Model } from '../../model/excexc200-model';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { formatDate } from '@angular/common';
import { MessageService } from 'primeng/components/common/messageservice';
import { TranslateService } from '@ngx-translate/core';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { Excexc200Service } from '../../service/excexc200.service';
import { Router } from '@angular/router';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { DecimalPipe } from '@angular/common';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-excexc200',
  templateUrl: './excexc200.component.html',
  styleUrls: ['./excexc200.component.scss']
})
export class Excexc200Component extends PermissionComponent {

  public gridExchangeForm = new FormGroup({
    exccountry: new FormControl(null, Validators.required),
    exccurrency: new FormControl(null),
    excrate: new FormControl({value: null, disabled: true}, Validators.required),
    excexccountry: new FormControl(null, Validators.required),
    excexccurrency: new FormControl(null),
    excexcrate: new FormControl({value: null, disabled: true}, Validators.required),
    excsdate: new FormControl(null, Validators.required),
    excedate: new FormControl({value: null, disabled: true}, Validators.required)
  });

  public page_model: Excexc200Model;
  translateLablePipe = new TranslateLablePipe();

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  constructor(
    private parameterWmsSerive: ParameterWmsSerive,
    private parameterService: ParameterService,
    private decimalService: DecimalService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private excexc200Service: Excexc200Service,
    private router: Router,
    renderer2:Renderer2,
    private decimalPipe: DecimalPipe,
    private autService: AuthenticationService
  ) {
    super('P50002',renderer2);
    this.page_model = this.excexc200Service.load;
    this.page_model.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel;
  }

  ngOnInit() {
    this.autService.getParameterWmsCountry().subscribe(response => {
      this.page_model.wmsCountry = JSON.parse(JSON.stringify(response));
      if(this.page_model.wmsCountry.length == 0){
        this.page_model.wmsCountryDisFlag = true;
      }
      this.parameterWmsSerive.getParameterWms(9146).subscribe(response => {
        this.page_model.wmsCurrency = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });
    }, err => {
      console.log(err);
    });
  }

  selectedExcStartDate(event){
    if(event == null){
      this.gridExchangeForm.controls['excedate'].reset();
      this.gridExchangeForm.controls['excedate'].disable();
    }else{
      this.page_model.currentDateEnd = event;
      this.gridExchangeForm.controls['excedate'].reset();
      this.gridExchangeForm.controls['excedate'].enable();
    }
  }

  async selectedCountry(event){
    this.page_model.wmsExcCountry = await this.page_model.wmsCountry.filter(data => {
      return ((data.value.toString().toLowerCase() != event.value.toString().toLowerCase()));
    });
    this.page_model.selectedWmsCountry = await this.page_model.wmsCountry.filter(data => {
      return ((data.value.toString().toLowerCase().indexOf(event.value.toString().toLowerCase()) > -1));
    });
    this.page_model.selectedWmsCurrency = await this.page_model.wmsCurrency.filter(data => {
      return ((data.parameterData.entry_code.toString().toLowerCase().indexOf(this.page_model.selectedWmsCountry[0].parameterData.parameter_v6.toString().toLowerCase()) > -1));
    });
    this.gridExchangeForm.controls['exccurrency'].setValue(this.translateLablePipe.transform(this.page_model.selectedWmsCurrency[0].engDesc,this.page_model.selectedWmsCurrency[0].localDesc));
    this.parameterService.getvprmdtltolableByCountry(104, event.value).subscribe(async response => {
      this.page_model.parameterDecimal = await JSON.parse(JSON.stringify(response));
      this.page_model.decimalData.dataTypeDecimalPurchase = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalPurchase);
      this.page_model.decimalData.dataTypeDecimalSelling = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalSelling);
      this.page_model.decimalData.dataTypeDecimalRoundMethod = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalRoundMethod);
      this.page_model.decimalData.dataTypeDecimalRoundToValue = await this.page_model.parameterDecimal.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalRoundToValue);
      this.page_model.method = await this.page_model.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1;
      this.page_model.roundToDecimal = await this.page_model.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
      this.page_model.decimal = await this.page_model.decimalData.dataTypeDecimalPurchase[0].value.pmdtbdnumv1;
      this.gridExchangeForm.get('excrate').setValue(1);
      this.decimalRate();
    }, err => {
      console.log(err);
    });;
    this.page_model.flagExc = false;
    this.gridExchangeForm.controls['excexccountry'].reset();
    this.gridExchangeForm.controls['excexccurrency'].reset();
    this.gridExchangeForm.controls['excrate'].enable();
  }

  async selectedExcCountry(event){
    this.page_model.selectedWmsExcCountry = await this.page_model.wmsCountry.filter(data => {
      return ((data.value.toString().toLowerCase().indexOf(event.value.toString().toLowerCase()) > -1));
    });
    this.page_model.selectedWmsExcCurrency = await this.page_model.wmsCurrency.filter(data => {
      return ((data.parameterData.entry_code.toString().toLowerCase().indexOf(this.page_model.selectedWmsExcCountry[0].parameterData.parameter_v6.toString().toLowerCase()) > -1));
    });
    this.gridExchangeForm.controls['excexccurrency'].setValue(this.translateLablePipe.transform(this.page_model.selectedWmsExcCurrency[0].engDesc,this.page_model.selectedWmsExcCurrency[0].localDesc));
    this.parameterService.getvprmdtltolableByCountry(104, event.value).subscribe(async response => {
      this.page_model.parameterDecimalExc = await JSON.parse(JSON.stringify(response));
      this.page_model.decimalExcData.dataTypeDecimalPurchase = await this.page_model.parameterDecimalExc.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalPurchase);
      this.page_model.decimalExcData.dataTypeDecimalSelling = await this.page_model.parameterDecimalExc.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalSelling);
      this.page_model.decimalExcData.dataTypeDecimalRoundMethod = await this.page_model.parameterDecimalExc.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalRoundMethod);
      this.page_model.decimalExcData.dataTypeDecimalRoundToValue = await this.page_model.parameterDecimalExc.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.page_model.typeDecimalRoundToValue);
      this.page_model.methodExc = await this.page_model.decimalExcData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1;
      this.page_model.roundToDecimalExc = await this.page_model.decimalExcData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
      this.page_model.decimalExc = await this.page_model.decimalExcData.dataTypeDecimalPurchase[0].value.pmdtbdnumv1;
    }, err => {
      console.log(err);
    });
    this.gridExchangeForm.controls['excexcrate'].reset();
    this.gridExchangeForm.controls['excexcrate'].enable();
  }

  decimalRate(){
    this.gridExchangeForm.controls['excrate'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.gridExchangeForm.controls['excrate'].value.toString().replace(/,/g, ""), this.page_model.method, this.page_model.roundToDecimal, this.page_model.decimal), '1.'+this.page_model.decimal+'-'+this.page_model.decimal, 'en'));
  }

  decimalRateExc(){
    this.gridExchangeForm.controls['excexcrate'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.gridExchangeForm.controls['excexcrate'].value.toString().replace(/,/g, ""), this.page_model.methodExc, this.page_model.roundToDecimalExc, this.page_model.decimalExc), '1.'+this.page_model.decimalExc+'-'+this.page_model.decimalExc, 'en'));
  }

  submitExchange(){
    this.page_model.exchangeRequest.exccountry = this.gridExchangeForm.controls['exccountry'].value;
    this.page_model.exchangeRequest.excrate = this.gridExchangeForm.controls['excrate'].value == null? null :this.gridExchangeForm.controls['excrate'].value.toString().replace(/,/g, "");
    this.page_model.exchangeRequest.excexccountry = this.gridExchangeForm.controls['excexccountry'].value;
    this.page_model.exchangeRequest.excexcrate = this.gridExchangeForm.controls['excexcrate'].value == null? null :this.gridExchangeForm.controls['excexcrate'].value.toString().replace(/,/g, "");
    this.page_model.exchangeRequest.excsdate = this.gridExchangeForm.controls['excsdate'].value == null? null: formatDate(this.gridExchangeForm.controls['excsdate'].value, 'yyyy-MM-dd', 'en_US');
    this.page_model.exchangeRequest.excedate = this.gridExchangeForm.controls['excedate'].value == null? null: formatDate(this.gridExchangeForm.controls['excedate'].value, 'yyyy-MM-dd', 'en_US');
    this.page_model.exchangeRequest.exccreusr = this.page_model.user.userName;
    if(this.page_model.exchangeRequest.exccountry != null && this.page_model.exchangeRequest.excrate != null &&
      this.page_model.exchangeRequest.excexccountry != null && this.page_model.exchangeRequest.excexcrate != null &&
      this.page_model.exchangeRequest.excsdate != null && this.page_model.exchangeRequest.excedate != null){
      this.excexc200Service.saveExchangeRate(this.page_model.exchangeRequest).subscribe(async response => {
        this.page_model.exchangeRateResponse = await JSON.parse(JSON.stringify(response));
        if(this.page_model.exchangeRateResponse.status == "1"){
          this.successAlert(this.translateService.instant('EXCEXC200.M00012'));
          setTimeout(() => {
            this.onConfirmBack();
          }, 500);
        }else{
          this.warningAlert('alert', 'error', this.translateService.instant('EXCEXC200.M00013'));
        }
      });
    }else{
      this.warningAlert('alert', 'warn', this.translateService.instant('EXCEXC200.M00014'));
    }
  }

  warningAlert(key: string, severityInfo: string, detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: key, sticky: true, severity: severityInfo, summary:this.translateService.instant('EXCEXC200.M00022'), detail: detaiInfo});
  }

  successAlert(detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: 'alertsuccess', sticky: true, severity: 'success', /*summary:this.translateService.instant('EXCEXC200.M00015'),*/detail: detaiInfo});
  }

  onReject(){
    this.messageService.clear();
  }

  onConfirm(){
    this.messageService.clear();
  }

  onConfirmBack(){
    this.messageService.clear();
    this.router.navigate(['excexc/excexc100']);
  }

  goBack() {
    this.page_model.exchangeRequest.exccountry = this.gridExchangeForm.controls['exccountry'].value;
    this.page_model.exchangeRequest.excrate = this.gridExchangeForm.controls['excrate'].value;
    this.page_model.exchangeRequest.excexccountry = this.gridExchangeForm.controls['excexccountry'].value;
    this.page_model.exchangeRequest.excexcrate = this.gridExchangeForm.controls['excexcrate'].value;
    this.page_model.exchangeRequest.excsdate = this.gridExchangeForm.controls['excsdate'].value == null? null: formatDate(this.gridExchangeForm.controls['excsdate'].value, 'yyyy-MM-dd', 'en_US');
    this.page_model.exchangeRequest.excedate = this.gridExchangeForm.controls['excedate'].value == null? null: formatDate(this.gridExchangeForm.controls['excedate'].value, 'yyyy-MM-dd', 'en_US');
    if(this.page_model.exchangeRequest.exccountry != null || this.page_model.exchangeRequest.excrate != null ||
    this.page_model.exchangeRequest.excexccountry != null || this.page_model.exchangeRequest.excexcrate != null ||
    this.page_model.exchangeRequest.excsdate != null || this.page_model.exchangeRequest.excedate != null){
      this.warningAlert('goback', 'warn', this.translateService.instant('EXCEXC200.M00016'));
    }else{
      this.onConfirmBack();
    }
  }

  checkInput(){
    this.page_model.checkFlagInput = false;
  }

  checkInputSubmit(){
    this.page_model.flagEditAndSave = false;
  }


}
