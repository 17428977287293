export const ChartColor = {
    backgroundColorChart: [
        'rgb(0,55,75)',
        'rgb(248,73,52)',
        'rgb(245,9,145)',
        'rgb(0,158,71)',
        'rgb(230,175,0)',
        'rgb(0,99,134)',
        'rgb(250,141,30)',
        'rgb(250,130,199)',
        'rgb(93,255,166)',
        'rgb(255,217,97)',
        'rgb(0,182,246)',
        'rgb(156,20,6)',
        'rgb(142,8,113)',
        'rgb(0,100,45)',
        'rgb(186,186,2)',
        'rgb(159,230,255)',
        'rgb(255,0,0)',
        'rgb(244,174,239)',
        'rgb(158,230,118)',
        'rgb(255,249,9)']
}