import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EmpcalCalService } from 'src/app/shared/services/empcal.service';
import { callCenterInquiry, empcalPage, searchInquiry, callCenterResponse } from './model/empcal100.model';
import { TranslateService } from '@ngx-translate/core';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';

@Component({
  selector: 'app-empcal100',
  templateUrl: './empcal100.component.html',
  styleUrls: ['./empcal100.component.scss']
})
export class Empcal100Component implements OnInit {

  public callCenterPage: empcalPage = {} as empcalPage;
  public callCenterModel: searchInquiry = {} as searchInquiry;
  public callCenterTable: Array<callCenterInquiry> = [] as Array<callCenterInquiry>
  translateLablePipe = new TranslateLablePipe();

  callCenterForm = new FormGroup({
    callCenterCode: new FormControl(),
    callCenterName: new FormControl(),
    callCenterOrg: new FormControl(),
    callCenterOl: new FormControl(),
    callCenterUser: new FormControl(),
    callCenterTel: new FormControl(),
    callCenterEmail: new FormControl()
  });

  constructor(
    private empcalservice: EmpcalCalService,
    private translateService: TranslateService,
    private parameterService: ParameterService,
    private exportExcelService: ExportExcelService,
  ) {
      this.getDataToDropdown();
  }

  ngOnInit() {
    this.callCenterPage.cols = [
      { field: 'callCenterCode', header: 'EMPCAL100.M00007', type: 'string', link: '/empcal/empcal200/' },
      { field: 'callCenterName', header: 'EMPCAL100.M00008', type: 'string' },
      { field: 'callCenterOrg', header: 'EMPCAL100.M00009', type: 'string' },
      { field: 'callCenterOl', header: 'EMPCAL100.M00010', type: 'string' },
      { field: 'callCenterTel', header: 'EMPCAL100.M00011', type: 'string'},
      { field: 'callCenterEmail', header: 'EMPCAL100.M00016', type: 'string'},
  ];

    this.callCenterPage.searchDisabled = false;
    this.callCenterPage.disabledReport = true;
  }

  getDataToDropdown() {
    // organization
    this.parameterService.getParameterDetailToLableValueCode(1500).subscribe(response=>{
      this.callCenterPage.org = JSON.parse(JSON.stringify(response));
    });
    // organization level
    this.parameterService.getParameterDetailToLableValueCode(101).subscribe(response=>{
      this.callCenterPage.level = JSON.parse(JSON.stringify(response));
    });
  }

  AutoSearch(event) {
    this.callCenterTable = this.callCenterPage.callCenterTemp;
    this.callCenterPage.advancedSearch = this.callCenterPage.advancedSearch == null ? '' : this.callCenterPage.advancedSearch;
    this.callCenterTable = this.callCenterTable.filter(data => {
      return (
        (data.callCenterCode && data.callCenterCode.toLowerCase().includes(this.callCenterPage.advancedSearch.toLowerCase())) ||
        (data.callCenterName && data.callCenterName.toLowerCase().includes(this.callCenterPage.advancedSearch.toLowerCase())) ||
        (data.callCenterOrg && data.callCenterOrg.toLowerCase().includes(this.callCenterPage.advancedSearch.toLowerCase())) ||
        (data.callCenterOl && data.callCenterOl.toLowerCase().includes(this.callCenterPage.advancedSearch.toLowerCase())) ||
        (data.callCenterUser && data.callCenterUser.toLowerCase().includes(this.callCenterPage.advancedSearch.toLowerCase())) ||
        (data.callCenterTel && data.callCenterTel.toLowerCase().includes(this.callCenterPage.advancedSearch.toLowerCase())) ||
        (data.callCenterEmail && data.callCenterEmail.toLowerCase().includes(this.callCenterPage.advancedSearch.toLowerCase()))
      );
    });
  }

  filterData() {
    this.callCenterTable = null;
    this.callCenterPage.callCenterTemp = null;
    this.callCenterPage.searchDisabled = true;
    this.callCenterModel.code = this.callCenterForm.controls['callCenterCode'].value;
    this.callCenterModel.name = this.callCenterForm.controls['callCenterName'].value;
    if(this.callCenterForm.controls['callCenterOrg'].value != null){
      this.callCenterModel.organization = this.callCenterForm.controls['callCenterOrg'].value;
    } else {
      this.callCenterModel.organization = null
    }
    if(this.callCenterForm.controls['callCenterOl'].value != null){
      this.callCenterModel.organizationLevel = this.callCenterForm.controls['callCenterOl'].value;
    } else {
      this.callCenterModel.organizationLevel = null
    }
    
    this.callCenterModel.username = this.callCenterForm.controls['callCenterUser'].value;
    this.empcalservice.getCallCenterInquiry(this.callCenterModel).subscribe((response:callCenterResponse[]) => {
      if(response){
        response.forEach(data => {
        data.callCenterOrg = this.translateLablePipe.transform(data.callCenterOrg_eng,data.callCenterOrg_local);
        data.callCenterOl = this.translateLablePipe.transform(data.callCenterOl_eng,data.callCenterOl_local);
        });
        this.callCenterTable = JSON.parse(JSON.stringify(response))
        this.callCenterPage.callCenterTemp = this.callCenterTable;
      }
        if(this.callCenterTable != null){
          this.callCenterPage.disabledReport = false;
        } else {
          this.callCenterPage.disabledReport = true;
        }
    }, err => {
      console.log(err);
    });

  }

  clearData() {
    this.callCenterForm.reset();
    this.callCenterPage.advancedSearch = "";
    this.callCenterPage.searchDisabled = false;
    if(this.callCenterTable){
      this.callCenterPage.disabledReport = false;
    }else{
      this.callCenterPage.disabledReport = true;
    }
    
  }

  changeSearch(){
    if(this.callCenterForm.dirty){
      this.callCenterPage.searchDisabled = false;
    } else {
      this.callCenterPage.searchDisabled = true;
    }
  }

  exportReport() {
    let sub_title = [];
    sub_title.push([this.translateService.instant('EMPCAL100.M00001')]);
    let header: any = {
      // รหัสพนักงานคอลเซ็นเตอร์ 
      callCenterCode: this.translateService.instant('EMPCAL100.M00007'),
      //ชื่อ-นามสกุล
      callCenterName: this.translateService.instant('EMPCAL100.M00008'),
      // องค์กร
      callCenterOrg: this.translateService.instant('EMPCAL100.M00009'),
      // ระดับภายในองค์กร
      callCenterOl: this.translateService.instant('EMPCAL100.M00010'),
      // เบอร์โทรศัพท์
      callCenterTel: this.translateService.instant('EMPCAL100.M00011'),
      // Email
      callCenterEmail: this.translateService.instant('EMPCAL100.M00016')
    };
    let export_data_list = [];
    this.callCenterPage.callCenterTemp.forEach((call_center: callCenterInquiry) => {
      let export_data = {
        callCenterCode: call_center.callCenterCode,
        callCenterName: call_center.callCenterName,
        callCenterOrg: call_center.callCenterOrg, 
        callCenterOl: call_center.callCenterOl, 
        callCenterTel: call_center.callCenterTel,
        callCenterEmail: call_center.callCenterEmail
      }
      export_data_list.push(export_data);
    });
    this.exportExcelService.exportAsExcelFile(export_data_list, this.translateService.instant('EMPCAL100.M00001'), header, null, sub_title);
    export_data_list.shift();
    
  }

}
