import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GetHistoryRequest } from 'src/app/upl/upload/upload200/model/upload200.model';
import { parameter_entrycode_ihitem, parameter_entrycode_item } from '../constant/parameter.constant';
import { ItemInhouseUploadRejectResponse, ItemUploadRejectResponse } from '../model/upload.model';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  UPL_URL: string = `${this.evmService.getApiUrl()}/upl/upload` as string;

  constructor(private http: HttpClient,
    private evmService: EnvironmentService,
    private autService: AuthenticationService) { }

  uploadSingleCSVFile(file: File, upload_Date: any): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('csvfile', file);
    const URL = `${this.UPL_URL}/uploadSingleCSVFile?uploadDate=${upload_Date}`;
    return this.http.post(URL, formData, { headers: this.autService.tokenHeaderUploadFile() });
  }

  getUploadHistory(request: GetHistoryRequest): Observable<any> {
    const URL = `${this.UPL_URL}/getUploadHistory?langCode=${request.langCode}&startDate=${request.startDate}&endDate=${request.endDate}&uploadName=${request.uploadName}`;
    return this.http.get(URL, { headers: this.autService.tokenHeaderUploadFile() });
  }

  getUploadHistoryDetail(langCode: string, fileName: string): Observable<any> {
    const URL = `${this.UPL_URL}/getSalePriceHistory?langCode=${langCode}&FileName=${fileName}`;
    return this.http.get(URL, { headers: this.autService.tokenHeaderUploadFile() });
  }

  uploadItemCSVFile(file: File, upload_Date: any): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('csvfile', file);
    const URL = `${this.UPL_URL}/uploadItemCSVFile?uploadDate=${upload_Date}`;
    return this.http.post(URL, formData, { headers: this.autService.tokenHeaderUploadFile() });
  }

  uploadIHItemCSVFile(file: File, upload_Date: any): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('csvfile', file);
    const URL = `${this.UPL_URL}/inhouseUpload?uploadDate=${upload_Date}`;
    return this.http.post(URL, formData, { headers: this.autService.tokenHeaderUploadFile() });
  }

  getItemUploadReject(file_name: string): Observable<Array<ItemUploadRejectResponse>>{
    const URL = `${this.UPL_URL}/getRejectInformationItemInfo?FileName=${file_name}&langCode=${localStorage.getItem('languageData')}&uploadCode=${parameter_entrycode_item}`
    return this.http.get<Array<ItemUploadRejectResponse>>(URL,{ headers: this.autService.tokenHeader() });
  }

  getItemInhouseUploadReject(file_name: string): Observable<Array<ItemInhouseUploadRejectResponse>>{
    const URL = `${this.UPL_URL}/getRejectInformationItemInHouse?FileName=${file_name}&langCode=${localStorage.getItem('languageData')}&uploadCode=${parameter_entrycode_ihitem}`
    return this.http.get<Array<ItemInhouseUploadRejectResponse>>(URL,{ headers: this.autService.tokenHeader() });
  }

  formatFileName(file: File, format_file: String){ 
    let nameFile = file.name.substring(0, format_file.length);
    if(nameFile == format_file){
      return true;
    }else{
      return false;
    }
  }

  formatFileDate(file: File, format_file: String, upload_date: String){
    let datefile = file.name.substring(format_file.length, format_file.length + 8);
    let year = datefile.substring(0, 4);
    let month = datefile.substring(4, 6);
    let day = datefile.substring(6, 8);
    let formatdate = day + '/' + month + '/' + year;
    if(upload_date == formatdate){
      return true;
    }else{
      return false;
    }
  }

  formatFile(file: File){ 
    let formatFile = file.name.substring(file.name.length - 4);
    if(formatFile.toLowerCase() == ".csv"){
      return true;
    }else{
      return false;
    }
  }

  downloadBadFile(fileName,uploadCode): Observable<Blob>{
    const URL = `${this.UPL_URL}/getErrorLog?fileName=${fileName}&uploadCode=${uploadCode}`
    return this.http.get(URL, {headers: this.autService.tokenHeader(),responseType: 'blob'});
  }
}
