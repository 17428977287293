import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as Chart from 'chart.js';
import { ViewChild } from '@angular/core';
import { UIChart } from 'primeng/primeng';
import { ParameterWmsSerive } from '../../services/parameterWms.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  /*
  Example send data to component.
  dataChart = [
    { value1: number,dates: string,label: string} // Or add value2 for chartBarCouple.
  ];
  select = {datetype: string, period: number, typeBar: string}; // typeBar is "single" or "couple"
  typeChart = string; // "bar" ,"line"
  <<----------------------------------->>
  How to use ?
  <app-bar-chart [dataChart]="variable" [select]="variable" [typeChart]="variable"></app-bar-chart> 
  */
  chartData: any;
  options: any;
  sumQty: number = 0 as number;
  dataShowChart: any = [];
  saleShow = false;
  currency = { currencyCd: '', currencyDesc: '' };
  @Input() dataChart: any;
  @Input() select: any;
  @Input() typeChart: any;
  @Input() isChange: any;
  @Input() dateFromToPeriod: any = [];
  @Input() dateFromToPeriodData: any = [];
  @Input() monthTranslateData: any = [];
  @Input() decimal: number;
  thaiMonth = [];
  @ViewChild('chart', { static: false }) chart: UIChart;

  constructor(private parameterWmsSerive: ParameterWmsSerive) {
  }

  ngOnInit() {
    Chart.defaults.global.defaultFontFamily = 'kanitmedium';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.dataChart != undefined && this.select != undefined) {
      if (changes.dataChart != undefined || changes.typeChart != undefined) {
        this.getCurrency(this.select.country);
        this.prepareData();
        this.findPeriodByDate();
      }
    }
  }

  getCurrency(country : string){
    this.parameterWmsSerive.getParameterWmsBytableNoAndCode(105, "MM").subscribe((response) => {
      if (response[0].parameterData.parameter_v6) {
        this.parameterWmsSerive.getParameterWmsBytableNoAndCode(9146, response[0].parameterData.parameter_v6).subscribe((currencyData) => {       
            this.currency.currencyCd = currencyData[0].parameterData.parameter_v1;
            this.currency.currencyDesc =  response[0].parameterData.parameter_v6 ;
        });
      }
    });
  }

  sumValue(data,key) {
    return data.reduce((a, b) => a + (b[key] || 0), 0);
  }

  findPeriodByDate() {    
    if(this.dateFromToPeriod.length > 0){
        let monthBackwardTemp = [];
        let dates = [];
      if (this.select.datetype == 'D' && this.select.period == 7 || this.select.datetype == 'M' && this.select.period == 0 || this.select.datetype == 'M' && this.select.period == 1) {
          let i = 0;
          let startDate = this.dateFromToPeriod[0];
          let endDate = this.dateFromToPeriod[1];
          if(this.select.datetype == 'M' && this.select.period == 1){
            this.dateFromToPeriod[0] = this.dateFromToPeriod[0].substring(0,6)+(this.dateFromToPeriod[0].substring(6,7)-1)+this.dateFromToPeriod[0].substring(7);
            this.dateFromToPeriod[1] = this.dateFromToPeriod[1].substring(0,6)+(this.dateFromToPeriod[1].substring(6,7)-1)+this.dateFromToPeriod[1].substring(7);
          }
          if(this.dateFromToPeriod[0] != this.dateFromToPeriod[1]){
              while (moment(this.dateFromToPeriod[0]).add(i,'days').format('YYYY-MM-DD') != moment(this.dateFromToPeriod[1]).format('YYYY-MM-DD')) {
                dates.push(moment(this.dateFromToPeriod[0]).add(i,'days').format('YYYY-MM-DD'));
                i++;
              }
              dates.push(moment(dates[dates.length-1]).add(1,'days').format('YYYY-MM-DD'));
          } else {
              dates.push(this.dateFromToPeriod[0]);
          }
            for (let i = 0; i < dates.length+1; i++) {
              for (let j = 0; j < this.dataShowChart.label.length; j++) {
                 if(dates[i] == moment(this.dataShowChart.label[j],"DD/MM/YYYY").format('YYYY-MM-DD')){
                  monthBackwardTemp.push({
                    sequence : j,
                    label: this.dataShowChart.label[j],
                    value1: this.dataShowChart.value1[j],
                  }); 
                 }      
              }
            }
            this.dateFromToPeriod[0] = startDate;
            this.dateFromToPeriod[1] = endDate;
            this.stageDrawChart(monthBackwardTemp);
      } else if(this.select.datetype == 'M' && this.select.period == 3 || this.select.datetype == 'Y' && this.select.period == 0) {
          let lableYear = new Date(moment(new Date()).format('YYYY-MM-DD')).getFullYear().toString();
          let month: number;
          let monthBackward: number[] = [];
          let monthBackwardTemp = [];
            let startDate = new Date(this.dateFromToPeriod[0]);
            let endDate   = new Date(this.dateFromToPeriod[1]);

            let minDate = new Date(this.dateFromToPeriod[0]);
            let maxDate = new Date(this.dateFromToPeriod[1]);
            if(this.select.datetype == 'M'){
              monthBackward = [];              
              for (let i = 0; i < +this.select.period + 1; i++) {
                  if (new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth() == 0 && i == 1) {
                    month = 12;
                  } else if (new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth() == 1 && i == 2) {
                    month = 13;
                  } else if (new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth() == 2 && i == 3) {
                    month = 14;
                  } else if (i == 0) {
                    month = new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth();
                  }                  
                  monthBackward.push(month-i);                 
              }              
            } else {
                  for(let i = maxDate.getMonth(); i >= 0; i--){
                    monthBackward.push(i)
                  } 
            }
            for (let i = 0; i < monthBackward.length; i++) {
              const element = monthBackward[i];
              if(this.monthTranslateData[0] == 'ม.ค.'){
                if(element+i > 11){
                    startDate.setFullYear(new Date(moment(new Date()).add(-1, 'year').format('YYYY-MM-DD')).getFullYear());
                    endDate.setFullYear(new Date(moment(new Date()).add(-1, 'year').format('YYYY-MM-DD')).getFullYear());
                    lableYear = new Date(moment(new Date()).add(542, 'year').format('YYYY-MM-DD')).getFullYear().toString();
                }
                else {
                    startDate.setFullYear(new Date(moment(new Date()).format('YYYY-MM-DD')).getFullYear());
                    endDate.setFullYear(new Date(moment(new Date()).format('YYYY-MM-DD')).getFullYear());
                    lableYear = new Date(moment(new Date()).add(543, 'year').format('YYYY-MM-DD')).getFullYear().toString();
                }
              } else {
                if(element+i > 11){
                    startDate.setFullYear(new Date(moment(new Date()).add(1, 'year').format('YYYY-MM-DD')).getFullYear());
                    endDate.setFullYear(new Date(moment(new Date()).add(1, 'year').format('YYYY-MM-DD')).getFullYear());
                    lableYear = new Date(moment(new Date()).subtract(1, 'year').format('YYYY-MM-DD')).getFullYear().toString();
                }
              } 
              startDate.setMonth(element);
              endDate.setMonth(element);
              let currency = new Date();
              let currencyYear = currency.getFullYear();
              if((element == 3 || element == 5 || element == 8 || element == 10) && maxDate.getDate() == 31){
                endDate = new Date(currencyYear, element + 1, 0);
                if(minDate.getDate() == 31){
                  startDate.setDate(31);
                }
              }
              if ((element == 0 || element == 2 || element == 4 || element == 6 || element == 7 || element == 9 || element == 10) && maxDate.getDate() == 31){
                endDate = new Date(currencyYear, element + 1, 0);
                if(minDate.getDate() == 31){
                  startDate.setDate(31);
                }
              }
              if(element == 1 && (maxDate.getDate() == 31 || maxDate.getDate() == 30 || maxDate.getDate() == 29)){
                endDate = new Date(currencyYear, element + 1, 0); 
                if(minDate.getDate() == 31){
                  startDate.setDate(31);
                } else if(minDate.getDate() == 30){
                  startDate.setDate(30);
                } else if(minDate.getDate() == 29){
                  startDate.setDate(29);
                }
              }
                let months = this.dataChart.filter(data =>  { 
                  return new Date(data.dates) >= startDate && new Date(data.dates) <= endDate });
                monthBackwardTemp.push({
                  sequence : i,
                  label: `${this.thaiMonth[element]} ${lableYear.substring(2)}`,
                  value1: months.length > 0 ? this.sumValue(months,'value1') :0,
                });
            }
            monthBackwardTemp = monthBackwardTemp.sort((a, b) => a.sequence < b.sequence ? 1 : a.sequence > b.sequence ? -1 : 0);
            this.stageDrawChart(monthBackwardTemp);  
      }
    }
  }

  stageDrawChart(dataTemp){
      this.dataShowChart.label = [];
      this.dataShowChart.value1 = [];      
      dataTemp.forEach((element) => {
        this.dataShowChart.label.push(element.label);
        this.dataShowChart.value1.push(element.value1);              
      });
      this.createChart(this.dataShowChart, this.decimal);     
  }

  prepareData(){
    this.thaiMonth = this.monthTranslateData;
    this.sumQty = 0;
    //length data object to chart 
    if (this.dataChart.length != 0) {

        let labelMonth;
        let label: any = [];
        let value2: any = [];
        let value1: any = [];
        let tempMonth = [];
        let month: number;        

        //Datetype ex. M, Y, D 
        if (this.select.datetype == 'M') {  
          //lableyear ex. 2020, 2021
          let lableYear = new Date(moment(new Date()).format('YYYY-MM-DD')).getFullYear().toString();
          
            if (this.select.period == 3) {
              for (let i = 0; i < +this.select.period + 1; i++) {

                if (new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth() == 0 && i == 1) {
                  month = 12;
                }else if (new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth() == 1 && i == 2) {
                  month = 13;
                }else if (new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth() == 2 && i == 3) {
                  month = 14;
                }else if (i == 0) {
                  month = new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth();
                }
                //monthTranslateData ex. [jan,Feb,Mar,...]
                if(this.monthTranslateData[0] == 'ม.ค.'){
                  if(month > 11){
                    lableYear = new Date(moment(new Date()).add(542, 'year').format('YYYY-MM-DD')).getFullYear().toString();
                  }else{
                    lableYear = new Date(moment(new Date()).add(543, 'year').format('YYYY-MM-DD')).getFullYear().toString();
                  }
                }else{
                  if(month > 11){
                    lableYear = new Date(moment(new Date()).subtract(1, 'year').format('YYYY-MM-DD')).getFullYear().toString();
                  }
                }  
                tempMonth.push({
                  sequence: i,
                  monthIndex: month-i,
                  lableMonth: `${this.thaiMonth[month-i]} ${lableYear.substring(2)}`,
                  value1: 0
                });
                if (this.select.typeBar == 'couple') {
                  tempMonth[i].value2 = 0;
                }
              }//for
            }else if(this.select.period == 0) {
              for(let i = 0; i < new Date(moment(new Date()).format('YYYY-MM-DD')).getDate(); i++) {
                tempMonth.push({
                  sequence : i,
                  lableMonth : moment(new Date()).subtract(i,'days').format('DD/MM/YYYY'),
                  date : moment(new Date()).subtract(i,'days').format('YYYY-MM-DD'),
                  value1 : 0,
                });
                if (this.select.typeBar == 'couple') {
                  tempMonth[i].value2 = 0;
                }
              }//for
            }else{
              const beforMonth = moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
              for(let i = 0; i < new Date(moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD')).getDate() ; i++) {
                tempMonth.push({
                  sequence : i,
                  lableMonth : moment(beforMonth).subtract(i, 'days').format('DD/MM/YYYY'),
                  date : moment(beforMonth).subtract(i, 'days').format('YYYY-MM-DD'),
                  value1 : 0,
                });
                if (this.select.typeBar == 'couple') {
                  tempMonth[i].value2 = 0;
                }
              }
            }
          }else if(this.select.datetype == 'D'){
            for (let i = 0; i < this.select.period; i++) {
              tempMonth.push({
                sequence : i,
                lableMonth : moment(new Date()).subtract(i, 'days').format('DD/MM/YYYY'),
                date : moment(new Date()).subtract(i, 'days').format('YYYY-MM-DD'),
                value1 : 0,
              });
              if (this.select.typeBar == 'couple') {
                tempMonth[i].value2 = 0;
              }
            }
          }else if(this.select.datetype == 'Y'){
            let lableYear = new Date(moment(new Date()).format('YYYY-MM-DD')).getFullYear().toString();
            if (this.select.period == 0) {
              for (let i = 0; i < this.thaiMonth.length; i++) {
                if (new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth() == 0 && i == 1) {
                  month = 12;
                } else if (new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth() == 1 && i == 2) {
                  month = 13;
                } else if (new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth() == 2 && i == 3) {
                  month = 14;
                } else if (i == 0) {
                  month = new Date(moment(new Date()).format('YYYY-MM-DD')).getMonth();
                }
                if(this.monthTranslateData[0] == 'ม.ค.'){
                  lableYear = new Date(moment(new Date()).add(543, 'year').format('YYYY-MM-DD')).getFullYear().toString();
                } 

                let temp = month-i;
                let monthYear;
                let Year;
                if(temp >= 0){
                  monthYear = temp;
                  Year = lableYear.substring(2);
                }else{
                  monthYear = temp+12;
                  Year = Number(lableYear.substring(2))-1;
                }
                if(month-i >= 0){
                  tempMonth.push({
                    sequence: i,
                    monthIndex: month-i,
                    lableMonth: `${this.thaiMonth[monthYear]} ${Year}`,
                    value1: 0,
                  });
                }
                if (this.select.typeBar == 'couple') {
                  tempMonth[i].value2 = 0;
                }
              }
            }
          }

        if(this.select.datetype == 'Y' && this.select.period == 0){
          tempMonth = tempMonth.sort((a, b) => a.monthIndex > b.monthIndex ? 1 : a.monthIndex < b.monthIndex ? -1 : 0);
        } else {
          tempMonth = tempMonth.sort((a, b) => a.sequence < b.sequence ? 1 : a.sequence > b.sequence ? -1 : 0);
        }
        
        this.dataChart.forEach(element => {
            this.sumQty = this.sumQty + element.value1;
            if (this.select.datetype == 'M' && this.select.period == 3) {
              labelMonth = new Date(element.dates);
              
              if (tempMonth.some((temp) => temp.monthIndex == labelMonth.getMonth())) {
                element.label = this.thaiMonth[labelMonth.getMonth()];
              }

              if (this.select.typeBar == 'single') {
                const data = tempMonth.find((temp) => temp.monthIndex == labelMonth.getMonth());
                data.value1 += element.value1;
              }

              if (this.select.typeBar == 'couple') {
                const data = tempMonth.find((temp) => temp.monthIndex == labelMonth.getMonth());
                data.value1 = element.value1;
                data.value2 = element.value2;
              }
            } else if (this.select.datetype == 'Y' && this.select.period == 0) {
              labelMonth = new Date(element.dates);

              if (tempMonth.some((temp) => temp.monthIndex == labelMonth.getMonth())) {
                element.label = this.thaiMonth[labelMonth.getMonth()];
              }

              if (this.select.typeBar == 'single') {
                const data = tempMonth.find((temp) => temp.monthIndex == labelMonth.getMonth());
                data.value1 += element.value1;
              }

              if (this.select.typeBar == 'couple') {
                const data = tempMonth.find((temp) => temp.monthIndex == labelMonth.getMonth());
                data.value1 = element.value1;
                data.value2 = element.value2;
              }

            }  else {
              if (this.select.typeBar == 'single') {
                const data = tempMonth.find((temp) => temp.date == element.dates);

                if (data) {
                  data.value1 = element.value1;
                }
              }

              if (this.select.typeBar == 'couple') {
                const data = tempMonth.find((temp) => temp.date == element.dates);

                if (data) {
                  data.value1 = element.value1;
                  data.value2 = element.value2;
                }
              }
            }
         });

        tempMonth.forEach(element => {
          value1.push(element.value1);
          label.push(element.lableMonth);
          if (this.select.typeBar == 'couple') {
            value2.push(element.value2);
          }    
        });

        if (this.select.datetype == 'D' && this.select.period == 0 && this.dataShowChart != undefined) {
          this.sumQty = this.dataChart[0].value1;
        }

        this.dataShowChart = {
          label: label,
          value1: value1,
          value2: value2
        };
        
        if (this.select.datetype == 'D') {
          if (this.select.period == 0 || this.select.period == 1) {
            this.sumQty = this.dataChart[0].value1;
            this.saleShow = true;
          } else {
            this.saleShow = false;
            this.createChart(this.dataShowChart, this.decimal);
            if (this.select.typeBar == 'couple') {
              this.createChartCouple(this.dataShowChart);
            }
          }
        }else{
          this.createChart(this.dataShowChart, this.decimal);
          if(this.select.typeBar == 'couple'){
            this.createChartCouple(this.dataShowChart);
          }   
          this.saleShow = false;
        }
  }
}

 //ChartSingle
  createChart(data, decimal) {
    this.chartData = {
      labels: data.label,
      datasets: [{
        backgroundColor: '#ce2e6c',
        data: data.value1,
        fill: false,
        borderColor: '#ce2e6c'}],
    };

    this.options = {
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            let value = parseFloat(tooltipItem.yLabel).toFixed(decimal);
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            userCallback: function(value, index, values) {
              value = value.toString();
              value = value.split(/(?=(?:...)*$)/);
              value = value.join(',');
              return value;
          }
          }
        }],
        xAxes: [{
          gridLines : {
            color: 'rgb(64,64,64)',
            display: false
          },
          ticks: {
            fontColor: 'rgb(64,64,64)',
          }
        }]
      }
    };
  }

 //ChartCouple
createChartCouple(chartData) {
  this.chartData = {
    labels: chartData.label,
    datasets: [
        {
          label: 'รับเข้า',
          fontColor: 'rgb(64,64,64)',
          backgroundColor: 'rgb(0,55,65)',
          data: chartData.value1,
          fill: false,
          borderColor: 'rgb(0,55,65)'
        },
        {
          label: 'ส่งออก',
          backgroundColor: 'rgb(245,9,145)',
          data: chartData.value2,
          fill: false,
          borderColor: 'rgb(245,9,145)'
      },
    ],
    },
    this.options = {
      legend: {
        position: 'top',
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            userCallback: function(label) {
              if (Math.floor(label) === label) {
                  return label;
              }
            },
            suggestedMin: 0,
          }
        }]
      }
   };
}

}
