import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss']
})
export class GroupCardComponent implements OnInit {
  /* Example send data to component
    file .ts
     this.tempData = [
        {
          'header': string,
          'sales': number,
          'icon': string,
        }
     ];
     file .html
     <app-group-card [tempData]="tempData"></app-group-card>
  */
  @Input()tempData: any;
  constructor(
    private translateService:TranslateService
  ) {
  }

  ngOnInit() {}

}
