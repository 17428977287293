import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ItmRoutingModule } from './itm-routing.module';
import { Itmitm100Component } from './itmitm100/itmitm100.component';
import { Itmitm200Component } from './itmitm200/itmitm200.component';

import { SharedModule } from '../shared/shared.module';

// import { ThaiDatePipe } from '../shared/pipes/thaidate.pipe';

@NgModule({
  declarations: [
    Itmitm100Component,
    Itmitm200Component,
    // ThaiDatePipe
  ],
  imports: [
    CommonModule,
    ItmRoutingModule,
    SharedModule
  ]
})
export class ItmModule { }
