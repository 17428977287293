import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Upload100Component } from './upload/upload100/upload100.component';
import { Upload200Component } from './upload/upload200/upload200.component';
import { Upload300Component } from './upload/upload300/upload300.component';


const routes: Routes = [
  { path: 'upload/upload100', component:  Upload100Component },
  { path: 'upload/upload200', component:  Upload200Component },
  { path: 'upload/upload300', component:  Upload300Component }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UplRoutingModule { }
