import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { DecimalData, Excexc200Model, exchangeRateResponse, exchangeRequest, FormModel } from '../model/excexc200-model';

@Injectable({
  providedIn: 'root'
})
export class Excexc200Service {

  private URL: string = `${this.evmService.getApiUrl()}/exc/excexc` as string;
  excexc200: Excexc200Model = {} as Excexc200Model;

  constructor(private http: HttpClient,
    private evmService: EnvironmentService,
    private autService: AuthenticationService,
    private fb: FormBuilder) { 
    this.init();
  } 

  init(): void {
    this.excexc200.wmsCountry = [];
    this.excexc200.wmsExcCountry = [];
    this.excexc200.selectedWmsCountry = [];
    this.excexc200.selectedWmsExcCountry = [];
    this.excexc200.wmsCurrency = [];
    this.excexc200.selectedWmsCurrency = [];
    this.excexc200.selectedWmsExcCurrency = [];
    this.excexc200.flagExc = true;
    this.excexc200.parameterDecimal = [];
    this.excexc200.parameterDecimalExc = [];
    this.excexc200.method;
    this.excexc200.roundToDecimal;
    this.excexc200.decimal;
    this.excexc200.methodExc;
    this.excexc200.roundToDecimalExc;
    this.excexc200.decimalExc;
    this.excexc200.decimalData = {} as DecimalData;
    this.excexc200.decimalExcData = {} as DecimalData;
    this.excexc200.typeDecimalPurchase = '1';
    this.excexc200.typeDecimalSelling = '2';
    this.excexc200.typeDecimalRoundMethod = '3';
    this.excexc200.typeDecimalRoundToValue = '4';
    this.excexc200.exchangeRequest = {} as exchangeRequest;
    this.excexc200.user = {} as AuthDataModel;
    this.excexc200.currentDateStart = new Date();
    this.excexc200.currentDateEnd;
    this.excexc200.exchangeRateResponse = {} as exchangeRateResponse;
    this.excexc200.flagEditAndSave = true;
    this.excexc200.form_model = {} as FormModel;
    this.excexc200.form_model.gridExchangeForm = this.fb.group({
      exccountry: [null, Validators.required],
      exccurrency: (null),
      excrate: [null, Validators.required],
      excexccountry: [null, Validators.required],
      excexccurrency: (null),
      excexcrate: [null, Validators.required],
      excsdate: [null, Validators.required],
      excedate: [null, Validators.required]
    });
    this.excexc200.checkFlagInput = true;
  }

  get load(): Excexc200Model {
      return this.excexc200;
  }

  saveExchangeRate(exchangeRateRequest: exchangeRequest):Observable<exchangeRateResponse> {    
    const URL: string = `${this.URL}/saveExchangeRate` as string;
    return this.http.post<exchangeRateResponse>(URL, exchangeRateRequest, { headers: this.autService.tokenHeader()});
  }

}
