import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateCalendar'
})
export class TranslateCalendarPipe implements PipeTransform {
  language: string;
  calendar: any;
  constructor() {
    this.language = localStorage.getItem('languageData') as string;
  }
  transform(): any {
    if (this.language == 'TH') {
      this.calendar = {
        thai: true, // Year and output thai format.
        firstDayOfWeek: 0,
        dayNames: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
        dayNamesShort: ['อา', 'จัน', 'อัง', 'พุธ', 'พฤ', 'ศุก', 'เสา'],
        dayNamesMin: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
        monthNames: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
        monthNamesShort: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
        today: 'วันนี้',
        clear: 'ล้างค่า',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'สป',
      };
    } else {
      this.calendar = {
        firstDayOfWeek: 0,
        dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        today: 'Today',
        clear: 'Clear',
        dateFormat: 'dd/mm/yy',
        weekHeader: 'Wk'
      };
    }
    return this.calendar;
  }

}
