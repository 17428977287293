import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { ParameterHeader, OptionGroup, Admprm100Model, FormModel } from '../../model/admprm/admprm100.model';


@Injectable({
    providedIn: 'root'
})
export class Admprm100Service {

    PRM_URL: string = `${this.evmService.getApiUrl()}/prm` as string;
    admprm100: Admprm100Model = {} as Admprm100Model;
    
    constructor(private http: HttpClient,
        private evmService: EnvironmentService,
        private autService: AuthenticationService,
        private fb: FormBuilder) { 
        this.init();
    }

    init(): void {
        this.admprm100.parameterHeaderList = [];
        this.admprm100.parameterHeaderListFilter = [];
        this.admprm100.parameterHeaderGroupList = [];
        this.admprm100.flagSearch = false;
        this.admprm100.firstPage = 1;
        this.admprm100.criteriaSearchGrid = { pmhtbhtbno: '', pmhtbhgroup: '', pmhtbhedesc: '', pmhtbhldesc: '' };
        this.admprm100.form_model = {} as FormModel;
        this.admprm100.form_model.searchGridGroupForm = this.fb.group({
            pmhtbhtbno: [null, Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
            pmhtbhgroup: (null),
            pmhtbhedesc: (null),
            pmhtbhldesc: (null)
        });
        this.admprm100.flagEmptyTable = true;
        this.admprm100.smartSearch = '';
        this.admprm100.parameterHeaderListReport = [];
    }

    get load(): Admprm100Model {
        return this.admprm100;
    }

    getParameterHeader(): Observable<ParameterHeader> {
        const URL = `${this.PRM_URL}/getvprmtblhdrAll`;
        return this.http.get<ParameterHeader>(URL, {headers: this.autService.tokenHeader()});
    }

    getParameterHeaderGroup(): Observable<OptionGroup> {
        const URL = `${this.PRM_URL}/getvprmtblhdrGroup`;
        return this.http.get<OptionGroup>(URL, {headers: this.autService.tokenHeader()});
    }

    getParameterHeaderByCritiria(parameterHeaderCritiriaRequest): Observable<ParameterHeader> {
        const URL = `${this.PRM_URL}/getSearchCritiriavprmtblhdr`;
        return this.http.post<ParameterHeader>(URL, parameterHeaderCritiriaRequest, {headers: this.autService.tokenHeader()});
    }

}
