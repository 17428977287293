import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { sellerDetailModel } from 'src/app/ser/serser/serser200/model/serser200.model';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class SellerService {

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService) { }

  private SERSER_URL: string = `${this.environmentService.getApiUrl()}/ser/serser` as string;

    // inquiry
    getSellerInquiry(sellerRequest) {    
      const URL: string = `${this.SERSER_URL}/getSellerInquiry` as string;
      return this.http.post(URL ,sellerRequest,{ headers: this.authenticationService.tokenHeader() });
    }

    // Detail
    getSellerDetail(sellerCode): Observable<sellerDetailModel> {    
      const URL: string = `${this.SERSER_URL}/getSeller/${sellerCode}` as string;
      return this.http.get<sellerDetailModel>(URL, {headers: this.authenticationService.tokenHeader()});
    }

}
