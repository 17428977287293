import { Component, OnInit } from '@angular/core';
import { GetHistoryRequest, ResponseHistory, ResponseHistoryReject, Upload200Model } from './model/upload200.model';
import { Upload200Service } from './service/upload200.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { DropDownListModel } from 'src/app/shared/model/dropdownlist.model';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { UploadService } from 'src/app/shared/services/upload.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { TranslateService } from '@ngx-translate/core';
import { parameter_entrycode_ihitem, parameter_entrycode_item, parameter_entrycode_saleprice } from 'src/app/shared/constant/parameter.constant';
import { ItemInhouseUploadRejectResponse, ItemUploadRejectResponse } from 'src/app/shared/model/upload.model';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-upload200',
  templateUrl: './upload200.component.html',
  styleUrls: ['./upload200.component.scss']
})
export class Upload200Component implements OnInit {

  page_model: Upload200Model;
  historyRequest = {} as GetHistoryRequest;
  responseHistory: Array<ResponseHistory> = [] as Array<ResponseHistory>;
  responseHistoryRj: Array<ResponseHistoryReject> = [] as Array<ResponseHistoryReject>
  tempResponseHistory: Array<ResponseHistory> = [];
  salePriceSelected: string;

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  constructor( private parameterService: ParameterService,
    private upload200Service: Upload200Service,
    private uploadService: UploadService,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService
   ) {
    this.upload200Service.clearFromControll();
    this.parameterUpload();
    this.page_model = this.upload200Service.load;
  }

  ngOnInit() {

    this.page_model.form_model.advance_search_form.valueChanges.subscribe( val => {
      if(this.page_model.form_model.advance_search_form.valid) {
        this.advanceSearchFunction();
      } else {
        if(this.tempResponseHistory.length > 0) {
          this.tempResponseHistory = this.responseHistory;
        }
      }
    });
  }

  parameterUpload(){
      this.parameterService.getvparameterDetailToLable(107).subscribe((parameter: Array<DropDownListModel>) => {
        this.page_model.data_list = parameter;
      });
  }

  clearSearch() {
    this.page_model.searchFormSummitFlag = true;
    this.page_model.form_model.groupHistory.reset();
    this.responseHistory = [];
    this.tempResponseHistory =[];
  }

  advanceSearchFunction() {
    if(this.page_model.form_model.advance_search_form.valid) {
      const key_search: string = this.page_model.form_model.advance_search_form.get('key').value;
        this.responseHistory = this.tempResponseHistory.filter((el: ResponseHistory) => {
          const statusDesc: string = this.statusDescription(el.status);
          const concatValue: string = `${el.end_date}${el.error_code}${el.error_message}${el.file_name}${el.row_bad}${el.row_fail}${el.row_success}${el.start_date}${statusDesc}${el.total_row}${el.username_upload}`;
          return concatValue.toLowerCase().indexOf(key_search.toLowerCase()) !== -1;
        });
        this.page_model.firstRow = 0;
    }
  }

  filterData() {

    this.historyRequest.langCode = this.page_model.country;
    this.historyRequest.uploadName = this.page_model.form_model.groupHistory.controls.uploadCode.value.parameterDetailPK.pmdtbdtbdentcd;
    if (this.page_model.form_model.groupHistory.controls.startendDate.value != null) {
      this.historyRequest.startDate = moment(this.page_model.form_model.groupHistory.controls.startendDate.value[0]).format('DD/MM/YYYY');
        if(this.page_model.form_model.groupHistory.controls.startendDate.value[1]){
          this.historyRequest.endDate = moment(this.page_model.form_model.groupHistory.controls.startendDate.value[1]).format('DD/MM/YYYY');
        }else{
          this.historyRequest.endDate = '';
        }
    } else {
      this.historyRequest.startDate = '';
      this.historyRequest.endDate = '';
    }

    this.uploadService.getUploadHistory(this.historyRequest).subscribe(res=>{
    this.responseHistory = JSON.parse(JSON.stringify(res.data));
    this.tempResponseHistory = this.responseHistory;
    });
  }

  checkSeachBtn(){

    if(this.page_model.form_model.groupHistory.controls.uploadCode.value){
      this.page_model.searchFormSummitFlag = false;
    }else{
      this.page_model.searchFormSummitFlag = true;
    }
  }

  printReject(fileName: string) {
    if(parameter_entrycode_saleprice == this.page_model.form_model.groupHistory.controls.uploadCode.value.parameterDetailPK.pmdtbdtbdentcd) {
      this.exrportSalePriceHistory(fileName);
    } else if (parameter_entrycode_item == this.page_model.form_model.groupHistory.controls.uploadCode.value.parameterDetailPK.pmdtbdtbdentcd) {
      this.exportItemHistory(fileName);
    } else if (parameter_entrycode_ihitem == this.page_model.form_model.groupHistory.controls.uploadCode.value.parameterDetailPK.pmdtbdtbdentcd) {
      this.exportItemInhouseHistory(fileName);
    }

  }

  exrportSalePriceHistory(fileName : string) {
    let exportOrder = [];
    this.uploadService.getUploadHistoryDetail(this.page_model.country,fileName).subscribe(res=>{
      this.responseHistoryRj = JSON.parse(JSON.stringify(res.data));
      this.responseHistoryRj.forEach(data=>{
        exportOrder.push({
          country_code: data.country_code,
          end_date: data.end_date,
          error_message: data.error_message,
          error_message_number: data.error_message_number,
          file_name: data.file_name,
          free_shipment_flag: data.free_shipment_flag,
          full_price_amount: data.full_price_amount,
          klangone_item_code: data.klangone_item_code,
          sale_channel_code: data.sale_channel_code,
          sale_price_amount: data.sale_price_amount,
          seller_code: data.seller_code,
          seller_item_code: data.seller_item_code,
          start_date: data.start_date,
          // status: this.statusDescription(data.status),
        });
      });

      let subTitle = [];
      let header: any = {
        country_code: this.translateService.instant('UPLOAD200.M00033'),
        sale_channel_code: this.translateService.instant('UPLOAD200.M00034'),
        seller_code: this.translateService.instant('UPLOAD200.M00035'),
        klangone_item_code: this.translateService.instant('UPLOAD200.M00036'),
        seller_item_code: this.translateService.instant('UPLOAD200.M00041'),
        start_date: this.translateService.instant('UPLOAD200.M00022'),
        end_date: this.translateService.instant('UPLOAD200.M00023'),
        full_price_amount: this.translateService.instant('UPLOAD200.M00037'),
        sale_price_amount: this.translateService.instant('UPLOAD200.M00038'),
        free_shipment_flag: this.translateService.instant('UPLOAD200.M00039'),
        // status: this.translateService.instant('UPLOAD200.M00024'),
        file_name: this.translateService.instant('UPLOAD200.M00021'),
        error_message_number: this.translateService.instant('UPLOAD200.M00040'),
        error_message: this.translateService.instant('UPLOAD200.M00025'),
      };
      this.exportExcelService.exportAsExcelFile(exportOrder, this.historyRequest.uploadName, header, null, subTitle);
    });
  }

  exportItemHistory(file_name : string) {
    let subTitle = [];
    let header = {
      product_code_from_seller: this.translateService.instant('UPLOAD200.M00042'),
      klangOne_item_code: this.translateService.instant('UPLOAD200.M00036'),
      item_english_name: this.translateService.instant('UPLOAD200.M00043'),
      item_local_name: this.translateService.instant('UPLOAD200.M00044'),
      item_short_name: this.translateService.instant('UPLOAD200.M00045'),
      brand: this.translateService.instant('UPLOAD200.M00046'),
      sun_category_code: this.translateService.instant('UPLOAD200.M00047'),
      item_type_code: this.translateService.instant('UPLOAD200.M00048'),
      barcode: this.translateService.instant('UPLOAD200.M00049'),
      seller_code: this.translateService.instant('UPLOAD200.M00035'),
      stock_unit: this.translateService.instant('UPLOAD200.M00050'),
      product_specification: this.translateService.instant('UPLOAD200.M00051'),
      gross_weight: this.translateService.instant('UPLOAD200.M00052'),
      width: this.translateService.instant('UPLOAD200.M00053'),
      length: this.translateService.instant('UPLOAD200.M00054'),
      height: this.translateService.instant('UPLOAD200.M00055'),
      order_unit: this.translateService.instant('UPLOAD200.M00056'),
      sku_per_order_unit: this.translateService.instant('UPLOAD200.M00057'),
      packaging_size: this.translateService.instant('UPLOAD200.M00058'),
      return_from_customer_code: this.translateService.instant('UPLOAD200.M00059'),
      return_to_seller: this.translateService.instant('UPLOAD200.M00060'),
      pre_order_Item: this.translateService.instant('UPLOAD200.M00061'),
      serial_no: this.translateService.instant('UPLOAD200.M00062'),
      picking_strategy: this.translateService.instant('UPLOAD200.M00063'),
      warehouse_code: this.translateService.instant('UPLOAD200.M00064'),
      minimum_stock: this.translateService.instant('UPLOAD200.M00065'),
      storage_bin_code: this.translateService.instant('UPLOAD200.M00066'),
      file_name: this.translateService.instant('UPLOAD200.M00021'),
      error_no: this.translateService.instant('UPLOAD200.M00040'),
      error_msg: this.translateService.instant('UPLOAD200.M00025'),
      usage_day: this.translateService.instant('UPLOAD200.M00071')
    };
    let exportDataList = [];

    this.uploadService.getItemUploadReject(file_name).subscribe((itemUploadRejectList: Array<ItemUploadRejectResponse>) => {
      console.log('itemUploadRejectList', itemUploadRejectList);
      itemUploadRejectList.forEach((itemUploadReject: ItemUploadRejectResponse) => {
        let exportData = {
          product_code_from_seller: itemUploadReject.product_code_from_seller,
          klangOne_item_code: itemUploadReject.system_item_code,
          item_english_name: itemUploadReject.item_english_desc,
          item_local_name: itemUploadReject.item_local_desc,
          item_short_name: itemUploadReject.item_short_name,
          brand: itemUploadReject.item_brand,
          sun_category_code: itemUploadReject.merchandise_code,
          item_type_code: itemUploadReject.item_type_code,
          barcode: itemUploadReject.item_barcode,
          seller_code: itemUploadReject.product_owner_code,
          stock_unit: itemUploadReject.stock_unit,
          product_specification: itemUploadReject.product_specification,
          gross_weight: itemUploadReject.gross_weight,
          width: itemUploadReject.width,
          length: itemUploadReject.length,
          height: itemUploadReject.height,
          order_unit: itemUploadReject.order_unit,
          sku_per_order_unit: itemUploadReject.sku_per_order_unit,
          packaging_size: itemUploadReject.packaging_size,
          return_from_customer_code: itemUploadReject.customer_return_flag,
          return_to_seller: itemUploadReject.return_seller_flag,
          pre_order_Item: itemUploadReject.pre_order_item_flag,
          serial_no: itemUploadReject.serial_item_flag,
          picking_strategy: itemUploadReject.pick_strategy,
          warehouse_code: itemUploadReject.warehouse,
          minimum_stock: itemUploadReject.minimum_stock,
          storage_bin_code: itemUploadReject.fix_bin,
          file_name: itemUploadReject.file_name,
          error_no: itemUploadReject.error_no,
          error_msg: itemUploadReject.error_msg,
          usage_day: itemUploadReject.usage_day
        };

        exportDataList.push(exportData);
      });

      this.exportExcelService.exportAsExcelFile(exportDataList, this.historyRequest.uploadName, header, null, subTitle);
    });
  }

  exportItemInhouseHistory(file_name : string) {
    let subTitle = [];
    let header = {
      klangOne_item_code_of_item_in_house_item: this.translateService.instant('UPLOAD200.M00067'),
      produced_quantity: this.translateService.instant('UPLOAD200.M00068'),
      klangOne_item_code_of_commercial_item: this.translateService.instant('UPLOAD200.M00069'),
      quantity_used: this.translateService.instant('UPLOAD200.M00070'),
      file_name: this.translateService.instant('UPLOAD200.M00021'),
      error_no: this.translateService.instant('UPLOAD200.M00040'),
      error_msg: this.translateService.instant('UPLOAD200.M00025')
    };
    let exportDataList = [];

    this.uploadService.getItemInhouseUploadReject(file_name).subscribe((itemInhouseUploadRejectList: Array<ItemInhouseUploadRejectResponse>) => {

      itemInhouseUploadRejectList.forEach((itemInhouseUploadReject: ItemInhouseUploadRejectResponse) => {
        let exportData = {
          klangOne_item_code_of_item_in_house_item: itemInhouseUploadReject.item_inhouse_code,
          produced_quantity: itemInhouseUploadReject.produce_qty,
          klangOne_item_code_of_commercial_item: itemInhouseUploadReject.item_component_code,
          quantity_used: itemInhouseUploadReject.produce_qty,
          file_name: itemInhouseUploadReject.file_name,
          error_no: itemInhouseUploadReject.error_no,
          error_msg: itemInhouseUploadReject.error_msg
        };

        exportDataList.push(exportData);
      });

      this.exportExcelService.exportAsExcelFile(exportDataList, this.historyRequest.uploadName, header, null, subTitle);
    });
  }

  statusDescription(status: string): string {
    let statusDescription: string = '-' as string;
    if (status === '0') {
      statusDescription = this.translateService.instant('UPLOAD200.M00030');
    } else if (status === '1') {
      statusDescription = this.translateService.instant('UPLOAD200.M00031');
    } else if (status === '2') {
      statusDescription = this.translateService.instant('UPLOAD200.M00032');
    } else if (status === '3') {
      statusDescription = this.translateService.instant('UPLOAD200.M00025');
    } else {
      statusDescription = '-';
    }
    return statusDescription;
  }

  customSort(event) {

      if(event.field === 'start_date'||event.field === 'end_date'){

        event.data.sort((data1, data2) => {
          let value1 = data1[event.field];
          let value2 = data2[event.field];
          var date1 = moment(value1, 'DD/MM/YYYY').toDate();
          var date2 = moment(value2, 'DD/MM/YYYY').toDate();
          let result = (date1.getTime() < date2.getTime()) ? -1 : (date1.getTime() > date2.getTime()) ? 1 : 0;
          return (event.order * result);
        });
      }else{
      event.data.sort((data1, data2) => {
          let value1 = data1[event.field];
          let value2 = data2[event.field];
          let result = null;

          if (value1 == null && value2 != null)
              result = -1;
          else if (value1 != null && value2 == null)
              result = 1;
          else if (value1 == null && value2 == null)
              result = 0;
          else if (typeof value1 === 'string' && typeof value2 === 'string')
              result = value1.localeCompare(value2);
          else
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

          return (event.order * result);
      });
    }
  }

  downloadBadFile(fileName){
    let fileNameBad;
    fileNameBad = fileName.split('.').slice(0, -1).join('.')+'.log';
    this.uploadService.downloadBadFile(fileNameBad,this.historyRequest.uploadName).subscribe(res=>{
      const blob = new Blob([res], { type: 'text/plain' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = fileNameBad;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
    });

  }
}
