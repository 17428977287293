import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormModel, Upload100Model } from '../model/upload100.model';

@Injectable({
  providedIn: 'root'
})
export class Upload100Service {

  upload100: Upload100Model = {} as Upload100Model;

  constructor(private fb: FormBuilder) {
    this.inti();
  }

  inti(): void {
    this.upload100.file = null;
    this.upload100.form_model = {} as FormModel;
    this.upload100.form_model.upload_form = this.fb.group({
      format_file: [null, Validators.required],
      upload_date: (null)
    });
    this.upload100.format_file_option;
    this.upload100.to_day = formatDate(new Date(), 'dd/MM/yyyy', 'en-US');
    this.upload100.flag_upload_date = true;
    this.upload100.flag_upload_file = true;
    this.upload100.format_file = null;
    this.upload100.flag_browse_file = true;
  }

  get load(): Upload100Model {
    return this.upload100;
  }
}
