import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CusRoutingModule } from './cus-routing.module';
import { Cusext100Component } from './cusext/cusext100/cusext100.component';
import { SharedModule } from '../shared/shared.module';
import { Cuscus100Component } from './cuscus/cuscus100/cuscus100.component';
import { Cuscus200Component } from './cuscus/cuscus200/cuscus200.component';
import { Cuscus300Component } from './cuscus/cuscus300/cuscus300.component';


@NgModule({
  declarations: [Cusext100Component, Cuscus100Component, Cuscus200Component, Cuscus300Component],
  imports: [
    CommonModule,
    CusRoutingModule,
    SharedModule
  ]
})
export class CusModule { }
