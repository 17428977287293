import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';

import { Acccdn100Component } from './acccdn/acccdn100/acccdn100.component';
import { Acccdn200Component } from './acccdn/acccdn200/acccdn200.component';
import { Accsda100Component } from './accsda/accsda100/accsda100.component';

const routes: Routes = [
  { path: 'acccdn/acccdn100', component: Acccdn100Component },
  { path: 'acccdn/acccdn200', component: Acccdn200Component },
  { path: 'acccdn/acccdn200/:creditNoteHeaderId', component: Acccdn200Component },
  { path: 'accsda/accsda100', component: Accsda100Component },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AccRoutingModule { }
