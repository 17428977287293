import { Component, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Admpro100Service } from 'src/app/adm/service/admpro/admpro100.service';
import { ProfileModelPage, ProfileData, ProfileRequest } from 'src/app/adm/model/admpro/admpro100.model';
import { TranslateService } from '@ngx-translate/core';
import { AdmproLocalStorageService } from 'src/app/adm/service/admpro/admproLocalStorage.service';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-admpro100',
  templateUrl: './admpro100.component.html',
  styleUrls: ['./admpro100.component.scss']
})
export class Admpro100Component extends PermissionComponent {

  public profilePage: ProfileModelPage = {} as ProfileModelPage;
  public profileData: Array<ProfileData> = [] as Array<ProfileData>
  public profileTable: Array<ProfileData> = [] as Array<ProfileData>
  public profileRequest: ProfileRequest = {} as ProfileRequest;
  public profilePrint: Array<ProfileRequest> = [] as Array<ProfileRequest>;
  public inputSearchAll: string;
  public dataNotFoundGrid: boolean;
  public toggleSearch: boolean;

  searchProfileDataGroupForm = new FormGroup({
    profileCode: new FormControl(''),
    profileDesc: new FormControl(''),
  });

  constructor(private profileservice: Admpro100Service,
    private translateService: TranslateService,
    private exportExcelService: ExportExcelService,
    localStorageService: AdmproLocalStorageService,
    private router: Router,
    renderer2:Renderer2) {
    super('P01002',renderer2);
    this.profilePage.disableBtuClear = true;
    this.profilePage.disabledBtuPrinReport = true;
    this.profilePage.firstRow = 0;
    localStorageService.clearProfileData();
  }

  ngOnInit() {
    this.dataNotFoundGrid = true;
    this.searchProfileDataGroupForm.valueChanges.subscribe(data => {
      this.profileRequest = data;

      if (data.profileCode || data.profileDesc) {
        this.profilePage.disableBtuClear = false;
        this.profilePage.disableBtuSearch = false;
      } else {
        this.profilePage.disableBtuClear = true;
      }
    })

  }

  searchDataGroupForm() {

    this.profileservice.getProfileInquiry(this.profileRequest).subscribe((response: any) => {
      this.profileData = response;
      this.profileTable = this.profileData;
      this.profileTable.sort((a, b) => (a.profileCode > b.profileCode) ? 1 : -1);

      if (this.profileTable.length == 0) {
        this.dataNotFoundGrid = true;
        this.profilePage.disabledBtuPrinReport = true;
      } else {
        this.dataNotFoundGrid = false;
        this.profilePage.disabledBtuPrinReport = false;
        this.profilePage.firstRow = 0;
      }

    });

    this.profilePage.disableBtuSearch = true;

  }

  clearData() {
    this.searchProfileDataGroupForm.reset();
    this.profileTable = this.profileData;
    this.profilePage.disableBtuSearch = false;
  }

  searchAuto() {
    this.profileTable = this.profileData;
    this.profileTable = this.profileTable.filter(data => {
      return (
        (data.profileCode.toString().toLowerCase().indexOf(this.inputSearchAll.toLowerCase()) > -1) ||
        (data.profileDesc.toString().toLowerCase().indexOf(this.inputSearchAll.toLowerCase()) > -1)
      );
    });

    if (this.profileTable.length == 0) {
      this.dataNotFoundGrid = true;
    } else {
      this.dataNotFoundGrid = false;
      this.profilePage.firstRow = 0;
    }
  }

  createProfile() {
    this.router.navigate(['admpro/admpro200']);
  }

  exportReport(){

    this.profileTable.forEach(data => {
      this.profilePrint.push({
        profileCode:data.profileCode,
        profileDesc:data.profileDesc,
      })
    });

    let subTitle = [];
    subTitle.push([this.translateService.instant('ADMPRO100.M00004')]);
    let header: any = {
      profileCode: this.translateService.instant('ADMPRO100.M00005'),
      profileDesc: this.translateService.instant('ADMPRO100.M00006'),
    }

    this.exportExcelService.exportAsExcelFile(this.profilePrint, this.translateService.instant('ADMPRO100.M00004'), header, null, subTitle);

  }

}
