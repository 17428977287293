import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Empagt100Component } from './empagt/empagt100/empagt100.component';
import { Empagt200Component } from './empagt/empagt200/empagt200.component';
import { Empcal100Component } from './empcal/empcal100/empcal100.component';
import { Empcal200Component } from './empcal/empcal200/empcal200.component';

const routes: Routes = [
  {path: 'empagt/empagt100', component: Empagt100Component},
  {path: 'empagt/empagt200', component: Empagt200Component},
  {path: 'empagt/empagt200/:agentCode', component: Empagt200Component},
  {path: 'empcal/empcal100', component: Empcal100Component},
  {path: 'empcal/empcal200/:callCenterCode', component: Empcal200Component}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmpRoutingModule { }
