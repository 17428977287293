import { Component, Renderer2 } from '@angular/core';
import { DimToActPage, UserData } from "src/app/adm/model/admusr/admusr300.model";
import { TranslateService } from '@ngx-translate/core';
import { AdmusrLocalStorageService } from "src/app/adm/service/admusr/admusrLocalStorage.service";
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { Admusr200Service } from "src/app/adm/service/admusr/admusr200.service";
import { MessageService } from "primeng/api";

@Component({
  selector: 'app-admusr300',
  templateUrl: './admusr300.component.html',
  styleUrls: ['./admusr300.component.scss']
})
export class Admusr300Component extends PermissionComponent {

  public userData: UserData = {} as UserData;
  public dimByActionData: DimToActPage = {} as DimToActPage;
  public tempDimByActionData: DimToActPage = {} as DimToActPage;
  public dimensionType: string;
  public dimensionName: string;
  public checkDirty: boolean;
  public disableSave:boolean;


  constructor(
    private translateService: TranslateService,
    private localStorageService: AdmusrLocalStorageService,
    private messageService: MessageService,
    private admusr200Service: Admusr200Service,
    renderer2: Renderer2) {
    super('P01003', renderer2);
    this.userData = this.localStorageService.getUserData();
    this.dimByActionData = this.localStorageService.getUserData().dimToActPage;
    this.tempDimByActionData = this.localStorageService.getUserData().dimToActPage;
    this.dimensionType = this.dimByActionData.dimType == 'saleChannel' ? 'ADMUSR300.M00004' : 'ADMUSR300.M00005';
    this.tempDimByActionData.actionData.sort((a, b) => (a.actionId > b.actionId) ? 1 : -1);
    this.dimByActionData.actionData.sort((a, b) => (a.actionId > b.actionId) ? 1 : -1);
    this.checkDisableSave();
  }

  saveAction() {

    let checkOrg:boolean 
    this.localStorageService.updateActionByDim(this.dimByActionData.dimType, this.dimByActionData.indexDim, this.dimByActionData.actionData, 'EDITED');
    this.userData = this.localStorageService.getUserData();

    if(this.userData.organization == '200'){
      checkOrg = true;
    }else{
      checkOrg = false;
    }

    if (this.userData.username
      && this.userData.userDesc
      && this.userData.profileId
      && this.userData.userCfPass
      && this.userData.userPass
      && this.userData.organizationLev
      && ((checkOrg == true && this.userData.organization) || (checkOrg == false && !this.userData.organization))) {

      if (this.dimByActionData.userId == null) {

        //case save user    
        this.admusr200Service.saveUser(this.userData).subscribe((res: number) => {
          if (res) {
            this.userData.userId = res;
            this.userData.dimToActPage.userId = res;
            this.checkDirty = false;
            this.userData.checkDirty = false;
            this.localStorageService.addUserData(this.userData);

            this.messageService.add({
              key: 'save',
              severity: 'success',
              detail: "ADMUSR300.M00006",
            });

            setTimeout(() => {
              window.location.reload();
            }, 1500);

          } else {
            this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMUSR300.M00008', detail: 'ADMUSR300.M00007' });
          }
        });
      } else {

        // case update user
        this.admusr200Service.updateUser(this.userData).subscribe((res: number) => {
          if (res) {
            this.userData.userId = res;
            this.userData.dimToActPage.userId = res;
            this.checkDirty = false;
            this.userData.checkDirty = false;
            this.localStorageService.addUserData(this.userData);
            this.messageService.clear();
            this.messageService.add({
              key: 'save',
              severity: 'success',
              detail: 'ADMUSR300.M00006',
            });

            setTimeout(() => {
              window.location.reload();
            }, 1500);

          } else {
            this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMUSR300.M00008', detail: 'ADMUSR300.M00007' });
          }
        });
      }
    } else {

      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMUSR300.M00008', detail: 'ADMUSR300.M00009' });
    }
  }

  switchAction() {
    this.checkDirty = JSON.stringify(this.dimByActionData.actionData)==JSON.stringify(this.tempDimByActionData.actionData);
    if(JSON.stringify(this.dimByActionData.actionData) == JSON.stringify(this.tempDimByActionData.actionData)){
      this.checkDirty = false;
    }
    else if(JSON.stringify(this.dimByActionData.actionData) != JSON.stringify(this.tempDimByActionData.actionData)){
      this.checkDirty = true;
    }   
    this.checkDisableSave();
  }

  clickAfterAlert() {
    this.messageService.clear();
  }

  backBtn() {

    if (this.checkDirty == true) {
      this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'ADMUSR300.M00015', detail: 'ADMUSR300.M00012' });
    } else {
      this.setPath();
    }
  }

  clickOkBack() {
    this.setPath();
  }

  setPath() {
    if (this.dimByActionData.userId == null) {
      this.localStorageService.updateActionByDim(null, null, null, 'EDITED');
      window.location.href = 'admusr/admusr200';
    } else {
      window.location.href = 'admusr/admusr200/' + this.dimByActionData.userId;
    }
  }

  checkDisableSave(){
    if(this.userData.checkDirty == true || this.checkDirty == true){
      this.disableSave = false;
    }else{
      this.disableSave = true;
    }
  }

}
