import { Component, Renderer2 } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { discountOrder, CustomerModel, ConfirmAndCancelSO, ItemSaleOrderModel, SaleOrderDetail, ReceiverModel, ShippmentModel, PaymentModel, PackingModel, PaymentTable, Logistic, SaveUUIDSoLanding, TempDDL } from './model/sales-order-maintenance-model';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SalesOrderrService } from 'src/app/shared/services/sales-order-service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { DecimalPipe } from '@angular/common';
import { PermissionDimensionComponent } from 'src/app/shared/components/permission-dimension/permission-dimension.component';
import { paymentDetail } from 'src/app/fna/model/payment-data-model';
import * as moment from 'moment';
import { PaymentDetailRequest } from '../../model/sales-order-maintenance-model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import * as uuid from 'uuid';
import { SoForBuyerService } from 'src/app/shared/services/so-landing.service';
import BigNumber from 'bignumber.js';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { ItemPriceRequest } from 'src/app/shared/components/search-item/model/info-item-model';
import { ThaiDatePipe } from 'src/app/shared/pipes/thaidate.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';
import { CustomerInquiryDetailModel, CustomerInquiryRequest } from 'src/app/shared/model/customer-inquiry.model';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { parameterDetail } from 'src/app/cus/cuscus/cuscus200/model/cuscus200.model';

@Component({
  selector: 'app-ord200',
  templateUrl: './ord200.component.html',
  styleUrls: ['./ord200.component.scss']
})
export class Ord200Component extends PermissionDimensionComponent {

  paymentDeatilForm = new FormGroup({
    saleOrderNumber: new FormControl({ value: '', disabled: true }, Validators.required),
    creator: new FormControl({ value: '', disabled: true }, Validators.required),
    country: new FormControl({ value: '', disabled: true }, Validators.required),
    currency: new FormControl({ value: '', disabled: true }, Validators.required),
    paymentChannel: new FormControl(Validators.required),
    bankAccount: new FormControl(Validators.required),
    paymentDate: new FormControl({ value: '', disabled: false }, Validators.required),
    operationDate: new FormControl({ value: '', disabled: true }, Validators.required),
    paymentAmount: new FormControl(Validators.required),
    amountToBePaid: new FormControl({ value: '', disabled: true }, Validators.required),
    remark: new FormControl(),
    paymentImage: new FormControl(),
    arrearage: new FormControl({ value: '', disabled: true })
  });

  public expectedDeliveryDate: string;
  public dataTableMaintenance: Array<ItemSaleOrderModel> = [] as Array<ItemSaleOrderModel>;
  public packOrder: Array<PackingModel> = [] as Array<PackingModel>;
  salesOrder = {} as SaleOrderDetail;
  saveSaleOrder = {} as SaleOrderDetail;
  discountOrder = {} as discountOrder;
  dataCustomer = {} as CustomerModel;
  dataReceiver = {} as ReceiverModel;
  dataTransport = {} as ShippmentModel;
  dataPayment = {} as PaymentModel;
  payDetail = {} as paymentDetail;
  confirmSaleOrder = {} as ConfirmAndCancelSO;
  cancelSaleOrder = {} as ConfirmAndCancelSO;
  paymentDetail = {} as PaymentDetailRequest;
  saveSoLanding = {} as SaveUUIDSoLanding;
  payTable: Array<PaymentTable> = [] as Array<PaymentTable>;
  optionSex: parameterDetail[];
  optionOccupation: parameterDetail[];
  optionNationality: parameterDetail[];

  //----- Default Data -------
  defaultSalesOrder = {} as SaleOrderDetail;
  defaultTransport = {} as ShippmentModel;
  defaultDiscountOrder = {} as discountOrder;
  defaultDataPayment = {} as PaymentModel;

  translateLablePipe = new TranslateLablePipe();

  disable: boolean;
  disableUrl: boolean;
  disableButton: boolean;
  disCountry: boolean;
  disableUUID: boolean = true;
  disableSwitchy: boolean = true;

  disableButtonSaveCus: boolean = true;
  disableButtonSaveConsi: boolean = true;
  disableButtonConsi: boolean = true;
  addItemDisabled: boolean = true;
  backPage: boolean;

  disableButtonEditSave: boolean = true;
  disableButtonSave: boolean = false;
  disableButtonConfirm: boolean = true;
  disableButtonPayment: boolean = true;
  disableButtonCancel: boolean = true;
  disableButtonCustomer: boolean = true;
  chargeCod: boolean;
  disableField: boolean = true;
  disableFieldConsignee: boolean = false;

  disableEditField: boolean = true;
  disableEditFieldItem: boolean = true;

  isChecker: any;
  display: boolean = false;
  saleOrderNo: any;
  countrySo: string;

  displayCustomer: boolean = false;
  displayReceiver: boolean = false;
  checkedSameAddress: boolean = false;
  checkedSameWarehouse: boolean = true;
  checkedSameCMB: boolean = false;
  checkedSameBox: boolean = false;
  disableBank: boolean = false;
  checkedItemQty: boolean = true;

  converted_img: any;
  base64Data: any;
  create_at: String;
  payChannel: any;
  bankTransfer: any;
  hightLight: any;
  sellerCode: string;
  base64textString: String = "";
  warehouse_code: string;
  flow_type_code_param: string;
  flow_type_code: string;
  warehouse_code_view: string;
  customerId: number;
  hiddenButton: Boolean = true;
  row: any;
  editTable: boolean = true;
  nowDate: string;
  valuenull: boolean = false;
  maxDate: Date;
  vSalePrice: boolean;
  flagGetPostcode: string;

  public user = {} as AuthDataModel;
  //dropdown
  public optionCountry: TempDDL[];
  order: MenuItem[];
  warehouse: TempDDL[];
  paymentChannel: TempDDL[];
  movementType: TempDDL[];
  salesChannels: TempDDL[];
  seller: TempDDL[];
  vat: TempDDL[];
  agent: TempDDL[];
  closeOrder: TempDDL[];
  selectedRowData: any;
  salesChannelsCategory: TempDDL[];

  subDistrictCustomer: TempDDL[];
  districtCustomer: TempDDL[];
  provinceCustomer: TempDDL[];

  subDistrictConsignee: TempDDL[];
  districtConsignee: TempDDL[];
  provinceConsignee: TempDDL[];

  paymentCOD: TempDDL[];
  defalutPaymentCOD: TempDDL[];
  uploadedFiles: any[] = [];
  optionChannel: TempDDL[];
  optionBank: TempDDL[];
  optionBankDefalut: TempDDL[];
  displaySavepayment: boolean = false;

  shipmentCompany: TempDDL[];
  shipment = {} as Logistic;
  shipmentItem = [];

  paymentRecordsForm: any;
  dataNotFound: boolean = true;
  dataPaymentNotFound: boolean = true;
  dataShipmentNotFound: boolean = true;
  disablePermission: boolean;
  disableConfirmPermission: boolean;
  disableCancalPermission: boolean;

  disabledGenReport: boolean = true;
  minimumDate = new Date();

  typeDecimalSelling: string = '2';
  typeDecimalRound: string = '4';
  typeDecimalRoundMethod: string = '3';
  decimalSelling: number = 0;
  methodSelling: string = "0";
  roundSelling: number = 0;

  typeMoreThan: string = 'M';
  typeLessThan: string = 'L';
  typeEqual: string = 'E';
  typeValueEqual: string;

  dataTypeDecimalRound: any;
  dataTypeDecimalSelling: any;
  dataTypeDecimalRoundMethod: any;

  uuidSOLanding: string;
  uuidSOLandingLink: string;
  urlSwitchy: string;
  checkUrlSwitchy: string;
  flagSuggest: boolean;
  dataChangeFlag: boolean;
  priceItemRequest: ItemPriceRequest = {} as ItemPriceRequest;
  Country: string;
  countryUse: string;
  warehouseDisFalg: boolean = false;
  sellerDisFlag: boolean = false;
  salesChannelsDisFlag: boolean = false;
  salesChannelsDisFlagCategory: boolean = false;
  movementTypeDisFlag: boolean = false;
  cur_bal_stk: any;
  cur_avai_stk: any;
  payment: any;
  uploadFlag = false as boolean;
  checkItem: boolean = true;
  disableUseWhBox: boolean = false;
  disableFieldCustomer: boolean = true;
  hideSaveCus: boolean = true;
  tempPhoneNumber: string;

  public disableConfirmField = false;
  public customerPhoneList: Array<CustomerInquiryDetailModel> = [] as Array<CustomerInquiryDetailModel>;
  public customerPhoneTemp: String;

  paymentedAmt: number = 0;
  thaidate = new ThaiDatePipe();
  arrearage: number = 0;

  disableDcFromKLOneCode: boolean = false;
  disableDcFromKLOneAmount: boolean = true;
  disableDcKLOneCode: boolean = false;
  disableDcKLOneAmount: boolean = true;

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar: any = this.translateCalendarPipe.transform();

  constructor(private parameterService: ParameterService,
    private messageService: MessageService,
    private router: Router,
    route: ActivatedRoute,
    private saleorderService: SalesOrderrService,
    private autService: AuthenticationService,
    private paymentService: PaymentService,
    private parameterwms: ParameterWmsSerive,
    private translateService: TranslateService,
    private decimalService: DecimalService,
    private decimalPipe: DecimalPipe,
    private soLandingService: SoForBuyerService,
    renderer2: Renderer2,
    private parameterWmsSerive: ParameterWmsSerive,
    private customerService: CustomerService,
  ) {
    super('P31101', renderer2, route)
    this.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel
    this.saleOrderNo = route.snapshot.paramMap.get('saleOrderNo');
    this.warehouse_code_view = route.snapshot.paramMap.get('warehouseCode');
    this.Country = localStorage.getItem('languageData') as string;
    if (this.saleOrderNo != null && this.warehouse_code_view != null) {
      this.disableUrl = true;
    } else {
      this.disableUrl = false;
    }
  }

  ngOnInit() {
    this.getParameterPayment();

    setTimeout(() => {
      this.checkPermission();
      this.order = [{ label: this.translateService.instant('ORDSOR200.M00208'), command: () => { this.confirmInfo(); }, disabled: this.disableButtonConfirm || this.disableConfirmPermission },
      { label: this.translateService.instant('ORDSOR200.M00209'), command: () => { this.savePayment(); }, disabled: this.disableButtonPayment || this.disablePermission },
      { label: this.translateService.instant('ORDSOR200.M00210'), command: () => { this.cancetInfo(); }, disabled: this.disableButtonCancel || this.disableCancalPermission }]
    }, 7000);

    this.discountOrder.totalPriceOrder = 0;
    this.discountOrder.discountBySeller = 0;
    this.discountOrder.discountKlangOnePrice = 0;
    this.discountOrder.discountSaleChannelPrice = 0;

    this.dataPayment.amount = 0;
    this.dataPayment.total_price = 0;
    this.dataPayment.cod_price = 0;

    this.dataTransport.klangoen_price = 0;
    this.dataTransport.discount_seller = 0;
    this.dataTransport.discount_other_price = 0;
    this.dataTransport.total_weight = 0;
    this.dataTransport.volumetric_weight = 0;
    this.dataTransport.shipping_price = 0;
    this.dataTransport.total_discount = 0;
    this.dataTransport.shipping_price_customer = 0;

    this.parameterService.getDataParameterDetail(4107, 1).subscribe((response: any) => { //Validate Sale Price

      if (response.pmdtbdtxtv1 == 'Y') {
        this.vSalePrice = true;
      } else {
        this.vSalePrice = false;
      }

    }, err => {
      console.log(err);
    });

    this.maxDate = new Date();

    this.paymentService.getVprmTableByPrmno(4300).subscribe(response => {
      this.payChannel = JSON.parse(JSON.stringify(response));
    });

    this.autService.getParameterWmsCountry().subscribe(response => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.optionCountry.length == 1) {
        this.disCountry = true;
        this.salesOrder.country_code = this.optionCountry[0].value;

        this.autService.getParameterSaleChannel(this.optionCountry[0].value).subscribe((response) => {
          this.salesChannelsCategory = JSON.parse(JSON.stringify(response));

          if (this.salesChannelsCategory.length == 0) {
            this.salesChannelsDisFlagCategory = true;
          }
        }, err => {
          console.log(err);
        });

        this.autService.getParameterNewSaleChannel(this.optionCountry[0].value).subscribe((response) => {
          this.salesChannels = JSON.parse(JSON.stringify(response));
          if (this.salesChannels.length == 0) {
            this.salesChannelsDisFlag = true;
          }
        }, err => {
          console.log(err);
        });

        this.parameterwms.getParameterWmsBytableNoAndCode(105, this.optionCountry[0].value).subscribe((responseParam) => {
          let respones = JSON.parse(JSON.stringify(responseParam));
          this.flagGetPostcode = respones[0].parameterData.parameter_v2;
          this.parameterwms.getParameterWmsBytableNoAndCode(9146, respones[0].parameterData.parameter_v6).subscribe((currencyResult) => {
            let currency = JSON.parse(JSON.stringify(currencyResult));
            this.salesOrder.currency_code = currency[0].value;
            this.salesOrder.currency_desc_eng = currency[0].engDesc;
            this.salesOrder.currency_desc_local = currency[0].localDesc;
          });
          this.countrySo = this.optionCountry[0].value;
          this.saleorderService.getparameterVat(this.optionCountry[0].value).subscribe((response) => {
            this.vat = JSON.parse(JSON.stringify(response));
            this.setDefaultVat();
          }, err => {
            console.log(err);
          });
        }, err => {
          console.log(err);
        });
        this.autService.getParameterWmsWareHouse(this.optionCountry[0].value).subscribe(response => {
          this.warehouse = JSON.parse(JSON.stringify(response));
          if (this.warehouse.length == 0) {
            this.warehouseDisFalg = true;
          }
        }, err => {
          console.log(err);

        });

        this.parameterwms.getParameterWms(9173).subscribe((response) => {
          this.shipmentCompany = JSON.parse(JSON.stringify(response));

        }, err => {
          console.log(err);
        });
        this.formatDecimal(this.optionCountry[0].value);
        this.seachProvinceByCoutry(this.optionCountry[0]);
      } else if (this.optionCountry.length == 0) {
        this.disCountry = true;
      }
    }, err => {
      console.log(err);
    });

    this.parameterService.getvparameterDetailToLable(4106).subscribe((response) => {
      let result = JSON.parse(JSON.stringify(response));
      this.typeValueEqual = result[0].value.pmdtbdtxtv1;
    });

    this.autService.getParameterWmsFlowType().subscribe((response) => {
      this.movementType = JSON.parse(JSON.stringify(response));
      if (this.movementType.length == 0) {
        this.movementTypeDisFlag = true;
      }
    }, err => {
      console.log(err);
    });

    this.saleorderService.getparameterDetailToLable(4100).subscribe((response) => {
      this.paymentChannel = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.saleorderService.getparameterMasterAgent().subscribe(response => {
      this.agent = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.saleorderService.getparameterSellerClose().subscribe(response => {
      this.closeOrder = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.parameterwms.getParameterWms(9150).subscribe((response) => {
      this.paymentCOD = JSON.parse(JSON.stringify(response));
      this.defalutPaymentCOD = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.parameterService.getDataParameterDetail(4107, 2).subscribe((response: any) => {

      if (response.pmdtbdtxtv1 == 'N') {
        this.hideSaveCus = true;
      } else {
        this.hideSaveCus = true;
        this.disableFieldCustomer = false;
      }
    }, err => {
      console.log(err);
    });

    this.disableButton = true;

    window.scroll(0, 0);
    if (this.saleOrderNo == null) {
      this.disable = false;
      this.saleOrderNo = null;
      this.salesOrder.create_date = new Date();
      this.dataTransport.shippemt_expected_date = moment(new Date()).format('DD/MM/YYYY');
      this.paymentRecordsForm = new FormGroup({
        saleOrderForm: new FormControl({ value: '', disabled: false }, Validators.required),
        savePaymentBy: new FormControl({ value: '', disabled: false }, Validators.required),
        country: new FormControl({ value: '', disabled: false }, Validators.required),
        paymentType: new FormControl({ value: '', disabled: true }),
        paymentDate: new FormControl({ value: '', disabled: false }),
        paymentSaveDate: new FormControl({ value: '', disabled: false }),
        amount: new FormControl(),
        amountPaid: new FormControl(),
        remark: new FormControl()
      });

      this.parameterWmsSerive.getParameterBytableNoAndCode(9141, 1).subscribe((response) => {
        this.salesOrder.status_order_desc_eng = response[0].engDesc;
        this.salesOrder.status_order_desc_local = response[0].localDesc;
      }, err => {
        console.log(err);
      });

      this.parameterService.getDataParameterDetail(4104, 100).subscribe(response => {
        let confirm_order_status = JSON.parse(JSON.stringify(response));
        this.salesOrder.confirm_order_status_eng = confirm_order_status.pmdtbdtbdedesc;
        this.salesOrder.confirm_order_status_local = confirm_order_status.pmdtbdtbdldesc;

      });

      this.dataNotFound = true;
      this.disableEditField = false;
      this.disableEditFieldItem = false;

      this.salesOrder.create_by = this.user.userName ? this.user.userName : this.user.userDesc;
      this.parameterwms.getParameterBytableNoAndCode(9148, 'O').subscribe(response => {
        let shipping_status = JSON.parse(JSON.stringify(response));
        this.dataTransport.shipping_status_eng = shipping_status[0].engDesc;
        this.dataTransport.shipping_status_local = shipping_status[0].localDesc;
      });

      this.parameterwms.getParameterBytableNoAndCode(9149, 0).subscribe(response => {
        let payment_status = JSON.parse(JSON.stringify(response));
        this.dataPayment.payment_status_eng = payment_status[0].engDesc;
        this.dataPayment.payment_status_local = payment_status[0].localDesc;
      });

      this.getDatePayment();
      //-----------------------------------------------------------
    } else {
      this.disable = true;
      this.addItemDisabled = true;
      this.getItemSaleOrder();

      this.paymentRecordsForm = new FormGroup({
        saleOrderForm: new FormControl({ value: '', disabled: true }, Validators.required),
        savePaymentBy: new FormControl({ value: '', disabled: true }, Validators.required),
        country: new FormControl({ value: '', disabled: true }, Validators.required),
        paymentType: new FormControl({ value: '', disabled: true }),
        paymentDate: new FormControl({ value: '', disabled: true }),
        paymentSaveDate: new FormControl({ value: '', disabled: true }),
        amount: new FormControl({ value: '', disabled: true }),
        amountPaid: new FormControl({ value: '', disabled: true }, Validators.required),
        remark: new FormControl({ value: '', disabled: true }),
      });
    }

    this.parameterService.getvparameterDetailToLable(4501).subscribe((response) => {
      this.optionSex = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.parameterService.getvparameterDetailToLable(4502).subscribe((response) => {
      this.optionOccupation = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.parameterService.getvparameterDetailToLable(4503).subscribe((response) => {
      this.optionNationality = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
  }

  KeyPress(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement;
    const currentValue: string = inputElement.value;
    const keyPressed: string = event.key;
    const isDigitOrPlus: boolean = (keyPressed >= '0' && keyPressed <= '9') || keyPressed === '+';
    const isFirstPlus: boolean = keyPressed === '+' && !currentValue.includes('+') && inputElement.selectionStart === 0;
    if (!isDigitOrPlus || (!isFirstPlus && keyPressed === '+')) {
        event.preventDefault();
    }
  }

  getSellerCode(page) {
    if (this.dataTableMaintenance.length > 0 || Object.keys(this.dataCustomer).length !== 0 || Object.keys(this.dataReceiver).length !== 0) {
      this.messageService.add({ key: 'warnChangeSeller', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00185', detail: 'ORDSOR200.M00186' });
    } else {
      this.sellerCode = page.value;
    }

    this.validateMandatoryFeilds();
  }

  checkValueEqual(value) {
    this.dataTransport.shipping_price = Number(value.toString().replace(/,/g, ""));
    this.dataTransport.shipping_price = this.decimalService.getRoundingDecimal(Number(this.dataTransport.shipping_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    if (this.typeValueEqual == 'M') {
      if (this.dataTransport.shipping_price <= 0) {
        this.dataTransport.shipping_price = null;
      }
    } else if (this.typeValueEqual == 'L') {
      this.dataTransport.shipping_price;
    } else if (this.typeValueEqual == 'E') {
      if (this.dataTransport.shipping_price < 0) {
        this.dataTransport.shipping_price = null;
      }
    }
    this.calculateDiscountTranSport(this.dataTransport.discount_seller);
  }

  changeSeller() {
    this.sellerCode = this.salesOrder.seller_code;
    this.checkedSameAddress = false;

    this.dataCustomer = {} as CustomerModel | null;
    this.dataReceiver = {} as ReceiverModel | null;
    this.dataTransport = {} as ShippmentModel | null;

    this.discountOrder.discountBySeller = 0;
    this.discountOrder.discountKlangOneCode = null;
    this.discountOrder.discountKlangOnePrice = 0;
    this.discountOrder.discountSaleChannelCode = null;
    this.discountOrder.discountSaleChannelPrice = 0;

    this.dataTransport.klangone_code = null;
    this.dataTransport.klangoen_price = 0;
    this.dataTransport.discount_seller = 0;
    this.dataTransport.discount_other_code = null
    this.dataTransport.discount_other_price = 0;
    this.dataTransport.shippemt_expected_date = moment(new Date()).format('DD/MM/YYYY');

    this.dataTableMaintenance = [];
    this.getDiscountSaleOrder(0);
    this.validateMandatoryFeilds();
    this.RecalFunction();
    this.messageService.clear('warnChangeSeller');
  }

  onRejectSeller() {
    this.salesOrder.seller_code = this.sellerCode;
    this.messageService.clear('warnChangeSeller');
  }

  getWareHouse(warehouse) {
    if (this.salesOrder.seller_code) {
      this.messageService.add({ key: 'warnChangeWarehouse', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00185', detail: 'ORDSOR200.M00186' });
    } else {
      this.warehouse_code = warehouse.value;
      this.autService.getParameterWmsSeller(this.countrySo, warehouse.value).subscribe(response => {
        this.seller = JSON.parse(JSON.stringify(response));
        if (this.seller.length == 0) {
          this.sellerDisFlag = true;
        }
      }, err => {
        console.log(err);
      });
      this.saleorderService.getBookBankNo(warehouse.value).subscribe(res => {
        let book_no = JSON.parse(JSON.stringify(res));
        this.salesOrder.bank_name_no = book_no.bank_no;
      }, err => {
        console.log(err);
      })
    }
    this.validateMandatoryFeilds();

  }

  changeWarehouse() {
    this.messageService.clear();
    this.warehouse_code = this.salesOrder.warehouse_code;
    this.salesOrder.seller_code = null;
    this.subDistrictConsignee = null;
    this.districtConsignee = null;
    this.subDistrictCustomer = null;
    this.districtCustomer = null;
    this.autService.getParameterWmsSeller(this.countrySo, this.salesOrder.warehouse_code).subscribe(response => {
      this.seller = JSON.parse(JSON.stringify(response));
      if (this.seller.length == 0) {
        this.sellerDisFlag = true;
      }
    }, err => {
      console.log(err);
    });

    this.checkedSameAddress = false;
    this.dataCustomer = {} as CustomerModel | null;
    this.dataReceiver = {} as ReceiverModel | null;
    this.dataTransport = {} as ShippmentModel | null;

    this.discountOrder.discountBySeller = 0;
    this.discountOrder.discountKlangOneCode = null;
    this.discountOrder.discountKlangOnePrice = 0;
    this.discountOrder.discountSaleChannelCode = null;
    this.discountOrder.discountSaleChannelPrice = 0;

    this.dataTransport.klangone_code = null;
    this.dataTransport.klangoen_price = 0;
    this.dataTransport.discount_seller = 0;
    this.dataTransport.discount_other_code = null
    this.dataTransport.discount_other_price = 0;
    this.dataTransport.shippemt_expected_date = moment(new Date()).format('DD/MM/YYYY');

    this.dataTableMaintenance = [];
    this.getDiscountSaleOrder(0);
    this.validateMandatoryFeilds();
    this.RecalFunction();
    this.messageService.clear('warnChangeWarehouse');
  }

  onRejectWarehouse() {
    this.salesOrder.warehouse_code = this.warehouse_code;
    this.messageService.clear('warnChangeWarehouse');
  }

  getSaleChannels() {
    this.parameterService.getDataParameterDetail(4103, this.salesOrder.sale_channel_code).subscribe(response => {
      let detail = JSON.parse(JSON.stringify(response));
      this.salesOrder.sales_channel_category_code = detail.pmdtbdtxtv1;
    });

    if (this.dataTableMaintenance.length != 0) {
      this.messageService.add({ key: 'warnChangeSaleChannel', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00185', detail: 'ORDSOR200.M00186' });
    }
    this.validateMandatoryFeilds();

  }

  changeSaleChannel() {
    this.dataTableMaintenance = [];

    this.dataTransport = {} as ShippmentModel | null;

    this.discountOrder.discountBySeller = 0;
    this.discountOrder.discountKlangOneCode = null;
    this.discountOrder.discountKlangOnePrice = 0;
    this.discountOrder.discountSaleChannelCode = null;
    this.discountOrder.discountSaleChannelPrice = 0;

    this.dataTransport.klangone_code = null;
    this.dataTransport.klangoen_price = 0;
    this.dataTransport.discount_seller = 0;
    this.dataTransport.discount_other_code = null
    this.dataTransport.discount_other_price = 0;
    this.dataTransport.shippemt_expected_date = moment(new Date()).format('DD/MM/YYYY');

    this.getDiscountSaleOrder(0);
    this.validateMandatoryFeilds();
    this.RecalFunction();
    this.messageService.clear('warnChangeSaleChannel');
  }

  onRejectSaleChannel() {
    this.messageService.clear('warnChangeSaleChannel');
  }

  getCloseOrder() {

    this.validateMandatoryFeilds();

  }

  confirmInfo() {
    this.messageService.clear();
    this.messageService.add({ key: 'confirm', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00163', detail: this.translateService.instant('ORDSOR200.M00215') });
  }

  cancetInfo() {
    this.messageService.clear();
    this.messageService.add({ key: 'cancel', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00165', detail: this.translateService.instant('ORDSOR200.M00216') });
  }

  onCancel() {
    this.confirmSaleOrder.document_no = this.saleOrderNo;
    this.confirmSaleOrder.version_docs = this.salesOrder.version;
    this.confirmSaleOrder.warehouse_code = this.warehouse_code_view;
    this.confirmSaleOrder.user_operation = this.user.userName ? this.user.userName : this.user.userDesc;
    this.saleorderService.cancelSaleOrder(this.confirmSaleOrder).subscribe((response) => {
    }, err => {
      console.log(err);
    })
    this.messageService.clear('cancel');
    window.location.reload();
    window.scroll(0, 0);
  }

  async onConfirm() {
    var date = moment(new Date()).format('DD/MM/YYYY');
    let parts1 = date.split('/');
    let dateToday = Number(parts1[2] + parts1[1] + parts1[0]);
    let parts2 = this.dataTransport.shippemt_expected_date.split('/');
    let shippemt_expected_date = Number(parts2[2] + parts2[1] + parts2[0]);

    if (dateToday > shippemt_expected_date) {
      this.messageService.clear('confirm');
      this.messageService.add({ key: 'alert', severity: 'warn', sticky: true, summary: 'ORDSOR200.M00217', detail: 'ORDSOR200.M00218' });
    } else {
      this.confirmSaleOrder.document_no = this.saleOrderNo;
      this.confirmSaleOrder.version_docs = this.salesOrder.version;
      this.confirmSaleOrder.warehouse_code = this.warehouse_code_view;
      this.confirmSaleOrder.user_operation = this.user.userName ? this.user.userName : this.user.userDesc;
      const myId = uuid.v4();
      this.confirmSaleOrder.uuidSOForSeller = myId;
      this.confirmSaleOrder.longUUidSOForSeller = window.location.host + '/ordsor/ordsor300/' + myId;
      if(this.Country == 'TH'){
        this.confirmSaleOrder.language = 'th';
      }else{
        this.confirmSaleOrder.language = 'en';
      }

        if (!this.disableButtonEditSave) {
          await this.checkSaveSaleOrder();
          this.messageService.clear('confirm');
          this.saleorderService.confirmSaleOrder(this.confirmSaleOrder).subscribe((response) => {
            window.location.reload();
            window.scroll(0, 0);
          }, err => {
            console.log(err);
          })
        } else {
          this.messageService.clear('confirm');
          this.saleorderService.confirmSaleOrder(this.confirmSaleOrder).subscribe((response) => {
            window.location.reload();
            window.scroll(0, 0);
          }, err => {
            console.log(err);
          })
        }
    }
  }

  typeMomentGetDate(dataFlowType) {

    if (this.dataTableMaintenance.length == 0) {
      this.flow_type_code_param = dataFlowType.value;
      if (dataFlowType.value == 3) {
        this.flow_type_code = "1";
      } else {
        this.flow_type_code = "0";
      }
      this.getSuggestionbox();
      this.caculateExpectedDate(dataFlowType.value);
    } else {
      this.messageService.add({ key: 'warnChangeFlowType', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00187', detail: 'ORDSOR200.M00188' });
    }

    this.validateMandatoryFeilds();
  }

  changeFlowType() {
    this.flow_type_code_param = this.salesOrder.movement_type_code
    this.caculateExpectedDate(Number(this.flow_type_code_param));
    if (this.flow_type_code_param == '3') {
      this.flow_type_code = "1";
    } else {
      this.flow_type_code = "0";
    }

    this.dataTransport = {} as ShippmentModel | null;

    this.discountOrder.discountBySeller = 0;
    this.discountOrder.discountKlangOneCode = null;
    this.discountOrder.discountKlangOnePrice = 0;
    this.discountOrder.discountSaleChannelCode = null;
    this.discountOrder.discountSaleChannelPrice = 0;

    this.dataTransport.klangone_code = null;
    this.dataTransport.klangoen_price = 0;
    this.dataTransport.discount_seller = 0;
    this.dataTransport.discount_other_code = null
    this.dataTransport.discount_other_price = 0;
    this.dataTransport.shippemt_expected_date = moment(new Date()).format('DD/MM/YYYY');

    this.dataTableMaintenance = [];
    this.getDiscountSaleOrder(0);
    this.validateMandatoryFeilds();
    this.RecalFunction();
    this.getSuggestionbox();
    this.messageService.clear('warnChangeFlowType');
  }

  onRejectFlowType() {
    this.salesOrder.movement_type_code = this.flow_type_code_param;
    this.messageService.clear('warnChangeFlowType');
  }

  deleteOrder() {
    let total = 0;
    if (this.row == 20) {
      this.dataTableMaintenance = [];
      this.getDiscountSaleOrder(total);
      this.selectedRowData = [];
      this.dataNotFound = true;
    } else {
      var i = this.dataTableMaintenance.length;
      while (i--) {
        if (this.dataTableMaintenance[i].item_check == true) {
          total = this.discountOrder.totalPriceOrder - this.dataTableMaintenance[i].total_price;
          this.dataTableMaintenance.splice(i, 1);
          this.getDiscountSaleOrder(total);
        }
        this.selectedRowData = [];
      }
      if (this.dataTableMaintenance.length == 0) {
        this.dataTableMaintenance = [];
        this.selectedRowData = [];
        this.dataNotFound = true;
      }
      this.getSuggestionbox();
    }
    this.disableButtonEditSave = false;
    this.RecalFunction();
    this.isChecker = false;
    this.messageService.clear('delete');
  }

  onReject() {
    this.messageService.clear();
  }

  getDiscountTransportKlangOne() {
    if (this.dataTransport.klangone_code) {
      this.parameterService.getDiscoutPrice(4202, this.countrySo, this.dataTransport.klangone_code).subscribe((response) => {
        let discount = JSON.parse(JSON.stringify(response));
        if (discount) {
          //---------- Check Special discount Code -----------
          if (discount.pmdtbdtxtv10 == 'Y') {
            this.disableDcKLOneAmount = false;
            this.disableDcKLOneCode = true;
          } else {
            this.disableDcKLOneAmount = true;
            this.disableDcKLOneCode = false;
          }
          this.dataTransport.klangoen_price = discount.pmdtbdnumv1
          this.calculateDiscountTranSport(this.dataTransport.discount_seller);
          this.RecalFunction();
        } else {
          this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00167', detail: 'ORDSOR200.M00168' });
          this.dataTransport.klangone_code = null;
          this.dataTransport.klangoen_price = 0;
          this.calculateDiscountTranSport(this.dataTransport.discount_seller);
          this.RecalFunction();
        }
      }, err => {
        console.log(err);
      });
    } else {
      this.dataTransport.klangone_code = null;
      this.dataTransport.klangoen_price = 0;
      this.calculateDiscountTranSport(this.dataTransport.discount_seller);
    }
  }

  getDiscountTransportKlangOneAmount(value: number) {
    value = Number(value.toString().replace(/\,/g, ''));
    if (value) {
      if (value == 0) {
        this.disableDcKLOneCode = false;
        this.disableDcKLOneAmount = true;
        this.dataTransport.klangoen_price = this.decimalService.getRoundingDecimal(Number(0), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
      } else {
        this.dataTransport.klangoen_price = this.decimalService.getRoundingDecimal(Number(value), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
        this.calculateDiscountTranSport(this.dataTransport.discount_seller);
        this.disableDcKLOneCode = true;
      }
    } else {
      this.dataTransport.klangoen_price = this.decimalService.getRoundingDecimal(Number(0), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
      this.disableDcKLOneCode = false;
      this.disableDcKLOneAmount = true;
    }
    this.calculateDiscountTranSport(this.dataTransport.discount_seller);
  }

  getDiscountTransportOther() {
    if (this.dataTransport.discount_other_code) {
      this.parameterService.getDiscoutPrice(4203, this.countrySo, this.dataTransport.discount_other_code).subscribe((response) => {
        let discount = JSON.parse(JSON.stringify(response));
        if (discount) {
          this.dataTransport.discount_other_price = discount.pmdtbdnumv1
          this.calculateDiscountTranSport(this.dataTransport.discount_seller);
          this.RecalFunction();
        } else {
          this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00167', detail: 'ORDSOR200.M00168' });
          this.dataTransport.discount_other_code = null;
          this.dataTransport.discount_other_price = 0;
          this.calculateDiscountTranSport(this.dataTransport.discount_seller);
          this.RecalFunction();
        }
      }, err => {
        console.log(err);
      });
    } else {
      this.dataTransport.discount_other_code = null;
      this.dataTransport.discount_other_price = 0;
      this.calculateDiscountTranSport(this.dataTransport.discount_seller);
    }

  }

  getDiscountSaleOrder(total) {
    this.discountOrder.totalPriceOrder = total;
    this.discountOrder.totalPriceOrder = this.decimalService.getRoundingDecimal(Number(this.discountOrder.totalPriceOrder), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.discountOrder.sumOrder = this.discountOrder.totalPriceOrder - this.discountOrder.discountBySeller - this.discountOrder.discountKlangOnePrice - this.discountOrder.discountSaleChannelPrice;
    this.discountOrder.sumOrder = this.decimalService.getRoundingDecimal(Number(this.discountOrder.sumOrder), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.discountOrder.vat = this.discountOrder.sumOrder * (this.salesOrder.vat / 100);
    if (Number(this.discountOrder.vat) < 0) {
      this.discountOrder.vat = 0;
    }
    this.discountOrder.vat = this.decimalService.getRoundingDecimal(Number(this.discountOrder.vat), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.discountOrder.realPrice = Number(this.discountOrder.sumOrder) + Number(this.discountOrder.vat);

    this.dataPayment.amount = this.decimalService.getRoundingDecimal(Number(this.dataPayment.amount), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.dataPayment.cod_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.cod_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    if (this.dataPayment.code_charge != 'C') {
    this.dataPayment.total_price = Number(this.dataPayment.amount);
    }else{
      this.dataPayment.total_price = Number(this.dataPayment.amount) + Number(this.dataPayment.cod_price);
    }
    this.dataPayment.total_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
  }

  formatDecimal(country) {
    this.parameterService.getDiscoutPrice(104, country, this.typeDecimalRound).subscribe(dataRound => {
      this.dataTypeDecimalRound = JSON.parse(JSON.stringify(dataRound));
      this.roundSelling = this.dataTypeDecimalRound.pmdtbdnumv1;
    });

    this.parameterService.getDiscoutPrice(104, country, this.typeDecimalSelling).subscribe(dataRound => {
      this.dataTypeDecimalSelling = JSON.parse(JSON.stringify(dataRound));
      this.decimalSelling = this.dataTypeDecimalSelling.pmdtbdnumv1;
    });

    this.parameterService.getDiscoutPrice(104, country, this.typeDecimalRoundMethod).subscribe(dataRound => {
      this.dataTypeDecimalRoundMethod = JSON.parse(JSON.stringify(dataRound));
      this.methodSelling = this.dataTypeDecimalRoundMethod.pmdtbdnumv1;
    });
  }


  getItemSaleOrder() {

    this.soLandingService.findSaleOrderNoBySOAndWH(this.saleOrderNo, this.warehouse_code_view).subscribe(response => {
      if (response) {

        if (response.sudsoruid) {
          this.uuidSOLanding = response.sudsoruid;
          this.uuidSOLandingLink = window.location.host + '/ordsor/ordsor300/' + this.uuidSOLanding;
          this.disableUUID = true;
        } else {
          this.disableUUID = false;
        }

        if (response.sudsorswc) {
          this.urlSwitchy = response.sudsorswc;
          this.disableSwitchy = true;
        } else {
          this.disableSwitchy = false;
        }

      } else {
        this.disableUUID = false;
        this.disableSwitchy = false;
      }
    }, err => {
      console.log(err);
    });

    this.saleorderService.getSaleOrderDetail(this.saleOrderNo, this.warehouse_code_view).subscribe(response => {

      setTimeout(() => {
        this.payment = this.payment.filter(data => data.parameterData.entry_code == response.payment_channel_code.substring(0, 1))
        if (this.payment[0].parameterData.parameter_v3 == 'Y') {
          this.uploadFlag = true;
        } else {
          this.uploadFlag = false;
        }
        let saleOrderDetail = JSON.parse(JSON.stringify(response));
        this.autService.getParameterWmsWareHouse(response.country_code).subscribe(warehousrDDL => {
          this.warehouse = JSON.parse(JSON.stringify(warehousrDDL));
          if (this.warehouse.length == 0) {
            this.warehouseDisFalg = true;
          }
        }, err => {
          console.log(err);

        });
        this.flagSuggest = response.flagSuggest;
        this.countrySo = response.country_code;
        this.create_at = response.create_at;
        this.saleorderService.getparameterVat(response.country_code).subscribe((response) => {
          this.vat = JSON.parse(JSON.stringify(response));
          if (saleOrderDetail.vat) {
            this.salesOrder.vat = Number(saleOrderDetail.vat);
          }
        }, err => {
          console.log(err);
        });

        this.parameterwms.getParameterWms(9173).subscribe((response) => {
          this.shipmentCompany = JSON.parse(JSON.stringify(response));
        }, err => {
          console.log(err);
        });

        this.autService.getParameterWmsSeller(response.country_code, response.warehouse_code).subscribe(response => {
          this.seller = JSON.parse(JSON.stringify(response));
          if (this.seller.length == 0) {
            this.sellerDisFlag = true;
          }
        }, err => {
          console.log(err);
        });

        this.autService.getParameterSaleChannel(response.country_code).subscribe((response) => {
          this.salesChannelsCategory = JSON.parse(JSON.stringify(response));
          if (this.salesChannelsCategory.length == 0) {
            this.salesChannelsDisFlagCategory = true;
          }
        }, err => {
          console.log(err);
        });

        this.autService.getParameterNewSaleChannel(response.country_code).subscribe((response) => {
          this.salesChannels = JSON.parse(JSON.stringify(response));

          if (this.salesChannels.length == 0) {
            this.salesChannelsDisFlag = true;
          }
        }, err => {
          console.log(err);
        });
      }, 4000);
      this.salesOrder = JSON.parse(JSON.stringify(response));
      this.salesOrder.create_date = new Date(this.salesOrder.create_date);

      if (this.salesOrder.movement_type_code == "3") {
        this.disableUseWhBox = true;
      }

      if (this.salesOrder.movement_type_code == "1") {
        if (this.salesOrder.status_order_code == "1") {
          this.disableUseWhBox = false;
        } else {
          this.disableUseWhBox = true;
        }
      }
      this.formatDecimal(response.country_code);

      if (response.create_at != "portal") {
        this.disableButtonSaveConsi = true;
        this.disableFieldConsignee = false;
        if (this.salesOrder.saler_close != null && this.salesOrder.saler_close != "") {
          let saler_close_WMS = this.closeOrder.filter(element => {
            let descArray = element.label.split(" ", 4);
            let desc = descArray[2] + " " + descArray[3];
            return (desc == this.salesOrder.saler_close);
          });
          this.salesOrder.saler_close = saler_close_WMS[0].value;
        }
      }

      this.defaultSalesOrder = JSON.parse(JSON.stringify(response));
      this.defaultSalesOrder.saler_close = this.salesOrder.saler_close;

      let coutry = { value: response.country_code };
      let provinceCustomer = { value: response.customer.customer_province }
      let districtCustomer = { value: response.customer.customer_district };
      let provinceConsignee = { value: response.receiver.receiver_province }
      let districtConsignee = { value: response.receiver.receiver_district };

      this.parameterwms.getParameterWmsBytableNoAndCode(105, response.country_code).subscribe((responseParam) => {
        let respones = JSON.parse(JSON.stringify(responseParam));
        this.flagGetPostcode = respones[0].parameterData.parameter_v2;
        this.seachProvinceByCoutry(coutry);
        this.changeProvince(provinceCustomer, null);
        this.changeDistrict(districtCustomer, null);
        this.changeProvinceConsignee(provinceConsignee, null);
        this.changeDistrictConsignee(districtConsignee, null);
        this.dataTransport = JSON.parse(JSON.stringify(response.shippment));

        //------- Check box Shipment ---------
        if (this.dataTransport.use_box_cmd == 'Y') {
          this.checkedSameCMB = true;
        } else {
          this.checkedSameCMB = false;
        }

        if (this.dataTransport.use_box_warehouse == 'Y') {
          this.checkedSameWarehouse = true;
        } else {
          this.checkedSameWarehouse = false;
        }
      }, err => {
        console.log(err);
      });

      this.validateMandatoryFeilds();
      this.dataTableMaintenance = JSON.parse(JSON.stringify(response.item));
      this.dataTableMaintenance.forEach((element, index) => {
        this.priceItemRequest.country = response.country_code;
        this.priceItemRequest.itemCode = element.item_code;
        this.priceItemRequest.saleChannel = response.sales_channel_category_code.toString();
        this.priceItemRequest.sellerCode = response.seller_code.toString();
        this.priceItemRequest.orderDate = moment(response.create_date).format('DD/MM/YYYY');
        this.saleorderService.getPriceItem(this.priceItemRequest).subscribe(responsePriceItem => {
          let priceItem = JSON.parse(JSON.stringify(responsePriceItem));
          this.dataTableMaintenance[index].item_free_shipment_flag = priceItem.free_shipment_flag;
        }, err => {
          console.log(err);
        });
      });

      this.paymentDeatilForm.controls['saleOrderNumber'].setValue(this.saleOrderNo);

      if (this.dataTableMaintenance.length == 0) {
        this.dataNotFound = true;
      } else {
        this.dataNotFound = false;
      }

      this.dataCustomer = JSON.parse(JSON.stringify(response.customer));
      this.customerId = this.dataCustomer.customer_id;

      this.disableFieldCustomer = true
      this.disableButtonCustomer = true
      this.disableButtonSaveCus = true
      this.checkSubmitCustomer = true

      this.dataReceiver = JSON.parse(JSON.stringify(response.receiver));

      this.defaultTransport = JSON.parse(JSON.stringify(response.shippment));

      this.dataPayment = JSON.parse(JSON.stringify(response.payment));
      this.defaultDataPayment = JSON.parse(JSON.stringify(response.payment));

      this.getDatePayment();

      this.discountOrder = JSON.parse(JSON.stringify(response.discountSo));
      this.defaultDiscountOrder = JSON.parse(JSON.stringify(response.discountSo));

      this.parameterService.getDataMapflagBynumv1(4104, this.salesOrder.shipFlag).subscribe(response => {
        let confirm_order_status = JSON.parse(JSON.stringify(response));
        this.salesOrder.confirm_order_status_eng = confirm_order_status.pmdtbdtbdedesc;
        this.salesOrder.confirm_order_status_local = confirm_order_status.pmdtbdtbdldesc;
      });

      this.disableButtonSaveCus = true;
      this.disableButtonSaveConsi = true;

      if (response.paymentinfo.length == 0) {
        this.dataPaymentNotFound = true;
      } else {
        this.paymentService.getpaymentInquiry(this.saleOrderNo, this.warehouse_code_view).subscribe((response) => {
          this.payDetail = JSON.parse(JSON.stringify(response));
          JSON.parse(JSON.stringify(this.payDetail.detail)).forEach(element => {
            this.paymentedAmt = this.paymentedAmt + element.paymentAmount;
            this.arrearage = this.decimalService.getRoundingDecimal(Number(Number(this.payDetail.saleOrderAmount) - Number(this.paymentedAmt)), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
          })
        });

        this.payTable = JSON.parse(JSON.stringify(response.paymentinfo));

        for (let i = 0; i < this.payTable.length; i++) {
          this.base64textString = JSON.parse(JSON.stringify(this.payTable))[i].image;
          this.converImg(this.base64textString);
          this.payTable[i].image = this.converted_img;

          this.payChannel.forEach(payCha => {
            if (this.payTable[i].payment_channel == payCha.value) {
              this.payTable[i].payment_channel = payCha.label;
            }
          });

          this.paymentService.getVprmTableByCountry(4302, this.salesOrder.country_code).subscribe((response) => {
            this.bankTransfer = JSON.parse(JSON.stringify(response));
            this.bankTransfer.forEach(bankTran => {
              if (this.payTable[i].bank == bankTran.value) {
                this.payTable[i].bank = bankTran.label;
              }
            });
          });

          if (this.payTable[i].confirm_date) {
            this.payTable[i].confirm_date = this.thaidate.transform(this.payTable[i].confirm_date, 'shortCommonFullTime')
          } else {
            this.payTable[i].confirm_date = null;
          }

        }
        this.converImg(null);
      }

      if (response.packing.length == 0) {
        this.dataShipmentNotFound = true;
      } else {
        this.dataShipmentNotFound = false;
        this.packOrder = JSON.parse(JSON.stringify(response.packing));
        this.packOrder.forEach(e => {
          e.circumference = e.weight * e.lengthy * e.height
        })
      }

      //------------------- Check Status SO -------------------------
      if (this.salesOrder.status_order_code == "1") {
        this.sellerCode = this.salesOrder.seller_code;
        this.warehouse_code = this.salesOrder.warehouse_code;
        if (this.salesOrder.movement_type_code == '3') {
          this.flow_type_code = "1";
        } else {
          this.flow_type_code = "0";
        }
        this.disable = false;
        this.disableButtonSave = false;
        this.disableButtonConfirm = false;
        this.disableButtonCancel = false;
        this.addItemDisabled = false;
        this.disableButtonPayment = true;
        this.disableEditFieldItem = false;
        this.disabledGenReport = false;
        this.chargeCod = false;
        this.disableButtonCustomer = false;
      } else if (this.salesOrder.status_order_code == "2") { // Confirm
        this.disableButtonSave = true;
        this.disableButtonConfirm = true;
        this.addItemDisabled = true;
        this.disableButtonCustomer = true;
        this.disableButtonCancel = false;
        this.disableButtonPayment = false;
        this.disabledGenReport = false;
        this.chargeCod = true;
        this.disableButtonConsi = true;
        this.checkedSameBox = true;
        if (this.salesOrder.payment.paymentStatus == "1" || this.salesOrder.payment.paymentStatus == "3") {
          this.disableButtonPayment = true;
        }
      } else if (this.salesOrder.status_order_code == "3") {// Cancle
        this.disableButtonSave = true;
        this.disableButtonConfirm = true;
        this.disableButtonCancel = true;
        this.disableButtonPayment = true;
        this.disableButtonCustomer = true;
        this.addItemDisabled = true;
        this.disabledGenReport = false;
        this.chargeCod = true;
        this.disableButtonConsi = true;
        this.checkedSameBox = true;
      } else if (this.salesOrder.status_order_code == "4") {
        this.disableButtonSave = true;
        this.disableButtonConfirm = true;
        this.addItemDisabled = true;
        this.disableButtonCustomer = true;
        this.disableButtonCancel = false;
        this.disableButtonPayment = false;
        this.disabledGenReport = false;
        this.chargeCod = true;
        if (this.salesOrder.payment.paymentStatus == "1" || this.salesOrder.payment.paymentStatus == "3") {
          this.disableButtonPayment = true;
        }
      } else if (this.salesOrder.status_order_code == "5") {
        this.disableButtonSave = true;
        this.disableButtonConfirm = true;
        this.addItemDisabled = true;
        this.disableButtonCancel = false;
        this.disableButtonPayment = false;
        this.disabledGenReport = false;
        this.chargeCod = true;
        if (this.salesOrder.payment.paymentStatus == "1" || this.salesOrder.payment.paymentStatus == "3") {
          this.disableButtonPayment = true;
        }
      } else if (this.salesOrder.status_order_code == "6") {
        this.disableButtonSave = true;
        this.disableButtonConfirm = true;
        this.addItemDisabled = true;
        this.disableButtonCustomer = true;
        this.disableButtonCancel = false;
        this.disableButtonPayment = false;
        this.disabledGenReport = false;
        this.chargeCod = true;
        if (this.salesOrder.payment.paymentStatus == "1" || this.salesOrder.payment.paymentStatus == "3") {
          this.disableButtonPayment = true;
        }
      } else if (this.salesOrder.status_order_code == "7") {
        this.disableButtonSave = true;
        this.disableButtonConfirm = true;
        this.disableButtonCancel = false;
        this.addItemDisabled = true;
        this.disableButtonCustomer = true;
        this.disableButtonPayment = false;
        this.disabledGenReport = false;
        this.chargeCod = true;
        if (this.salesOrder.payment.paymentStatus == "1" || this.salesOrder.payment.paymentStatus == "3") {
          this.disableButtonPayment = true;
        }
      } else if (this.salesOrder.status_order_code == "8") {
        this.disableButtonSave = true;
        this.disableButtonConfirm = true;
        this.disableButtonCancel = false;
        this.addItemDisabled = true;
        this.disableButtonCustomer = true;
        this.disableButtonPayment = false;
        this.disabledGenReport = false;
        this.chargeCod = true;
        if (this.salesOrder.payment.paymentStatus == "1" || this.salesOrder.payment.paymentStatus == "3") {
          this.disableButtonPayment = true;
        }
      } else if (this.salesOrder.status_order_code == "9") {
        this.disableButtonSave = true;
        this.disableButtonConfirm = true;
        this.disableButtonCancel = true;
        this.addItemDisabled = true;
        this.disableButtonCustomer = true;
        this.disableButtonPayment = false;
        this.disabledGenReport = false;
        this.chargeCod = true;
        if (this.salesOrder.payment.paymentStatus == "1" || this.salesOrder.payment.paymentStatus == "3") {
          this.disableButtonPayment = true;
        }
      }
      this.order = [{ label: this.translateService.instant('ORDSOR200.M00208'), command: () => { this.onConfirm(); }, disabled: this.disableButtonConfirm },
      { label: this.translateService.instant('ORDSOR200.M00209'), command: () => { this.savePayment(); }, disabled: this.disableButtonPayment },
      { label: this.translateService.instant('ORDSOR200.M00210'), command: () => { this.onCancel(); }, disabled: this.disableButtonCancel }]
    }, err => {
      console.log(err);
    });
  }

  calculateVat(data) {
    this.salesOrder.vat = data.value;
    this.calculateDiscount(this.discountOrder.discountBySeller);
    this.validateMandatoryFeilds();
  }

  calculateDiscount(value) {
    if (this.discountOrder.discountBySeller < 0) {
      this.discountOrder.discountBySeller = this.decimalService.getRoundingDecimal(Number(0), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    } else {
      this.discountOrder.discountBySeller = Number(value.toString().replace(/,/g, ""));
    }
    this.discountOrder.sumOrder = this.discountOrder.totalPriceOrder - this.discountOrder.discountBySeller - this.discountOrder.discountKlangOnePrice - this.discountOrder.discountSaleChannelPrice;
    if (Number(this.discountOrder.sumOrder) < 0) {
      this.discountOrder.sumOrder = 0;
    }
    this.discountOrder.sumOrder = this.decimalService.getRoundingDecimal(Number(this.discountOrder.sumOrder), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.discountOrder.vat = this.discountOrder.sumOrder * (this.salesOrder.vat / 100);
    if (Number(this.discountOrder.vat) < 0) {
      this.discountOrder.vat = Number(this.discountOrder.vat) * -1;
    }
    this.discountOrder.vat = this.decimalService.getRoundingDecimal(Number(this.discountOrder.vat), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.discountOrder.realPrice = Number(this.discountOrder.sumOrder) + Number(this.discountOrder.vat);

    this.dataPayment.amount = this.decimalService.getRoundingDecimal(Number(this.dataPayment.amount), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.dataPayment.cod_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.cod_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.dataPayment.total_price = Number(this.dataPayment.amount) + Number(this.dataPayment.cod_price);
    this.dataPayment.total_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);

    this.RecalFunction();
  }

  calculateTable() {
    let total = 0;
    this.dataTableMaintenance.forEach(e => {
      e.item_price_per_piece = Number(this.decimalService.getRoundingDecimal(e.item_price_per_piece, this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1));
      if (e.discountPercen != null && e.discountPercen != 0) {
        let item_price = new BigNumber(this.decimalService.getRoundingDecimal((100 - e.discountPercen) * (e.item_price_per_piece / 100), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1));
        let item_qty = e.item_quantity == null ? new BigNumber(0) : new BigNumber(e.item_quantity);
        e.total_price = Number(item_price.multipliedBy(item_qty));
        e.total_price = this.decimalService.getRoundingDecimal(Number(e.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
        total = Number(total) + Number(e.total_price)
        total = this.decimalService.getRoundingDecimal(Number(total), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
      } else if (e.discount_value != null && e.discount_value != 0) {
        let item_price = new BigNumber(this.decimalService.getRoundingDecimal(e.item_price_per_piece - e.discount_value, this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1));
        let item_qty = e.item_quantity == null ? new BigNumber(0) : new BigNumber(e.item_quantity);
        e.total_price = Number(item_price.multipliedBy(item_qty));
        e.total_price = this.decimalService.getRoundingDecimal(Number(e.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
        total = Number(total) + Number(e.total_price)
        total = this.decimalService.getRoundingDecimal(Number(total), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
      } else {
        let item_price = new BigNumber(this.decimalService.getRoundingDecimal(e.item_price_per_piece, this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1));
        let item_qty = e.item_quantity == null ? new BigNumber(0) : new BigNumber(e.item_quantity);
        e.total_price = Number(item_price.multipliedBy(item_qty));
        e.total_price = this.decimalService.getRoundingDecimal(Number(e.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
        total = Number(total) + Number(e.total_price)
        total = this.decimalService.getRoundingDecimal(Number(total), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
      }
    })
    this.getDiscountSaleOrder(total);
  }

  checkValueDisper(index) {
    if (this.dataTableMaintenance[index].discount_value != 0 && this.dataTableMaintenance[index].discount_value != null) {
      this.dataTableMaintenance[index].discount_value = 0;
    }
  }

  checkValueDiscount(index) {
    if (this.dataTableMaintenance[index].discountPercen != 0 && this.dataTableMaintenance[index].discountPercen != null) {
      this.dataTableMaintenance[index].discountPercen = 0;
    }
  }

  checkStock(item_qu, cur_avai_stk, i) {
    if (item_qu > cur_avai_stk && item_qu != null) {
      this.messageService.clear('alertStk');
      this.messageService.add({ key: 'alertStk', severity: 'warn', summary: 'ORDSOR200.M00191', detail: 'ORDSOR200.M00271' });
    }
    if (item_qu == 0 || item_qu == null) {
      this.dataTableMaintenance[i].item_quantity = null;
      this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00191', detail: 'ORDSOR200.M00272' });
    }
  }

  calculateItem(item_qu, item_price, item_disPer, item_disva, index) {
    if (item_qu < 0) {
      item_qu = 0;
    } else if (item_disva < 0) {
      this.dataTableMaintenance[index].discount_value = 0;
    } else if (item_disPer < 0) {
      item_disPer = 0
    } else if (item_disPer > 100) {
      item_disPer = 100
    } else if (item_disva > 0) {
      if (item_price < item_disva) {
        this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00191', detail: 'ORDSOR200.M00169' });
        this.dataTableMaintenance[index].discount_value = 0;
      }
    } else {
      this.dataTableMaintenance[index].discount_value = item_disva;
    }
    this.dataTableMaintenance[index].item_quantity = item_qu;
    this.dataTableMaintenance[index].item_price_per_piece = item_price;
    this.dataTableMaintenance[index].discountPercen = item_disPer;
    this.calculateTable();
    this.RecalFunction();
  }

  onRejectStk() {
    this.messageService.clear('alertStk');
  }

  calculateDiscountTranSport(value) {
    if (this.dataTransport.discount_seller < 0) {
      this.dataTransport.discount_seller = this.decimalService.getRoundingDecimal(Number(0), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    } else {
      this.dataTransport.discount_seller = Number(value.toString().replace(/,/g, ""));
    }
    this.dataTransport.total_discount = Number(this.dataTransport.klangoen_price)
      + Number(this.dataTransport.discount_seller)
      + Number(this.dataTransport.discount_other_price);

    this.dataTransport.shipping_price = this.decimalService.getRoundingDecimal(Number(this.dataTransport.shipping_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.dataTransport.shipping_price_customer = this.dataTransport.shipping_price - this.dataTransport.total_discount;
    if (Number(this.dataTransport.shipping_price_customer) < 0) {
      this.dataTransport.shipping_price_customer = 0;
    }
    this.RecalFunction();
  }

  getCountryCurrency(page) {
    if (this.salesOrder.warehouse_code || this.salesOrder.vat) {
      this.messageService.add({ key: 'warnChangeCountry', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00185', detail: 'ORDSOR200.M00186' });
    } else {

      this.autService.getParameterSaleChannelInquiry(page.value).subscribe((response) => {
        this.salesChannelsCategory = JSON.parse(JSON.stringify(response));
        if (this.salesChannelsCategory.length == 0) {
          this.salesChannelsDisFlagCategory = true;
        } else {
          this.salesChannelsDisFlagCategory = false;
        }
      }, err => {
        console.log(err);
      });

      this.autService.getParameterNewSaleChannel(page.value).subscribe((response) => {
        this.salesChannels = JSON.parse(JSON.stringify(response));
        if (this.salesChannels.length == 0) {
          this.salesChannelsDisFlag = true;
        }
      }, err => {
        console.log(err);
      });

      this.parameterwms.getParameterWmsBytableNoAndCode(105, page.value).subscribe((responseParam) => {
        let respones = JSON.parse(JSON.stringify(responseParam));
        this.flagGetPostcode = respones[0].parameterData.parameter_v2;
        this.parameterwms.getParameterWmsBytableNoAndCode(9146, respones[0].parameterData.parameter_v6).subscribe((currencyResult) => {
          let currency = JSON.parse(JSON.stringify(currencyResult));
          this.salesOrder.currency_code = currency[0].value;
          this.salesOrder.currency_desc_eng = currency[0].engDesc;
          this.salesOrder.currency_desc_local = currency[0].localDesc;
        });
        this.countrySo = page.value;

        this.saleorderService.getparameterVat(page.value).subscribe((response) => {
          this.vat = JSON.parse(JSON.stringify(response));
          this.setDefaultVat();
        }, err => {
          console.log(err);
        });
      }, err => {
        console.log(err);
      });

      this.autService.getParameterWmsWareHouse(page.value).subscribe(response => {
        this.warehouse = JSON.parse(JSON.stringify(response));
        if (this.warehouse.length == 0) {
          this.warehouseDisFalg = true;
        } else {
          this.warehouseDisFalg = false;
        }
      }, err => {
        console.log(err);

      });
      this.formatDecimal(page.value);

      this.parameterwms.getParameterWms(9173).subscribe((response) => {
        this.shipmentCompany = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });
    }
    this.seachProvinceByCoutry(page);
    this.validateMandatoryFeilds();
  }

  changeCountry() {
    this.messageService.clear();
    this.countrySo = this.salesOrder.country_code;
    this.salesOrder.warehouse_code = null;
    this.salesOrder.seller_code = null;
    this.salesOrder.bank_name_no = null;
    this.salesOrder.vat = null;
    this.salesOrder.sale_channel_code = null;
    this.subDistrictConsignee = null;
    this.districtConsignee = null;
    this.subDistrictCustomer = null;
    this.districtCustomer = null;
    this.salesOrder.sales_channel_category_code = null;

    this.autService.getParameterSaleChannelInquiry(this.salesOrder.country_code).subscribe((response) => {
      this.salesChannelsCategory = JSON.parse(JSON.stringify(response));
      if (this.salesChannelsCategory.length == 0) {
        this.salesChannelsDisFlagCategory = true;
      } else {
        this.salesChannelsDisFlagCategory = false;
        this.warehouseDisFalg = false;
      }
    }, err => {
      console.log(err);
    });

    this.autService.getParameterNewSaleChannel(this.salesOrder.country_code).subscribe((response) => {
      this.salesChannels = JSON.parse(JSON.stringify(response));
      if (this.salesChannels.length == 0) {
        this.salesChannelsDisFlag = true;
      } else {
        this.salesChannelsDisFlag = false;
        this.warehouseDisFalg = false;
      }
    }, err => {
      console.log(err);
    });

    this.parameterwms.getParameterWmsBytableNoAndCode(105, this.salesOrder.country_code).subscribe((responseParam) => {
      let respones = JSON.parse(JSON.stringify(responseParam));
      this.flagGetPostcode = respones[0].parameterData.parameter_v2;
      this.parameterwms.getParameterWmsBytableNoAndCode(9146, respones[0].parameterData.parameter_v6).subscribe((currencyResult) => {
        let currency = JSON.parse(JSON.stringify(currencyResult));
        this.salesOrder.currency_code = currency[0].value;
        this.salesOrder.currency_desc_eng = currency[0].engDesc;
        this.salesOrder.currency_desc_local = currency[0].localDesc;
      });
      this.saleorderService.getparameterVat(this.salesOrder.country_code).subscribe((response) => {
        this.vat = JSON.parse(JSON.stringify(response));
        this.setDefaultVat();
      }, err => {
        console.log(err);
      });
    }, err => {
      console.log(err);
    });

    this.autService.getParameterWmsWareHouse(this.salesOrder.country_code).subscribe(response => {
      this.warehouse = JSON.parse(JSON.stringify(response));
      if (this.warehouse.length == 0) {
        this.warehouseDisFalg = true;
      }
    }, err => {
      console.log(err);

    });

    this.checkedSameAddress = false;
    this.dataCustomer = {} as CustomerModel | null;
    this.dataReceiver = {} as ReceiverModel | null;
    this.dataTransport = {} as ShippmentModel | null;

    this.discountOrder.discountBySeller = 0;
    this.discountOrder.discountKlangOneCode = null;
    this.discountOrder.discountKlangOnePrice = 0;
    this.discountOrder.discountSaleChannelCode = null;
    this.discountOrder.discountSaleChannelPrice = 0;

    this.dataTransport.klangone_code = null;
    this.dataTransport.klangoen_price = 0;
    this.dataTransport.discount_seller = 0;
    this.dataTransport.discount_other_code = null
    this.dataTransport.discount_other_price = 0;
    this.dataTransport.shippemt_expected_date = moment(new Date()).format('DD/MM/YYYY');

    this.dataTableMaintenance = [];
    this.getDiscountSaleOrder(0);
    this.validateMandatoryFeilds();
    this.RecalFunction();
    this.messageService.clear('warnChangeCountry');
  }

  onRejectCountry() {
    this.salesOrder.country_code = this.countrySo;
    this.messageService.clear('warnChangeCountry');
  }

  getDatePayment() {
    this.parameterService.getvparameterDetailToLable(4303).subscribe((response) => {
      let result = JSON.parse(JSON.stringify(response));
      result.forEach(element => {
        if (element.value.parameterDetailPK.pmdtbdtbdentcd == 100) {
          this.dataPayment.due_date = moment(this.salesOrder.create_date, "DD/MM/YYYY").add('days', Number(element.value.pmdtbdnumv1)).format('DD/MM/YYYY').toString();
        }
      });
    }, err => {
      console.log(err);
    });
  }

  getDiscountKlangOne() {
    if (this.discountOrder.discountKlangOneCode) {
      this.parameterService.getDiscoutPrice(4200, this.countrySo, this.discountOrder.discountKlangOneCode).subscribe((response) => {
        let discount = JSON.parse(JSON.stringify(response));
        if (discount) {
          //---------- Check Special discount Code -----------
          if (discount.pmdtbdtxtv10 == 'Y') {
            this.disableDcFromKLOneAmount = false;
            this.disableDcFromKLOneCode = true;
          } else {
            this.disableDcFromKLOneAmount = true;
            this.disableDcFromKLOneCode = false;
          }
          this.discountOrder.discountKlangOnePrice = discount.pmdtbdnumv1
          this.calculateDiscount(this.discountOrder.discountBySeller);
        } else {
          this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00167', detail: 'ORDSOR200.M00168' });
          this.discountOrder.discountKlangOneCode = null;
          this.discountOrder.discountKlangOnePrice = 0;
          this.calculateDiscount(this.discountOrder.discountBySeller);
        }
      }, err => {
        console.log(err);
      });
    } else {
      this.discountOrder.discountKlangOneCode = null;
      this.discountOrder.discountKlangOnePrice = 0;
      this.calculateDiscount(this.discountOrder.discountBySeller);
    }
    this.RecalFunction();
  }

  getDiscountKlangOneAmount(value: number) {
    value = Number(value.toString().replace(/\,/g, ''));
    if (value) {
      if (value == 0) {
        this.disableDcFromKLOneCode = false;
        this.disableDcFromKLOneAmount = true;
        this.discountOrder.discountKlangOnePrice = this.decimalService.getRoundingDecimal(Number(0), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
      } else {
        this.discountOrder.discountKlangOnePrice = this.decimalService.getRoundingDecimal(Number(value), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
        this.calculateDiscount(this.discountOrder.discountBySeller);
        this.disableDcFromKLOneCode = true;
      }
    } else {
      this.discountOrder.discountKlangOnePrice = this.decimalService.getRoundingDecimal(Number(0), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
      this.disableDcFromKLOneCode = false;
      this.disableDcFromKLOneAmount = true;
    }
    this.calculateDiscount(this.discountOrder.discountBySeller);
  }

  getDiscountSaleChannel() {
    if (this.discountOrder.discountSaleChannelCode) {
      this.parameterService.getDiscoutPrice(4201, this.countrySo, this.discountOrder.discountSaleChannelCode).subscribe((response) => {
        let discount = JSON.parse(JSON.stringify(response));
        if (discount) {
          this.discountOrder.discountSaleChannelPrice = discount.pmdtbdnumv1
          this.calculateDiscount(this.discountOrder.discountBySeller);
        } else {
          this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00167', detail: 'ORDSOR200.M00168' });
          this.discountOrder.discountSaleChannelCode = null;
          this.discountOrder.discountSaleChannelPrice = 0;
          this.calculateDiscount(this.discountOrder.discountBySeller);
        }
      }, err => {
        console.log(err);
      });
    } else {
      this.discountOrder.discountSaleChannelCode = null;
      this.discountOrder.discountSaleChannelPrice = 0;
      this.calculateDiscount(this.discountOrder.discountBySeller);
    }
    this.RecalFunction();

  }

  getPaymentMethod(data) {
    let payment_type = this.paymentChannel.filter(type => type.value == data.value);
    this.dataPayment.payment_type_local = payment_type[0].localDesc;
    this.dataPayment.payment_type_eng = payment_type[0].engDesc;
    if (data.value == '100') {
      this.chargeCod = false;
      this.dataPayment.code_charge = "S";
      this.paymentCOD = this.defalutPaymentCOD;

      if (this.dataPayment.amount && this.dataPayment.amount != 0) {
        this.parameterService.getDataParameterDetail(4500, this.dataTransport.shippment_company).subscribe(response => {
          let dataParam = JSON.parse(JSON.stringify(response));
          this.dataPayment.cod_price = dataParam.pmdtbdnumv1 * this.dataPayment.amount;
          this.dataPayment.amount = this.decimalService.getRoundingDecimal(Number(this.dataPayment.amount), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
          this.dataPayment.cod_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.cod_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
          this.dataPayment.total_price = Number(this.dataPayment.amount) + Number(this.dataPayment.cod_price);
          this.dataPayment.total_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
          this.CodChargeChange(this.dataPayment.code_charge);
        });
      } else {
        this.dataPayment.cod_price = 0;
      }
    } else if (data.value == '200') {
      this.chargeCod = true;
      this.dataPayment.cod_price = 0;
      this.paymentCOD = null;
      this.dataPayment.total_price = this.dataPayment.amount;
      this.dataPayment.total_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    }
    this.validateMandatoryFeilds();
  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  backBtn() {
    if (this.saleOrderNo == null) {
      if (this.salesOrder.country_code || this.salesOrder.warehouse_code || this.salesOrder.seller_code || this.salesOrder.vat ||
        this.salesOrder.payment_channel_code || this.salesOrder.sale_channel_code ||
        this.salesOrder.movement_type_code) {
        this.backPage = true;
      }
    } else {
      if (this.salesOrder.country_code != this.defaultSalesOrder.country_code
        || this.salesOrder.warehouse_code != this.defaultSalesOrder.warehouse_code
        || this.salesOrder.payment_channel_code != this.defaultSalesOrder.payment_channel_code
        || this.salesOrder.seller_code != this.defaultSalesOrder.seller_code
        || this.salesOrder.sale_channel_code != this.defaultSalesOrder.sale_channel_code
        || this.salesOrder.movement_type_code != this.defaultSalesOrder.movement_type_code
        || this.salesOrder.agent_code != this.defaultSalesOrder.agent_code
        || this.salesOrder.saler_close != this.defaultSalesOrder.saler_close
        || this.salesOrder.reference != this.defaultSalesOrder.reference
        || this.salesOrder.vat != this.defaultSalesOrder.vat
        || this.salesOrder.remark != this.defaultSalesOrder.remark
        || this.disableButtonSaveCus == false
        || this.disableButtonSaveConsi == false
        || this.dataTransport.shippment_company != this.defaultTransport.shippment_company
        || this.dataTransport.shippemt_expected_date != this.defaultTransport.shippemt_expected_date
        || this.dataTransport.klangone_code != this.defaultTransport.klangone_code
        || this.dataTransport.discount_other_code != this.defaultTransport.discount_other_code
        || this.dataTransport.discount_seller != this.defaultTransport.discount_seller
        || this.dataTransport.shipping_price != this.defaultTransport.shipping_price
        || this.discountOrder.totalPriceOrder != this.defaultDiscountOrder.totalPriceOrder
        || this.discountOrder.discountBySeller != this.defaultDiscountOrder.discountBySeller
        || this.discountOrder.discountKlangOneCode != this.defaultDiscountOrder.discountKlangOneCode
        || this.discountOrder.discountSaleChannelCode != this.defaultDiscountOrder.discountSaleChannelCode
        || this.dataPayment.code_charge != this.defaultDataPayment.code_charge
      ) {
        this.backPage = true;
      } else {
        this.backPage = false;
      }
    }
    if (this.backPage) {
      this.messageService.add({ key: 'backpage', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00182', detail: 'ORDSOR200.M00183' });
    } else {
      this.router.navigate(['/ordsor/ordsor100']);
    }

  }

  backpage() {
    this.router.navigate(['/ordsor/ordsor100']);
  }

  checked(isChecker: any, row) {
    this.row = row;
    if (isChecker) {
      this.dataTableMaintenance[row].item_check = true;
    } else {
      this.dataTableMaintenance[row].item_check = false;
    }

    for (let i = 0; i < this.dataTableMaintenance.length; i++) {
      let index = this.dataTableMaintenance.findIndex(x => x.item_check === true);
      if (index !== -1) {
        this.isChecker = true;
      } else {
        this.isChecker = false;
      }
    }
  }
  savePayment() {
    let amountToBePaidDecimal = this.decimalService.getRoundingDecimal(Number(this.dataPayment.total_price), this.methodSelling, this.roundSelling, this.decimalSelling)
    this.paymentDeatilForm.reset();
    let newDate = moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
    this.nowDate = newDate;
    this.paymentDeatilForm.controls['saleOrderNumber'].setValue(this.saleOrderNo);
    this.paymentDeatilForm.controls['creator'].setValue(this.user.userName ? this.user.userName : this.user.userDesc);
    this.paymentDeatilForm.controls['country'].setValue(this.salesOrder.country_code.concat(" - ").concat(this.translateLablePipe.transform(this.salesOrder.country_desc_eng, this.salesOrder.country_desc_local))),
      this.paymentDeatilForm.controls['currency'].setValue(this.translateLablePipe.transform(this.salesOrder.currency_desc_eng, this.salesOrder.currency_desc_local)),
      this.paymentDeatilForm.controls['operationDate'].setValue(newDate);
    this.paymentDeatilForm.controls['amountToBePaid'].setValue(this.decimalPipe.transform(amountToBePaidDecimal, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en'));
    this.displaySavepayment = true;
    this.base64textString = null;
    this.converted_img = "";
    let arrearage = this.decimalService.getRoundingDecimal(Number(this.arrearage), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.paymentDeatilForm.controls['arrearage'].setValue(this.decimalPipe.transform(arrearage, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en'));
  }

  checkedAll(isChecker: any, row) {
    this.row = row;
    if (isChecker) {
      this.isChecker = isChecker;
      this.dataTableMaintenance.forEach(x => x.item_check = true);
    } else {
      this.isChecker = isChecker;
      this.dataTableMaintenance.forEach(x => x.item_check = false);
    }
  }

  selectRow(index) {
    this.hightLight = index;
    this.hiddenButton = false;

  }

  showDialog(data) {
    if (data == '') {
      this.hightLight = 10000;
      this.display = true;
      this.hiddenButton = true;
    } else {
    }
  }

  searchCustomer() {
    this.displayCustomer = true;
    this.hiddenButton = true;
    this.hightLight = 10000;
    this.checkSubmitCustomer = true;
  }

  searchReceiver() {
    this.displayReceiver = true;
  }

  addItem() {
    this.display = true;
    this.hiddenButton = true;
  }

  useSameAddress(check) {
    if (check == true) {

      let provinceConsignee = { value: this.dataCustomer.customer_province };
      let districtConsignee = { value: this.dataCustomer.customer_district };

      this.changeProvinceConsignee(provinceConsignee, null);
      this.changeDistrictConsignee(districtConsignee, null);

      this.dataReceiver.receiver_phone = this.dataCustomer.customer_phone;
      this.dataReceiver.receiver_address = this.dataCustomer.customer_address;
      this.dataReceiver.receiver_subdistrict = this.dataCustomer.customer_subdistrict;
      this.dataReceiver.receiver_district = this.dataCustomer.customer_district;
      this.dataReceiver.receiver_postal_code = this.dataCustomer.customer_postal_code;
      this.dataReceiver.receiver_province = this.dataCustomer.customer_province;
      this.dataReceiver.receiver_name = this.dataCustomer.customer_name;
      this.dataReceiver.receiver_lastname = this.dataCustomer.customer_lastname;

      this.disableFieldConsignee = true;
      this.disableButtonSaveConsi = false;
      this.getSuggestionbox();
    } else {
      this.disableFieldConsignee = false;
      this.disableButtonSaveConsi = true;
      this.dataReceiver = {
        customer_Id: null,
        receiver_country: null,
        seller_code: null,
        receiver_address: null,
        receiver_Id: null,
        receiver_district: null,
        receiver_lastname: null,
        receiver_name: null,
        receiver_phone: null,
        receiver_postal_code: null,
        receiver_province: null,
        receiver_subdistrict: null
      };
    }
  }

  deleteItem() {
    this.messageService.clear();
    this.messageService.add({ key: 'delete', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00166', detail: this.translateService.instant('ORDSOR200.M00214') });
  }

  getdata(data: any): void {
    if (data != undefined) {
      let index = this.dataTableMaintenance.findIndex(x => x.item_code === data.item_code);
      let totalPrice = this.dataTableMaintenance.findIndex(x => x.total_price === 0);
      let sizeHeight = data.item_height === null;
      let sizeLength = data.item_length === null;
      let sizeWidth = data.item_width === null;
      let weight = data.item_weight === null;
      if (index !== -1) {
        this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00170', detail: 'ORDSOR200.M00171' });
      } else if (totalPrice !== -1) {
        this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00172', detail: 'ORDSOR200.M00173' });
      } else if (sizeHeight || sizeLength || sizeWidth) {
        this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00172', detail: 'ORDSOR200.M00193' });
      } else if (weight) {
        this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00172', detail: 'ORDSOR200.M00196' });
      } else {
        this.cur_bal_stk = data.cur_bal_stk;
        this.cur_avai_stk = data.cur_avai_stk;
        this.dataTableMaintenance.push(data);
        this.getSuggestionbox();
      }

      let total = 0;
      this.dataTableMaintenance.forEach(e => {
        if (e.discountPercen != null && e.discountPercen != 0) {
          e.total_price = (100 - e.discountPercen) * (e.item_price_per_piece / 100) * e.item_quantity;
          total += e.total_price;
        } else if (e.discount_value != null && e.discount_value != 0) {
          e.total_price = (e.item_price_per_piece - e.discount_value) * e.item_quantity;
          total += e.total_price;
        } else {
          e.total_price = e.item_price_per_piece * e.item_quantity;
          total += e.total_price;
        }
      })
      this.getDiscountSaleOrder(total);
      this.disableButtonEditSave = false;
    }
    this.display = false;
  }

  getdataCustomer(dataCustomer: any): void {
    if (dataCustomer != undefined) {
      if (dataCustomer.customer_sex) {
        this.optionSex.forEach((data) => {
          if (data.value.parameterDetailPK.pmdtbdtbdentcd === dataCustomer.customer_sex) {
            this.dataCustomer.customer_sexDes = data.label;
          }
        });
      }

      if (dataCustomer.customer_occupertion) {
        this.optionOccupation.forEach((data) => {
          if (data.value.parameterDetailPK.pmdtbdtbdentcd === dataCustomer.customer_occupertion) {
            this.dataCustomer.customer_occupertionCdDes = data.label;
          }
        });
      }

      if (dataCustomer.customer_nationality) {
        this.optionNationality.forEach((data) => {
          if (data.value.parameterDetailPK.pmdtbdtbdentcd === dataCustomer.customer_nationality) {
            this.dataCustomer.customer_nationalityDes = data.label;
          }
        });
      }
      this.dataCustomer.customer_status = dataCustomer.customer_status;
      this.dataCustomer.customer_url_facebook = dataCustomer.customer_url_facebook;
      this.dataCustomer.customer_birth = dataCustomer.customer_birth;
      console.log('this.dataCustomer', this.dataCustomer);
      this.disableConfirmField = true;

      let coutry = { value: dataCustomer.customer_country };

      let provinceCustomer = { value: dataCustomer.customer_province }
      let districtCustomer = { value: dataCustomer.customer_district };

      this.seachProvinceByCoutry(coutry);
      this.changeProvince(provinceCustomer, null);
      this.changeDistrict(districtCustomer, null);

      this.dataCustomer = dataCustomer;
      this.customerId = this.dataCustomer.customer_id;
      this.disableButtonSaveCus = true;
      if (this.create_at == 'wms' || this.create_at == 'ecom') {
        this.disableButtonConsi = true;
      } else {
        this.disableButtonConsi = false;
      }

      this.checkedSameAddress = false;
      this.disableFieldConsignee = false;
      this.disableButtonSaveConsi = true;
      this.dataReceiver = {
        customer_Id: null,
        receiver_country: null,
        seller_code: null,
        receiver_address: null,
        receiver_Id: null,
        receiver_district: null,
        receiver_lastname: null,
        receiver_name: null,
        receiver_phone: null,
        receiver_postal_code: null,
        receiver_province: null,
        receiver_subdistrict: null
      };
      this.disableButtonEditSave = false;
    }

    this.displayCustomer = false;

    if (this.hideSaveCus == false) {
      this.disableFieldCustomer = true;
    }
  }

  getdataReceiver(dataReceiver: any): void {
    if (dataReceiver != undefined) {

      let provinceConsignee = { value: dataReceiver.receiver_province }
      let districtConsignee = { value: dataReceiver.receiver_district };

      this.changeProvinceConsignee(provinceConsignee, null);
      this.changeDistrictConsignee(districtConsignee, null);

      this.dataReceiver = dataReceiver;

      this.disableButtonSaveConsi = true;
      this.disableButtonEditSave = false;

      this.checkedSameAddress = false;
      this.disableFieldConsignee = false;
    }
    this.displayReceiver = false;
    this.validateMandatoryFeilds();
    this.getSuggestionbox();
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]).then(() => {
        window.location.reload();
      }));
  }

  checkSaveSaleOrder() {

    if (this.salesOrder.country_code == null || this.salesOrder.warehouse_code == null ||
      this.salesOrder.payment_channel_code == null || this.salesOrder.seller_code == null || this.salesOrder.sale_channel_code == null ||
      this.salesOrder.movement_type_code == null || this.salesOrder.create_date == null ||
      this.salesOrder.vat == null || this.salesOrder.ads_id  == null ||

      this.dataCustomer.customer_name == null || this.dataCustomer.customer_name == '' ||
      this.dataCustomer.customer_lastname == null ||  this.dataCustomer.customer_lastname == '' ||
      this.dataCustomer.customer_phone == null ||  this.dataCustomer.customer_phone == '' ||
      this.dataCustomer.customer_address == null || this.dataCustomer.customer_address == '' ||
      this.dataCustomer.customer_province == null ||
      this.dataCustomer.customer_postal_code == null || this.dataCustomer.customer_postal_code == '' ||

      this.dataReceiver.receiver_phone == null || this.dataReceiver.receiver_phone == '' ||
      this.dataReceiver.receiver_name == null ||  this.dataReceiver.receiver_name == '' ||
      this.dataReceiver.receiver_address == null || this.dataReceiver.receiver_address == '' ||
      this.dataReceiver.receiver_province == null ||
      this.dataReceiver.receiver_postal_code == null || this.dataReceiver.receiver_postal_code == '' ||

      this.dataTransport.shippment_company == null
    ) {
      this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00175' });
    } else {
      this.setShipmentConfirmStatus();
      if (this.disableButtonSaveCus == false && this.disableButtonSaveConsi == false) {
        this.saveSaleOrderAll();
      } else if (this.disableButtonSaveCus == false) {
        this.saveSaleOrderAndCustomer();
      } else if (this.disableButtonSaveConsi == false) {
        this.saveSaleOrderAndReceive();
      } else {
        this.saveInfotmation();
      }
    }

  }



  saveInfotmation() {
    this.saveSaleOrder = this.salesOrder;
    this.saveSaleOrder.country_code = this.salesOrder.country_code;
    this.saveSaleOrder.sale_channel_code = this.salesOrder.sale_channel_code;
    this.saveSaleOrder.customer = this.dataCustomer;
    this.saveSaleOrder.receiver = this.dataReceiver;
    this.saveSaleOrder.item = this.dataTableMaintenance;
    this.saveSaleOrder.shippment = this.dataTransport;
    this.saveSaleOrder.discountSo = this.discountOrder;
    this.saveSaleOrder.payment = this.dataPayment;
    this.saveSaleOrder.useSameCustomer = String(this.checkedSameAddress);
    this.saveSaleOrder.shippment.use_box_warehouse = String(this.checkedSameWarehouse);
    this.saveSaleOrder.shippment.use_box_cmd = String(this.checkedSameCMB);
    this.saveSaleOrder.create_date = moment(this.saveSaleOrder.create_date).format('YYYY-MM-DD HH:mm:ss');
    this.saveSaleOrder.uuidSOForSeller = this.saveSoLanding.uuid;
    this.saveSaleOrder.status_order_code = '1';
    this.saveSaleOrder.shippment.shipping_status_code = '1';
    if (this.saveSaleOrder.item == null || this.saveSaleOrder.item.length == 0) {
      this.checkedItemQty = true;
      this.checkItem = false;
    } else {
      this.checkItem = true;
      if (this.saveSaleOrder.item.filter(data => data.item_quantity == 0 || data.item_quantity == null).length > 0) {
        this.checkedItemQty = false;
      } else {
        this.checkedItemQty = true;
      }
    }
    if (this.checkItem) {
      if (this.checkedItemQty) {
        if (this.flagSuggest) {
          if (this.dataTransport.shipping_price != null) {
            if (Number(this.saveSaleOrder.payment.amount) >= 0) {
              this.saleorderService.saveSaleOrder(this.saveSaleOrder).subscribe(res => {
                let result = JSON.parse(JSON.stringify(res));
                if (result.status == 0) {
                  this.saleorderService.saveCustomerSeller(this.saveSaleOrder.customer.customer_id, this.saveSaleOrder.seller_code).subscribe(res => { });
                  this.dataCustomer.customer_sellerCode = this.sellerCode;
                  this.dataCustomer.customer_country = this.countrySo;
                  if (this.dataCustomer.customer_province) {
                    this.provinceCustomer.forEach((data) => {
                      if (data.value === this.dataCustomer.customer_province) {
                        this.dataCustomer.customer_provinceDes = data.label;
                      }
                    });
                  }
                  if (this.dataCustomer.customer_district) {
                    this.districtCustomer.forEach((data) => {
                      if (data.value === this.dataCustomer.customer_district) {
                        this.dataCustomer.customer_districtDes = data.label;
                      }
                    });
                  }
                  if (this.dataCustomer.customer_subdistrict) {
                    this.subDistrictCustomer.forEach((data) => {
                      if (data.value === this.dataCustomer.customer_subdistrict) {
                        this.dataCustomer.customer_subdistrictDes = data.label;
                      }
                    });
                  }
                  if (this.dataCustomer.customer_sex) {
                    this.optionSex.forEach((data) => {
                      if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_sex) {
                        this.dataCustomer.customer_sexDes = data.label;
                      }
                    });
                  }
                  if (this.dataCustomer.customer_occupertion) {
                    this.optionOccupation.forEach((data) => {
                      if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_occupertion) {
                        this.dataCustomer.customer_occupertionCdDes = data.label;
                      }
                    });
                  }
                  if (this.dataCustomer.customer_nationality) {
                    this.optionNationality.forEach((data) => {
                      if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_nationality) {
                        this.dataCustomer.customer_nationalityDes = data.label;
                      }
                    });
                  }
                  this.saleorderService.saveCustomerSo(this.dataCustomer).subscribe(res => {
                    console.log('saveCustomerSo',res);
                  });
                  this.messageService.add({ key: 'save', severity: 'success', /*summary: 'ORDSOR200.M00164', detail: result.message*/detail: this.translateService.instant('ORDSOR200.M00164') });
                  let url = '/ordsor/ordsor200/' + result.data.sales_order_no + '/' + result.data.warehouse_code;
                  this.redirectTo(url);
                  window.scroll(0, 0);

                } else {
                  this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: result.message });
                }
              }, err => {
                console.log(err);
              });
              this.displaySavepayment = false;
              this.messageService.clear();
            } else {
              this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00273' });
            }
          } else {
            this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00177' });
          }

        } else {
          this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00178' });
        }
      } else {
        this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00191', detail: 'ORDSOR200.M00272' });
      }
    } else {
      this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00173' });
    }
  }

  saveSOCustomer(typeSave, saveConsignee) {

    if (this.dataCustomer.customer_name == null || this.dataCustomer.customer_name == '' ||
      this.dataCustomer.customer_lastname == null ||  this.dataCustomer.customer_lastname == '' ||
      this.dataCustomer.customer_phone == null ||  this.dataCustomer.customer_phone == '' ||
      this.dataCustomer.customer_address == null || this.dataCustomer.customer_address == '' ||
      this.dataCustomer.customer_province == null ||
      this.dataCustomer.customer_postal_code == null || this.dataCustomer.customer_postal_code == '' ||

      this.dataCustomer.customer_name.length == 0 || this.dataCustomer.customer_lastname.length == 0 ||
      this.dataCustomer.customer_phone.length == 0 || this.dataCustomer.customer_address.length == 0 ||
      this.dataCustomer.customer_province.length == 0 ||
      this.dataCustomer.customer_postal_code.length == 0) {
      this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00179' });
    } else {
      if (this.dataCustomer.customer_province) {
        this.provinceCustomer.forEach((data) => {
          if (data.value === this.dataCustomer.customer_province) {
            this.dataCustomer.customer_provinceDes = data.label;
          }
        });
      }
      if (this.dataCustomer.customer_district) {
        this.districtCustomer.forEach((data) => {
          if (data.value === this.dataCustomer.customer_district) {
            this.dataCustomer.customer_districtDes = data.label;
          }
        });
      }
      if (this.dataCustomer.customer_subdistrict) {
        this.subDistrictCustomer.forEach((data) => {
          if (data.value === this.dataCustomer.customer_subdistrict) {
            this.dataCustomer.customer_subdistrictDes = data.label;
          }
        });
      }
      this.dataCustomer.customer_sellerCode = this.sellerCode;
      this.dataCustomer.customer_country = this.countrySo;
      this.saleorderService.saveCustomerSo(this.dataCustomer).subscribe(res => {
        let result = JSON.parse(JSON.stringify(res));
        console.log('result:' , result)
        if (result.status == 4) {
          if (typeSave == 1) { } else {
            this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00176', detail: result.message });
          }
        }
        else if (result.status == 0) {
          this.customerId = result.code;
          this.dataCustomer.customer_id = result.code;
          this.dataCustomer.customer_kpmemid = result.kp_memberID
          this.dataCustomer.customer_kpdfmemid = result.kp_default_memberID
          this.messageService.clear();
          if (typeSave == 1) {
            if (saveConsignee == 0) {
              this.saveInfotmation();
              this.saveReceiver(1);
            } else { }
          } else {
            this.messageService.add({ key: 'save', severity: 'success',/* summary: 'ORDSOR200.M00164', detail: result.message*/detail: this.translateService.instant('ORDSOR200.M00164') });
            this.checkSubmitCustomer = true;
            this.disableConfirmField = true;
          }

          this.disableButtonSaveCus = true;
        } else {
          if (typeSave == 1) { } else {
            this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00176', detail: result.message });
          }
        }
      }, err => {
        console.log(err);
      });
    }
  }

  changeDataCustomer(subdistrict) {

    if (subdistrict) {
      if (this.flagGetPostcode.toLowerCase() == 'subdistrict') {
        this.parameterWmsSerive.getParameterWmsBytableNoAndCode(104, subdistrict.value).subscribe((response) => {
          this.dataCustomer.customer_postal_code = response[0].parameterData.parameter_v2;
        }, err => {
          console.log(err);
        });
      }
    }
    this.disableButtonSaveCus = false;
  }

  seachProvinceByCoutry(page) {
    this.parameterwms.getAddressWms(102, page.value).subscribe(response => {
      this.provinceCustomer = JSON.parse(JSON.stringify(response));
      this.provinceConsignee = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
  }

  changeDistrict(page, flagChange) {

    this.parameterwms.getAddressWms(104, page.value).subscribe(response => {
      this.subDistrictCustomer = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);

    });

    if (this.flagGetPostcode.toLowerCase() == 'district') {
      this.parameterWmsSerive.getParameterWmsBytableNoAndCode(103, page.value).subscribe((response) => {
        this.dataCustomer.customer_postal_code = response[0].parameterData.parameter_v2;
      }, err => {
        console.log(err);
      });
    }

    if (flagChange) {
      this.dataCustomer.customer_subdistrict = null;
      this.dataCustomer.customer_postal_code = null;
      this.disableButtonSaveCus = false;
    }
  }

  changeProvince(page, flagChange) {

    this.parameterwms.getAddressWms(103, page.value).subscribe(response => {
      this.districtCustomer = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);

    });
    this.getSuggestionbox();
    if (flagChange) {
      this.dataCustomer.customer_district = null;
      this.dataCustomer.customer_subdistrict = null;
      this.dataCustomer.customer_postal_code = null;
      this.disableButtonSaveCus = false;
    }
  }

  changeDistrictConsignee(page, flagChange) {

    this.parameterwms.getAddressWms(104, page.value).subscribe(response => {
      this.subDistrictConsignee = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.getSuggestionbox();
    if (flagChange) {
      this.dataReceiver.receiver_subdistrict = null;
      this.dataReceiver.receiver_postal_code = null;
      this.disableButtonSaveConsi = false;
    }
    if (this.flagGetPostcode.toLowerCase() == 'district') {
      this.parameterWmsSerive.getParameterWmsBytableNoAndCode(103, page.value).subscribe((response) => {
        this.dataReceiver.receiver_postal_code = response[0].parameterData.parameter_v2;
      }, err => {
        console.log(err);
      });
    }
  }

  changeProvinceConsignee(page, flagChange) {
    this.parameterwms.getAddressWms(103, page.value).subscribe(response => {
      this.districtConsignee = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    if (flagChange) {
      this.dataReceiver.receiver_district = null;
      this.dataReceiver.receiver_subdistrict = null;
      this.dataReceiver.receiver_postal_code = null;
      this.disableButtonSaveConsi = false;
    }
    this.getSuggestionbox();
  }

  changeDataConsignee(subdistrict) {

    if (subdistrict) {
      if (this.flagGetPostcode.toLowerCase() == 'subdistrict') {
        this.parameterWmsSerive.getParameterWmsBytableNoAndCode(104, subdistrict.value).subscribe((response) => {
          this.dataReceiver.receiver_postal_code = response[0].parameterData.parameter_v2;
        }, err => {
          console.log(err);
        });
      }
    }

    if (this.create_at == 'wms' || this.create_at == 'ecom') {
      this.disableButtonSaveConsi = true;
    } else {
      this.disableButtonSaveConsi = false;
    }

  }

  changeDataConsigneeSuggestionbox() {
    this.getSuggestionbox();
  }

  loadfile() {
    if (this.Country == 'EN') {
      this.countryUse = 'en'
    } else {
      this.countryUse = 'lo'
    }
    this.saleorderService.getReport(this.countryUse, this.saleOrderNo, this.user.userID, this.user.userName, this.warehouse_code_view).subscribe((resultBlob: Blob) => {
      var downloadURL = URL.createObjectURL(resultBlob);
      window.open(downloadURL);
    });
  }

  public checkSubmitCustomer: boolean = false as boolean;
  saveReceiver(typeSave) {
    if (this.dataReceiver.receiver_phone == null || this.dataReceiver.receiver_phone == '' ||
      this.dataReceiver.receiver_name == null ||  this.dataReceiver.receiver_name == '' ||
      this.dataReceiver.receiver_address == null || this.dataReceiver.receiver_address == '' ||
      this.dataReceiver.receiver_province == null ||
      this.dataReceiver.receiver_postal_code == null || this.dataReceiver.receiver_postal_code == '' ||

      this.dataReceiver.receiver_phone.length == 0 || this.dataReceiver.receiver_name.length == 0 ||
      this.dataReceiver.receiver_address.length == 0 || this.dataReceiver.receiver_province.length == 0 ||
      this.dataReceiver.receiver_postal_code.length == 0) {

      this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00180' });
    } else {
      this.dataReceiver.seller_code = this.sellerCode;
      this.dataReceiver.customer_Id = this.customerId;
      this.dataReceiver.receiver_country = this.countrySo;
      if (this.checkSubmitCustomer) {
        this.saleorderService.saveCosignee(this.dataReceiver).subscribe(res => {
          let response = JSON.parse(JSON.stringify(res));
          if (response.status == 5) {
            if (typeSave == 1) { } else {
              this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00176', detail: response.message });
            }
          } else {
            let response = JSON.parse(JSON.stringify(res));
            this.dataReceiver.receiver_Id = response.code;
            this.messageService.clear();
            if (typeSave == 1) { } else {
              this.messageService.add({ key: 'save', severity: 'success', /*summary: 'ORDSOR200.M00164', detail: response.message*/detail: this.translateService.instant('ORDSOR200.M00164') });
            }
            this.disableButtonSaveConsi = true;
          }
        }, err => {
          console.log(err);
        });
      } else {
        this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00181' });
      }
    }
  }

  validateMandatoryFeilds() {
    if (this.salesOrder.country_code && this.salesOrder.warehouse_code && this.salesOrder.seller_code &&
      this.salesOrder.payment_channel_code && this.salesOrder.sale_channel_code &&
      this.salesOrder.movement_type_code && this.salesOrder.ads_id) {
      this.disableField = false;
      this.disableButtonCustomer = false;
      this.disableButtonConsi = false;
      this.addItemDisabled = false;
    }
    else {
      this.disableButtonCustomer = true;
      this.disableButtonConsi = true;
      this.addItemDisabled = true;
      this.disableField = true;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  myUploader(event, form) {
    this.converted_img = "";
    form.clear();
    let file = event.files[0];
    if (file.size < 1048576) {
      if (event.files && file) {
        let reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    } else {
      this.confirmMessage("ขนาดของไฟล์สูงสุดคือ 1 MB");
    }
  }

  confirmMessage(message: string) {
    this.messageService.clear();
    this.messageService.add({ key: 'mes', sticky: true, severity: 'error', detail: message });
  }

  onConfirmMes() {
    this.messageService.clear();
  }

  handleReaderLoaded(readerEvt) {
    let binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.converImg(this.base64textString);
  }

  converImg(img) {
    this.base64Data = img;
    if (this.base64Data == null) {
      this.converted_img = ""
    } else {
      this.converted_img = "data:image/jpeg;base64," + this.base64Data;
    }
  }

  checkvaluenull() {
    if (this.uploadFlag == true) {
      if (this.paymentDeatilForm.controls['paymentDate'].value == null || this.paymentDeatilForm.controls['paymentAmount'].value == null
        || this.paymentDeatilForm.controls['remark'].value.length == 0 || this.base64Data == null) {
        this.valuenull = true;
      } else {
        this.valuenull = false;
      }
    } else {
      if (this.paymentDeatilForm.controls['paymentDate'].value == null || this.paymentDeatilForm.controls['paymentAmount'].value == null
        || this.paymentDeatilForm.controls['remark'].value.length == 0) {
        this.valuenull = true;
      } else {
        this.valuenull = false;
      }
    }
  }

  decimalPaymentAmount() {
    this.paymentDeatilForm.controls['paymentAmount'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.paymentDeatilForm.controls['paymentAmount'].value.toString().replace(/,/g, ""), this.methodSelling, this.roundSelling, this.decimalSelling), '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en'));
  }

  savePaymentDetail() {
    this.checkvaluenull();
    if (this.valuenull == true) {
      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ORDSOR200.M00163', detail: 'ORDSOR200.M00184' });
    } else if (this.valuenull == false) {
      this.paymentDetail.amountToBePaid = Number(this.paymentDeatilForm.controls['amountToBePaid'].value);
      this.paymentDetail.bankAccount = null;
      this.paymentDetail.country = this.salesOrder.country_code;
      this.paymentDetail.creator = this.paymentDeatilForm.controls['creator'].value;
      this.paymentDetail.currency = this.salesOrder.currency_code;
      this.paymentDetail.lastUpdateUser = this.paymentDeatilForm.controls['creator'].value;
      this.paymentDetail.paymentAmount = Number(this.paymentDeatilForm.controls['paymentAmount'].value.replace(/,/g, ""));
      this.paymentDetail.paymentChannel = null;
      this.paymentDetail.paymentDate = moment(this.paymentDeatilForm.controls['paymentDate'].value).format('YYYY-MM-DD HH:mm:ss').toString();
      this.paymentDetail.paymentHeaderID = this.salesOrder.paymentHeaderID;
      this.paymentDetail.paymentImage = this.base64Data;
      this.paymentDetail.remark = this.paymentDeatilForm.controls['remark'].value;
      this.paymentDetail.saleOrderNumber = this.paymentDeatilForm.controls['saleOrderNumber'].value;
      this.paymentDetail.sellerCode = this.salesOrder.seller_code;
      this.paymentDetail.warehouseCode = this.salesOrder.warehouse_code;
      this.paymentDetail.paymentMode = this.salesOrder.payment_channel_code;
      this.paymentDetail.flowType = this.salesOrder.movement_type_code;
      this.paymentDetail.operationDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      this.saleorderService.savePaymentDetail(this.paymentDetail).subscribe(res => {
        let result = JSON.parse(JSON.stringify(res));
        if (result.status == 0) {
          this.messageService.add({ key: 'save', severity: 'success',/* summary: 'ORDSOR200.M00164', detail: result.message*/detail: this.translateService.instant('ORDSOR200.M00164') });
          window.location.reload();
        } else {
          this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00176', detail: result.message });
        }
      }, err => {
        console.log(err);
      });
      this.displaySavepayment = false;
    }
  }
  paymentChannelCheck(channel) {
    if (channel.value == "200") {
      this.disableBank = true;
      this.optionBank = null;
    } else {
      this.optionBank = this.optionBankDefalut;
      this.disableBank = false;
    }
  }

  checkvalueclose() {
    if (this.paymentDeatilForm.controls['paymentDate'].value != null ||
      this.paymentDeatilForm.controls['paymentAmount'].value != null ||
      this.paymentDeatilForm.controls['remark'].value != 0 ||
      this.base64Data != null) {
      this.valuenull = false;
    } else {
      this.valuenull = true;
    }
  }

  closedialog() {
    this.checkvalueclose();
    if (this.valuenull == false) {
      this.messageService.clear();
      this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: 'ORDSOR200.M00163', detail: this.translateService.instant('ORDSOR200.M00162') });
    } else if (this.valuenull == true) {
      this.display = false;
    }
  }

  onConfirmCanclePayment() {
    this.messageService.clear();
    this.displaySavepayment = false;
  }

  userBoxCBM() {

    if (this.checkedSameCMB) {
      this.getSuggestionbox();
    }

  }

  getSuggestionbox() {
    if (this.dataTransport.shippment_company == null || this.dataReceiver.receiver_postal_code == null) {
    } else {
      this.parameterService.getDataParameterDetail(4500, this.dataTransport.shippment_company).subscribe(response => {
        let dataParam = JSON.parse(JSON.stringify(response));
        this.shipment.denominator = dataParam.pmdtbdnumv2;

        this.shipment.country = this.countrySo;
        this.shipment.isCBM = this.checkedSameCMB;
        this.shipment.flowTypeCode = this.salesOrder.movement_type_code;
        this.shipmentItem = [];
        this.dataTableMaintenance.forEach(item => {
          this.shipmentItem.push({
            height: item.item_height,
            itemCode: item.item_code,
            itemQty: item.item_quantity,
            lenght: item.item_length,
            weight: item.item_weight,
            width: item.item_width
          });
        });

        this.shipment.itemLogisticList = this.shipmentItem;
        this.parameterService.getDiscoutPrice(4500, this.countrySo, this.dataTransport.shippment_company).subscribe(res => {
          let dataLogistic = JSON.parse(JSON.stringify(res));
          if (dataLogistic) {
            this.shipment.lgtCode = dataLogistic.pmdtbdtxtv2;
          }
          this.shipment.postCode = this.dataReceiver.receiver_postal_code;
          this.saleorderService.getSugguestionBox(this.shipment).subscribe(res => {
            let respones = JSON.parse(JSON.stringify(res));
            this.dataTransport.guide_size_box = respones.data.suggestionbox;
            this.dataTransport.shipping_price = respones.data.shippingPrice == null ? 0 : respones.data.shippingPrice;
            this.dataTransport.total_weight = respones.data.overallWeight;
            this.dataTransport.volumetric_weight = respones.data.overalleightByVolume;
            this.dataTransport.shipping_price_customer = this.dataTransport.shipping_price;
            this.dataTransport.klangoen_price = 0;
            this.dataTransport.discount_seller = 0;
            this.dataTransport.discount_other_price = 0;
            this.dataTransport.klangone_code = null;
            this.dataTransport.discount_other_code = null;
            this.flagSuggest = respones.data.flagSuggest;

            this.checkValueEqual(this.dataTransport.shipping_price);
            this.discountOrder.realPrice = this.decimalService.getRoundingDecimal(Number(this.discountOrder.realPrice), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
            this.dataPayment.amount = Number(this.discountOrder.realPrice) + Number(this.dataTransport.shipping_price_customer);
            this.dataPayment.amount = this.decimalService.getRoundingDecimal(Number(this.dataPayment.amount), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);

            if (this.salesOrder.payment_channel_code == "100") {
              this.dataPayment.cod_price = dataParam.pmdtbdnumv1 * Math.round(this.dataPayment.amount);
            } else {
              this.dataPayment.cod_price = 0;
            }
            this.dataPayment.amount = this.decimalService.getRoundingDecimal(Number(this.dataPayment.amount), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
            this.dataPayment.cod_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.cod_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
            this.dataPayment.total_price = Number(this.dataPayment.amount) + Number(this.dataPayment.cod_price);
            this.dataPayment.total_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
            this.calculateDiscountTranSport(this.dataTransport.discount_seller);
          })
        });
      });
    }
  }

  saveSaleOrderAndCustomer() {
    if (this.dataCustomer.customer_name == null|| this.dataCustomer.customer_lastname == null ||
      this.dataCustomer.customer_phone == null || this.dataCustomer.customer_address == null || this.dataCustomer.customer_province == null ||
      this.dataCustomer.customer_postal_code == null) {
      this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00179' });
    } else {
      this.saveSaleOrder.item = this.dataTableMaintenance;
      if (this.saveSaleOrder.item == null || this.saveSaleOrder.item.length == 0) {
        this.checkedItemQty = true;
        this.checkItem = false;
      } else {
        this.checkItem = true;
        if (this.saveSaleOrder.item.filter(data => data.item_quantity == 0 || data.item_quantity == null).length > 0) {
          this.checkedItemQty = false;
        } else {
          this.checkedItemQty = true;
        }
      }
      if (this.checkItem) {
        if (this.checkedItemQty) {
          if (this.flagSuggest) {
            if (this.dataTransport.shipping_price != null) {
              this.dataCustomer.customer_sellerCode = this.sellerCode;
              this.dataCustomer.customer_country = this.countrySo;
              if (this.dataCustomer.customer_province) {
                this.provinceCustomer.forEach((data) => {
                  if (data.value === this.dataCustomer.customer_province) {
                    this.dataCustomer.customer_provinceDes = data.label;
                  }
                });
              }
              if (this.dataCustomer.customer_district) {
                this.districtCustomer.forEach((data) => {
                  if (data.value === this.dataCustomer.customer_district) {
                    this.dataCustomer.customer_districtDes = data.label;
                  }
                });
              }
              if (this.dataCustomer.customer_subdistrict) {
                this.subDistrictCustomer.forEach((data) => {
                  if (data.value === this.dataCustomer.customer_subdistrict) {
                    this.dataCustomer.customer_subdistrictDes = data.label;
                  }
                });
              }
              if (this.dataCustomer.customer_sex) {
                this.optionSex.forEach((data) => {
                  if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_sex) {
                    this.dataCustomer.customer_sexDes = data.label;
                  }
                });
              }
              if (this.dataCustomer.customer_occupertion) {
                this.optionOccupation.forEach((data) => {
                  if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_occupertion) {
                    this.dataCustomer.customer_occupertionCdDes = data.label;
                  }
                });
              }
              if (this.dataCustomer.customer_nationality) {
                this.optionNationality.forEach((data) => {
                  if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_nationality) {
                    this.dataCustomer.customer_nationalityDes = data.label;
                  }
                });
              }
              this.saleorderService.saveCustomerSo(this.dataCustomer).subscribe(res => {
                let result = JSON.parse(JSON.stringify(res));
                if (result.status == 4) {
                  this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00176', detail: result.message });
                }
                else if (result.status == 0) {
                  this.customerId = result.code;
                  this.dataCustomer.customer_id = result.code;
                  this.messageService.clear();
                  this.messageService.add({ key: 'save', severity: 'success',/* summary: 'ORDSOR200.M00164', detail: result.message*/detail: this.translateService.instant('ORDSOR200.M00164') });
                  this.checkSubmitCustomer = true;
                  this.disableButtonSaveCus = true;
                  this.saveSaleOrder = this.salesOrder;
                  this.saveSaleOrder.country_code = this.salesOrder.country_code;

                  this.saveSaleOrder.customer = this.dataCustomer;
                  this.saveSaleOrder.receiver = this.dataReceiver;
                  this.saveSaleOrder.shippment = this.dataTransport;
                  this.saveSaleOrder.discountSo = this.discountOrder;
                  this.saveSaleOrder.payment = this.dataPayment;
                  this.saveSaleOrder.item = this.dataTableMaintenance;
                  this.saveSaleOrder.useSameCustomer = String(this.checkedSameAddress);
                  this.saveSaleOrder.shippment.use_box_warehouse = String(this.checkedSameWarehouse);
                  this.saveSaleOrder.shippment.use_box_cmd = String(this.checkedSameCMB);
                  this.saveSaleOrder.create_date = moment(this.saveSaleOrder.create_date).format('YYYY-MM-DD HH:mm:ss');

                  if (Number(this.saveSaleOrder.payment.amount) >= 0) {
                    this.saleorderService.saveSaleOrder(this.saveSaleOrder).subscribe(res => {
                      let result = JSON.parse(JSON.stringify(res));
                      if (result.status == 0) {
                        this.saleorderService.saveCustomerSeller(this.saveSaleOrder.customer.customer_id, this.saveSaleOrder.seller_code).subscribe(res => { });
                        this.messageService.add({ key: 'save', severity: 'success', /*summary: 'ORDSOR200.M00164', detail: result.message*/detail: this.translateService.instant('ORDSOR200.M00164') });
                        let url = '/ordsor/ordsor200/' + result.data.sales_order_no + '/' + result.data.warehouse_code;
                        this.redirectTo(url);
                        window.scroll(0, 0);
                      } else {
                        this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: result.message });
                      }
                    }, err => {
                      console.log(err);
                    });

                    this.displaySavepayment = false;
                    this.messageService.clear();
                  } else {
                    this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00273' });
                  }

                } else {
                  this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00176', detail: result.message });
                }
              }, err => {
                console.log(err);
              });
            } else {
              this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00177' });
            }
          } else {
            this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00176', detail: 'ORDSOR200.M00178' });
          }
        } else {
          this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00191', detail: 'ORDSOR200.M00272' });
        }
      } else {
        this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00173' });
      }
    }
  }

  saveSaleOrderAndReceive() {
    if (this.dataReceiver.receiver_phone == null || this.dataReceiver.receiver_name == null ||
      this.dataReceiver.receiver_address == null || this.dataReceiver.receiver_province == null || this.dataReceiver.receiver_postal_code == null) {
      this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00180' });
    } else {
      this.saveSaleOrder.item = this.dataTableMaintenance;
      if (this.saveSaleOrder.item == null || this.saveSaleOrder.item.length == 0) {
        this.checkedItemQty = true;
        this.checkItem = false;
      } else {
        this.checkItem = true;
        if (this.saveSaleOrder.item.filter(data => data.item_quantity == 0 || data.item_quantity == null).length > 0) {
          this.checkedItemQty = false;
        } else {
          this.checkedItemQty = true;
        }
      }
      if (this.checkItem) {
        if (this.checkedItemQty) {
          if (this.flagSuggest) {
            if (this.dataTransport.shipping_price != null) {
              this.dataReceiver.seller_code = this.sellerCode;
              this.dataReceiver.customer_Id = this.customerId;
              this.dataReceiver.receiver_country = this.countrySo;
              this.saleorderService.saveCosignee(this.dataReceiver).subscribe(res => {
                let response = JSON.parse(JSON.stringify(res));
                if (response.status == 5) {
                  this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00176', detail: response.message });
                } else {
                  let response = JSON.parse(JSON.stringify(res));
                  this.dataReceiver.receiver_Id = response.code;
                  this.messageService.clear();
                  this.checkSubmitCustomer = false;
                  this.disableButtonSaveConsi = true;
                  this.saveSaleOrder = this.salesOrder;
                  this.saveSaleOrder.country_code = this.salesOrder.country_code;

                  this.saveSaleOrder.customer = this.dataCustomer;
                  this.saveSaleOrder.receiver = this.dataReceiver;
                  this.saveSaleOrder.shippment = this.dataTransport;
                  this.saveSaleOrder.discountSo = this.discountOrder;
                  this.saveSaleOrder.payment = this.dataPayment;
                  this.saveSaleOrder.item = this.dataTableMaintenance;
                  this.saveSaleOrder.useSameCustomer = String(this.checkedSameAddress);
                  this.saveSaleOrder.shippment.use_box_warehouse = String(this.checkedSameWarehouse);
                  this.saveSaleOrder.shippment.use_box_cmd = String(this.checkedSameCMB);
                  this.saveSaleOrder.create_date = moment(this.saveSaleOrder.create_date).format('YYYY-MM-DD HH:mm:ss');

                  if (Number(this.saveSaleOrder.payment.amount) >= 0) {
                    this.saleorderService.saveSaleOrder(this.saveSaleOrder).subscribe(res => {
                      let result = JSON.parse(JSON.stringify(res));

                      if (result.status == 0) {
                        this.saleorderService.saveCustomerSeller(this.saveSaleOrder.customer.customer_id, this.saveSaleOrder.seller_code).subscribe(res => { });
                        this.dataCustomer.customer_sellerCode = this.sellerCode;
                        this.dataCustomer.customer_country = this.countrySo;
                        if (this.dataCustomer.customer_province) {
                          this.provinceCustomer.forEach((data) => {
                            if (data.value === this.dataCustomer.customer_province) {
                              this.dataCustomer.customer_provinceDes = data.label;
                            }
                          });
                        }
                        if (this.dataCustomer.customer_district) {
                          this.districtCustomer.forEach((data) => {
                            if (data.value === this.dataCustomer.customer_district) {
                              this.dataCustomer.customer_districtDes = data.label;
                            }
                          });
                        }
                        if (this.dataCustomer.customer_subdistrict) {
                          this.subDistrictCustomer.forEach((data) => {
                            if (data.value === this.dataCustomer.customer_subdistrict) {
                              this.dataCustomer.customer_subdistrictDes = data.label;
                            }
                          });
                        }
                        if (this.dataCustomer.customer_sex) {
                          this.optionSex.forEach((data) => {
                            if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_sex) {
                              this.dataCustomer.customer_sexDes = data.label;
                            }
                          });
                        }
                        if (this.dataCustomer.customer_occupertion) {
                          this.optionOccupation.forEach((data) => {
                            if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_occupertion) {
                              this.dataCustomer.customer_occupertionCdDes = data.label;
                            }
                          });
                        }
                        if (this.dataCustomer.customer_nationality) {
                          this.optionNationality.forEach((data) => {
                            if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_nationality) {
                              this.dataCustomer.customer_nationalityDes = data.label;
                            }
                          });
                        }
                        this.saleorderService.saveCustomerSo(this.dataCustomer).subscribe(res => {
                          console.log('saveCustomerSo',res);
                        });
                        this.messageService.add({ key: 'save', severity: 'success',/* summary: 'ORDSOR200.M00164', detail: result.message*/detail: this.translateService.instant('ORDSOR200.M00164') });
                        let url = '/ordsor/ordsor200/' + result.data.sales_order_no + '/' + result.data.warehouse_code;
                        this.redirectTo(url);
                        window.scroll(0, 0);
                      } else {
                        this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: result.message });
                      }
                    }, err => {
                      console.log(err);
                    });
                    this.displaySavepayment = false;
                    this.messageService.clear();
                  } else {
                    this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00273' });
                  }
                }
              }, err => {
                console.log(err);
              });
            } else {
              this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00177' });
            }
          } else {
            this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00176', detail: 'ORDSOR200.M00178' });
          }
        } else {
          this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00191', detail: 'ORDSOR200.M00272' });
        }
      } else {
        this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00173' });
      }
    }
  }

  saveSaleOrderAll() {

    if (this.dataCustomer.customer_name == null || this.dataCustomer.customer_name == '' ||
      this.dataCustomer.customer_lastname == null ||  this.dataCustomer.customer_lastname == '' ||
      this.dataCustomer.customer_phone == null ||  this.dataCustomer.customer_phone == '' ||
      this.dataCustomer.customer_address == null || this.dataCustomer.customer_address == '' ||
      this.dataCustomer.customer_province == null ||
      this.dataCustomer.customer_postal_code == null || this.dataCustomer.customer_postal_code == '' ) {
      this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00179' });
    } else {
      this.saveSaleOrder.item = this.dataTableMaintenance;
      if (this.saveSaleOrder.item == null || this.saveSaleOrder.item.length == 0) {
        this.checkedItemQty = true;
        this.checkItem = false;
      } else {
        this.checkItem = true;
        if (this.saveSaleOrder.item.filter(data => data.item_quantity == 0 || data.item_quantity == null).length > 0) {
          this.checkedItemQty = false;
        } else {
          this.checkedItemQty = true;
        }
      }
      if (this.checkItem) {
        if (this.checkedItemQty) {
          if (this.flagSuggest) {
            if (this.dataTransport.shipping_price != null) {
              this.dataCustomer.customer_sellerCode = this.sellerCode;
              this.dataCustomer.customer_country = this.countrySo;
              if (this.dataCustomer.customer_province) {
                this.provinceCustomer.forEach((data) => {
                  if (data.value === this.dataCustomer.customer_province) {
                    this.dataCustomer.customer_provinceDes = data.label;
                  }
                });
              }
              if (this.dataCustomer.customer_district) {
                this.districtCustomer.forEach((data) => {
                  if (data.value === this.dataCustomer.customer_district) {
                    this.dataCustomer.customer_districtDes = data.label;
                  }
                });
              }
              if (this.dataCustomer.customer_subdistrict) {
                this.subDistrictCustomer.forEach((data) => {
                  if (data.value === this.dataCustomer.customer_subdistrict) {
                    this.dataCustomer.customer_subdistrictDes = data.label;
                  }
                });
              }
              if (this.dataCustomer.customer_sex) {
                this.optionSex.forEach((data) => {
                  if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_sex) {
                    this.dataCustomer.customer_sexDes = data.label;
                  }
                });
              }
              if (this.dataCustomer.customer_occupertion) {
                this.optionOccupation.forEach((data) => {
                  if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_occupertion) {
                    this.dataCustomer.customer_occupertionCdDes = data.label;
                  }
                });
              }
              if (this.dataCustomer.customer_nationality) {
                this.optionNationality.forEach((data) => {
                  if (data.value.parameterDetailPK.pmdtbdtbdentcd === this.dataCustomer.customer_nationality) {
                    this.dataCustomer.customer_nationalityDes = data.label;
                  }
                });
              }
              this.saleorderService.saveCustomerSo(this.dataCustomer).subscribe(res => {
                let result = JSON.parse(JSON.stringify(res));
                if (result.status == 4) {
                  this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00176', detail: result.message });
                }
                else if (result.status == 0) {
                  this.customerId = result.code;
                  this.dataCustomer.customer_id = result.code;
                  this.checkSubmitCustomer = true;
                  this.disableButtonSaveCus = true;
                  if (this.dataReceiver.receiver_phone == null || this.dataReceiver.receiver_name == null ||
                    this.dataReceiver.receiver_address == null || this.dataReceiver.receiver_province == null || this.dataReceiver.receiver_postal_code == null) {
                    this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00180' });
                  } else {
                    this.dataReceiver.seller_code = this.sellerCode;
                    this.dataReceiver.customer_Id = this.customerId;
                    this.dataReceiver.receiver_country = this.countrySo;
                    if (this.checkSubmitCustomer) {
                      this.saleorderService.saveCosignee(this.dataReceiver).subscribe(res => {
                        let response = JSON.parse(JSON.stringify(res));
                        if (response.status == 5) {
                          this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00176', detail: response.message });
                        } else {
                          let response = JSON.parse(JSON.stringify(res));
                          this.dataReceiver.receiver_Id = response.code;
                          this.messageService.clear();
                          this.checkSubmitCustomer = false;
                          this.disableButtonSaveConsi = true;
                          this.saveSaleOrder = this.salesOrder;
                          this.saveSaleOrder.country_code = this.salesOrder.country_code;

                          this.saveSaleOrder.customer = this.dataCustomer;
                          this.saveSaleOrder.receiver = this.dataReceiver;
                          this.saveSaleOrder.shippment = this.dataTransport;
                          this.saveSaleOrder.discountSo = this.discountOrder;
                          this.saveSaleOrder.payment = this.dataPayment;
                          this.saveSaleOrder.item = this.dataTableMaintenance;
                          this.saveSaleOrder.useSameCustomer = String(this.checkedSameAddress);
                          this.saveSaleOrder.shippment.use_box_warehouse = String(this.checkedSameWarehouse);
                          this.saveSaleOrder.shippment.use_box_cmd = String(this.checkedSameCMB);
                          this.saveSaleOrder.create_date = moment(this.saveSaleOrder.create_date).format('YYYY-MM-DD HH:mm:ss');

                          if (Number(this.saveSaleOrder.payment.amount) >= 0) {
                            this.saleorderService.saveSaleOrder(this.saveSaleOrder).subscribe(res => {
                              let result = JSON.parse(JSON.stringify(res));

                              if (result.status == 0) {
                                this.saleorderService.saveCustomerSeller(this.saveSaleOrder.customer.customer_id, this.saveSaleOrder.seller_code).subscribe(res => { });
                                this.messageService.add({ key: 'save', severity: 'success',/* summary: 'ORDSOR200.M00164', detail: result.message*/detail: this.translateService.instant('ORDSOR200.M00164') });
                                let url = '/ordsor/ordsor200/' + result.data.sales_order_no + '/' + result.data.warehouse_code;
                                this.redirectTo(url);
                                window.scroll(0, 0);
                              } else {
                                this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00174', detail: result.message });
                              }
                            }, err => {
                              console.log(err);
                            });
                            this.displaySavepayment = false;
                            this.messageService.clear();
                          } else {
                            this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00273' });
                          }
                        }
                      }, err => {
                        console.log(err);
                      });
                    } else {
                      this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00181' });
                    }
                  }
                } else {
                  this.messageService.add({ key: 'alert', severity: 'error', summary: 'ORDSOR200.M00176', detail: result.message });
                }
              }, err => {
                console.log(err);
              });
            } else {
              this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00177' });
            }
          } else {
            this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00176', detail: 'ORDSOR200.M00178' });
          }
        } else {
          this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00191', detail: 'ORDSOR200.M00272' });
        }
      } else {
        this.messageService.add({ key: 'alert', severity: 'warn', summary: 'ORDSOR200.M00174', detail: 'ORDSOR200.M00173' });
      }
    }

  }

  public RecalFunction(): void {
    this.discountOrder.totalPriceOrder = this.decimalService.getRoundingDecimal(Number(this.discountOrder.totalPriceOrder), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.discountOrder.sumOrder = this.discountOrder.totalPriceOrder - (this.discountOrder.discountBySeller + this.discountOrder.discountKlangOnePrice + this.discountOrder.discountSaleChannelPrice);
    if (Number(this.discountOrder.sumOrder) < 0) {
      this.discountOrder.sumOrder = 0;
    }
    this.discountOrder.sumOrder = this.decimalService.getRoundingDecimal(Number(this.discountOrder.sumOrder), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    this.discountOrder.realPrice = Number(this.discountOrder.sumOrder) + Number(this.discountOrder.vat);
    this.discountOrder.realPrice = this.decimalService.getRoundingDecimal(Number(this.discountOrder.realPrice), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);

    this.dataPayment.amount = Number(this.dataTransport.shipping_price_customer) + Number(this.discountOrder.realPrice);
    if (this.dataPayment.amount !== this.dataPayment.amount) {
      this.dataPayment.amount = 0;
    }
    if (Number(this.dataPayment.amount) < 0) {
      this.dataPayment.amount = 0;
    }
    this.dataPayment.amount = this.decimalService.getRoundingDecimal(Number(this.dataPayment.amount), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    // COD = มูลค่าสุทธิ * parameter
    if (this.salesOrder.payment_channel_code == '100') {
      if ((this.dataPayment.amount && this.dataPayment.amount != 0) && (this.dataTransport.shippment_company)) {
        this.parameterService.getDataParameterDetail(4500, this.dataTransport.shippment_company).subscribe(response => {
          let dataParam = JSON.parse(JSON.stringify(response));
          this.dataPayment.cod_price = dataParam.pmdtbdnumv1 * this.dataPayment.amount;
          this.dataPayment.cod_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.cod_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
          if (Number(this.dataPayment.cod_price) < 0) {
            this.dataPayment.cod_price = Number(this.dataPayment.cod_price) * -1;
          }
          if (this.dataPayment.code_charge != 'C') {
            this.dataPayment.amount = this.decimalService.getRoundingDecimal(Number(this.dataPayment.amount), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
            this.dataPayment.total_price = this.dataPayment.amount;
            this.dataPayment.total_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
          } else {
            this.dataPayment.amount = this.decimalService.getRoundingDecimal(Number(this.dataPayment.amount), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
            this.dataPayment.total_price = Number(this.dataPayment.amount) + Number(this.dataPayment.cod_price);
            this.dataPayment.total_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
          }
        });
      } else {
        this.dataPayment.total_price = this.dataPayment.amount;
        this.dataPayment.cod_price = this.decimalService.getRoundingDecimal(Number(0), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);;
      }
    } else {
      this.dataPayment.total_price = this.dataPayment.amount;
      this.dataPayment.cod_price = this.decimalService.getRoundingDecimal(Number(0), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);;
    }
  }

  public CodChargeChange(data: string): void {
    if (data != 'C') {
      this.dataPayment.amount = this.decimalService.getRoundingDecimal(Number(this.dataPayment.amount), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
      this.dataPayment.total_price = this.dataPayment.amount;
      this.dataPayment.total_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    } else {
      this.dataPayment.amount = this.decimalService.getRoundingDecimal(Number(this.dataPayment.amount), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
      this.dataPayment.cod_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.cod_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
      this.dataPayment.total_price = Number(this.dataPayment.amount) + Number(this.dataPayment.cod_price);
      this.dataPayment.total_price = this.decimalService.getRoundingDecimal(Number(this.dataPayment.total_price), this.dataTypeDecimalRoundMethod.pmdtbdtxtv1, this.dataTypeDecimalRound.pmdtbdnumv1, this.dataTypeDecimalSelling.pmdtbdnumv1);
    }
  }

  SaveUUIDSOLanding() {
    const myId = uuid.v4();
    this.saveSaleOrder = this.salesOrder;
    this.saveSaleOrder.country_code = this.salesOrder.country_code;
    this.saveSaleOrder.sale_channel_code = this.salesOrder.sale_channel_code;
    this.saveSaleOrder.customer = this.dataCustomer;
    this.saveSaleOrder.receiver = this.dataReceiver;
    this.saveSaleOrder.item = this.dataTableMaintenance;
    this.saveSaleOrder.shippment = this.dataTransport;
    this.saveSaleOrder.discountSo = this.discountOrder;
    this.saveSaleOrder.payment = this.dataPayment;
    this.saveSaleOrder.useSameCustomer = String(this.checkedSameAddress);
    this.saveSaleOrder.shippment.use_box_warehouse = String(this.checkedSameWarehouse);
    this.saveSaleOrder.shippment.use_box_cmd = String(this.checkedSameCMB);
    this.saveSaleOrder.create_date = moment(this.saveSaleOrder.create_date).format('YYYY-MM-DD HH:mm:ss');
    this.saveSaleOrder.uuidSOForSeller = myId;
    this.saveSaleOrder.longUUidSOForSeller = window.location.host + '/ordsor/ordsor300/' + myId;
    this.saveSaleOrder.userName = this.user.userName ? this.user.userName : this.user.userDesc;
    if (this.urlSwitchy) {
      this.saveSaleOrder.switchyLink = this.urlSwitchy;
    }
    this.saleorderService.saveSolandingUUID(this.saveSaleOrder).subscribe(res => {
      if (res) {
        let url = '/ordsor/ordsor200/' + this.saleOrderNo + '/' + this.warehouse_code_view;
        this.redirectTo(url);
        window.scroll(0, 0);
      }
    })
  }

  genURLSwitchy() {
    this.saveSaleOrder = this.salesOrder;
    this.saveSaleOrder.country_code = this.salesOrder.country_code;
    this.saveSaleOrder.sale_channel_code = this.salesOrder.sale_channel_code;
    this.saveSaleOrder.customer = this.dataCustomer;
    this.saveSaleOrder.receiver = this.dataReceiver;
    this.saveSaleOrder.item = this.dataTableMaintenance;
    this.saveSaleOrder.shippment = this.dataTransport;
    this.saveSaleOrder.discountSo = this.discountOrder;
    this.saveSaleOrder.payment = this.dataPayment;
    this.saveSaleOrder.useSameCustomer = String(this.checkedSameAddress);
    this.saveSaleOrder.shippment.use_box_warehouse = String(this.checkedSameWarehouse);
    this.saveSaleOrder.shippment.use_box_cmd = String(this.checkedSameCMB);
    this.saveSaleOrder.create_date = moment(this.saveSaleOrder.create_date).format('YYYY-MM-DD HH:mm:ss');
    this.saveSaleOrder.userName = this.user.userName ? this.user.userName : this.user.userDesc;
    if (this.uuidSOLandingLink) {
      this.saveSaleOrder.uuidSOForSeller = this.uuidSOLanding;
      this.saveSaleOrder.longUUidSOForSeller = this.uuidSOLandingLink;
    }
    if (this.salesOrder.switchy_custom_domain_name) {
      this.saveSaleOrder.switchyDomain = this.salesOrder.switchy_custom_domain_name;
      this.saveSaleOrder.saleOrderLink = this.salesOrder.long_link_en;
      this.saleorderService.genURLSwitchy(this.saveSaleOrder).subscribe(res => {
        let result = JSON.parse(JSON.stringify(res));

        if (result.sudsorswc) {
          this.checkUrlSwitchy = result.sudsorswc;
          let url = '/ordsor/ordsor200/' + this.saleOrderNo + '/' + this.warehouse_code_view;
          this.redirectTo(url);
          window.scroll(0, 0);
        } else if (result.errorCode == "406") {
          this.messageService.add({ key: 'alert', sticky: true, severity: 'error', summary: 'ORDSOR200.M00283', detail: 'ORDSOR200.M00284' });
        }
      }, err => {
        console.log(err);
      });
    } else {
      this.messageService.add({ key: 'alert', sticky: true, severity: 'error', summary: 'ORDSOR200.M00283', detail: 'ORDSOR200.M00284' });
    }

  }

  LinkUUIDSOLanding() {
    let url = '/ordsor/ordsor300/' + this.uuidSOLanding
    window.open(url);
  }

  LinkToSwitchy() {
    let url = this.urlSwitchy;
    window.open(url);
  }

  CopyUUIDSOLanding() {
    if (this.uuidSOLanding) {
      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', (window.location.host + '/ordsor/ordsor300/' + this.uuidSOLanding));
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      document.execCommand('copy');
      this.messageService.add({ key: 'copy', severity: 'success', summary: 'ORDSOR200.M00189', detail: 'ORDSOR200.M00190' });
    }
  }

  CopySwitchyLink() {
    if (this.urlSwitchy) {
      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', (this.urlSwitchy));
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      document.execCommand('copy');
      this.messageService.add({ key: 'copy', severity: 'success', summary: 'ORDSOR200.M00189', detail: 'ORDSOR200.M00190' });
    }
  }

  checkPermission() {
    let checkPermission = this.permissionData.accActionFalse.filter(act => act == "1");
    let checkPermissionConfirm = this.permissionData.accActionFalse.filter(act => act == "2");
    let checkPermissionCancel = this.permissionData.accActionFalse.filter(act => act == "4");

    if (checkPermission.length > 0) {
      this.disablePermission = true;
    } else {
      this.disablePermission = false;
    }

    if (checkPermissionConfirm.length > 0) {
      this.disableConfirmPermission = true;
    } else {
      this.disableConfirmPermission = false;
    }

    if (checkPermissionCancel.length > 0) {
      this.disableCancalPermission = true;
    } else {
      this.disableCancalPermission = false;
    }

  }

  checkDiscount() {
    if (this.discountOrder.discountBySeller > this.discountOrder.totalPriceOrder) {
      this.messageService.add({ key: 'alert',sticky: true, severity: 'warn', summary: 'ORDSOR200.M00285'});
    }
  }

  checkDiscountTransport() {
    if (this.dataTransport.discount_seller > this.dataTransport.shipping_price) {
      this.messageService.add({ key: 'alert',sticky: true, severity: 'warn', summary: 'ORDSOR200.M00286'});
    }
  }

  checkEditSave() {
    this.disableButtonEditSave = false;
    this.disableButtonConfirm = true;
  }

  dataChange() {
    this.dataChangeFlag = true;
  }

  onPaste(event: ClipboardEvent, controlName: string): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');

    const sanitizedText = pastedText.replace(/[^0-9+]/g, '');

    event.preventDefault();
    const input = event.target as HTMLInputElement;
    input.value = sanitizedText;

    this.dataCustomer.customer_phone = input.value;
  }

  setTempPhoneNumber() {
    this.tempPhoneNumber = this.dataCustomer.customer_phone
  }

  focusOutValidatePhoneNumber(field: string) {
    if (this.tempPhoneNumber != this.dataCustomer.customer_phone) {
      setTimeout(() => {
        if ((!this.customerId) && (this.customerPhoneTemp != this.dataCustomer.customer_phone) && (this.dataCustomer.customer_phone != '')) {
          let customerInquiry: CustomerInquiryRequest = {} as CustomerInquiryRequest;
          customerInquiry.phoneNumber = this.dataCustomer.customer_phone;
          this.customerService.getCustomerPhone(customerInquiry).subscribe((CustomerList: Array<CustomerInquiryDetailModel>) => {
            this.customerPhoneList = CustomerList;
            let temp_phone_number = this.customerPhoneList
            let isDuplicate = false;
            temp_phone_number.forEach(
              (customer) => {
                const temp = {
                  Phone1: customer.phoneNumber ? customer.phoneNumber.replace(/^\+/, '') : null,
                  Phone2: customer.phoneNumber2 ? customer.phoneNumber2.replace(/^\+/, '') : null,
                  Phone3: customer.phoneNumber3 ? customer.phoneNumber3.replace(/^\+/, '') : null,
                  Phone4: customer.phoneNumber4 ? customer.phoneNumber4.replace(/^\+/, '') : null,
                  Phone5: customer.phoneNumber5 ? customer.phoneNumber5.replace(/^\+/, '') : null
                };
                if (!isDuplicate) {
                  isDuplicate = Object.values(temp).includes(this.dataCustomer.customer_phone);
                }
              }
            )
            if (isDuplicate && this.dataCustomer.customer_phone) {
              this.messageService.add({ key: 'errornumber', sticky: true, severity: 'error', detail: this.translateService.instant('CUSCUS200.M00329') });
            }
            this.customerPhoneTemp = this.dataCustomer.customer_phone
          });
        }
      }, 300);
    }
  }

  createOreder() {
    let url = '/ordsor/ordsor200'
    this.dataChangeFlag = false;
    this.messageService.clear('createOreder');
    this.redirectTo(url);
  }

  createOrederInfo() {
    if (this.dataChangeFlag == true) {
      this.messageService.add({ key: 'createOreder', severity: 'warn', sticky: true, summary: 'ORDSOR200.M00263', detail: 'ORDSOR200.M00183' });
    } else {
      this.createOreder();
    }
  }

  clearDataReceiver() {
    this.checkedSameAddress = false;
    this.disableFieldConsignee = false;
    this.dataReceiver = {} as ReceiverModel;
  }

  caculateExpectedDate(flowType: number) {
    let shippemt_expected_date = new Date();

    if (flowType == 1) {
      this.parameterService.getvparameterDetailToLable(4205).subscribe((response) => {
        let result = JSON.parse(JSON.stringify(response));
        shippemt_expected_date.setDate(shippemt_expected_date.getDate() + result[0].value.pmdtbdnumv1);
        this.dataTransport.shippemt_expected_date = moment(shippemt_expected_date).format('DD/MM/YYYY');

      });
    } else if (flowType == 3) {
      this.parameterService.getvparameterDetailToLable(4205).subscribe((response) => {
        let result = JSON.parse(JSON.stringify(response));
        shippemt_expected_date.setDate(shippemt_expected_date.getDate() + result[0].value.pmdtbdnumv2);
        this.dataTransport.shippemt_expected_date = moment(shippemt_expected_date).format('DD/MM/YYYY');
      });
    }
  }

  onRowSelect(data) {
    this.cur_bal_stk = data.cur_bal_stk;
    this.cur_avai_stk = data.cur_avai_stk;
  }

  setDefaultVat() {
    this.parameterService.getDiscoutPrice(201, this.countrySo, 100).subscribe(response => {
      let result = JSON.parse(JSON.stringify(response));
      this.parameterService.getDiscoutPrice(200, this.countrySo, result.pmdtbdtxtv2).subscribe(res => {
        let resultMap = JSON.parse(JSON.stringify(res));
        if (result.pmdtbdtxtv1 == 'Y') {
          this.salesOrder.vat = Number(resultMap.pmdtbdtxtv2);
        } else {
          this.salesOrder.vat = null;
        }
      });
    });
  }

  getParameterPayment() {
    this.parameterwms.getParameterWms(9144).subscribe((res) => {
      this.payment = res;
    })
  }

  changeExpectedDate() {
    this.dataTransport.shippemt_expected_date = moment(this.dataTransport.shippemt_expected_date).format('DD/MM/YYYY');
  }

  setShipmentConfirmStatus(){
    if(this.salesOrder.payment_channel_code == "100" && this.salesOrder.movement_type_code =="1") {
      this.salesOrder.shipFlag = "1";
    }else {
      this.salesOrder.shipFlag = "0";
    }
  }
}
