import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { ExchangeRateCriteriaRequest, ExchangeRateDelete, excRate } from '../excexc/excexc100/model/excexc100.model';

@Injectable({
  providedIn: 'root'
})
export class Excexc100Service {

    private URL: string = `${this.evmService.getApiUrl()}/exc/excexc` as string;

    constructor(private http: HttpClient,
        private evmService: EnvironmentService,
        private autService: AuthenticationService) { 
    } 

    getAllExchangeRate():Observable<excRate> {    
        const URL: string = `${this.URL}/getExchangeRateInquiry` as string;
        return this.http.get<excRate>(URL, { headers: this.autService.tokenHeader()});
    }

    getExchangeRateByCriteria(exchangeRateCriteriaRequest: ExchangeRateCriteriaRequest):Observable<excRate> {    
        const URL: string = `${this.URL}/getExchangeRateByCriteria` as string;
        return this.http.post<excRate>(URL, exchangeRateCriteriaRequest, { headers: this.autService.tokenHeader()});
    }

    getExchangeRateDelete(exchangeRateDelete: ExchangeRateDelete):Observable<ExchangeRateDelete> {    
       console.log('exchangeRateDelete',exchangeRateDelete);
       
        const URL: string = `${this.URL}/getExchangeRateDelete` as string;
        return this.http.post<ExchangeRateDelete>(URL, exchangeRateDelete, { headers: this.autService.tokenHeader()});
    }

}
