import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { truncateSync } from 'fs';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as uuid from 'uuid';
import { TranslateService } from '@ngx-translate/core';
import { DashboardSaleService } from 'src/app/shared/services/dashboard-sale.service';
import { Dshsal100Model, FormModel, ResponseDshPieChartSaleOrderPeriod, ResponseDshSaleOrder, ResponseDshSaleOrderPeriod, ResponseDshTableOrderSaleOrderPeriod, ResponseDshTableSaleOrderPeriod, ResponseSaleSummaryByPeriod, SaleSummaryByPeriod, TempDDL } from '../model/dshsal100.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';
import { SalesOrderrService } from 'src/app/shared/services/sales-order-service';
import { discountOrder, PaymentModel, PaymentInfo, PackingModel, ShippmentModel, ItemSaleOrderModel, ReceiverModel, CustomerModel, SaleOrderDetail} from 'src/app/shared/model/saleorder.model';
import { stringify } from '@angular/compiler/src/util';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { animate } from '@angular/animations';


@Injectable({
    providedIn: 'root'
})
export class Dshsal100Service {
    dshsal100: Dshsal100Model = {} as Dshsal100Model;
    optionChannel: TempDDL[];
    optionSale: TempDDL[];
    optionStatus: TempDDL[];

    constructor(
        private paymentService: PaymentService,
        private dshsalService: DashboardSaleService,
        private translateService: TranslateService,
        private evmService: EnvironmentService,
        private autService: AuthenticationService,
        private fb: FormBuilder) {
        this.init();
    }

    init(): void{
        this.dshsal100.page_code = 'dshsal100';
        this.dshsal100.uuid = '';

        this.dshsal100.form_model = {} as FormModel;
        // Criterial Form
        this.dshsal100.form_model.criterial_form = this.fb.group({
            country: [null, Validators.required],
            seller_form: [null, Validators.required]
        });
        this.dshsal100.form_model.period = this.fb.group({
            period: [null],
        });
        this.dshsal100.form_model.searchInvGroupForm = this.fb.group({
            sales_order_no: [null, Validators.required],
            sales_order_date: [null, Validators.required],
            item_code_from_seller: [null, Validators.required],
            system_item_code: [null, Validators.required],
            item_desc: [null, Validators.required],
            tracking_no: [null, Validators.required],
            sales_status: [null, Validators.required],
            payment_status: [null, Validators.required],
            last_mile_status_date: [null, Validators.required],
            payment_complete_date: [null, Validators.required],
            payment_method: [null, Validators.required],
            dirty:[null]
        });
        this.dshsal100.form_model.searchItmGroupForm = this.fb.group({
            item_code_from_seller: [null, Validators.required],
            system_item_code: [null, Validators.required],
            item_desc: [null, Validators.required]
        });
        this.dshsal100.form_model.searchOrderGroupForm = this.fb.group({
            sales_order_no: [null, Validators.required],
            sales_order_date: [null, Validators.required],
            sales_channel: [null, Validators.required],
            sales_status: [null, Validators.required],
            payment_status: [null, Validators.required],
            dirty:[null]
        });

        this.dshsal100.sale_sum_data = {
            sale_amt_today: 0,
            sale_amt_this_month: 0,
            sale_amt_three_month: 0,
            sale_amt_this_year: 0,
            sale_doc_today: 0,
            sale_doc_this_month: 0,
            sale_doc_three_month: 0,
            sale_doc_this_year: 0,
            sale_item_total_sku: 0,
            sale_item_avg_sku: 0,
            sale_doc_total: 0,
            current_balance_qty: 0
        } as SaleSummaryByPeriod;

        this.dshsal100.table_order_data = [];
        this.dshsal100.table_sale_data = [];
        this.dshsal100.table_total_data = [];
    }

    get load(): Dshsal100Model {
        return this.dshsal100;
    }

    loadOverviewData(page_model: Dshsal100Model) :Observable<ResponseSaleSummaryByPeriod>{
            return this.dshsalService.getSaleSummaryPeriod(page_model.form_model.criterial_form.controls["country"].value,
                             page_model.form_model.criterial_form.controls["seller_form"].value,
                             page_model.uuid);
    }

    loadDshTableTotalData(page_model: Dshsal100Model) :Observable<ResponseDshSaleOrderPeriod>{
        var period = page_model.form_model.period.controls["period"].value;
        // console.log(period.parameterDetailPK.pmdtbdtbdentcd);
        // console.log(page_model.uuid);
            return this.dshsalService.getTableFullSaleOrderDetailByPeriod(page_model.form_model.criterial_form.controls["country"].value,
                            period.parameterDetailPK.pmdtbdtbdentcd,
                            page_model.form_model.criterial_form.controls["seller_form"].value,
                            ""); //Value uuid
    }

    loadDshTableOrderData(page_model: Dshsal100Model) :Observable<ResponseDshTableOrderSaleOrderPeriod>{
        var period = page_model.form_model.period.controls["period"].value;
        return this.dshsalService.getTableOrderSaleOrderDetailByPeriod(page_model.form_model.criterial_form.controls["country"].value,
                        period.parameterDetailPK.pmdtbdtbdentcd,
                        page_model.form_model.criterial_form.controls["seller_form"].value,
                        ""); //Value uuid
    }

    loadDshTableSaleData(page_model: Dshsal100Model) :Observable<ResponseDshTableSaleOrderPeriod>{
        var period = page_model.form_model.period.controls["period"].value;
        return this.dshsalService.getTableSaleOrderDetailByPeriod(page_model.form_model.criterial_form.controls["country"].value,
                        period.parameterDetailPK.pmdtbdtbdentcd,
                        page_model.form_model.criterial_form.controls["seller_form"].value,
                        ''); //Value uuid
    }

    loadDshPieChartData(page_model: Dshsal100Model) :Observable<ResponseDshPieChartSaleOrderPeriod>{
        var period = page_model.form_model.period.controls["period"].value;
        return this.dshsalService.getPieChartSaleOrderDetailByPeriod(page_model.form_model.criterial_form.controls["country"].value,
                        period.parameterDetailPK.pmdtbdtbdentcd,
                        page_model.form_model.criterial_form.controls["seller_form"].value,
                        ""); //Value uuid
    }

    loadDshChartData(page_model: Dshsal100Model) :Observable<ResponseDshSaleOrder>{
        var period = page_model.form_model.period.controls["period"].value;
        return this.dshsalService.getDashbordChartByPeriod(page_model.form_model.criterial_form.controls["country"].value,
                        page_model.form_model.criterial_form.controls["seller_form"].value,
                        period.parameterDetailPK.pmdtbdtbdentcd,
                        ''); //Value uuid 
    }
    loadFunction(){
        this.paymentService.getVprmTableByPrmno(4300).subscribe(response => {
            this.optionChannel = JSON.parse(JSON.stringify(response));
          }, err => {
            console.log(err);
          });
    }
}
