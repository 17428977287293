import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
    name: 'thaidate'
})
export class ThaiDatePipe implements PipeTransform {
  transform(date: string, format: string): string {
    let ThaiDay = ['อาทิตย์','จันทร์','อังคาร','พุธ','พฤหัสบดี','ศุกร์','เสาร์']
    let shortThaiMonth = [
        'ม.ค.','ก.พ.','มี.ค.','เม.ย.','พ.ค.','มิ.ย.',
        'ก.ค.','ส.ค.','ก.ย.','ต.ค.','พ.ย.','ธ.ค.'
        ];  
    let longThaiMonth = [
        'มกราคม','กุมภาพันธ์','มีนาคม','เมษายน','พฤษภาคม','มิถุนายน',
        'กรกฎาคม','สิงหาคม','กันยายน','ตุลาคม','พฤศจิกายน','ธันวาคม'
        ];   
    let inputDate=new Date(date);
    let dataDate = [
        inputDate.getDay(),inputDate.getDate(),inputDate.getMonth(),inputDate.getFullYear()
        ];
    let outputDateFull = [
        'วัน '+ThaiDay[dataDate[0]],
        'ที่ '+dataDate[1],
        'เดือน '+longThaiMonth[dataDate[2]],
        'พ.ศ. '+(dataDate[3]+543)
    ];
    let outputDateShort = [
        dataDate[1],
        shortThaiMonth[dataDate[2]],
        dataDate[3]+543
    ];
    let outputDateMedium = [
        dataDate[1],
        longThaiMonth[dataDate[2]],
        dataDate[3]+543
    ];
    let outputOnlyMonth = [
        longThaiMonth[dataDate[2]],
        dataDate[3]+543
    ];    
    let outputOnlyYear = [
        dataDate[3]+543
    ];
    let returnDate:string;
    returnDate = outputDateMedium.join(" ");
    if(format=='full'){ //วัน พฤหัสบดี ที่ 1 เดือน ธันวาคม พ.ศ. 2563
        returnDate = outputDateFull.join(" ");
    }    
    if(format=='medium'){ //1 ธันวาคม 2563
        returnDate = outputDateMedium.join(" ");
    }      
    if(format=='short'){ //	1 ธ.ค. 2563
        returnDate = outputDateShort.join(" ");
    }
    if(format=='month'){ //	ธันวาคม 2563	
        returnDate = outputOnlyMonth.join(" ");
    }
    if(format=='year'){ // 2563
        returnDate = outputOnlyYear.join(" ");
    }     
    if(format=='shortCommon'){ // dd/mm/yyyy
        returnDate = moment(date).format('DD/MM/YYYY');
    }
    if(format=='shortCommonTime'){ 
        returnDate = moment(date).format('DD/MM/YYYY HH:mm');
    }
    if(format=='shortCommonFullTime'){ 
        if(date != null){
            returnDate = moment(date).format('DD/MM/YYYY HH:mm:ss');
        }else{
            returnDate = '';
        } 
    }

    if (format==='formatCommon'){
        if(date != null){
            returnDate = moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
        }else{
            returnDate = '';
        }
    } // dd/mm/yyyy
    return returnDate;
  }
}