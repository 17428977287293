import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import {  AttributeDeleteRequest, AttributeInquiryRequest } from '../model/attribute.model'
import { AttributeModel} from '../../adm/model/admatr/admatr100.model'
@Injectable({
    providedIn: 'root'
  })

  export class AttributeService {

    ATTIBULT_URL : string = `${this.evmService.getApiUrl()}/adm/atr` as string;
   
    constructor(
                private http: HttpClient,
                private evmService : EnvironmentService,
                private autService: AuthenticationService){

      }
      getAttributeInquiry(param : AttributeInquiryRequest ) : Observable <Array<AttributeModel>>{
        const URL = `${this.ATTIBULT_URL}/getAttributeInquiry/` ;
       return this.http.post<Array<AttributeModel>>(`${URL}`,param,{headers: this.autService.tokenHeader()})
      }

      deleteAttribute(request : AttributeDeleteRequest) : Observable<any> {
        const URL = `${this.ATTIBULT_URL}/deleteAttribute/` ;
        return this.http.post(`${URL}`,request,{headers: this.autService.tokenHeader()})
      
      }
     
  }
