import { Component, OnInit } from '@angular/core';
import { SoForBuyerService } from 'src/app/shared/services/so-landing.service';
import { SaleOrderNo } from 'src/app/shared/model/so-landing-model';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SaleOrderDetail } from 'src/app/shared/model/saleorder.model';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { DecimalData, paymentDetail } from 'src/app/fna/model/payment-data-model';
import * as moment from 'moment';
@Component({
  selector: 'app-ord300',
  templateUrl: './ord300.component.html',
  styleUrls: ['./ord300.component.scss']
})
export class Ord300Component implements OnInit {

  //model infomation
  public salesOrder = {} as SaleOrderDetail;
  public saleOrderNo = {} as SaleOrderNo;
  public paymentInfo = {} as paymentDetail;
  public decimalInfo = {} as DecimalData;

  //status information
  public sale_order_status_list = [];
  public payment_status_list = [];
  public logistic_status_list = [];
  public sale_order_status = [];
  public payment_status = [];
  public logistic_status = [];

  //sales order number
  public sales_order_no: string;

  //sales information
  public sales_list: string;
  public sales_date: String;
  public sales_agent_code: String;
  public sales_agent_name: String;
  public sales_agent_lastname: String;
  public sales_ko_member_code: String;
  public sales_customer_name: String;
  public sales_customer_lastname: String;
  public sales_customer_phone: String;
  public sales_customer_address: String;
  public sales_customer_subdistrict: any;
  public sales_customer_district: String;
  public sales_customer_province: String;
  public sales_customer_postal_code: String;

  //item sales list
  public item_list = [];

  //sale order detail
  public detail_total_order: number;
  public detail_all_discount: number;
  public detail_sum_order: number;
  public detail_vat_desc: Number;
  public detail_vat_value: number;
  public detail_net_worth: number;
  public detail_currency: String;

  //shipment information
  public shippment_date: String;
  public shippment_company: String;
  public shippment_tracking_no: String;
  public shippment_receiver_name: String;
  public shippment_receiver_lastname: String;
  public shippment_receiver_address: String;
  public shippment_receiver_subdistrict: String;
  public shippment_receiver_district: String;
  public shippment_receiver_province: String;
  public shippment_receiver_postal_code: String;
  public shippment_shipping_price: Number;
  public shippment_total_discount: Number;
  public shippment_currency: String;

  //payment information
  public pay_net_worth: number;
  public pay_paid_amount: number = 0;
  public pay_arrears_amount: number;
  public pay_cod_service: number;
  public pay_payment_method: String;
  public pay_currency: String;

  //decimal information
  public sales_decimal_type: string = '2';
  public sale_decimal: number = 0;
  public round_to_decimal: number = 0;
  public decimal_method: string = "0";
  public decimal_type_purchase: string = '1';
  public decimal_type_selling: string = '2';
  public decimal_type_round_method: string = '3';
  public decimal_type_round_to_value: string = '4';

  //variable for display
  public translateLablePipe = new TranslateLablePipe();
  public uuid: string;
  public default_language: string = 'TH';
  public user_language: string;
  public display_sale_order_status: String;
  public display_payment_status: String;
  public display_logistic_status: String;
  public index_sale_order_status: number;
  public index_payment_status: number;
  public index_logistic_status: number;

  constructor(
    public soforbuyerService: SoForBuyerService,
    public translateService: TranslateService,
    public routeService: ActivatedRoute) {

    this.uuid = routeService.snapshot.paramMap.get('uuid');
    this.translateService.use(this.default_language);
    this.user_language = this.default_language;
    this.soforbuyerService.findSaleOrderNo(this.uuid).subscribe(res => {
      this.saleOrderNo = JSON.parse(JSON.stringify(res[0]));
      this.getSaleOrder(this.saleOrderNo.saleOrderNo, this.saleOrderNo.warehouseCode);
    }, err => {
      console.log(err);
    });

  }

  ngOnInit() {

    this.getAllStatus();

  }

  getLanguage(language) {

    this.user_language = language;
    this.translateService.use(language);

    this.sale_order_status = [];
    this.payment_status = [];
    this.logistic_status = [];
    this.getSaleOrder(this.saleOrderNo.saleOrderNo, this.saleOrderNo.warehouseCode);
    this.getAllStatus();

  }

  getAllStatus() {

    //sale order status
    this.soforbuyerService.getSaleOrderStatus().subscribe(res => {
      this.sale_order_status_list = JSON.parse(JSON.stringify(res));

      if (this.user_language == this.default_language) {
        this.sale_order_status_list.forEach(data => {
          this.sale_order_status.push({ label: data.parameterData.entry_desc_local })
        })
      } else if (this.user_language != this.default_language) {
        this.sale_order_status_list.forEach(data => {
          this.sale_order_status.push({ label: data.parameterData.entry_desc_eng })
        })
      } else {
        this.sale_order_status_list.forEach(data => {
          this.sale_order_status.push({ label: data.parameterData.entry_desc_eng })
        })
      }
    }, err => {
      console.log(err);
    });

    //payment status
    this.soforbuyerService.getPaymentStatus().subscribe(res => {
      this.payment_status_list = JSON.parse(JSON.stringify(res));

      if (this.user_language == this.default_language) {
        this.payment_status_list.forEach(data => {
          this.payment_status.push({ label: data.parameterData.entry_desc_local })
        })
      } else if (this.user_language != this.default_language) {
        this.payment_status_list.forEach(data => {
          this.payment_status.push({ label: data.parameterData.entry_desc_eng })
        })
      } else {
        this.payment_status_list.forEach(data => {
          this.payment_status.push({ label: data.parameterData.entry_desc_eng })
        })
      }
    }, err => {
      console.log(err);
    });

    //logistic status
    this.soforbuyerService.getLogisticStatus().subscribe(res => {
      this.logistic_status_list = JSON.parse(JSON.stringify(res));
      if (this.user_language == this.default_language) {
        this.logistic_status_list.forEach(data => {
          this.logistic_status.push({ label: data.parameterData.entry_desc_local })
        })
      } else if (this.user_language != this.default_language) {
        this.logistic_status_list.forEach(data => {
          this.logistic_status.push({ label: data.parameterData.entry_desc_eng })
        })
      } else {
        this.logistic_status_list.forEach(data => {
          this.logistic_status.push({ label: data.parameterData.entry_desc_eng })
        })
      }
    }, err => {
      console.log(err);
    });
  }


  getSaleOrder(saleOrderNo, warehouseCode) {

    this.soforbuyerService.getSaleOrderDetail(saleOrderNo, warehouseCode).subscribe(res => {
      this.salesOrder = JSON.parse(JSON.stringify(res));

      //sales order number
      this.sales_order_no = this.salesOrder.sales_order_no;

      //sales information
      this.sales_list = this.salesOrder.sales_order_no;
      this.sales_date = moment(res.create_date).format('DD/MM/YYYY HH:mm:ss');
      this.sales_agent_code = this.salesOrder.agent_code;
      this.sales_ko_member_code = this.salesOrder.customer.customer_klangone_code;
      this.sales_customer_name = this.salesOrder.customer.customer_name
      this.sales_customer_lastname = this.salesOrder.customer.customer_lastname;
      this.sales_customer_phone = this.salesOrder.customer.customer_phone;
      this.sales_customer_address = this.salesOrder.customer.customer_address;
      this.sales_customer_subdistrict = this.salesOrder.customer.customer_subdistrict;
      this.sales_customer_district = this.salesOrder.customer.customer_district;
      this.sales_customer_province = this.salesOrder.customer.customer_province;
      this.sales_customer_postal_code = this.salesOrder.customer.customer_postal_code;

      //item sales list
      this.item_list = this.salesOrder.item;

      //sale order detail
      this.detail_total_order = this.salesOrder.discountSo.totalPriceOrder;
      this.detail_all_discount = this.salesOrder.discountSo.discountBySeller + this.salesOrder.discountSo.discountKlangOneCode + this.salesOrder.discountSo.discountKlangOnePrice + this.salesOrder.discountSo.discountSaleChannelCode + this.salesOrder.discountSo.discountSaleChannelPrice;
      this.detail_sum_order = this.salesOrder.discountSo.sumOrder;
      this.detail_vat_desc = this.salesOrder.vat;
      this.detail_vat_value = this.salesOrder.discountSo.vat;
      this.detail_net_worth = this.salesOrder.discountSo.realPrice;
      this.detail_currency = this.translateLablePipe.transform(this.salesOrder.currency_desc_eng, this.salesOrder.currency_desc_local);

      //shipment information
      this.shippment_date = this.salesOrder.shippment.shippment_date;
      this.shippment_company = this.salesOrder.shippment.shippment_company;
      this.shippment_tracking_no = this.salesOrder.packing.length == 0 ? null : this.salesOrder.packing[0].tracking_no;
      this.shippment_receiver_name = this.salesOrder.receiver.receiver_name;
      this.shippment_receiver_lastname = this.salesOrder.receiver.receiver_lastname;
      this.shippment_receiver_address = this.salesOrder.receiver.receiver_address;
      this.shippment_receiver_subdistrict = this.salesOrder.receiver.receiver_subdistrict;
      this.shippment_receiver_district = this.salesOrder.receiver.receiver_district;
      this.shippment_receiver_province = this.salesOrder.receiver.receiver_province;
      this.shippment_receiver_postal_code = this.salesOrder.receiver.receiver_postal_code;
      this.shippment_shipping_price = this.salesOrder.shippment.shipping_price_customer;
      this.shippment_total_discount = this.salesOrder.shippment.total_discount;
      this.shippment_currency = this.translateLablePipe.transform(this.salesOrder.currency_desc_eng, this.salesOrder.currency_desc_local);

      this.getInformationFromApi(this.salesOrder);
      this.getPaymentData(this.salesOrder);
      this.displayStatus(this.salesOrder);

    }, err => {
      console.log(err);
    });
  }

  getPaymentData(salesOrder) {
    this.salesOrder = salesOrder;

    //payment information  
    this.soforbuyerService.getpaymentInquiry(this.salesOrder.sales_order_no, this.salesOrder.warehouse_code).subscribe((response) => {
      this.paymentInfo = JSON.parse(JSON.stringify(response));

      this.pay_net_worth = this.paymentInfo.saleOrderAmount;

      if (this.paymentInfo.detail.length > 0) {
        this.paymentInfo.detail.forEach(paymentInfo => {
          this.pay_paid_amount = this.pay_paid_amount + paymentInfo.paymentAmount;
        });
      } else {
        this.pay_paid_amount = this.paymentInfo.paymentedAmount == null ? 0 : this.paymentInfo.paymentedAmount;
      }

      this.pay_cod_service = Number(this.salesOrder.payment.cod_price);
      this.pay_arrears_amount = this.pay_net_worth - this.pay_paid_amount;
      this.pay_payment_method = this.translateLablePipe.transform(this.salesOrder.payment.payment_type_eng as string, this.salesOrder.payment.payment_type_local as string);
      this.pay_currency = this.translateLablePipe.transform(this.salesOrder.currency_desc_eng, this.salesOrder.currency_desc_local);
    });
  }

  getInformationFromApi(salesOrder) {
    this.salesOrder = salesOrder;

    //agent information
    if (this.sales_agent_code) {
      this.soforbuyerService.getAgentDetail(this.sales_agent_code).subscribe(res => {
        let agent_data = JSON.parse(JSON.stringify(res));
        this.sales_agent_name = agent_data.agentname;
        this.sales_agent_lastname = agent_data.agentsurname;
      }, err => {
        console.log(err);
      });
    }

    //customer sub district
    if (this.salesOrder.customer.customer_subdistrict) {
      this.soforbuyerService.getParameterBytableNoAndCode(104, this.salesOrder.customer.customer_subdistrict).subscribe(res => {
        let customer_sub_district_list = JSON.parse(JSON.stringify(res[0]));

        if (this.user_language == this.default_language) {
          this.sales_customer_subdistrict = customer_sub_district_list.localDesc;
        } else if (this.user_language != this.default_language) {
          this.sales_customer_subdistrict = customer_sub_district_list.engDesc;
        } else {
          this.sales_customer_subdistrict = customer_sub_district_list.engDesc;
        }
      }, err => {
        console.log(err);
      });
    }

    //customer district
    if (this.salesOrder.customer.customer_district) {
      this.soforbuyerService.getParameterBytableNoAndCode(103, this.salesOrder.customer.customer_district).subscribe(res => {
        let customer_district_list = JSON.parse(JSON.stringify(res[0]));

        if (this.user_language == this.default_language) {
          this.sales_customer_district = customer_district_list.localDesc;
        } else if (this.user_language != this.default_language) {
          this.sales_customer_district = customer_district_list.engDesc;
        } else {
          this.sales_customer_district = customer_district_list.engDesc;
        }
      }, err => {
        console.log(err);
      });
    }

    //customer province

    if (this.salesOrder.customer.customer_province) {
      this.soforbuyerService.getParameterBytableNoAndCode(102, this.salesOrder.customer.customer_province).subscribe(res => {
        let customer_province_list = JSON.parse(JSON.stringify(res[0]));

        if (this.user_language == this.default_language) {
          this.sales_customer_province = customer_province_list.localDesc;
        } else if (this.user_language != this.default_language) {
          this.sales_customer_province = customer_province_list.engDesc;
        } else {
          this.sales_customer_province = customer_province_list.engDesc;
        }
      }, err => {
        console.log(err);
      });
    }

    //receiver sub district

    if (this.salesOrder.receiver.receiver_subdistrict) {
      this.soforbuyerService.getParameterBytableNoAndCode(104, this.salesOrder.receiver.receiver_subdistrict).subscribe(res => {
        let receiver_sub_district_list = JSON.parse(JSON.stringify(res[0]));

        if (this.user_language == this.default_language) {
          this.shippment_receiver_subdistrict = receiver_sub_district_list.localDesc;
        } else if (this.user_language != this.default_language) {
          this.shippment_receiver_subdistrict = receiver_sub_district_list.engDesc;
        } else {
          this.shippment_receiver_subdistrict = receiver_sub_district_list.engDesc;
        }

      }, err => {
        console.log(err);
      });
    }

    //receiver district

    if (this.salesOrder.receiver.receiver_district) {
      this.soforbuyerService.getParameterBytableNoAndCode(103, this.salesOrder.receiver.receiver_district).subscribe(res => {
        let receiver_district_list = JSON.parse(JSON.stringify(res[0]));

        if (this.user_language == this.default_language) {
          this.shippment_receiver_district = receiver_district_list.localDesc;
        } else if (this.user_language != this.default_language) {
          this.shippment_receiver_district = receiver_district_list.engDesc;
        } else {
          this.shippment_receiver_district = receiver_district_list.engDesc;
        }

      }, err => {
        console.log(err);
      });
    }

    //receiver province

    if (this.salesOrder.receiver.receiver_province) {
      this.soforbuyerService.getParameterBytableNoAndCode(102, this.salesOrder.receiver.receiver_province).subscribe(res => {
        let receiver_province_list = JSON.parse(JSON.stringify(res[0]));
        if (this.user_language == this.default_language) {
          this.shippment_receiver_province = receiver_province_list.localDesc;
        } else if (this.user_language != this.default_language) {
          this.shippment_receiver_province = receiver_province_list.engDesc;
        } else {
          this.shippment_receiver_province = receiver_province_list.engDesc;
        }
      }, err => {
        console.log(err);
      });
    }

    //logistic company
    if (this.salesOrder.shippment.shippment_company) {
      this.soforbuyerService.getParameterBytableNoAndCode(9173, this.salesOrder.shippment.shippment_company).subscribe(res => {
        let logistic_company_list = JSON.parse(JSON.stringify(res[0]));

        if (this.user_language == this.default_language) {
          this.shippment_company = logistic_company_list.localDesc;
        } else if (this.user_language != this.default_language) {
          this.shippment_company = logistic_company_list.engDesc;
        } else {
          this.shippment_company = logistic_company_list.engDesc;
        }
      }, err => {
        console.log(err);
      });
    }

    //dicimal infomation
    this.soforbuyerService.getvprmdtltolableByCountry(104, this.salesOrder.country_code).subscribe(response => {
      let decimal_data = JSON.parse(JSON.stringify(response));

      this.decimalInfo.dataTypeDecimalPurchase = decimal_data.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.decimal_type_purchase);
      this.decimalInfo.dataTypeDecimalSelling = decimal_data.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.decimal_type_selling);
      this.decimalInfo.dataTypeDecimalRoundMethod = decimal_data.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.decimal_type_round_method);
      this.decimalInfo.dataTypeDecimalRoundToValue = decimal_data.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.decimal_type_round_to_value);
      this.sale_decimal = this.decimalInfo.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
      this.decimal_method = this.decimalInfo.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
      this.round_to_decimal = this.decimalInfo.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;

    }, err => {
      console.log(err);
    });

  }

  displayStatus(salesOrder) {

    this.salesOrder = salesOrder;

    if (this.user_language == this.default_language) {

      this.display_sale_order_status = this.salesOrder.status_order_desc_local;
      this.display_payment_status = this.salesOrder.payment.payment_status_local;
      this.display_logistic_status = this.salesOrder.shippment.shipping_status_local;

      this.index_sale_order_status = this.sale_order_status_list.findIndex(list => list.parameterData.entry_desc_local === this.display_sale_order_status);
      this.index_payment_status = this.payment_status_list.findIndex(list => list.parameterData.entry_desc_local === this.display_payment_status);
      this.index_logistic_status = this.logistic_status_list.findIndex(list => list.parameterData.entry_desc_local === this.display_logistic_status);

    } else if (this.user_language != this.default_language) {

      this.display_sale_order_status = this.salesOrder.status_order_desc_eng;
      this.display_payment_status = this.salesOrder.payment.payment_status_eng;
      this.display_logistic_status = this.salesOrder.shippment.shipping_status_eng;

      this.index_sale_order_status = this.sale_order_status_list.findIndex(parameter => parameter.parameterData.entry_desc_eng == this.display_sale_order_status);
      this.index_payment_status = this.payment_status_list.findIndex(parameter => parameter.parameterData.entry_desc_eng == this.display_payment_status);
      this.index_logistic_status = this.logistic_status_list.findIndex(parameter => parameter.parameterData.entry_desc_eng == this.display_logistic_status);

    } else {

      this.display_sale_order_status = this.salesOrder.status_order_desc_eng;
      this.display_payment_status = this.salesOrder.payment.payment_status_eng;
      this.display_logistic_status = this.salesOrder.shippment.shipping_status_eng;

      this.index_sale_order_status = this.sale_order_status_list.findIndex(parameter => parameter.parameterData.entry_desc_eng == this.display_sale_order_status);
      this.index_payment_status = this.payment_status_list.findIndex(parameter => parameter.parameterData.entry_desc_eng == this.display_payment_status);
      this.index_logistic_status = this.logistic_status_list.findIndex(parameter => parameter.parameterData.entry_desc_eng == this.display_logistic_status);

    }
  }

}
