import { Component, OnInit, ViewChild , Renderer2} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { CheckBoxValue, DecimalData, paymentDetail, paymentInquiryPage, paymentRequest, TempDDL,PaymentInquiry} from '../../model/payment-data-model';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { Big } from 'big.js';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import BigNumber from 'bignumber.js';
import { ThaiDatePipe } from 'src/app/shared/pipes/thaidate.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';
import { ResentPamApi } from 'src/app/ord/model/ord100.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fnapay100',
  templateUrl: './fnapay100.component.html',
  styleUrls: ['./fnapay100.component.scss']
})
export class Fnapay100Component extends PermissionComponent {

  paymentInquiryPage: paymentInquiryPage = {} as paymentInquiryPage;
  // paymentInquiryData = {} as PaymentInquiry;
  payDetail = {} as PaymentInquiry;
  payRequest = {} as paymentRequest;
  public decimalData:DecimalData = {} as DecimalData;
  optionChannel: TempDDL[];
  optionBank: TempDDL[];
  optionFlow: TempDDL[];
  optionMode: TempDDL[];
  warehouse: TempDDL[];
  allStatus: string[] = ['All'];
  criteriaSearchGrid = { country: '', smartSearchShort: '', smartSearchLong: '', saleOrderNumber: '', saleOrderDate: '', flowType: '', paymentMode: '', paymentChannel: '', bankAccount: '', paymentDate: '', allStatus: '', confirmStatus: '', cancelStatus: '', warehouseDes: '', klangOneMemberCd: '', customerName: '', waitingPayment: '', waitingReview: '', confirmPayment: '' };
  isDisabled: boolean = false;
  row: any=[];
  isChecker: any;
  arrearage: any;
  messageAlert: string;
  selectCountry: string
  userCode: any;
  userDesc: any;
  responseHeader: any = [];
  paymentDataShort=[];
  params: any;
  status: boolean;
  listConfirmPayment:any=[];
  listSaleOrderNumber:any=[];
  listPaymentStatus:any=[];
  paymentLongTemp:any=[];
  exportReportTemp:any=[];
  exportReportDetailTemp:any=[];
  statusShipment: SelectItem[];
  paymentStatus: Array<CheckBoxValue> = [] as Array<CheckBoxValue>;
  checkShipmentStatus: string[] = [];
  checkPaymentStatus: string[] = [];
  disabledReport: boolean = true;
  channelTemp = [];
  bankTemp = [];
  creator = [];
  amount = [];
  remark = [];
  paymentDateTemp = [];
  paymentAmount: number = 0;
  listWarehouse:any = [];
  temp1=[];
  disabledCountry: boolean;
  translateLablePipe = new TranslateLablePipe();

  decimalSelling: string;
  dataTypeDecimalSelling:any;

  typeDecimalPurchase: string = '1';
  typeDecimalSelling: string = '2';
  typeDecimalRoundMethod: string = '3';
  typeDecimalRoundToValue: string = '4';

  warehouseDisFlag: boolean = false;
  optionFlowDisFlag: boolean = false;
  // checkboxGroup :Array<CheckBoxValue> = [] as Array<CheckBoxValue>;
  // shipmentStatusList:Array<CheckBoxValue> = [] as Array<CheckBoxValue>;

  method : string = "0";
  roundToDecimal : number = 0;
  decimal : number = 0;
  firstRow: number = 0 as number;
  thaidate = new ThaiDatePipe();

  searchGridGroupForm = new FormGroup({
    country_code: new FormControl(),
    saleOrderNumber: new FormControl(),
    saleOrderDate: new FormControl(),
    flowType: new FormControl(),
    warehouseCode: new FormControl(),
    klangOneMemberCd: new FormControl(),
    customerName: new FormControl(),
    paymentMode: new FormControl(),
    paymentChannel: new FormControl(),
    paymentDate: new FormControl(),
    bankAccount: new FormControl(),
    statusShipment: new FormControl(),
    paymentStatus: new FormControl(),
  });
  public user = {} as AuthDataModel;

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();
  public resentPamApiModel: Array<ResentPamApi> = [] as Array<ResentPamApi>;

  constructor(
    private paymentService: PaymentService,
    private exportExcelService: ExportExcelService,
    private parameterWmsSerive: ParameterWmsSerive,
    private messageService: MessageService,
    private parameterService: ParameterService,
    private autService: AuthenticationService,
    private translateService: TranslateService ,
    private decimalService: DecimalService,
    renderer2:Renderer2,
    private router: Router

  ) {
    super('P32101',renderer2);
    this.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel
    this.userCode = this.user.userName ? this.user.userName : this.user.userDesc
    this.paymentInquiryPage.searchBtn = true;
    this.autService.getParameterWmsCountry().subscribe(response => {
      this.paymentInquiryPage.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.paymentInquiryPage.optionCountry.length == 1) {
        this.criteriaSearchGrid.country = this.paymentInquiryPage.optionCountry[0].value;
        this.disabledCountry = true;
        this.getCountry(this.criteriaSearchGrid.country);
      } else if(this.paymentInquiryPage.optionCountry.length == 0) {
        this.disabledCountry = true;
      } else {
        this.disabledCountry = false;
      }
    });
    this.parameterWmsSerive.getParameterWmsCheckbox(9149).subscribe((response) => {
      this.paymentStatus = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.status = false;
    this.statusShipment = [
      { value: '', label: 'FNAPAY100.M00048'},
      { value: 200, label: 'FNAPAY100.M00049'},
      { value: 300, label: 'FNAPAY100.M00050'}
    ];
  }

  deliveryConfirmation(event,data) {
    if(event){
      if(data == ''){
        this.statusShipment.forEach((element: CheckBoxValue) => {
          if(element.value != '' ) {
            element.checked = false;
          }
        });
        this.checkShipmentStatus.splice(0,2);
      }  else {
        this.statusShipment.forEach((element: CheckBoxValue) => {
          if(element.value === '' ){
            element.checked = false;
            const index = this.checkShipmentStatus.findIndex(res => res === '');
            if(index != -1){
              this.checkShipmentStatus.splice(index,1);
            }
          }
        });
      }
      this.checkShipmentStatus.push(data);
      this.changebtn();
    }else {
      let removeIndex = this.checkShipmentStatus.findIndex(itm => itm===data);
      if(removeIndex !== -1)
        this.checkShipmentStatus.splice(removeIndex,1);
        this.changebtn();
    }
  }

  statusPayment(event,data){
    if(event){
      this.checkPaymentStatus.push(data);
      this.changebtn();
    }else {
      let removeIndex = this.checkPaymentStatus.findIndex(itm => itm===data);
      if(removeIndex !== -1)
        this.checkPaymentStatus.splice(removeIndex,1);
        this.changebtn();
    }
  }

  changebtn(){
    if(this.selectCountry != null){
      this.isDisabled = false;
    }else{
      this.isDisabled = true;
    }
  }

  getCountry(country) {
    this.searchGridGroupForm.reset();
    this.paymentStatus.forEach((element: CheckBoxValue) => {
      element.checked = false;
    });
    this.statusShipment.forEach((element: CheckBoxValue) => {
      element.checked = false;
    });
    this.payRequest.shipmentStatus = [];
    this.checkShipmentStatus = [];
    this.isDisabled = false;
    this.selectCountry = this.criteriaSearchGrid.country
    this.searchGridGroupForm.controls['country_code'].setValue(this.selectCountry);
    this.autService.getParameterWmsWareHouse(this.criteriaSearchGrid.country).subscribe(response =>{
      this.warehouse = JSON.parse(JSON.stringify(response));
      if(this.warehouse.length == 0){
        this.warehouseDisFlag = true;
      }else{
        this.warehouseDisFlag = false;
      }
    },err=>{
      console.log(err);
    });
      this.loadFunction();
  }

  loadFunction(){
    this.autService.getParameterWmsFlowType().subscribe(response => {
      this.optionFlow = JSON.parse(JSON.stringify(response));
      if(this.optionFlow.length == 0){
        this.optionFlowDisFlag = true;
      }
    });

    this.paymentService.getVprmTableByPrmno(4100).subscribe(response => {
      this.optionMode = JSON.parse(JSON.stringify(response));
    });

    this.paymentService.getVprmTableByPrmno(4300).subscribe(response => {
      this.optionChannel = JSON.parse(JSON.stringify(response));
    });

    this.paymentService.getVprmTableByCountry(4302,this.criteriaSearchGrid.country).subscribe((response) => {
      this.optionBank = JSON.parse(JSON.stringify(response));
    });

    this.parameterService.getvprmdtltolableByCountry(104, this.criteriaSearchGrid.country).subscribe((response) => {
      let decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalPurchase);
      this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalSelling);
      this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalRoundMethod);
      this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalRoundToValue);
      this.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
      this.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
      this.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
    }, err => {
      console.log(err);
    });
  }

  ngOnInit() {
    this.paymentInquiryPage.dataNotFoundGridTab1 = true;

    this.paymentInquiryPage.calendar = this.translateCalendar;
  }

  async getOptionData() {
    this.parameterService.getvparameterDetailToLable(2600).subscribe((response) => {
      this.paymentInquiryPage.optionPeriod = JSON.parse(JSON.stringify(response));
      this.paymentInquiryPage.optionPeriod = this.paymentInquiryPage.optionPeriod.sort((a, b) => a.value.prdv4 < b.value.prdv4 ? -1 : a.value.prdv4 > b.value.prdv4 ? 1 : 0);
      let len = (this.paymentInquiryPage.optionPeriod).length - 1;
      this.paymentInquiryPage.defaultPeriod = this.paymentInquiryPage.optionPeriod[len].value;
    }, err => {
      console.log(err);
    });
  }

  checked(isChecker: any, row) {
    if (isChecker) {
        this.row.push(row);
        if (this.row.length > 0) {
            this.disabledReport = false;
        }
    } else {
        const index = this.row.findIndex(data => data === row);
        this.row.splice(index, 1);
        if (this.row.length === 0) {
            this.disabledReport = true;
        }
    }
}

  checkedAll(isChecker: any, row) {
    if (isChecker) {
      for(let i = 0 ; i < this.paymentInquiryPage.paymentDataLong.length ; i ++){
        this.row.push(i);
      }
      this.disabledReport = false;
    } else {
      this.row=[];
      this.disabledReport = true;
    }
  }

  selectedCountry(event) {
    this.paymentInquiryPage.selectCountry = event.value.parameterDetailPK.prdentcd;
    this.getOptionData();
  }

  smartSearchShort(event) {
    this.paymentInquiryPage.paymentDataLong = this.paymentLongTemp;
    this.criteriaSearchGrid.smartSearchShort = this.criteriaSearchGrid.smartSearchShort == null ? '' : this.criteriaSearchGrid.smartSearchShort;
    this.paymentInquiryPage.paymentDataLong = this.paymentInquiryPage.paymentDataLong.filter(data => {
      return (
        (data.saleOrderDate && data.saleOrderDate.toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase())) ||
        (data.saleOrderNumber && data.saleOrderNumber.toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase())) ||
        (data.flowType && data.flowType.toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase())) ||
        (data.paymentMode && data.paymentMode.toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase())) ||
        (data.paymentChannel.toString() && data.paymentChannel.toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase())) ||
        (data.paymentDate.toString() && data.paymentDate.toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase())) ||
        (this.decimalService.getRoundingDecimal(new Big(Number(data.saleOrderAmount)), this.method,this.roundToDecimal,this.decimal).toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase())) ||
        (this.decimalService.getRoundingDecimal(new Big(Number(data.paymentedAmount)), this.method,this.roundToDecimal,this.decimal).toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase())) ||
        (this.decimalService.getRoundingDecimal(new Big(Number(data.arrearage)), this.method,this.roundToDecimal,this.decimal).toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase())) ||
        (data.saleOrderAmount).toFixed(this.decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') && (data.saleOrderAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase()) ||
        (data.paymentedAmount).toFixed(this.decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') && (data.paymentedAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase()) ||
        (data.arrearage).toFixed(this.decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') && (data.arrearage).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase()) ||
        (data.paymentStatus && data.paymentStatus.toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchShort.toLowerCase()))
      );
    });
    this.firstRow = 0;
  }

  smartSearchLong(event) {
    this.paymentInquiryPage.paymentDataLong = this.paymentLongTemp;
    this.criteriaSearchGrid.smartSearchLong = this.criteriaSearchGrid.smartSearchLong == null ? '' : this.criteriaSearchGrid.smartSearchLong;

    this.paymentInquiryPage.paymentDataLong = this.paymentInquiryPage.paymentDataLong.filter((data) => {
      return (
        (data.saleOrderDate && data.saleOrderDate.toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.saleOrderNumber && data.saleOrderNumber.toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.warehouseDes && data.warehouseDes.toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.customerName && data.customerName.toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.klangOneMemberCd && data.klangOneMemberCd.toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.flowType && data.flowType.toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.paymentMode && data.paymentMode.toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.paymentChannel.toString() && data.paymentChannel.toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.bankAccount.toString() && data.bankAccount.toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.paymentDate.toString() && data.paymentDate.toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (this.decimalService.getRoundingDecimal(new Big(Number(data.saleOrderAmount)), this.method,this.roundToDecimal,this.decimal).toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.saleOrderAmount).toFixed(this.decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') && (data.saleOrderAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase()) ||
        (this.decimalService.getRoundingDecimal(new Big(Number(data.paymentedAmount)), this.method,this.roundToDecimal,this.decimal).toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.paymentedAmount).toFixed(this.decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') && (data.paymentedAmount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase()) ||
        (data.invoiceNo && data.invoiceNo.toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.receiptNo && data.receiptNo.toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (this.decimalService.getRoundingDecimal(new Big(Number(data.arrearage)), this.method,this.roundToDecimal,this.decimal).toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase())) ||
        (data.arrearage).toFixed(this.decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') && (data.arrearage).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase()) ||
        (data.paymentStatus && data.paymentStatus.toString().toLowerCase().includes(this.criteriaSearchGrid.smartSearchLong.toLowerCase()))
      );
    });
    this.firstRow = 0;
  }

  setDataToFilter(){
    this.paymentLongTemp.forEach((element: any) => element.checked = false);
    this.isDisabled = true;
    this.exportReportTemp = [];
    this.paymentLongTemp = [];
    this.row =[];
    this.payRequest.paymentStatus = [];
    this.payRequest.country = this.selectCountry;
    this.payRequest.sellerCode = [this.userCode];
    this.payRequest.saleOrderNumber = this.searchGridGroupForm.controls['saleOrderNumber'].value;
    this.payRequest.flowTypeCode = this.searchGridGroupForm.controls['flowType'].value;
    this.payRequest.warehouseCode = this.searchGridGroupForm.controls['warehouseCode'].value;
    this.payRequest.paymentModeCode = this.searchGridGroupForm.controls['paymentMode'].value;
    this.payRequest.bankAccountCode = this.searchGridGroupForm.controls['bankAccount'].value;
    this.payRequest.paymentChannelCode = this.searchGridGroupForm.controls['paymentChannel'].value;
    this.payRequest.klangOneMemberCd = this.searchGridGroupForm.controls['klangOneMemberCd'].value;
    this.payRequest.customerName = this.searchGridGroupForm.controls['customerName'].value;

    if (this.searchGridGroupForm.controls['saleOrderDate'].value != null) {
      this.payRequest.saleOrderDate_from = moment(this.searchGridGroupForm.controls['saleOrderDate'].value[0]).format('YYYY-MM-DD');
      if (this.searchGridGroupForm.controls['saleOrderDate'].value[1] == null) {
        this.payRequest.saleOrderDate_to = moment(this.searchGridGroupForm.controls['saleOrderDate'].value[0]).format('YYYY-MM-DD');
      } else {
        this.payRequest.saleOrderDate_to = moment(this.searchGridGroupForm.controls['saleOrderDate'].value[1]).format('YYYY-MM-DD');
      }
    } else {
      this.payRequest.saleOrderDate_from = '0001-01-01';
      this.payRequest.saleOrderDate_to = '9999-12-31';
    }
    if (this.searchGridGroupForm.controls['paymentDate'].value != null) {
      this.payRequest.paymentDate_from = moment(this.searchGridGroupForm.controls['paymentDate'].value[0]).format('YYYY-MM-DD');
      if (this.searchGridGroupForm.controls['paymentDate'].value[1] == null) {
        this.payRequest.paymentDate_to = moment(this.searchGridGroupForm.controls['paymentDate'].value[0]).format('YYYY-MM-DD');
      } else {
        this.payRequest.paymentDate_to = moment(this.searchGridGroupForm.controls['paymentDate'].value[1]).format('YYYY-MM-DD');
      }
    } else {
      this.payRequest.paymentDate_from = '0001-01-01';
      this.payRequest.paymentDate_to = '9999-12-31';
    }
    let listWarehouse = []
    if (this.payRequest.warehouseCode != null) {
      this.payRequest.warehouseCode.forEach(element => {
        listWarehouse.push(element);
      })
      this.payRequest.warehouseCode = listWarehouse;
    } else {
      this.warehouse.forEach(element => {
        listWarehouse.push(element.value);
      });
      this.payRequest.warehouseCode = listWarehouse;
    }
    if (this.checkShipmentStatus.length < 1) {
      this.payRequest.shipmentStatus = ['100', '200', '300']
    } else {
      if (this.checkShipmentStatus.includes("")) {
        this.payRequest.shipmentStatus = ['100', '200', '300']
      } else {
        this.payRequest.shipmentStatus = this.checkShipmentStatus
      }
    }
    if (this.checkPaymentStatus.length < 1) {
      this.payRequest.paymentStatus = ['0', '1', '2','3']
    } else {
      this.payRequest.paymentStatus = this.checkPaymentStatus
    }
  }

  filterData() {
    this.setDataToFilter();
    this.paymentService.getPaymentHeader(this.payRequest).subscribe(response => {

       if (response != null) {
        this.payDetail = JSON.parse(JSON.stringify(response));
        JSON.parse(JSON.stringify(this.payDetail)).forEach(element => {

          this.paymentLongTemp.push({
            'saleOrderDate': element.saleOrderDate,
            'saleOrderNumber': element.saleOrderNumber,
            'warehouseDes': this.translateLablePipe.transform(element.warehouseDes_eng,element.warehouseDes_local),
            'sellerDes': this.translateLablePipe.transform(element.seller_eng,element.seller_local),
            'warehouseCode': element.warehouseCode,
            'customerName': element.customerName,
            'klangOneMemberCd': element.klangOneMemberCd,
            'flowType': this.translateLablePipe.transform(element.flowType_eng,element.flowType_local),
            'paymentMode': this.translateLablePipe.transform(element.paymentMode_eng,element.paymentMode_local),
            'paymentChannel': this.translateLablePipe.transform(element.paymentChannel_eng!= null?element.paymentChannel_eng:'',element.paymentChannel_local!= null?element.paymentChannel_local:''),
            'bankAccount': this.translateLablePipe.transform(element.pbankAccount_eng!= null?element.pbankAccount_eng:'',element.bankAccount_local!= null?element.bankAccount_local:''),
            'paymentDate': element.paymentDate != null?element.paymentDate:'',
            'saleOrderAmount': element.saleOrderAmount,
            'paymentedAmount': element.paymentedAmount != null?element.paymentedAmount:0,
            'invoiceNo': element.invoiceNo,
            'receiptNo': element.receiptNo,
            'arrearage': Number(element.arrearage),
            'paymentStatus': this.translateLablePipe.transform(element.paymentStatus_eng,element.paymentStatus_local),
            'flowTypeCode': element.flowTypeCode,
            'paymentModeCode': element.paymentMode,
            'shipmentStatusCode': element.confirmShipmentStatus,
            'paymentStatusCode': element.paymentStatusCode,
            'saleChannel': this.translateLablePipe.transform(element.sales_channel_desc_eng,element.sales_channel_desc_local),
            'saleChannelCategory': this.translateLablePipe.transform(element.sales_channel_category_desc_eng,element.sales_channel_category_desc_local),
          })

          this.exportReportTemp.push({
            'saleOrderDate': this.thaidate.transform(element.saleOrderDate, 'shortCommonFullTime'),
            'saleOrderNumber': element.saleOrderNumber,
            'warehouseDes': this.translateLablePipe.transform(element.warehouseDes_eng,element.warehouseDes_local),
            'customerName': element.customerName,
            'klangOneMemberCd': element.klangOneMemberCd,
            'flowType': this.translateLablePipe.transform(element.flowType_eng,element.flowType),
            'paymentMode': this.translateLablePipe.transform(element.paymentMode_eng,element.paymentMode_local),
            'paymentChannel': this.translateLablePipe.transform(element.paymentChannel_eng!= null?element.paymentChannel_eng:'',element.paymentChannel_local!= null?element.paymentChannel_local:''),
            'bankAccount': this.translateLablePipe.transform(element.pbankAccount_eng!= null?element.pbankAccount_eng:'',element.bankAccount_local!= null?element.bankAccount_local:''),
            'paymentDate': element.paymentDate != null?element.paymentDate:'',
            'saleOrderAmount': this.decimalService.getRoundingDecimal(new Big(element.saleOrderAmount), this.method, this.roundToDecimal,this.decimal),
            'paymentedAmount': this.decimalService.getRoundingDecimal(new Big(Number(element.paymentedAmount)), this.method, this.roundToDecimal,this.decimal),
            'invoiceNo': element.invoiceNo,
            'receiptNo': element.receiptNo,
            'arrearage': this.decimalService.getRoundingDecimal(new Big(Number(element.arrearage)), this.method, this.roundToDecimal,this.decimal),
            'paymentStatus': this.translateLablePipe.transform(element.paymentStatus_eng,element.paymentStatus_local)
          })

          this.exportReportDetailTemp.push({
            'saleOrderNumber': element.saleOrderNumber,
            'saleOrderDate': this.thaidate.transform(element.saleOrderDate, 'shortCommonFullTime'),
            'country': element.countryCode,
            'warehouseDes': this.translateLablePipe.transform(element.warehouseDes_eng,element.warehouseDes_local),
            'seller': element.sellerCode + ' - ' + this.translateLablePipe.transform(element.seller_eng,element.seller_local),
            'klangOneMemberCd': element.klangOneMemberCd,
            'customerName': element.customerName,
            'flowType': this.translateLablePipe.transform(element.flowType_eng,element.flowType_local),
            'paymentMode': this.translateLablePipe.transform(element.paymentMode_eng,element.paymentMode_local),
            'invoiceNo': element.invoiceNo,
            'receiptNo': element.receiptNo,
            'orderStatus' : this.translateLablePipe.transform(element.saleOrderStatus_eng,element.saleOrderStatus_local),
            'paymentStatus': this.translateLablePipe.transform(element.paymentStatus_eng,element.paymentStatus_local),
            'comfirmShipment': this.translateLablePipe.transform(element.confirmShipmentStatus_eng,element.confirmShipmentStatus_local),
            'saleOrderAmount': this.decimalService.getRoundingDecimal(new Big(element.saleOrderAmount), this.method, this.roundToDecimal,this.decimal),
            'paymentedAmount': this.decimalService.getRoundingDecimal(new Big(Number(element.paymentedAmount)), this.method, this.roundToDecimal,this.decimal),
            'arrearage': this.decimalService.getRoundingDecimal(new Big(Number(element.arrearage)), this.method, this.roundToDecimal,this.decimal),
            'paymentDate': element.paymentDate != null?element.paymentDate:'',
            'paymentSaveDate': element.payOperationDate,
            'paymentChannel': this.translateLablePipe.transform(element.paymentChannel_eng!= null?element.paymentChannel_eng:'',element.paymentChannel_local!= null?element.paymentChannel_local:''),
            'bankAccount': this.translateLablePipe.transform(element.pbankAccount_eng!= null?element.pbankAccount_eng:'',element.bankAccount_local!= null?element.bankAccount_local:''),
            'remark': element.remark,
            'amount' : element.amountToBePaid,
            'savePaymentBy' : element.creator,
          })

        })
        this.paymentInquiryPage.paymentDataLong = this.paymentLongTemp;

        this.firstRow = 0;
      } else {
        this.disabledReport = true;
        this.paymentInquiryPage.paymentDataLong = [];
      }
    }, err => {
      console.log(err);
    });
  }

  clearData() {
    this.disabledReport = true;
    this.paymentInquiryPage.paymentDataLong = [];
    this.payRequest.paymentStatus = [];
    this.checkPaymentStatus = [];
    this.searchGridGroupForm.reset();
    this.isDisabled = true;
    this.payRequest.saleOrderDate_from = null;
    this.payRequest.saleOrderDate_to = null;
    this.payRequest.paymentDate_from = null;
    this.payRequest.paymentDate_to = null;
    this.payRequest.shipmentStatus = [];
    this.checkShipmentStatus = [];
    this.row = [];
    this.paymentStatus.forEach((element: CheckBoxValue) => {
      element.checked = false;
    });
    this.statusShipment.forEach((element: CheckBoxValue) => {
      element.checked = false;
    });
    this.criteriaSearchGrid.smartSearchShort = null;
    this.criteriaSearchGrid.smartSearchLong = null;
    if (this.paymentInquiryPage.optionCountry.length != 1) {
      this.criteriaSearchGrid.country = null;
    } else {
      this.isDisabled = false;
    }
    this.paymentLongTemp.forEach((element: any) => {element.checked = false;} );
  }

  checkConfirmPayment(message: string) {
    this.listConfirmPayment = [];
    this.messageService.clear();
    let data:any = [];
    data = this.row.filter(data => data.paymentStatusCode != '2' || (data.paymentStatusCode =='2' && data.flowTypeCode == '3' && data.paymentModeCode == '100' && data.shipmentStatusCode == '200'));
    if (data.length == 0) {
      this.row.forEach(data => {
           this.listSaleOrderNumber.push(data.saleOrderNumber);
            this.listWarehouse.push(data.warehouseCode);
            this.listPaymentStatus.push(data.paymentStatusCode);
      });
      this.confirmInfo(this.translateService.instant('FNAPAY100.M00052'));
    } else {
      this.notUpdatedMessage(this.translateService.instant('FNAPAY100.M00053'));
    }
    this.messageAlert = message;
  }

  notUpdatedMessage(message: string){
    this.messageService.clear();
    this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: this.translateService.instant('FNAPAY100.M00051'), detail: message });
  }

  confirmInfo(message: string) {
    this.messageService.clear();
    this.messageService.add({ key: 'conpay', sticky: true, severity: 'warn', summary: this.translateService.instant('FNAPAY100.M00051'), detail: message });
    this.messageAlert = message;
  }

  confirmArrearageInfo(message: string) {
    this.messageService.clear();
    this.messageService.add({ key: 'ca', sticky: true, severity: 'warn', summary: this.translateService.instant('FNAPAY100.M00051'), detail: message });
    this.messageAlert = message;
  }

  onConfirmAg(){
    this.confirmPayment();
  }

  onConfirm() {
    let checkArrearage: number = 0;
    this.row.forEach(data => {
      checkArrearage = Number(data.arrearage) + checkArrearage;
    });
    if (checkArrearage > 0) {
      this.confirmArrearageInfo(this.translateService.instant('FNAPAY100.M00055'));
    } else {
      this.confirmPayment();
    }
  }

  confirmPayment() {
    this.messageService.clear();
    setTimeout(() => {
      let listConfirm = {
        'saleOrderNumber': this.listSaleOrderNumber,
        'confirmPaymentUser': this.userCode,
        'warehouseCode': this.listWarehouse,
        'paymentStatus': this.listPaymentStatus
      };
      this.paymentService.confirmPayment(listConfirm).subscribe(response => {
        window.location.reload();
      });
    }, 250);
  }

  onReject() {
    this.messageService.clear();
  }


  exportReport() {

    let subTitle = [];
    subTitle.push([this.translateService.instant('FNAPAY100.M00056')]);
    let header: any = {
      saleOrderDate: this.translateService.instant('FNAPAY100.M00057'),
      saleOrderNumber: this.translateService.instant('FNAPAY100.M00058'),
      warehouseDes: this.translateService.instant('FNAPAY100.M00059'),
      customerName: this.translateService.instant('FNAPAY100.M00060'),
      klangOneMemberCd: this.translateService.instant('FNAPAY100.M00061'),
      flowType: this.translateService.instant('FNAPAY100.M00062'),
      paymentMode: this.translateService.instant('FNAPAY100.M00063'),
      paymentChannel: this.translateService.instant('FNAPAY100.M00064'),
      bankAccount: this.translateService.instant('FNAPAY100.M00065'),
      paymentDate: this.translateService.instant('FNAPAY100.M00066'),
      saleOrderAmount: this.translateService.instant('FNAPAY100.M00067'),
      paymentedAmount: this.translateService.instant('FNAPAY100.M00068'),
      invoiceNo: this.translateService.instant('FNAPAY100.M00069'),
      receiptNo: this.translateService.instant('FNAPAY100.M00070'),
      arrearage: this.translateService.instant('FNAPAY100.M00071'),
      paymentStatus: this.translateService.instant('FNAPAY100.M00072'),
    }
    this.exportExcelService.exportAsExcelFile(this.exportReportTemp, this.translateService.instant('FNAPAY100.M00056'), header, null, subTitle);
    this.exportReportTemp.shift();
  }

  exportReportDetail(){
    let subTitle = [];
    subTitle.push([this.translateService.instant('FNAPAY100.M00056')]);
    let header: any = {
      saleOrderNumber: this.translateService.instant('FNAPAY100.M00058'),
      saleOrderDate: this.translateService.instant('FNAPAY100.M00057'),
      country:  this.translateService.instant('FNAPAY100.M00000'),
      warehouseDes: this.translateService.instant('FNAPAY100.M00059'),
      seller: this.translateService.instant('FNAPAY100.M00076'),
      klangOneMemberCd: this.translateService.instant('FNAPAY100.M00061'),
      customerName: this.translateService.instant('FNAPAY100.M00060'),
      flowType: this.translateService.instant('FNAPAY100.M00062'),
      paymentMode: this.translateService.instant('FNAPAY100.M00063'),
      invoiceNo: this.translateService.instant('FNAPAY100.M00069'),
      receiptNo: this.translateService.instant('FNAPAY100.M00070'),
      orderStatus: this.translateService.instant('FNAPAY100.M00077'),
      paymentStatus: this.translateService.instant('FNAPAY100.M00072'),
      comfirmShipment: this.translateService.instant('FNAPAY100.M00078'),
      saleOrderAmount: this.translateService.instant('FNAPAY100.M00067'),
      paymentedAmount: this.translateService.instant('FNAPAY100.M00068'),
      arrearage: this.translateService.instant('FNAPAY100.M00071'),
      paymentDate: this.translateService.instant('FNAPAY100.M00066'),
      paymentSaveDate: this.translateService.instant('FNAPAY100.M00079'),
      paymentChannel: this.translateService.instant('FNAPAY100.M00064'),
      bankAccount: this.translateService.instant('FNAPAY100.M00065'),
      remark: this.translateService.instant('FNAPAY100.M00080'),
      amount : this.translateService.instant('FNAPAY100.M00081'),
      savePaymentBy : this.translateService.instant('FNAPAY100.M00082'),
    }
    this.exportExcelService.exportAsExcelFile(this.exportReportDetailTemp, this.translateService.instant('FNAPAY100.M00056'), header, null, subTitle);
    this.exportReportDetailTemp.shift();
  }


  customSort(event) {

    if(event.field === 'saleOrderDate'){

      event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        value1 = value1.split('/')
        value2 = value2.split('/')
        let nDate =new Date(value1[2],value1[1]-1,value1[0]);
        let pDate =new Date(value2[2],value2[1]-1,value2[0]);
        let result = null;
        result = (nDate < pDate) ? -1 : (nDate > pDate) ? 1 : 0;
        return (event.order * result);
      });
    }else{
    event.data.sort((data1, data2) => {
        let value1 = data1[event.field];
        let value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null)
            result = -1;
        else if (value1 != null && value2 == null)
            result = 1;
        else if (value1 == null && value2 == null)
            result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2);
        else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (event.order * result);
    });
  }
}

  @ViewChild('dateFilter', undefined) private dateFilter: any;

  onDatesRangeFilterSelected(selectedValue: Date) {

    if (this.criteriaSearchGrid.saleOrderDate[1]) {
      this.dateFilter.hideOverlay();
    }
  }

  resendApiData(){
    let resentLanguage: string;
    let checkPaymentNotConfirm: boolean = false;
    let resentIsSelect:any=[];

    this.row.forEach(element => {
      resentIsSelect.push(element);
    });

    if(this.user.language == 'TH'){
      resentLanguage = 'th';
    }else{
      resentLanguage = 'en';
    }

    if(resentIsSelect){
      resentIsSelect.forEach(row=>{
        if(row.shipmentStatusCode == '300'){
          checkPaymentNotConfirm = true;
        }else{
          let resentPamApi: any = {
            warehouseCode: row.warehouseCode,
            salesOrderNo: row.saleOrderNumber,
            language: resentLanguage
          }
          this.resentPamApiModel.push(resentPamApi);
        }
      });

      if(checkPaymentNotConfirm){
        resentIsSelect = [];
        this.notUpdatedMessage(this.translateService.instant('FNAPAY100.M00088'));
      }else{
        this.paymentService.resentPamApi(this.resentPamApiModel).subscribe(res=>{
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['/fnapay/fnapay100']).then(() => {
            window.location.reload();
          }));
        });
      }
    }

  }
}
