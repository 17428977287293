import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as JWT from 'jwt-decode';
import { SelectItem } from 'primeng/api';
import { LanguageType } from 'src/app/shared/enum/language-type.enum';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { ParameterCountryList } from 'src/app/shared/model/parameter.model';
import { AdministrationLanguageService } from 'src/app/shared/services/administration-language.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';


@Component({
  selector: 'app-autaut100',
  templateUrl: './autaut100.component.html',
  styleUrls: ['./autaut100.component.scss']
})
export class Autaut100Component implements OnInit {
  switch = 'login';
  users: string;
  password: string;
  data: any;
  status: boolean;
  message: string;
  public aaa: any;
  public devices: any;
  
  // new auth
  public authData = {} as AuthDataModel;
  public tokenData: any;
  public dataLogin: any;

  public optionCountry: Array<ParameterCountryList> = [] as Array<ParameterCountryList>;
  public selectedCountry: ParameterCountryList = {} as ParameterCountryList;

  constructor(
    private router: Router,
    private autService: AuthenticationService,
    private translateService: TranslateService,
    private admLangService :AdministrationLanguageService
    ) {
      let langList = [];
      this.admLangService.getvparameterCountry().subscribe(response => {
        this.optionCountry = JSON.parse(JSON.stringify(response));
        this.optionCountry.forEach(element => {
          langList.push(element.value.entryCode);
        })
        this.translateService.addLangs(langList);
        let rusult = this.optionCountry.filter(data =>data.value.entryDefault == 'Y');
        this.selectedCountry = rusult[0];
        localStorage.setItem('languageData', this.selectedCountry.value.entryCode);
        this.translateService.use(this.selectedCountry.value.entryCode);
        this.translateService.setDefaultLang(this.selectedCountry.value.entryCode);
      }, err => {
        this.translateService.use('EN')
        this.translateService.setDefaultLang('EN');
      });
      this.status = false;
  }

  ngOnInit() {
  }

  useLanguage(language: string) {
    this.translateService.use(language);
  }

  // New Login
  doLogin() {
    if (!this.users || !this.password) {
      this.message = 'AUTAUT100.M00005' as string; // * โปรดระบุชื่อผู้ใช้ และรหัสผ่าน
      this.status = true as boolean;
    } else {
      this.autService.doLogin(this.users, this.password).subscribe((result) => {
        this.dataLogin = result;
        this.tokenData = JWT(this.dataLogin.token);
        this.autService.checkExpToken(this.tokenData.exp);
        this.autService.setToken(this.dataLogin.token);
        this.autService.doAuth().subscribe((authenticate: AuthDataModel) => {
          this.authData = authenticate as AuthDataModel;
          localStorage.setItem('userData', JSON.stringify(this.authData));
          this.router.navigate(['/homhom/homhom100']);
        },
        (error) => {
          this.message = `* ${error.error.message}` as string;
          this.status = true as boolean;
        });
      },
      (error) => {
        this.message = 'AUTAUT100.M00005' as string; //  โปรดระบุชื่อผู้ใช้ และรหัสผ่าน
        this.status = true as boolean;
      });
    }
  }

  loginORregister(page: number) {
    // console.log('page', page);
    if (page === 1) {
      // console.log('login', page);
      this.switch = 'login';
      this.status = false;
    } else if (page === 2) {
      // console.log('register', page);
      this.switch = 'register';
    } else if (page === 3) {
      // console.log('other', page);
      this.switch = 'other';
    }

  }

  user_code(user: string) {
    this.status = false;
    this.users = user;
  }
  pass_word(pass: string) {
    this.status = false;
    this.password = pass;
  }

  ForgotPass() {
    this.router.navigate(['/aut/autaut200']);
    // console.log('forget-password');
  }

  enterFunction(event: any) {
    if (event.keyCode === 13) {
      this.doLogin();
    }
  }

  selectedCountryFunction(event){
    this.useLanguage(event.value.value.entryCode);
    localStorage.setItem('languageData', event.value.value.entryCode);
  }

}

export const data = {
  sellercode: undefined,
  usercode: undefined,
  userfn: undefined,
  userln: undefined
};
