import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  data$: Observable<any> = this.data.asObservable();
  private barcode;

  constructor(private http: HttpClient) { }

  setData(newData) {
    this.data.next(newData);
  }

  set tobarcode(barcode) {
    this.barcode = barcode;
  }

  get tobarcode() {
    return this.barcode;
  }

  public getLanguageJsonfile(language: string): Observable<any>{
    const folderPath = `assets/i18n`;
    return this.http.get(`${folderPath}/${language}.json`, {observe: 'response', responseType: 'blob'});
  }
}
