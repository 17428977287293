import { Component, OnInit, Output, Injectable, Input, EventEmitter, NgZone } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})

@Injectable({
  providedIn: 'root',
})

export class AlertMessageComponent implements OnInit {
  
  messages: any[] = [];
  subscription: Subscription;
  private close = false;

  @Input() rtn:string; 
  private errorHeader = [{keyMsg:'e',severity:'error',summary:'Error'},
                         {keyMsg:'w',severity:'warn',summary:'Worning'},
                         {keyMsg:'i',severity:'info',summary:'Information'},
                         {keyMsg:'s',severity:'success',summary:'Success'}
                        ];
  
  public afterClose;
  start: any;
  end: any;

  constructor(public messageService: MessageService,private ngZone: NgZone) {
  }

  ngOnInit() {
  }

  public alertMsg(){

  }

  public async showMsg(key,msg) : Promise<any> {
    let header = this.errorHeader.filter(x => x.keyMsg == key);
    // console.log(header);
    this.messageService.clear();
    let aa = await this.messageService.add({key: header[0].keyMsg ,sticky: true, severity: header[0].severity ,summary:header[0].summary , detail:msg,
    closable:false});
    
    await this.messageService.clear();
    // while(this.messageService)
      return this.afterClose
  }
 
  resetSubscription() {
    throw new Error("Method not implemented.");
  }
  subscribe() {
    throw new Error("Method not implemented.");
  }

  public clearinfo(result,key){
    this.close = true;
    this.afterClose = result;
    // console.log('this.afterClose',this.afterClose);
    this.messageService.clear(key);
  }

  public showerror(message:string) {
    this.messageService.clear();
    const msg = this.messageService.add({key: 'e',sticky: true, severity:'error',summary:'Error', detail:message,closable:false});
  }
  
  public clearerrorY(aa) {
    this.messageService.clear('e');
    // console.log('aa: ',aa);
  }
  public clearwarnY() {
    this.messageService.clear('w');
  }
  public clearwarnN() {
    this.messageService.clear('w');
  }
  public clearsuccessY() {
    this.messageService.clear('s');
  }

  public clearinfoY() {
    this.messageService.clear('i');
  }

}
