import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { UplRoutingModule } from './upl-routing.module';
import { Upload100Component } from './upload/upload100/upload100.component';
import { Upload200Component } from './upload/upload200/upload200.component';
import { Upload300Component } from './upload/upload300/upload300.component';


@NgModule({
  declarations: [Upload100Component,Upload200Component, Upload300Component],
  imports: [
    CommonModule,
    UplRoutingModule,
    SharedModule
  ]
})
export class UplModule { }
