import { ChangeDetectorRef, Component, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, SelectItem } from 'primeng/api';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { CustomerInquiryDetailModel } from 'src/app/shared/model/customer-inquiry.model';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ExportCsvService } from 'src/app/shared/services/export-csv.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { Cuscus100Model, TempDDL } from './model/cuscus100.model';
import { Cuscus100Service } from './service/cuscus100.service';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { ParameterEntity } from 'src/app/shared/model/paramete.rmodel';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-cuscus100',
  templateUrl: './cuscus100.component.html',
  styleUrls: ['./cuscus100.component.scss']
})
export class Cuscus100Component extends PermissionComponent {

  @ViewChild('table',{static:true}) table: Table
  @ViewChild('tableDetail',{static:true}) tableDetail: Table
  @ViewChild('tableSeller',{static:true}) tableSeller: Table
  page_model: Cuscus100Model;
  countryDisFalg: boolean = false;
  countryDDL: SelectItem[];
  sellerDDL: ParameterEntity[];
  optionSex: TempDDL[];
  optionOccupation: TempDDL[];
  searchForm = new FormGroup({
    keySearch: new FormControl(),
    sellerCode: new FormControl(),
    taxId: new FormControl(),
    email: new FormControl(),
    address: new FormControl(),
    province: new FormControl(),
    district: new FormControl(),
    subdistrict: new FormControl(),
    createBy: new FormControl(),
    updateDate: new FormControl(),
    updateUser: new FormControl(),
  });

  customer_info: MenuItem[];
  customer_detail: MenuItem[];
  customer_bySeller: MenuItem[];
  exportReportFlag: boolean;
  disabledCountry: boolean = false;
  disabledSaleChannel: boolean = false;
  dataCustomerReport: any = [];
  optionCountry: any = [];
  disableExportReport: boolean = false;
  hideAdvancedSearchTab1: boolean = true;
  hideAdvancedSearchTab2: boolean = true;
  hideAdvancedSearchTab3: boolean = true;

  translateLablePipe = new TranslateLablePipe();
  temp_data: Array<CustomerInquiryDetailModel> = [];
  firstRowTab1: number = 0 as number;
  firstRowTab2: number = 0 as number;
  firstRowTab3: number = 0 as number;
  first:number=0;
  last:number=0;
  firstTap2:number=0;
  lastTap2:number=0;
  firstTap3:number=0;
  lastTap3:number=0;
  EXCEL_FORMAT: string = 'EXCEL';
  CSV_FORMAT: string = 'CSV';
  sexDesc: string = '';
  nationality: string ='';

  searchCustomerGroupForm = new FormGroup({
    searchklangOneMemberCode: new FormControl(),
    searchklangThaitMemberCode: new FormControl(),
    searchPhoneNumber: new FormControl(),
    searchCustomerFN: new FormControl(),
    searchCustomerLN: new FormControl(),
    searchRegion: new FormControl(),
    searchZipcode: new FormControl(),
    country: new FormControl(),
    seller: new FormControl(),
  });

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  tempSubdistrict: any;
  tempDistrict: any;
  tempProvince: any;
  tempCountry: any;

  constructor(renderer2: Renderer2,
    private exportExcelService: ExportExcelService,
    private exportCsvService: ExportCsvService,
    private translateService: TranslateService,
    private service: Cuscus100Service,
    private parameterService: ParameterService,
    private autService: AuthenticationService,
    private parameterwms: ParameterWmsSerive,
    private cdRef : ChangeDetectorRef,
    private router: Router,) {
    super('P50001', renderer2);
    this.page_model = this.service.load;
    this.page_model.searchFormSummitFlag = true;
    this.exportReportFlag= true;
    this.disabledSaleChannel = true;
  }

  ngOnInit() {
    this.ClearData();

    this.page_model.date.locale = this.translateCalendar;

    this.parameterwms.getParameterWms(104).subscribe(response => {this.tempSubdistrict = response})
    this.parameterwms.getParameterWms(103).subscribe(response => {this.tempDistrict = response})
    this.parameterwms.getParameterWms(102).subscribe(response => {this.tempProvince = response})
    this.parameterwms.getParameterWms(105).subscribe(response => {this.tempCountry = response})

    this.autService.getParameterWmsCountry().subscribe(response => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.optionCountry.length == 1) {
        this.page_model.searchFormSummitFlag = false;
      }else{
        this.page_model.searchFormSummitFlag = true;
      }
    }, err => {
      console.log(err);
    });

    setTimeout(() => {
      this.customer_info = [
        { label: this.translateService.instant('Excel'), command: () => { this.exportReport_view_information_cr(this.EXCEL_FORMAT); }, disabled: this.disableExportReport},
        { label: this.translateService.instant('CSV'), command: () => { this.exportReport_view_information_cr(this.CSV_FORMAT); }, disabled: this.disableExportReport}
      ]
      this.customer_detail = [
        { label: this.translateService.instant('Excel'), command: () => { this.exportReport_view_detail_information(this.EXCEL_FORMAT); }, disabled: this.disableExportReport},
        { label: this.translateService.instant('CSV'), command: () => { this.exportReport_view_detail_information(this.CSV_FORMAT); }, disabled: this.disableExportReport}
      ]
      this.customer_bySeller = [
        { label: this.translateService.instant('Excel'), command: () => { this.exportReport_by_seller(this.EXCEL_FORMAT); }, disabled: this.disableExportReport},
        { label: this.translateService.instant('CSV'), command: () => { this.exportReport_by_seller(this.CSV_FORMAT); }, disabled: this.disableExportReport}
      ]
    }, 7000);
    this.service.loadComponanceData(this.page_model);
    this.parameterService.getvparameterDetailToLable(4501).subscribe((response) => {
      this.optionSex = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.parameterService.getvparameterDetailToLable(4502).subscribe((response) => {
      this.optionOccupation = response.map(detail => ({
        ...detail,
      })).sort((a, b) => {
        const numA = parseInt(a.label.split(' - ')[0], 10);
        const numB = parseInt(b.label.split(' - ')[0], 10);
        return numA - numB;
      });
    }, err => {
    console.log(err);
    });

    this.searchForm.get('keySearch').valueChanges.subscribe(() => {
      if (this.searchForm.get('keySearch').value) {
        this.advanceSearchFunction();
      }
    });

  }

  searchFunction() {
    this.page_model.searchFormSummitFlag = false;
    this.service.searchFunction().subscribe((CustomerList: Array<CustomerInquiryDetailModel>) => {
      if (this.page_model.form_model.search_form.get('kpmenId').value) {
        this.page_model.customer_inquiry_list = CustomerList;
        this.page_model.customer_inquiry_list2 = CustomerList.filter((value, index, array) => index == array.findIndex(item => item.customerId == value.customerId));
        this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter(data => data.kpmenId != null);
        this.firstRowTab1 = 0
        this.firstRowTab2 = 0
        this.firstRowTab3 = 0
      } else {
        this.page_model.customer_inquiry_list = CustomerList
        const seen = new Set<string>();
        this.page_model.customer_inquiry_list2 = CustomerList.filter((value) => {
            if (seen.has(value.customerId)) {
                return false;
            } else {
                seen.add(value.customerId);
                return true;
            }
        });
        this.firstRowTab1 = 0
        this.firstRowTab2 = 0
        this.firstRowTab3 = 0
      }

      this.page_model.customer_inquiry_list.forEach( data => {
          if (data.province) {
            this.tempProvince.forEach(item => {
              if (item.value === data.province) {
                data.province = this.translateLablePipe.transform(item.engDesc, item.localDesc);
              }
            });
          }
        }
      )

      this.page_model.customer_inquiry_list.forEach( data => {
        if (data.country) {
          this.tempCountry.forEach(element => {
            if (data.country == element.value) {
              data.country = this.translateLablePipe.transform(element.engDesc, element.localDesc);
            }
          });
        }
      });

      this.page_model.customer_inquiry_list.forEach( data => {
        if (data.district) {
          this.tempDistrict.forEach(item => {
            if (item.value === data.district) {
                data.district = this.translateLablePipe.transform(item.engDesc, item.localDesc);
            }
          });
        }
      });

      this.page_model.customer_inquiry_list.forEach(data => {
        if (data.subdistrict) {
            this.tempSubdistrict.forEach(item => {
            if (item.value === data.subdistrict) {
                data.subdistrict = this.translateLablePipe.transform(item.engDesc, item.localDesc);
            }
         });
        }
      });

      this.page_model.customer_inquiry_list.forEach( data => {
        if (data.stat != null){
          if (data.stat == 'true'){
            data.stat =  this.translateService.instant('CUSCUS100.M00307')
          } else {
            data.stat =  this.translateService.instant('CUSCUS100.M00308')
          }
        }
      });

      this.page_model.tmp_data = this.page_model.customer_inquiry_list;

      this.page_model.tmp_data.forEach(data => {
        data.sexDesc = this.translateLablePipe.transform(data.sexDesc_eng, data.sexDesc_local);
        data.occupationDesc = this.translateLablePipe.transform(data.occupationDesc_eng, data.occupationDesc_local);
      });

      if (this.page_model.form_model.search_form.valid) {
        const slrmemid: string = this.page_model.form_model.search_form.get('slrmemid').value;

        if (slrmemid) {
          this.page_model.customer_inquiry_list = this.page_model.customer_inquiry_list.filter((item: CustomerInquiryDetailModel) => {
            const slrmemidValue: string = `${item.slrmemid}`;
            const slrmemidConcatValue = slrmemidValue.toLowerCase();
            return slrmemidConcatValue.indexOf(slrmemid.toLowerCase()) !== -1
          });
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
            const slrmemidValue: string = `${item.slrmemid}`;
            const slrmemidConcatValue = slrmemidValue.toLowerCase();
            return slrmemidConcatValue.indexOf(slrmemid.toLowerCase()) !== -1
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }
      }

      if (this.page_model.form_model.search_form.valid) {
        const province: string = this.page_model.form_model.search_form.get('province').value;
        if (province) {
          this.page_model.customer_inquiry_list = this.page_model.customer_inquiry_list.filter((item: CustomerInquiryDetailModel) => {
            const provinceValue: string = `${item.province}`;
            const provinceConcatValue = provinceValue.toLowerCase();
              return provinceConcatValue.indexOf(province.toLowerCase()) !== -1
          });
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
            const provinceValue: string = `${item.province}`;
            const provinceConcatValue = provinceValue.toLowerCase();
              return provinceConcatValue.indexOf(province.toLowerCase()) !== -1
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }
      }
      this.getSeller();
    }, error => {
      console.log(error);
    });
  }

  getSeller() {
    this.autService.getParameterWmsSeller(null, null).subscribe((res: ParameterEntity[]) => {
      this.sellerDDL = JSON.parse(JSON.stringify(res));
      this.page_model.customer_inquiry_list.forEach((list) => {
        if (list.sellerCode) {
          this.sellerDDL.forEach((sel) => {
              if (list.sellerCode === sel.value) {
                list.sellerCode = sel.label;
              }
          });
        }
      });
    });
  }

  ngAfterViewChecked(){
    if(this.table._totalRecords === 0 ){
    } else {
      this.first = this.table._first + 1;
      this.last = this .table._first + this.table._rows;
      if(this.last > this.page_model.customer_inquiry_list2.length ){
        this.last = this.page_model.customer_inquiry_list2.length
      }
    }
    // tableDetail
    if(this.tableDetail._totalRecords === 0 ){
    } else {
      this.firstTap2 = this.tableDetail._first + 1;
      this.lastTap2 = this .tableDetail._first + this.tableDetail._rows;
      if(this.last > this.page_model.customer_inquiry_list2.length ){
        this.last = this.page_model.customer_inquiry_list2.length
      }
    }
    //tableSeller
    if(this.tableSeller._totalRecords === 0 ){
    } else {
      this.firstTap3 = this.tableSeller._first + 1;
      this.lastTap3 = this .tableSeller._first + this.tableSeller._rows;
      if(this.lastTap3 > this.page_model.customer_inquiry_list.length ){
        this.lastTap3 = this.page_model.customer_inquiry_list.length
      }
    }
    this.cdRef.detectChanges();
  }

  ClearData() {
    this.exportReportFlag = true;
    this.page_model.searchFormSummitFlag = true;
    this.page_model.customer_inquiry_list = [];
    this.page_model.customer_inquiry_list2 = [];
    this.page_model.form_model.criterial_form.reset();
    if ( this.page_model.data_list.country_data.length === 1 ) {
      this.page_model.form_model.criterial_form.patchValue({
        country:  this.page_model.data_list.country_data[0].value
      });
      this.changeCountry();
    }
    this.page_model.form_model.search_form.patchValue({
      country: "",
      customerId: "",
      kpmenId: "",
      kpdfmemid: "",
      stat: "",
      firstname: "",
      sex: "",
      occupation: "",
      phoneNumber: "",
      province: "",
      birhdayFilter: "",
      slrmemid: "",
      lastname: "",
      age: "",
      memberCreateDatetime: "",
    });
    this.searchForm.patchValue({
      sellerCode :"",
      taxId :"",
      address :"",
      email :"",
      province :"",
      district :"",
      subdistrict :"",
      createBy :"",
      updateDate :"",
      updateUser :"",
    });

  }

  addCustomer() {
    this.router.navigate(['/cuscus/cuscus200']);
  }

  exportReport_view_information_cr(format) {
    const subTitle = [];
    subTitle.push([this.translateService.instant('CUSCUS100.M00039')]);
    const header: any = {
      customer_active: this.translateService.instant('CUSCUS100.M00008'),
      customer_kp_id: this.translateService.instant('CUSCUS100.M00001'),
      customer_kpdef_id: this.translateService.instant('CUSCUS100.M00002'),
      customer_seller_code: this.translateService.instant('CUSCUS100.M00018'),
      customer_seller_id: this.translateService.instant('CUSCUS100.M00019'),
      customer_firstname: this.translateService.instant('CUSCUS100.M00033'),
      customer_lastname: this.translateService.instant('CUSCUS100.M00007'),
      customer_phone: this.translateService.instant('CUSCUS100.M00009'),
      customer_birth: this.translateService.instant('CUSCUS100.M00020'),
      customer_age: this.translateService.instant('CUSCUS100.M00003'),
      customer_sex: this.translateService.instant('CUSCUS100.M00004'),
      customer_occupation: this.translateService.instant('CUSCUS100.M00005'),
      customer_nationality: this.translateService.instant('CUSCUS200.M00055'),
      customer_tax_id: this.translateService.instant('CUSCUS100.M00010'),
      customer_url_facebook: this.translateService.instant('CUSCUS100.M000312'),
      customer_country: this.translateService.instant('CUSCUS100.M00011'),
      customer_address: this.translateService.instant('CUSCUS100.M00013'),
      customer_district: this.translateService.instant('CUSCUS100.M00015'),
      customer_sub_district: this.translateService.instant('CUSCUS100.M00016'),
      customer_province: this.translateService.instant('CUSCUS100.M00014'),
      customer_postal_code: this.translateService.instant('CUSCUS100.M00017'),
    };
    this.dataCustomerReport = [];
    this.page_model.customer_inquiry_list.forEach((element) => {
      const dataReport = {
        customer_active: element.stat,
        customer_kp_id: element.kpmenId,
        customer_kpdef_id: element.kpdfmemid,
        customer_seller_code: element.sellerCode,
        customer_seller_id: element.slrmemid,
        customer_firstname: element.firstname,
        customer_lastname: element.lastname,
        customer_phone: element.phoneNumber,
        customer_birth: element.birth,
        customer_age: element.age,
        customer_sex: element.sexDesc_eng,
        customer_occupation: element.occupationDesc,
        customer_nationality: element.nationalityDesc_eng,
        customer_tax_id: element.taxId,
        customer_url_facebook: element.facebook,
        customer_country: element.country,
        customer_address: element.address,
        customer_district: element.district,
        customer_sub_district: element.subdistrict,
        customer_province: element.province,
        customer_postal_code: element.postCode,
      };
      this.dataCustomerReport.push(dataReport);
    });

    if (format == this.EXCEL_FORMAT) {
      this.exportExcelService.exportAsExcelFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00039'), header, null, subTitle);
      this.dataCustomerReport.shift();
    } else if (format == this.CSV_FORMAT) {
      this.exportCsvService.exportAsCsvFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00039'), header, null, subTitle);
      this.dataCustomerReport.shift();
    } else {
      this.exportExcelService.exportAsExcelFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00039'), header, null, subTitle);
      this.dataCustomerReport.shift();
    }
  }

  exportReport_view_information(format) {
    const subTitle = [];
    subTitle.push([this.translateService.instant('CUSCUS100.M00039')]);
    const header: any = {
      customer_kp_id: this.translateService.instant('CUSCUS100.M00001'),
      customer_kpdef_id: this.translateService.instant('CUSCUS100.M00002'),
      customer_firstname: this.translateService.instant('CUSCUS100.M00033'),
      customer_lastname: this.translateService.instant('CUSCUS100.M00007'),
      customer_phone: this.translateService.instant('CUSCUS100.M00009'),
      customer_country: this.translateService.instant('CUSCUS100.M00011'),
      customer_age: this.translateService.instant('CUSCUS100.M00003'),
      customer_sex: this.translateService.instant('CUSCUS100.M00004'),
      customer_occupation: this.translateService.instant('CUSCUS100.M00005'),
      customer_nationality: this.translateService.instant('CUSCUS200.M00055'),
      customer_create_date: this.translateService.instant('CUSCUS100.M00293'),
      customer_create_by: this.translateService.instant('CUSCUS100.M00295'),
      customer_update: this.translateService.instant('CUSCUS100.M00297'),
      customer_update_user: this.translateService.instant('CUSCUS100.M00296'),
      customer_active: this.translateService.instant('CUSCUS100.M00008'),
    };
    this.dataCustomerReport = [];
    this.page_model.customer_inquiry_list.forEach((element) => {
      const dataReport = {
        customer_kp_id: element.kpmenId,
        customer_kpdef_id: element.kpdfmemid,
        customer_firstname: element.firstname,
        customer_lastname: element.lastname,
        customer_phone: element.phoneNumber,
        customer_country: element.country,
        customer_age: element.age,
        customer_sex: element.sexDesc_eng,
        customer_occupation: element.occupationDesc,
        customer_nationality: element.nationalityDesc_eng,
        customer_create_date: element.createDate,
        customer_create_by: element.createBy,
        customer_update: element.updateDate,
        customer_update_user: element.updateUser,
        customer_active: element.stat,
      };
      this.dataCustomerReport.push(dataReport);
    });

    if (format == this.EXCEL_FORMAT) {
      this.exportExcelService.exportAsExcelFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00039'), header, null, subTitle);
      this.dataCustomerReport.shift();
    } else if (format == this.CSV_FORMAT) {
      this.exportCsvService.exportAsCsvFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00039'), header, null, subTitle);
      this.dataCustomerReport.shift();
    } else {
      this.exportExcelService.exportAsExcelFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00039'), header, null, subTitle);
      this.dataCustomerReport.shift();
    }
  }

  exportReport_view_detail_information(format) {
    const subTitle = [];
    subTitle.push([this.translateService.instant('CUSCUS100.M00040')]);
    const header: any = {
      customer_kp_id: this.translateService.instant('CUSCUS100.M00001'),
      customer_kpdef_id: this.translateService.instant('CUSCUS100.M00002'),
      customer_firstname: this.translateService.instant('CUSCUS100.M00033'),
      customer_lastname: this.translateService.instant('CUSCUS100.M00007'),
      customer_birth: this.translateService.instant('CUSCUS100.M00020'),
      customer_age: this.translateService.instant('CUSCUS100.M00003'),
      customer_sex: this.translateService.instant('CUSCUS100.M00004'),
      customer_occupation: this.translateService.instant('CUSCUS100.M00005'),
      customer_phone: this.translateService.instant('CUSCUS100.M00009'),
      customer_email: this.translateService.instant('CUSCUS100.M00012'),
      customer_tax_id: this.translateService.instant('CUSCUS100.M00010'),
      customer_url_facebook: this.translateService.instant('CUSCUS100.M000312'),
      customer_klangthai: this.translateService.instant('ORDSOR200.M00045'),
      customer_nationality: this.translateService.instant('CUSCUS200.M00055'),
      customer_country: this.translateService.instant('CUSCUS100.M00011'),
      customer_address: this.translateService.instant('CUSCUS100.M00013'),
      customer_province: this.translateService.instant('CUSCUS100.M00014'),
      customer_district: this.translateService.instant('CUSCUS100.M00015'),
      customer_sub_district: this.translateService.instant('CUSCUS100.M00016'),
      customer_postal_code: this.translateService.instant('CUSCUS100.M00017'),
      customer_create_date: this.translateService.instant('CUSCUS100.M00293'),
      customer_create_by: this.translateService.instant('CUSCUS100.M00295'),
      customer_update: this.translateService.instant('CUSCUS100.M00297'),
      customer_update_user: this.translateService.instant('CUSCUS100.M00296'),
      customer_active: this.translateService.instant('CUSCUS100.M00008'),
    };
    this.dataCustomerReport = [];
    this.page_model.customer_inquiry_list2.forEach((element) => {
      const dataReport = {
      customer_kp_id: element.kpmenId,
      customer_kpdef_id: element.kpdfmemid,
      customer_firstname: element.firstname,
      customer_lastname: element.lastname,
      customer_birth: element.birth,
      customer_age: element.age,
      customer_sex: element.sexDesc_eng,
      customer_occupation: element.occupationDesc,
      customer_phone: element.phoneNumber,
      customer_email: element.email,
      customer_tax_id: element.taxId,
      customer_url_facebook: element.facebook,
      customer_klangthai: element.klangThaitMemberCode,
      customer_nationality: element.nationalityDesc_eng,
      customer_country: element.country,
      customer_address: element.address,
      customer_province: element.province,
      customer_district: element.district,
      customer_sub_district: element.subdistrict,
      customer_postal_code: element.postCode,
      customer_create_date: element.createDate,
      customer_create_by: element.createBy,
      customer_update: element.updateDate,
      customer_update_user: element.updateUser,
      customer_active: element.stat,
      };
      this.dataCustomerReport.push(dataReport);
    });

    if (format == this.EXCEL_FORMAT) {
      this.exportExcelService.exportAsExcelFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00040'), header, null, subTitle);
      this.dataCustomerReport.shift();
    } else if (format == this.CSV_FORMAT) {
      this.exportCsvService.exportAsCsvFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00040'), header, null, subTitle);
      this.dataCustomerReport.shift();
    } else {
      this.exportExcelService.exportAsExcelFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00040'), header, null, subTitle);
      this.dataCustomerReport.shift();
    }
  }

  exportReport_by_seller(format) {
    const subTitle = [];
    subTitle.push([this.translateService.instant('CUSCUS100.M00041')]);
    const header: any = {
      customer_kp_id: this.translateService.instant('CUSCUS100.M00001'),
      customer_kpdef_id: this.translateService.instant('CUSCUS100.M00002'),
      customer_seller_code: this.translateService.instant('CUSCUS100.M00018'),
      customer_seller_id: this.translateService.instant('CUSCUS100.M00019'),
      customer_firstname: this.translateService.instant('CUSCUS100.M00033'),
      customer_lastname: this.translateService.instant('CUSCUS100.M00007'),
      customer_birth: this.translateService.instant('CUSCUS100.M00020'),
      customer_age: this.translateService.instant('CUSCUS100.M00003'),
      customer_sex: this.translateService.instant('CUSCUS100.M00004'),
      customer_occupation: this.translateService.instant('CUSCUS100.M00005'),
      customer_phone: this.translateService.instant('CUSCUS100.M00009'),
      customer_email: this.translateService.instant('CUSCUS100.M00012'),
      customer_tax_id: this.translateService.instant('CUSCUS100.M00010'),
      customer_url_facebook: this.translateService.instant('CUSCUS100.M000312'),
      customer_klangthai: this.translateService.instant('ORDSOR200.M00045'),
      customer_nationality: this.translateService.instant('CUSCUS200.M00055'),
      customer_country: this.translateService.instant('CUSCUS100.M00011'),
      customer_address: this.translateService.instant('CUSCUS100.M00013'),
      customer_province: this.translateService.instant('CUSCUS100.M00014'),
      customer_district: this.translateService.instant('CUSCUS100.M00015'),
      customer_sub_district: this.translateService.instant('CUSCUS100.M00016'),
      customer_postal_code: this.translateService.instant('CUSCUS100.M00017'),
      customer_create_date: this.translateService.instant('CUSCUS100.M00293'),
      customer_create_by: this.translateService.instant('CUSCUS100.M00295'),
      customer_update: this.translateService.instant('CUSCUS100.M00297'),
      customer_update_user: this.translateService.instant('CUSCUS100.M00296'),
      customer_active: this.translateService.instant('CUSCUS100.M00008'),
    };
    this.dataCustomerReport = [];
    this.page_model.customer_inquiry_list.forEach((element) => {
      const dataReport = {
        customer_kp_id: element.kpmenId,
        customer_kpdef_id: element.kpdfmemid,
        customer_seller_code: element.sellerCode,
        customer_seller_id: element.slrmemid,
        customer_firstname: element.firstname,
        customer_lastname: element.lastname,
        customer_birth: element.birth,
        customer_age: element.age,
        customer_sex: element.sexDesc_eng,
        customer_occupation: element.occupationDesc,
        customer_phone: element.phoneNumber,
        customer_email: element.email,
        customer_tax_id: element.taxId,
        customer_url_facebook: element.facebook,
        customer_klangthai: element.klangThaitMemberCode,
        customer_nationality: element.nationalityDesc_eng,
        customer_country: element.country,
        customer_address: element.address,
        customer_province: element.province,
        customer_district: element.district,
        customer_sub_district: element.subdistrict,
        customer_postal_code: element.postCode,
        customer_create_date: element.createDate,
        customer_create_by: element.createBy,
        customer_update: element.updateDate,
        customer_update_user: element.updateUser,
        customer_active: element.stat,
      };
      this.dataCustomerReport.push(dataReport);
    });

    if (format == this.EXCEL_FORMAT) {
      this.exportExcelService.exportAsExcelFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00041'), header, null, subTitle);
      this.dataCustomerReport.shift();
    } else if (format == this.CSV_FORMAT) {
      this.exportCsvService.exportAsCsvFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00041'), header, null, subTitle);
      this.dataCustomerReport.shift();
    } else {
      this.exportExcelService.exportAsExcelFile(this.dataCustomerReport, this.translateService.instant('CUSCUS100.M00041'), header, null, subTitle);
      this.dataCustomerReport.shift();
    }
  }

  convertDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  formatDate(dateTimeString: string | null | undefined): string | null {
    if (dateTimeString) {
      const [datePart] = dateTimeString.split(' ');
      return datePart;
    }
    return 'NULL';
}

  advanceSearchFunction() {
    if (this.searchForm.valid) {
      const key_search: string = this.searchForm.get('keySearch').value;
      const sellerCode: string = this.searchForm.get('sellerCode').value;
      const taxId: string = this.searchForm.get('taxId').value;
      const address: string = this.searchForm.get('address').value;
      const email: string = this.searchForm.get('email').value;
      const province: string = this.searchForm.get('province').value;
      const district: string = this.searchForm.get('district').value;
      const subdistrict: string = this.searchForm.get('subdistrict').value;
      const createBy: string = this.searchForm.get('createBy').value;
      const updateDate: string = this.convertDate(this.searchForm.get('updateDate').value);
      const updateUser: string = this.searchForm.get('updateUser').value;

      if (sellerCode || taxId || address || email || province || district || subdistrict || createBy || updateUser || this.searchForm.get('updateDate').value || key_search) {

        if (this.searchForm.get('updateDate').value) {
          this.page_model.customer_inquiry_list2 = this.page_model.tmp_data.filter((item: CustomerInquiryDetailModel) => {
            if (item.updateDate) {
              const concatValue: string = `${item.firstname}
              ${item.country}
              ${item.customerId}
              ${item.sellerCode}
              ${item.firstname}
              ${item.lastname}
              ${item.phoneNumber}
              ${item.klangOneMemberCode}
              ${item.klangThaitMemberCode}
              ${item.taxId}
              ${item.address}
              ${item.subdistrict}
              ${item.district}
              ${item.province}
              ${item.postCode}
              ${item.email}
              ${item.kpmenId}
              ${item.kpdfmemid}
              ${item.slrmemid}
              ${item.birth}
              ${item.age}
              ${item.sex}
              ${item.occupation}
              ${item.phoneNumber2}
              ${item.phoneNumber3}
              ${item.phoneNumber4}
              ${item.phoneNumber5}
              ${item.createBy}
              ${item.createDate}
              ${this.formatDate(item.updateDate)}
              ${item.updateUser}
              ${item.stat}`;
              const lowercaseConcatValue = concatValue.toLowerCase();
              return(
                (key_search && lowercaseConcatValue.indexOf(key_search.toLowerCase()) !== -1) ||
                (sellerCode && lowercaseConcatValue.indexOf(sellerCode.toLowerCase()) !== -1) ||
                (taxId && lowercaseConcatValue.indexOf(taxId.toLowerCase()) !== -1) ||
                (address && lowercaseConcatValue.indexOf(address.toLowerCase()) !== -1) ||
                (email && lowercaseConcatValue.indexOf(email.toLowerCase()) !== -1) ||
                (province && lowercaseConcatValue.indexOf(province.toLowerCase()) !== -1) ||
                (district && lowercaseConcatValue.indexOf(district.toLowerCase()) !== -1) ||
                (subdistrict && lowercaseConcatValue.indexOf(subdistrict.toLowerCase()) !== -1) ||
                (createBy && lowercaseConcatValue.indexOf(createBy.toLowerCase()) !== -1) ||
                (updateDate && lowercaseConcatValue.indexOf(updateDate.toLowerCase()) !== -1) ||
                (updateUser && lowercaseConcatValue.indexOf(updateUser.toLowerCase()) !== -1)
              );
            }
            this.firstRowTab1 = 0
            this.firstRowTab2 = 0
            this.firstRowTab3 = 0
          });
        } else {
          this.page_model.customer_inquiry_list2 = this.page_model.tmp_data.filter((item: CustomerInquiryDetailModel) => {
            const concatValue: string = `${item.firstname}
            ${item.country}
            ${item.customerId}
            ${item.sellerCode}
            ${item.firstname}
            ${item.lastname}
            ${item.phoneNumber}
            ${item.klangOneMemberCode}
            ${item.klangThaitMemberCode}
            ${item.taxId}
            ${item.address}
            ${item.subdistrict}
            ${item.district}
            ${item.province}
            ${item.postCode}
            ${item.email}
            ${item.kpmenId}
            ${item.kpdfmemid}
            ${item.slrmemid}
            ${item.birth}
            ${item.age}
            ${item.sex}
            ${item.occupation}
            ${item.phoneNumber2}
            ${item.phoneNumber3}
            ${item.phoneNumber4}
            ${item.phoneNumber5}
            ${item.createBy}
            ${item.createDate}
            ${this.formatDate(item.updateDate)}
            ${item.updateUser}
            ${item.stat}`;
            const lowercaseConcatValue = concatValue.toLowerCase();
            return(
              (key_search && lowercaseConcatValue.indexOf(key_search.toLowerCase()) !== -1) ||
              (sellerCode && lowercaseConcatValue.indexOf(sellerCode.toLowerCase()) !== -1) ||
              (taxId && lowercaseConcatValue.indexOf(taxId.toLowerCase()) !== -1) ||
              (address && lowercaseConcatValue.indexOf(address.toLowerCase()) !== -1) ||
              (email && lowercaseConcatValue.indexOf(email.toLowerCase()) !== -1) ||
              (province && lowercaseConcatValue.indexOf(province.toLowerCase()) !== -1) ||
              (district && lowercaseConcatValue.indexOf(district.toLowerCase()) !== -1) ||
              (subdistrict && lowercaseConcatValue.indexOf(subdistrict.toLowerCase()) !== -1) ||
              (createBy && lowercaseConcatValue.indexOf(createBy.toLowerCase()) !== -1) ||
              (updateDate && lowercaseConcatValue.indexOf(updateDate.toLowerCase()) !== -1) ||
              (updateUser && lowercaseConcatValue.indexOf(updateUser.toLowerCase()) !== -1)
            );
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }

        if (sellerCode) {
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
            const sellerCodeValue: string = `${item.sellerCode}`;
            const sellerCodeConcatValue = sellerCodeValue.toLowerCase();
            return sellerCodeConcatValue.indexOf(sellerCode.toLowerCase()) !== -1
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }

        if (taxId) {
            this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
              const taxIdValue: string = `${item.taxId}`;
              const taxIdConcatValue = taxIdValue.toLowerCase();
              return taxIdConcatValue.indexOf(taxId.toLowerCase()) !== -1
            });
            this.firstRowTab1 = 0
            this.firstRowTab2 = 0
            this.firstRowTab3 = 0
          }

        if (address) {
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
            const addressValue: string = `${item.address}`;
            const addressConcatValue = addressValue.toLowerCase();
            return addressConcatValue.indexOf(address.toLowerCase()) !== -1
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }

        if (email) {
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
            const emailValue: string = `${item.email}`;
            const emailConcatValue = emailValue.toLowerCase();
            return emailConcatValue.indexOf(email.toLowerCase()) !== -1
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }


        if (province) {
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
            const provinceValue: string = `${item.province}`;
            const provinceConcatValue = provinceValue.toLowerCase();
            return provinceConcatValue.indexOf(province.toLowerCase()) !== -1
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }


        if (subdistrict) {
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
            const subdistrictValue: string = `${item.subdistrict}`;
            const subdistrictConcatValue = subdistrictValue.toLowerCase();
            return subdistrictConcatValue.indexOf(subdistrict.toLowerCase()) !== -1
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }

        if (createBy) {
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
            const createByValue: string = `${item.createBy}`;
            const createByConcatValue = createByValue.toLowerCase();
            return createByConcatValue.indexOf(createBy.toLowerCase()) !== -1
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }

        if (updateUser) {
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
            const updateUserValue: string = `${this.formatDate(item.updateUser)}`;
            const updateUserConcatValue = updateUserValue.toLowerCase();
            return updateUserConcatValue.indexOf(updateUser.toLowerCase()) !== -1
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }

        if (key_search) {
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2.filter((item: CustomerInquiryDetailModel) => {
            const key_searchValue: string = `${item.firstname}
            ${item.country}
            ${item.customerId}
            ${item.sellerCode}
            ${item.firstname}
            ${item.lastname}
            ${item.phoneNumber}
            ${item.klangOneMemberCode}
            ${item.klangThaitMemberCode}
            ${item.taxId}
            ${item.address}
            ${item.subdistrict}
            ${item.district}
            ${item.province}
            ${item.postCode}
            ${item.email}
            ${item.kpmenId}
            ${item.kpdfmemid}
            ${item.slrmemid}
            ${item.birth}
            ${item.age}
            ${item.sex}
            ${item.occupation}
            ${item.phoneNumber2}
            ${item.phoneNumber3}
            ${item.phoneNumber4}
            ${item.phoneNumber5}
            ${item.createBy}
            ${item.createDate}
            ${this.formatDate(item.updateDate)}
            ${item.updateUser}
            ${item.stat}`;
            const key_searchConcatValue = key_searchValue.toLowerCase();
            return key_searchConcatValue.indexOf(key_search.toLowerCase()) !== -1
          });
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        } else {
          this.page_model.customer_inquiry_list2 = this.page_model.customer_inquiry_list2
          this.firstRowTab1 = 0
          this.firstRowTab2 = 0
          this.firstRowTab3 = 0
        }

      } else {
        this.page_model.customer_inquiry_list2 = this.page_model.tmp_data;
        this.firstRowTab1 = 0
        this.firstRowTab2 = 0
        this.firstRowTab3 = 0
      }

    }

  }

  changeCountry(){
    this.page_model.searchFormSummitFlag = false;
  }

  showAdvancedSearch(teb) {
    if (teb == '1') {
      if (this.hideAdvancedSearchTab1 == true) {
        this.hideAdvancedSearchTab1 = false;
      } else {
        this.hideAdvancedSearchTab1 = true;
      }
    } else if (teb == '2') {
      if (this.hideAdvancedSearchTab2 == true) {
        this.hideAdvancedSearchTab2 = false;
      } else {
        this.hideAdvancedSearchTab2 = true;
      }
    } else if (teb == '3') {
      if (this.hideAdvancedSearchTab3 == true) {
        this.hideAdvancedSearchTab3 = false;
      } else {
        this.hideAdvancedSearchTab3 = true;
      }
    }

  }
}
