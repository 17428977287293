import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as JWT from 'jwt-decode';
import { AuthDataModel, AuthModel } from '../model/authentication.model';
import { Router } from '@angular/router';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private AUT_URL: string = `${this.environmentService.getApiUrl()}/authentication` as string;

  private key_token: string  = 'Token' as string;
  private key_data: string  = 'userData' as string;
  expire: boolean;
  checkExpTime: any;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private router: Router
  ) {}

  public ckeckExpOrRefreshToken() {
    if (localStorage) {
      const user = JSON.parse(localStorage.getItem(this.key_data)) as AuthDataModel;
      const current = new Date();
      const refreshToken = new Date(user.refreshToken);
      const token = JWT(localStorage.getItem(this.key_token));
      this.checkExpToken(token.exp);
      if (current >= refreshToken && this.expire == false) {
        this.doRefreshToken().subscribe((result) => {
          this.removeToken();
          const tokenData = JWT(result.token);
          this.checkExpToken(tokenData.exp);
          this.setToken(result.token);
          this.doAuth().subscribe((result: any) => {
            this.removeAuthData();
            const authData = result as AuthDataModel;
            localStorage.setItem('userData', JSON.stringify(authData));
          });
        }, (error) => {
          this.removeToken();
          this.removeAuthData();
          this.router.navigate(['/']);
        });
      } else if (this.expire) {
        this.removeToken();
        this.removeAuthData();
        this.router.navigate(['/']);
      }
    }
  }

  public refreshtoKen() {
    this.doRefreshToken().subscribe((result) => {
      this.removeToken();
      const tokenData = JWT(result.token);
      this.checkExpToken(tokenData.exp);
      this.setToken(result.token);
      this.doAuth().subscribe((result) => {
        this.removeAuthData();
        const authData = result as AuthDataModel;
        localStorage.setItem('userData', JSON.stringify(authData));
      });
    }, (error) => {
      this.removeToken();
      this.removeAuthData();
      this.router.navigate(['/']);
    });
  }

  public removeToken() {
    localStorage.removeItem(this.key_token);
  }

  public removeAuthData() {
    localStorage.removeItem(this.key_data);
  }

  public doLogin(username: string, pass: string): Observable<AuthModel> {
    this.removeToken();
    const URL = `${this.AUT_URL}/signin`;
    return this.http.post<AuthModel>(URL, { password: pass, userName: username});
  }

  public doAuth(): Observable<AuthDataModel>{
    const URL = `${this.AUT_URL}/userauthentication`;
    return this.http.get<AuthDataModel>(URL, {headers: this.tokenHeader()});
  }

  public doRefreshToken(): Observable<AuthModel> {
    const URL = `${this.AUT_URL}/refreshtoken`;
    return this.http.get<AuthModel>(URL, {headers: this.tokenHeader()});
  }

  public doLogout() {
    const URL = `${this.AUT_URL}/signout`;
    return this.http.get<AuthDataModel>(URL, {headers: this.tokenHeader()});
  }

  public checkExpToken(expTime) {
    this.checkExpTime = expTime;
    const date = new Date(0);
    date.setUTCSeconds(expTime);
    if (date.valueOf() < new Date().valueOf()) {
      this.expire = true;
    } else {
      this.expire = false;
    }
  }

  public getExpToken() {
    this.checkExpToken(this.checkExpTime);
    return this.expire;
  }

  public setToken(token): void {
    localStorage.setItem(this.key_token, token);
  }

  public tokenHeader(): any {
    return {
      'Authorization': `Bearer ${this.getToken()}`,
      'Content-Type': 'application/json'
    };
  }

  public tokenHeaderUploadFile(): any {
    return {
      'Authorization': `Bearer ${this.getToken()}`
    };
  }

  public getToken(): string {
    return localStorage.getItem(this.key_token);
  }

  getParameterWmsSeller(country,warehouse){
    const URL = `${this.AUT_URL}/getParameterWmsSeller/${country}/${warehouse}`;
    return this.http.get(URL, {headers: this.tokenHeader()});
  }

  getParameterWmsWareHouse(country){
    const URL = `${this.AUT_URL}/getParameterWarehouse/${country}`;
    return this.http.get(URL, {headers: this.tokenHeader()});
  }

  getParameterSaleChannel(country){
    const URL = `${this.AUT_URL}/getParameterSaleChannel/${country}`;
    return this.http.get(URL, {headers: this.tokenHeader()});
  }

  getParameterSaleChannelInquiry(country){
    const URL = `${this.AUT_URL}/getParameterSaleChannelInquiry/${country}`;
    return this.http.get(URL, {headers: this.tokenHeader()});
  }

  getParameterWmsFlowTypeInquiry(){
    const URL = `${this.AUT_URL}/getParameterFlowTypeInquiry`;
    return this.http.get(URL, {headers: this.tokenHeader()});
  }

  getParameterWmsFlowType(){
    const URL = `${this.AUT_URL}/getParameterFlowType`;
    return this.http.get(URL, {headers: this.tokenHeader()});
  }

  getParameterWmsCountry(){
    const URL = `${this.AUT_URL}/getParameterCountry`;
    return this.http.get(URL, {headers: this.tokenHeader()});
  }

  getParameterNewSaleChannel(country){
    const URL = `${this.AUT_URL}/getParameterNewSaleChannel/${country}`;
    return this.http.get(URL, {headers: this.tokenHeader()});
  }

}
