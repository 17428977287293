import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService} from 'primeng/api';
import { Admprm200Service } from '../../service/admprm/admprm200.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Admprm200Model, ParameterDetailTable } from '../../model/admprm/admprm200.model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';

@Component({
  selector: 'app-admprm200',
  templateUrl: './admprm200.component.html',
  styleUrls: ['./admprm200.component.scss']
})
export class Admprm200Component extends PermissionComponent{

  public page_model: Admprm200Model;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private admprm200Service: Admprm200Service,
              private translateService: TranslateService,
              private autService: AuthenticationService,
              renderer2:Renderer2,
              private fb: FormBuilder) {
    super('P01000',renderer2);
    this.page_model = this.admprm200Service.load;
    this.route.paramMap.subscribe(params => {
      if (params) {
        this.page_model.pmhtbhtbno = params.get('pmhtbhtbno');
      }
    });
  }

  ngOnInit() {

    this.autService.getParameterWmsCountry().subscribe(response => {
      this.page_model.optionCountry = JSON.parse(JSON.stringify(response));
    });
    this.admprm200Service.getParameterHeaderGroup().subscribe(response => {
      this.page_model.parameterHeaderGroupList = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.admprm200Service.getParameterHeaderByPmhtbhtbno(this.page_model.pmhtbhtbno).subscribe(response => {
      this.page_model.parameterHeader = JSON.parse(JSON.stringify(response));
        this.page_model.pmhtbhgroupHeader.push({value: this.page_model.parameterHeader.pmhtbhgroup, label: this.page_model.parameterHeader.pmhtbhgroup});
        if(this.page_model.parameterHeader.pmhtbhsys){
          this.page_model.checkFlagButtonAdd = true;
          this.page_model.checkFlagEntryCb = true;
        }else{
          this.page_model.checkFlagButtonAdd = false;
          this.page_model.checkFlagEntryCb = false;
        }
        this.page_model.form_model.gridParameterHeaderForm = this.fb.group({
          pmhtbhtbno: (this.page_model.parameterHeader.pmhtbhtbno),
          pmhtbhgroup: (this.page_model.parameterHeader.pmhtbhgroup),
          pmhtbhsys: (this.page_model.parameterHeader.pmhtbhsys),
          pmhtbhacc: (this.page_model.parameterHeader.pmhtbhacc),
          pmhtbhedesc: [this.page_model.parameterHeader.pmhtbhedesc, Validators.required],
          pmhtbhlangcode: (this.page_model.parameterHeader.pmhtbhlangcode),
          pmhcmnt: (this.page_model.parameterHeader.pmhcmnt),
          pmhtbhldesc: (this.page_model.parameterHeader.pmhtbhldesc)
        });
    }, err => {
      console.log(err);
    });
    
    this.admprm200Service.getvparameterDetailByPmhtbhtbno(this.page_model.pmhtbhtbno).subscribe(async response => {
      this.page_model.parameterDetailList = JSON.parse(JSON.stringify(response));
      this.page_model.parameterDetailList.forEach((data, index) => {
        this.page_model.parameterDetail.push({pmdtbdtbno: data.value.pmdtbdtbno,
          pmdtbdtbdentcd: data.value.pmdtbdtbdentcd,
          pmdtbdtbdedesc: data.value.pmdtbdtbdedesc,
          pmdtbdlangcode: data.value.pmdtbdlangcode,
          pmdtbddms: data.value.pmdtbddms,
          pmdtbdtbdldesc: data.value.pmdtbdtbdldesc
        });
      });
      this.ckeckParameterDetailTable();
    }, err => {
      console.log(err);
    });

    setTimeout(() => {
      this.checkPermission();
    }, 4000);
  }

  checkPermission(){
    let checkPermission = this.permissionData.accActionFalse.filter(act =>act == "3");
    console.log('checkPermission',checkPermission);
    
    if(checkPermission.length > 0){
      this.page_model.checkFlagEntryCb = true;
    }else{
      this.page_model.checkFlagEntryCb =  false;
    }
  }


  checkedAll(isChecker: boolean) {
    let country = this.page_model.form_model.gridParameterHeaderPanel.get('country').value;
    this.page_model.parameterDetailDelete = [];
    if (isChecker) {
      this.page_model.parameterDetail.forEach((data, index) => {
        if(country == null){
          this.page_model.parameterDetailDelete.push(data);
        }else if(country != null && data.pmdtbddms == country){
          this.page_model.parameterDetailDelete.push(data);
        }
      });
      this.page_model.checkFlagButtonDelete = false;
    } else {
      this.page_model.parameterDetailDelete = [];
      this.page_model.checkFlagButtonDelete = true;
    }
  }

  checked(isChecker: boolean, parameter: ParameterDetailTable) {
    if (isChecker) {
      this.page_model.parameterDetailDelete.push(parameter);
      this.page_model.checkFlagButtonDelete = false;
    } else {
      let parameterDetailDeleteCheck : Array<ParameterDetailTable> = [] as Array<ParameterDetailTable>;
      this.page_model.parameterDetailDelete.forEach((data, index) => {
        if(data.pmdtbdtbdentcd != parameter.pmdtbdtbdentcd){
          parameterDetailDeleteCheck.push(data);
        }
      });
      this.page_model.parameterDetailDelete = parameterDetailDeleteCheck;
      if(this.page_model.parameterDetailDelete.length > 0){
        this.page_model.checkFlagButtonDelete = false;
      }else{
        this.page_model.checkFlagButtonDelete = true;
      }
    }
  }
  
  ckeckParameterDetailTable(){
    if(this.page_model.parameterDetail.length > 0){
      if(this.page_model.parameterDetail[0].pmdtbddms != null){
        this.page_model.checkFlagCountry = false;
      }else{
        setTimeout(() => {
          this.page_model.parameterDetailFilter = this.page_model.parameterDetail;
          this.page_model.parameterDetailSmartSearch = this.page_model.parameterDetail;
          this.page_model.checkFlagCountry = true;
          if(this.page_model.parameterDetailFilter){
            this.page_model.flagEmptyTable = false;
          }else{
            this.page_model.flagEmptyTable = true;
          }
        }, 1400);
      }
    }
  }
  
  async getCountry(e){
    this.page_model.parameterDetailFilter = this.page_model.parameterDetail;
    this.page_model.parameterDetailFilter = this.page_model.parameterDetailFilter.filter(data => {
      return (
        (data.pmdtbddms.toString().toLowerCase().indexOf(e.value.toString().toLowerCase()) > -1) 
      );
    });
    this.page_model.parameterDetailSmartSearch = this.page_model.parameterDetailFilter.filter(data => {
      return (
        (data.pmdtbddms.toString().toLowerCase().indexOf(e.value.toString().toLowerCase()) > -1) 
      );
    });
    if(this.page_model.parameterDetailFilter){
      this.page_model.flagEmptyTable = false;
    }else{
      this.page_model.flagEmptyTable = true;
    }
    this.page_model.smartSearch = '';
    this.page_model.firstPage = 0;
  }

  deleteParameterDetail(){
    this.deleteAlert(this.translateService.instant('ADMPRM200.M00029'));
  }

  async updatePrameterHeader(){
    this.page_model.parameterHeaderUpdate = {
      pmhtbhtbno: this.page_model.parameterHeader.pmhtbhtbno,
      pmhtbhgroup: this.page_model.parameterHeader.pmhtbhgroup,
      pmhtbhsys: this.page_model.parameterHeader.pmhtbhsys,
      pmhtbhacc: this.page_model.parameterHeader.pmhtbhacc,
      pmhtbhedesc: this.page_model.form_model.gridParameterHeaderForm.get('pmhtbhedesc').value == '' ? null : this.page_model.form_model.gridParameterHeaderForm.get('pmhtbhedesc').value,
      pmhtbhlangcode: this.page_model.parameterHeader.pmhtbhlangcode,
      pmhcmnt: this.page_model.form_model.gridParameterHeaderForm.get('pmhcmnt').value == '' ? null : this.page_model.form_model.gridParameterHeaderForm.get('pmhcmnt').value,
      pmhtbhldesc: this.page_model.form_model.gridParameterHeaderForm.get('pmhtbhldesc').value == '' ? null : this.page_model.form_model.gridParameterHeaderForm.get('pmhtbhldesc').value,
    }
    await this.admprm200Service.updateparameterHeader(this.page_model.parameterHeaderUpdate).subscribe(response =>{
      let dataResponse = JSON.parse(JSON.stringify(response));
      if(dataResponse.length > 0){
        this.successAlert(this.translateService.instant('ADMPRM200.M00024'));
        setTimeout(() => {
          window.location.reload();
          window.scroll(0, 0);
        }, 500);
      }else{
        this.warningAlert('alert', 'error', this.translateService.instant('ADMPRM200.M00026'), this.translateService.instant('ADMPRM200.M00025'));
      }
    });
  }

  checkInputUpdateParameterHeader(){
    let pmhtbhedesc = this.page_model.form_model.gridParameterHeaderForm.get('pmhtbhedesc').value == '' ? null : this.page_model.form_model.gridParameterHeaderForm.get('pmhtbhedesc').value;
    let pmhcmnt = this.page_model.form_model.gridParameterHeaderForm.get('pmhcmnt').value == '' ? null : this.page_model.form_model.gridParameterHeaderForm.get('pmhcmnt').value;
    let pmhtbhldesc = this.page_model.form_model.gridParameterHeaderForm.get('pmhtbhldesc').value == '' ? null : this.page_model.form_model.gridParameterHeaderForm.get('pmhtbhldesc').value;
    if(this.page_model.form_model.gridParameterHeaderForm.controls['pmhtbhedesc'].valid){
      if(pmhtbhedesc != this.page_model.parameterHeader.pmhtbhedesc || pmhcmnt != this.page_model.parameterHeader.pmhcmnt || pmhtbhldesc != this.page_model.parameterHeader.pmhtbhldesc){
        this.page_model.checkFlagInputPrmhdr = false;
      }else{
        this.page_model.checkFlagInputPrmhdr = true;
      }
    }else{
      this.page_model.checkFlagInputPrmhdr = true;
    }
  }

  navigateToEditParameterDetail(pmdtbdtbno: string, pmdtbdtbdentcd: string, pmdtbddms: string) {
    if(pmdtbddms == null){
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['admprm/admprm300', pmdtbdtbno, pmdtbdtbdentcd]).then(() => {
          window.location.reload();
          window.scroll(0, 0);
        })
      );
    }else{
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['admprm/admprm300', pmdtbdtbno, pmdtbdtbdentcd, pmdtbddms]).then(() => {
          window.location.reload();
          window.scroll(0, 0);
        })
      );
    }
  }

  navigateToAddParameterDetail(pmdtbdtbno: StringConstructor) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['admprm/admprm300', pmdtbdtbno]).then(() => {
        window.location.reload();
        window.scroll(0, 0);
      })
    );
  }

  warningAlert(key: string, severityInfo: string, summary: string, detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: key, sticky: true, severity: severityInfo, summary: summary, detail: detaiInfo});
  }

  onReject(){
    this.messageService.clear();
  }

  onConfirm(){
    this.messageService.clear();
    window.location.reload();
    window.scroll(0, 0);
  }

  successAlert(detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: 'alertsuccess', sticky: true, severity: 'success', detail: detaiInfo});
  }

  goBack() {
    if(!this.page_model.checkFlagInputPrmhdr){
        this.warningAlert('goback', 'warn', this.translateService.instant('ADMPRM200.M00030'), this.translateService.instant('ADMPRM200.M00027'));
    }else{
      this.router.navigate(['admprm/admprm100']);
    }
  }

  onConfirmGoBack(){
    this.messageService.clear();
    this.router.navigate(['admprm/admprm100']);
  }

  smartSearchFunction(){
    this.page_model.parameterDetailFilter = this.page_model.parameterDetailSmartSearch;
    this.page_model.parameterDetailFilter = this.page_model.parameterDetailFilter.filter(data => {
      return (
        (data.pmdtbdtbdentcd.toString().toLowerCase().indexOf(this.page_model.smartSearch.toString().toLowerCase()) > -1) ||
        (data.pmdtbdtbdedesc.toString().toLowerCase().indexOf(this.page_model.smartSearch.toString().toLowerCase()) > -1) ||
        (data.pmdtbdtbdldesc.toString().toLowerCase().indexOf(this.page_model.smartSearch.toString().toLowerCase()) > -1)
      );
    });
    if(this.page_model.parameterDetailFilter.length > 0){
      this.page_model.flagEmptyTable = false;
    }else{
      this.page_model.flagEmptyTable = true;
    }
    this.page_model.firstPage = 0;
  }

  deleteAlert(detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: 'alertdelete', sticky: true, severity: 'warn', summary: this.translateService.instant('ADMPRM200.M00011'), detail: detaiInfo});
  }

  async onConfirmDelete(){
    await this.page_model.parameterDetailDelete.forEach((data, index) => {
      let mdtbdtbno = data.pmdtbdtbno;
      let pmdtbdtbdentcd = data.pmdtbdtbdentcd;
      let pmdtbddms = data.pmdtbddms;
      this.admprm200Service.deleteparameterDetail(mdtbdtbno, pmdtbdtbdentcd, pmdtbddms).subscribe(response =>{
        let dataResponse = JSON.parse(JSON.stringify(response));
        if(dataResponse){
          this.successAlert(this.translateService.instant('ADMPRM200.M00022'));
          setTimeout(() => {
            window.location.reload();
            window.scroll(0, 0);
          }, 500);
        }else{
          this.warningAlert('alert', 'error', this.translateService.instant('ADMPRM200.M00026'), this.translateService.instant('ADMPRM200.M00023'));
        }
      });
    });
  }
}
