import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Admprm100Component } from './admprm/admprm100/admprm100.component';
import { AdmRoutingModule } from './adm-routing.module';
import { Admprm300Component } from './admprm/admprm300/admprm300.component';
import { Admprm200Component } from './admprm/admprm200/admprm200.component';

import { SharedModule } from '../shared/shared.module';
import { Admatr100Component } from './admatr/admatr100/admatr100.component';
import { Admatr200Component } from './admatr/admatr200/admatr200.component';
import { Admusr100Component } from './admusr/admusr100/admusr100.component';
import { Admusr200Component } from './admusr/admusr200/admusr200.component';
import { Admusr300Component } from './admusr/admusr300/admusr300.component';
import { Admusr110Component } from './admusr/admusr110/admusr110.component';
import { Admpro100Component } from './admpro/admpro100/admpro100.component';
import { Admpro200Component } from './admpro/admpro200/admpro200.component';
import { Admpro300Component } from './admpro/admpro300/admpro300.component';


@NgModule({
  declarations: [
    Admprm100Component,
    Admprm200Component,
    Admprm300Component,
    Admatr100Component,
    Admatr200Component,
    Admusr100Component,
    Admusr200Component,
    Admusr300Component,
    Admusr110Component,
    Admpro100Component,
    Admpro200Component,
    Admpro300Component
  ],
  imports: [
    CommonModule,
    AdmRoutingModule,
    SharedModule
  ]
})
export class AdmModule { }
