import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ParameterService } from '../services/parameter.service';


@Pipe({
    name: 'decimal'
})
export class NumberDecimalPipe implements PipeTransform {
    
    public numberDecimal:string;

    constructor(private decimalPipe: DecimalPipe, private parameterService: ParameterService) {
    }
    transform(value: number, decimal: number): string{
        if(value != null && decimal != null){
            this.numberDecimal = this.decimalPipe.transform(value, '1.'+decimal+'-'+decimal, 'en');
        }else if(value != null && decimal == null){
            this.numberDecimal = value.toString();
        }else{
            this.numberDecimal = null;
        }
        return this.numberDecimal
    }
}

