import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Admprm100Model } from '../../model/admprm/admprm100.model';
import { Admprm100Service } from '../../service/admprm/admprm100.service';
import { TranslateService } from '@ngx-translate/core';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';

@Component({
  selector: 'app-admprm100',
  templateUrl: './admprm100.component.html',
  styleUrls: ['./admprm100.component.scss']
})

export class Admprm100Component implements OnInit {
  public page_model: Admprm100Model;

  constructor(private router: Router,
              private admprm100Service: Admprm100Service,
              private translateService: TranslateService,
              private exportExcelService: ExportExcelService) {
    this.page_model = this.admprm100Service.load;
    this.page_model.flagClear = true;
  }
  ngOnInit() {
    this.admprm100Service.getParameterHeader().subscribe(response => {
      this.page_model.parameterHeaderListFilter = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.admprm100Service.getParameterHeaderGroup().subscribe(response => {
      this.page_model.parameterHeaderGroupList = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
  }

  navigateToParameterDetail(pmhtbhtbno: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['admprm/admprm200', pmhtbhtbno]).then(() => {
        window.location.reload();
        window.scroll(0, 0);
      })
    );
  }

  changeSearch(){
    if(this.page_model.form_model.searchGridGroupForm.controls['pmhtbhtbno'].invalid){
      this.page_model.flagSearch = true;
    }
    if(this.page_model.form_model.searchGridGroupForm.controls['pmhtbhtbno'] == null){
      this.page_model.flagSearch = true;
    }else{
      this.page_model.flagSearch = false;
      this.page_model.flagClear = false;
    }
  }

  searchFunction() {
    this.admprm100Service.getParameterHeaderByCritiria(this.page_model.form_model.searchGridGroupForm.value).subscribe(response => {
      this.page_model.parameterHeaderList = JSON.parse(JSON.stringify(response));
      this.page_model.parameterHeaderListFilter = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    if(this.page_model.parameterHeaderList){
      this.page_model.flagEmptyTable = false;
    }else{
      this.page_model.flagEmptyTable = true;
    }
    this.page_model.flagSearch = true;
    this.page_model.firstPage = 0;
  }

  clearSearchFunction() {
    this.page_model.form_model.searchGridGroupForm.reset();
    this.page_model.flagSearch = false;
    this.page_model.flagClear = true;
  }

  smartSearchFunction(){
    this.page_model.parameterHeaderList = this.page_model.parameterHeaderListFilter;
    this.page_model.parameterHeaderList = this.page_model.parameterHeaderList.filter(data => {
      data.pmhtbhldesc = data.pmhtbhldesc == null ? '': data.pmhtbhldesc;
      return (
        (data.pmhtbhtbno.toString().toLowerCase().indexOf(this.page_model.smartSearch.toString().toLowerCase()) > -1) ||
        (data.pmhtbhgroup.toString().toLowerCase().indexOf(this.page_model.smartSearch.toString().toLowerCase()) > -1) ||
        (data.pmhtbhedesc.toString().toLowerCase().indexOf(this.page_model.smartSearch.toString().toLowerCase()) > -1) ||
        (data.pmhtbhldesc.toString().toLowerCase().indexOf(this.page_model.smartSearch.toString().toLowerCase()) > -1)
      );
    });
    if(this.page_model.parameterHeaderList.length > 0){
      this.page_model.flagEmptyTable = false;
    }else{
      this.page_model.flagEmptyTable = true;
    }
    this.page_model.firstPage = 0;
  }

  exportReportFucntion(){
    this.page_model.parameterHeaderListReport = [];
    this.page_model.parameterHeaderList.forEach((element) => {
      this.page_model.parameterHeaderListReport.push(
        {
          pmhtbhtbno: element.pmhtbhtbno,
          pmhtbhgroup: element.pmhtbhgroup,
          pmhtbhedesc: element.pmhtbhedesc,
          pmhtbhldesc: element.pmhtbhldesc
        }
      )
    });
    let subTitle = [];
    subTitle.push([this.translateService.instant('ADMPRM100.M00001')]);
    let header: any = {
      pmhtbhtbno: this.translateService.instant('ADMPRM100.M00003'),
      pmhtbhgroup: this.translateService.instant('ADMPRM100.M00004'),
      pmhtbhedesc: this.translateService.instant('ADMPRM100.M00005'),
      pmhtbhldesc: this.translateService.instant('ADMPRM100.M00006')
    }
    this.exportExcelService.exportAsExcelFile(this.page_model.parameterHeaderListReport, this.translateService.instant('ADMPRM100.M00001'), header, null, subTitle);
  }
}
