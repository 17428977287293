import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomRoutingModule } from './hom-routing.module';
import { Homhom100Component } from './homhom100/homhom100.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    Homhom100Component
  ],
  imports: [
    CommonModule,
    HomRoutingModule,
    SharedModule
  ]
})
export class HomModule { }
