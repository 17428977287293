import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormModel, Upload300Model } from '../model/upload300.model';

@Injectable({
  providedIn: 'root'
})
export class Upload300Service {

  upload300: Upload300Model = {} as Upload300Model;

  constructor(private fb: FormBuilder) {
    this.inti();
  }

  inti(): void {
    this.upload300.file_item = null;
    this.upload300.form_model = {} as FormModel;
    this.upload300.form_model.upload_form_item = this.fb.group({
      format_file: [null, Validators.required],
      upload_date: (null)
    });
    this.upload300.format_file_option_item;
    this.upload300.to_day = formatDate(new Date(), 'dd/MM/yyyy', 'en-US');
    this.upload300.flag_upload_date_item = true;
    this.upload300.flag_upload_file_item = true;
    this.upload300.format_file_item = null;
    this.upload300.flag_browse_file_item = true;
    this.upload300.file_ihitem = null;
    this.upload300.form_model.upload_form_ihitem = this.fb.group({
      format_file: [null, Validators.required],
      upload_date: (null)
    });
    this.upload300.format_file_option_ihitem;
    this.upload300.flag_upload_date_ihitem = true;
    this.upload300.flag_upload_file_ihitem = true;
    this.upload300.format_file_ihitem = null;
    this.upload300.flag_browse_file_ihitem = true;
  }

  get load(): Upload300Model {
    return this.upload300;
  }
}
