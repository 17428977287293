import { Component, Renderer2 } from '@angular/core';
import { MessageService } from "primeng/primeng";
import { MenuSendToActionPage, ProfileData } from 'src/app/adm/model/admpro/admpro300.model';
import { AdmproLocalStorageService } from 'src/app/adm/service/admpro/admproLocalStorage.service';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { Admpro200Service } from 'src/app/adm/service/admpro/admpro200.service';

@Component({
  selector: 'app-admpro300',
  templateUrl: './admpro300.component.html',
  styleUrls: ['./admpro300.component.scss'],
  providers: [MessageService]
})
export class Admpro300Component extends PermissionComponent {

  public menuByActionData: MenuSendToActionPage = {} as MenuSendToActionPage;
  public tempMenuByActionData: MenuSendToActionPage = {} as MenuSendToActionPage;
  public profileData: ProfileData = {} as ProfileData;
  public disablePermission: boolean;
  public checkDirty: boolean;
  public disableBtn: boolean

  constructor(
    private localStorageService: AdmproLocalStorageService,
    private profileservice: Admpro200Service,
    private messageService: MessageService,
    renderer2: Renderer2) {
    super('P01002', renderer2);
    this.profileData = this.localStorageService.getProfileData();
    this.menuByActionData = this.localStorageService.getProfileData().menuSendToActionPage;
    this.tempMenuByActionData =  this.localStorageService.getProfileData().menuSendToActionPage;
    this.menuByActionData.actionData.sort((a, b) => (a.actionId > b.actionId) ? 1 : -1);
    this.tempMenuByActionData.actionData.sort((a, b) => (a.actionId > b.actionId) ? 1 : -1);
    this.checkDisableSave();
  }

  ngOnInit() {
  }

  switchAction(){
      this.checkDirty = JSON.stringify(this.menuByActionData.actionData) == JSON.stringify(this.tempMenuByActionData.actionData);
    if(JSON.stringify(this.menuByActionData.actionData) == JSON.stringify(this.tempMenuByActionData.actionData)){
      this.checkDirty = false;
    }else if(JSON.stringify(this.menuByActionData.actionData) != JSON.stringify(this.tempMenuByActionData.actionData)){
      this.checkDirty = true;
    }
    this.checkDisableSave();
  }

  submitAction() {
    this.localStorageService.updateActionByMenu(this.menuByActionData.indexParent, this.menuByActionData.indexChilde, this.menuByActionData.actionData, 'EDITED');
    this.profileData = this.localStorageService.getProfileData();
    if (this.profileData.profileCode && this.profileData.profileDesc) {

      if (this.menuByActionData.profileId == null) {
        //case create data
        this.profileservice.saveProfile(this.profileData).subscribe((res: number) => {
          if (res) {
            this.profileData.profileId = res;
            this.profileData.menuSendToActionPage.profileId = res;
            this.checkDirty = false;
            this.profileData.checkDirty = false;
            this.localStorageService.addProfileData(this.profileData);
            this.messageService.clear();
            this.messageService.add({
              key: 'save',
              severity: 'success',
              detail: 'ADMPRO300.M00004',
            });

            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMPRO300.M00005' , detail: 'ADMPRO300.M00006' });
          }
        });

      } else {

        // case update data
        this.profileservice.updateProfile(this.profileData).subscribe((res: number) => {
          if (res) {
            this.profileData.profileId = res;
            this.profileData.menuSendToActionPage.profileId = res;
            this.checkDirty = false;
            this.profileData.checkDirty = false;
            this.localStorageService.addProfileData(this.profileData);
            this.messageService.clear();
            this.messageService.add({
              key: 'save',
              severity: 'success',
              detail: 'ADMPRO300.M00004',
            });

            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMPRO300.M00005' , detail: 'ADMPRO300.M00006' });
          }
        });

      }
    }else{
      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'ADMPRO300.M00005' , detail: 'ADMPRO300.M00009' });
    }
  }

  backBtn() {
    if (this.checkDirty == true) {
      this.messageService.add({ key: 'alert', sticky: true, severity: 'warn', summary: 'ADMPRO300.M00015', detail: 'ADMPRO300.M00010' });
    } else {
      this.setPath();
    }
  }

  clickOkBack() {
    this.setPath();
  }

  setPath() {
    if (this.profileData.profileId == null) {
      this.localStorageService.updateActionByMenu(null, null, null, 'EDITED');
      window.location.href = 'admpro/admpro200';
    } else {
      window.location.href = 'admpro/admpro200/' + this.menuByActionData.profileId;
    }
  }

  clickAfterAlert(){
    this.messageService.clear();
  }

  checkDisableSave(){
    if(this.profileData.checkDirty == true || this.checkDirty == true){
      this.disableBtn = false;
    }else{
      this.disableBtn = true;
    }
  }

}
