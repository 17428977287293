import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { callCenterModel, callCenterVariable, empcalPage } from './model/empcal200.model';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmpcalCalService } from 'src/app/shared/services/empcal.service';
import * as moment from 'moment';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { th } from 'src/app/shared/constant/date.constant';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-empcal200',
  templateUrl: './empcal200.component.html',
  styleUrls: ['./empcal200.component.scss']
})
export class Empcal200Component extends PermissionComponent {

  public callCenterPage: empcalPage = {} as empcalPage;
  public callCenterModel: callCenterModel = {} as callCenterModel;
  callCenterVariable: callCenterVariable = {} as callCenterVariable;
  countryCall: string;
  provinceCall : string;
  districtCall : string;

  public th: any;
  translateLablePipe = new TranslateLablePipe();

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  callCenterDetailForm = new FormGroup({

    callCenterId: new FormControl(),

    //	ข้อมูลพนักงาน
    callCenterCode: new FormControl({ value: '', disabled: true },Validators.required),
    callCenterFName: new FormControl({ value: '', disabled: false },Validators.required),
    callCenterLName: new FormControl({ value: '', disabled: false },Validators.required),
    callCenterOrgDesc: new FormControl({ value: '', disabled: true },Validators.required),
    callCenterOrg: new FormControl({ value: '', disabled: true },Validators.required),
    callCenterOlDesc: new FormControl({ value: '', disabled: true },Validators.required),
    callCenterOl: new FormControl({ value: '', disabled: true },Validators.required),
    callCenterUser: new FormControl({ value: '', disabled: true },Validators.required),
    callCenterBD: new FormControl({ value: '', disabled: false },Validators.required),
    callCenterIdCard: new FormControl({ value: '', disabled: false }),
    callCenterTexId: new FormControl({ value: '', disabled: false }),
    callCenterEmail: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    callCenterTel: new FormControl({ value: '', disabled: false },Validators.required),
    callCenterStatus: new FormControl({ value: '', disabled: false }),

    //  ที่อยู่
    callCenterAddress: new FormControl({ value: '', disabled: false },Validators.required),
    callCenterSubDistrict: new FormControl({ value: '', disabled: false }),
    callCenterDistrict: new FormControl({ value: '', disabled: false }),
    callCenterProvince: new FormControl({ value: '', disabled: false },Validators.required),
    callCenterPostalCode: new FormControl({ value: '', disabled: false },Validators.required),
    callCenterCountry: new FormControl({ value: '', disabled: false },Validators.required),

    //	ช่องทางการติดต่อ
    callCenterLine: new FormControl({ value: '', disabled: false }),
    callCenterInstagram: new FormControl({ value: '', disabled: false }),
    callCenterFacebook: new FormControl({ value: '', disabled: false }),
    callCenterWhatApps: new FormControl({ value: '', disabled: false }),
    callCenterTelegram: new FormControl({ value: '', disabled: false }),

    //	บัญชีธนาคาร
    callCenterAccNo: new FormControl({ value: '', disabled: false }),
    callCenterBankAcc: new FormControl({ value: '', disabled: false }),
    callCenterAccName: new FormControl({ value: '', disabled: false }),
    callCenterCOO: new FormControl({ value: '', disabled: false }),
    callCenterChanPay: new FormControl({ value: '', disabled: false }),

  });

  constructor(
    private router: Router,
    private messageService: MessageService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private empcalservice: EmpcalCalService,
    private parameterWmsSerive: ParameterWmsSerive,
    private parameterService: ParameterService,
    renderer2: Renderer2
  ) {
    super('P70002', renderer2);
    this.callCenterPage.callCenterCode = this.route.snapshot.paramMap.get('callCenterCode');
    this.getCallCenterDetail();

  }

  ngOnInit() {
    this.getParameterToDDL();
    this.callCenterPage.maxDate = new Date();
    this.callCenterPage.formatDate = th;
  }

  getParameterToDDL() {
    // Country
    this.parameterWmsSerive.getParameterWms(105).subscribe((response) => {
      this.callCenterPage.country = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    let payChan : any = [];
    this.parameterService.getParameterDetailToLableValueCode(4300).subscribe((response) => {
      this.callCenterPage.payChan = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

  }

  getProvider(){
    // Province
      this.parameterWmsSerive.getAddressWms(102,this.callCenterDetailForm.controls['callCenterCountry'].value).subscribe((response) => {
        this.callCenterPage.province = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });
  }

  getDistrict(){
    this.parameterWmsSerive.getAddressWms(103, this.callCenterDetailForm.controls['callCenterProvince'].value).subscribe((response) => {
      this.callCenterPage.district = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
  }

  getSubDistrict(){
    this.parameterWmsSerive.getAddressWms(104, this.callCenterDetailForm.controls['callCenterDistrict'].value).subscribe((response) => {
      this.callCenterPage.subDistrict = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
  }

  getBank(){
    this.parameterService.getvprmdtltolableByCountryV2(4302, this.callCenterDetailForm.controls['callCenterCOO'].value).subscribe((response) => {
      this.callCenterPage.bank = JSON.parse(JSON.stringify(response));
    });
  }

  getCallCenterDetail() {
    let callCenterDataTemp
    this.empcalservice.getCallCenterDetail(this.callCenterPage.callCenterCode).subscribe((response) => {
      callCenterDataTemp = JSON.parse(JSON.stringify(response))
      if(callCenterDataTemp.callCenterBD != null){
        this.callCenterPage.birthday = new Date(callCenterDataTemp.callCenterBD);
      }

      this.callCenterDetailForm.patchValue({
        callCenterId:callCenterDataTemp.callCenterId,
    //	ข้อมูลพนักงาน
        callCenterCode: callCenterDataTemp.callCenterCode,
        callCenterFName: callCenterDataTemp.callCenterFName,
        callCenterLName: callCenterDataTemp.callCenterLName,
        callCenterOrg: callCenterDataTemp.callCenterOrg,
        callCenterOrgDesc: this.translateLablePipe.transform(callCenterDataTemp.callCenterOrgDesc_eng,callCenterDataTemp.callCenterOrgDesc_local),
        callCenterOl: callCenterDataTemp.callCenterOl,
        callCenterOlDesc: this.translateLablePipe.transform(callCenterDataTemp.callCenterOlDesc_eng ,callCenterDataTemp.callCenterOlDesc_local),
        callCenterUser: callCenterDataTemp.callCenterUser,
        callCenterBD: this.callCenterPage.birthday,
        callCenterIdCard: callCenterDataTemp.callCenterIdCard,
        callCenterTexId: callCenterDataTemp.callCenterTexId,
        callCenterEmail: callCenterDataTemp.callCenterEmail,
        callCenterTel: callCenterDataTemp.callCenterTel,
        callCenterStatus: callCenterDataTemp.callCenterStatus,

        //  ที่อยู่
        callCenterAddress: callCenterDataTemp.callCenterAddress,
        callCenterSubDistrict: callCenterDataTemp.callCenterSubDistrict,
        callCenterDistrict: callCenterDataTemp.callCenterDistrict,
        callCenterProvince: callCenterDataTemp.callCenterProvince,
        callCenterPostalCode: callCenterDataTemp.callCenterPostalCode,
        callCenterCountry: callCenterDataTemp.callCenterCountry,

        //	ช่องทางการติดต่อ
        callCenterLine: callCenterDataTemp.callCenterLine,
        callCenterInstagram: callCenterDataTemp.callCenterInstagram,
        callCenterFacebook: callCenterDataTemp.callCenterFacebook,
        callCenterWhatApps: callCenterDataTemp.callCenterWhatApps,
        callCenterTelegram: callCenterDataTemp.callCenterTelegram,

        //	บัญชีธนาคาร
        callCenterAccNo: callCenterDataTemp.callCenterAccNo,
        callCenterBankAcc: callCenterDataTemp.callCenterBankAcc,
        callCenterAccName: callCenterDataTemp.callCenterAccName,
        callCenterCOO: callCenterDataTemp.callCenterCOO,
        callCenterChanPay: callCenterDataTemp.callCenterChanPay,
      });
      this.callCenterPage.callCenterEdit = this.callCenterDetailForm.value;
      this.countryCall = this.callCenterDetailForm.controls['callCenterCountry'].value;
      this.provinceCall=  this.callCenterDetailForm.controls['callCenterProvince'].value;
      this.districtCall = this.callCenterDetailForm.controls['callCenterDistrict'].value;

      if(this.callCenterDetailForm.controls['callCenterCountry'].value){
        this.getProvider();
        this.getBank()
      }
      if(this.callCenterDetailForm.controls['callCenterProvince'].value){
        this.getDistrict();
      }
      if(this.callCenterDetailForm.controls['callCenterDistrict'].value){
        this.getSubDistrict();
      }
    }, err => {
      console.log(err);
    });
  }

  checkRequired() {
    if (
      (this.callCenterDetailForm.controls['callCenterFName'].value !== '' &&
        this.callCenterDetailForm.controls['callCenterFName'].value !== undefined &&
        this.callCenterDetailForm.controls['callCenterFName'].value !== null
      ) &&
      (this.callCenterDetailForm.controls['callCenterLName'].value !== '' &&
        this.callCenterDetailForm.controls['callCenterLName'].value !== undefined &&
        this.callCenterDetailForm.controls['callCenterLName'].value !== null
      ) &&
      (this.callCenterDetailForm.controls['callCenterBD'].value !== '' &&
        this.callCenterDetailForm.controls['callCenterBD'].value !== undefined &&
        this.callCenterDetailForm.controls['callCenterBD'].value !== null
      ) &&
      (this.callCenterDetailForm.controls['callCenterEmail'].value !== '' &&
        this.callCenterDetailForm.controls['callCenterEmail'].value !== undefined &&
        this.callCenterDetailForm.controls['callCenterEmail'].value !== null
      ) &&
      (this.callCenterDetailForm.controls['callCenterTel'].value !== '' &&
        this.callCenterDetailForm.controls['callCenterTel'].value !== undefined &&
        this.callCenterDetailForm.controls['callCenterTel'].value !== null
      ) &&
      (this.callCenterDetailForm.controls['callCenterAddress'].value !== '' &&
        this.callCenterDetailForm.controls['callCenterAddress'].value !== undefined &&
        this.callCenterDetailForm.controls['callCenterAddress'].value !== null
      ) &&
      (this.callCenterDetailForm.controls['callCenterProvince'].value !== '' &&
        this.callCenterDetailForm.controls['callCenterProvince'].value !== undefined &&
        this.callCenterDetailForm.controls['callCenterProvince'].value !== null
      ) &&
      (this.callCenterDetailForm.controls['callCenterPostalCode'].value !== '' &&
        this.callCenterDetailForm.controls['callCenterPostalCode'].value !== undefined &&
        this.callCenterDetailForm.controls['callCenterPostalCode'].value !== null
      ) &&
      (this.callCenterDetailForm.controls['callCenterCountry'].value !== '' &&
        this.callCenterDetailForm.controls['callCenterCountry'].value !== undefined &&
        this.callCenterDetailForm.controls['callCenterCountry'].value !== null
      )
    ) {
      return true;
    } else {
      return false;
    }
  }


  saveCallCenterDetail() {
    this.callCenterModel = this.callCenterDetailForm.value;
    this.callCenterModel.callCenterBD =  moment(this.callCenterDetailForm.controls['callCenterBD'].value).format('DD/MM/YYYY');
    if (this.checkRequired()) {
      this.empcalservice.saveCallCenterDetail(this.callCenterModel).subscribe((response) => {
        let result = JSON.parse(JSON.stringify(response))
        if (result.status == "1") {
          this.messageService.add({ key: 'save', severity: 'success', detail: 'EMPCAL200.M00063' });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          this.messageService.add({ key: 'notsave', sticky: true, severity: 'error', detail: 'EMPCAL200.M00064' });
        }
      }, err => {
        console.log(err);
      });
    } else {
      this.messageService.add({ key: 'notsave', sticky: true, severity: 'error', summary:'EMPCAL200.M00064', detail: 'EMPCAL200.M00066' });
    }
  }

  backBtn() {
    if( this.callCenterPage.callCenterEdit != this.callCenterDetailForm.value){
      this.messageService.add({key: 'backpage', sticky: true, severity:'warn', summary:'EMPCAL200.M00067', detail: 'EMPCAL200.M00068'});
    } else {
      this.onConfirm();
    }
  }

  onConfirm(){
    this.router.navigate(['/empcal/empcal100']);
  }

  onReject(){
    this.messageService.clear();
  }

  onChangeCountry(){
    if(this.callCenterDetailForm.controls['callCenterProvince'].value || this.callCenterDetailForm.controls['callCenterPostalCode'].value){
      this.messageService.add({ key: 'ChangeCountry', severity: 'warn', summary: 'EMPCAL200.M00069', detail: 'EMPCAL200.M00070' });
    }else{
      this.countryCall = this.callCenterDetailForm.controls['callCenterCountry'].value;
      this.parameterWmsSerive.getAddressWms(102,this.callCenterDetailForm.controls['callCenterCountry'].value).subscribe((response) => {
        this.callCenterPage.province = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });
    }
  }

  changeCountry(){
    this.countryCall = this.callCenterDetailForm.controls['callCenterCountry'].value;
    this.callCenterPage.province = null;
    this.callCenterPage.district = null;
    this.callCenterPage.subDistrict = null;
    this.callCenterDetailForm.patchValue({
        callCenterSubDistrict: null,
        callCenterDistrict: null,
        callCenterProvince: null,
        callCenterPostalCode: null
    });

    this.parameterWmsSerive.getAddressWms(102,this.callCenterDetailForm.controls['callCenterCountry'].value).subscribe((response) => {
      this.callCenterPage.province = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.messageService.clear('ChangeCountry');
  }

  onRejectCountry(){
    this.callCenterDetailForm.patchValue({
      callCenterCountry: this.countryCall
    });
    this.messageService.clear('ChangeCountry');
  }


  onChangeProvince(){
    if(this.callCenterDetailForm.controls['callCenterDistrict'].value){
      this.messageService.add({ key: 'changeProvince', severity: 'warn', summary: 'EMPCAL200.M00071', detail: 'EMPCAL200.M00072' });
    }else{
    this.provinceCall = this.callCenterDetailForm.controls['callCenterProvince'].value;
    this.parameterWmsSerive.getAddressWms(103, this.callCenterDetailForm.controls['callCenterProvince'].value).subscribe((response) => {
      this.callCenterPage.district = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    }

  }

  changeProvince(){
    this.provinceCall=  this.callCenterDetailForm.controls['callCenterProvince'].value;
    this.callCenterPage.district = null;
    this.callCenterPage.subDistrict = null;
    this.callCenterDetailForm.patchValue({
        callCenterSubDistrict: null,
        callCenterDistrict: null,
        callCenterPostalCode: null
    });
    this.parameterWmsSerive.getAddressWms(103, this.callCenterDetailForm.controls['callCenterProvince'].value).subscribe((response) => {
      this.callCenterPage.district = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.messageService.clear('changeProvince');
  }

  onRejectProvince(){
    this.callCenterDetailForm.patchValue({
      callCenterProvince: this.provinceCall
    });
    this.messageService.clear('changeProvince');
  }

  onChangeDistrict(){
    if(this.callCenterDetailForm.controls['callCenterSubDistrict'].value != null){
      this.messageService.add({ key: 'changeDistrict', severity: 'warn', summary: 'EMPCAL200.M00073', detail: 'EMPCAL200.M00074' });
    }else{
      this.districtCall = this.callCenterDetailForm.controls['callCenterDistrict'].value;
      this.parameterWmsSerive.getAddressWms(104, this.callCenterDetailForm.controls['callCenterDistrict'].value).subscribe((response) => {
        this.callCenterPage.subDistrict = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });
    }
  }

  changeDistrict(){
    this.districtCall = this.callCenterDetailForm.controls['callCenterDistrict'].value;
    this.callCenterPage.subDistrict = null;
    this.callCenterDetailForm.patchValue({
      callCenterSubDistrict: null
    });
    this.parameterWmsSerive.getAddressWms(104, this.callCenterDetailForm.controls['callCenterDistrict'].value).subscribe((response) => {
    this.callCenterPage.subDistrict = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });
      this.messageService.clear('changeDistrict');
  }

  onRejectDistrict(){
    this.callCenterDetailForm.patchValue({
      callCenterDistrict: this.districtCall
    });
    this.messageService.clear('changeDistrict');
  }

}
