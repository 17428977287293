export const hyperLink = '120';
export const footerSellerHelpCenter = '100';
export const footerCustomerHelpCenter = '101';
export const footerJoinUs = '102';
export const footerCrossBorder = '103';
export const footerStockAndOrder = '104';
export const footerSaleTeamAndCRM = '105';
export const footerMarketingEditingAndTranslate = '106';
export const footerOtherService = '107';
export const Sale_Channel_Cambodia_Klangthai_web = '400';
export const Sale_Channel_Cambodia_Klangthai_Android = '401';
export const Sale_Channel_Cambodia_Klangthai_IOS = '402';
export const Sale_Channel_Cambodia_Klangthai_Facebook = '403';
export const Sale_Channel_Cambodia_Klangthai_IG = '404';
export const Sale_Channel_Laos_Klangthai_Facebook = '405';
export const footerThailangFb = '200';
export const footerThailangLine = '201';
export const footerServicePolicy = '300';
export const footerSalesPolicy = '301';