import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/shared/services/application.service';
import { AboutModel } from '../../../shared/model/application.model';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  public aboutData: AboutModel = {} as AboutModel;
  aboutDataList:any[];

  constructor(private appService: ApplicationService) {
  }

  ngOnInit() {
    this.appService.getEnvironmentAndVersion().subscribe((response: AboutModel) => {
      this.aboutData = response;
      this.aboutDataList = [
        {No:1, Api:'WMS API', Online: this.aboutData.apiWMS, Color: this.aboutData.apiColorWMS},
        {No:2, Api:'Master API', Online: this.aboutData.apiMaster, Color: this.aboutData.apiColorMaster}
      ]
    });
  }

}
