import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmpRoutingModule } from './emp-routing.module';

import { Empcal100Component } from './empcal/empcal100/empcal100.component';
import { Empcal200Component } from './empcal/empcal200/empcal200.component';
import { SharedModule } from '../shared/shared.module';
import { Empagt100Component } from './empagt/empagt100/empagt100.component';
import { Empagt200Component } from './empagt/empagt200/empagt200.component';


@NgModule({
  declarations: [ Empcal100Component, Empcal200Component, Empagt100Component, Empagt200Component],
  imports: [
    CommonModule,
    EmpRoutingModule,
    SharedModule
  ]
})
export class EmpModule { }
