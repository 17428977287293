import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class AdministrationUserService {

    private PUBLIC_ADMUSR_URL: string = `${this.environmentService.getApiUrl()}/api/usr` as string;
    private ADMUSR_URL: string = `${this.environmentService.getApiUrl()}/adm/usr` as string;

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
        private authenticationService: AuthenticationService) {
    }

    public saveDatas(register) {
      const URL = `${this.PUBLIC_ADMUSR_URL}/register`;
      return this.http.post(URL, register);
    }

    public changePwd(oldPwd: string, newPwd: string, confNewPwd: string) {
        const URL = `${this.ADMUSR_URL}/changepassword`;
        return this.http.post(URL,
        {
          oldPassword: oldPwd,
          newPassword: newPwd,
          confirmNewPassword: confNewPwd,
        }, {headers: this.authenticationService.tokenHeader()});
      }

      public forgetUserCd(userCd: string) {
        const URL = `${this.PUBLIC_ADMUSR_URL}/forgetpassword`;
        return this.http.post(URL, userCd);
      }

}
