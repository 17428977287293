import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { callCenterModel } from 'src/app/emp/empcal/empcal200/model/empcal200.model';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class EmpcalCalService {
  
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService
  ) { }

  private EMPCAL_URL: string = `${this.environmentService.getApiUrl()}/emp/empcal` as string;

      // inquiry
      getCallCenterInquiry(Request) {    
        const URL: string = `${this.EMPCAL_URL}/getCallCenterInquiry` as string;
        return this.http.post(URL ,Request,{ headers: this.authenticationService.tokenHeader() });
      }

      // detail
      getCallCenterDetail(callCenterId): Observable<callCenterModel> {    
        const URL: string = `${this.EMPCAL_URL}/getCallCenterByCallCode/${callCenterId}` as string;
        return this.http.get<callCenterModel>(URL, {headers: this.authenticationService.tokenHeader()});
      }

      saveCallCenterDetail(callCenter) {    
        const URL: string = `${this.EMPCAL_URL}/saveCallCenter` as string;
        return this.http.post(URL, callCenter, {headers: this.authenticationService.tokenHeader()});
      }

}
