import { Component, OnInit, Renderer2 } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { Cusexp100, param } from './Model/cusext100.model';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';

@Component({
  selector: 'app-cusext100',
  templateUrl: './cusext100.component.html',
  styleUrls: ['./cusext100.component.scss']
})
export class Cusext100Component extends PermissionComponent {
  public searchSeller = new FormGroup({
    seller: new FormControl(),
    country: new FormControl(),
  });
  // sellerSearch = {} as param;
  countrySearch = {} as param;
  disExport:boolean = true;

  // sellersDDL: SelectItem[];
  countryDDL: SelectItem[];
  // seller: string[];
  // sellerCode: string[];
  // sellerDisFalg:boolean = false;
  countryDisFalg: boolean = false;
  public customerData: Array<Cusexp100> = [] as Array<Cusexp100>;
  constructor(
    private exportExcelService: ExportExcelService,
    private autService: AuthenticationService,
    private CustomerService: CustomerService,
    private messageService: MessageService,
    private translateService: TranslateService,
    renderer2:Renderer2) {
      super('P60001',renderer2);
  }
  ngOnInit() {
    // this.autService.getParameterWmsSeller(null,null).subscribe(res=>{
    //   this.sellersDDL = JSON.parse(JSON.stringify(res));
    //   if(this.sellersDDL.length == 0){
    //     this.sellerDisFalg = true;
    //   }
    // });

    this.autService.getParameterWmsCountry().subscribe(response => {
      this.countryDDL = JSON.parse(JSON.stringify(response));
      if (this.countryDDL.length == 1) {
        this.countryDisFalg = true;
      }
    });
  }

  // CheckSeller(){
  //   if(this.searchSeller.controls['seller'].value == null){
  //     this.disExport = true;
  //   }else{
  //     this.disExport = false;
  //   }
  //   this.sellerSearch.sellerCode = this.searchSeller.controls['seller'].value;
  // }

  CheckCountry(){
    if(this.searchSeller.controls['country'].value == null){
      this.disExport = true;
    }else{
      this.disExport = false;
    }
    this.countrySearch.country = this.searchSeller.controls['country'].value;
  }

  exportReport() {
    this.CustomerService.getCustomerData(this.countrySearch).subscribe(res=>{
      this.customerData = JSON.parse(JSON.stringify(res));

      if(this.customerData.length == 0){
        this.messageService.add({ key: 'warn', sticky: true, severity: 'warn', summary: 'CUSEXT100.M00019', detail: 'CUSEXT100.M00018' });
      }else{
          let exportOrder = [];
          this.customerData.forEach(customer=>{
            exportOrder.push({
              firstname: customer.firstname,
              lastname: customer.lastname,
              klangOneMemberCode: customer.klangOneMemberCode,
              klangThaitMemberCode: customer.klangThaitMemberCode,
              // sellerCode: customer.sellerCode,
              // sellerName: customer.sellerName,
              address: customer.address,
              subdistrict: customer.subdistrict,
              district: customer.district,
              province: customer.province,
              postCode: customer.postCode,
              phoneNumber: customer.phoneNumber,
              email: customer.email,
              taxId: customer.taxId
            });
          });
          let subTitle = [];
          subTitle.push([this.translateService.instant('CUSEXT100.M00026')]);
          let header: any = {
            firstname: this.translateService.instant('CUSEXT100.M00000'),
            lastname: this.translateService.instant('CUSEXT100.M00001'),
            klangOneMemberCode: this.translateService.instant('CUSEXT100.M00002'),
            klangThaitMemberCode: this.translateService.instant('CUSEXT100.M00003'),
            // sellerCode: this.translateService.instant('CUSEXT100.M00004'),
            // sellerName: this.translateService.instant('CUSEXT100.M00022'),
            address: this.translateService.instant('CUSEXT100.M00008'),
            subdistrict: this.translateService.instant('CUSEXT100.M00010'),
            district: this.translateService.instant('CUSEXT100.M00011'),
            province: this.translateService.instant('CUSEXT100.M00012'),
            postCode: this.translateService.instant('CUSEXT100.M00013'),
            phoneNumber: this.translateService.instant('CUSEXT100.M00005'),
            email: this.translateService.instant('CUSEXT100.M00014'),
            taxId: this.translateService.instant('CUSEXT100.M00007')
          }
          this.exportExcelService.exportAsExcelFile(exportOrder,this.translateService.instant('CUSEXT100.M00016'), header, null, subTitle);
        }
    });

    }

    onReject(){
      this.messageService.clear();
    }


}
