import { ChangeDetectorRef, Component, Renderer2, ViewChild } from '@angular/core';
import { TempDDL, ERROR100Search, ResentPamApi } from '../../model/ord100.model';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { SalesOrderrService } from 'src/app/shared/services/sales-order-service';
import { PermissionDimensionComponent } from 'src/app/shared/components/permission-dimension/permission-dimension.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import {Table} from 'primeng/table';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';
import { apierrhis100 } from './model/apierrhis-model';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-apierrhis100',
  templateUrl: './apierrhis100.component.html',
  styleUrls: ['./apierrhis100.component.scss']
})
export class Apierrhis100Component extends PermissionDimensionComponent {

  @ViewChild('table',{static:true}) table: Table
  first:number=0;
  last:number=0;
  public optionCountry: TempDDL[];
  warehouseData: TempDDL[];
  row: any=[];
  public Search: string;
  public disButtonSearch: boolean = true;
  // public checkAllTable: boolean = false as boolean;
  public dataTable: Array<apierrhis100> = [] as Array<apierrhis100>
  public IsSearchAdvanced: boolean = false as boolean;
  warehouseDataDisFlag: boolean = false as boolean;
  saleChannelDisFlag: boolean = false as boolean;

  public searchAdvancedGroupForm = new FormGroup({
    warehouse_code: new FormControl(),
    error_date_from_to: new FormControl(),
    sales_date_from_to: new FormControl(),
    sales_order_no: new FormControl(),
    country_code:new FormControl(),


  });
  criteriaSearchGrid = { country: ''};
  defaultData:any;
  searchShort:any;
  language:string;
  languageTH:string;
  disable :boolean = true;
  // statusTransport:any;
  dataNotFound:boolean = true;
  country : string;
  dataTableSearchShort:any;
  disCountry:boolean;
  errApiSeach = {} as ERROR100Search;
  Country: string;
  firstRow: number = 0 as number;
  user: AuthDataModel;
  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();
  public resentPamApiModel: Array<ResentPamApi> = [] as Array<ResentPamApi>;

  constructor(private parameterService:ParameterService,
              private saleorderService : SalesOrderrService,
              private autService: AuthenticationService,
              private cdRef : ChangeDetectorRef,
              private messageService: MessageService,
              private router: Router,
              /* For Permission*/
              renderer2:Renderer2,
              route: ActivatedRoute) {
              super('P31102',renderer2,route);
              this.Country = localStorage.getItem('languageData') as string;
              this.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel;
              this.language = localStorage.getItem('languageData') as string;
              this.languageTH='TH';
              }

  ngOnInit() {
    this.checkValueCountry();
  }

  ngAfterViewChecked(){
    if(this.table._totalRecords != 0 ){
      this.first = this.table._first+1;
      this.last = this .table._first+this.table._rows;
      if(this.last>this.dataTable.length ){
        this.last=this.dataTable.length
      }
    }
    this.cdRef.detectChanges();
  }

  getCountryCurrency(page){
    this.disButtonSearch = false;
    this.country = page.value;
    this.searchAdvancedGroupForm.controls['country_code'].setValue(this.country);

    this.autService.getParameterWmsWareHouse(page.value).subscribe(response =>{
      this.warehouseData = JSON.parse(JSON.stringify(response));
      if(this.warehouseData.length == 0){
        this.warehouseDataDisFlag = true;
      }else{
        this.warehouseDataDisFlag = false;
      }
    },err=>{
      console.log(err);

    });
  }

  getErrorSaleOrderByCounty(){
    this.errApiSeach = {} as ERROR100Search;
    this.errApiSeach.country_code = this.country;
    if(this.searchAdvancedGroupForm.controls['warehouse_code'].value){
      this.errApiSeach.warehouse_code = this.searchAdvancedGroupForm.controls['warehouse_code'].value;
    } else {
      let warehouse_code = [];
      this.warehouseData.forEach( row => {
        warehouse_code.push(row.value);
      });
      this.errApiSeach.warehouse_code = warehouse_code;
    }
    this.errApiSeach.sales_order_no = this.searchAdvancedGroupForm.controls['sales_order_no'].value;
    if(this.searchAdvancedGroupForm.controls['error_date_from_to'].value != null){
      this.errApiSeach.error_date_from = moment(this.searchAdvancedGroupForm.controls['error_date_from_to'].value[0]).format('DD/MM/YYYY');
      this.errApiSeach.error_date_to = moment(this.searchAdvancedGroupForm.controls['error_date_from_to'].value[1]).format('DD/MM/YYYY');
    }

    if(this.searchAdvancedGroupForm.controls['sales_date_from_to'].value != null){
      this.errApiSeach.sales_date_from = moment(this.searchAdvancedGroupForm.controls['sales_date_from_to'].value[0]).format('DD/MM/YYYY');
      this.errApiSeach.sales_date_to = moment(this.searchAdvancedGroupForm.controls['sales_date_from_to'].value[1]).format('DD/MM/YYYY');
    }
    this.saleorderService.getErrorSaleOrderByCounty(this.errApiSeach).subscribe(res=>{
      this.dataTable = JSON.parse(JSON.stringify(res));
      this.defaultData = JSON.parse(JSON.stringify(res));
      if(this.dataTable.length == 0){
        this.dataNotFound = true;
      }else{
        this.dataNotFound = false;
      }
      this.firstRow = 0;
    }, err => {
      console.log(err);
    });
    this.disButtonSearch = true;


  }

  filterData() {
    if(this.country != null){
      this.disButtonSearch = false;
    }else{
      this.disButtonSearch = true;
    }
  }


  ClearData() {
    if(this.dataTable){
      this.dataTable.forEach((element: any) => element.checked = false );
    }
    this.searchAdvancedGroupForm.reset();
    this.criteriaSearchGrid.country = null;
    this.disButtonSearch = true;
    this.dataTable = [];
    this.dataNotFound = true;
    this.warehouseData = null;
    this.country = null;

    this.errApiSeach.country_code = null;
    this.errApiSeach.warehouse_code = [];
    this.errApiSeach.sales_order_no = null;
    this.checkValueCountry();

  }

  refershTable(dTable,dTabletho){
    if(this.dataTable){
      this.dataTable.forEach((element: any) => element.checked = false );
    }
    // dTable.reset();
    // dTabletho.reset();
  }

  checkValueCountry(){
    this.autService.getParameterWmsCountry().subscribe(response => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.optionCountry.length == 1) {
        this.disCountry = true;
        this.disButtonSearch = false;
        this.criteriaSearchGrid.country = this.optionCountry[0].value;
        this.country = this.optionCountry[0].value;

        this.autService.getParameterWmsWareHouse(this.optionCountry[0].value).subscribe(response =>{
          this.warehouseData = JSON.parse(JSON.stringify(response));
          if(this.warehouseData.length == 0){
            this.warehouseDataDisFlag = true;
          }
        },err=>{
          console.log(err);

        });
      }else if(this.optionCountry.length == 0){
        this.disCountry = true;
      }
    }, err => {
      console.log(err);
    });
  }

  smartSearchShort(event) {
    this.dataTableSearchShort = this.defaultData;
    if(this.searchShort){
      this.dataTable = this.dataTableSearchShort.filter(data=>{
        if (this.language == 'TH'){
          if(
            data.err_date && data.err_date.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.sales_order_no && data.sales_order_no.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.create_date && data.create_date.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.warehouse_desc_local && data.warehouse_desc_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.sales_status_desc_local && data.sales_status_desc_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.payment_conf_date && data.payment_conf_date.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.payment_status_desc_local && data.payment_status_desc_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.status && data.status.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.error && data.error.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.user && data.user.toLowerCase().includes(this.searchShort.toLowerCase())
          ){
            return true;
          }else{
            return false;
          }
        } else {
          if(
            data.err_date && data.err_date.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.sales_order_no && data.sales_order_no.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.create_date && data.create_date.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.warehouse_desc_eng && data.warehouse_desc_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.sales_status_desc_eng && data.sales_status_desc_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.payment_conf_date && data.payment_conf_date.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.payment_status_desc_eng && data.payment_status_desc_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.status && data.status.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.error && data.error.toLowerCase().includes(this.searchShort.toLowerCase()) ||
            data.user && data.user.toLowerCase().includes(this.searchShort.toLowerCase())
          ){
            return true;
          }else{
            return false;
          }
        }

        return false;
      });
    } else {
      this.dataTable = this.dataTableSearchShort;
    }

    if(this.dataTable.length == 0){
      this.dataNotFound = true;
    }else{
      this.dataNotFound = false;
      this.firstRow = 0;
    }
  }


  checked(isChecker: any, row) {
    if (isChecker) {
      this.row.push(row);
    } else {
      const index = this.row.findIndex(data => data === row);
      this.row.splice(index,1);
    }
  }

  checkedAll(isChecker: any, row) {
    if (isChecker) {
      for(let i = 0 ; i < this.dataTable.length ; i ++){
        this.row.push(i);
      }
    } else {
      this.row=[];
    }
  }

  resendApiDate(){
    let errHisIsSelect:any=[];
    let resentLanguage: string;

      this.row.forEach(element => {
        errHisIsSelect.push(this.dataTable[element]);
      });

      console.info('errHisIsSelect', errHisIsSelect);
      if(this.language == 'TH'){
        resentLanguage = 'th';
      }else{
        resentLanguage = 'en';
      }

    if(errHisIsSelect){
      errHisIsSelect.forEach(errHis=>{
        let resentPamApi: any = {
          warehouseCode: errHis.warehouse_code,
          salesOrderNo: errHis.sales_order_no,
          language: resentLanguage
        }
        this.resentPamApiModel.push(resentPamApi);
      });

      this.saleorderService.resentPamApiErrorHistory(this.resentPamApiModel).subscribe(res=>{
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/apierrhis/apierrhis100']).then(() => {
          window.location.reload();
        }));
      },err=>{
        console.log(err);
      });
    }

  }
}
