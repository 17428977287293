import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SalesOrderrService } from '../../services/sales-order-service';
import { profileReceiver,ReceiverModel } from './model/search-receiver-model';

@Component({
  selector: 'app-search-receiver',
  templateUrl: './search-receiver.component.html',
  styleUrls: ['./search-receiver.component.scss']
})
export class SearchReceiverComponent implements OnInit {
  @Input()displayReceiver: boolean;
  @Input() customerId: string;
  @Output() dataReceiver: EventEmitter<ReceiverModel> = new EventEmitter<ReceiverModel>();
  hiddenButton: Boolean = true;
  hightLight:any;
  name:String;
  phone:String;
  address:String;
  index:any
  dataNotFoundGridTab1: Boolean = true;
  receiverDetail:ReceiverModel = {} as ReceiverModel;
  profileModel: Array<ReceiverModel> = [] as Array<ReceiverModel>;
  receiver: Array<ReceiverModel> = [] as Array<ReceiverModel>;
  firstRow: number = 0 as number;
  constructor(private saleorderService : SalesOrderrService) { }

  ngOnInit() {
    this.getReceiverInqAll();
  }

  saveInfoItem(): void{
    this.saleorderService.getReceiverDetail(this.profileModel[this.index].receiver_Id).subscribe(res=>{
      this.receiverDetail = JSON.parse(JSON.stringify(res));
      this.dataReceiver.emit({
        receiver_address:this.receiverDetail.receiver_address,
        receiver_Id:this.receiverDetail.receiver_Id,
        receiver_district:this.receiverDetail.receiver_district,
        receiver_lastname:this.receiverDetail.receiver_lastname,
        receiver_name:this.receiverDetail.receiver_name,
        receiver_phone:this.receiverDetail.receiver_phone,
        receiver_postal_code:this.receiverDetail.receiver_postal_code,
        receiver_province:this.receiverDetail.receiver_province,
        receiver_subdistrict:this.receiverDetail.receiver_subdistrict
      });
    },err=>{
      console.log(err);
      
    })

    this.displayReceiver = false;
  }
  closeDialog(){
    this.dataReceiver.emit();
    this.displayReceiver = false;
  }

  selectRow(index){
    // this.hightLight = true;
    this.hightLight = index;
    this.hiddenButton = false;
    this.index = index;
    
  }

  getReceiverInqAll(){
    this.saleorderService.getReceiverInquiry(this.customerId).subscribe(res=>{
      this.receiver = JSON.parse(JSON.stringify(res));
      // this.profileModel = this.receiver;
      // if (this.profileModel.length == 0) {
      //   this.dataNotFoundGridTab1 = true;
      // } else {
      //   this.dataNotFoundGridTab1 = false;
      // }
    },err=>{
      console.log(err);
      
    })
  }

  // getReceiverInq(){
  //   this.saleorderService.getReceiverInquiry(this.customerId).subscribe(res=>{
  //     this.receiver = JSON.parse(JSON.stringify(res));
  //     this.profileModel = this.receiver;
  //     if (this.profileModel.length == 0) {
  //       this.dataNotFoundGridTab1 = true;
  //     } else {
  //       this.dataNotFoundGridTab1 = false;
  //     }
  //   },err=>{
  //     console.log(err);
      
  //   })
  // }

  getReceiverInq() {
    this.profileModel = this.receiver;
    if(this.name){
      this.profileModel = this.profileModel.filter(data => data.receiver_name.toLowerCase().indexOf(this.name.toLowerCase()) > -1);
      if(this.profileModel.length == 0){
        this.profileModel = this.receiver;
        this.profileModel = this.profileModel.filter(data => data.receiver_lastname.toLowerCase().indexOf(this.name.toLowerCase()) > -1);
      }
    }
    
    if(this.phone){
      this.profileModel = this.profileModel.filter(data =>data.receiver_phone.toLowerCase().indexOf(this.phone.toLowerCase()) > -1);
    }

    if(this.address){
      this.profileModel = this.profileModel.filter(data =>data.receiver_address.toLowerCase().indexOf(this.address.toLowerCase()) > -1);
    }

    if (this.profileModel.length == 0) {
      this.dataNotFoundGridTab1 = true;
    } else {
      this.dataNotFoundGridTab1 = false;
      this.firstRow = 0;
    }
    
  }

}
