import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http/';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { InfoList,
  ProductDetail } from '../model/product.model';
import { ItemInquiryResponse, StockListBarChartReponse } from '../model/dashboard-stock.model';
import { ItemByWhModel, ItemExpiryModel, ItemInquiryByWhRequest, ItemInquiryExpiryRequest, ItemInquiryRequest, ItemModel } from '../model/item.model';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  ITM_URL: string = `${this.evmService.getApiUrl()}/itm` as string;

  constructor(private http: HttpClient,
              private evmService: EnvironmentService,
              private autService: AuthenticationService) { }

  getAllProductBySeller(seller:string): Observable<InfoList> {
    const URL = `${this.ITM_URL}/getAllProductBySeller/seller=${seller}`;
    return this.http.get<InfoList>(URL , {headers: this.autService.tokenHeader()});
  }

  getProductDetailByBarcode(itemSellerCode: string) {
    const URL = `${this.ITM_URL}/getDetailByBarcode/${itemSellerCode}`;
    return this.http.get(URL , {headers: this.autService.tokenHeader()});
  }

  getDataProductDetailByBarcode(itemSellerCode: string) {
    const URL = `${this.ITM_URL}/getDataByItemCode/${itemSellerCode}`;
    return this.http.get(URL , {headers: this.autService.tokenHeader()});
  }

  getBarChartMontForItemPage(period: string,seller :string): Observable<StockListBarChartReponse> {
    const URL = `${this.ITM_URL}/getBarChartMontForItemPage/${period},seller=${seller}`;
    return this.http.get<StockListBarChartReponse>(URL, {headers: this.autService.tokenHeader()});
}

/*getItemList(searchRequest): Observable<ItemInquiryResponse> {
  const URL = `${this.ITM_URL}/getItemInquiry/${searchRequest}`;
  return this.http.post<ItemInquiryResponse>(URL, {headers: this.autService.tokenHeader()});
}*/

getItemInquiry(param : ItemInquiryRequest ) : Observable <Array<ItemModel>>{
  const URL = `${this.ITM_URL}/getItemInquiry` ;
 return this.http.post<Array<ItemModel>>(`${URL}`,param,{headers: this.autService.tokenHeader()})
}

getItemInquiryByWh(param : ItemInquiryByWhRequest ) : Observable <Array<ItemByWhModel>>{
    const URL = `${this.ITM_URL}/getItemInquiryByWh` ;
   return this.http.post<Array<ItemByWhModel>>(`${URL}`,param,{headers: this.autService.tokenHeader()})
  }

  getItemReport(itemrCode) {    
    const URL: string = `${this.ITM_URL}/getItemReport` as string;
    return this.http.post(URL ,itemrCode,{ headers: this.autService.tokenHeader() });
  }

  getItemInquiryExpiry(param : ItemInquiryExpiryRequest ) : Observable <Array<ItemExpiryModel>>{
    const URL = `${this.ITM_URL}/getItemInquiryExpiry` ;
   return this.http.post<Array<ItemExpiryModel>>(`${URL}`,param,{headers: this.autService.tokenHeader()})
  }


}
