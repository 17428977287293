import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, interval } from 'rxjs';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { SynchronizeService } from 'src/app/shared/services/synchronize.service';
import { SynchronizeDataDetailModel,
  SynchronizeDataModel,
  SynchronizeDataModelDetailModel,
  SynchronizeStatusModel } from '../../model/synchronize-data-model';
import { date_format, th } from 'src/app/shared/constant/date.constant';

@Component({
  selector: 'app-synsyn100',
  templateUrl: './synsyn100.component.html',
  styleUrls: ['./synsyn100.component.scss']
})
export class Synsyn100Component implements OnInit {

  status: boolean;
  constructor(
    private router: Router,
    private synService: SynchronizeService,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService
  ) {
    this.status = false;
    this.translateService.use(localStorage.getItem('languageData') as string);
  }

  modeRadio: string = null;
  checkedAllFlag: boolean = false as boolean;
  loadingFlag: boolean = false as boolean;
  first: number;

  // blocked: boolean;
  dataNotFoundGridOrd: boolean = true as boolean;
  private updateSubscription: Subscription;

  synchronizeDataModelList: Array<SynchronizeDataModel> = new Array<SynchronizeDataModel>();

  synchronizeDataModelDetail = {} as SynchronizeDataModelDetailModel;

  display: boolean = false as boolean;

  current_date: Date = new Date();
  locale = th;
  format_date: string = date_format;
  
  ngOnInit() {
    this.intitalSynchronizeData();
    this.updateSubscription = interval(60000).subscribe(
      (val) => {
        this.intitalSynchronizeData();
      });
  }

  intitalSynchronizeData() {
    this.loadingFlag = true;
    const synchronizeDatalist: Array<SynchronizeDataModel> = new Array<SynchronizeDataModel>();
    this.synService.getSynchronizeData().subscribe((respones: SynchronizeDataModel[]) => {
      respones.forEach((element: SynchronizeDataModel) => {
        element.od = this.current_date;
        synchronizeDatalist.push(element);
        this.loadingFlag = false;
      }, (err: { message: any; }) => {
        this.loadingFlag = false;
        console.log(err.message);
      });
    });
    this.synchronizeDataModelList = synchronizeDatalist;
  }

  checkedAll(status: boolean) {
    this.modeRadio = null;
    this.clearChecker();
    if (status) {
      this.synchronizeDataModelList.forEach((element: SynchronizeDataModel) => {
        element.checked = true;
      });
    } else {
      this.clearChecker();
    }
  }

  clearSelectData() {
    this.modeRadio = null;
    this.checkedAllFlag = false;
    this.clearChecker();
  }

  checked(sheetCode: string, isChecker: boolean, sheetDescription: string) {
    this.modeRadio = null;
    this.checkedAllFlag = false;
    if (isChecker === true) {
      const synchronizeData = this.synchronizeDataModelList.find((element: SynchronizeDataModel) => element.sheetCode === sheetCode);
      synchronizeData.checked = true;
      const index = this.synchronizeDataModelList.indexOf(synchronizeData);
      this.synchronizeDataModelList[index] = synchronizeData;
    } else {
      const synchronizeData = this.synchronizeDataModelList.find((element: SynchronizeDataModel) => element.sheetCode === sheetCode);
      synchronizeData.checked = false;
      const index = this.synchronizeDataModelList.indexOf(synchronizeData);
      this.synchronizeDataModelList[index] = synchronizeData;
    }
  }

  onUploadData(): void {
    this.loadingFlag = true;
    const synchronizeDataCheckIsTruelist: Array<SynchronizeDataModel> = this.synchronizeDataModelList.filter((element: SynchronizeDataModel) => element.checked === true);
    this.synchronizeDataProcessFunction(synchronizeDataCheckIsTruelist);
    this.clearChecker();
  }

  synchronizeDataProcessFunction(synchronizeDataCheckIsTruelist: Array<SynchronizeDataModel> ) {
    const synchronizeDatalist: Array<SynchronizeDataModel> = new Array<SynchronizeDataModel>();
    this.synService.synchronizeDataProcess(synchronizeDataCheckIsTruelist).subscribe((respones: SynchronizeDataModel[]) => {
      respones.forEach((element: SynchronizeDataModel) => {
        element.od = this.current_date;
        synchronizeDatalist.push(element);
      }, (err: { message: any; }) => {
        this.loadingFlag = false;
        console.log(err.message);
      });
      this.loadingFlag = false;
    });
    this.synchronizeDataModelList = synchronizeDatalist;
  }

  backToSysdata() {
    // this.status = 0;
    this.router.navigate(['/syn/synsyn100']);
  }

  checkByMode(mode: string) {
    this.checkedAllFlag = false;
    this.clearChecker();
    const synchronizeDatalist: Array<SynchronizeDataModel> = this.synchronizeDataModelList.filter((element: SynchronizeDataModel) => {
      return element.mode === mode;
    });
    synchronizeDatalist.forEach((element: SynchronizeDataModel) => {
      const index = this.synchronizeDataModelList.indexOf(element);
      element.checked = true;
      this.synchronizeDataModelList[index] = element;
    });
  }

  clearChecker() {
    this.synchronizeDataModelList.forEach((element: SynchronizeDataModel) => {
      element.checked = false;
    });
  }

  statusDescription(status: number): string {
    let statusDescription: string = '-' as string;
    if (status === 0) {
      statusDescription = this.translateService.instant('SYNSYN100.M00258');
    } else if (status === 1) {
      statusDescription = this.translateService.instant('SYNSYN100.M00257');
    } else if (status === 2) {
      statusDescription = this.translateService.instant('SYNSYN100.M00256');
    } else if (status === 3) {
      statusDescription = this.translateService.instant('SYNSYN100.M00259');
    } else {
      statusDescription = '-';
    }
    return statusDescription;
  }

  dateFormat(dateTime: Date): string {
    let result = moment(dateTime).format('DD/MM/YYYY hh:mm');
    if (result === 'Invalid date') {
      result = '-';
    }
    return result;
  }

  showDialog(synchronizeData: SynchronizeDataModel): void {
    this.display = true;
    this.loadingFlag = true;
    this.synService.getSynchronizeDetailData(synchronizeData.sheetCode).subscribe((respones: SynchronizeDataModelDetailModel) => {
      if (respones) {
        // console.log(`true`);
        this.dataNotFoundGridOrd = true;
        this.synchronizeDataModelDetail = respones;
      } else {
        // console.log(`false`);
        this.dataNotFoundGridOrd = false;
        this.synchronizeDataModelDetail.sheetCode = synchronizeData.sheetCode;
        this.synchronizeDataModelDetail.sheetDescription = synchronizeData.sheetDescription;
        this.synchronizeDataModelDetail.sheetUrl = synchronizeData.sheetUrl;
        this.synchronizeDataModelDetail.mode = synchronizeData.mode;
        this.synchronizeDataModelDetail.synchronizeDataList = [] as Array<SynchronizeDataDetailModel>;
      }
      this.loadingFlag = false;
    });

    // console.log('synchronizeDataModelDetail: ', this.synchronizeDataModelDetail);
  }

  navigateToExport(sheetCode: string, filesName: string) {
    console.log('navigateToExport');
    const responce = [];
    if (sheetCode === 'ITM100') {
      this.synService.getSynchronizeStatus(sheetCode, filesName).subscribe((response: SynchronizeStatusModel[]) => {
        response.forEach(element => {
          responce.push(element);
        });
        if (responce.length !== 0) {
          const subTitle = [];
          subTitle.push([`${this.translateService.instant('SYNSYN100.M00026')}` + sheetCode]);
          const header: any = {
            sellerCode: this.translateService.instant('SYNSYN100.M00028'),
            sellerName: this.translateService.instant('SYNSYN100.M00029'),
            productCodeSeller: this.translateService.instant('SYNSYN100.M00030'),
            productCode:  this.translateService.instant('SYNSYN100.M00031'),
            productName: this.translateService.instant('SYNSYN100.M00032'),
            productBrand: this.translateService.instant('SYNSYN100.M00033'),
            category: this.translateService.instant('SYNSYN100.M00034'),
            subcategory: this.translateService.instant('SYNSYN100.M00035'),
            inventoryFlag: this.translateService.instant('SYNSYN100.M00036'),
            productType: this.translateService.instant('SYNSYN100.M00037'),
            productUnit: this.translateService.instant('SYNSYN100.M00038'),
            productWeightG: this.translateService.instant('SYNSYN100.M00039'),
            productWidthCM: this.translateService.instant('SYNSYN100.M00040'),
            productLengthCM: this.translateService.instant('SYNSYN100.M00041'),
            productHeightCM: this.translateService.instant('SYNSYN100.M00042'),
            cartonBarcode: this.translateService.instant('SYNSYN100.M00043'),
            productPreCarton: this.translateService.instant('SYNSYN100.M00044'),
            cartonWeightG: this.translateService.instant('SYNSYN100.M00045'),
            cartonWidthCM: this.translateService.instant('SYNSYN100.M00046'),
            cartonLengthCM: this.translateService.instant('SYNSYN100.M00047'),
            cartonHeightCM: this.translateService.instant('SYNSYN100.M00048'),
            productDescription: this.translateService.instant('SYNSYN100.M00049'),
            productDetail: this.translateService.instant('SYNSYN100.M00050'),
            productImage: this.translateService.instant('SYNSYN100.M00051'),
            URLVDO: this.translateService.instant('SYNSYN100.M00052'),
            URLKH: this.translateService.instant('SYNSYN100.M00053'),
            URLTH: this.translateService.instant('SYNSYN100.M00054'),
            URLLA: this.translateService.instant('SYNSYN100.M00055'),
            fullPriceKH: this.translateService.instant('SYNSYN100.M00056'),
            sellPriceKH: this.translateService.instant('SYNSYN100.M00057'),
            fullPriceTH: this.translateService.instant('SYNSYN100.M00058'),
            sellPriceTH: this.translateService.instant('SYNSYN100.M00059'),
            fullPriceLA: this.translateService.instant('SYNSYN100.M00060'),
            sellPriceLA: this.translateService.instant('SYNSYN100.M00061'),
            productPreOrder: this.translateService.instant('SYNSYN100.M00062'),
            wholesaleFlag: this.translateService.instant('SYNSYN100.M00063'),
            freeShipingFlag: this.translateService.instant('SYNSYN100.M00064'),
            FFMFlag: this.translateService.instant('SYNSYN100.M00065'),
            CRMFlag: this.translateService.instant('SYNSYN100.M00066'),
            clearanceFlag: this.translateService.instant('SYNSYN100.M00067'),
            platformServicePercent: this.translateService.instant('SYNSYN100.M00068'),
            CRMServicePercent: this.translateService.instant('SYNSYN100.M00069'),
            clearanceServicePercent: this.translateService.instant('SYNSYN100.M00070'),
            stockActiveKHFlag: this.translateService.instant('SYNSYN100.M00071'),
            stockActiveTHFlag: this.translateService.instant('SYNSYN100.M00072'),
            stockActiveLAFlag: this.translateService.instant('SYNSYN100.M00073'),
            salesActiveKHFlag: this.translateService.instant('SYNSYN100.M00074'),
            salesActiveTHFlag: this.translateService.instant('SYNSYN100.M00075'),
            salesActiveLAFlag: this.translateService.instant('SYNSYN100.M00076'),
            klangthaiMallFlag: this.translateService.instant('SYNSYN100.M00077'),
            thaiTradeMallFalg: this.translateService.instant('SYNSYN100.M00078'),
            aseanLocalMallFalg: this.translateService.instant('SYNSYN100.M00079'),
            filesName: this.translateService.instant('SYNSYN100.M00080'),
            errorCode: this.translateService.instant('SYNSYN100.M00081'),
            errorMessage: this.translateService.instant('SYNSYN100.M00082')
          };
          this.exportExcelService.exportAsExcelFile(responce, this.translateService.instant('SYNSYN100.M00083'), header, null, subTitle);
          responce.shift();
        }
      });
    } else if (sheetCode === 'LOG001') {
      this.synService.getSynchronizeStatus(sheetCode, filesName).subscribe((response: SynchronizeStatusModel[]) => {
        response.forEach(element => {
          responce.push(element);
        });
        if (responce.length !== 0) {
          const subTitle = [];
          subTitle.push([`${this.translateService.instant('SYNSYN100.M00026')}` + sheetCode]);
          const header: any = {
            office: this.translateService.instant('SYNSYN100.M00084'),
            logistic: this.translateService.instant('SYNSYN100.M00085'),
            invoiceNumber: this.translateService.instant('SYNSYN100.M00086'),
            fullNonfull: this.translateService.instant('SYNSYN100.M00087'),
            documentType: this.translateService.instant('SYNSYN100.M00037'),
            documentDate: this.translateService.instant('SYNSYN100.M00088'),
            repackNumber: this.translateService.instant('SYNSYN100.M00089'),
            deliveryStatus: this.translateService.instant('SYNSYN100.M00090'),
            receiveOfficeDate: this.translateService.instant('SYNSYN100.M00091'),
            combineboxDate: this.translateService.instant('SYNSYN100.M00092'),
            receiveLogisticDate: this.translateService.instant('SYNSYN100.M00093'),
            receiveWarehouseDate: this.translateService.instant('SYNSYN100.M00094'),
            openboxDate: this.translateService.instant('SYNSYN100.M00095'),
            sizeBox: this.translateService.instant('SYNSYN100.M00096'),
            boxqtyPerSize: this.translateService.instant('SYNSYN100.M00097'),
            boxqtyPerDocument: this.translateService.instant('SYNSYN100.M00098'),
            filesName: this.translateService.instant('SYNSYN100.M00080'),
            errorCode: this.translateService.instant('SYNSYN100.M00081'),
            errorMessage: this.translateService.instant('SYNSYN100.M00082')
          };
          this.exportExcelService.exportAsExcelFile(responce, this.translateService.instant('SYNSYN100.M00083'), header, null, subTitle);
          responce.shift();
        }
      });
    } else if (sheetCode === 'LOG002') {
      this.synService.getSynchronizeStatus(sheetCode, filesName).subscribe((response: SynchronizeStatusModel[]) => {
        response.forEach(element => {
          responce.push(element);
        });
        if (responce.length !== 0) {
          const subTitle = [];
          subTitle.push([`${this.translateService.instant('SYNSYN100.M00026')}` + sheetCode]);
          const header: any = {
            list: this.translateService.instant('SYNSYN100.M00099'),
            customerName: this.translateService.instant('SYNSYN100.M00100'),
            customerCode: this.translateService.instant('SYNSYN100.M00101'),
            refer: this.translateService.instant('SYNSYN100.M00102'),
            transactionDate: this.translateService.instant('SYNSYN100.M00103'),
            trackingNo: this.translateService.instant('SYNSYN100.M00104'),
            value: this.translateService.instant('SYNSYN100.M00105'),
            paymentStatus: this.translateService.instant('SYNSYN100.M00106'),
            logisticCountry: this.translateService.instant('SYNSYN100.M00107'),
            route: this.translateService.instant('SYNSYN100.M00108'),
            filesName: this.translateService.instant('SYNSYN100.M00080'),
            errorCode: this.translateService.instant('SYNSYN100.M00081'),
            errorMessage: this.translateService.instant('SYNSYN100.M00082'),
          };
          this.exportExcelService.exportAsExcelFile(responce, this.translateService.instant('SYNSYN100.M00083'), header, null, subTitle);
          responce.shift();
        }
      });
    } else if (sheetCode === 'SAL100' || sheetCode === 'SAL200') {
      this.synService.getSynchronizeStatus(sheetCode, filesName).subscribe((response: SynchronizeStatusModel[]) => {
        response.forEach(element => {
          responce.push(element);
        });
        if (responce.length !== 0) {
          const subTitle = [];
          subTitle.push([`${this.translateService.instant('SYNSYN100.M00026')}` + sheetCode]);
          const header: any = {
            invoiceNumber: this.translateService.instant('SYNSYN100.M00109'),
            invoiceDate: this.translateService.instant('SYNSYN100.M00110'),
            productCodeSeller: this.translateService.instant('SYNSYN100.M00030'),
            productCode:  this.translateService.instant('SYNSYN100.M00031'),
            productName:  this.translateService.instant('SYNSYN100.M00032'),
            sellerCode: this.translateService.instant('SYNSYN100.M00028'),
            productQuantity: this.translateService.instant('SYNSYN100.M00111'),
            productUnit: this.translateService.instant('SYNSYN100.M00038'),
            productTotalAmount: this.translateService.instant('SYNSYN100.M00112'),
            salesChannel: this.translateService.instant('SYNSYN100.M00113'),
            country: this.translateService.instant('SYNSYN100.M00114'),
            filesName: this.translateService.instant('SYNSYN100.M00080'),
            errorCode: this.translateService.instant('SYNSYN100.M00081'),
            errorMessage: this.translateService.instant('SYNSYN100.M00082')
          };
          this.exportExcelService.exportAsExcelFile(responce,  this.translateService.instant('SYNSYN100.M00083'), header, null, subTitle);
          responce.shift();
        }
      });
    } else if (sheetCode === 'SAL300') {
      this.synService.getSynchronizeStatus(sheetCode, filesName).subscribe((response: SynchronizeStatusModel[]) => {
        response.forEach(element => {
          responce.push(element);
        });
        if (responce.length !== 0) {
          const subTitle = [];
          subTitle.push([`${this.translateService.instant('SYNSYN100.M00026')}`  + sheetCode]);
          const header: any = {
            country: this.translateService.instant('SYNSYN100.M00114'),
            countryCurrency: this.translateService.instant('SYNSYN100.M00115'),
            orderNumber: this.translateService.instant('SYNSYN100.M00116'),
            createBy: this.translateService.instant('SYNSYN100.M00117'),
            createByDescription: this.translateService.instant('SYNSYN100.M00118'),
            customerName: this.translateService.instant('SYNSYN100.M00100'),
            customerCode: this.translateService.instant('SYNSYN100.M00119'),
            customerEmail: this.translateService.instant('SYNSYN100.M00120'),
            customerPhoneNumber: this.translateService.instant('SYNSYN100.M00121'),
            customerAddress: this.translateService.instant('SYNSYN100.M00122'),
            customerTaxID: this.translateService.instant('SYNSYN100.M00123'),
            agentName: this.translateService.instant('SYNSYN100.M00124'),
            agentEmail: this.translateService.instant('SYNSYN100.M00125'),
            agentPhoneNumber: this.translateService.instant('SYNSYN100.M00126'),
            agentAddress: this.translateService.instant('SYNSYN100.M00127'),
            refer: this.translateService.instant('SYNSYN100.M00102'),
            salesChanel: this.translateService.instant('SYNSYN100.M00113'),
            salesDescription: this.translateService.instant('SYNSYN100.M00128'),
            saleChanelType: this.translateService.instant('SYNSYN100.M00129'),
            orderDate: this.translateService.instant('SYNSYN100.M00103'),
            discountAmount: this.translateService.instant('SYNSYN100.M00130'),
            totalAmountWithoutTax: this.translateService.instant('SYNSYN100.M00131'),
            taxAmount: this.translateService.instant('SYNSYN100.M00132'),
            shippingAmountPayCustomer: this.translateService.instant('SYNSYN100.M00133'),
            shippingDate: this.translateService.instant('SYNSYN100.M00134'),
            trackingNumberZort: this.translateService.instant('SYNSYN100.M00104'),
            shippingChanel: this.translateService.instant('SYNSYN100.M00135'),
            consigneeName: this.translateService.instant('SYNSYN100.M00136'),
            consigneePhoneNumber: this.translateService.instant('SYNSYN100.M00138'),
            consigneeEmail: this.translateService.instant('SYNSYN100.M00139'),
            consigneeAddress: this.translateService.instant('SYNSYN100.M00140'),
            consigneePostcode: this.translateService.instant('SYNSYN100.M00137'),
            consigneeProvince: this.translateService.instant('SYNSYN100.M00141'),
            consigneeDistrict: this.translateService.instant('SYNSYN100.M00142'),
            consigneeSubdistrict: this.translateService.instant('SYNSYN100.M00143'),
            totalAmountWithShipping: this.translateService.instant('SYNSYN100.M00144'),
            remark: this.translateService.instant('SYNSYN100.M00145'),
            productStatus: this.translateService.instant('SYNSYN100.M00146'),
            paymentStatus: this.translateService.instant('SYNSYN100.M00147'),
            textInvoiceNumber: this.translateService.instant('SYNSYN100.M00148'),
            paymentChanel: this.translateService.instant('SYNSYN100.M00149'),
            paymentAmount: this.translateService.instant('SYNSYN100.M00150'),
            paymentDateZort: this.translateService.instant('SYNSYN100.M00151'),
            paymentDate: this.translateService.instant('SYNSYN100.M00151'),
            productCode:  this.translateService.instant('SYNSYN100.M00031'),
            productName: this.translateService.instant('SYNSYN100.M00032'),
            productQuantity: this.translateService.instant('SYNSYN100.M00152'),
            productUnit: this.translateService.instant('SYNSYN100.M00153'),
            productAmountUnit: this.translateService.instant('SYNSYN100.M00154'),
            productDiscountUnit: this.translateService.instant('SYNSYN100.M00155'),
            totalAmountLocal: this.translateService.instant('SYNSYN100.M00156'),
            exchangeDate: this.translateService.instant('SYNSYN100.M00157'),
            exchangeRate: this.translateService.instant('SYNSYN100.M00158'),
            totalAmountExchange: this.translateService.instant('SYNSYN100.M00156'),
            supplierCode: this.translateService.instant('SYNSYN100.M00159'),
            sellerCode: this.translateService.instant('SYNSYN100.M00028'),
            productWholesaleFlag: this.translateService.instant('SYNSYN100.M00160'),
            moneyReceivingFlag: this.translateService.instant('SYNSYN100.M00161'),
            commissionType: this.translateService.instant('SYNSYN100.M00162'),
            commissionCurremcy: this.translateService.instant('SYNSYN100.M00163'),
            fixCostAmountLocal: this.translateService.instant('SYNSYN100.M00164'),
            fixCostAmountExchange: this.translateService.instant('SYNSYN100.M00165'),
            percentODC: this.translateService.instant('SYNSYN100.M00166'),
            percentCSA: this.translateService.instant('SYNSYN100.M00167'),
            percentFMM: this.translateService.instant('SYNSYN100.M00168'),
            amountODC: this.translateService.instant('SYNSYN100.M00169'),
            amountCSA: this.translateService.instant('SYNSYN100.M00170'),
            amountFMM: this.translateService.instant('SYNSYN100.M00171'),
            shipingFreeFlag: this.translateService.instant('SYNSYN100.M00172'),
            trackingNumber: this.translateService.instant('SYNSYN100.M00173'),
            logisticCompany: this.translateService.instant('SYNSYN100.M0085'),
            sizeBox: this.translateService.instant('SYNSYN100.M00095'),
            location: this.translateService.instant('SYNSYN100.M00174'),
            shippingAmountBox: this.translateService.instant('SYNSYN100.M00175'),
            boxQuantity: this.translateService.instant('SYNSYN100.M00176'),
            totalShippingAmount: this.translateService.instant('SYNSYN100.M00177'),
            customerAmount: this.translateService.instant('SYNSYN100.M00178'),
            sellerAmount: this.translateService.instant('SYNSYN100.M00179'),
            orderCreateDate: this.translateService.instant('SYNSYN100.M00180'),
            shippedDate: this.translateService.instant('SYNSYN100.M00181'),
            shippingStatus: this.translateService.instant('SYNSYN100.M00182'),
            codFreeFlag: this.translateService.instant('SYNSYN100.M00183'),
            codFlag: this.translateService.instant('SYNSYN100.M00184'),
            codTracking: this.translateService.instant('SYNSYN100.M00173'),
            codLogisticCompany: this.translateService.instant('SYNSYN100.M00085'),
            codTotalAmount: this.translateService.instant('SYNSYN100.M00144'),
            codServiceAmount: this.translateService.instant('SYNSYN100.M00185'),
            codShippedSate: this.translateService.instant('SYNSYN100.M00186'),
            receiverName: this.translateService.instant('SYNSYN100.M00136'),
            receiverPhoneNumber: this.translateService.instant('SYNSYN100.M00138'),
            receiverEmail: this.translateService.instant('SYNSYN100.M00139'),
            receiverAddress: this.translateService.instant('SYNSYN100.M00140'),
            receiverPostcode: this.translateService.instant('SYNSYN100.M00137'),
            receiverProvince: this.translateService.instant('SYNSYN100.M00141'),
            receiverDistrict: this.translateService.instant('SYNSYN100.M00142'),
            receiverSubdistrict: this.translateService.instant('SYNSYN100.M00143'),
            filesName: this.translateService.instant('SYNSYN100.M00080'),
            errorCode: this.translateService.instant('SYNSYN100.M00081'),
            errorMessage:  this.translateService.instant('SYNSYN100.M00082')
          };
          this.exportExcelService.exportAsExcelFile(responce,  this.translateService.instant('SYNSYN100.M00083'), header, null, subTitle);
          responce.shift();
        }
      });
    } else if (sheetCode === 'SER100') {
      this.synService.getSynchronizeStatus(sheetCode, filesName).subscribe((response: SynchronizeStatusModel[]) => {
        response.forEach(element => {
          responce.push(element);
        });
        if (responce.length !== 0) {
          const subTitle = [];
          subTitle.push([`${this.translateService.instant('SYNSYN100.M00026')}` + sheetCode]);
          const header: any = {
            googleSheetID: this.translateService.instant('SYNSYN100.M00187'),
            type: this.translateService.instant('SYNSYN100.M00037'),
            invoiceNumber: this.translateService.instant('SYNSYN100.M00188'),
            createBy: this.translateService.instant('SYNSYN100.M00189'),
            sellerName: this.translateService.instant('SYNSYN100.M00029'),
            sellerCode: this.translateService.instant('SYNSYN100.M00028'),
            sellerEmail: this.translateService.instant('SYNSYN100.M00190'),
            sellerPhoneNumber: this.translateService.instant('SYNSYN100.M00191'),
            sellerAddress: this.translateService.instant('SYNSYN100.M00192'),
            sellerTexID: this.translateService.instant('SYNSYN100.M00193'),
            refer: this.translateService.instant('SYNSYN100.M00102'),
            salesChannel: this.translateService.instant('SYNSYN100.M00113'),
            invoiceDateBE: this.translateService.instant('SYNSYN100.M00194'),
            discountAmount: this.translateService.instant('SYNSYN100.M00195'),
            amount: this.translateService.instant('SYNSYN100.M00105'),
            vatAmount: this.translateService.instant('SYNSYN100.M00132'),
            shippingSellerAmount: this.translateService.instant('SYNSYN100.M00196'),
            shippingDate: this.translateService.instant('SYNSYN100.M00197'),
            trackingNumber: this.translateService.instant('SYNSYN100.M00173'),
            shippingChannels: this.translateService.instant('SYNSYN100.M00135'),
            consigneeName: this.translateService.instant('SYNSYN100.M00136'),
            consigneePhoneNumber: this.translateService.instant('SYNSYN100.M00138'),
            consigneeEmail: this.translateService.instant('SYNSYN100.M00139'),
            consigneeAddress: this.translateService.instant('SYNSYN100.M00140'),
            consigneePostcode: this.translateService.instant('SYNSYN100.M00137'),
            consigneeProvince: this.translateService.instant('SYNSYN100.M00141'),
            consigneeDistrict: this.translateService.instant('SYNSYN100.M00142'),
            consigneeSubdistrict: this.translateService.instant('SYNSYN100.M00143'),
            totalAmount: this.translateService.instant('SYNSYN100.M00198'),
            remark: this.translateService.instant('SYNSYN100.M00145'),
            invoiceStatus: this.translateService.instant('SYNSYN100.M00199'),
            paymentStatus: this.translateService.instant('SYNSYN100.M00147'),
            taxInvoiceNumber: this.translateService.instant('SYNSYN100.M00200'),
            paymentChannels: this.translateService.instant('SYNSYN100.M00149'),
            paymentAmount: this.translateService.instant('SYNSYN100.M00150'),
            paymentDateBE: this.translateService.instant('SYNSYN100.M00201'),
            serviceCode: this.translateService.instant('SYNSYN100.M00202'),
            serviceName: this.translateService.instant('SYNSYN100.M00203'),
            serviceNumberTime: this.translateService.instant('SYNSYN100.M00204'),
            serviceAmountPerTime: this.translateService.instant('SYNSYN100.M00205'),
            serviceDiscountPerTime: this.translateService.instant('SYNSYN100.M00206'),
            serviceTotalAmount: this.translateService.instant('SYNSYN100.M00207'),
            servicePaymentDate: this.translateService.instant('SYNSYN100.M00208'),
            serviceStatus: this.translateService.instant('SYNSYN100.M00209'),
            servicePaymentStatus: this.translateService.instant('SYNSYN100.M00210'),
            serviceDescription: this.translateService.instant('SYNSYN100.M00211'),
            serviceType: this.translateService.instant('SYNSYN100.M00212'),
            country: this.translateService.instant('SYNSYN100.M00114'),
            serviceCompany: this.translateService.instant('SYNSYN100.M00213'),
            invoiceDate: this.translateService.instant('SYNSYN100.M00103'),
            paymentDate: this.translateService.instant('SYNSYN100.M00214'),
            currency: this.translateService.instant('SYNSYN100.M00215'),
            exchangeRete: this.translateService.instant('SYNSYN100.M00216'),
            serviceEndDateBE: this.translateService.instant('SYNSYN100.M00218'),
            serviceEndDate: this.translateService.instant('SYNSYN100.M00219'),
            subServiceType: this.translateService.instant('SYNSYN100.M00220'),
            filesName: this.translateService.instant('SYNSYN100.M00080'),
            errorCode: this.translateService.instant('SYNSYN100.M00081'),
            errorMessage: this.translateService.instant('SYNSYN100.M00082')
          };
          this.exportExcelService.exportAsExcelFile(responce, this.translateService.instant('SYNSYN100.M00083'), header, null, subTitle);
          responce.shift();
        }
      });
    } else if (sheetCode === 'SER200') {
      this.synService.getSynchronizeStatus(sheetCode, filesName).subscribe((response: SynchronizeStatusModel[]) => {
        response.forEach(element => {
          responce.push(element);
        });
        if (responce.length !== 0) {
          const subTitle = [];
          subTitle.push([`${this.translateService.instant('SYNSYN100.M00026')}` + sheetCode]);
          const header: any = {
            googleSheetID: this.translateService.instant('SYNSYN100.M00187'),
            type: this.translateService.instant('SYNSYN100.M00037'),
            invoiceNumber: this.translateService.instant('SYNSYN100.M00188'),
            createBy: this.translateService.instant('SYNSYN100.M00189'),
            sellerName: this.translateService.instant('SYNSYN100.M00029'),
            sellerCode: this.translateService.instant('SYNSYN100.M00028'),
            sellerEmail: this.translateService.instant('SYNSYN100.M00190'),
            sellerPhoneNumber: this.translateService.instant('SYNSYN100.M00191'),
            sellerAddress: this.translateService.instant('SYNSYN100.M00192'),
            sellerTexID: this.translateService.instant('SYNSYN100.M00193'),
            refer: this.translateService.instant('SYNSYN100.M00102'),
            invoiceDateBE: this.translateService.instant('SYNSYN100.M00194'),
            discountAmount: this.translateService.instant('SYNSYN100.M00195'),
            amount: this.translateService.instant('SYNSYN100.M00105'),
            vatAmount: this.translateService.instant('SYNSYN100.M00132'),
            shippingSellerAmount: this.translateService.instant('SYNSYN100.M00196'),
            totalAmount: this.translateService.instant('SYNSYN100.M00198'),
            remark: this.translateService.instant('SYNSYN100.M00145'),
            invoiceStatus: this.translateService.instant('SYNSYN100.M00199'),
            paymentStatus: this.translateService.instant('SYNSYN100.M00147'),
            paymentChannels: this.translateService.instant('SYNSYN100.M00149'),
            paymentAmount: this.translateService.instant('SYNSYN100.M00150'),
            paymentDateBE: this.translateService.instant('SYNSYN100.M002001'),
            purchaseCode:  this.translateService.instant('SYNSYN100.M00031'),
            purchaseName: this.translateService.instant('SYNSYN100.M00032'),
            purchaseNumberTime: this.translateService.instant('SYNSYN100.M00152'),
            purchaseAmountPerTime: this.translateService.instant('SYNSYN100.M00154'),
            purchaseDiscountPerTime: this.translateService.instant('SYNSYN100.M00221'),
            purchaseTotalAmount: this.translateService.instant('SYNSYN100.M00217'),
            purchaseStatus: this.translateService.instant('SYNSYN100.M00222'),
            purchaseDescription: this.translateService.instant('SYNSYN100.M00223'),
            invoiceDate: this.translateService.instant('SYNSYN100.M00103'),
            paymentDate: this.translateService.instant('SYNSYN100.M00214'),
            sellerCodeKHA: this.translateService.instant('SYNSYN100.M00028'),
            country: this.translateService.instant('SYNSYN100.M00114'),
            currency: this.translateService.instant('SYNSYN100.M00215'),
            exchangeRete: this.translateService.instant('SYNSYN100.M00216'),
            purchaseChannel: this.translateService.instant('SYNSYN100.M00224'),
            buyerName: this.translateService.instant('SYNSYN100.M00225'),
            filesName: this.translateService.instant('SYNSYN100.M00080'),
            errorCode: this.translateService.instant('SYNSYN100.M00081'),
            errorMessage: this.translateService.instant('SYNSYN100.M00082')
          };
          this.exportExcelService.exportAsExcelFile(responce, this.translateService.instant('SYNSYN100.M00083'), header, null, subTitle);
          responce.shift();
        }
      });
    } else if (sheetCode === 'STK100' || sheetCode === 'STK200') {
      this.synService.getSynchronizeStatus(sheetCode, filesName).subscribe((response: SynchronizeStatusModel[]) => {
        response.forEach(element => {
          responce.push(element);
        });
        if (responce.length !== 0) {
          const subTitle = [];
          subTitle.push([`${this.translateService.instant('SYNSYN100.M00026')}` + sheetCode]);
          const header: any = {
            productCodeSeller: this.translateService.instant('SYNSYN100.M00030'),
            productCode:  this.translateService.instant('SYNSYN100.M00031'),
            productName: this.translateService.instant('SYNSYN100.M00032'),
            dateStock: this.translateService.instant('SYNSYN100.M00226'),
            productUnit: this.translateService.instant('SYNSYN100.M00038'),
            category: this.translateService.instant('SYNSYN100.M00034'),
            productBrand: this.translateService.instant('SYNSYN100.M00227'),
            beginQuantity: this.translateService.instant('SYNSYN100.M00228'),
            receivedQuantity: this.translateService.instant('SYNSYN100.M00229'),
            issuedQuantity: this.translateService.instant('SYNSYN100.M00230'),
            soldQuantity: this.translateService.instant('SYNSYN100.M00231'),
            returnedQuantity: this.translateService.instant('SYNSYN100.M00232'),
            endingQuantity: this.translateService.instant('SYNSYN100.M00233'),
            sellerCode: this.translateService.instant('SYNSYN100.M00028'),
            country: this.translateService.instant('SYNSYN100.M00114'),
            productAmount: this.translateService.instant('SYNSYN100.M00234'),
            totalProductAmount: this.translateService.instant('SYNSYN100.M00235'),
            period: this.translateService.instant('SYNSYN100.M00236'),
            filesName: this.translateService.instant('SYNSYN100.M00080'),
            errorCode: this.translateService.instant('SYNSYN100.M00081'),
            errorMessage: this.translateService.instant('SYNSYN100.M00082')
          };
          this.exportExcelService.exportAsExcelFile(responce, this.translateService.instant('SYNSYN100.M00083'), header, null, subTitle);
          responce.shift();
        }
      });
    } else if (sheetCode === 'USE100') {
      this.synService.getSynchronizeStatus(sheetCode, filesName).subscribe((response: SynchronizeStatusModel[]) => {
        response.forEach(element => {
          responce.push(element);
        });
        if (responce.length !== 0) {
          const subTitle = [];
          subTitle.push([`${this.translateService.instant('SYNSYN100.M00026')}` + sheetCode]);
          const header: any = {
            username: this.translateService.instant('SYNSYN100.M00237'),
            shopName: this.translateService.instant('SYNSYN100.M00238'),
            brand: this.translateService.instant('SYNSYN100.M00239'),
            language: this.translateService.instant('SYNSYN100.M00240'),
            email: this.translateService.instant('SYNSYN100.M00241'),
            phoneNumber: this.translateService.instant('SYNSYN100.M00242'),
            address: this.translateService.instant('SYNSYN100.M00243'),
            lineID: this.translateService.instant('SYNSYN100.M00244'),
            activeFlag: this.translateService.instant('SYNSYN100.M00245'),
            sellerCode: this.translateService.instant('SYNSYN100.M00028'),
            tin: this.translateService.instant('SYNSYN100.M00123'),
            profileID: this.translateService.instant('SYNSYN100.M00246'),
            bankAccountNumber: this.translateService.instant('SYNSYN100.M00247'),
            bankAccount: this.translateService.instant('SYNSYN100.M00248'),
            bankAccountName: this.translateService.instant('SYNSYN100.M00249'),
            fulfillmentLinkKH: this.translateService.instant('SYNSYN100.M00250'),
            fulfillmentLinkLA: this.translateService.instant('SYNSYN100.M00251'),
            fulfillmentLinkTH: this.translateService.instant('SYNSYN100.M00252'),
            fulfillmentFlag: this.translateService.instant('SYNSYN100.M00253'),
            filesName: this.translateService.instant('SYNSYN100.M00080'),
            errorCode: this.translateService.instant('SYNSYN100.M00081'),
            errorMessage: this.translateService.instant('SYNSYN100.M00082')
          };
          this.exportExcelService.exportAsExcelFile(responce, this.translateService.instant('SYNSYN100.M00083'), header, null, subTitle);
          responce.shift();
        }
      });
    }
  }
}
