import { HttpClient } from '@angular/common/http/';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChartSaleMove } from '../model/dashboard-overall.model';
import { SaleSummary } from '../model/dashboard-sale.model';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';
import * as uuid from 'uuid';
import { ResponseDshOverall, ResponseSaleOrderDetailByPeriod, ResponseSalesByItemGrid, ResponseSaleSummaryByPeriod, ResponseStockCardByPeriod } from 'src/app/dsh/dshova/dshova100/model/dshova100.model';


@Injectable({
  providedIn: 'root'
})
export class DashboardOverallService {

  private DSHOVA_URL: string = `${this.environmentService.getApiUrl()}/dsh/ova` as string;

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService,
              private authenticationService: AuthenticationService) {
}

  getSaleSummaryByInvoice(period: string, country: string, seller: string): Observable<SaleSummary> {
    const URL: string = `${this.DSHOVA_URL}/getsalesummarybyinvoice/period=${period},country=${country},seller=${seller}` as string;
    return this.http.get<SaleSummary>(URL, {headers: this.authenticationService.tokenHeader()});
  }

  getDataToChartSalMov(period: string, country: string, seller: string): Observable<ChartSaleMove> {
    const URL: string = `${this.DSHOVA_URL}/getdatatochartsalmov/period=${period},country=${country},seller=${seller}` as string;
    return this.http.get<ChartSaleMove>(URL, {headers: this.authenticationService.tokenHeader()});
  }

  getSaleSummaryByPeriod(country: string, seller: string, uuid: uuid): Observable<ResponseSaleSummaryByPeriod> {
    const URL: string = `${this.DSHOVA_URL}/salesummaryperiod/${country}/${seller}?uuid=${uuid}` as string;
    return this.http.get<ResponseSaleSummaryByPeriod>(URL, {headers: this.authenticationService.tokenHeader()});
  }

  getStockCardByPeriod(country: string, sellerCode: string, period: string, uuid: uuid): Observable<ResponseStockCardByPeriod> {
    const URL: string = `${this.DSHOVA_URL}/getstockcardbyperiod/?country=${country}&sellerCode=${sellerCode}&period=${period}&uuid=${uuid}` as string;
    return this.http.get<ResponseStockCardByPeriod>(URL, {headers: this.authenticationService.tokenHeader()});
  }

  getSaleOrderDetailByPeriod(country: string, sellerCode: string, period: string, uuid: uuid): Observable<ResponseSaleOrderDetailByPeriod> {
    const URL: string = `${this.DSHOVA_URL}/getsaleorderdetailbyperiod/?country=${country}&sellerCode=${sellerCode}&period=${period}&uuid=${uuid}` as string;
    return this.http.get<ResponseSaleOrderDetailByPeriod>(URL, {headers: this.authenticationService.tokenHeader()});
  }

  getTableSaleOrderDetailByPeriod(country: string, sellerCode: string, period: string, uuid: uuid): Observable<ResponseSalesByItemGrid> {
    const URL: string = `${this.DSHOVA_URL}/gettablesaleorderdetailbyperiod/?country=${country}&sellerCode=${sellerCode}&period=${period}&uuid=${uuid}` as string;
    return this.http.get<ResponseSalesByItemGrid>(URL, {headers: this.authenticationService.tokenHeader()});
  }

  getDashbordOverallByPeriod(country: string, sellerCode: string, period: string, uuid: uuid): Observable<ResponseDshOverall> {
    const URL: string = `${this.DSHOVA_URL}/getdashbordoverallbyperiod/?country=${country}&sellerCode=${sellerCode}&period=${period}&uuid=${uuid}` as string;
    return this.http.get<ResponseDshOverall>(URL, {headers: this.authenticationService.tokenHeader()});
  }
}