import { Component,
  OnInit,
  ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DropDownListModel } from 'src/app/shared/model/dropdownlist.model';
import { AdministrationUserService } from 'src/app/shared/services/administration-user.service';
import { Register } from 'src/app/shared/model/register.model';

@Component({
  selector: 'app-lanlan100',
  templateUrl: './lanlan100.component.html',
  styleUrls: ['./lanlan100.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Lanlan100Component implements OnInit {

  distributorList: Array<DropDownListModel> = [
    {label: 'เจ้าของแบรนด์', value: 'เจ้าของแบรนด์'},
    {label: 'ตัวแทนจำหน่าย', value: 'ตัวแทนจำหน่าย'},
    {label: 'เป็นทั้งคู่', value: 'เป็นทั้งคู่'}] as Array<DropDownListModel>;

  everList: Array<DropDownListModel> = [
    {label: 'เคย', value: 'เคย'},
    {label: 'ไม่เคย', value: 'ไม่เคย'}] as Array<DropDownListModel>;

  haveStoreFrontList: Array<DropDownListModel> = [
      {label: 'มี', value: 'มี'},
      {label: 'ไม่มี', value: 'ไม่มี'}] as Array<DropDownListModel>;

  registerGroupForm = new FormGroup({
    name: new FormControl(),
    phone: new FormControl(),
    address: new FormControl(),
    lineID: new FormControl(),
    email: new FormControl(),
    productName: new FormControl(),
    price: new FormControl(),
    website: new FormControl(),
    distributor: new FormControl(),
    quesFacebook: new FormControl(),
    quesGoogle: new FormControl(),
    advertisement: new FormControl(),
    linkAdvertisement: new FormControl(),
  });
  submitted: boolean = false as boolean;
  images: any[];
  dataRegister: any[];
  public items: any = [];
  constructor(private router: Router,
              private  scrollToService: ScrollToService,
              private admuserService: AdministrationUserService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.registerGroupForm = this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required],
      lineID: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      productName: ['', Validators.required],
      price: ['', Validators.required],
      website: new FormControl(),
      distributor: new FormControl(),
      quesFacebook: new FormControl(),
      quesGoogle: new FormControl(),
      advertisement: new FormControl(),
      linkAdvertisement: new FormControl(),
    });
    this.items = [];
    this.items.push({label: 'เกี่ยวกับ KlangOne.com', command: () => this.triggerScrollTo('select2')});
    this.items.push({label: 'บริการ', command: () => this.triggerScrollTo('service')});
    this.items.push({label: 'สมัครลงทะเบียนเป็นผู้ขาย', command: () => this.triggerScrollTo('select5')});
    this.items.push({label: 'เข้าสู่ระบบ', command: () => this.signin()});
  }

  get f() { return this.registerGroupForm.controls; }

  public triggerScrollTo( detination: string ) {
    const config: ScrollToConfigOptions = {
      target: detination
    };

    this.scrollToService.scrollTo(config);
  }

  signin() {
    this.router.navigate(['/aut/autaut100']);
  }

  register() {
    this.submitted = true;
    if (this.registerGroupForm.invalid) {
      return;
  }
    const registerInterface: Partial<Register> = {
      names: this.registerGroupForm.controls.name.value,
      phone: this.registerGroupForm.controls.phone.value,
      companyAddress: this.registerGroupForm.controls.address.value,
      lineID: this.registerGroupForm.controls.lineID.value,
      email: this.registerGroupForm.controls.email.value,
      productName: this.registerGroupForm.controls.productName.value,
      price: this.registerGroupForm.controls.price.value,
      productWebsite: this.registerGroupForm.controls.website.value,
      distributor: this.registerGroupForm.controls.distributor.value,
      questionFacebook: this.registerGroupForm.controls.quesFacebook.value,
      questionGoogle: this.registerGroupForm.controls.quesGoogle.value,
      advertisement: this.registerGroupForm.controls.advertisement.value,
      linkAdvertisement: this.registerGroupForm.controls.linkAdvertisement.value
    };
    this.admuserService.saveDatas(registerInterface).subscribe();
    alert('ยินดีด้วย! รายชื่อของคุณได้รับการลงทะเบียนแล้ว ทางทีม KlangOne จะรีบติดต่อกลับอย่างเร็วที่สุดค่ะ ^^');
    this.registerGroupForm.reset();
    this.submitted = false;
  }
}
