import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PsaRoutingModule } from './psa-routing.module';

import { Prisal100Component } from './prisal100/prisal100.component';
import { Prisal200Component } from './prisal200/prisal200.component';

@NgModule({
  declarations: [Prisal100Component, Prisal200Component],
  imports: [
    CommonModule,
    PsaRoutingModule,
    SharedModule
  ]
})
export class PsaModule { }
