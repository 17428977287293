import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { PeriodDropDownListModel } from '../model/dashboard-stock.model';
// import { FinancialSummaryModel } from 'src/app/app-dashboard/transaction-wallet/model/financial-summary-model';

@Injectable({
    providedIn: 'root'
  })
export class DashboardFinancialService {

    private DSHFNA_URL: string = `${this.environmentService.getApiUrl()}/dsh/fna` as string;

    constructor(private http: HttpClient,
                private environmentService: EnvironmentService,
                private authenticationService: AuthenticationService) {
      }

    getPeriod(): Observable<PeriodDropDownListModel> {
        const URL = `${this.DSHFNA_URL}/getPeriodFinancialSummary`;
        return this.http.get<PeriodDropDownListModel>(URL, {headers: this.authenticationService.tokenHeader()});
    }

    // getFinacialSummaryData(year: string, month: string): Observable<FinancialSummaryModel> {
    //     const URL = `${this.DSHFNA_URL}/getFinancialSummary`;
    //     return this.http.get<FinancialSummaryModel>(`${this.apiService.getApiUrl()}${URL}`,
    //         {headers: this.authService.tokenHeader(), params: {year, month}});
    // }
	
	//   getWalletBalance(seller,ischart,period): Observable<walletBalance> {
  //   return this.http.get<walletBalance>(`${this.apiService.getApiUrl()}dashboard/getwalletbalance/${seller}/${ischart}/${period}`, {headers: this.authService.tokenHeader()});
  // }

  // getYearMonth(seller): Observable<filterRange> {
  //   return this.http.get<filterRange>(`${this.apiService.getApiUrl()}dashboard/getoptionwallet/${seller}`, {headers: this.authService.tokenHeader()});
  // }

  // getDataPur(period): Observable<walletData>{
  //   return this.http.get<walletData>(`${this.apiService.getApiUrl()}dashboard/gettransactionwallet/${period}`, {headers: this.authService.tokenHeader()});
  // }
  
  // getTranDataSum(period): Observable<sum[]>{
  //   return this.http.get<sum[]>(`${this.apiService.getApiUrl()}dashboard/gettransactionwalletsum/${period}`, {headers: this.authService.tokenHeader()});
  // }

  // getPeriod(): Observable<dataDate[]>{
  //   return this.http.get<dataDate[]>(`${this.apiService.getApiUrl()}dashboard/gettransactionwalletDat`, {headers: this.authService.tokenHeader()});
  // }
  
  //   getYearMonth(seller,country): Observable<filterRange> {
  //   return this.http.get<filterRange>(`${this.apiService.getApiUrl()}dashboard/getcommissionsummary/${seller}/${country}`, {headers: this.authService.tokenHeader()});
  // }
 
  // getDataCMS(seller,country,type,period,group): Observable<dataCMS> {
  //   return this.http.get<dataCMS>(`${this.apiService.getApiUrl()}dashboard/getCMSsummary/${seller}/${country}/${type}/${period}/${group}`);
  // }
  // 
  // calculateCMS(seller,country,type,period): Observable<calCommission> {
  //   return this.http.get<calCommission>(`${this.apiService.getApiUrl()}dashboard/getwalletsummary/${seller}/${country}/${type}/${period}`);
  // }

  // getfixcost(seller,country,period): Observable<fixCostModel> {
  //   return this.http.get<fixCostModel>(`${this.apiService.getApiUrl()}dashboard/getFixcost/${seller}/${country}/${period}`);
  // }

  // getStrandardCMS(seller,country,period): Observable<strandardCMSModel> {
  //   return this.http.get<strandardCMSModel>(`${this.apiService.getApiUrl()}dashboard/getStrandardCMS/${seller}/${country}/${period}`);
  // }

  // getTotalSummary(seller,country,period): Observable<totalSummaryModel> {
  //   return this.http.get<totalSummaryModel>(`${this.apiService.getApiUrl()}dashboard/getTotal/${seller}/${country}/${period}`);
  // }

  // getCmsSummary(seller,country,period): Observable<cmsSummaryModel> {
  //   return this.http.get<cmsSummaryModel>(`${this.apiService.getApiUrl()}dashboard/getcmsgroup/${seller}/${country}/${period}`);
  // }

  // getRevenueFromSales(seller,country,period): Observable<revenueFromSalesModel> {
  //   return this.http.get<revenueFromSalesModel>(`${this.apiService.getApiUrl()}dashboard/getRevenueFromSales/seller=${seller},country=${country},period=${period}`, {headers: this.authService.tokenHeader()});
  // }

  // getServiceFromSales(seller,country,period): Observable<serviceFromSalesModel> {
  //   return this.http.get<serviceFromSalesModel>(`${this.apiService.getApiUrl()}dashboard/getServiceFromSales/seller=${seller},country=${country},period=${period}`, {headers: this.authService.tokenHeader()});
  // }

  // getServiceDetailFromSales(seller,country,period,type,group): Observable<serviceFromSalesDetailModel[]> {
  //   return this.http.get<serviceFromSalesDetailModel[]>(`${this.apiService.getApiUrl()}dashboard/getServiceDetailFromSales/seller=${seller},country=${country},period=${period},type=${type},group=${group}`, {headers: this.authService.tokenHeader()});
  // }

  // getServiceCMSDetailFromSales(seller,country,period,type,group): Observable<serviceFromSalesDetailModel[]> {
  //   return this.http.get<serviceFromSalesDetailModel[]>(`${this.apiService.getApiUrl()}dashboard/getServiceCMSDetailFromSales/seller=${seller},country=${country},period=${period},type=${type},group=${group}`, {headers: this.authService.tokenHeader()});
  // }

  // getLogisticAndWhChargeSeller(seller,country,period,size,type): Observable<logisticAndWhChargeSeller[]> {
  //   return this.http.get<logisticAndWhChargeSeller[]>(`${this.apiService.getApiUrl()}dashboard/getLogisticAndWhChargeSeller/seller=${seller},country=${country},period=${period},size=${size},type=${type}`, {headers: this.authService.tokenHeader()});
  // }

  // getCommissionSum(seller,country,period,type): Observable<logisticAndWhChargeSeller[]> {
  //   return this.http.get<logisticAndWhChargeSeller[]>(`${this.apiService.getApiUrl()}dashboard/getCommissionSum/seller=${seller},country=${country},period=${period},type=${type}`, {headers: this.authService.tokenHeader()});
  // }

  // getCommissionCOD(seller,country,period,type): Observable<logisticAndWhChargeSeller[]> {
  //   return this.http.get<logisticAndWhChargeSeller[]>(`${this.apiService.getApiUrl()}dashboard/getCommissionCOD/seller=${seller},country=${country},period=${period},type=${type}`, {headers: this.authService.tokenHeader()});
  // }
}
