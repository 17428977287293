import { HttpClient } from '@angular/common/http/';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CountInvoce, TotalSale } from '../model/dashboard-overall.model';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';

@Injectable({
    providedIn: 'root'
  })
  export class DashboardService {


    private DSH_URL: string = `${this.environmentService.getApiUrl()}/dsh` as string;

    constructor(private http: HttpClient,
                private environmentService: EnvironmentService,
                private authenticationService: AuthenticationService) {
    }

    getTotalSale(country: string, seller:string): Observable<TotalSale> {
        const URL: string = `${this.DSH_URL}/findtotalsale/country=${country},seller=${seller}` as string;
        return this.http.get<TotalSale>(URL, {headers: this.authenticationService.tokenHeader()});
    }

    getTotalSaleQty(seller:string): Observable<TotalSale> {
        const URL: string = `${this.DSH_URL}/findtotalsaleqty/seller=${seller}` as string;
        return this.http.get<TotalSale>(URL, {headers: this.authenticationService.tokenHeader()});
    }

    getCountInvoice(country: string, seller:string): Observable<CountInvoce> {
        const URL: string = `${this.DSH_URL}/getcountinvoice/country=${country},seller=${seller}` as string;
        return this.http.get<CountInvoce>(URL, {headers: this.authenticationService.tokenHeader()});
      }
}
