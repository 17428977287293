import { Component, OnInit } from '@angular/core';
import { ChartColor } from 'src/app/shared/constant/chartcolor.constant';


@Component({
  selector: 'app-dshfna100',
  templateUrl: './dshfna100.component.html',
  styleUrls: ['./dshfna100.component.scss']
})
export class Dshfna100Component implements OnInit {
  chartPie: any;
  data: any;
  saleChannelPie: any;
  optionsPie: any;
  dataExpense: any;
  display: boolean = false as boolean;
  groupData: any;
  pageName: string;
  programId: string = '' as string;

  constructor() {
    this.pageName = localStorage.getItem(this.programId);
  }

  ngOnInit() {
    this.chartPie = {
      labels: ['KHA-1137 Sampeng center', 'คลัง Pre-order', 'คลังสินค้าหลัก', 'คลัง1', 'คลัง2', 'คลัง3', 'คลัง4', 'คลัง5'],
      datasets: [
        {
          data: [120, 50, 400, 400, 6, 2528, 1554, 230],
          backgroundColor: ChartColor.backgroundColorChart,
          // hoverBackgroundColor: [
          //     "#FF6384",
          //     "#36A2EB",
          //     "#FFCE56"
          // ]
        },
        // this.optionsPie = {
        //   legend: {
        //       position: 'right',
        //  }
        // }
      ]

    };

    this.saleChannelPie = {
      labels: ['คลัง1', 'คลัง2', 'คลัง3', 'คลัง4', 'คลัง5'],
      datasets: [
        {
          data: [120, 50, 400, 400, 6],
          backgroundColor: ChartColor.backgroundColorChart,
          // hoverBackgroundColor: [
          //     "#FF6384",
          //     "#36A2EB",
          //     "#FFCE56"
          // ]
        },
        // this.optionsPie = {
        //   legend: {
        //       position: 'right  ',
        //  }
        // }
      ]
    };

    this.data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: '#42A5F5',
          borderColor: '#1E88E5',
          data: [65, 59, 80, 81, 56, 55, 40]
        },
        {
          label: 'My Second dataset',
          backgroundColor: '#9CCC65',
          borderColor: '#7CB342',
          data: [28, 48, 40, 19, 86, 27, 90]
        }
      ]
    };


    this.dataExpense = {
        labels: ['KHA-1137 Sampeng center', 'คลัง Pre-order', 'คลังสินค้าหลัก'],
        datasets: [
            {
                data: [300, 50, 100],
                backgroundColor: ChartColor.backgroundColorChart,
                // hoverBackgroundColor: [
                //     "#FF6384",
                //     "#36A2EB",
                //     "#FFCE56"
                // ]
            }]
    };

  }


  viewDetailByGroup(group) {
    this.display = true;
    this.groupData = group;
    // console.log(this.groupData);
  }
}
