import { Component, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ExchangeRateService } from 'src/app/shared/services/exchange-rate.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { ExchangeRateCriteriaRequest, ExchangeRateDelete, exchangeRateInquiry, excRate, ExchangeRate } from './model/excexc100.model';
import { Excexc100Service } from '../../service/excexc100.service';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { ParameterEntity } from 'src/app/shared/model/parameterwmsmodel';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Big } from 'big.js'
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { DecimalData } from 'src/app/fna/model/payment-data-model';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-excexc100',
  templateUrl: './excexc100.component.html',
  styleUrls: ['./excexc100.component.scss']
})
export class Excexc100Component extends PermissionComponent {
  public exchangeRateInquiry: exchangeRateInquiry = {} as exchangeRateInquiry;
  public exchangeRate: excRate = {} as excRate;
  public exchangeRateFillter: excRate = {} as excRate;
  public excDelete: ExchangeRateDelete = {} as ExchangeRateDelete;
  public wmsCurrency: Array<ParameterEntity> = [] as Array<ParameterEntity>;
  public criteriaSearchGrid: ExchangeRateCriteriaRequest = {} as ExchangeRateCriteriaRequest;
  public selectedWmsCurrency: Array<ParameterEntity> = [] as Array<ParameterEntity>;
  public wmsCountry: Array<ParameterEntity> = [] as Array<ParameterEntity>;
  public checkFlagDelete: boolean = true;
  public flagButtonDelete: boolean;
  public disableExportReport: boolean = true;
  public disableClearData: boolean = true;
  public disableSearchData: boolean = false;
  public isChecker: any;
  public row: any;
  translateLablePipe = new TranslateLablePipe();
  firstRow: number = 0 as number;

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  searchProdGroupForm1 = new FormGroup({
    searchexchange_rate_code: new FormControl(null),
    searchexchange_rate_country_start: new FormControl(null),
    exccurrency: new FormControl(null),
    searchexchange_rate_country_end: new FormControl(null),
    excexccurrency: new FormControl(null),
    searchexchange_rate_date_start: new FormControl(null),
    searchexchange_rate_date_end: new FormControl(null),
  });

  constructor(private parameterService: ParameterService,
    private exchangeRateService: ExchangeRateService,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService,
    private excexc100Service: Excexc100Service,
    private messageService: MessageService,
    private router: Router,
    private decimalService:DecimalService,
    renderer2:Renderer2,
    private parameterWmsSerive: ParameterWmsSerive) {
      super('P50002',renderer2);
  }

  ngOnInit() {
    this.exchangeRateInquiry.dataNotFoundGridTab1 = true;
    this.exchangeRate.data = this.exchangeRateFillter.data;
    this.parameterService.getvparameterDetailToLable(1000).subscribe((response) => {
      this.exchangeRateInquiry.optionCountry = JSON.parse(JSON.stringify(response));
    }
      , err => {
        console.log(err);
      });
    this.exchangeRateInquiry.excexc100Data = this.exchangeRateService.exchangerate;
    this.excexc100Service.getAllExchangeRate().subscribe(async response => {
      this.exchangeRateFillter = await JSON.parse(JSON.stringify(response));
    });
    this.parameterWmsSerive.getParameterWms(9146).subscribe(response => {
      this.wmsCurrency = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
  }

  SearchAuto(event) {
    this.exchangeRate.data = this.exchangeRateFillter.data.filter(data => {
      return (
        (data.exccountry.toString().toLowerCase().indexOf(event.toLowerCase()) > -1) ||
        (moment(data.excsdate).format('DD/MM/YYYY').toString().toLowerCase().indexOf(event.toLowerCase()) > -1) ||
        (data.excexccountry.toString().toLowerCase().indexOf(event.toLowerCase()) > -1) ||
        (moment(data.excedate).format('DD/MM/YYYY').toString().toLowerCase().indexOf(event.toLowerCase()) > -1) ||
        (data.excrate.toString().toLowerCase().indexOf(event.toLowerCase()) > -1) ||
        (data.excexcrate.toString().toLowerCase().indexOf(event.toLowerCase()) > -1)||
        (this.decimalService.getRoundingDecimal(new Big(Number(data.excrate)), data.excmethod, data.excrouting, data.excdecimal).toString().toLowerCase().indexOf(event.toLowerCase()) > -1)||
        (this.decimalService.getRoundingDecimal(new Big(Number(data.excexcrate)), data.excexmethod, data.excexrouting, data.excexdecimal).toString().toLowerCase().indexOf(event.toLowerCase()) > -1)||
        ((data.excrate).toFixed(data.excdecimal).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().indexOf(event.toLowerCase()) > -1) ||
        ((data.excexcrate).toFixed(data.excexdecimal).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().indexOf(event.toLowerCase()) > -1) ||
        (this.translateLablePipe.transform(data.exccurrency_eng,data.exccurrency_local).toString().toLowerCase().indexOf(event.toLowerCase()) > -1)||
        (this.translateLablePipe.transform(data.excexccurrency_eng,data.excexccurrency_local).toString().toLowerCase().indexOf(event.toLowerCase()) > -1)
      );
    });
    this.firstRow = 0;
  }
  ClearData() {
    this.exchangeRateInquiry.dataNotFoundGridTab1 = true;
    this.disableClearData = true;
    this.disableExportReport = true;
    this.disableSearchData = false;
    this.searchProdGroupForm1.reset();
    this.exchangeRateInquiry.excexc100Data = this.exchangeRateService.exchangerate;
    this.exchangeRate.data = [] ;
  }

  exportReport() {
    let exportExchangerate = [];
    this.exchangeRate.data.forEach(exc=>{
      exportExchangerate.push({
        exccountry: exc.exccountry,
        exccurrency: this.translateLablePipe.transform(exc.exccurrency_eng,exc.exccurrency_local),
        excrate: exc.excrate,
        excexccountry: exc.excexccountry,
        excexccurrency: this.translateLablePipe.transform(exc.excexccurrency_eng,exc.excexccurrency_local),
        excexcrate: exc.excexcrate,
        excsdate: moment(exc.excsdate).format('DD/MM/YYYY'),
        excedate: moment(exc.excedate).format('DD/MM/YYYY'),
        })
    });


    let subTitle = [];
    subTitle.push([this.translateService.instant('EXCEXC100.M00000')]);
    let header: any = {
      exccountry: this.translateService.instant('EXCEXC100.M00005'),
      exccurrency: this.translateService.instant('EXCEXC100.M00010'),
      excrate: this.translateService.instant('EXCEXC100.M00009'),
      excexccountry: this.translateService.instant('EXCEXC100.M00006'),
      excexccurrency: this.translateService.instant('EXCEXC100.M00010'),
      excexcrate: this.translateService.instant('EXCEXC100.M00009'),
      excsdate: this.translateService.instant('EXCEXC100.M00029'),
      excedate: this.translateService.instant('EXCEXC100.M00012'),
    }
    this.exportExcelService.exportAsExcelFile(exportExchangerate, this.translateService.instant('EXCEXC100.M00000'), header, null, subTitle);
    this.exchangeRate.data.shift();
  }
  selectRow() {

  }

  addFunction() {
    this.router.navigate(['/excexc/excexc200']);
  }

  remove() {
    this.excDelete.excId.forEach(data => {
      this.exchangeRate.data.forEach((exc, index) => {
        if (exc.excId == data) {
          this.exchangeRate.data.splice(index, 1);
        }
      })
    });

    this.excexc100Service.getExchangeRateDelete(this.excDelete).subscribe(res => {
      if (res) {
        this.messageService.clear();
      } else {
        this.messageService.clear();
      }
      this.checkFlagDelete = true;
    });


    if (this.exchangeRate.data.length == 0) {
      this.exchangeRateInquiry.dataNotFoundGridTab1 = true;
    }
  }

  checkedAll(isChecker: any) {

    if (this.excDelete.excId == undefined) {
      this.excDelete.excId = [] as Array<number>;
    }

    if (isChecker) {
      this.checkFlagDelete = false;
      this.exchangeRate.data.forEach(exc => {
        this.excDelete.excId.push(exc.excId);
      })
    } else {
      this.checkFlagDelete = true;
    }
  }


  checked(isChecker: any, excDelete: ExchangeRate) {

    if (this.excDelete.excId == undefined) {
      this.excDelete.excId = [] as Array<number>;
    }

    if (isChecker == true) {
      this.excDelete.excId.push(excDelete.excId);
    } else {
      this.excDelete.excId = this.excDelete.excId.filter(data => data != excDelete.excId);
    }

    if (this.excDelete.excId.length > 0) {
      this.checkFlagDelete = false;
    } else {
      this.checkFlagDelete = true;
    }

  }


  filterData() {
    this.criteriaSearchGrid.exccountry = this.searchProdGroupForm1.get('searchexchange_rate_country_start').value == '' ? null : this.searchProdGroupForm1.get('searchexchange_rate_country_start').value;
    this.criteriaSearchGrid.excexccountry = this.searchProdGroupForm1.get('searchexchange_rate_country_end').value == '' ? null : this.searchProdGroupForm1.get('searchexchange_rate_country_end').value;
    if (this.searchProdGroupForm1.get('searchexchange_rate_date_start').value == null) {
      this.criteriaSearchGrid.excsdate = formatDate('01/01/1990', 'dd/MM/yyyy', 'en_US').toString();
      this.criteriaSearchGrid.excsdateend = '31/12/2049';
    } else {
      this.criteriaSearchGrid.excsdate = this.searchProdGroupForm1.get('searchexchange_rate_date_start').value[0] == null || this.searchProdGroupForm1.get('searchexchange_rate_date_start').value[0] == '' ? formatDate('01/01/1990', 'dd/MM/yyyy', 'en_US').toString() : formatDate(this.searchProdGroupForm1.get('searchexchange_rate_date_start').value[0], 'dd/MM/yyyy', 'en_US').toString();
      this.criteriaSearchGrid.excsdateend = this.searchProdGroupForm1.get('searchexchange_rate_date_start').value[0] == null || this.searchProdGroupForm1.get('searchexchange_rate_date_start').value[0] == '' ? '31/12/2049' : this.searchProdGroupForm1.get('searchexchange_rate_date_start').value[1] == null || this.searchProdGroupForm1.get('searchexchange_rate_date_start').value[1] == '' ? formatDate(this.searchProdGroupForm1.get('searchexchange_rate_date_start').value[0], 'dd/MM/yyyy', 'en_US').toString() : formatDate(this.searchProdGroupForm1.get('searchexchange_rate_date_start').value[1], 'dd/MM/yyyy', 'en_US').toString();
    }
    if (this.searchProdGroupForm1.get('searchexchange_rate_date_end').value == null) {
      this.criteriaSearchGrid.excedate = formatDate('01/01/1990', 'dd/MM/yyyy', 'en_US').toString();
      this.criteriaSearchGrid.excedateend = '31/12/2049';
    } else {
      this.criteriaSearchGrid.excedate = this.searchProdGroupForm1.get('searchexchange_rate_date_end').value[0] == null || this.searchProdGroupForm1.get('searchexchange_rate_date_end').value[0] == '' ? formatDate('01/01/1990', 'dd/MM/yyyy', 'en_US').toString() : formatDate(this.searchProdGroupForm1.get('searchexchange_rate_date_end').value[0], 'dd/MM/yyyy', 'en_US').toString();
      this.criteriaSearchGrid.excedateend = this.searchProdGroupForm1.get('searchexchange_rate_date_end').value[0] == null || this.searchProdGroupForm1.get('searchexchange_rate_date_end').value[0] == '' ? '31/12/2049' : this.searchProdGroupForm1.get('searchexchange_rate_date_end').value[1] == null || this.searchProdGroupForm1.get('searchexchange_rate_date_end').value[1] == '' ? formatDate(this.searchProdGroupForm1.get('searchexchange_rate_date_end').value[0], 'dd/MM/yyyy', 'en_US').toString() : formatDate(this.searchProdGroupForm1.get('searchexchange_rate_date_end').value[1], 'dd/MM/yyyy', 'en_US').toString();
    }
    this.excexc100Service.getExchangeRateByCriteria(this.criteriaSearchGrid).subscribe((response) => {
      this.exchangeRate = JSON.parse(JSON.stringify(response));
    }
      , err => {
        console.log(err);
      });
    this.disableExportReport = false;
    this.disableClearData = false;
    this.disableSearchData = true;
    this.exchangeRateInquiry.dataNotFoundGridTab1 = true;
  }
  changeSearch(event){
    if(event){
      this.disableSearchData = false;
    } else {
      this.disableSearchData = true;
    }
  }

  deleteExchangeItem(message) {
    this.messageService.clear();
    this.messageService.add({ key: 'delete', sticky: true, severity: 'warn', summary: this.translateService.instant('EXCEXC100.M00036'), detail: message });
  }

  onReject() {
    this.messageService.clear();
  }
}
