import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class CreditNoteService {
  

  constructor(private http: HttpClient,
              private environmentService: EnvironmentService,
              private authenticationService: AuthenticationService) { }

  private CREDIT_NOTE_URL: string = `${this.environmentService.getApiUrl()}/api/acc/cnd` as string;

  getReturnDocumentInquiry(seller:string ,warehouse:string) {    
     
    const URL: string = `${this.CREDIT_NOTE_URL}/getDocument/${seller}/${warehouse}` as string;
    return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
  }

  getDocumentTypeSaleOrder(creditNoteData){
    const URL: string = `${this.CREDIT_NOTE_URL}/getDocumentTypeSaleOrder/` as string;
    return this.http.post(URL,creditNoteData,{ headers: this.authenticationService.tokenHeader()});
  }

  
}
