import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LanRouthModule } from './lan-routhing.module';
import { SharedModule } from '../shared/shared.module';

import { Lanlan100Component } from './lanlan100/lanlan100.component';

@NgModule({
  declarations: [
    Lanlan100Component
  ],
  imports: [
    CommonModule,
    LanRouthModule,
    SharedModule
  ]
})
export class LanModule { }
