import { Component, OnInit , Renderer2} from '@angular/core';
import { CreditNoteInquiryPage, CriteriaSearchGrid, CreditNoteDataInquiryModel ,CreditNotePrintModel ,DecimalData, CreditNotePrintDetailModel, CreditNoteModel} from '../model/acccdn';
import { FormControl, FormGroup } from '@angular/forms';
import { CreditNoteService } from 'src/app/acc/service/acccdn';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { TranslateService } from '@ngx-translate/core';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { Big } from 'big.js';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-acccdn100',
  templateUrl: './acccdn100.component.html',
  styleUrls: ['./acccdn100.component.scss']
})
export class Acccdn100Component extends PermissionComponent {

  creditNotePage: CreditNoteInquiryPage = {} as CreditNoteInquiryPage;
  criteriaSearchGrid: CriteriaSearchGrid = {} as CriteriaSearchGrid;
  decimalData:DecimalData = {} as DecimalData;
  smartSearch: string;
  translateLablePipe = new TranslateLablePipe();
  disabledWarehouse: boolean = false;

  searchGridGroupForm = new FormGroup({
    warehouse: new FormControl(''),
    seller: new FormControl(''),
    documentType: new FormControl(''),
    status: new FormControl(''),
    documentNo: new FormControl(''),
    documentDate: new FormControl(),
    saleOrderNo: new FormControl(''),
    referenceDocNo: new FormControl('')
  });

  translateCalendarPipe = new TranslateCalendarPipe();

  constructor(private creditNotService: CreditNoteService,
    private router: Router,
    private autService: AuthenticationService,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService,
    private parameterService:ParameterService,
    private decimalService: DecimalService,
    renderer2:Renderer2) {
    super('P40001',renderer2);
    this.creditNotePage.disabledCountry = true;
    this.creditNotePage.language = localStorage.getItem('languageData') as string;
    this.creditNotePage.languageTH = 'TH';
  }

  ngOnInit() {
    this.creditNotePage.IsSearchGrid = false;
    this.creditNotePage.dataNotFoundGridTab = true;
    this.creditNotePage.disabledBtuPrinReport = true;
    this.creditNotePage.disabledBtuClear = true;
    this.creditNotePage.disabledBtuSubmit = true;
    this.creditNotePage.firstRow = 0;

    this.autService.getParameterWmsCountry().subscribe(response => {
      this.creditNotePage.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.creditNotePage.optionCountry.length == 1) {
        this.creditNotePage.country = this.creditNotePage.optionCountry[0].value;
        this.creditNotePage.disabledCountry = true;
        this.creditNotePage.disabledBtuSubmit = false;
        this.getDecimal(this.creditNotePage.country);
      } else if(this.creditNotePage.optionCountry.length == 0){
        this.creditNotePage.disabledCountry = true;
      } else{
        this.creditNotePage.disabledCountry = false;
      }
      this.getWarehouse();
    });

    this.creditNotService.getParameterPortal(4401, null).subscribe(response => {
      this.creditNotePage.statusData = JSON.parse(JSON.stringify(response));

    }, err => {
      console.log(err);
    });

    this.creditNotService.getParameterPortal(4400, null).subscribe(response => {
      this.creditNotePage.documentTypeData = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.creditNotePage.setLocaleDate = this.translateCalendarPipe.transform();
  }

  getWarehouse() {
    this.autService.getParameterWmsWareHouse(this.creditNotePage.country).subscribe(response => {
      this.creditNotePage.warehouseData = JSON.parse(JSON.stringify(response));
      if(this.creditNotePage.warehouseData.length == 0){
        this.disabledWarehouse = true;
      }else{
        this.disabledWarehouse = false;
      }
    }, err => {
      console.log(err);
    });
  }

  selectedCountry() {
    this.getWarehouse();
    this.getDecimal(this.creditNotePage.country);
    this.creditNotePage.dataNotFoundGridTab = true;
    this.creditNotePage.disabledBtuClear = false;
    this.creditNotePage.disabledBtuSubmit = false;
  }

  getDecimal(country){
    this.parameterService.getvprmdtltolableByCountry(104, country).subscribe(response => {
      this.creditNotePage.decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.purchasePriceDecimal = this.creditNotePage.decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == '1');
      this.decimalData.sellingPriceDecimal = this.creditNotePage.decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == '2');
      this.decimalData.roundingMethod = this.creditNotePage.decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == '3');
      this.decimalData.roundToValue = this.creditNotePage.decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == '4');
      this.creditNotePage.decimal = this.decimalData.sellingPriceDecimal[0].value.pmdtbdnumv1;
      this.creditNotePage.method = this.decimalData.roundingMethod[0].value.pmdtbdtxtv1
      this.creditNotePage.roundToDecimal = this.decimalData.roundToValue[0].value.pmdtbdnumv1;

    }, err => {
      console.log(err);
    });
  }

  changeDataSearch(){
    if(this.creditNotePage.country){
      this.creditNotePage.disabledBtuClear = false;
      this.creditNotePage.disabledBtuSubmit = false;
    }
  }

  searchHide() {
    this.creditNotePage.IsSearchGrid = this.creditNotePage.IsSearchGrid == true ? false : true;
  }

  filterData() {
    this.creditNotePage.disabledBtuSubmit = true;
    this.criteriaSearchGrid.country = this.creditNotePage.country
    this.criteriaSearchGrid.warehouseCode = this.searchGridGroupForm.controls['warehouse'].value == '' || this.searchGridGroupForm.controls['warehouse'].value == null ? [] : this.searchGridGroupForm.controls['warehouse'].value;
    this.criteriaSearchGrid.saleOrderNo = this.searchGridGroupForm.controls['saleOrderNo'].value;
    this.criteriaSearchGrid.sellerCode = this.searchGridGroupForm.controls['seller'].value == '' || this.searchGridGroupForm.controls['seller'].value == null ? [] : [this.searchGridGroupForm.controls['seller'].value];
    this.criteriaSearchGrid.rfDocument = this.searchGridGroupForm.controls['referenceDocNo'].value;
    this.criteriaSearchGrid.creditNoteStatus = this.searchGridGroupForm.controls['status'].value == '' || this.searchGridGroupForm.controls['status'].value == null ? [] : this.searchGridGroupForm.controls['status'].value;
    this.criteriaSearchGrid.creditNoteNo = this.searchGridGroupForm.controls['documentNo'].value;

    if (this.searchGridGroupForm.controls['documentDate'].value != null) {

      this.criteriaSearchGrid.creditNotetDate_from = moment(this.searchGridGroupForm.controls['documentDate'].value[0]).format('YYYY-MM-DD');
      if (this.searchGridGroupForm.controls['documentDate'].value[1] == null) {
        this.criteriaSearchGrid.creditNoteDate_to = moment(this.searchGridGroupForm.controls['documentDate'].value[0]).format('YYYY-MM-DD');
      } else {
        this.criteriaSearchGrid.creditNoteDate_to = moment(this.searchGridGroupForm.controls['documentDate'].value[1]).format('YYYY-MM-DD');
      }
    } else {
      this.criteriaSearchGrid.creditNotetDate_from = '0001-01-01';
      this.criteriaSearchGrid.creditNoteDate_to = '9999-12-31';
    }

    this.criteriaSearchGrid.creditNoteType = this.searchGridGroupForm.controls['documentType'].value == '' || this.searchGridGroupForm.controls['documentType'].value == null ? [] : this.searchGridGroupForm.controls['documentType'].value;
    this.creditNotService.getCreditNoteInquiry(this.criteriaSearchGrid).subscribe((response: CreditNoteDataInquiryModel[]) => {
      this.creditNotePage.creditnoteData = response;
      this.creditNotePage.creditnoteData.sort((a, b) => (a.creditNoteNo > b.creditNoteNo) ? 1 : -1)
      this.getPrintData(this.creditNotePage.creditnoteData);
      this.getPrintDataDetail(this.creditNotePage.creditnoteData);
      this.creditNotePage.creditnoteTemp = this.creditNotePage.creditnoteData;
      if (this.creditNotePage.creditnoteData.length == 0) {
        this.creditNotePage.dataNotFoundGridTab = true;
      } else {
        this.creditNotePage.dataNotFoundGridTab = false;
        this.creditNotePage.disabledBtuPrinReport = false;
        this.creditNotePage.firstRow = 0;
      }
    }, err => {
      console.log(err);
    });
  }

  getPrintData(creditnoteData){

    this.creditNotePage.dataPrint = [] as Array<CreditNotePrintModel>
    creditnoteData.forEach(dataTable => {
      this.creditNotePage.dataPrint.push({
        creditNoteNo: dataTable.creditNoteNo,
        creditNotetDate: moment(dataTable.creditNotetDate).format('DD/MM/YYYY HH:mm:ss'),
        confCreditNoteDate:dataTable.confCreditNoteDate,
        warehouseDesc: this.translateLablePipe.transform(dataTable.warehouseCode+'-'+dataTable.warehouseDesc_eng,dataTable.warehouseDesc_local),
        sellerDesc: this.translateLablePipe.transform(dataTable.sellerCode+'-'+dataTable.sellerDesc_eng,dataTable.sellerDesc_local),
        creditNoteTypeDesc: this.translateLablePipe.transform(dataTable.creditNoteType+'-'+dataTable.creditNoteTypeDesc_eng,dataTable.creditNoteTypeDesc_local),
        incVatTotalAmt: this.decimalService.getRoundingDecimal(new Big(Number(dataTable.incVatTotalAmt)), this.creditNotePage.method, this.creditNotePage.roundToDecimal, this.creditNotePage.decimal),
        creditNoteStatusDesc: this.translateLablePipe.transform(dataTable.creditNoteStatus+'-'+dataTable.creditNoteStatusDesc_eng,dataTable.creditNoteStatusDesc_local),
      })
    });
    this.creditNotePage.dataPrint.sort((a, b) => (a.creditNoteNo > b.creditNoteNo) ? 1 : -1)
  }

  getPrintDataDetail(creditnoteData) {
    this.creditNotePage.dataPrintDetail = [] as Array<CreditNotePrintDetailModel>

    creditnoteData.forEach(dataTable => {
      this.creditNotService.getCreditNoteData(Number(dataTable.creditNoteHeaderId)).subscribe((response: CreditNoteModel) => {
        response.documentData.forEach(document => {
          // this.creditNotService.getParameterPortal(200, this.creditNotePage.country).subscribe((vatResponse) => {
            // let dataVat = JSON.parse(JSON.stringify(vatResponse));
            // let vatSelect = dataVat.filter(data => data.value == response.vatCode);
            this.creditNotePage.dataPrintDetail.push({
              creditNoteNo: dataTable.creditNoteNo,
              creditNotetDate: moment(dataTable.creditNotetDate).format('DD/MM/YYYY HH:mm:ss'),
              coutryDesc: response.country,
              warehouseDesc: this.translateLablePipe.transform(dataTable.warehouseCode + '-' + dataTable.warehouseDesc_eng, dataTable.warehouseDesc_local),
              sellerDesc: this.translateLablePipe.transform(dataTable.sellerCode + '-' + dataTable.sellerDesc_eng, dataTable.sellerDesc_local),
              creditNoteTypeDesc: this.translateLablePipe.transform(dataTable.creditNoteType + '-' + dataTable.creditNoteTypeDesc_eng, dataTable.creditNoteTypeDesc_local),
              perCenVat: response.vatDesc,
              creditNoteStatusDesc: this.translateLablePipe.transform(dataTable.creditNoteStatus + '-' + dataTable.creditNoteStatusDesc_eng, dataTable.creditNoteStatusDesc_local),
              createBy: response.createBy,
              excludingVatAmt: this.decimalService.getRoundingDecimal(new Big(Number(response.excludingVatAmt)), this.creditNotePage.method, this.creditNotePage.roundToDecimal, this.creditNotePage.decimal),
              vatAmount: this.decimalService.getRoundingDecimal(new Big(Number(response.vatAmt)), this.creditNotePage.method, this.creditNotePage.roundToDecimal, this.creditNotePage.decimal),
              incVatTotalAmt: this.decimalService.getRoundingDecimal(new Big(Number(dataTable.incVatTotalAmt)), this.creditNotePage.method, this.creditNotePage.roundToDecimal, this.creditNotePage.decimal),
              documentNo: document.documentNo,
              documentDate: moment(document.documentDate).format('DD/MM/YYYY'),
              movementType: document.flowTypeCode == null ? '' :  this.translateLablePipe.transform(document.flowTypeCode + '-' + document.flowTypeDesc_eng, document.flowTypeDesc_local),
              saleorderNo: document.saleOrderNo,
              qty: document.totalQty,
              total: this.decimalService.getRoundingDecimal(new Big(Number(document.excludingVatAmt)), this.creditNotePage.method, this.creditNotePage.roundToDecimal, this.creditNotePage.decimal),
              vat: this.decimalService.getRoundingDecimal(new Big(Number(document.vatAmt)), this.creditNotePage.method, this.creditNotePage.roundToDecimal, this.creditNotePage.decimal),
              totalTax: this.decimalService.getRoundingDecimal(new Big(Number(document.includingVatAmt)), this.creditNotePage.method, this.creditNotePage.roundToDecimal, this.creditNotePage.decimal),
              confCreditNoteDate:dataTable.confCreditNoteDate,
              retrunMoneyDate: moment(document.returnMonetDate).format('DD/MM/YYYY HH:mm:ss'),
              operationDate: moment(document.recordDate).format('DD/MM/YYYY HH:mm:ss'),
            })
          })
        // });
      });

    });
    this.creditNotePage.dataPrintDetail.sort((a, b) => (a.creditNoteNo > b.creditNoteNo) ? 1 : -1)
  }

  clearData() {
    this.creditNotePage.dataNotFoundGridTab = true;
    this.searchGridGroupForm.reset();
    if (this.creditNotePage.optionCountry.length == 1) {
      this.creditNotePage.country = this.creditNotePage.optionCountry[0].value;
    }
    else if (this.creditNotePage.optionCountry.length > 1){
      this.creditNotePage.country = '';
    }
    this.creditNotePage.disabledBtuSubmit = true;
    this.creditNotePage.disabledBtuClear = true;
  }

  createCreditNote() {
    this.router.navigate(['acccdn/acccdn200'])
  }

  smartSearchInTable() {

    this.creditNotePage.creditnoteData = this.creditNotePage.creditnoteTemp;
    this.smartSearch = this.smartSearch == undefined ? '' : this.smartSearch;

    this.creditNotePage.creditnoteData = this.creditNotePage.creditnoteData.filter(data => {
      let wareHouseDesc: string = null;
      let sellerDesc: string = null;
      let creditNoteTypeDes: string = null;
      let creditNoteStatusDesc: string = null;
      if(this.creditNotePage.language == this.creditNotePage.languageTH){
        wareHouseDesc = data.warehouseCode.toString()+' - '+data.warehouseDesc_local.toString();
        sellerDesc = data.sellerCode.toString()+' - '+data.sellerDesc_local.toString();
        creditNoteTypeDes = data.creditNoteType.toString()+' - '+data.creditNoteTypeDesc_local.toString()
        creditNoteStatusDesc = data.creditNoteStatus.toString()+' - '+data.creditNoteStatusDesc_local.toString();
      }else{
        wareHouseDesc = data.warehouseCode.toString()+' - '+data.warehouseDesc_eng.toString();
        sellerDesc = data.sellerCode.toString()+' - '+data.sellerDesc_eng.toString();
        creditNoteTypeDes = data.creditNoteType.toString()+' - '+data.creditNoteTypeDesc_eng.toString()
        creditNoteStatusDesc = data.creditNoteStatus.toString()+' - '+data.creditNoteStatusDesc_eng.toString();
      }
      const creditNotetDate: string = moment(data.creditNotetDate).format('DD/MM/YYYY');
      return (
        (data.creditNoteNo.toString().toLowerCase().indexOf(this.smartSearch.toLowerCase()) > -1) ||
        (creditNotetDate.toString().toLowerCase().indexOf(this.smartSearch.toLowerCase()) > -1) ||
        (wareHouseDesc.toString().toLowerCase().indexOf(this.smartSearch.toLowerCase()) > -1) ||
        (sellerDesc.toString().toLowerCase().indexOf(this.smartSearch.toLowerCase()) > -1) ||
        (creditNoteTypeDes.toString().toLowerCase().indexOf(this.smartSearch.toLowerCase()) > -1) ||
        (creditNoteStatusDesc.toString().toLowerCase().indexOf(this.smartSearch.toLowerCase()) > -1) ||
        (this.decimalService.getRoundingDecimal(new Big(Number(data.incVatTotalAmt)), this.creditNotePage.method, this.creditNotePage.roundToDecimal, this.creditNotePage.decimal).toString().toLowerCase().indexOf(this.smartSearch.toLowerCase()) > -1)
      );
    });

    if (this.creditNotePage.creditnoteData.length == 0) {
      this.creditNotePage.dataNotFoundGridTab = true;
    } else {
      this.creditNotePage.dataNotFoundGridTab = false;
      this.creditNotePage.firstRow = 0;
    }
  }

  exportReport() {

    let subTitle = [];
    subTitle.push([this.translateService.instant('ACCCDN100.M00030')]);
    let header: any = {
      creditNoteNo: this.translateService.instant('ACCCDN100.M00013'),
      creditNotetDate: this.translateService.instant('ACCCDN100.M00015'),
      warehouseDesc: this.translateService.instant('ACCCDN100.M00006'),
      sellerDesc: this.translateService.instant('ACCCDN100.M00008'),
      creditNoteTypeDesc: this.translateService.instant('ACCCDN100.M00017'),
      incVatTotalAmt: this.translateService.instant('ACCCDN100.M00033'),
      creditNoteStatusDesc: this.translateService.instant('ACCCDN100.M00029'),
      confCreditNoteDate: this.translateService.instant('ACCCDN100.M00047'),
    }
    this.exportExcelService.exportAsExcelFile(this.creditNotePage.dataPrint, this.translateService.instant('ACCCDN100.M00030'), header, null, subTitle);
    this.creditNotePage.dataPrint.shift();
  }

  exportReportDetail(){
    let subTitle = [];
    subTitle.push([this.translateService.instant('ACCCDN100.M00030')]);
    let header: any = {
      creditNoteNo: this.translateService.instant('ACCCDN100.M00013'),
      creditNotetDate: this.translateService.instant('ACCCDN100.M00015'),
      coutryDesc: this.translateService.instant('ACCCDN100.M00004'),
      warehouseDesc: this.translateService.instant('ACCCDN100.M00006'),
      sellerDesc: this.translateService.instant('ACCCDN100.M00008'),
      creditNoteTypeDesc: this.translateService.instant('ACCCDN100.M00017'),
      perCenVat: this.translateService.instant('ACCCDN100.M00034'),
      creditNoteStatusDesc: this.translateService.instant('ACCCDN100.M00029'),
      confCreditNoteDate: this.translateService.instant('ACCCDN100.M00047'),
      createBy: this.translateService.instant('ACCCDN100.M00035'),
      excludingVatAmt: this.translateService.instant('ACCCDN100.M00036'),
      vatAmount: this.translateService.instant('ACCCDN100.M00037'),
      incVatTotalAmt: this.translateService.instant('ACCCDN100.M00033'),
      documentNo: this.translateService.instant('ACCCDN100.M00038'),
      documentDate: this.translateService.instant('ACCCDN100.M00039'),
      movementType: this.translateService.instant('ACCCDN100.M00040'),
      saleorderNo: this.translateService.instant('ACCCDN100.M00041'),
      qty: this.translateService.instant('ACCCDN100.M00042'),
      total: this.translateService.instant('ACCCDN100.M00043'),
      vat: this.translateService.instant('ACCCDN100.M00044'),
      totalTax: this.translateService.instant('ACCCDN100.M00045'),
      retrunMoneyDate: this.translateService.instant('ACCCDN100.M00048'),
      operationDate: this.translateService.instant('ACCCDN100.M00049'),
    }

    this.exportExcelService.exportAsExcelFile(this.creditNotePage.dataPrintDetail, this.translateService.instant('ACCCDN100.M00030'), header, null, subTitle);
    this.creditNotePage.dataPrintDetail.shift();
  }

  getVatData(vatCode :string) {
    this.creditNotService.getParameterPortal(200, this.creditNotePage.country).subscribe((response) => {
      let dataVat = JSON.parse(JSON.stringify(response));
        let vatSelect = dataVat.filter(data => data.value == vatCode);
        return vatSelect;
    });
  }

}
