import { Component, Renderer2 } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { UserCriteria, SearchCriteria, UserDataTable, DisableData, UserDataPrint } from "src/app/adm/model/admusr/admusr100.model";
import { Admusr100Service } from "src/app/adm/service/admusr/admusr100.service";
import { TranslateService } from '@ngx-translate/core';
import { AdmusrLocalStorageService } from "src/app/adm/service/admusr/admusrLocalStorage.service";
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { Router } from "@angular/router";
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';

@Component({
  selector: 'app-admusr100',
  templateUrl: './admusr100.component.html',
  styleUrls: ['./admusr100.component.scss']
})
export class Admusr100Component extends PermissionComponent {

  userCriteria: UserCriteria = {} as UserCriteria;
  userData: Array<UserDataTable> = []  as Array<UserDataTable>;
  userPrint: Array<UserDataPrint> = []  as Array<UserDataPrint>;
  tempUserData: Array<UserDataTable> = []  as Array<UserDataTable>;
  searchCriteria: SearchCriteria = {} as SearchCriteria;
  disableData: DisableData = {} as DisableData;
  inputSearchAll:string;
  translateLablePipe = new TranslateLablePipe();
  toggleSearch: boolean;
  firstRow: number = 0 as number;

  searchByUserGroupForm = new FormGroup({
    username: new FormControl(null),
    userDesc: new FormControl(null),
    profileId: new FormControl(null),
    organization: new FormControl(null),
  });

  constructor(
    private admusr100Service: Admusr100Service,
    private translateService: TranslateService,
    private localStorageService: AdmusrLocalStorageService,
    private exportExcelService: ExportExcelService,
    private router: Router,
    renderer2:Renderer2
  ) {
    super('P01003',renderer2);
    this.disableData.disableBtuClear = true;
    this.disableData.dataNotFoundGrid = true;
    this.disableData.disabledBtuPrinReport = true;
    this.localStorageService.clearUserData();
  }

  ngOnInit() {

    this.admusr100Service.getUserCriteria().subscribe((res: UserCriteria) => {
      this.userCriteria = res;
      this.userCriteria.profileData.sort((a, b) => (a.value - b.value));
    });

    this.searchByUserGroupForm.valueChanges.subscribe(data => {
      this.searchCriteria = data;
      this.disableData.disableBtuClear = false;
      this.disableData.disableBtuSearch = false;
    })

  }

  filterData() {

    this.searchCriteria.username = this.searchCriteria.username == undefined ? '' : this.searchCriteria.username;
    this.searchCriteria.userDesc = this.searchCriteria.userDesc == undefined ? '' : this.searchCriteria.userDesc;
    this.searchCriteria.organization = this.searchCriteria.organization == undefined ? []  : this.searchCriteria.organization;
    this.searchCriteria.profileId = this.searchCriteria.profileId == undefined ? []  : this.searchCriteria.profileId;

    this.admusr100Service.getUserInquiry(this.searchCriteria).subscribe((res: Array<UserDataTable>) => {
      this.userData = res;
      this.userData.sort((a, b) => (a.username > b.username) ? 1 : -1);
      this.tempUserData = this.userData;

      if (this.userData.length == 0) {
        this.disableData.dataNotFoundGrid = true;
        this.disableData.disabledBtuPrinReport = true;
      } else {
        this.disableData.disabledBtuPrinReport = false;
        this.disableData.dataNotFoundGrid = false;
        this.firstRow = 0;
      }

    });

    this.disableData.disableBtuSearch = true;

  }

  searchAuto() {

    this.userData = this.tempUserData;
    this.userData = this.userData.filter(data => {
      return (
        (data.username.toString().toLowerCase().indexOf(this.inputSearchAll.toLowerCase()) > -1) ||
        (data.userDesc.toString().toLowerCase().indexOf(this.inputSearchAll.toLowerCase()) > -1) ||
        (data.profileDesc.toString().toLowerCase().indexOf(this.inputSearchAll.toLowerCase()) > -1) ||
        (data.organizationDesc_eng.toString().toLowerCase().indexOf(this.inputSearchAll.toLowerCase()) > -1) ||
        (data.organizationDesc_local.toString().toLowerCase().indexOf(this.inputSearchAll.toLowerCase()) > -1)
      );
    });

    if (this.userData.length == 0) {
      this.disableData.dataNotFoundGrid = true;
    } else {
      this.disableData.dataNotFoundGrid = false;
      this.firstRow = 0;
    }
  }

  ClearData() {
    this.searchByUserGroupForm.reset();
    this.disableData.disableBtuSearch = false;
  }

  createUser() {
    this.router.navigate(['admusr/admusr200']);
  }

  exportReport(){

    this.userData.forEach(data => {
      this.userPrint.push({
        username:data.username,
        userDesc:data.userDesc,
        profileDesc:data.profileDesc,
        organizationDesc: this.translateLablePipe.transform(data.organizationDesc_eng,data.organizationDesc_local),
      })
    });

    let subTitle = [];
    subTitle.push([this.translateService.instant('ADMUSR100.M00002')]);
    let header: any = {
      username: this.translateService.instant('ADMUSR100.M00003'),
      userDesc: this.translateService.instant('ADMUSR100.M00018'),
      profileDesc: this.translateService.instant('ADMUSR100.M00022'),
      organizationDesc: this.translateService.instant('ADMUSR100.M00005'),
    }

    this.exportExcelService.exportAsExcelFile(this.userPrint, this.translateService.instant('ADMUSR100.M00002'), header, null, subTitle);

  }
}
