import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { element } from 'protractor';
import { ItemList, ItemInfo } from 'src/app/ord/model/sales-order-maintenance-model';
import { ItemSaleOrderModel } from 'src/app/ord/ordsor/ordsor200/model/sales-order-maintenance-model';
import { ItemService } from '../../services/item.service';
import { SalesOrderrService } from '../../services/sales-order-service';
import { ItemPriceRequest } from './model/info-item-model';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.scss']
})
export class SearchItemComponent implements OnInit {
  @Input() display: boolean;
  @Input() sellerCode: string;
  @Input() warehouse_code: string;
  @Input() flow_type_code: string;
  @Input() saleChannel: string;
  @Input() country: string;
  @Input() check_sale_price: boolean;
  @Input() orderDate: string;
  @Output() data: EventEmitter<ItemSaleOrderModel> = new EventEmitter<ItemSaleOrderModel>();
  hiddenButton: Boolean = true;
  hightLight: any;
  itemSellerCode: any;
  productCodeKT: any;
  productName: any;
  index: any
  dataNotFoundGridTab1: Boolean = true;
  profileModel: Array<ItemList> = [] as Array<ItemList>;
  infoItem: Array<ItemList> = [] as Array<ItemList>;
  infoItemAll: Array<ItemList> = [] as Array<ItemList>;
  itemInfo: ItemInfo = {} as ItemInfo;
  priceItemRequest: ItemPriceRequest = {} as ItemPriceRequest;
  firstRow: number = 0 as number;
  constructor(private saleorderService: SalesOrderrService,
    private itemService: ItemService) { }

  ngOnInit() {
    this.getDataItemAll();
  }

  getDataItemAll() {
    this.saleorderService.getItemList(this.sellerCode, this.isCheckNotEmptyWarehouse(this.warehouse_code) ? this.warehouse_code : 0, this.flow_type_code).subscribe(res => {
      this.infoItemAll = JSON.parse(JSON.stringify(res.data));
      if(this.check_sale_price){
        this.infoItemAll.forEach(element => {
          this.priceItemRequest.country = this.country;
          this.priceItemRequest.itemCode = element.item_code;
          this.priceItemRequest.saleChannel = this.saleChannel;
          this.priceItemRequest.sellerCode = this.sellerCode;
          this.priceItemRequest.orderDate = moment(this.orderDate).format('DD/MM/YYYY');
          this.saleorderService.getPriceItem(this.priceItemRequest).subscribe(responsePriceItem => {
            let priceItem = JSON.parse(JSON.stringify(responsePriceItem));
            if(priceItem.itemPrice != null){
              this.infoItem.push(element);
            }
          }, err => {
            console.log(err);
          });
        });
      }else{
        this.infoItem = this.infoItemAll;
      }
      if (this.profileModel.length == 0) {
        this.dataNotFoundGridTab1 = true;
      } else {
        this.dataNotFoundGridTab1 = false;
      }
    }, err => {
      console.log(err);
    });
  }

  getDataItem() {
    this.profileModel = this.infoItem;
    if (this.itemSellerCode) {
      this.profileModel = this.profileModel.filter(data => {
        if (data.item_product_code_seller && data.item_product_code_seller.toLowerCase().includes(this.itemSellerCode.toLowerCase())) {
          return true;
        }
        return false;
        // data.item_product_code_seller.toLowerCase().indexOf(this.sellerCode.toLowerCase()) > -1
      });
    }
    // this.profileModel = fillterItem;

    if (this.productCodeKT) {
      this.profileModel = this.profileModel.filter(data => {
        if (data.item_product_code_klangOne && data.item_product_code_klangOne.toLowerCase().includes(this.productCodeKT.toLowerCase())) {
          // data.item_product_code_klangOne.toLowerCase().indexOf(this.productCodeKT.toLowerCase()) > -1
          return true;
        }
        return false;
      });
    }

    // this.profileModel = fillterItem;

    if (this.productName) {
      
      this.profileModel = this.profileModel.filter(data => {
      
        return ( data.item_desc_eng.toLowerCase().indexOf(this.productName.toLowerCase()) > -1 ||
                 data.item_desc_local.toLowerCase().indexOf(this.productName.toLowerCase()) > -1 )
                 
      });
      
    }
    // this.profileModel = fillterItem;

    if (this.profileModel.length == 0) {
      this.dataNotFoundGridTab1 = true;
    } else {
      this.dataNotFoundGridTab1 = false;
      this.firstRow = 0;
    }

  }

  saveInfoItem(): void {
    this.itemService.getDataProductDetailByBarcode(this.profileModel[this.index].item_code).subscribe(res => {
      this.itemInfo = JSON.parse(JSON.stringify(res));

      this.priceItemRequest.country = this.country;
      this.priceItemRequest.itemCode = this.itemInfo.item_code;
      this.priceItemRequest.saleChannel = this.saleChannel;
      this.priceItemRequest.sellerCode = this.sellerCode;
      this.priceItemRequest.orderDate = moment(this.orderDate).format('DD/MM/YYYY');
      
      let cur_avai_stk : String = '0';
      let cur_bal_stk : String = '0';
      this.infoItemAll.filter(data => {
        if(data.item_code == this.itemInfo.item_code){
            if(data.cur_avai_stk != null){
                cur_avai_stk = data.cur_avai_stk;
            }
            if(data.cur_bal_stk != null){
                cur_bal_stk = data.cur_bal_stk;
            }
        }
      });
      
      this.saleorderService.getPriceItem(this.priceItemRequest).subscribe(responsePriceItem => {
        let priceItem = JSON.parse(JSON.stringify(responsePriceItem));
        this.data.emit({
          item_check: false,
          item_code: this.itemInfo.item_code,
          item_sellerCode: this.itemInfo.item_product_code_seller,
          item_KlangOneCode: this.itemInfo.item_product_code_klangOne,
          item_desc_eng: this.itemInfo.item_english,
          item_desc_local: this.itemInfo.item_local,
          item_unit: this.itemInfo.item_stock_unit,
          item_free_shipment_flag: priceItem.free_shipment_flag,
          item_quantity: null,

          item_height: this.itemInfo.item_height,
          item_length: this.itemInfo.item_lenght,
          item_weight: this.itemInfo.item_gross_weight,
          item_width: this.itemInfo.item_width,

          item_price_per_piece: priceItem.itemPrice,
          discountPercen: 0,
          discount_value: 0,
          total_price: 0,
          cur_bal_stk: cur_bal_stk,
          cur_avai_stk: cur_avai_stk
          
        });
      }, err => {
        console.log(err);
      });
    })

    this.display = false;
  }

  closeDialog() {
    this.display = false;
    this.data.emit();
  }

  selectRow(index) {
    this.hightLight = index;
    this.hiddenButton = false;
    this.index = index;
  }

  isCheckNotEmptyWarehouse(wh) {
    if (wh !== '' && wh !== undefined && wh !== null) {
      return true;
    } else {
      return false;
    }
  }
}
