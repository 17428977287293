import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';



@Pipe({
    name: 'translateLable',
})
export class TranslateLablePipe implements PipeTransform {

    private description: string = null;
    private country: string = null;

    constructor() {
        this.country = localStorage.getItem('languageData') as string;
    }

    transform(engDesc: string, localDesc: string): string {

        if (this.country == 'TH') {
            this.description = localDesc;
        } else {
            this.description = engDesc;
        }
        return this.description;
    }

}
