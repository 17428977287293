import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'translateDDL',
})

export class TranslateDDLPipe implements PipeTransform {

    private country: string;

    constructor() {
        this.country = localStorage.getItem('languageData') as string;
    }

    transform(optionsData: any[]): any[] {

        if (optionsData) {
            if (this.country == 'TH') {
                optionsData.forEach((data: any) => {
                    data.label = data.localDesc;
                });
            } else {
                optionsData.forEach((data: any) => {
                    data.label = data.engDesc;
                });
            }
        }
        return optionsData;
    }

}
