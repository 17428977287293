import { Component, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, SelectItem } from 'primeng/primeng';
import { TabView } from 'primeng/tabview';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { CustomerHistoryModel } from 'src/app/shared/model/customer-inquiry.model';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { Cuscus300Model, TempDDL } from './model/cuscus300.model';
import { Cuscus300Service } from './service/cuscus300.service';

@Component({
  selector: 'app-cuscus300',
  templateUrl: './cuscus300.component.html',
  styleUrls: ['./cuscus300.component.scss']
})
export class Cuscus300Component extends PermissionComponent {

  @ViewChild('tabView', { static: false }) tabView: TabView;
  page_model: Cuscus300Model;
  countryDisFalg: boolean = false;
  countryDDL: SelectItem[];
  optionSex: TempDDL[];
  optionOccupation: TempDDL[];
  searchForm = new FormGroup({
    keySearch: new FormControl(),
  });

  customer_info: MenuItem[];
  customer_detail: MenuItem[];
  customer_bySeller: MenuItem[];
  exportReportFlag: boolean;
  disabledCountry: boolean = false;
  disabledSaleChannel: boolean = false;
  dataCustomerReport: any = [];
  disableExportReport: boolean = false;
  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();
  translateLablePipe = new TranslateLablePipe();
  temp_data: Array<CustomerHistoryModel> = [];
  firstRow: number = 0 as number;
  EXCEL_FORMAT: string = 'EXCEL';
  CSV_FORMAT: string = 'CSV';
  occuDescTemp: TempDDL[];
  nationalityDescTemp : TempDDL[];
  language: string;
  types: any[];


  searchCustomerGroupForm = new FormGroup({
    searchklangOneMemberCode: new FormControl(),
    searchklangThaitMemberCode: new FormControl(),
    searchPhoneNumber: new FormControl(),
    searchCustomerFN: new FormControl(),
    searchCustomerLN: new FormControl(),
    searchRegion: new FormControl(),
    searchZipcode: new FormControl(),
    country: new FormControl(),
    seller: new FormControl(),
  });

  constructor(renderer2: Renderer2,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService,
    private service: Cuscus300Service,
    private parameterService: ParameterService,
    private autService: AuthenticationService,
    private router: Router,) {
    super('P50001', renderer2);
    this.page_model = this.service.load;
    this.page_model.searchFormSummitFlag = true;
    this.exportReportFlag= true;
    this.disabledSaleChannel = true;
  }
  ngOnInit() {
    this.ClearData();
    this.language = localStorage.languageData
    this.page_model.date.locale = this.translateCalendar;
    this.service.loadComponanceData(this.page_model);
    this.parameterService.getvparameterDetailToLable(4501).subscribe((response) => {
      this.optionSex = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });
    this.parameterService.getvparameterDetailToLable(4502).subscribe((response) => {
      this.optionOccupation = JSON.parse(JSON.stringify(response));
      this.occuDescTemp = JSON.parse(JSON.stringify(response));
    }, err => {
    console.log(err);
    });
    this.parameterService.getvparameterDetailToLable(4503).subscribe((response) => {
      this.nationalityDescTemp = JSON.parse(JSON.stringify(response));
    }, err => {
    console.log(err);
    });
    this.searchForm.valueChanges.subscribe(() => {
      if (this.searchForm.valid) {
        this.advanceSearchFunction();
      } else {
        this.page_model.customer_inquiry_list = this.page_model.tmp_data;
      }
    });

    this.autService.getParameterWmsCountry().subscribe(response => {
      this.countryDDL = JSON.parse(JSON.stringify(response));
    });
  }

  searchFunction() {
    this.service.searchFunction().subscribe((CustomerList: Array<CustomerHistoryModel>) => {
      this.page_model.customer_inquiry_list = CustomerList;

      const parseDate = (dateString: string): Date => {
        const [date, time] = dateString.split(' ');
        const [day, month, year] = date.split('/').map(Number);
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds);
      };

      this.page_model.customer_inquiry_list = this.page_model.customer_inquiry_list.sort((a, b) => {
          return parseDate(b.updateDate).getTime() - parseDate(a.updateDate).getTime();
      });

      this.page_model.customer_inquiry_list.forEach((his) => {
        if (his.updateField === 'customer_occupertion') {
          if (this.occuDescTemp) {
            this.occuDescTemp.find((data) => data.value === his.lastData);
            const matchingDataLast = this.occuDescTemp.find((data) => data.value.parameterDetailPK.pmdtbdtbdentcd === his.lastData
            );
            if (matchingDataLast) {
              his.lastData = this.language === 'TH' ? `${matchingDataLast.localDesc}` : `${matchingDataLast.engDesc}`;
            }
          }
          const matchingDataNew = this.occuDescTemp.find((data) => data.value.parameterDetailPK.pmdtbdtbdentcd === his.newData);
          if (matchingDataNew) {
            his.newData = this.language === 'TH' ? `${matchingDataNew.localDesc}` : `${matchingDataNew.engDesc}`;
          }
        } else if (his.updateField === 'customer_sex') {
          if (this.optionSex) {
            this.optionSex.find((data) => data.value === his.lastData);
            const matchingDataLast = this.optionSex.find((data) => data.value.parameterDetailPK.pmdtbdtbdentcd === his.lastData
            );
            if (matchingDataLast) {
              his.lastData = this.language === 'TH' ? `${matchingDataLast.localDesc}` : `${matchingDataLast.engDesc}`;
            }
          }
          const matchingDataNew = this.optionSex.find((data) => data.value.parameterDetailPK.pmdtbdtbdentcd === his.newData);
          if (matchingDataNew) {
            his.newData = this.language === 'TH' ? `${matchingDataNew.localDesc}` : `${matchingDataNew.engDesc}`;
          }
        } else if (his.updateField === 'customer_status') {
          this.types = [];
          this.types.push({ label: this.translateService.instant('CUSCUS200.M00038'), value: 'true' });
          this.types.push({ label: this.translateService.instant('CUSCUS200.M00037'), value: 'false' });
          if (this.types) {
            this.types.find((data) => data.value === his.lastData);
            const matchingDataLast = this.types.find((data) => data.value === his.lastData
            );
            if (matchingDataLast) {
              his.lastData = `${matchingDataLast.label}`;
            }
          }
          const matchingDataNew = this.types.find((data) => data.value === his.newData);
          if (matchingDataNew) {
            his.newData = `${matchingDataNew.label}`
          }
        } else if (his.updateField === 'customer_country') {
          const matchingDataLast = this.countryDDL.find((data) => data.value === his.lastData);
          if (matchingDataLast) {
            his.lastData = `${matchingDataLast.label}`;
          }
          const matchingDataNew = this.countryDDL.find((data) => data.value === his.newData);
          if (matchingDataNew) {
            his.newData = `${matchingDataNew.label}`;
          }
        } else if (his.updateField === 'customer_nationality') {
          if (this.nationalityDescTemp) {
            this.nationalityDescTemp.find((data) => data.value === his.lastData);
            const matchingDataLast = this.nationalityDescTemp.find((data) => data.value.parameterDetailPK.pmdtbdtbdentcd === his.lastData
            );
            if (matchingDataLast) {
              his.lastData = this.language === 'TH' ? `${matchingDataLast.localDesc}` : `${matchingDataLast.engDesc}`;
            }
          }
          const matchingDataNew = this.nationalityDescTemp.find((data) => data.value.parameterDetailPK.pmdtbdtbdentcd === his.newData);
          if (matchingDataNew) {
            his.newData = this.language === 'TH' ? `${matchingDataNew.localDesc}` : `${matchingDataNew.engDesc}`;
          }
        }
      })

      this.page_model.customer_inquiry_list = JSON.parse(JSON.stringify(CustomerList));
      this.page_model.tmp_data = this.page_model.customer_inquiry_list;
      this.firstRow = 0;
    }, error => {
      console.log(error);
    });
  }

  advanceSearchFunction() {
    if (this.searchForm.valid) {
      const key_search: string = this.searchForm.get('keySearch').value;
      if (key_search) {
        this.page_model.customer_inquiry_list = this.page_model.tmp_data.filter((item: CustomerHistoryModel) => {
          const concatValue: string = `${item.firstname}
          ${item.lastname}
          ${item.kpmenId}
          ${item.kpdfmemid}
          ${item.updateDate}
          ${item.updateUser}
          ${item.lastData}
          ${item.newData}
          ${item.updateField}`;
          return concatValue.toLowerCase().indexOf(key_search.toLowerCase()) !== -1;
        });
        this.firstRow = 0;
      }
      else {
        this.page_model.customer_inquiry_list = this.page_model.tmp_data;
      }
    }
  }

  ClearData() {
    this.exportReportFlag = true;
    this.page_model.searchFormSummitFlag = true;
    this.page_model.customer_inquiry_list = null;
    this.page_model.form_model.criterial_form.reset();
    if ( this.page_model.data_list.country_data.length === 1 ) {
      this.page_model.form_model.criterial_form.patchValue({
        country:  this.page_model.data_list.country_data[0].value
      });
    }
    this.page_model.form_model.search_form.patchValue({
      customerId: "",
      kpmenId: "",
      kpdfmemid: "",
      firstname: "",
      updateDate: "",
      updateUser: "",
      phoneNumber: ""
    });
  }

  exportReport() {
    const subTitle = [];
    subTitle.push([this.translateService.instant('CUSCUS300.M00008')]);
    const header: any = {
      customer_kp_id: this.translateService.instant('CUSCUS100.M00001'),
      customer_kpdef_id: this.translateService.instant('CUSCUS100.M00002'),
      customer_firstname: this.translateService.instant('CUSCUS100.M00033'),
      customer_lastname: this.translateService.instant('CUSCUS100.M00007'),
      customer_updateDate: this.translateService.instant('CUSCUS300.M00003'),
      customer_updateUser: this.translateService.instant('CUSCUS300.M00004'),
      customer_chageField: this.translateService.instant('CUSCUS300.M00005'),
      customer_userLastEdit: this.translateService.instant('CUSCUS300.M00006'),
      customer_userNewEdit: this.translateService.instant('CUSCUS300.M00007')
    };
    this.dataCustomerReport = [];
    this.page_model.customer_inquiry_list.forEach((element) => {
      const dataReport = {
        customer_kp_id: element.kpmenId,
        customer_kpdef_id: element.kpdfmemid,
        customer_firstname: element.firstname,
        customer_lastname: element.lastname,
        customer_updateDate: element.updateDate,
        customer_updateUser: element.updateUser,
        customer_chageField: element.updateField,
        customer_userLastEdit: element.lastData,
        customer_userNewEdit: element.newData,
      };
      this.dataCustomerReport.push(dataReport);
    });
    this.exportExcelService.exportAsExcelFile(this.dataCustomerReport, this.translateService.instant('CUSCUS300.M00008'), header, null, subTitle);
    this.dataCustomerReport.shift();
  }

}
