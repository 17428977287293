import { Component, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService, SelectItem } from 'primeng/api';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { DecimalData, paymentDetail, paymentRecords, saveHeaderPaymentRequest, TempDDL } from '../../model/payment-data-model';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { DecimalPipe } from '@angular/common';
import { Big } from 'big.js';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-fnapay200',
  templateUrl: './fnapay200.component.html',
  styleUrls: ['./fnapay200.component.scss']
})
export class Fnapay200Component extends PermissionComponent {

  // paymentDetailData = {} as PaymentDetail;
  payDetail = {} as paymentDetail;
  payRecords = {} as paymentRecords;
  paySaveHdr = {} as saveHeaderPaymentRequest;
  public decimalData:DecimalData = {} as DecimalData;
  translateLablePipe = new TranslateLablePipe();

  paymentHeaderForm = new FormGroup({
    saleOrderNumber: new FormControl({ value: '', disabled: true }, Validators.required),
    saleOrderDate: new FormControl({ value: '', disabled: true }, Validators.required),
    warehouseCode: new FormControl({ value: '', disabled: true }, Validators.required),
    warehouseDesc: new FormControl({ value: '', disabled: true }, Validators.required),
    country: new FormControl({ value: '', disabled: true }, Validators.required),
    customerName: new FormControl({ value: '', disabled: true }, Validators.required),
    customerID: new FormControl({ value: '', disabled: true }, Validators.required),
    currency: new FormControl({ value: '', disabled: true }, Validators.required),
    flowType: new FormControl({ value: '', disabled: true }, Validators.required),
    paymentMode: new FormControl({ value: '', disabled: true }, Validators.required),
    saleOrderAmount: new FormControl({ value: '', disabled: true }, Validators.required),
    confirmedPaymentDate: new FormControl({ value: '', disabled: true }, Validators.required),
    confirmedPaymentUser: new FormControl({ value: '', disabled: true }, Validators.required),
    paymentStatus: new FormControl({ value: '', disabled: true }, Validators.required),
    confirmShipmentStatus: new FormControl({ value: '', disabled: true }, Validators.required),
    paymentedAmount: new FormControl({ value: '', disabled: true }, Validators.required),
    invoiceNo: new FormControl(),
    receiptNo: new FormControl(),
    arrearage: new FormControl({ value: '', disabled: true }, Validators.required),
    confirmDate: new FormControl({ value: '', disabled: true }),
    confirmUser: new FormControl({ value: '', disabled: true }),
    seller: new FormControl({ value: '', disabled: true }),
    saleChannel: new FormControl({ value: '', disabled: true }),
    saleChannelCategory: new FormControl({ value: '', disabled: true }),
  });
  paymentDeatilForm = new FormGroup({
    saleOrderNumber: new FormControl({ value: '', disabled: true }, Validators.required),
    creator: new FormControl({ value: '', disabled: true }, Validators.required),
    country: new FormControl({ value: '', disabled: true }, Validators.required),
    currency: new FormControl({ value: '', disabled: true }, Validators.required),
    paymentChannel: new FormControl(Validators.required),
    bankAccount: new FormControl(Validators.required),
    paymentDate: new FormControl({ value: '', disabled: false }, Validators.required),
    operationDate: new FormControl({ value: '', disabled: true }, Validators.required),
    paymentAmount: new FormControl(Validators.required),
    amountToBePaid: new FormControl({ value: '', disabled: true }, Validators.required),
    remark: new FormControl(),
    paymentImage: new FormControl(),
    arrearage: new FormControl({ value: '', disabled: true })
  });

  payTable = [];
  optionChannel: TempDDL[];
  optionBank: TempDDL[];
  bankTran: any[];
  defaultData: any;
  showPanel: boolean = false;
  uploadedFiles: any[] = [];
  display: boolean = false;
  dataRecords: any = [];
  dataSrc: any;
  showdialog: boolean = false;
  isDisabledPayment: boolean = false;
  isDisabledShipment: boolean = false;
  disabledAll: boolean = false;
  disabledBtuEdit:boolean;
  disabledBtuDelete:boolean;
  disabled
  bankAccount: any;
  currency = { currencyCd: '', currencyDesc: '' };
  saleOrderNo: any;
  warehouseCode: any;
  arrearage: any;
  messageAlert: String;
  prmdtl: any;
  tempRow: any;
  defaultDate: String;
  paymentedAmt: number = 0;
  currentDate: Date;
  channelSelection: any;
  bankSelection: any;
  img: any;
  opDate: Date;
  nowDate: string;
  base64Data: any;
  converted_image: any;
  converted_img: any;
  selectRow: any;
  userCode: any;
  logData: any;
  formUplode: any;
  countyCode: string;
  params:any;
  listSaleOrderNumber:any = [];
  listWarehouse:any = [];
  listPaymentStatus:any=[];
  disableBank : boolean = false;
  valuenull: boolean = false;
  statusCancel: boolean = false;
  urlBlob:any;
  disableSaveHdr: boolean = true;
  prmCurrency:any;
  private base64textString: String = "";
  status: boolean;
  maxDate: Date;
  payment:any;
  channel:any;
  uploadFlag = false as boolean;

  typeDecimalPurchase: string = '1';
  typeDecimalSelling: string = '2';
  typeDecimalRoundMethod: string = '3';
  typeDecimalRoundToValue: string = '4';

  method : string = "0";
  roundToDecimal : number = 0;
  decimal : number = 0;

  numberDecimal:any;
  public user = {} as AuthDataModel;
  language: String;

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  constructor(
    private router: Router,
    private messageService: MessageService,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private parameterwms: ParameterWmsSerive,
    private parameterService: ParameterService,
    private decimalService: DecimalService,
    private decimalPipe: DecimalPipe,
    renderer2:Renderer2) {
    super('P32101',renderer2);
    this.display = false;
    this.saleOrderNo = this.route.snapshot.paramMap.get('saleOrderNumber');
    this.warehouseCode = route.snapshot.paramMap.get('warehouseCode');
    this.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel
    this.userCode = this.user.userName ? this.user.userName : this.user.userDesc;
    this.status = false;
    this.language = localStorage.getItem('languageData') as string;

  }

  ngOnInit() {
    setTimeout(() => {
        this.getDataToTable();
        this.checkPermission();
    }, 1200);
    this.parameterwms.getParameterWms(9144).subscribe((res) => {
      this.payment = res;
    })
    this.parameterService.getvparameterDetailToLable(4300).subscribe((res) => {
      this.channel = res;
    })
  }

  checkPermission(){
    let checkPermissionEdit = this.permissionData.accActionFalse.filter(act =>act == "1");
    if(checkPermissionEdit.length > 0){
      this.disabledBtuEdit = true;
    }
    let checkPermissionDelete = this.permissionData.accActionFalse.filter(act =>act == "3");
    if(checkPermissionDelete.length > 0){
      this.disabledBtuDelete = true;
    }
  }

  getDataToTable(){
    this.display = false;
    this.paymentService.getpaymentInquiry(this.saleOrderNo,this.warehouseCode).subscribe(async (response) => {
      this.payDetail = await JSON.parse(JSON.stringify(response));
      this.parameterService.getvprmdtltolableByCountry(104, this.payDetail.countryCode).subscribe((response) => {
        let decimalData = JSON.parse(JSON.stringify(response));
        this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalPurchase);
        this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalSelling);
        this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalRoundMethod);
        this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalRoundToValue);
        this.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
        this.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
        this.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
      }, err => {
        console.log(err);
      });
      if (this.payDetail.confirmedPaymentDate != null) {
        this.payDetail.confirmedPaymentDate = moment(this.payDetail.confirmedPaymentDate).format('DD/MM/YYYY HH:mm:ss')
      }
      if (this.payDetail.detail.length > 0) {
        JSON.parse(JSON.stringify(this.payDetail.detail)).forEach(element => {
          this.paymentedAmt = this.paymentedAmt + element.paymentAmount;
          this.base64textString = element.paymentImage;
          this.converImg(this.base64textString);
          this.payTable.push({
            payID: element.paymentID,
            aacceptPayment: element.paymentDate,
            paymentRecord: element.operationDate,
            channel: this.translateLablePipe.transform(element.paymentChannel_eng,element.paymentChannel_local),
            bankTran: this.translateLablePipe.transform(element.bankAccount_eng,element.bankAccount_local),
            remark: element.remark,
            converted_image: this.converted_img,
            amt: element.paymentAmount,
            pBy: element.creator,
            confirmPay: this.payDetail.confirmedPaymentDate,
            confirmBy: this.payDetail.confirmedPaymentUser,
            chanCode: element.channelCode,
            bankCode: element.bankAccountCode,
          });
          this.converImg(null);
        });
      }
      this.checkstatus();
      this.parameterwms.getParameterWmsBytableNoAndCode(105, this.payDetail.countryCode).subscribe((responseParam) => {
        let respones = JSON.parse(JSON.stringify(responseParam));
        this.parameterwms.getParameterWmsBytableNoAndCode(9146, respones[0].parameterData.parameter_v6).subscribe((currencyResult) => {
          let currency = JSON.parse(JSON.stringify(currencyResult));
          this.paymentHeaderForm.controls['currency'].setValue(this.translateLablePipe.transform(currency[0].engDesc,currency[0].localDesc));
          this.prmCurrency = this.translateLablePipe.transform(currency[0].engDesc,currency[0].localDesc);
        });
        this.paymentService.getVprmTableByPrmno(4300).subscribe(response => {
            this.optionChannel = JSON.parse(JSON.stringify(response));
        });
        this.paymentService.getVprmTableByCountry(4302, this.payDetail.countryCode).subscribe((response) => {
            this.optionBank = JSON.parse(JSON.stringify(response));
        });
      });
      this.paymentHeaderForm.controls['confirmShipmentStatus'].setValue(this.translateLablePipe.transform(this.payDetail.confirmShipmentStatus_eng,this.payDetail.confirmShipmentStatus_local));
      this.paymentHeaderForm.controls['saleOrderNumber'].setValue(this.payDetail.saleOrderNumber);
      this.paymentHeaderForm.controls['warehouseCode'].setValue(this.payDetail.warehouseCode);
      this.paymentHeaderForm.controls['warehouseDesc'].setValue(this.translateLablePipe.transform(this.payDetail.warehouseDes_eng,this.payDetail.warehouseDes_local));
      this.paymentHeaderForm.controls['country'].setValue(this.translateLablePipe.transform(this.payDetail.country_eng,this.payDetail.country_local));
      this.paymentHeaderForm.controls['customerID'].setValue(this.payDetail.customerID);
      this.paymentHeaderForm.controls['customerName'].setValue(this.payDetail.customerName);
      this.paymentHeaderForm.controls['flowType'].setValue(this.translateLablePipe.transform(this.payDetail.flowType_eng,this.payDetail.flowType_local));
      this.paymentHeaderForm.controls['paymentMode'].setValue(this.translateLablePipe.transform(this.payDetail.paymentMode_eng,this.payDetail.paymentMode_local));
      this.paymentHeaderForm.controls['saleOrderAmount'].setValue(this.payDetail.saleOrderAmount);
      this.paymentHeaderForm.controls['paymentStatus'].setValue(this.translateLablePipe.transform(this.payDetail.paymentStatus_eng,this.payDetail.paymentStatus_local));
      this.paymentHeaderForm.controls['paymentedAmount'].setValue(this.paymentedAmt);
      this.paymentHeaderForm.controls['invoiceNo'].setValue(this.payDetail.invoiceNo);
      this.paymentHeaderForm.controls['receiptNo'].setValue(this.payDetail.receiptNo);
      this.paymentHeaderForm.controls['arrearage'].setValue((Number(this.paymentHeaderForm.controls['saleOrderAmount'].value) - Number(this.paymentHeaderForm.controls['paymentedAmount'].value)));
      this.paymentHeaderForm.controls['confirmDate'].setValue(this.payDetail.confirmedPaymentDate);
      this.paymentHeaderForm.controls['confirmUser'].setValue(this.payDetail.confirmedPaymentUser);
      this.paymentHeaderForm.controls['seller'].setValue(this.payDetail.sellerCode + " - " + this.translateLablePipe.transform(this.payDetail.seller_eng, this.payDetail.seller_local));
      this.paymentHeaderForm.controls['saleChannel'].setValue(this.payDetail.sale_channel + " - " + this.translateLablePipe.transform(this.payDetail.sales_channel_desc_eng, this.payDetail.sales_channel_desc_local));
      this.paymentHeaderForm.controls['saleChannelCategory'].setValue(this.payDetail.sales_channel_category_code + " - " + this.translateLablePipe.transform(this.payDetail.sales_channel_category_desc_eng, this.payDetail.sales_channel_category_desc_local));
      this.payment = this.payment.filter(data => data.parameterData.entry_code == this.payDetail.paymentModeCode.substring(0,1))

    }, err => {
      console.log(err);
    });


  }

  myUploader(event, form) {
    this.converted_img = "";
    form.clear();
    let file = event.files[0];
    if (file.size < 1048576) {
      if (event.files && file) {
        let reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    } else {
      this.confirmMessage(this.translateService.instant('FNAPAY200.M00044'));
    }

  }

  handleReaderLoaded(readerEvt) {
    let binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.converImg(this.base64textString);
  }

  converImg(img) {
    this.base64Data = img;
    if (this.base64Data == null) {
      this.converted_img = ""
    } else {
      this.converted_img = "data:image/jpeg;base64," + this.base64Data;
    }
  }

  backBtn() {
    if(this.paymentHeaderForm.controls['invoiceNo'].value != this.payDetail.invoiceNo ||
       this.paymentHeaderForm.controls['receiptNo'].value != this.payDetail.receiptNo) {
        this.messageService.add({key: 'backpage', sticky: true, severity:'warn', summary:this.translateService.instant('FNAPAY200.M00045'), detail: this.translateService.instant('FNAPAY200.M00046')});
       } else {
        this.router.navigate(['/fnapay/fnapay100']);
       }
  }

  changebtn(){
    if(this.paymentHeaderForm.controls['invoiceNo'].value != this.payDetail.invoiceNo ||
       this.paymentHeaderForm.controls['receiptNo'].value != this.payDetail.receiptNo) {
        this.disableSaveHdr = false;
      } else {
       this.disableSaveHdr = true;
      }
  }

  backpage(){
    this.router.navigate(['/fnapay/fnapay100']);
  }


  decimalPaymentAmount(value){
    this.paymentDeatilForm.controls['paymentAmount'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.paymentDeatilForm.controls['paymentAmount'].value.toString().replace(/,/g, ""), this.method, this.roundToDecimal,this.decimal), '1.' + this.decimal + '-' + this.decimal, 'en'));
    }

  addList(form) {
    this.maxDate = new Date();
    let newDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    this.nowDate = newDate;
    this.display = true;
    this.paymentDeatilForm.controls['saleOrderNumber'].setValue(this.payDetail.saleOrderNumber);
    this.paymentDeatilForm.controls['country'].setValue(this.translateLablePipe.transform(this.payDetail.country_eng,this.payDetail.country_local));
    this.paymentDeatilForm.controls['currency'].setValue(this.prmCurrency);
    this.paymentDeatilForm.controls['amountToBePaid'].setValue(this.decimalService.getRoundingDecimal(new Big(this.payDetail.saleOrderAmount), this.method, this.roundToDecimal,this.decimal));
    this.paymentDeatilForm.controls['operationDate'].setValue(this.nowDate);
    this.base64textString = null;
    this.payRecords.paymentID = null;
    this.payRecords.paymentHeaderID = null;
    this.paymentDeatilForm.controls.paymentChannel.reset();
    this.paymentDeatilForm.controls.bankAccount.reset();
    this.paymentDeatilForm.controls.paymentDate.reset();
    this.paymentDeatilForm.controls.paymentAmount.reset();
    this.paymentDeatilForm.controls.remark.reset();
    this.paymentDeatilForm.controls['creator'].setValue(this.userCode);
    this.paymentDeatilForm.controls['bankAccount'].enable();
    this.converted_img = "";
    this.paymentDeatilForm.controls['arrearage'].setValue(this.paymentHeaderForm.controls['arrearage'].value);
  }

  checkstatus() {
    if(this.payDetail.saleOrderStatus == "3" || this.payDetail.saleOrderStatus == "1" || this.payDetail.paymentStatusCode == "3"){
      this.statusCancel = true;
      this.isDisabledShipment = true;
      this.isDisabledPayment = true;
      this.disabledAll = true;
      this.disabledBtuDelete = true;
        this.disabledBtuEdit = true;
      this.paymentHeaderForm.controls['invoiceNo'].disable();
      this.paymentHeaderForm.controls['receiptNo'].disable();
    } else {
      // setTimeout(() => {
        if (this.payDetail.paymentStatusCode === "1" && this.payDetail.shipmentStatusCode === "300") {//สำเร็จทั้งหมด
            this.isDisabledPayment = true;
            this.isDisabledShipment = true;
            this.disabledAll = true;
            this.disabledBtuDelete = true;
            this.disabledBtuEdit = true;
        } else if (this.payDetail.paymentStatusCode === "1" && this.payDetail.shipmentStatusCode === "200"){
          this.isDisabledPayment = true;
          this.disabledAll = true;
          this.disabledBtuDelete = true;
          this.disabledBtuEdit = true;
        } else {
          if (this.payTable.length > 0) {
            if (this.payDetail.paymentModeCode == '200') { //prepaid
              // ยืนยันการชำระ -> [x] ชำระ -> ยืนยันการจัดส่ง -> [x] จัดส่ง
              if (this.payDetail.paymentStatusCode == "1") {
                this.isDisabledPayment = true;
                this.isDisabledShipment = false;
                // this.disabledAll = true;
              } else {
                this.isDisabledPayment = false;
                this.isDisabledShipment = true;
              }
            } else { // COD
              if (this.payDetail.shipmentStatusCode != "300") {
                if (this.payDetail.flowTypeCode == '3') { //pre-Order
                  // ยืนยันการชำระ -> [x] ชำระ -> ยืนยันการจัดส่ง -> [x] จัดส่ง
                  this.isDisabledPayment = true;
                  this.isDisabledShipment = false;
                }
              } else {
                  this.isDisabledPayment = false;
                  this.isDisabledShipment = true;
              }
            }
          } else {
            this.isDisabledPayment = true;
            this.isDisabledShipment = true;
          }
        }
      // }, 1400);
    }
  }

  paymentChannelCheck(channel){
    if(channel.value == "200"){
      this.paymentDeatilForm.controls['bankAccount'].disable();
      this.paymentDeatilForm.controls['bankAccount'].reset();
    } else {
      this.paymentDeatilForm.controls['bankAccount'].enable();
    }
    let checkChannel = this.channel.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == channel.value)
    if(this.payment[0].parameterData.parameter_v3 == 'Y' && checkChannel[0].value.pmdtbdtxtv1 == 'Y'){
      this.uploadFlag = true;
    } else{
      this.uploadFlag = false;
    }
  }

  checkvaluenull(paymentChannel){
    if(this.uploadFlag == true){
      if(paymentChannel == 100){ //banktranfer
        if(this.paymentDeatilForm.controls['bankAccount'].value == null || this.paymentDeatilForm.controls['paymentDate'].value == null ||
        this.paymentDeatilForm.controls['paymentAmount'].value == null || this.converted_img == null || this.base64Data == null ){
            this.valuenull = true;
        }else{
          this.valuenull = false;
        }
      }else if(paymentChannel == 200){ //cash
        if(this.paymentDeatilForm.controls['paymentDate'].value == null || this.paymentDeatilForm.controls['paymentAmount'].value == null  ||
        this.converted_img == null || this.base64Data == null ){
          this.valuenull = true;
        }else{
          this.valuenull = false;
        }
      }else{
        this.valuenull = true;
      }
    } else{
      if(paymentChannel == 100){ //banktranfer
        if(this.paymentDeatilForm.controls['bankAccount'].value == null || this.paymentDeatilForm.controls['paymentDate'].value == null ||
        this.paymentDeatilForm.controls['paymentAmount'].value == null ){
            this.valuenull = true;
        }else{
          this.valuenull = false;
        }
      }else if(paymentChannel == 200){ //cash
        if(this.paymentDeatilForm.controls['paymentDate'].value == null || this.paymentDeatilForm.controls['paymentAmount'].value == null ){
          this.valuenull = true;
        }else{
          this.valuenull = false;
        }
      }else{
        this.valuenull = true;
      }
    }
  }

  setPaymentHeader(){
    this.paySaveHdr.sellerCode = this.payDetail.sellerCode;
    this.paySaveHdr.saleOrderNumber = this.paymentHeaderForm.controls['saleOrderNumber'].value;
    this.paySaveHdr.saleOrderDate = this.payDetail.saleOrderDate.toString();
    this.paySaveHdr.warehouseCode = this.payDetail.warehouseCode.toString();
    this.paySaveHdr.country = this.payDetail.countryCode;
    this.paySaveHdr.customerID = this.payDetail.customerCode;
    this.paySaveHdr.currency = this.payDetail.currencyCode;
    this.paySaveHdr.flowType = this.payDetail.flowTypeCode;
    this.paySaveHdr.paymentMode = this.payDetail.paymentModeCode;
    this.paySaveHdr.saleOrderAmount = this.payDetail.saleOrderAmount;
    this.paySaveHdr.paymentStatus = this.payDetail.paymentStatusCode;
    this.paySaveHdr.confirmShipmentStatus = this.payDetail.shipmentStatusCode;
    this.paySaveHdr.paymentedAmount = this.paymentHeaderForm.controls['paymentedAmount'].value;
    this.paySaveHdr.invoiceNo = this.paymentHeaderForm.controls['invoiceNo'].value;
    this.paySaveHdr.receiptNo = this.paymentHeaderForm.controls['receiptNo'].value;
    this.paySaveHdr.lastUpdateUser = this.userCode;
  }

  savePaymentDetail(flag) {
    if (flag == 1) { // save header
      this.setPaymentHeader();
      this.paymentService.postPaymentHeader(this.paySaveHdr).subscribe();
      window.location.reload();
    } else if (flag == 2) { // save detail
      this.checkvaluenull(this.paymentDeatilForm.controls['paymentChannel'].value);
      if (this.valuenull == true) {
        this.messageService.add({ key: 'm', sticky: true, severity: 'error', summary: this.translateService.instant('FNAPAY200.M00049'), detail: this.translateService.instant('FNAPAY200.M00048') });
      } else if (this.valuenull == false) {
        this.payRecords.paymentHeaderID = this.payDetail.paymentID;
        this.payRecords.sellerCode = this.payDetail.sellerCode;
        this.payRecords.saleOrderNumber = this.paymentDeatilForm.controls['saleOrderNumber'].value;
        this.payRecords.creator = this.paymentDeatilForm.controls['creator'].value;
        this.payRecords.country = this.payDetail.countryCode;
        this.payRecords.currency = this.payDetail.currencyCode;
        this.payRecords.bankAccount = this.paymentDeatilForm.controls['bankAccount'].value;
        this.payRecords.paymentChannel = this.paymentDeatilForm.controls['paymentChannel'].value;
        if (this.paymentDeatilForm.controls['paymentDate'].value == null) {
          this.payRecords.paymentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString();
        } else {
          this.payRecords.paymentDate = moment(this.paymentDeatilForm.controls['paymentDate'].value).format('YYYY-MM-DD HH:mm:ss').toString();
        }
        this.payRecords.operationDate = this.paymentDeatilForm.controls['operationDate'].value;
        this.payRecords.paymentAmount =  Number(this.paymentDeatilForm.controls['paymentAmount'].value.replace(/,/g,""));
        this.payRecords.amountToBePaid = Number(this.paymentDeatilForm.controls['amountToBePaid'].value);
        this.payRecords.remark = this.paymentDeatilForm.controls['remark'].value;
        this.payRecords.paymentImage = this.base64Data
        this.payRecords.lastUpdateUser = this.userCode;
        this.converted_img = "";
        this.payRecords.paymentedAmount = Number((this.paymentHeaderForm.controls['paymentedAmount'].value) + Number(this.paymentDeatilForm.controls['paymentAmount'].value.replace(/,/g,"")))
        this.payRecords.warehouseCode = this.payDetail.warehouseCode;
        this.payRecords.paymentMode = this.payDetail.paymentModeCode;
        this.payRecords.flowType = this.payDetail.flowTypeCode;
        if(this.payRecords.paymentHeaderID == null){
          this.setPaymentHeader();
          this.paymentService.postPaymentHeader(this.paySaveHdr).subscribe(res => {
            this.paymentService.postPaymentDetail(this.payRecords).subscribe(res => {
              let result = JSON.parse(JSON.stringify(res));
              if(result == null){
                this.messageService.add({ key: 'mes', sticky: true, severity: 'error', detail: this.translateService.instant('FNAPAY200.M00049') });
              }else{
                this.display = false;
                this.messageService.add({ key: 'alert', severity: 'success', detail: this.translateService.instant('FNAPAY200.M00057') });
                setTimeout(() => {
                  window.location.reload();
                }, 500);
              }
            });
          });
        } else {
        this.paymentService.postPaymentDetail(this.payRecords).subscribe(res => {
          let result = JSON.parse(JSON.stringify(res));
          if(result == null){
            this.messageService.add({ key: 'mes', sticky: true, severity: 'error', detail: this.translateService.instant('FNAPAY200.M00049') });
          }else{
            this.display = false;
            this.messageService.add({ key: 'alert', severity: 'success', detail: this.translateService.instant('FNAPAY200.M00057') });
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        });
        }
      }
    }
  }

  checkvalueclose(){
    if(this.paymentDeatilForm.controls['paymentChannel'].value != null || this.paymentDeatilForm.controls['bankAccount'].value != null  ||
       this.paymentDeatilForm.controls['paymentDate'].value != null ||
       this.paymentDeatilForm.controls['paymentAmount'].value != null || this.base64Data != null){
      this.valuenull = false;
    }else{
      this.valuenull = true;
    }
  }

  closedialog(message){
    this.checkvalueclose();
    if(this.valuenull == false){
    this.messageService.clear();
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: this.translateService.instant('FNAPAY200.M00047'), detail:this.translateService.instant(message) });
    this.messageAlert = this.translateService.instant(message);
    }else if(this.valuenull == true){
      this.display = false;
    }
  }

  showSuccess(message: string) {
    this.messageService.clear();
    this.messageService.add({ key: 'alert', severity: 'success', detail: 'FNAPAY200.M00057' });
  }

  confirmMessage(message: string) {
    this.messageService.clear();
    this.messageService.add({ key: 'mes', sticky: true, severity: 'error', detail: message });
  }

  confirmDelete(message: string, row) {
    this.messageService.clear();
    this.messageService.add({ key: 'de', sticky: true, severity: 'warn', summary: this.translateService.instant('FNAPAY200.M00051'), detail: this.translateService.instant(message)});
    this.selectRow = row;
  }

  confirmInfo(message: string) {
    this.messageService.clear();
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: this.translateService.instant('FNAPAY200.M00047'), detail: this.translateService.instant(message)});
    this.messageAlert = this.translateService.instant(message);
  }

  confirmShipment(message: string) {
    this.messageService.clear();
    this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: this.translateService.instant('FNAPAY200.M00047'), detail: this.translateService.instant(message)});
    this.messageAlert = this.translateService.instant(message);
  }

  confirmArrearageInfo(message: string) {
    this.messageService.clear();
    this.messageService.add({ key: 'ca', sticky: true, severity: 'warn', summary: this.translateService.instant('FNAPAY200.M00047'), detail: message });
    this.messageAlert = message;
  }

  onConfirmMes() {
    this.messageService.clear();
  }

  onConfirm() {
    if (this.messageAlert == this.translateService.instant('FNAPAY200.M00052')) {
      this.messageService.clear();
      setTimeout(() => {
        let listConfirm = {
          'saleOrderNumber': this.paymentHeaderForm.controls['saleOrderNumber'].value,
          'confirmPaymentUser': this.userCode,
          'warehouseCode': this.paymentHeaderForm.controls['warehouseCode'].value,
          'shipmentStatus': this.payDetail.shipmentStatusCode
        };
        this.paymentService.confirmShipment(listConfirm).subscribe();
        window.location.reload();
      }, 250);
    } else if(this.messageAlert == this.translateService.instant('FNAPAY200.M00053')){
      this.messageService.clear();
      this.display = false;
    } else {
      if(this.paymentHeaderForm.controls['arrearage'].value > 0){
        this.confirmArrearageInfo(this.translateService.instant('FNAPAY200.M00054')+ " " + this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.paymentHeaderForm.controls['arrearage'].value.toString().replace(/,/g, ""), this.method, this.roundToDecimal,this.decimal), '1.' + this.decimal + '-' + this.decimal, 'en') + " "
         + this.paymentHeaderForm.controls['currency'].value + " " + this.translateService.instant('FNAPAY200.M00055'));

      }else {
          this.onConfirmAg();

      }
    }
    this.checkstatus();
  }

  onConfirmAg() {
    this.messageService.clear();
    let pamlanguage = null;
    if(this.language == 'TH'){
      pamlanguage = 'th';
    }else{
      pamlanguage = 'en';
    }
    // setTimeout(() => {
      this.listSaleOrderNumber.push(this.paymentHeaderForm.controls['saleOrderNumber'].value)
      this.listWarehouse.push(this.paymentHeaderForm.controls['warehouseCode'].value)
      this.listPaymentStatus.push(this.payDetail.paymentStatusCode);
      let listConfirm = {
        'saleOrderNumber': this.listSaleOrderNumber,
        'confirmPaymentUser': this.userCode,
        'warehouseCode': this.listWarehouse,
        'paymentStatus': this.listPaymentStatus,
        'language': pamlanguage
      };
        this.paymentService.confirmPayment(listConfirm).subscribe();
        window.location.reload();
      // this.checkstatus();
    // }, 250);
  }

  deleteRow() {
    this.messageService.clear();
    this.paymentService.deletePaymentDetail(this.payTable[this.selectRow].payID,this.payDetail.saleOrderNumber,this.payDetail.warehouseCode).subscribe();
    window.location.reload();
  }

  onReject() {
    this.messageService.clear();
    this.checkstatus();
  }

  edit(row, form) {
    this.maxDate = new Date();
    let payDate = moment(this.payTable[row].aacceptPayment).format('YYYY-MM-DD HH:mm:ss');
    let opDate = moment(this.payTable[row].paymentRecord).format('YYYY-MM-DD HH:mm:ss');
    this.display = true;
    this.payRecords.paymentID = this.payTable[row].payID
    this.paymentDeatilForm.controls['saleOrderNumber'].setValue(this.payDetail.saleOrderNumber);
    this.paymentDeatilForm.controls['country'].setValue(this.translateLablePipe.transform(this.payDetail.country_eng,this.payDetail.country_local));
    this.paymentDeatilForm.controls['currency'].setValue(this.translateLablePipe.transform(this.payDetail.currency_eng,this.payDetail.currency_local));
    this.paymentDeatilForm.controls['amountToBePaid'].setValue(this.decimalService.getRoundingDecimal(new Big(this.payDetail.saleOrderAmount), this.method, this.roundToDecimal,this.decimal));
    this.paymentDeatilForm.controls['operationDate'].setValue(opDate);
    this.paymentDeatilForm.controls['paymentChannel'].setValue(this.payTable[row].chanCode);
    this.paymentDeatilForm.controls['bankAccount'].setValue(this.payTable[row].bankCode);
    this.paymentDeatilForm.controls['paymentAmount'].setValue(this.decimalService.getRoundingDecimal(new Big(this.payTable[row].amt), this.method, this.roundToDecimal,this.decimal));
    this.paymentDeatilForm.controls['remark'].setValue(this.payTable[row].remark);
    this.paymentDeatilForm.controls['creator'].setValue(this.userCode);
    this.paymentDeatilForm.controls['paymentDate'].setValue(new Date(payDate));
    this.converted_img = this.payTable[row].converted_image;
    this.base64Data = this.payDetail.detail[row].paymentImage
    this.paymentChannelCheck(this.paymentDeatilForm.controls['paymentChannel']);
    this.paymentDeatilForm.controls['arrearage'].setValue(this.paymentHeaderForm.controls['arrearage'].value);
    this.checkstatus();
  }

}
