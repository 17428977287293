import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() dataTable: any;
  @Input() dataHeader: any;
  @Input() checkbox: boolean;
  index : any = [];
  @Output() rowData = new EventEmitter<any>();
  cols:string;
  firstRow: number = 0 as number;
  constructor(private translateService: TranslateService) {
   }

  ngOnInit(): void {
  }

  checked(){
    let row : any = [];
    let v_table : any = [];
    this.index.forEach((result) => {
      row.push(result);
    });
    v_table.push({'v_table':row})
    this.rowData.emit(v_table);
  }

  ngOnChanges(){
    this.firstRow = 0;
  }

}
