import { Component, Renderer2 } from '@angular/core';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { CheckboxModel } from 'src/app/shared/model/checkbox.model';
import { DeleteSalePriceRequest, SalePriceInquiryModel } from 'src/app/shared/model/sales-price.model';
import { Prisal100Model } from './model/prisal100.model';
import { Prisal100Service } from './service/prisal100.service';
import { TranslateService } from '@ngx-translate/core';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { DecimalData } from '../model/pricesale';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { date_format_moment, date_format_system } from 'src/app/shared/constant/date.constant';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DropDownListModel } from 'src/app/shared/model/dropdownlist.model';
import { SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { Big } from 'big.js';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-prisal100',
  templateUrl: './prisal100.component.html',
  styleUrls: ['./prisal100.component.scss']
})
export class Prisal100Component extends PermissionComponent {

  page_model: Prisal100Model;

  // searchFormSummitFlag: boolean;
  exportReportFlag: boolean;
  disabledCountry: boolean = false;
  disabledSaleChannel: boolean = false;

  salePriceSelected: Array<SalePriceInquiryModel> = [];
  temp_allSalePrice: Array<SalePriceInquiryModel> = [];

  public decimalData:DecimalData = {} as DecimalData;

  typeDecimalPurchase: string = '1';
  typeDecimalSelling: string = '2';
  typeDecimalRoundMethod: string = '3';
  typeDecimalRoundToValue: string = '4';

  translateLablePipe = new TranslateLablePipe();

  method:string;
  roundToDecimal:number;
  decimal:number;
  defaultPeriod: any;
  optionValueDefault: any;
  optionData: SelectItem[];
  firstRow: number = 0 as number;

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  constructor(renderer2: Renderer2,
    private service: Prisal100Service,
    private translateService: TranslateService,
    private exportExcelService: ExportExcelService,
    private prmService: ParameterService,
    private decimalService: DecimalService,
    private messageService: MessageService,
    private autService: AuthenticationService,
    private router: Router,) {
    super('P50001', renderer2);
    this.page_model = this.service.load;
    this.page_model.searchFormSummitFlag = true;
    this.exportReportFlag= true;
    this.disabledSaleChannel = true;
  }

  useLanguage(Language: string) {
    this.translateService.use(Language);
  }

  ngOnInit() {
    this.service.loadComponanceData(this.page_model);

    this.page_model.date.locale = this.translateCalendar;

    this.page_model.sale_price_list = [];
    this.page_model.form_model.criterial_form.reset();
    this.page_model.form_model.search_form.reset();
    this.page_model.form_model.search_form.controls['period'].setValue(['2']);
    this.page_model.data_list.free_shipment = [
      {label:'Free Shipping', value: 1, localDesc:'Free Shipping', engDesc:'Free Shipping'},
      {label:'No Free Shipping', value: 2, localDesc:'No Free Shipping', engDesc:'No Free Shipping'}
    ];

  }

  getFormatNumber(country){
    this.prmService.getvprmdtltolableByCountry(104, country).subscribe(response => {
      let decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalPurchase);
      this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalSelling);
      this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalRoundMethod);
      this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalRoundToValue);
      this.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
      this.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
      this.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
    }, err => {
      console.log(err);
    });
}

  searchFunction() {
    this.page_model.searchFormSummitFlag = true;
    this.service.searchFunction().subscribe((salePriceList: Array<SalePriceInquiryModel>) => {
      this.page_model.sale_price_list = salePriceList;
      this.page_model.sale_price_list.forEach((element: SalePriceInquiryModel) => {
        element.ItemName = this.translateLablePipe.transform(element.itemDesc_eng,element.itemDesc_local),
        element.SellerName = this.translateLablePipe.transform(element.sellerDesc_eng,element.sellerDesc_local),
        element.SaleChannel = this.translateLablePipe.transform(element.saleChanelDesc_eng,element.saleChanelDesc_local),
        element.itemCodeFormSeller = element.itemCodeFromKT;
        if(this.page_model.sale_price_list.length > 0) {
          this.temp_allSalePrice = salePriceList;
          this.exportReportFlag = false;
          this.firstRow = 0;
        }
        if(element.free_shipment_flag == 1){
          element.free_shipment_flag = true;
        }else{
          element.free_shipment_flag = false;
        }
      });
    }, error => {
      console.log("Error");
    });
  }

  addFunction() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['prisal/prisal200']).then(() => {
        window.location.reload();
        window.scroll(0, 0);
      })
    );
  }

  deleteFunction() {
    // 2.2.2.7 System allow delete sale perice period is future
    // Check selected data on table more than one record
    if (this.salePriceSelected.length > 0) {
      let sale_price_no_list: Array<number> = [];
      // if selected data on table have period is not future.
      let sale_price_active_list: Array<SalePriceInquiryModel> = [];

      // 2.2.2.8 if current date is after start date system not allow delete
      // 2.2.2.9 if current date is after end date syste not allow to delete
      sale_price_active_list = this.salePriceSelected.filter((sale_price: SalePriceInquiryModel) => {
        // Format for checking date of moment is 'YYYY-MM-DD' (date_format_moment)
        // Change format date to moment format
        let current_date: string = moment().format(date_format_moment);
        let start_date: string = moment(sale_price.startDate, date_format_system).format(date_format_moment);
        let end_date: string = moment(sale_price.endDate, date_format_system).format(date_format_moment);
        return moment(current_date).isBetween(start_date, end_date, undefined, '[]') || moment(current_date).isSameOrAfter(end_date);
      });

      if (sale_price_active_list.length > 0) {
        // Additional Specification then seleted active
        this.messageService.clear();
        this.messageService.add({ key: 'error', severity: 'warn', summary: this.translateService.instant('PRISAL100.M00039'), detail: this.translateService.instant('PRISAL100.M00045') });
      } else {
        // System warning message on screen "ต้องการลบราคาขายหรือไม่"
        this.messageService.clear();
        this.messageService.add({ key: 'delete_confirm', severity: 'warn', summary: this.translateService.instant('PRISAL100.M00025'), detail: this.translateService.instant('PRISAL100.M00046') });
      }
     }

    // if user confiem system will be delete sale price
    // then system show success transaction "ลบข้อมูลราคาขายสำเร็จ"
    // System to be delete sale price are seletes.
    // else selected data on table have period is not future.

  }

  deleteSalePriceFunction() {
    let sale_price_no_list: DeleteSalePriceRequest = {} as DeleteSalePriceRequest;
    sale_price_no_list.priceSaleNo = [];
    this.salePriceSelected.forEach((sale_price: SalePriceInquiryModel) => {
      sale_price_no_list.priceSaleNo.push(sale_price.salePriceNo);
    });
    this.service.deleteFunction(sale_price_no_list).subscribe(success => {
      this.salePriceSelected = [];
      this.page_model.sale_price_list = [];
      this.temp_allSalePrice = [];
      this.service.searchFunction().subscribe((salePriceList: Array<SalePriceInquiryModel>) => {
        this.page_model.sale_price_list = salePriceList;
        this.page_model.sale_price_list.forEach((element: SalePriceInquiryModel) => {
          element.itemCodeFormSeller = element.itemCodeFromKT;
          if(this.page_model.sale_price_list.length > 0) {
            this.temp_allSalePrice = salePriceList;
          }
        });
        this.messageService.clear();
        this.messageService.add({ key: 'success', severity: 'success', summary: this.translateService.instant('PRISAL100.M00038'), detail: this.translateService.instant('PRISAL100.M00047') });
      });
    });

  }

  periodCheckFunction(period: CheckboxModel) {
    this.page_model.form_model.search_form.controls['period'].setValue([period.value]);
  }

  clearSearchFunction() {
    this.exportReportFlag = true;
    this.page_model.searchFormSummitFlag = true;
    this.page_model.sale_price_list = null;
    this.page_model.form_model.criterial_form.reset();
    if ( this.page_model.data_list.country_data.length === 1 ) {
      this.page_model.form_model.criterial_form.patchValue({
        country:  this.page_model.data_list.country_data[0].value
      });
      this.changeCountry();
    }
    // this.page_model.form_model.search_form.reset();
    // this.page_model.form_model.search_form.controls['period'].setValue(['2']);
    this.page_model.form_model.search_form.patchValue({
      item_code: [null],
      item_name: [null],
      sale_channel: [null],
      seller: [null],
      freeShipping: [null],
      start_date: [null],
      end_date: [null],
      period: ['2']
    });
  }

  modifyFunction(salid: string) {
    window.location.href = `/prisal/prisal200/${salid}`;
  }

  advanceSearchFunction() {
    let key = this.page_model.form_model.advance_search_form.get('key').value;
    if(key) {
       this.page_model.sale_price_list = this.temp_allSalePrice.filter((el: SalePriceInquiryModel) => {
         return (
          (el.itemCodeFormSeller && el.itemCodeFormSeller.toLowerCase().includes(key.toLowerCase())) ||
          (el.itemCodeFromKO && el.itemCodeFromKO.toLowerCase().includes(key.toLowerCase())) ||
          (el.ItemName && el.ItemName.toLowerCase().includes(key.toLowerCase())) ||
          (el.sellerCode && el.sellerCode.toLowerCase().includes(key.toLowerCase())) ||
          (el.SellerName && el.SellerName.toLowerCase().includes(key.toLowerCase())) ||
          (el.SaleChannel && el.SaleChannel.toLowerCase().includes(key.toLowerCase())) ||
          (this.decimalService.getRoundingDecimal(new Big(Number(el.fullPrice)),this.method,this.roundToDecimal,this.decimal).toString().toLowerCase().includes(key.toLowerCase())) ||
          (this.decimalService.getRoundingDecimal(new Big(Number(el.salePrice)),this.method,this.roundToDecimal,this.decimal).toString().toLowerCase().includes(key.toLowerCase())) ||
          (el.fullPrice).toFixed(this.decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().includes(key.toLowerCase()) ||
          (el.salePrice).toFixed(this.decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().includes(key.toLowerCase()) ||
          (el.startDate && el.startDate.toString().toLowerCase().includes(key.toLowerCase())) ||
          (el.endDate && el.endDate.toString().toLowerCase().includes(key.toLowerCase()))
          );
       });
       this.firstRow = 0;
      }
    }

  exportReportFunction() {
    let sub_title = [];
    sub_title.push([this.translateService.instant('PRISAL100.M00000')]);
    let header: any = {
      // รหัสสินค้าจาก Seller
      itemCodeFormSeller: this.translateService.instant('PRISAL100.M00028'),
      //รหัสสินค้าจาก KlangOne
      itemCodeFromKO: this.translateService.instant('PRISAL100.M00029'),
      // ชื่อสินค้า
      itemDesc: this.translateService.instant('PRISAL100.M00030'),
      // รหัสผู้ขาย
      sellerCode: this.translateService.instant('PRISAL100.M00031'),
      // ชื่อผู้ขาย
      sellerDesc: this.translateService.instant('PRISAL100.M00032'),
      // ช่องทางการขาย
      saleChanelDesc: this.translateService.instant('PRISAL100.M00033'),
      // ราคาเต็ม
      fullPrice: this.translateService.instant('PRISAL100.M00034'),
      // ราคาขาย
      salePrice: this.translateService.instant('PRISAL100.M00035'),
      // วันที่ราคามีผลเริ่มใช้
      startDate: this.translateService.instant('PRISAL100.M00036'),
      // วันที่ราคาสิ้นสุด
      endDate: this.translateService.instant('PRISAL100.M00037'),
      //Free Shipping
      freeShipping: this.translateService.instant('PRISAL100.M00049')
    };
    let export_data_list = [];
    this.page_model.sale_price_list.forEach((sale_price: SalePriceInquiryModel) => {
      let export_data = {
        itemCodeFormSeller: sale_price.itemCodeFormSeller,
        itemCodeFromKO: sale_price.itemCodeFromKO,
        itemDesc: this.translateLablePipe.transform(sale_price.itemDesc_eng,sale_price.itemDesc_local),
        sellerCode: sale_price.sellerCode,
        sellerDesc: this.translateLablePipe.transform(sale_price.sellerDesc_eng,sale_price.sellerDesc_local),
        saleChanelDesc: this.translateLablePipe.transform(sale_price.saleChanelDesc_eng,sale_price.saleChanelDesc_local),
        fullPrice: this.decimalService.getRoundingDecimal(sale_price.fullPrice, this.method, this.roundToDecimal, this.decimal),
        salePrice: this.decimalService.getRoundingDecimal(sale_price.salePrice, this.method, this.roundToDecimal, this.decimal),
        startDate: sale_price.startDate,
        endDate: sale_price.endDate,
        freeShipping: sale_price.free_shipment_flag == 1 ? 'Yes' :'No'
      }
      export_data_list.push(export_data);
    });
    // console.log(export_data_list);
    this.exportExcelService.exportAsExcelFile(export_data_list, this.translateService.instant('PRISAL100.M00004'), header, null, sub_title);
  }

  onReject() {
    this.messageService.clear();
  }

  onErrorCancel() {
    this.messageService.clear();
  }

  onDeleteConfirm() {
    this.deleteSalePriceFunction();
  }

  changeCountry(){
    this.page_model.searchFormSummitFlag = false;
    this.autService.getParameterSaleChannel(this.page_model.form_model.criterial_form.
      get('country').value).subscribe((response: Array<DropDownListModel>) => {
      this.page_model.data_list.sale_channel_data = response;
      if(this.page_model.data_list.sale_channel_data.length == 0){
        this.disabledSaleChannel = true;
      }else{
        this.disabledSaleChannel = false;
      }
    });
  }

  changeSearch(event){
    if(this.page_model.form_model.criterial_form.get('country').value != null){
      if(event){
        this.page_model.searchFormSummitFlag = false;
      }
    }else{
      this.page_model.searchFormSummitFlag = true;
    }

  }
}
