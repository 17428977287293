import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class EwlselService {

  private URL: string = `${this.evmService.getApiUrl()}/ewl/ewlsel` as string;

  constructor(private http: HttpClient,
    private evmService: EnvironmentService,
    private autService: AuthenticationService) { 
    }

    getExternalLinkBySeller(sellerCode){
      const URL = `${this.URL}/getExternalLinkBySeller?sellerCode=${sellerCode}`;
      return this.http.get(URL, {headers: this.autService.tokenHeader()});
    }
}
