import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';

@Injectable({
  providedIn: 'root'
})

export class Admpro100Service {

  constructor(private http: HttpClient,
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService) { }

    private PROFILE_URL: string = `${this.environmentService.getApiUrl()}/adm/prof` as string;

    getProfileInquiry(param) {  
      const URL: string = `${this.PROFILE_URL}/getProfileInquiry` as string;
      return this.http.post(URL,param,{ headers: this.authenticationService.tokenHeader()});
    }
}
