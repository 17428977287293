import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Excexc100Component } from './excexc/excexc100/excexc100.component';
import { Excexc200Component } from './excexc/excexc200/excexc200.component';

const routes: Routes = [
  { path: 'excexc/excexc100', component: Excexc100Component },
  { path: 'excexc/excexc200', component: Excexc200Component }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExcRoutingModule { }
