import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ParameterCountryList } from "../model/parameter.model";
import { EnvironmentService } from "./environment.service";

@Injectable({
    providedIn: 'root'
})

export class AdministrationLanguageService {
    
    private PUBLIC_ADMLANG_URL: string = `${this.environmentService.getApiUrl()}/api/lang/translation/get` as string;
    private PRM_URL: string = `${this.environmentService.getApiUrl()}/api/lang` as string;
    private  message: string = '' as string;

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService
    ) {

    }

    
  getvparameterCountry(): Observable<ParameterCountryList> {
    const URL = `${this.PRM_URL}/translation/getCountryAll`;
    return this.http.get<ParameterCountryList>(URL);
  }
    
}
