import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Cusext100Component } from './cusext/cusext100/cusext100.component';
import { Cuscus100Component } from './cuscus/cuscus100/cuscus100.component';
import { Cuscus200Component } from './cuscus/cuscus200/cuscus200.component';
import { Cuscus300Component } from './cuscus/cuscus300/cuscus300.component';


const routes: Routes = [
  { path: 'cusext/cusext100', component: Cusext100Component }
  , { path: 'cuscus/cuscus100', component: Cuscus100Component }
  , { path: 'cuscus/cuscus200', component: Cuscus200Component }
  , { path: 'cuscus/cuscus200/:customerId', component: Cuscus200Component }
  , { path: 'cuscus/cuscus300', component: Cuscus300Component }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CusRoutingModule { }