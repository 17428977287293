import { Injectable } from '@angular/core';
import { ProfileData, ActionData } from 'src/app/adm/model/admpro/admpro200.model';


@Injectable({
  providedIn: 'root'
})

export class AdmproLocalStorageService {

  public storageName:string = 'ProfileData';
  
  /* Status Page is 'EDIT' --> Program will load new proflie data 
     Status Page is 'EDITED' --> Program will set proflie data from Local Storage and change status page is 'EDIT' after set data */

  constructor() { }
 
  addProfileData(data: ProfileData) {
    localStorage.setItem(this.storageName, JSON.stringify(data));
  }

  updateActionByMenu(indexParent,indexChilde,actionData:Array<ActionData>,statusPage){
    let profile = this.getProfileData();  
    profile.statusPage = statusPage;
    if(actionData){
      profile.menuData[indexParent].menuChilde[indexChilde].actionData = actionData;
      profile.menuSendToActionPage.actionData = actionData;
    }
    localStorage.setItem(this.storageName, JSON.stringify(profile));
  }

  getProfileData() {
    let data = localStorage.getItem(this.storageName);
    return JSON.parse(data);
  }

  clearProfileData() {
    localStorage.removeItem(this.storageName);
  }

}