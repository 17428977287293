import { DecimalService } from './../../shared/services/decimal.service';
import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DropDownListModel } from 'src/app/shared/model/dropdownlist.model';
import { StockBarChartReponse, StockListBarChartReponse } from 'src/app/shared/model/dashboard-stock.model';
import { CreateItemList, InfoList, ProductDetail, ProductlineList, ProductList } from 'src/app/shared/model/product.model';
import { ItemService } from 'src/app/shared/services/item.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { DecimalData, DecimalProp, itm100Model, SearchFormGroup, TempDDL } from 'src/app/itm/model/itm-model'
import { decimalTypePurchase, decimalTypeRoundMethod, decimalTypeRoundToValue, decimalTypeSelling } from 'src/app/shared/constant/decimal.constant';
import { itm100Service } from 'src/app/adm/service/itm/itmitm100.service';
import { ItemByWhModel, ItemExpiryModel, ItemModel, ItemPage } from 'src/app/shared/model/item.model';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ParameterEntity } from 'src/app/shared/model/paramete.rmodel';
import { DecimalPipe } from '@angular/common';
import { ThaiDatePipe } from 'src/app/shared/pipes/thaidate.pipe';

@Component({
  selector: 'app-itmitm100',
  templateUrl: './itmitm100.component.html',
  styleUrls: ['./itmitm100.component.scss']
})
export class Itmitm100Component implements OnInit {

  itm100 = {} as itm100Model;
  temp_allItem: Array<ItemModel> = [];
  Serchsubmit: SearchFormGroup;
  public yearList: Array<DropDownListModel> = [] as Array<DropDownListModel>;
  public monthList: Array<DropDownListModel> = [] as Array<DropDownListModel>;
  public BarChartData: StockListBarChartReponse = {} as StockListBarChartReponse;
  public stockBarChartData: Array<StockBarChartReponse> = [] as Array<StockBarChartReponse>;

  public data: InfoList = {} as InfoList;
  public infoTableProduct: Array<ProductList> = [] as Array<ProductList>;
  //public itemList: Array<ItemModel>  = [] as Array<ItemModel>;
  itemList: Array<ItemModel> = []
  public infoGrapProductline: Array<ProductlineList> = [] as Array<ProductlineList>;
  public infoCreateItem: Array<CreateItemList> = [] as Array<CreateItemList>;
  decimalData: DecimalData = {} as DecimalData;
  decimalProp: DecimalProp[] = [] as DecimalProp[];
  decimalPropTemp: DecimalProp = {} as DecimalProp;
  translateLablePipe = new TranslateLablePipe();

  ItemTypeData: TempDDL[];

  selectedScopes: any[];

  sellerLength: any;
  status: boolean;
  dataProdchange: any = [];
  dataProdReport: any = [];
  seller: string;
  DupIsSearch: boolean = true as boolean;
  chartPie: any;
  chartLine: any;
  productline: any = [];
  qtyBrand: any = [];
  brand: any = [];
  qtyProduct: any = [];
  DupIsDillDown: boolean = true as boolean;
  typeChart: string = 'doughnut' as string;
  options: any;
  optionsLine: any;
  DupOptions: boolean = true as boolean;
  getTableProductData: any = [];
  listItemCode: any = [];
  /*  searchProdGroupForm = new FormGroup({
      searchProductline: new FormControl(),
      searchBrand: new FormControl(),
      searchProductCode: new FormControl(),
      searchProductCodeOnline: new FormControl(),
      searchProductName: new FormControl()
    });*/

  searchProdGroupForm = new FormGroup({
    sellerCode: new FormControl(),
    khangOneCode: new FormControl(),
    hierarchyCode: new FormControl(),
  });
  searchSellerGroupForm = new FormGroup({
    seller: new FormControl()
  })
  searchByWhGroupForm = new FormGroup({
    warehouse: new FormControl(),
    country: new FormControl(),
  });
  searchProductExpiryGroupForm = new FormGroup({
    warehouse_code: new FormControl(),
    country_code: new FormControl(),
    lot_no: new FormControl(),
    itemValidate: new FormControl(),
    from_threshold_days: new FormControl(),
    to_threshold_days: new FormControl(),
    mfg_date: new FormControl(),
    exp_date: new FormControl(),
  });

  searchProductline: string = '' as string;
  searchBrand: string = '' as string;
  searchProductCode: string = '' as string;
  searchProductCodeOnline: string = '' as string;
  searchProductName: string = '' as string;
  optionData: any;
  optionYear: SelectItem[];
  optionMonth: SelectItem[];
  createItemDate: any = [];
  qtyItemCreate: any = [];
  first: number = 0 as number;
  dataNotFoundGrid2: boolean = false as boolean;
  dataNotFoundGrid: boolean = true as boolean;
  dataNotFoundProdline: boolean = false as boolean;
  dataNotFoundCreateItem: boolean = false as boolean;
  checkShowChartLine: boolean = false as boolean;
  qtySumCreateItem: number = 0 as number;
  typeChartCreateItem: string = 'bar' as string;
  disButtonChart: boolean = false as boolean;
  isRight: boolean = false as boolean;
  barChartData: any;
  dataShowChart: any;
  labelDateFrom: any;
  labelDateTo: any;
  periodLabel: any;
  pageName: string;
  searchDisabled: boolean = true as boolean;
  exportDisabled: boolean = true as boolean;
  clearDisabled: boolean = false as boolean;
  searchByWhDisabled: boolean = true as boolean;
  searchProductExpiryDisabled: boolean = true as boolean;
  clearByWhDisabled: boolean = false as boolean;
  cols: any[];
  loadingFlag: boolean = false as boolean;
  sellerData: ParameterEntity[];
  param = { country: null, warehouse: null };
  warehouse: string;
  sellerValue: string;
  display: boolean = false as boolean;

  month: any;
  year: any;
  label = [];
  periodLast4month = [3, 2, 1, 0];
  admissionData = [];
  exportDatachart = [];
  programId: string = 'P22101' as string;
  firstRow: number = 0 as number;
  firstRow1: number = 0 as number;
  firstRow2: number = 0 as number;
  typeDecimalSelling: string = '2';
  decimalSelling: string;
  dataTypeDecimalSelling: any;

  searchForm = new FormGroup({
    keySearch: new FormControl(),
    keySearchExpiry: new FormControl(),
  });

  expiryOptions : TempDDL[];

  public itemInquiryPage: ItemPage = {} as ItemPage;
  public tableItemByWh: Array<ItemByWhModel> = [] as Array<ItemByWhModel>;
  public tableItemByWhTemp: Array<ItemByWhModel> = [] as Array<ItemByWhModel>;
  public tableItemProductExpiry: Array<ItemExpiryModel> = [] as Array<ItemExpiryModel>;
  public tableItemProductExpiryTemp: Array<ItemExpiryModel> = [] as Array<ItemExpiryModel>;
  public tableItemProductExpirySearchTemp: Array<ItemExpiryModel> = [] as Array<ItemExpiryModel>;
  public thaidate = new ThaiDatePipe();
  public optionCountry: ParameterEntity[];
  public warehouseData: TempDDL[];
  public hideAdvancedSearch: boolean = true;

  constructor(private itmService: ItemService,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService,
    private parameterService: ParameterService,
    private itm100Service: itm100Service,
    private ParameterWmsSerive: ParameterWmsSerive,
    private autService: AuthenticationService,
    private decimalPipe: DecimalPipe
  ) {
    this.status = false;
    this.itm100 = this.itm100Service.load;
    this.itm100.form.criteriaFormGroup.form.controls['seller'].setValue(null);
    this.searchByWhGroupForm.controls['warehouse'].disable();
  }

  ngOnInit() {
    this.ClearData();
    this.ClearDataProductExpiryGroupFormh();
    this.itm100.itemList = [];
    this.checkDropDown();
    if (window.screen.width < 640) {
      this.isRight = false;
    } else if (window.screen.width >= 641) {
      this.isRight = true;
    }

    // หมวดหมู่
    this.ParameterWmsSerive.getParameterCategory().subscribe((response) => {
      this.ItemTypeData = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.autService.getParameterWmsCountry().subscribe((response) => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.optionCountry.length == 1) {
        this.searchByWhGroupForm.controls['country'].disable();
        this.searchByWhGroupForm.controls['country'].setValue(this.optionCountry[0].value);
        this.searchByWhGroupForm.controls['warehouse'].enable();
        this.getCountry(this.optionCountry[0]);
      } else if (this.optionCountry.length > 1) {
        this.searchByWhGroupForm.controls['country'].reset();
      } else if (this.optionCountry.length == 0) {
        this.searchByWhGroupForm.controls['country'].disable();
      }
    }, err => {
      console.log(err);
    });

    this.autService.getParameterWmsWareHouse(null).subscribe(response => {
      this.warehouseData = JSON.parse(JSON.stringify(response));
      if (this.warehouseData.length == 0) {
        this.searchByWhGroupForm.controls['warehouse'].disable();
      } else if (this.warehouseData.length == 1) {
        this.searchByWhGroupForm.controls['warehouse'].disable();
        this.searchByWhGroupForm.controls['warehouse'].setValue(this.warehouseData[0].value);
      } else {
        this.searchByWhGroupForm.controls['warehouse'].enable();
      }
    }, err => {
      console.log(err);
    });

    this.parameterService.getParameterDetailToLableValueCode(131).subscribe((parameter: Array<TempDDL>) => {
      this.expiryOptions = parameter;
    }, err => {
      console.log(err);
    });

    this.pageName = localStorage.getItem(this.programId);
    this.dataNotFoundProdline = true;
    this.getFormatNumber('KH');
    this.getFormatNumber('LA');
    this.getFormatNumber('TH');

    this.searchForm.valueChanges.subscribe(() => {
      if (this.searchForm.valid) {
        this.advanceSearchFunction();
        this.automaticSearchFunction();
      } else {
        this.itemInquiryPage.itemDataList = this.itemInquiryPage.itemTemp;
        this.tableItemProductExpiry = this.tableItemProductExpiryTemp;
      }
    });

    this.parameterService.getDiscoutPrice(104, 'GB', this.typeDecimalSelling).subscribe(dataRound => {
      this.dataTypeDecimalSelling = JSON.parse(JSON.stringify(dataRound));
      this.decimalSelling = this.dataTypeDecimalSelling.pmdtbdnumv1;
    });
  }

  translateMessage(langLabel) {
    let msgLang: any[] = [];
    for (let i = 0; i < langLabel.length; i++) {
      this.translateService.get(langLabel[i]).subscribe(async res => {
        msgLang.push(await res);
      });
    }
    return msgLang;
  }

  getSeller(seller) {
    this.seller = seller.seller;
    this.getTableProduct(this.seller);
  }

  getTableProduct(seller: string) {
    this.itmService.getAllProductBySeller(seller).subscribe((response: InfoList) => {
      this.data = response;
      this.infoTableProduct = this.data.itemList;
      this.infoGrapProductline = this.data.infoGrapProductline;
      this.infoCreateItem = this.data.infoCreateItem;
      this.getTableProductData = this.infoTableProduct;

      if (this.infoTableProduct.length === 0) {
        this.dataNotFoundGrid = true;

      } else {
        this.dataNotFoundGrid = false;
      }
      if (this.infoGrapProductline.length === 0) {
        this.dataNotFoundProdline = true;
      } else {
        this.dataNotFoundProdline = false;
      }
      if (this.infoCreateItem.length === 0) {
        this.dataNotFoundCreateItem = true;
      } else {
        this.dataNotFoundCreateItem = false;
      }

      const checkLength = this.productline.length;
      this.infoGrapProductline.forEach((element, index) => {
        if (index === 0) {
          this.productline.push((element.brand === null || element.brand === '') ? this.translateService.instant('ITMITM100.M00098') : element.brand);
          this.qtyBrand.push(element.qtyProduct);
        } else if (element.brand === this.productline) {
          this.qtyBrand[checkLength] = this.qtyBrand[checkLength] + parseInt(element.qtyProduct);
        } else if (element.brand != this.productline) {
          this.productline.push((element.brand === null || element.brand === '') ? this.translateService.instant('ITMITM100.M00098') : element.brand);
          this.qtyBrand.push(element.qtyProduct);
        }
      });
    }, () => {
      this.dataNotFoundGrid2 = true;
      this.dataNotFoundCreateItem = true;
      this.dataNotFoundProdline = true;
      this.checkShowChartLine = false;
    });
  }

  selectData(e: any) {
    this.brand = [];
    this.qtyProduct = [];
    this.typeChart = 'pie';
    this.DupOptions = true;
    const label = e.element._model.label;
    const checkLength = this.brand.length;
    this.infoGrapProductline.forEach((element, index) => {
      if (element.brand === label) {
        if (index === 0) {
          this.brand.push(element.productline);
          this.qtyProduct.push(element.qtyProduct);
        } else if (element.productline === this.brand) {
          this.qtyProduct[checkLength] = this.qtyProduct[checkLength] + parseInt(element.qtyBrand);
        } else if (element.productline != this.brand) {
          this.brand.push(element.productline);
          this.qtyProduct.push(element.qtyBrand);
        }
      }
    });
    if (this.DupIsDillDown) {
      this.DupIsDillDown = false;
    }
  }

  changeSearch() {
    if (this.itm100.form.criteriaFormGroup.form.get('seller').value) {
      this.searchDisabled = false;
    }
    else {
      this.searchDisabled = true;
    }
  }

  searchSubmit() {
    this.exportDisabled = false;
    this.searchDisabled = true;
    this.clearDisabled = false;
    this.itm100Service.searchSubmit().subscribe((itmList: Array<ItemModel>) => {
      this.itm100.itemList = itmList;
      if (this.itm100.itemList) {
        this.itm100.itemList.forEach((element: ItemModel) => {
          element.item_product_code_seller = element.item_product_code_seller;
          element.item_product_code_klangOne = element.item_product_code_klangOne;
          element.item_english = element.item_english;
          element.item_brand = element.item_brand;
          element.merchandise_desc_eng = element.merchandise_desc_eng;
          element.merchandise_desc_local = element.merchandise_desc_local;
        })
        this.itm100.itemList = JSON.parse(JSON.stringify(itmList));
        this.itemInquiryPage.itemTemp = this.itm100.itemList;
        this.firstRow = 0;
      }
    }, err => {
      console.log(err);
    });

    this.itm100Service.searchByWhSubmit().subscribe((itmList: Array<ItemByWhModel>) => {
      this.tableItemByWh = JSON.parse(JSON.stringify(itmList));
      this.tableItemByWhTemp = JSON.parse(JSON.stringify(itmList));
      this.firstRow1 = 0;
    }, err => {
      console.log(err);
    });

    this.itm100Service.searchProductExpiryGroupForm().subscribe((itmList: Array<ItemExpiryModel>) => {
      this.tableItemProductExpiry = JSON.parse(JSON.stringify(itmList));
      this.setDataProductExpiry(null);
      this.tableItemProductExpiryTemp = JSON.parse(JSON.stringify(itmList));
      this.tableItemProductExpiryTemp.forEach(data => {
        data.item_name = this.translateLablePipe.transform(data.item_desc_eng, data.item_desc_local);
        data.threshold_days = Math.abs(data.exp_threshold_days);
        data.exp_date = data.exp_date?moment(data.exp_date,'YYYY-MM-DD').format('DD/MM/YYYY'):null;
        data.mfg_date = data.mfg_date?moment(data.mfg_date,'YYYY-MM-DD').format('DD/MM/YYYY'):null;
      });
      this.tableItemProductExpiry.forEach(data => {
        data.item_name = this.translateLablePipe.transform(data.item_desc_eng, data.item_desc_local);
        data.exp_date = data.exp_date?moment(data.exp_date,'YYYY-MM-DD').format('DD/MM/YYYY'):null;
        data.mfg_date = data.mfg_date?moment(data.mfg_date,'YYYY-MM-DD').format('DD/MM/YYYY'):null;
        data.threshold_days = Math.abs(data.exp_threshold_days);
      });
      this.firstRow2 = 0;
    }, err => {
      console.log(err);
    });
  }

  setDataProductExpiry(validateItemAging) {
    this.tableItemProductExpiry.forEach(data => {
      if (validateItemAging == '1' || validateItemAging == '2') {
        data.threshold_days = Math.abs(data.exp_threshold_days);
      } else if ((validateItemAging == '3')) {
        data.threshold_days = Math.abs(data.mfg_threshold_days);
      } else {
        data.threshold_days = Math.abs(data.exp_threshold_days);
      }
    });
  }

  getFormatNumber(country) {
    this.parameterService.getvprmdtltolableByCountry(104, country).subscribe(response => {
      let decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypePurchase);
      this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeSelling);
      this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundMethod);
      this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundToValue);
      this.decimalPropTemp = {} as DecimalProp
      this.decimalPropTemp.type = country;
      this.decimalPropTemp.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1;
      this.decimalPropTemp.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
      this.decimalPropTemp.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
      this.decimalProp.push(this.decimalPropTemp)
    }, err => {
      console.log(err);
    });
  }

  exportReport() {
    const subTitle = [];

    subTitle.push([this.translateService.instant('ITMITM100.M00126')]);
    const header: any = {
      item_code: this.translateService.instant('ITMITM100.M00020'),
      item_product_code_klangOne: this.translateService.instant('ITMITM100.M00021'),
      seller_desc: this.translateService.instant('ITMITM100.M00130'),
      item_desc: this.translateService.instant('ITMITM100.M00026'),
      item_brand: this.translateService.instant('ITMITM100.M00022'),
      merchandise_desc: this.translateService.instant('ITMITM100.M00023'),
      item_type: this.translateService.instant('ITMITM100.M00024'),
      cur_bal_stk: this.translateService.instant('ITMITM100.M00127'),
      cur_avai_stk: this.translateService.instant('ITMITM100.M00128'),
    };
    this.dataProdReport = [];
    this.itm100.itemList.forEach((element) => {
      const dataReport = {
        item_code: element.item_product_code_seller,
        item_product_code_klangOne: element.item_product_code_klangOne,
        seller_desc: this.translateLablePipe.transform(element.seller_desc_eng, element.seller_desc_local),
        item_desc: this.translateLablePipe.transform(element.item_desc_eng, element.item_desc_local),
        item_brand: element.item_brand,
        merchandise_desc: this.translateLablePipe.transform(element.merchandise_desc_eng, element.merchandise_desc_local),
        item_type: element.item_stock_unit,
        cur_bal_stk: this.decimalPipe.transform(element.cur_bal_stk, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en'),
        cur_avai_stk: this.decimalPipe.transform(element.cur_avai_stk, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en')
      };
      this.dataProdReport.push(dataReport);
    });

    this.exportExcelService.exportAsExcelFile(this.dataProdReport, this.translateService.instant('ITMITM100.M00102'), header, null, subTitle);
    this.dataProdReport.shift();
  }

  exportData() {
    let request = {}
    if (this.itm100.form.criteriaFormGroup.form.controls['seller'].value != null) {
      this.listItemCode = [];
      this.itm100.itemList.forEach(element => {
        this.listItemCode.push(element.item_code);
      });
    } else {
      this.listItemCode = [];
      this.listItemCode.push('ALL');
    }
    request = {
      'itemCode': this.listItemCode
    };
    let sub_title = [];
    sub_title.push([this.translateService.instant('ITMITM100.M00100')]);
    let header: any = {
      item_code: this.translateService.instant('ITMITM100.M00020'),
      item_product_code_klangOne: this.translateService.instant('ITMITM100.M00021'),
      item_type_desc: this.translateService.instant('ITMITM100.M00060'),
      item_english: this.translateService.instant('ITMITM100.M00108'),
      item_local: this.translateService.instant('ITMITM100.M00109'),
      item_creation_date: this.translateService.instant('ITMITM100.M00110'),
      item_brand: this.translateService.instant('ITMITM100.M00022'),
      item_merchandise_hierarchy_desc: this.translateService.instant('ITMITM100.M00023'),
      item_sub_merchandise_hierarchy: this.translateService.instant('ITMITM100.M00058'),
      item_stock_unit: this.translateService.instant('ITMITM100.M00024'),
      item_seller_name: this.translateService.instant('ITMITM100.M00062'),
      item_allow_rtn_from_cus_flg: this.translateService.instant('ITMITM100.M00111'),
      item_allow_rtn_to_seller: this.translateService.instant('ITMITM100.M00112'),
      item_pre_ord_flag: this.translateService.instant('ITMITM100.M00113'),
      item_gross_weight: this.translateService.instant('ITMITM100.M00063'),
      item_width: this.translateService.instant('ITMITM100.M00064'),
      item_lenght: this.translateService.instant('ITMITM100.M00065'),
      item_height: this.translateService.instant('ITMITM100.M00066'),
      item_capacity: this.translateService.instant('ITMITM100.M00114'),
      item_order_unit: this.translateService.instant('ITMITM100.M00115'),
      item_sku: this.translateService.instant('ITMITM100.M00116'),
      item_manage_by_serial_flg: this.translateService.instant('ITMITM100.M00123'),
      item_minimum_order: this.translateService.instant('ITMITM100.M00118'),
      attribute_class: this.translateService.instant('ITMITM100.M00119'),
      attribute_code: this.translateService.instant('ITMITM100.M00120'),
      attribute_value: this.translateService.instant('ITMITM100.M00121'),
      cur_bal_stk: this.translateService.instant('ITMITM100.M00127'),
      cur_avai_stk: this.translateService.instant('ITMITM100.M00128'),
    };
    let dataReport = []
    this.itmService.getItemReport(request).subscribe(response => {
      JSON.parse(JSON.stringify(response)).forEach(item => {
        item.item_allow_rtn_from_cus_flg = item.item_allow_rtn_from_cus_flg === true ? "Yes" : "No";
        item.item_allow_rtn_to_seller = item.item_allow_rtn_to_selle === true ? "Yes" : "No";
        let export_data = {
          item_code: item.item_product_code_seller,
          item_product_code_klangOne: item.item_product_code_klangOne,
          item_type_desc: this.translateLablePipe.transform(item.item_type_desc_eng, item.item_type_desc_local),
          item_english: item.item_english,
          item_local: item.item_local,
          item_creation_date: item.item_creation_date,
          item_brand: item.item_brand,
          item_merchandise_hierarchy_desc: this.translateLablePipe.transform(item.item_merchandise_hierarchy_desc_eng, item.item_merchandise_hierarchy_desc_local),
          item_sub_merchandise_hierarchy: this.translateLablePipe.transform(item.item_sub_merchandise_hierarchy_eng, item.item_sub_merchandise_hierarchy_local),
          item_stock_unit: item.item_stock_unit,
          item_seller_name: this.translateLablePipe.transform(item.item_seller_name_eng, item.item_seller_name_local),
          item_allow_rtn_from_cus_flg: item.item_allow_rtn_from_cus_flg,
          item_allow_rtn_to_seller: item.item_allow_rtn_to_seller,
          item_pre_ord_flag: item.item_pre_ord_flag,
          item_gross_weight: item.item_gross_weight,
          item_width: item.item_width,
          item_lenght: item.item_lenght,
          item_height: item.item_height,
          item_capacity: item.item_capacity,
          item_order_unit: item.item_order_unit,
          item_sku: item.item_sku,
          item_manage_by_serial_flg: item.item_manage_by_serial_flg,
          item_minimum_order: item.item_minimum_order,
          attribute_class: item.attribute_class,
          attribute_code: item.attribute_code,
          attribute_value: item.attribute_value,
          cur_bal_stk: this.decimalPipe.transform(item.cur_bal_stk, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en'),
          cur_avai_stk: this.decimalPipe.transform(item.cur_avai_stk, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en')
        }
        dataReport.push(export_data);
      });
      this.exportExcelService.exportAsExcelFile(dataReport, this.translateService.instant('ITMITM100.M00105'), header, null, sub_title);
      dataReport.shift();
    })
  }

  exportDataByWh() {
    let sub_title = [];
    sub_title.push([this.translateService.instant('ITMITM100.M00100')]);
    let header: any = {
      item_product_code_seller: this.translateService.instant('ITMITM100.M00020'),
      item_product_code_klangOne: this.translateService.instant('ITMITM100.M00021'),
      itemSeller: this.translateService.instant('ITMITM100.M00130'),
      itemDesc: this.translateService.instant('ITMITM100.M00026'),
      item_brand: this.translateService.instant('ITMITM100.M00022'),
      merchandise_desc: this.translateService.instant('ITMITM100.M00023'),
      item_stock_unit: this.translateService.instant('ITMITM100.M00024'),
      warehouse: this.translateService.instant('ITMITM100.M00131'),
      country: this.translateService.instant('ITMITM100.M00132'),
      cur_bal_stk: this.translateService.instant('ITMITM100.M00127'),
      cur_avai_stk: this.translateService.instant('ITMITM100.M00128'),
    };
    let dataProdReport = []
    this.tableItemByWh.forEach((element) => {
      const dataReport = {
        item_product_code_seller: element.item_product_code_seller,
        item_product_code_klangOne: element.item_product_code_klangOne,
        itemSeller: this.translateLablePipe.transform(element.seller_desc_eng, element.seller_desc_local),
        itemDesc: this.translateLablePipe.transform(element.item_desc_eng, element.item_desc_local),
        item_brand: element.item_brand,
        merchandise_desc: this.translateLablePipe.transform(element.merchandise_desc_eng, element.merchandise_desc_local),
        item_stock_unit: element.item_stock_unit,
        warehouse: element.warehouse,
        country: element.country,
        cur_bal_stk: this.decimalPipe.transform(element.cur_bal_stk, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en'),
        cur_avai_stk: this.decimalPipe.transform(element.cur_avai_stk, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en')
      };
      dataProdReport.push(dataReport);
    });
    this.exportExcelService.exportAsExcelFile(dataProdReport, this.translateService.instant('ITMITM100.M00105'), header, null, sub_title);
    dataProdReport.shift();
  }

  exportDataProductExpiry() {
    let sub_title = [];
    sub_title.push([this.translateService.instant('ITMITM100.M00100')]);
    let header: any = {
      item_product_code_seller: this.translateService.instant('ITMITM100.M00020'),
      item_product_code_klangOne: this.translateService.instant('ITMITM100.M00021'),
      itemSeller: this.translateService.instant('ITMITM100.M00130'),
      itemDesc: this.translateService.instant('ITMITM100.M00026'),
      warehouse_code: this.translateService.instant('ITMITM100.M00131'),
      lot_no: this.translateService.instant('ITMITM100.M00147'),
      mfg_date: this.translateService.instant('ITMITM100.M00148'),
      exp_date: this.translateService.instant('ITMITM100.M00149'),
      threshold_days: this.translateService.instant('ITMITM100.M00150'),
      cur_bal_stk: this.translateService.instant('ITMITM100.M00127'),
      cur_avai_stk: this.translateService.instant('ITMITM100.M00128')
    };
    let dataProdReport = []
    this.tableItemProductExpiry.forEach((element) => {
      const dataReport = {
        item_product_code_seller: element.item_product_code_seller,
        item_product_code_klangOne: element.item_product_code_klangone,
        itemSeller: this.translateLablePipe.transform(element.seller_desc_eng, element.seller_desc_local),
        itemDesc: this.translateLablePipe.transform(element.item_desc_eng, element.item_desc_local),
        warehouse_code: this.translateLablePipe.transform(element.warehouse_desc_eng, element.warehouse_desc_local),
        lot_no: element.lot_no,
        mfg_date: element.mfg_date,
        exp_date: element.exp_date,
        threshold_days: element.threshold_days,
        cur_bal_stk: this.decimalPipe.transform(element.cur_bal_stk, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en'),
        cur_avai_stk: this.decimalPipe.transform(element.cur_avai_stk, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en')
      };
      dataProdReport.push(dataReport);
    });
    this.exportExcelService.exportAsExcelFile(dataProdReport, this.translateService.instant('ITMITM100.M00105'), header, null, sub_title);
    dataProdReport.shift();
  }

  getCountry(page) {
    this.autService.getParameterWmsWareHouse(page.value).subscribe(response => {
      this.warehouseData = JSON.parse(JSON.stringify(response));
      if (this.warehouseData.length == 0) {
        this.searchByWhGroupForm.controls['warehouse'].disable();
      } else if (this.warehouseData.length == 1) {
        this.searchByWhGroupForm.controls['warehouse'].disable();
        this.searchByWhGroupForm.controls['warehouse'].setValue(this.warehouseData[0].value);
      } else {
        this.searchByWhGroupForm.controls['warehouse'].enable();
      }
    }, err => {
      console.log(err);
    });
  }

  getWarehouse() {
    if (this.searchByWhGroupForm.controls['warehouse'].value != null || this.searchByWhGroupForm.controls['country'].value != null) {
      this.searchByWhDisabled = false;
    } else {
      this.searchByWhDisabled = true;
    }
  }

  getValue() {
    if (this.searchProductExpiryGroupForm.controls['warehouse_code'].value !== null ||
      this.searchProductExpiryGroupForm.controls['country_code'].value != null ||
      this.searchProductExpiryGroupForm.controls['lot_no'].value != null ||
      this.searchProductExpiryGroupForm.controls['itemValidate'].value != null ||
      this.searchProductExpiryGroupForm.controls['from_threshold_days'].value != null ||
      this.searchProductExpiryGroupForm.controls['to_threshold_days'].value != null ||
      this.searchProductExpiryGroupForm.controls['mfg_date'].value != null ||
      this.searchProductExpiryGroupForm.controls['exp_date'].value != null) {
      this.searchProductExpiryDisabled = false;
    } else {
      this.searchProductExpiryDisabled = true;
    }
  }

  ClearData() {
    if (this.sellerLength == 1) {
      this.searchDisabled = false;
      this.itm100.form.criteriaFormGroup.form.controls['seller'].setValue(this.selectedScopes);
    }
    else {
      this.searchDisabled = true;
      this.warehouse = null;
      this.itm100.form.criteriaFormGroup.form.controls['seller'].setValue(this.warehouse);
    }
    this.itm100.itemList = [];
    this.tableItemByWh = [];
    this.tableItemProductExpiry = [];
    this.exportDisabled = true;
    this.itm100.form.searchFormGroup.form.reset();
    this.dataNotFoundGrid2 = false;
    this.infoTableProduct = this.getTableProductData;
  }

  ClearDataByWh() {
    this.searchByWhGroupForm.reset();
    this.tableItemByWh = this.tableItemByWhTemp;
    this.autService.getParameterWmsCountry().subscribe((response) => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.optionCountry.length == 1) {
        this.searchByWhGroupForm.controls['country'].disable();
        this.searchByWhGroupForm.controls['country'].setValue(this.optionCountry[0].value);
        this.searchByWhGroupForm.controls['warehouse'].enable();
        this.getCountry(this.optionCountry[0]);
      } else if (this.optionCountry.length > 1) {
        this.searchByWhGroupForm.controls['country'].reset();
      } else if (this.optionCountry.length == 0) {
        this.searchByWhGroupForm.controls['country'].disable();
      }
    }, err => {
      console.log(err);
    });
    if (this.searchByWhGroupForm.controls['warehouse'].value != null || this.searchByWhGroupForm.controls['country'].value != null) {
      this.searchByWhDisabled = false;
    } else {
      this.searchByWhDisabled = true;
    }
    this.firstRow1 = 0;
  }

  ClearDataProductExpiryGroupFormh() {
    this.searchProductExpiryGroupForm.reset();
    if (this.searchProductExpiryGroupForm.controls['warehouse_code'].value != null ||
      this.searchProductExpiryGroupForm.controls['country_code'].value != null ||
      this.searchProductExpiryGroupForm.controls['lot_no'].value != null ||
      this.searchProductExpiryGroupForm.controls['itemValidate'].value != null ||
      this.searchProductExpiryGroupForm.controls['from_threshold_days'].value != null ||
      this.searchProductExpiryGroupForm.controls['to_threshold_days'].value != null ||
      this.searchProductExpiryGroupForm.controls['mfg_date'].value != null ||
      this.searchProductExpiryGroupForm.controls['exp_date'].value != null) {
      this.searchProductExpiryDisabled = false;
    } else {
      this.searchProductExpiryDisabled = true;
    }    
    this.firstRow2 = 0;
    this.dataNotFoundGrid = true;
    this.dataNotFoundProdline = true;
    this.tableItemProductExpiry = this.tableItemProductExpiryTemp;
  }

  checkDropDown() {
    this.autService.getParameterWmsSeller(this.param.country, this.param.warehouse).subscribe((res: ParameterEntity[]) => {
      this.sellerData = JSON.parse(JSON.stringify(res));
      if (this.sellerData.length == 1) {
        this.sellerLength = this.sellerData.length;
        this.searchDisabled = false;
        this.display = true;
        this.selectedScopes = [];
        this.sellerData.map(item => this.selectedScopes.push(item.value));
        this.itm100.form.criteriaFormGroup.form.controls['seller'].setValue(this.selectedScopes);

      }
      else if (this.sellerData.length == 0) {
        this.sellerLength = this.sellerData.length;
        this.display = true;
      }
      else if (this.sellerData.length > 1) {
        this.display = false;
      }
    }, err => {
      console.log(err);
    }
    );
  }

  getSellerCode(seller) {
    this.warehouse = seller.value;
    this.itm100.form.criteriaFormGroup.form.controls['seller'].setValue(this.warehouse);
    this.searchDisabled = false;
  }

  advanceSearchFunction() {
    if (this.searchForm.valid) {
      const key_search: string = this.searchForm.get('keySearch').value;
      if (key_search) {
        this.itm100.itemList = this.itemInquiryPage.itemTemp.filter((item: ItemModel) => {
          const concatValue: string = `${item.item_product_code_seller}${item.item_product_code_klangOne}${item.item_desc_local}${item.item_desc_eng}${item.item_brand}${item.merchandise_desc_local}${item.merchandise_desc_eng}${item.item_stock_unit}`;
          return concatValue.toLowerCase().indexOf(key_search.toLowerCase()) !== -1;
        });
        this.firstRow = 0;
      }
      else {
        this.itm100.itemList = this.itemInquiryPage.itemTemp;
      }
    }
  }

  automaticSearchFunction() {
    if (this.searchForm.valid) {    
      const key_search: string = this.searchForm.get('keySearchExpiry').value;
      if (key_search) {
        this.tableItemProductExpiry = this.tableItemProductExpiry.filter((item: ItemExpiryModel) => {
          const concatValue: string = `${item.item_product_code_seller}${item.item_product_code_klangone}${item.warehouse_code}${item.item_name}${item.warehouse_desc_eng}${item.warehouse_desc_local}${item.seller_desc_eng}${item.seller_desc_local}${item.mfg_date}${item.exp_date}${item.cur_avai_stk}${item.cur_bal_stk}${item.threshold_days}`;
          return concatValue.toLowerCase().indexOf(key_search.toLowerCase()) !== -1;
        });
        this.firstRow2 = 0;
      }
      else {
        if (this.searchProductExpiryGroupForm.controls['warehouse_code'].value != null ||
              this.searchProductExpiryGroupForm.controls['country_code'].value != null ||
              this.searchProductExpiryGroupForm.controls['lot_no'].value != null ||
              this.searchProductExpiryGroupForm.controls['itemValidate'].value != null ||
              this.searchProductExpiryGroupForm.controls['from_threshold_days'].value != null ||
              this.searchProductExpiryGroupForm.controls['to_threshold_days'].value != null ||
              this.searchProductExpiryGroupForm.controls['mfg_date'].value != null ||
              this.searchProductExpiryGroupForm.controls['exp_date'].value != null) {
                this.tableItemProductExpiry = this.tableItemProductExpirySearchTemp;
        } else {
            this.tableItemProductExpiry = this.tableItemProductExpiryTemp;
        }     
      }
    }
  }

  searchByWhSubmit() {
    this.tableItemByWh = this.tableItemByWhTemp.filter(data => {
      let warehouse = this.searchByWhGroupForm.controls['warehouse'].value != null ? this.searchByWhGroupForm.controls['warehouse'].value : data.warehouse;
      let country = this.searchByWhGroupForm.controls['country'].value != null ? this.searchByWhGroupForm.controls['country'].value : data.country;
      return data.warehouse.toLowerCase() == warehouse.toLowerCase() &&
        data.country.toLowerCase() == country.toLowerCase();
    });
    this.firstRow = 0;
  }

  searchProductExpiry() {
    const warehouse = this.searchProductExpiryGroupForm.controls['warehouse_code'].value;
    const country = this.searchProductExpiryGroupForm.controls['country_code'].value;
    const lotNo = this.searchProductExpiryGroupForm.controls['lot_no'].value;
    let fromThresholdDays = this.searchProductExpiryGroupForm.controls['from_threshold_days'].value;
    let toThresholdDays = this.searchProductExpiryGroupForm.controls['to_threshold_days'].value;
    if((fromThresholdDays == null || fromThresholdDays == '') && Number(toThresholdDays) > 0){
      fromThresholdDays =  '0';
    }
    if((toThresholdDays == null || toThresholdDays == '') && Number(fromThresholdDays) > 0){
      toThresholdDays =  fromThresholdDays;
    } 
    let mfgDateFrom;
    let mfgDateTo;
    let expDateFrom;
    let expDateTo;
    if(this.searchProductExpiryGroupForm.controls['mfg_date'].value){
      mfgDateFrom = this.searchProductExpiryGroupForm.controls['mfg_date'].value[0];
      mfgDateTo = this.searchProductExpiryGroupForm.controls['mfg_date'].value[1];
      if(!mfgDateTo){
        mfgDateTo = mfgDateFrom
      }
    }
    if(this.searchProductExpiryGroupForm.controls['exp_date'].value){
      expDateFrom = this.searchProductExpiryGroupForm.controls['exp_date'].value[0];
      expDateTo = this.searchProductExpiryGroupForm.controls['exp_date'].value[1];
      if(!expDateTo){
        expDateTo = expDateFrom
      }
    }
    const validate = this.searchProductExpiryGroupForm.controls['itemValidate'].value;
    if(validate){
      this.setDataProductExpiry(validate);
    }
    this.tableItemProductExpiry = this.tableItemProductExpiryTemp.filter(data => {
      const warehouseMatch = warehouse ? data.warehouse_code.toString().toLowerCase().includes(warehouse) : true;
      const countryMatch = country ? data.country_code.toString().toLowerCase().includes(country.toLowerCase()) : true;
      const lotNoMatch = lotNo ? data.lot_no.toString().toLowerCase().includes(lotNo.toLowerCase()) : true;
      const thresholdDaysMatch = fromThresholdDays ? Number(fromThresholdDays) <= Number(data.threshold_days) && Number(toThresholdDays) >= Number(data.threshold_days): true;
      const [mfg_day, mfg_month, mfg_year] = data.mfg_date != null && data.mfg_date != '' ? data.mfg_date.split('/') : '';
      const [exp_day, exp_month, exp_year] = data.exp_date != null && data.exp_date != '' ? data.exp_date.split('/') : '';
      const mfgDateMatch = mfgDateFrom && mfgDateTo ? data.mfg_date != null && data.mfg_date != '' && (mfgDateFrom <= new Date(+mfg_year, +mfg_month - 1, +mfg_day)) && (new Date(+mfg_year, +mfg_month - 1, +mfg_day) <= mfgDateTo) : true ;     
      const expDateMatch = expDateFrom && expDateTo ? data.exp_date != null && data.exp_date != '' && (expDateFrom <= new Date(+exp_year, +exp_month - 1, +exp_day)) && (new Date(+exp_year, +exp_month - 1, +exp_day) <= expDateTo) : true ;
      const validate1 = validate && validate =='1' ? Number(data.exp_threshold_days) > 0 : true;
      const validate2 = validate && validate =='2' ? Number(data.exp_threshold_days) < 0 : true;
      const validate3 = validate && validate =='3' ? data.mfg_date != null && data.mfg_date != '' : true; 
      const validate4 = validate && validate =='4' ? Number(data.exp_threshold_days) == 0 : true;
      return warehouseMatch && countryMatch && lotNoMatch  && thresholdDaysMatch  && validate1 && validate2 && validate3 && validate4 && mfgDateMatch && expDateMatch;
    });
    this.tableItemProductExpirySearchTemp = this.tableItemProductExpiry;
    this.searchProductExpiryDisabled = true;
    this.firstRow2 = 0;
  }

  showAdvancedSearch() {
    if (this.hideAdvancedSearch == true) {
      this.hideAdvancedSearch = false;
    } else {
      this.hideAdvancedSearch = true;
    }
  }

  changeFromThreshold(){
    if (this.searchProductExpiryGroupForm.controls['from_threshold_days'].value == '0'){
      this.searchProductExpiryGroupForm.controls['to_threshold_days'].setValue(0);
    }else if (this.searchProductExpiryGroupForm.controls['from_threshold_days'].value == null || this.searchProductExpiryGroupForm.controls['from_threshold_days'].value == ''){
      this.searchProductExpiryGroupForm.controls['to_threshold_days'].setValue(null);
    }
  }

  changeToThreshold(){
  }
}
