import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Menu } from 'src/app/shared/model/menu.model';
import { AdministrationMenuService } from 'src/app/shared/services/administration-menu.service';


@Component({
  selector: 'app-homhom100',
  templateUrl: './homhom100.component.html',
  styleUrls: ['./homhom100.component.scss']
})
export class Homhom100Component implements OnInit {

  public groupMenuData: Array<Menu> = [] as Array<Menu>;
  public visibleSidebar: boolean = false as boolean;
  // public menuData: any = [];
  public profileId: number;
  public Country: string;

  public loadingFlag: boolean = false as boolean;

  constructor(
    private router: Router,
    private admmnuService: AdministrationMenuService
  ) {
    this.profileId = JSON.parse(localStorage.getItem('userData')).profileId;
    this.Country = localStorage.getItem('languageData') as string;
  }

  ngOnInit() {
    this.getMenuHome();
  }

  goTopage(page: Menu) {
      this.router.navigate([page.menuRout]);
      localStorage.setItem('menuName', page.menuDesc);
  }

  getMenuHome() {
    this.admmnuService.getMenuHome(this.Country).subscribe((res: Array<Menu>) => {
      this.groupMenuData = res.filter(data => data.subMenu.length > 0);
      this.groupMenuData.sort((a: { menuOrbid: number; }, b: { menuOrbid: number; }) => a.menuOrbid - b.menuOrbid);
    });
  }

}
