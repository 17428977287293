import { Component, OnInit, Input } from '@angular/core';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductDetail } from 'src/app/shared/model/product.model';
import { ItemService } from 'src/app/shared/services/item.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { FormGroup, FormControl, FormControlName } from '@angular/forms';
import { DecimalData , DecimalProp, StockByWh} from 'src/app/itm/model/itm-model'
import { DecimalService } from './../../shared/services/decimal.service';
import { decimalTypePurchase, decimalTypeRoundMethod, decimalTypeRoundToValue, decimalTypeSelling } from 'src/app/shared/constant/decimal.constant';
import {  ItemByWhModel, ItemInquiryByWhRequest, ItemPage } from 'src/app/shared/model/item.model';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-itmitm200',
  templateUrl: './itmitm200.component.html',
  styleUrls: ['./itmitm200.component.scss']
})
export class Itmitm200Component implements OnInit {

  public ItemPage: ItemPage = {} as ItemPage;
  barcode: string;
  productDetailData = {} as ProductDetail;
  dataProd: any = [];
  dataProdReport: any = [];
  dataProdExport: any = [];
  seller: string = 'admin' as string;
  userIdToEdit;
  pageName: string;
  loadingFlag: boolean = false as boolean;
  status: boolean;
  dataNotFoundGrid: boolean = true;
  sellerTemp:string;
  getCustomerTemp:string;
  translateLablePipe = new TranslateLablePipe();
  programId: string = 'P70000' as string;

  decimalProp:DecimalProp[] = [] as DecimalProp[];
  decimalPropTemp: DecimalProp = {} as DecimalProp;

  decimalData:DecimalData = {} as DecimalData;
  itmAtr = [];
  cols=[];
  tableStockByWh : Array<StockByWh> = [] as  Array<StockByWh>;
  itemByWh : Array<ItemByWhModel> = [] as  Array<ItemByWhModel>;

  firstRow: number = 0 as number;
  typeDecimalSelling: string = '2';
  decimalSelling: string;
  dataTypeDecimalSelling:any;

  sellerDetailForm = new FormGroup({

    itemCode: new FormControl({ value: '', disabled: true }),

    //ข้อมูลสินค้า
    itemCodeSeller: new FormControl({ value: '', disabled: true }),
    itemCodeKlangone: new FormControl({ value: '', disabled: true }),
    itemDescEng: new FormControl({ value: '', disabled: true }),
    itemDescLocal: new FormControl({ value: '', disabled: true }),
    itemType : new FormControl({ value: '', disabled: true }),
    itemCreateDate : new FormControl({ value: '', disabled: true }),
    itemCategoty : new FormControl({ value: '', disabled: true }),
    itemSubCategoty : new FormControl({ value: '', disabled: true }),
    itemSeller : new FormControl({ value: '', disabled: true }),
    itemBrand :  new FormControl({ value: '', disabled: true }),

    //คุณลักษณะเฉพาะ
    getProductFromCustomer : new FormControl({ value: '', disabled: true }),
    returnToSeller : new FormControl({ value: '', disabled: true }),
    productMovementType : new FormControl({ value: '', disabled: true }),
    UsageDay  : new FormControl({ value: '', disabled: true }),
    shelfLifeUom  : new FormControl({ value: '', disabled: true }),


    //ข้อมูลการจัดเก็บ
    unit : new FormControl({ value: '', disabled: true }),
    quantityPerCate : new FormControl({ value: '', disabled: true }),
    storageWeight : new FormControl({ value: '', disabled: true }),
    storageWide : new FormControl({ value: '', disabled: true }),
    storageLong : new FormControl({ value: '', disabled: true }),
    storageHigh : new FormControl({ value: '', disabled: true }),

    //ข้อมูลการส่งออกและสั่ง
    orderUnit : new FormControl({ value: '', disabled: true }),
    quantitySKU : new FormControl({ value: '', disabled: true }),
    serial : new FormControl({ value: '', disabled: true }),
    minimumOrder : new FormControl({ value: '', disabled: true }),

    //คุณลักษณะเพิ่มเติม
    classDetails : new FormControl({ value: '', disabled: true }),
    codeDetails : new FormControl({ value: '', disabled: true }),
    cost :new FormControl({ value: '', disabled: true }),

    //Stock Information
    cur_bal_stk : new FormControl({ value: '', disabled: true }),
    cur_avai_stk : new FormControl({ value: '', disabled: true })

  });

  constructor(private itmService: ItemService,
              private exportExcelService: ExportExcelService,
              private router: Router,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              private parameterService:ParameterService,
              private decimalService:DecimalService,
              private decimalPipe: DecimalPipe ){
    this.ItemPage.itemSellerCode = this.route.snapshot.paramMap.get('itemSellerCode');
    this.seller = JSON.parse(localStorage.getItem('userData')).itemSellerCode;
    // this.barcode = localStorage.getItem('product/barcodeBySeller');
    this.barcode =  this.route.snapshot.paramMap.get('itemCode');
    this.pageName = localStorage.getItem(this.programId);
    this.status = false;
    this.cols = [
      { field: 'class', header: 'ITMITM200.M00080', type: 'string' },
      { field: 'code', header: 'ITMITM200.M00081', type: 'string' },
      { field: 'value', header: 'ITMITM200.M00082', type: 'string' }
    ];
    this.parameterService.getDiscoutPrice(104,'GB',this.typeDecimalSelling).subscribe(dataRound=>{
      this.dataTypeDecimalSelling = JSON.parse(JSON.stringify(dataRound));
      this.decimalSelling = this.dataTypeDecimalSelling.pmdtbdnumv1;
    });
    this.getSellerDetail();
  }

  getSellerDetail() {
    let sellerDataTemp;

    this.itmService.getDataProductDetailByBarcode(this.barcode).subscribe(response => {
      sellerDataTemp = JSON.parse(JSON.stringify(response));

      this.tableStockByWh = sellerDataTemp.stock_by_wh;

      if (sellerDataTemp.attributeData != null) {
        sellerDataTemp.attributeData.forEach(element => {
          this.itmAtr.push({
            class: element.attribute_class,
            code: element.attribute_code,
            value: element.attribute_value,
          });
        });
      }
      this.sellerDetailForm.patchValue({

        //itemCode: sellerDataTemp.itemCode,

        //ข้อมูลสินค้า
        itemCodeSeller: sellerDataTemp.item_product_code_seller,
        itemCodeKlangone: sellerDataTemp.item_product_code_klangOne,
        itemDescEng: sellerDataTemp.item_english,
        itemDescLocal: sellerDataTemp.item_local,
        itemType : this.translateLablePipe.transform(sellerDataTemp.item_type_desc_eng,sellerDataTemp.item_type_desc_local),
        itemCreateDate : sellerDataTemp.item_creation_date,
        itemCategoty : this.translateLablePipe.transform(sellerDataTemp.item_merchandise_hierarchy_desc_eng,sellerDataTemp.item_merchandise_hierarchy_desc_local),
        itemSubCategoty : this.translateLablePipe.transform(sellerDataTemp.item_sub_merchandise_hierarchy_eng,sellerDataTemp.item_sub_merchandise_hierarchy_local),
        itemSeller : this.translateLablePipe.transform(sellerDataTemp.item_seller_name_eng,sellerDataTemp.item_seller_name_local),
        itemBrand :  sellerDataTemp.item_brand,

        //คุณลักษณะเฉพาะ
        getProductFromCustomer : sellerDataTemp.item_allow_rtn_from_cus_flg,
        returnToSeller : sellerDataTemp.item_allow_rtn_to_selle,
        productMovementType : this.translateLablePipe.transform(sellerDataTemp.item_pre_ord_flag_eng,sellerDataTemp.item_pre_ord_flag_local),
        UsageDay: this.decimalPipe.transform(sellerDataTemp.usage_day, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en'),
        shelfLifeUom: sellerDataTemp.shelf_life_uom,

        //ข้อมูลการจัดเก็บ
        unit : sellerDataTemp.item_stock_unit,
        quantityPerCate : sellerDataTemp.item_capacity,
        storageWeight : sellerDataTemp.item_gross_weight,
        storageWide : sellerDataTemp.item_width,
        storageLong : sellerDataTemp.item_lenght,
        storageHigh : sellerDataTemp.item_height,

        //ข้อมูลการส่งออกและสั่ง
        orderUnit : sellerDataTemp.item_order_unit,
        quantitySKU : sellerDataTemp.item_sku,
        serial : sellerDataTemp.item_manage_by_serial_flg,
        minimumOrder : null,

        //คุณลักษณะเพิ่มเติม
        classDetails : null,
        codeDetails : null,
        cost : null,

        //Stock Information
        cur_bal_stk : this.decimalPipe.transform(sellerDataTemp.cur_bal_stk, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en'),
        cur_avai_stk : this.decimalPipe.transform(sellerDataTemp.cur_avai_stk, '1.' + this.decimalSelling + '-' + this.decimalSelling, 'en')
      });
      this.sellerTemp = sellerDataTemp.item_allow_rtn_to_seller;
      this.getCustomerTemp = sellerDataTemp.item_allow_rtn_from_cus_flg;
      this.getCustomerTemp  = this.getCustomerTemp  === "1"? "No": "Yes";
      if(sellerDataTemp.item_allow_rtn_to_seller.toLowerCase() == "yes" ){
        this.sellerDetailForm.controls['returnToSeller'].setValue(true);
      }else{
         this.sellerDetailForm.controls['returnToSeller'].setValue(false) ;
       }
      if(sellerDataTemp.item_allow_rtn_from_cus_flg == "1"){
        this.sellerDetailForm.controls['getProductFromCustomer'].setValue(false);
      }
      else{
       this.sellerDetailForm.controls['getProductFromCustomer'].setValue(true);
      }

      /*console.log(sellerDataTemp);
      console.log(this.ItemPage.itemSellerCode );*/
    })
    let itmInquiry:  ItemInquiryByWhRequest = {} as  ItemInquiryByWhRequest ;
    itmInquiry.itemCode = this.barcode;
    this.itmService.getItemInquiryByWh(itmInquiry).subscribe(response => {
        this.itemByWh = JSON.parse(JSON.stringify(response));
    });

  }

  ngOnInit() {

  }

  backBtn() {
    this.router.navigate(['/itmitm/itmitm100'])

  }

  getFormatNumber(country){
    this.parameterService.getvprmdtltolableByCountry(104, country).subscribe(response => {
      let decimalData = JSON.parse(JSON.stringify(response));
        this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypePurchase);
        this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeSelling);
        this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundMethod);
        this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == decimalTypeRoundToValue);
        this.decimalPropTemp = {} as DecimalProp
        this.decimalPropTemp.type = country;
        this.decimalPropTemp.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1;
        this.decimalPropTemp.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
        this.decimalPropTemp.roundToDecimal= this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
        // index [0] country = 'KH' , index [1] country = 'LA' , index [0] country = 'TH'
        this.decimalProp.push(this.decimalPropTemp)
    }, err => {
      console.log(err);
    });
  }

 /* exportData() {
    const subTitle = [];
    subTitle.push([this.translateService.instant('ITMITM100.M00063')]);
    const header: any = {};
    this.dataProdExport = [];
    this.dataProd.forEach((element) => {
      const dataItemExport = [
        { dataheader: this.translateService.instant('ITMITM200.M00052'), dataItem: element.item_code },
        { dataheader: this.translateService.instant('ITMITM200.M00053'), dataItem: element.item_product_code_klangOne },
        { dataheader: this.translateService.instant('ITMITM200.M00005'), dataItem: element.item_type_desc },
        { dataheader: this.translateService.instant('ITMITM200.M00066'), dataItem: element.item_english },
        { dataheader: this.translateService.instant('ITMITM200.M00067'), dataItem: element.item_local },
        { dataheader: this.translateService.instant('ITMITM200.M00068'), dataItem: element.item_creation_date },
        /*{ dataheader: this.translateService.instant('ITMITM200.M00055'), dataItem: element.inventoryornoninventory },
        { dataheader: this.translateService.instant('ITMITM200.M00002'), dataItem: element.mainprodorassemblyproduct },
        { dataheader: this.translateService.instant('ITMITM200.M00003'), dataItem: element.produnit },
        { dataheader: this.translateService.instant('ITMITM200.M00006'), dataItem: element.sellercode },
        { dataheader: this.translateService.instant('ITMITM200.M00007'), dataItem: element.lacationname },
        { dataheader: this.translateService.instant('ITMITM200.M00070'), dataItem: element.weightperpiece },
        { dataheader: this.translateService.instant('ITMITM200.M00071'), dataItem: element.widthperpiece },
        { dataheader: this.translateService.instant('ITMITM200.M00716'), dataItem: element.lengthperpiece },
        { dataheader: this.translateService.instant('ITMITM200.M00009'), dataItem: element.heightperpiece },
        { dataheader: this.translateService.instant('ITMITM200.M00010'), dataItem: element.cartonbarcode },
        { dataheader: this.translateService.instant('ITMITM200.M00011'), dataItem: element.numberofpiecespercarton },
        { dataheader: this.translateService.instant('ITMITM200.M00012'), dataItem: element.weightpercarton },
        { dataheader: this.translateService.instant('ITMITM200.M00015'), dataItem: element.widthpercarton },
        { dataheader: this.translateService.instant('ITMITM200.M00075'), dataItem: element.lengthpercarton },
        { dataheader: this.translateService.instant('ITMITM200.M00076'), dataItem: element.heightpercarton },
        { dataheader: this.translateService.instant('ITMITM200.M00077'), dataItem: element.desshort },
        { dataheader: this.translateService.instant('ITMITM200.M00078'), dataItem: element.deslong },
        { dataheader: this.translateService.instant('ITMITM200.M00080'), dataItem: element.prodpicture },
        { dataheader: this.translateService.instant('ITMITM200.M00081'), dataItem: element.linkVDO },
        { dataheader: this.translateService.instant('ITMITM200.M00082'), dataItem: element.linkwebsitekh },
        { dataheader: this.translateService.instant('ITMITM100.M00022'), dataItem: element.linkwebsiteth },
        { dataheader: this.translateService.instant('ITMITM100.M00023'), dataItem: element.linkwebsitela },
        { dataheader: this.translateService.instant('ITMITM100.M00024'), dataItem: this.decimalService.getRoundingDecimal(element.sellingpricekh, this.decimalProp[0].method, this.decimalProp[0].roundToDecimal, this.decimalProp[0].decimal)},
        { dataheader: this.translateService.instant('ITMITM100.M00025'), dataItem: this.decimalService.getRoundingDecimal(element.fullpricekh, this.decimalProp[0].method, this.decimalProp[0].roundToDecimal, this.decimalProp[0].decimal) },
        { dataheader: this.translateService.instant('ITMITM100.M00026'), dataItem: this.decimalService.getRoundingDecimal(element.sellingpricela, this.decimalProp[1].method, this.decimalProp[1].roundToDecimal, this.decimalProp[1].decimal)},
        { dataheader: this.translateService.instant('ITMITM100.M00027'), dataItem: this.decimalService.getRoundingDecimal(element.fullpricela, this.decimalProp[1].method, this.decimalProp[1].roundToDecimal, this.decimalProp[1].decimal) },
        { dataheader: this.translateService.instant('ITMITM100.M00028'), dataItem: this.decimalService.getRoundingDecimal(element.sellingpriceth, this.decimalProp[2].method, this.decimalProp[2].roundToDecimal, this.decimalProp[2].decimal) },
        { dataheader: this.translateService.instant('ITMITM100.M00029'), dataItem: this.decimalService.getRoundingDecimal(element.fullpriceth, this.decimalProp[2].method, this.decimalProp[2].roundToDecimal, this.decimalProp[2].decimal) },
        { dataheader: this.translateService.instant('ITMITM100.M00031'), dataItem: element.numberofpiecesperpromotion },
        { dataheader: this.translateService.instant('ITMITM100.M00032'), dataItem: element.wholesaleprice === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00033'), dataItem: element.freedelivertplusCOD === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00034'), dataItem: element.useFFM === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00035'), dataItem: element.useCRM === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00036'), dataItem: element.useclearance === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00037'), dataItem: element.percentplatform },
        { dataheader: this.translateService.instant('ITMITM100.M00038'), dataItem: element.percentCRM },
        { dataheader: this.translateService.instant('ITMITM100.M00039'), dataItem: element.percentclearance },
        { dataheader: this.translateService.instant('ITMITM100.M00040'), dataItem: element.countryactivekh === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00041'), dataItem: element.countryactiveth === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00042'), dataItem: element.countryactivela === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00044'), dataItem: element.ecommerceactivekh === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00045'), dataItem: element.ecommerceactiveth === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00046'), dataItem: element.ecommerceactivela === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00048'), dataItem: element.klangthaimall === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00049'), dataItem: element.thaitrademall === true ? 'Yes' : 'No' },
        { dataheader: this.translateService.instant('ITMITM100.M00050'), dataItem: element.aseanlocalmall === true ? 'Yes' : 'No' }
      ];

      dataItemExport.forEach(element => {
        const dataExport = {
          dataheader: element.dataheader,
          dataItem: element.dataItem
        };
        this.dataProdExport.push(dataExport);
      });

    });
     console.log(this.dataProdExport)
    this.exportExcelService.exportAsExcelFile(this.dataProdExport, this.translateService.instant('ITMITM100.M00065'), header, null, subTitle);
    this.dataProdExport.shift();
  }*/

  exportData() {
    const subTitle = [];
    this.dataProdExport = [];

    subTitle.push([this.translateService.instant('ITMITM200.M00085')]);
    const header: any = {
      item_product_code_seller: this.translateService.instant('ITMITM200.M00052'),
      item_product_code_klangOne: this.translateService.instant('ITMITM200.M00053'),
      item_desc: this.translateService.instant('ITMITM200.M00005'),
      item_english: this.translateService.instant('ITMITM200.M00066'),
      item_local: this.translateService.instant('ITMITM200.M00067'),
      item_creation_date: this.translateService.instant('ITMITM200.M00068'),

      item_brand: this.translateService.instant('ITMITM200.M00055'),
      item_merchandise_hierarchy_desc: this.translateService.instant('ITMITM200.M00002'),
      item_sub_merchandise_hierarchy: this.translateService.instant('ITMITM200.M00003'),
      item_stock_unit: this.translateService.instant('ITMITM200.M00006'),
      item_seller_name: this.translateService.instant('ITMITM200.M00007'),
      item_allow_rtn_from_cus_flg: this.translateService.instant('ITMITM200.M00070'),

      item_allow_rtn_to_selle: this.translateService.instant('ITMITM200.M00071'),
      item_pre_ord_flag : this.translateService.instant('ITMITM200.M00084'),
      item_gross_weight: this.translateService.instant('ITMITM200.M00009'),
      item_width: this.translateService.instant('ITMITM200.M00010'),
      item_lenght: this.translateService.instant('ITMITM200.M00011'),
      item_height: this.translateService.instant('ITMITM200.M00012'),

      item_capacity: this.translateService.instant('ITMITM200.M00015'),
      item_order_unit: this.translateService.instant('ITMITM200.M00075'),
       item_sku: this.translateService.instant('ITMITM200.M00076'),
       item_manage_by_serial_flg: this.translateService.instant('ITMITM200.M00077'),
      // minimumOrder: this.translateService.instant('ITMITM200.M00078'),
       classDetails: this.translateService.instant('ITMITM200.M00080'),
       codeDetails: this.translateService.instant('ITMITM200.M00081'),
       cost: this.translateService.instant('ITMITM200.M00082'),
       warehouse: this.translateService.instant('ITMITM200.M00090'),
       country: this.translateService.instant('ITMITM200.M00091'),
       cur_bal_stk: this.translateService.instant('ITMITM200.M00087'),
       cur_ava_stk: this.translateService.instant('ITMITM200.M00088'),



    };
    this.dataProdReport = [];
    //console.log(this.sellerDetailForm.controls['itemCode'].value);
    var classDetails;
    var codeDetails;
    var cost;
    let classDetailsList =[];
    let codeDetailsList =[];
    let costList =[];
    this.itmAtr.forEach(e=>{
      classDetailsList.push(e.class);
      codeDetailsList.push(e.code);
      costList.push(e.value);
    });
      classDetails = classDetailsList.join(", ");
      codeDetails = codeDetailsList.join(", ");
      cost = costList.join(", ");

    if(this.itemByWh != null){
        this.itemByWh.forEach((element) => {
            const dataReport = {
              item_product_code_seller: element.item_product_code_seller,
              item_product_code_klangOne: element.item_product_code_klangOne,
              item_desc: this.translateLablePipe.transform(element.item_type_desc_eng, element.item_type_desc_local),
              item_english: this.sellerDetailForm.controls['itemDescEng'].value,
              item_local: this.sellerDetailForm.controls['itemDescLocal'].value,
              item_creation_date: this.sellerDetailForm.controls['itemCreateDate'].value,
              item_brand: this.sellerDetailForm.controls['itemBrand'].value,
              item_merchandise_hierarchy_desc: this.sellerDetailForm.controls['itemCategoty'].value,
              item_sub_merchandise_hierarchy: this.translateLablePipe.transform(element.merchandise_desc_eng, element.merchandise_desc_local),
              item_stock_unit: element.item_stock_unit,
              item_seller_name: this.translateLablePipe.transform(element.seller_desc_eng, element.seller_desc_local),
              item_allow_rtn_from_cus_flg: this.getCustomerTemp,
              item_allow_rtn_to_selle: this.sellerTemp,
              item_pre_ord_flag: this.sellerDetailForm.controls['productMovementType'].value,
              item_gross_weight: this.sellerDetailForm.controls['storageWeight'].value,
              item_width: this.sellerDetailForm.controls['storageWide'].value,
              item_lenght: this.sellerDetailForm.controls['storageLong'].value,
              item_height: this.sellerDetailForm.controls['storageHigh'].value,
              item_capacity:this.sellerDetailForm.controls['quantityPerCate'].value,
              item_order_unit: this.sellerDetailForm.controls['orderUnit'].value,
              item_sku: this.sellerDetailForm.controls['quantitySKU'].value,
              item_manage_by_serial_flg: this.sellerDetailForm.controls['serial'].value,
              usage_day: this.sellerDetailForm.controls['UsageDay'].value,
              //item_minimumOrder: this.sellerDetailForm.controls['minimumOrder'].value,
              classDetails: classDetails,
              codeDetails: codeDetails,
              cost: cost,
              warehouse: element.warehouse,
              country: element.country,
              cur_bal_stk: element.cur_bal_stk == null ? 0 : element.cur_bal_stk,
              cur_ava_stk: element.cur_avai_stk == null ? 0 : element.cur_avai_stk,
              };
            this.dataProdReport.push(dataReport);
          });
    }else{
        const dataReport = {
            item_product_code_seller: this.sellerDetailForm.controls['itemCodeSeller'].value,
            item_product_code_klangOne: this.sellerDetailForm.controls['itemCodeKlangone'].value,
            item_desc: this.sellerDetailForm.controls['itemType'].value,
            item_english: this.sellerDetailForm.controls['itemDescEng'].value,
            item_local: this.sellerDetailForm.controls['itemDescLocal'].value,
            item_creation_date: this.sellerDetailForm.controls['itemCreateDate'].value,
            item_brand: this.sellerDetailForm.controls['itemBrand'].value,
            item_merchandise_hierarchy_desc: this.sellerDetailForm.controls['itemCategoty'].value,
            item_sub_merchandise_hierarchy: this.sellerDetailForm.controls['itemSubCategoty'].value,
            item_stock_unit: this.sellerDetailForm.controls['unit'].value,
            item_seller_name: this.sellerDetailForm.controls['itemSeller'].value,
            item_allow_rtn_from_cus_flg: this.getCustomerTemp,
            item_allow_rtn_to_selle: this.sellerTemp,
            item_pre_ord_flag: this.sellerDetailForm.controls['productMovementType'].value,
            item_gross_weight: this.sellerDetailForm.controls['storageWeight'].value,
            item_width: this.sellerDetailForm.controls['storageWide'].value,
            item_lenght: this.sellerDetailForm.controls['storageLong'].value,
            item_height: this.sellerDetailForm.controls['storageHigh'].value,
            item_capacity:this.sellerDetailForm.controls['quantityPerCate'].value,
            item_order_unit: this.sellerDetailForm.controls['orderUnit'].value,
            item_sku: this.sellerDetailForm.controls['quantitySKU'].value,
            item_manage_by_serial_flg: this.sellerDetailForm.controls['serial'].value,
            usage_day: this.sellerDetailForm.controls['UsageDay'].value,
            //item_minimumOrder: this.sellerDetailForm.controls['minimumOrder'].value,
            classDetails: classDetails,
            codeDetails: codeDetails,
            cost: cost,
            cur_bal_stk: this.sellerDetailForm.controls['cur_bal_stk'].value == null ? 0 : this.sellerDetailForm.controls['cur_bal_stk'].value,
            cur_ava_stk: this.sellerDetailForm.controls['cur_avai_stk'].value == null ? 0 : this.sellerDetailForm.controls['cur_avai_stk'].value,
            };
          this.dataProdReport.push(dataReport);
    }

      //console.log(element)
    //console.log(this.dataProdReport);
    this.exportExcelService.exportAsExcelFile(this.dataProdReport, this.translateService.instant('ITMITM200.M00086'), header, null, subTitle);
    this.dataProdReport.shift();
  }
}
