import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { pur, ser, walletData } from 'src/app/shared/model/dashboard-finance.model';
import { MonthData, PeriodDropDownListModel } from 'src/app/shared/model/dashboard-stock.model';
import { DropDownListModel } from 'src/app/shared/model/dropdownlist.model';
import { DashboardFinancialService } from 'src/app/shared/services/dashboard-finance.service';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';

@Component({
  selector: 'app-dshfna200',
  templateUrl: './dshfna200.component.html',
  styleUrls: ['./dshfna200.component.scss']
})
export class Dshfna200Component implements OnInit {

  periodFrom = new FormGroup({
    year: new FormControl(),
    month: new FormControl()
  });

  public loadingFlag = false;
  public isDataNotFoundFlag = false;

  private period: PeriodDropDownListModel = {} as PeriodDropDownListModel;
  public yearList: Array<DropDownListModel> = [] as Array<DropDownListModel>;
  public monthList: Array<DropDownListModel> = [] as Array<DropDownListModel>;
  placeholderMonth = 'ไม่พบข้อมูล';
  searchPurGroupForm = new FormGroup({
    listNameForm: new FormControl(),
    verdorNameForm: new FormControl(),
    purchase_dateForm: new FormControl(),
    listStatusForm: new FormControl(),
    paymentStatusForm: new FormControl()
  });

  searchSerGroupForm = new FormGroup({
    listNameForm: new FormControl(),
    customerNameForm: new FormControl(),
    sellerdateForm: new FormControl(),
    listStatusForm: new FormControl(),
    paymentStatusForm: new FormControl()
  });

  seller = 'admin';

  walletDataGrid: walletData = {} as walletData;
  pur: Array<pur[]> = [{}] as Array<pur[]>;
  sal: Array<ser[]> = [{}] as Array<ser[]>;
  walletChart: any;
  options: any;
  firstDoc = 0;
  firstDocDate = 0;
  IsSearchGridTab1 = false;
  IsSearchGridTab2 = false;
  dataNotFound = true;
  dataNotFoundGridTab1 = true;
  dataNotFoundGridTab2 = true;
  criteriaSearchWalGridTab1 = { listNameForm: '', verdorNameForm: '', purchase_dateForm: '', listStatusForm: '', paymentStatusForm: '' };
  criteriaSearchWalGridTab2 = { listNameForm: '', customerNameForm: '', sellerdateForm: '', listStatusForm: '', paymentStatusForm: '' };
  searchGridTab1GroupForm = new FormGroup({
    walletDocument: new FormControl(),
    walletDetail: new FormControl(),
    walletCode: new FormControl(),
  });
  searchGridTab2GroupForm = new FormGroup({
    walletDate: new FormControl(),
    walletDocument: new FormControl(),
    walletDetail: new FormControl(),
    walletCode: new FormControl(),
  });
  invoiceDate: any;
  periodLabel: string;
  getWalletByDocData: any = [];
  getWalletByDateAndDocData: any = [];
  count = 0;
  th: any;
  walletValueAmount = 0;
  defaultMonth: '';
  defaultYear: { label: '2020', value: '2020' };
  dataYear: SelectItem[];
  dataMonth: SelectItem[];
  dataNotFoundAll = false;
  isRight = false;
  isIpad = false;

  DataPur: any;
  DataSal: any;
  newDate: Date;
  DataSum: any;
  tempS: any;
  tempP: any;
  getMonth: any = [];
  getYear: any = [];
  getDate: any;
  // period = '';
  searchDate: any;
  tempA: any;
  showMonth: any;
  thisMonth: '';
  pageName: string;

  private dropdownListNoDataFound: Array<DropDownListModel> = [{label: 'ไม่พบข้อมูล', value : null,engDesc:'No Data Found', localDesc:'ไม่พบข้อมูล'}];

  programId: string = 'P12002' as string;

  constructor(
    private dshfnaService: DashboardFinancialService,
    private exportExcelService: ExportExcelService) {
    this.pageName = localStorage.getItem(this.programId);
    // this.getDataDate();
    this.monthList = this.dropdownListNoDataFound;
    this.yearList = this.dropdownListNoDataFound;
    // this.optionData = this.dropdownListNoDataFound;
  }

  ngOnInit() {
    if (window.screen.width <= 640) {
      this.isRight = false;
    } else {
      this.isRight = true;
      if (window.screen.width > 640 && window.screen.width <= 1024) {
        this.isIpad = true;
      }
    }
    this.th = {
      firstDayOfWeek: 0,
      dayNames: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
      dayNamesShort: ['อา', 'จัน', 'อัง', 'พุธ', 'พฤ', 'ศุก', 'เสา'],
      dayNamesMin: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
      monthNames: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
        'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      monthNamesShort: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
      today: 'วันนี้',
      clear: 'ล้างค่า',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'สป',
    };
    // Setting Period data for test
    this.getPeriodData();
  }

  getPeriodData() {
    this.loadingFlag = true;
    this.dshfnaService.getPeriod().subscribe((response: PeriodDropDownListModel) => {
      this.period = response;
      if (this.period.yearList.length === 0) {
        this.yearList = this.dropdownListNoDataFound;
        this.monthList = this.dropdownListNoDataFound;
        this.periodFrom.controls.year.setValue(null);
        this.periodFrom.controls.month.setValue(null);
        // this.isDataNotFoundFlag = true;
      } else if (this.period.yearList.length === 1) {
        this.yearList = this.period.yearList;
        this.periodFrom.controls.year.setValue(this.yearList[0].value);
      } else {
        this.yearList = this.period.yearList;
      }
      this.loadingFlag = false;
    }, (err: { message: any; }) => {
      this.loadingFlag = false;
      console.log(err.message);
    });
  }

  selectedData(even) {

  }

  getData(period) {
    // this.dshfnaService.getDataPur(period).subscribe((response) => {
    //   this.DataPur = response.pur;
    //   this.DataSal = response.sal;
    //   this.tempP = this.DataPur;
    //   this.tempS = this.DataSal;
    //   this.DataSal.forEach(element => {
    //     if (element.status === 'สำเร็จ') {
    //       element.status = 'ส่งมอบงานแล้ว';
    //     } else if (element.status === 'รอโอน') {
    //       element.status = 'รอดำเนินการ';
    //     } else if (element.status === 'ยกเลิก') {
    //       element.status = 'ยกเลิกการบริการ';
    //     }
    //   });

    //   if (this.DataPur.length === 0) {
    //     this.dataNotFoundGridTab1 = true;
    //   } else {
    //     this.dataNotFoundGridTab1 = false;
    //     // this.getSalesData = this.salesDataGrid.saleSumInvGrid;
    //     // this.getSalesDataOrd = this.salesDataGrid.saleSumOrderGrid;
    //   }

    //   if (this.DataSal.length === 0) {
    //     this.dataNotFoundGridTab2 = true;
    //   } else {
    //     this.dataNotFoundGridTab2 = false;
    //   //   this.salesDataGrid.saleSumItemGrid.forEach(element => {
    //   //   this.sumQtyPc = this.sumQtyPc+element.usd;
    //   // });
    //   //   this.getSalesDataItem = this.salesDataGrid.saleSumItemGrid;
    //   }
    // }, (error) => {
    //     console.log(error);
    //   });
  }

  getDataSum(period) {
    // this.dshfnaService.getTranDataSum(period).subscribe(response => {
    //   this.DataSum = response;
    // }, (error) => {
    //     console.log(error);
    //   });
  }

  getDataDate() {
    this.getMonth = [];
    this.getYear = [];
    this.dshfnaService.getPeriod().subscribe(response => {
      this.tempA = response;
      moment.locale('th');
      // for (let i = 0; i < response.slice(-1)[0].monthly.length; i++) {
      //   this.getMonth.push({ label: moment(response[(response).length - 1].monthly[i]).format('MMMM'), value: moment(response[(response).length-1].monthly[i]).format('MM') })
      // }

      JSON.parse(JSON.stringify(response)).forEach(element => {
        this.getYear.push({ label: moment(moment.max(element.yearly)).add(543, 'years').format('YYYY'), value: moment(element.yearly).format('YYYY') })
        this.defaultMonth = this.getMonth[(this.getYear).length - 1].value;
        this.defaultYear = this.getYear[(this.getYear).length - 1].value;
        // this.period = this.defaultMonth + this.defaultYear;
        element.period = this.period;
        this.showMonth = this.defaultYear + '-' + this.defaultMonth + '-' + '15';
        this.getDataSum(this.period);
        this.getData(this.period);
      });
    }, (error) => {
        console.log(error);
      });
  }

  selectMonth(event) {
    let period: string;
    this.IsSearchGridTab1 = false;
    this.IsSearchGridTab2 = false;
    this.ClearData(1);
    this.ClearData(2);
    period = event.value + this.defaultYear;
    this.showMonth = this.defaultYear + '-' + event.value + '-' + '15';
    this.getDataSum(event.value + this.defaultYear);
    this.getData(event.value + this.defaultYear);
  }

  selectYear(value: string) {
    const monthDataTemp: MonthData = this.period.monthData.find((element: MonthData) => element.year === value);
    this.monthList = monthDataTemp.monthList;

    if (this.monthList.length === 1) {
      this.periodFrom.controls.month.setValue(this.monthList[0].value);
    }

    // this.getMonth = [];
    // let period: string;
    // moment.locale('th');
    // let tempB: any = [];
    // tempB =  this.tempA.find((x: { yearly: any; }) => x.yearly === event.value);

    // for (let i = 0; i < tempB.monthly.length; i++) {
    //   this.getMonth.push({ label: moment(tempB.monthly[i]).format('MMMM'), value: moment(tempB.monthly[i]).format('MM') })
    // }

    // this.defaultMonth = this.getMonth[(this.getMonth).length - 1].value;
    // this.tempA.period = this.period;
    // this.ClearData(1);
    // this.ClearData(2);
    // period = this.defaultMonth + event.value;
    // this.showMonth = event.value + '-' + this.defaultMonth + '-' + '15';
    // this.getDataSum(this.defaultMonth + event.value);
    // this.getData(this.defaultMonth + event.value);
  }

  filterData(flag) {
    if (flag === 1) {
      this.DataPur = this.tempP;
      this.criteriaSearchWalGridTab1.listNameForm = this.criteriaSearchWalGridTab1.listNameForm == null ? '' : this.criteriaSearchWalGridTab1.listNameForm;
      this.criteriaSearchWalGridTab1.verdorNameForm = this.criteriaSearchWalGridTab1.verdorNameForm == null ? '' : this.criteriaSearchWalGridTab1.verdorNameForm;
      this.criteriaSearchWalGridTab1.listStatusForm = this.criteriaSearchWalGridTab1.listStatusForm == null ? '' : this.criteriaSearchWalGridTab1.listStatusForm;
      this.criteriaSearchWalGridTab1.paymentStatusForm = this.criteriaSearchWalGridTab1.paymentStatusForm == null ? '' : this.criteriaSearchWalGridTab1.paymentStatusForm;
      this.DataPur = this.DataPur.filter(data => {
        return (
          (data.list_name.toString().toLowerCase().indexOf(this.criteriaSearchWalGridTab1.listNameForm.toLowerCase()) > -1) &&
          (data.vendor_name.toString().toLowerCase().indexOf(this.criteriaSearchWalGridTab1.verdorNameForm.toLowerCase()) > -1) &&
          (data.doc_status.toString().toLowerCase().indexOf(this.criteriaSearchWalGridTab1.listStatusForm.toLowerCase()) > -1) 
          // (data.pay_status.toString().toLowerCase().indexOf(this.criteriaSearchWalGridTab1.paymentStatusForm.toLowerCase()) > -1)
        );
      });

      if (this.criteriaSearchWalGridTab1.paymentStatusForm) {
        this.DataPur = this.DataPur.filter(data => {
          return data.pay_status.toString().indexOf(this.criteriaSearchWalGridTab1.paymentStatusForm = 'ชำระครบ')
        });
      }

      if (this.criteriaSearchWalGridTab1.purchase_dateForm) {
        this.DataPur = this.DataPur.filter((data: { purchase_date: moment.MomentInput; }) => {
          return ((moment(data.purchase_date).format('YYYY-MM-DD') == moment(this.criteriaSearchWalGridTab1.purchase_dateForm).format('YYYY-MM-DD')));
        });
      }

      if (this.DataPur.length === 0) {
        this.dataNotFoundGridTab1 = true;
      } else {
        this.dataNotFoundGridTab1 = false;
      }
    } else if (flag === 2) {
      this.DataSal = this.tempS;
      this.criteriaSearchWalGridTab2.listNameForm = this.criteriaSearchWalGridTab2.listNameForm == null ? '' : this.criteriaSearchWalGridTab2.listNameForm;
      this.criteriaSearchWalGridTab2.customerNameForm = this.criteriaSearchWalGridTab2.customerNameForm == null ? '' : this.criteriaSearchWalGridTab2.customerNameForm;
      this.criteriaSearchWalGridTab2.listStatusForm = this.criteriaSearchWalGridTab2.listStatusForm == null ? '' : this.criteriaSearchWalGridTab2.listStatusForm;
      this.criteriaSearchWalGridTab2.paymentStatusForm = this.criteriaSearchWalGridTab2.paymentStatusForm == null ? '' : this.criteriaSearchWalGridTab2.paymentStatusForm;
      this.DataSal = this.DataSal.filter(data => {
        return (
          (data.list.toString().toLowerCase().indexOf(this.criteriaSearchWalGridTab2.listNameForm.toLowerCase()) > -1) &&
          (data.customername.toString().toLowerCase().indexOf(this.criteriaSearchWalGridTab2.customerNameForm.toLowerCase()) > -1) &&
          (data.status.toString().toLowerCase().indexOf(this.criteriaSearchWalGridTab2.listStatusForm.toLowerCase()) > -1) 
          // (data.payment.toString().toLowerCase().indexOf(this.criteriaSearchWalGridTab2.paymentStatusForm.toLowerCase()) > -1)
        );
      });

      if (this.criteriaSearchWalGridTab2.paymentStatusForm) {
        this.DataSal = this.DataSal.filter(data => {
          return data.payment.toString().indexOf(this.criteriaSearchWalGridTab2.paymentStatusForm = 'ชำระครบ')
        });
      }

      if (this.criteriaSearchWalGridTab2.sellerdateForm) {
        this.DataSal = this.DataSal.filter(data => {
          return ((moment(data.sellerdate).format('YYYY-MM-DD') == moment(this.criteriaSearchWalGridTab2.sellerdateForm).format('YYYY-MM-DD')));
        });
      }

      if (this.DataSal.length === 0){
        this.dataNotFoundGridTab2 = true;
      } else {
        this.dataNotFoundGridTab2 = false;
      }
    }
  }

  ClearData(flag) {
    if (flag === 1) {
      this.dataNotFoundGridTab1 = false;
      this.searchPurGroupForm.reset();
      this.DataPur = this.tempP;
      this.count = 0;
    } else if (flag === 2) {
      this.dataNotFoundGridTab2 = false;
      this.searchSerGroupForm.reset();
      this.DataSal = this.tempS;
    }
  }

  convertCeToBe(flag) {
    this.count++;
    if (this.count === 1 || flag === 1) {
      this.DataPur.purchase_date = moment(this.criteriaSearchWalGridTab1.purchase_dateForm).format('YYYY-MM-DD');
    } else if (flag === 2) {
      this.DataPur.sellerdate = moment(this.criteriaSearchWalGridTab2.sellerdateForm).format('YYYY-MM-DD');
    }
  }

  exportReport(flag) {
    if (flag === 1) {
      const subTitle = [];
      subTitle.push(['Transaction การเงิน (Wallet) - ข้อมูลซื้อบริการ']);
      const header: any = {
        list_name: 'รายการ',
        vendor_name: 'ชื่อผู้ขาย',
        detail: 'รายละเอียดรายการ',
        note: 'หมายเหตุ',
        purchase_date: 'วันที่ทำรายการ',
        total_cost: 'มูลค่า(บาท)',
        doc_status: 'สถานะเอกสาร',
        pay_status: 'สถานะการชำระเงิน'
      };
      this.exportExcelService.exportAsExcelFile(this.DataPur, 'รายงานสรุปข้อมูลซื้อบริการ', header, null, subTitle);
      this.DataPur.shift();
    } else if (flag === 2) {
      const subTitle = [];
      subTitle.push(['Transaction การเงิน (Wallet) - ข้อมูลขาย']);
      const header: any = {
        list: 'รายการ',
        customername: 'ชื่อลูกค้า',
        prodname: 'รายละเอียดรายการ',
        sellerdate: 'วันที่ทำรายการ',
        value: 'มูลค่า(บาท)',
        status: 'สถานะการให้บริการ',
        payment: 'สถานะการชำระเงิน'
      };
      this.exportExcelService.exportAsExcelFile(this.DataSal, 'รายงานสรุปข้อมูลขาย', header, null, subTitle);
      this.DataSal.shift();
    } else if (flag === 3) {
      const title = [];
      const header: any = {};
      const titleYearprosales = ['มูลค่ายอดขายสิ้นค้าทั้งปี' + this.defaultYear, 'มูลค่าบริการที่ใช้ปี ' + this.defaultYear];
      const titleDataa1 = [this.DataSum[0].yearprosales, this.DataSum[0].yearpropur];
      const titleVsalesdebt = ['ค่าสินค้ารอรับชำระ', 'ค่าบริการค้างชำระ'];
      const titleDataa2 = [this.DataSum[0].vsalesdebt, this.DataSum[0].vpurserdebt];
      const titleAftvsalesdebt = ['มูลค่ารายการขายค้างชำระ', 'มูลค่ารายการซื้อค้างจ่าย'];
      const titleDataa3 = [this.DataSum[0].aftvsalesdebt, this.DataSum[0].aftvpurserdebt];
      const titleAftpaythism = ['มูลค่าชำระกลับสุทธิ'];
      const titleDataa4 = [this.DataSum[0].aftpaythism];
      title.push(['สรุป Transaction การเงิน (Wallet)']);
      title.push(['']);
      title.push(titleYearprosales);
      title.push(titleDataa1);
      title.push(titleVsalesdebt);
      title.push(titleDataa2);
      title.push(titleAftvsalesdebt);
      title.push(titleDataa3);
      title.push(titleAftpaythism);
      title.push(titleDataa4);
      this.exportExcelService.exportAsExcelFile(this.DataSum, 'รายงานสรุปยอดขายสินค้าและบริการทั้งปี', header, title, null);
      this.DataSum.shift();
    }
  }

}
