import { DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FileUpload, MessageService } from 'primeng/primeng';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { ResponseUpload, Upload100Model } from './model/upload100.model';
import { Upload100Service } from './service/upload100.service';
import { TranslateService } from '@ngx-translate/core';
import { Renderer2 } from '@angular/core';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { parameter_entrycode_saleprice, parameter_upload,parameter_upload_template } from 'src/app/shared/constant/parameter.constant';
import { VParameter } from 'src/app/shared/model/vparameter';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-upload100',
  templateUrl: './upload100.component.html',
  styleUrls: ['./upload100.component.scss']
})
export class Upload100Component extends PermissionComponent {

  @ViewChild('fileInput', { static: false })
  fileInput: ElementRef;
  public page_model: Upload100Model;
  translateLablePipe = new TranslateLablePipe();

  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();

  constructor(private upload100Service: Upload100Service,
    private parameterService: ParameterService,
    private uploadService: UploadService,
    private messageService: MessageService,
    private translateService: TranslateService,
    renderer2: Renderer2) {
    super('P33001', renderer2);
    this.page_model = this.upload100Service.load;
  }

  ngOnInit() {
    this.parameterService.getDataParameterDetail(parameter_upload, parameter_entrycode_saleprice).subscribe(response => {
      this.page_model.format_file_option = JSON.parse(JSON.stringify(response));
      this.onSelectedFormatFile(this.page_model.format_file_option);
    }, err => {
      console.log(err);
    });

     //-------------- Get URL Google sheet Sale price --------------------
     this.parameterService.getDataParameterDetail(parameter_upload_template, parameter_entrycode_saleprice).subscribe(response=>{
      let result = JSON.parse(JSON.stringify(response));
      if(result){
        this.page_model.template_url = result.pmdtbdtxtv1;
      }
    });
  }

  onSelectFile(file_salpri_info) {
    this.page_model.file = file_salpri_info.target.files[0];
    let format_file_flag: boolean = this.uploadService.formatFile(this.page_model.file);
    if (format_file_flag) {
      this.page_model.form_model.upload_form.controls['format_file'].setValue(this.page_model.file.name);
      this.page_model.flag_upload_file = false;
      if (this.formatFile(this.page_model.file, this.page_model.format_file, this.page_model.form_model.upload_form.controls['upload_date'].value)) {
        this.page_model.flag_upload_file = false;
      } else {
        this.page_model.flag_upload_file = true;
      }
    } else {
      this.warningAlert('alert', 'warn', this.translateService.instant('UPLOAD100.M00014'));
      this.page_model.form_model.upload_form.controls['format_file'].reset();
      this.page_model.flag_upload_file = true;
      this.fileInput.nativeElement.value = "";
    }
  }

  onUploadFile() {
    if (this.page_model.file) {
      this.uploadService.uploadSingleCSVFile(this.page_model.file,this.page_model.form_model.upload_form.controls['upload_date'].value).subscribe((response: ResponseUpload) => {
        if(response.status == "1"){
          this.successAlert(this.translateService.instant('UPLOAD100.M00013'));
          setTimeout(() => {
            window.location.reload();
            window.scroll(0, 0);
          }, 500);
        }else{
          this.warningAlert('alert', 'error', this.translateService.instant('UPLOAD100.M00014'));
        }
      }, err => {
        console.log(err);
      });
    }
  }

  onSelectedFormatFile(parameter_upload_salpri: VParameter) {
    this.page_model.format_file = parameter_upload_salpri.pmdtbdtxtv1;
    this.page_model.flag_browse_file = false;
    if (parameter_upload_salpri.pmdtbdtxtv6 == 'Y') {
      this.page_model.form_model.upload_form.controls['upload_date'].setValue(this.page_model.to_day);
      this.page_model.flag_upload_date = false;
    } else {
      this.page_model.form_model.upload_form.controls['upload_date'].setValue(this.page_model.to_day);
      this.page_model.flag_upload_date = true;
    }
  }

  onSelectedUploadDate(upload_date_salpri: Date) {
    this.page_model.form_model.upload_form.controls['upload_date'].setValue(formatDate(upload_date_salpri, 'dd/MM/yyyy', 'en-US'));
    if (this.formatFile(this.page_model.file, this.page_model.format_file, this.page_model.form_model.upload_form.controls['upload_date'].value)) {
      this.page_model.flag_upload_file = false;
    } else {
      this.page_model.flag_upload_file = true;
    }
  }

  onGoToUploadHistory(){
    let url = 'upload/upload200'
    window.open(url);
  }

  formatFile(file: File, format_file: String, upload_date: String) {
    if (file && format_file && upload_date) {
      let format_filename_flag: boolean = this.uploadService.formatFileName(file, format_file);
      let format_filedate_flag: boolean = this.uploadService.formatFileDate(file, format_file, upload_date);
      if (format_filename_flag && format_filedate_flag) {
        return true;
      } else if (format_filename_flag && !format_filedate_flag) {
        this.warningAlert('alert', 'warn', this.translateService.instant('UPLOAD300.M00011'));
        return false;
      } else if (!format_filename_flag && format_filedate_flag) {
        this.warningAlert('alert', 'warn', this.translateService.instant('UPLOAD300.M00012'));
        return false;
      } else {
        this.warningAlert('alert', 'warn', this.translateService.instant('UPLOAD300.M00013'));
        return false;
      }
    } else {
      return false;
    }
  }

  warningAlert(key: string, severityInfo: string, detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: key, sticky: true, severity: severityInfo, summary: this.translateService.instant('UPLOAD100.M00009'), detail: detaiInfo});
  }

  successAlert(detaiInfo: string){
    this.messageService.clear();
    this.messageService.add({key: 'alertsuccess', sticky: true, severity: 'success', detail: detaiInfo});
  }

  onConfirm(){
    this.messageService.clear();
  }

  onReject(){
    this.messageService.clear();
  }

  linkURL(){
    let url = this.page_model.template_url;
    window.open(url);
  }

}
