import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { AccRoutingModule } from './acc-routing.module';
import { Acccdn100Component } from './acccdn/acccdn100/acccdn100.component';
import { Acccdn200Component } from './acccdn/acccdn200/acccdn200.component';
import { Accsda100Component } from './accsda/accsda100/accsda100.component';


@NgModule({
  declarations: [
    Acccdn100Component,
    Acccdn200Component,
    Accsda100Component
  ],
  imports: [
    CommonModule,
    SharedModule,
    AccRoutingModule,
  ]
})
export class AccModule { }
