import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { Fnapay100Component } from './fnapay/fnapay100/fnapay100.component';
import { Fnapay200Component } from './fnapay/fnapay200/fnapay200.component';


const routes: Routes = [
  { path: 'fnapay/fnapay100', component: Fnapay100Component },
  { path: 'fnapay/fnapay200/:saleOrderNumber/:warehouseCode', component: Fnapay200Component },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FnaRoutingModule { }
