import { Component, OnInit, Renderer2 } from '@angular/core';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { PriceSaleMaintenancePage, PriceSaleData, SaveData, OrignalData, DecimalData } from '../model/pricesale';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { PriceSaleService } from '../../shared/services/priceSale.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { SelectItem } from 'primeng/api';

import * as moment from 'moment';
import { Prisal200Model } from './model/prisal200.model';
import { Prisal200Service } from './service/prisal200.service';
import { DecimalPipe } from '@angular/common';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-prisal200',
  templateUrl: './prisal200.component.html',
  styleUrls: ['./prisal200.component.scss']
})
export class Prisal200Component extends PermissionComponent {

  public priceSaleData: PriceSaleMaintenancePage = {} as PriceSaleMaintenancePage;
  public saveData: SaveData = {} as SaveData;
  public orignalData: OrignalData = {} as OrignalData;
  public decimalData: DecimalData = {} as DecimalData;
  public minimumDate = new Date();
  public minEndate: Date;
  public maximumDate;
  public mandatory = false;
  public user: any;
  public doNotEdit = false;
  public disableCountry: boolean;
  public conditionalStarEdit = false;
  public conditionalEndEdit = false;
  public canBesave = true;
  public hightLight: any;
  public display = false;
  public hiddenButton = true;
  public canSelectItem = false;
  public sizeCanbeSave = 0;
  translateLablePipe = new TranslateLablePipe();

  typeDecimalPurchase: string = '1';
  typeDecimalSelling: string = '2';
  typeDecimalRoundMethod: string = '3';
  typeDecimalRoundToValue: string = '4';
  method: string;
  roundToDecimal: number;
  decimal: number = 0;
  dataTypeDecimalSelling: any;
  decimalSelling: number = 0;
  count = 0;
  defaultPeriod: any;
  optionValueDefault: any;
  optionData: SelectItem[];
  disSaleChanel:boolean = false;
  disSaleSeller:boolean = false;

  public checkEffective:boolean = false;
  public page_model: Prisal200Model;
  salePriceRegExp: RegExp = /^-?\d*\.{0,1}\d*$/

  translateCalendarPipe = new TranslateCalendarPipe();
  setLocaleDate:any = this.translateCalendarPipe.transform();

  constructor(
    renderer2: Renderer2,
    private messageService: MessageService,
    private autService: AuthenticationService,
    private parameterWmsSerive: ParameterWmsSerive,
    private psaSerive: PriceSaleService,
    private router: Router,
    private route: ActivatedRoute,
    private decimalService: DecimalService,
    private parameterService: ParameterService,
    private prisal200Service: Prisal200Service,
    private decimalPipe: DecimalPipe
  ) {
    super('P50001', renderer2);
    this.page_model = this.prisal200Service.load;
    this.priceSaleData.priceSaleDetailData = {} as PriceSaleData;
    this.saveData.prcSaleChannelCode = [];
    this.user = JSON.parse(localStorage.getItem('userData'));
    this.page_model.form_model.gridDataForm.controls['salePriceNo'].setValue(Number(this.route.snapshot.paramMap.get('salePriceNo')));
  }

  ngOnInit() {
    if (this.page_model.form_model.gridDataForm.controls['salePriceNo'].value !== undefined
      && this.page_model.form_model.gridDataForm.controls['salePriceNo'].value !== null
      && this.page_model.form_model.gridDataForm.controls['salePriceNo'].value != 0) {
      this.doNotEdit = true;
      this.page_model.form_model.gridDataForm.controls['itemCodeFromKT'].disable();
      this.getPriceSaleData();
    } else {
      this.getCountryData();
      this.page_model.form_model.gridDataForm.controls['endDate'].setValue(moment('2049-12-31').toDate());
    }
  }

  goBack() {
    if (!this.canBesave) {
      this.messageService.clear();
      this.messageService.add({ key: 'black', sticky: true, severity: 'warn', summary: 'PRISAL200.M00077', detail: 'PRISAL200.M00028' });
    } else {
      this.router.navigate(['/prisal/prisal100']);
    }
  }

  getCountryData() {
    this.autService.getParameterWmsCountry().subscribe(response => {
      this.priceSaleData.countryData = JSON.parse(JSON.stringify(response));
      if (this.priceSaleData.countryData.length == 1) {
        this.page_model.form_model.gridDataForm.controls['countryCode'].setValue(this.priceSaleData.countryData[0].value);
        this.disableCountry = true;
        this.getSeller(this.page_model.form_model.gridDataForm.controls['countryCode'].value);
        this.getCurrency(this.page_model.form_model.gridDataForm.controls['countryCode'].value);
        this.getDecimalDisplayByCountry(this.page_model.form_model.gridDataForm.controls['countryCode'].value,
          this.page_model.form_model.gridDataForm.controls['fullPrice'].value,
          this.page_model.form_model.gridDataForm.controls['salePrice'].value);
        this.getSaleChanel();
        this.formatDecimal(this.page_model.form_model.gridDataForm.controls['countryCode'].value);
        this.optionData = this.optionData.sort((a, b) => a.value.pmdtbdnumv3 < b.value.pmdtbdnumv3 ? -1 : a.value.pmdtbdnumv3 > b.value.pmdtbdnumv3 ? 1 : 0);
        const defaultSelectItem: SelectItem[] = this.optionData.filter((selectItem: SelectItem) => {
          return selectItem.value.parameterDetailPK.pmdtbdtbdentcd == 'KH';
        });
        this.optionValueDefault = defaultSelectItem[0].value;
        this.defaultPeriod = this.optionValueDefault;
      }else if(this.priceSaleData.countryData.length == 0) {
        this.disableCountry = true;
      }
    });
  }

  getCurrency(country) {
    this.parameterWmsSerive.getParameterWmsBytableNoAndCode(105, country).subscribe((responseParam) => {
      let respones = JSON.parse(JSON.stringify(responseParam));
      this.parameterWmsSerive.getParameterWmsBytableNoAndCode(9146, respones[0].parameterData.parameter_v6).subscribe((currencyResult) => {
        let currency = JSON.parse(JSON.stringify(currencyResult));
        this.page_model.form_model.gridDataForm.controls['currency'].setValue(this.translateLablePipe.transform(currency[0].engDesc,currency[0].localDesc));
      });
    });
  }

  changeCountry() {
    this.canBesave = this.orignalData.orgCountryCode != this.page_model.form_model.gridDataForm.controls['countryCode'].value ? false : true;
    this.getDecimalDisplayByCountry(this.page_model.form_model.gridDataForm.controls['countryCode'].value,
      this.page_model.form_model.gridDataForm.controls['fullPrice'].value,
      this.page_model.form_model.gridDataForm.controls['salePrice'].value);
    this.getCurrency(this.page_model.form_model.gridDataForm.controls['countryCode'].value);
    this.getSaleChanel();
    this.formatDecimal(this.page_model.form_model.gridDataForm.controls['countryCode'].value);
    if (this.checkIsNotValidSeller()) {
      this.messageService.add({ key: 'cleardataCountry', sticky: true, severity: 'warn', summary: 'PRISAL200.M00076', detail: 'PRISAL200.M00029' });
    } else {
      this.getSeller(this.page_model.form_model.gridDataForm.controls['countryCode'].value);
      this.defaultPeriod = this.optionValueDefault;
    }
  }


  getSeller(country) {
    this.autService.getParameterWmsSeller(country, null).subscribe(response => {
      this.priceSaleData.sellerData = JSON.parse(JSON.stringify(response));
      if (this.priceSaleData.sellerData.length == 1) {
        this.page_model.form_model.gridDataForm.controls['sellerCode'].setValue(this.priceSaleData.sellerData[0].value);
        this.disSaleSeller = true;
      }
      if(this.priceSaleData.sellerData.length == 0){
        this.disSaleSeller = true;
      }
      else{
        this.disSaleSeller = false;
      }
    });
  }


  changeSeller(even) {
    if (this.orignalData.orgSellerCode !== this.page_model.form_model.gridDataForm.controls['sellerCode'].value) {
      let selectData = this.priceSaleData.sellerData.filter(data => data.value == this.page_model.form_model.gridDataForm.controls['sellerCode'].value);
      this.page_model.form_model.gridDataForm.controls['sellerCode'].setValue(selectData[0].value);
      this.canSelectItem = true;
      this.canBesave = false;
      if (this.count == 0) {
        this.orignalData.orgSellerCode = this.page_model.form_model.gridDataForm.controls['sellerCode'].value;
      }
      if (this.checkIsNotValidItem()) {
        this.messageService.add({ key: 'cleardataItem', sticky: true, severity: 'warn', summary: 'PRISAL200.M00076', detail: 'PRISAL200.M00029' });
      }
      this.count++;
    } else {
      let selectData = this.priceSaleData.sellerData.filter(data => data.value == this.orignalData.orgSellerCode);
      this.page_model.form_model.gridDataForm.controls['sellerCode'].setValue(selectData[0].value);
      this.orignalData.orgSellerCode = this.page_model.form_model.gridDataForm.controls['sellerCode'].value;
    }
  }


  getSaleChanel() {
    this.autService.getParameterSaleChannelInquiry(this.page_model.form_model.gridDataForm.controls['countryCode'].value).subscribe(response => {
      this.priceSaleData.saleChanelData = JSON.parse(JSON.stringify(response));

      if(this.priceSaleData.saleChanelData.length == 0){
        this.disSaleChanel = true;
      }
      else{
        this.disSaleChanel = false;
      }
    });
  }


  changSaleChanel() {
    this.canBesave = this.orignalData.orgSaleChannelCode != this.page_model.form_model.gridDataForm.controls['saleChanelCode'].value ? false : true;
  }

  checkMandatory() {
    let startDate = moment(this.page_model.form_model.gridDataForm.controls['startDate'].value).format('DD/MM/YYYY');
    let endDate = moment(this.page_model.form_model.gridDataForm.controls['endDate'].value).format('DD/MM/YYYY');

    this.saveData.prcCountryCode = this.page_model.form_model.gridDataForm.controls['countryCode'].value;
    this.saveData.prcSellerCode = this.page_model.form_model.gridDataForm.controls['sellerCode'].value;
    this.saveData.prcSellerItmcode = this.page_model.form_model.gridDataForm.controls['itemCodeFromKT'].value;
    this.saveData.prcklItmCode = this.page_model.form_model.gridDataForm.controls['itemCodeFromKO'].value;
    this.saveData.prcWMSItmcode = this.page_model.form_model.gridDataForm.controls['itemCodeFromWMS'].value;
    this.saveData.prcFullPriceSale = this.page_model.form_model.gridDataForm.controls['fullPrice'].value.replace(/,/g, "");
    this.saveData.prcPriceSale = this.page_model.form_model.gridDataForm.controls['salePrice'].value.replace(/,/g, "");
    this.saveData.prcSaleChannelCode = this.page_model.form_model.gridDataForm.controls['saleChanelCode'].value;

    if (this.checkIsNotValidItem() &&
      (this.page_model.form_model.gridDataForm.controls['countryCode'].value !== '' &&
        this.page_model.form_model.gridDataForm.controls['countryCode'].value !== undefined &&
        this.page_model.form_model.gridDataForm.controls['countryCode'].value !== null
      ) &&
      (this.checkIsNotValidSaleChanel()
      ) &&
      (this.checkIsNotValidSeller()
      ) &&
      (this.page_model.form_model.gridDataForm.controls['salePrice'].value !== null &&
        this.page_model.form_model.gridDataForm.controls['salePrice'].value !== undefined &&
        this.page_model.form_model.gridDataForm.controls['salePrice'].value !== null
      ) &&
      (startDate !== '' &&
        this.page_model.form_model.gridDataForm.controls['startDate'].value !== undefined &&
        this.page_model.form_model.gridDataForm.controls['startDate'].value !== null
      ) &&
      (endDate !== '' &&
        this.page_model.form_model.gridDataForm.controls['endDate'].value !== undefined &&
        this.page_model.form_model.gridDataForm.controls['endDate'].value !== null
      )
    ) {
      this.save();
    } else {
      this.messageService.add({ key: 'mes', sticky: true, severity: 'error', summary: 'PRISAL200.M00034', detail: 'PRISAL200.M00030' });
    }
  }

  save() {
    this.saveData.prcCountryCode = this.page_model.form_model.gridDataForm.controls['countryCode'].value;
    this.saveData.prcSellerCode = this.page_model.form_model.gridDataForm.controls['sellerCode'].value;
    this.saveData.prcSellerItmcode = this.page_model.form_model.gridDataForm.controls['itemCodeFromKT'].value;
    this.saveData.prcklItmCode = this.page_model.form_model.gridDataForm.controls['itemCodeFromKO'].value;
    this.saveData.prcWMSItmcode = this.page_model.form_model.gridDataForm.controls['itemCodeFromWMS'].value;
    this.saveData.prcFullPriceSale = this.page_model.form_model.gridDataForm.controls['fullPrice'].value.replace(/,/g, "");
    this.saveData.prcPriceSale = this.page_model.form_model.gridDataForm.controls['salePrice'].value.replace(/,/g, "");
    this.saveData.prcSaleChannelCode = this.page_model.form_model.gridDataForm.controls['saleChanelCode'].value;

    if(this.page_model.form_model.gridDataForm.controls['freeShipmentFlag'].value !== '')
    {
        if(this.page_model.form_model.gridDataForm.controls['freeShipmentFlag'].value === true){
          this.saveData.free_shipment_flag = 1;
        }
        else{
          this.saveData.free_shipment_flag = 2;
        }
    }

    let startDate = moment(this.page_model.form_model.gridDataForm.controls['startDate'].value).format('DD/MM/YYYY');
    let endDate = moment(this.page_model.form_model.gridDataForm.controls['endDate'].value).format('DD/MM/YYYY');

    if (this.page_model.form_model.gridDataForm.controls['salePriceNo'].value === 0) {
      this.saveData.prcStartDate = startDate;
      this.saveData.prcEndDate = endDate;
    } else {
      this.saveData.prcId = this.page_model.form_model.gridDataForm.controls['salePriceNo'].value;
      if (startDate != this.orignalData.orgStartDate &&
        endDate != this.orignalData.orgEndDate
      ) {
        this.saveData.prcStartDate = startDate;
        this.saveData.prcEndDate = moment(this.page_model.form_model.gridDataForm.controls['endDate'].value).format('DD/MM/YYYY');
      } else if (startDate != this.orignalData.orgStartDate &&
        endDate == this.orignalData.orgEndDate
      ) {
        this.saveData.prcStartDate = startDate;
        this.saveData.prcEndDate = endDate;
      } else if (startDate == this.orignalData.orgStartDate &&
        endDate != this.orignalData.orgEndDate) {
        this.saveData.prcStartDate = startDate;
        this.saveData.prcEndDate = moment(this.page_model.form_model.gridDataForm.controls['endDate'].value).format('DD/MM/YYYY');
      } else {
        this.saveData.prcStartDate = startDate;
        this.saveData.prcEndDate = moment(this.page_model.form_model.gridDataForm.controls['endDate'].value).format('DD/MM/YYYY');
      }
    }
    this.psaSerive.saveOrUpdate(this.saveData).subscribe((response: any) => {
      if (response.status == "1") {
        this.messageService.add({ key: "save", severity: "success", detail: 'PRISAL200.M00031', });
        setTimeout(() => {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['prisal/prisal100']).then(() => {
              window.location.reload();
              window.scroll(0, 0);
            }));
        }, 1000);
      } else if (response.status == "2") {
        this.messageService.add({ key: 'price', sticky: true, severity: 'warn', summary: 'PRISAL200.M00027', detail: 'PRISAL200.' + response.message });
      } else {
        this.messageService.add({ key: 'mes', severity: 'error', sticky: true, summary: 'PRISAL200.M00027', detail: 'PRISAL200.M00034' });
      }
    });
  }


  getPriceSaleData() {
    this.psaSerive.getPriceSaleData(this.page_model.form_model.gridDataForm.controls['salePriceNo'].value).subscribe(async (response: PriceSaleData) => {
      let saleChanelCode = [];
      saleChanelCode.push(JSON.parse(JSON.stringify(response.saleChanelCode)));
      this.page_model.form_model.gridDataForm.controls['saleChanelCode'].reset();
      this.page_model.form_model.gridDataForm.controls['countryCode'].setValue(response.countryCode);
      this.page_model.form_model.gridDataForm.controls['sellerCode'].setValue(response.sellerCode);
      this.page_model.form_model.gridDataForm.controls['saleChanelCode'].setValue(saleChanelCode);
      this.page_model.form_model.gridDataForm.controls['startDate'].setValue(moment(response.startDate).toDate());
      this.page_model.form_model.gridDataForm.controls['endDate'].setValue(moment(response.endDate).toDate());
      this.page_model.form_model.gridDataForm.controls['iemDesc'].setValue(this.translateLablePipe.transform(response.iemDesc_eng,response.iemDesc_local));
      this.page_model.form_model.gridDataForm.controls['itemCodeFromKO'].setValue(response.itemCodeFromKO);
      this.page_model.form_model.gridDataForm.controls['itemCodeFromKT'].setValue(response.itemCodeFromKT);
      this.page_model.form_model.gridDataForm.controls['itemCodeFromWMS'].setValue(response.itemCodeFromWMS);

        if(this.page_model.form_model.gridDataForm.controls['startDate'].value <= this.minimumDate){
            this.checkEffective = true;
        }else{
            this.checkEffective = false;
        }

        if(response.freeShipmentFlag !== undefined){
            if(response.freeShipmentFlag === 1){
            this.page_model.form_model.gridDataForm.controls['freeShipmentFlag'].patchValue(true);
            }
            else{
            this.page_model.form_model.gridDataForm.controls['freeShipmentFlag'].patchValue(false);
            }
        }

      this.getDecimalDisplayByCountry(response.countryCode, response.fullPrice, response.salePrice);
      this.getCountryData();
      this.getSaleChanel();
      this.getSeller(response.countryCode);
      this.getCurrency(response.countryCode);
      this.formatDecimal(response.countryCode);
      this.minEndate = new Date(response.startDate);
      this.orignalData.orgStartDate = moment(response.startDate).format('DD/MM/YYYY');
      this.orignalData.orgEndDate = moment(response.endDate).format('DD/MM/YYYY');

      if (moment(response.startDate).isBefore(moment(new Date()))) {
        this.page_model.form_model.gridDataForm.controls['startDate'].disable();
        this.page_model.form_model.gridDataForm.controls['fullPrice'].disable();
        this.page_model.form_model.gridDataForm.controls['salePrice'].disable();
        this.page_model.form_model.gridDataForm.controls['freeShipmentFlag'].disable();
      } else {
        this.page_model.form_model.gridDataForm.controls['startDate'].enable();
        this.page_model.form_model.gridDataForm.controls['fullPrice'].enable();
        this.page_model.form_model.gridDataForm.controls['salePrice'].enable();
        this.page_model.form_model.gridDataForm.controls['freeShipmentFlag'].enable();
      }
      if (moment(response.endDate).isBefore(moment(new Date()))) {
        this.page_model.form_model.gridDataForm.controls['endDate'].disable();
      } else {
        this.page_model.form_model.gridDataForm.controls['endDate'].enable();
      }
    }, err => {
      console.log(err);
    });
  }

  showDialog() {
    if (this.page_model.form_model.gridDataForm.controls['sellerCode'].value !== '' &&
      this.page_model.form_model.gridDataForm.controls['sellerCode'].value !== undefined &&
      this.page_model.form_model.gridDataForm.controls['sellerCode'].value !== null
    ) {
      this.hightLight = 10000;
      this.display = true;
      this.hiddenButton = true;
    } else {
      this.messageService.clear();
      this.messageService.add({ key: 'mes', severity: 'error', sticky: true, summary: 'PRISAL200.M00076', detail: 'PRISAL200.M00035' });
    }
  }

  getItemdata(data: any): void {
    if (data !== undefined) {
      this.page_model.form_model.gridDataForm.controls['iemDesc'].setValue(data.item_desc_eng);
      this.page_model.form_model.gridDataForm.controls['itemCodeFromKO'].setValue(data.item_KlangOneCode);
      this.page_model.form_model.gridDataForm.controls['itemCodeFromKT'].setValue(data.item_sellerCode);
      this.page_model.form_model.gridDataForm.controls['itemCodeFromWMS'].setValue(data.item_code);
    }
    this.display = false;
  }


  clickCancel() {
    this.messageService.clear();
  }

  changeItem() {
    this.canBesave = this.orignalData.orgWMSItmcode != this.page_model.form_model.gridDataForm.controls['itemCodeFromWMS'].value ? false : true;
  }

  changeSaleprice(value) {
    // this.page_model.form_model.gridDataForm.controls['salePrice'].setValue(Number(value.toString().replace(/,/g, "")));
    this.canBesave = false;
  }

  changeFullprice(value) {
    this.page_model.form_model.gridDataForm.controls['salePrice'].setValue(null);
    this.canBesave = false;
  }

  changeStartDate() {
    this.minEndate = new Date(this.page_model.form_model.gridDataForm.controls['startDate'].value);
    if(this.orignalData.orgStartDate == moment(this.page_model.form_model.gridDataForm.controls['startDate'].value).format('DD/MM/YYYY')
    && this.orignalData.orgEndDate == moment(this.page_model.form_model.gridDataForm.controls['endDate'].value).format('DD/MM/YYYY')){
      this.canBesave = true;
    }else{
      this.canBesave = false;
    }
    if(this.page_model.form_model.gridDataForm.controls['endDate'].value < this.page_model.form_model.gridDataForm.controls['startDate'].value){
      this.page_model.form_model.gridDataForm.controls['endDate'].reset();
    }
  }
  changeEndDate() {
    if(this.orignalData.orgStartDate == moment(this.page_model.form_model.gridDataForm.controls['startDate'].value).format('DD/MM/YYYY')
    && this.orignalData.orgEndDate == moment(this.page_model.form_model.gridDataForm.controls['endDate'].value).format('DD/MM/YYYY')){
      this.canBesave = true;
    }else{
      this.canBesave = false;
    }
  }

  changeFreeShipment(value) {
    this.canBesave = this.orignalData.orgFreeshipment != this.page_model.form_model.gridDataForm.controls['freeShipmentFlag'].value ? false : true;
  }

  clearItemInfo() {
    this.messageService.clear();
    this.orignalData.orgSellerCode = this.page_model.form_model.gridDataForm.controls['sellerCode'].value;
    this.page_model.form_model.gridDataForm.controls['itemCodeFromKO'].reset();
    this.page_model.form_model.gridDataForm.controls['itemCodeFromKT'].reset();
    this.page_model.form_model.gridDataForm.controls['itemCodeFromWMS'].reset();
    this.page_model.form_model.gridDataForm.controls['iemDesc'].reset();
  }

  notChangeSaller() {
    this.messageService.clear();
    this.page_model.form_model.gridDataForm.controls['sellerCode'].setValue(this.orignalData.orgSellerCode);
  }

  clearSellerandItemInfo() {
    this.messageService.clear();
    this.page_model.form_model.gridDataForm.controls['sellerCode'].reset();
    this.clearItemInfo();
    this.getSeller(this.page_model.form_model.gridDataForm.controls['countryCode'].value);
  }

  notSellerandItem() {
    this.messageService.clear();
    this.page_model.form_model.gridDataForm.controls['sellerCode'].setValue(this.orignalData.orgSellerCode);
  }

  black() {
    this.router.navigate(['prisal/prisal100/']);
  }

  notBlack() {
    this.messageService.clear();
  }


  checkIsNotValidSeller() {
    if (this.page_model.form_model.gridDataForm.controls['sellerCode'].value !== '' &&
      this.page_model.form_model.gridDataForm.controls['sellerCode'].value !== undefined &&
      this.page_model.form_model.gridDataForm.controls['sellerCode'].value !== null) {
      return true;
    } else {
      return false;
    }
  }

  checkIsNotValidItem() {
    if (this.page_model.form_model.gridDataForm.controls['itemCodeFromWMS'].value !== '' &&
      this.page_model.form_model.gridDataForm.controls['itemCodeFromWMS'].value !== undefined &&
      this.page_model.form_model.gridDataForm.controls['itemCodeFromWMS'].value !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkIsNotValidSaleChanel() {
    if (Array.isArray(this.page_model.form_model.gridDataForm.controls['saleChanelCode'].value)) {
      return true;
    } else {
      return false;
    }
  }

  checkFullprice() {
    this.page_model.form_model.gridDataForm.controls['salePrice'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.page_model.form_model.gridDataForm.controls['salePrice'].value.toString().replace(/,/g, ""), this.method, this.roundToDecimal, this.decimal), '1.' + this.decimal + '-' + this.decimal, 'en'));
    if (Number(this.page_model.form_model.gridDataForm.controls['fullPrice'].value.replace(/,/g, "")) !== 0 &&
    this.page_model.form_model.gridDataForm.controls['fullPrice'].value !== undefined &&
    this.page_model.form_model.gridDataForm.controls['fullPrice'].value !== null &&
    Number(this.page_model.form_model.gridDataForm.controls['salePrice'].value.toString().replace(/,/g, "")) > Number(this.page_model.form_model.gridDataForm.controls['fullPrice'].value.toString().replace(/,/g, ""))) {
      this.page_model.form_model.gridDataForm.controls['salePrice'].reset();
      this.canBesave = true;
      this.messageService.clear();
      this.messageService.add({ key: 'price', sticky: true, severity: 'warn', summary: 'PRISAL200.M00027', detail: 'PRISAL200.M00036' });
    }
  }

  decimalPrice(value) {
    this.page_model.form_model.gridDataForm.controls['fullPrice'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.page_model.form_model.gridDataForm.controls['fullPrice'].value.toString().replace(/,/g, ""), this.method, this.roundToDecimal, this.decimal), '1.' + this.decimal + '-' + this.decimal, 'en'));
  }

  closePrice() {
    this.messageService.clear();
  }

  getDecimalDisplayByCountry(countryCode, fullPrice, priceSale) {
    this.parameterService.getvprmdtltolableByCountry(104, countryCode).subscribe(response => {
      let decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.dataTypeDecimalPurchase = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalPurchase);
      this.decimalData.dataTypeDecimalSelling = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalSelling);
      this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalRoundMethod);
      this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalRoundToValue);
      this.decimal = this.decimalData.dataTypeDecimalSelling[0].value.pmdtbdnumv1;
      this.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
      this.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;

      this.page_model.form_model.gridDataForm.controls['fullPrice'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(fullPrice, this.method, this.roundToDecimal, this.decimal), '1.' + this.decimal + '-' + this.decimal, 'en'));
      this.page_model.form_model.gridDataForm.controls['salePrice'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(priceSale, this.method, this.roundToDecimal, this.decimal), '1.' + this.decimal + '-' + this.decimal, 'en'));
      this.orignalData.orgFullPriceSale = this.decimalService.getRoundingDecimal(fullPrice, this.method, this.roundToDecimal, this.decimal);
      this.orignalData.orgPriceSale = this.decimalService.getRoundingDecimal(priceSale, this.method, this.roundToDecimal, this.decimal);
    }, err => {
      console.log(err);
    });
  }

  formatDecimal(country) {
    this.parameterService.getDiscoutPrice(104, country, this.typeDecimalSelling).subscribe(dataRound => {
      this.dataTypeDecimalSelling = JSON.parse(JSON.stringify(dataRound));
      this.decimalSelling = this.dataTypeDecimalSelling.pmdtbdnumv1;
    });
  }

}
