import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertMessageComponent } from '../components/alert-message/alert-message.component';
import { Router } from '@angular/router';
import { ErrorService } from '../services/error.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    message: string;
    errorResponse: any = {};
    error: any;


    constructor(private router: Router, private alert: AlertMessageComponent, private errorService: ErrorService) {
        // let errorTemp = this.errorService.getHttpRequest();
        // JSON.parse(JSON.stringify(errorTemp)).forEach(element => {
        //     this.errorResponse = element
        // });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorTemp = this.errorService.getHttpRequest();
                JSON.parse(JSON.stringify(errorTemp)).forEach(element => {
                    this.errorResponse = element
                });
                
                if (this.errorResponse.message != undefined) {
                    if (this.errorResponse.errorType == "E") {
                        if (this.errorResponse.errorCode == 401) {
                            this.message = this.errorResponse.message
                            this.SerUnavailable();
                        }
                        else if (this.errorResponse.errorCode == 403) {
                            this.router.navigate(['/permission-denied']);
                        }
                        else if (this.errorResponse.errorCode == 500) {
                            this.message = this.errorResponse.message
                            this.SerUnavailable();
                        }
                    } 
                } else if (this.errorResponse.message == undefined && this.errorResponse.errorType != "S") {
                    this.message = 'Application error. Please contact your system administrator.';
                    this.SerUnavailable();
                }
                if (error.status == 500 || error.status == 503 || error.status == 0) {
                    this.message = 'Internal Server Error. Please contact your system administrator.';
                    this.SerUnavailable();
                }
                return throwError(error.status);
            }));
    }

    async SerUnavailable() {
        const toast = await this.alert.showerror(this.message);
    }

}