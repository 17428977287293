import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';
import { SynchronizeDataModel, SynchronizeDataModelDetailModel, SynchronizeStatusModel } from 'src/app/core/model/synchronize-data-model';

@Injectable({
  providedIn: 'root'
})
export class SynchronizeService {

  SYN_URL: string = `${this.evmService.getApiUrl()}/synchronize` as string;

  constructor(private http: HttpClient,
              private evmService: EnvironmentService,
              private autService: AuthenticationService) {
  }

  uploadGoogleSheets(dataPath) {
    const URL = `${this.SYN_URL}/googlesheet/`;
    return this.http.get(URL, { headers: this.autService.tokenHeader() });
  }

  getSynchronizeData(): Observable<SynchronizeDataModel[]> {
    const URL = `${this.SYN_URL}/googlesheet/getsynchronizelist/`;
    return this.http.post <SynchronizeDataModel[]>(URL,
            { } ,
            { headers: this.autService.tokenHeader() });
  }

  getSynchronizeDetailData(sheetCode: string): Observable<SynchronizeDataModelDetailModel> {
    const URL = `${this.SYN_URL}/googlesheet/getsynchronizeDetaillist/${sheetCode}`;
    return this.http.get <SynchronizeDataModelDetailModel>(URL,
            { headers: this.autService.tokenHeader() });
  }

  synchronizeDataProcess(synchronizeDataList: SynchronizeDataModel[]): Observable<SynchronizeDataModel[]> {
    const URL = `${this.SYN_URL}/googlesheet/synchronizeprocess/`;
    return this.http.put <SynchronizeDataModel[]>(URL,
    { synchronizeData: synchronizeDataList} ,
    { headers: this.autService.tokenHeader() });
  }

  getSynchronizeStatus(sheetCode, filesName): Observable<SynchronizeStatusModel[]> {
    const URL: string = `${this.SYN_URL}/googlesheet/getsynchronizestatus/${sheetCode}/${filesName}` as string;
    return this.http.get<SynchronizeStatusModel[]>(URL, {headers: this.autService.tokenHeader()});
  }

}
