import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DecimalData, Dshova100Model, FormModel, StockCardByPeriod } from '../model/dshova100.model';
import * as uuid from 'uuid';
import { DashboardOverallService } from 'src/app/shared/services/dashboard-overall.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartColor } from 'src/app/shared/constant/chartcolor.constant';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class Dshova100Service {

  dshova100: Dshova100Model = {} as Dshova100Model;
  translateLablePipe = new TranslateLablePipe();
  constructor(private fb: FormBuilder,
    private dshovaService: DashboardOverallService,
    private translateService: TranslateService,
    private decimalService: DecimalService,
    private decimalPipe: DecimalPipe) {
    this.init();
  }
  init(): void {
    this.dshova100.form_model = {} as FormModel;
    this.dshova100.form_model.gridSearchCriteriaForm = this.fb.group({
      country: (null),
      seller: (null),
      sellerLength: (null),
    });
    this.dshova100.form_model.gridHeaderCriteriaForm = this.fb.group({
      countinv: (null),
      countitem: (null),
      defaultPeriod: (null),
    });
    this.dshova100.form_model.gridAdvanceSearchForm = this.fb.group({
      itemCodeCriteria: (null),
      onlineCodeCriteria: (null),
      dateCriteria: [null],
      saleCat: (null)
    });
    this.dshova100.uuid = "";
    this.dshova100.form_model.gridOverviewForm = this.fb.group({
      sale_doc_today: (0),
      sale_doc_this_month: (0),
      sale_doc_three_month: (0),
      sale_doc_this_year: (0),
      sale_doc_total: (0),
      sale_item_avg_sku: (0)
    });
    this.dshova100.tempData = [
      {
        'header': this.translateService.instant('DSHOVA100.M00048'),
        'order': 0,
        'icon': 'pi pi-shopping-cart',
      },
      {
        'header': this.translateService.instant('DSHOVA100.M00049'),
        'order': 0,
        'icon': 'pi pi-clock',
      },
      {
        'header': this.translateService.instant('DSHOVA100.M00050'),
        'order': 0,
        'icon': 'fa fa-hourglass-half',
      },
      {
        'header': this.translateService.instant('DSHOVA100.M00051'),
        'order': 0,
        'icon': 'fa fa-calendar',
      }
    ];
    this.dshova100.DatatoChartStock;
    this.dshova100.stockBal = [] as Array<StockCardByPeriod>;
    this.dshova100.showStockChart;
    this.dshova100.showDataNotFoundStockChart = true;
    this.dshova100.optionPie;
    this.dshova100.stockBalanceChart;
    this.dshova100.dataToshowChart;
    this.dshova100.select;
    this.dshova100.showDataNotFound = true;
    this.dshova100.showSaleChart = true;
    this.dshova100.salesByItemGrid;
    this.dshova100.showGrid;
    this.dshova100.showDataNotFoundGrid = true;
    this.dshova100.showAdvanceSearch;
    this.dshova100.showSaleAmount;
    this.dshova100.showToday;
    this.dshova100.showNotToday = true;
    this.dshova100.periodSaleShow;
    this.dshova100.disButtonChart = false;
    this.dshova100.isRight = false;
    this.dshova100.showDetail = false;
    this.dshova100.parameterDecimal = [];
    this.dshova100.decimalData = {} as DecimalData;
    this.dshova100.typeDecimalPurchase = '1';
    this.dshova100.typeDecimalSelling = '2';
    this.dshova100.typeDecimalRoundMethod = '3';
    this.dshova100.typeDecimalRoundToValue = '4';
    this.dshova100.method;
    this.dshova100.roundToDecimal;
    this.dshova100.decimal;
  }

  get load(): Dshova100Model {
    return this.dshova100;
  }

  loadOverviewData(page_model: Dshova100Model, country: string, seller: string, uuid: string, period: string, datetype: string, periodtype: string) {
    if (country != null && seller != null) {
      this.dshovaService.getSaleSummaryByPeriod(country, seller, uuid).subscribe(response => {
        page_model.form_model.gridOverviewForm.controls['sale_doc_today'].setValue(response.data.sale_doc_today);
        page_model.form_model.gridOverviewForm.controls['sale_doc_this_month'].setValue(response.data.sale_doc_this_month);
        page_model.form_model.gridOverviewForm.controls['sale_doc_three_month'].setValue(response.data.sale_doc_three_month);
        page_model.form_model.gridOverviewForm.controls['sale_doc_this_year'].setValue(response.data.sale_doc_this_year);
        page_model.form_model.gridOverviewForm.controls['sale_doc_total'].setValue(response.data.sale_doc_total);
        page_model.form_model.gridOverviewForm.controls['sale_item_avg_sku'].setValue(response.data.sale_item_avg_sku);
        page_model.uuid = response.uuid;
        page_model.form_model.gridHeaderCriteriaForm.controls['countinv'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(response.data.sale_doc_total, page_model.method, page_model.roundToDecimal, page_model.decimal), '1.'+page_model.decimal+'-'+page_model.decimal, 'en'));
        page_model.form_model.gridHeaderCriteriaForm.controls['countitem'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(response.data.sale_item_avg_sku, page_model.method, page_model.roundToDecimal, page_model.decimal), '1.'+page_model.decimal+'-'+page_model.decimal, 'en'));
        page_model.tempData = [
          {
            'header': this.translateService.instant('DSHOVA100.M00048'),
            'order': page_model.form_model.gridOverviewForm.controls['sale_doc_today'].value,
            'icon': 'pi pi-shopping-cart',
          },
          {
            'header': this.translateService.instant('DSHOVA100.M00049'),
            'order': page_model.form_model.gridOverviewForm.controls['sale_doc_this_month'].value,
            'icon': 'pi pi-clock',
          },
          {
            'header': this.translateService.instant('DSHOVA100.M00050'),
            'order': page_model.form_model.gridOverviewForm.controls['sale_doc_three_month'].value,
            'icon': 'fa fa-hourglass-half',
          },
          {
            'header': this.translateService.instant('DSHOVA100.M00051'),
            'order': page_model.form_model.gridOverviewForm.controls['sale_doc_this_year'].value,
            'icon': 'fa fa-calendar',
          }
        ];
      });
    }
  }

  loadAllChartDataData(page_model: Dshova100Model, country: string, seller: string, period: string, uuid: string, datetype: string, periodtype: string) {
    if (country != null && seller != null && period != null) {
      this.dshovaService.getDashbordOverallByPeriod(country, seller, period, page_model.uuid).subscribe(response => {
        page_model.uuid = response.uuid;
        page_model.DatatoChartStock = response.stockCradByPeriod;
        if (page_model.DatatoChartStock != null) {
          if (page_model.DatatoChartStock.length > 0) {
            page_model.stockBal = page_model.DatatoChartStock;
            if (page_model.stockBal.length == 0 || !page_model.stockBal) {
              page_model.showStockChart = false;
              page_model.showDataNotFoundStockChart = true;
            } else {
              const listLabel = new Array();
              const listQty = new Array();

              page_model.stockBal.forEach(element => {
                listLabel.push(this.translateLablePipe.transform(element.item_english_desc, element.item_local_desc));
                listQty.push(element.ending_balance_qty);
              });

              let legendChart: any;
              if (window.screen.width >= 640 && window.screen.width < 1025) {
                legendChart = false;
              } else if (window.screen.width > 1024) {
                legendChart = 'right';
              }

              page_model.stockBalanceChart = {
                labels: listLabel,
                datasets: [{
                  label: this.translateService.instant('DSHOVA100.M00046'),
                  data: listQty,
                  backgroundColor: ChartColor.backgroundColorChart,
                  hoverBackgroundColor: ChartColor.backgroundColorChart
                }]
              };

              page_model.optionPie = {
                legend: {
                  position: legendChart,
                }
              };

              page_model.showStockChart = true;
              page_model.showDataNotFoundStockChart = false;
            }
          }else{
            page_model.showStockChart = false;
            page_model.showDataNotFoundStockChart = true;
          }
        } else {
          page_model.showStockChart = false;
          page_model.showDataNotFoundStockChart = true;
        }

        page_model.dataToshowChart = response.saleOrderDetailByPeriod;
        page_model.select = { datetype, period: periodtype, typeBar: 'single', showText: this.translateService.instant('DSHOVA100.M00047') };
        if (page_model.dataToshowChart != null) {
          if (page_model.dataToshowChart.length == 0) {
            page_model.showDataNotFound = true;
          } else {
            page_model.showDataNotFound = false;
          }
        } else {
          page_model.showDataNotFound = true;
        }

        if (page_model.select.datetype == 'D') {
          page_model.select.period == 0 || page_model.select.period == 1 ? page_model.showSaleChart = true : page_model.showSaleChart = false;
        } else {
          page_model.showSaleChart = false;
        }

        page_model.salesByItemGrid = response.tableSaleOrderDetailByPeriod;
        if (page_model.salesByItemGrid != null) {
          if (page_model.salesByItemGrid.length == 0) {
            page_model.showGrid = false;
            page_model.showDataNotFoundGrid = true;
            page_model.showAdvanceSearch = false;
          } else {
            page_model.showDataNotFoundGrid = false;
            page_model.showGrid = true;
            page_model.backupGridData = page_model.salesByItemGrid;
          }
        } else {
          page_model.showGrid = false;
          page_model.showDataNotFoundGrid = true;
          page_model.showAdvanceSearch = false;
        }
      });

    }
  }
}
