import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DshRoutingModule } from './dsh-routing.module';
import { Dshfna100Component } from './dshfna/dshfna100/dshfna100.component';
import { Dshfna200Component } from './dshfna/dshfna200/dshfna200.component';
import { Dshlgt100Component } from './dshlgt/dshlgt100/dshlgt100.component';
import { Dshova100Component } from './dshova/dshova100/dshova100.component';
import { Dshsal100Component } from './dshsal/dshsal100/dshsal100.component';
import { Dshstk100Component } from './dshstk/dshstk100/dshstk100.component';


import { SharedModule } from '../shared/shared.module';
import { Dshlgt110Component } from './dshlgt/dshlgt110/dshlgt110.component';
import { Dshsalfs100Component } from './dshsalfs/dshsalfs100/dshsalfs100.component';
import { Dshovafs100Component } from './dshovafs/dshovafs100/dshovafs100.component';
import { Dshstkfs100Component } from './dshstkfs/dshstkfs100/dshstkfs100.component';

@NgModule({
  declarations: [
    Dshfna100Component,
    Dshfna200Component,
    Dshlgt100Component,
    Dshova100Component,
    Dshsal100Component,
    Dshstk100Component,
    Dshlgt110Component,
    Dshovafs100Component,
    Dshstkfs100Component,
    Dshsalfs100Component
  ],
  imports: [
    CommonModule,
    DshRoutingModule,
    SharedModule
  ]
})
export class DshModule { }
