import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvironmentService } from './environment.service';
import { AuthenticationService } from './authentication.service';

import { Menu, MenuList, Label } from '../model/menu.model';

export enum GroupMenuId {
    MenuBar = 1, MainMenu, Footer, HomePage, UserMenu=5
  }

@Injectable({
    providedIn: 'root'
})

export class AdministrationMenuService {

    private ADMMNU_URL: string = `${this.environmentService.getApiUrl() }/adm/mnu` as string;

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private environmentService: EnvironmentService) {
        }

    getMenu(Country): Observable<Array<Menu>> {
        const URL: string = `${this.ADMMNU_URL}/getMenu/${Country}` as string;
        return this.http.get<Array<Menu>>(URL, { headers: this.authenticationService.tokenHeader() });
    }

    getMenuBar(Country): Observable<Array<Menu>> {
        const URL: string = `${this.ADMMNU_URL}/getMenuGroup/${GroupMenuId.MenuBar}/${Country}` as string;
        return this.http.get<Array<Menu>>(URL, { headers: this.authenticationService.tokenHeader() });
    }

    getMainMenu(Country): Observable<Array<Menu>> {
        const URL: string = `${this.ADMMNU_URL}/getMenuGroup/${GroupMenuId.MainMenu}/${Country}` as string;
        return this.http.get<Array<Menu>>(URL, { headers: this.authenticationService.tokenHeader() });
    }

    getMenuFooter(Country): Observable<Array<Menu>> {
        const URL: string = `${this.ADMMNU_URL}/getMenuGroup/${GroupMenuId.Footer}/${Country}` as string;
        return this.http.get<Array<Menu>>(URL, { headers: this.authenticationService.tokenHeader() });
    }

    getMenuHome(Country): Observable<Array<Menu>> {
        const URL: string = `${this.ADMMNU_URL}/getMenuGroup/${GroupMenuId.HomePage}/${Country}` as string;
        return this.http.get<Array<Menu>>(URL, { headers: this.authenticationService.tokenHeader() });
    }

    getMenuUser(Country): Observable<Array<Menu>> {
        const URL: string = `${this.ADMMNU_URL}/getMenuGroup/${GroupMenuId.UserMenu}/${Country}` as string;
        return this.http.get<Array<Menu>>(URL, { headers: this.authenticationService.tokenHeader() });
    }

    getMenuList(): Observable<Array<MenuList>> {
        const URL: string = `${this.ADMMNU_URL}/getMenuList` as string;
        return this.http.get<Array<MenuList>>(URL, { headers: this.authenticationService.tokenHeader() });
    }

    getLabel(Country): Observable<Label> {
      const URL: string = `${this.ADMMNU_URL}/getLabel/${Country}` as string;
      return this.http.get<Label>(URL, { headers: this.authenticationService.tokenHeader() });
  }
}
