import { Component, Inject, Injectable, Renderer2, TemplateRef } from '@angular/core';
import { PremissionService } from 'src/app/shared/services/premission.service';
import { PermissionModel, FlowTypeAndSaleChannalData, RequestData, ActionData } from 'src/app/shared/model/premission-model';
import { NgxPermissionsService, NgxPermissionsConfigurationService, NgxRolesService } from 'ngx-permissions';
import { AppInjector } from 'src/app/shared/services/app-injecter-service';
import { ActivatedRoute } from '@angular/router';
import { SalesOrderrService } from 'src/app/shared/services/sales-order-service';
import { SaleOrderDetail } from 'src/app/ord/ordsor/ordsor200/model/sales-order-maintenance-model';
import { actionData } from 'src/app/shared/constant/permission.constant';

@Component({
  selector: 'app-permission-dimension',
  templateUrl: './permission-dimension.component.html',
  styleUrls: ['./permission-dimension.component.scss']
})
@Injectable()
export class PermissionDimensionComponent {

  permissionData: PermissionModel;
  premissionService: PremissionService;
  ngxPermissionsService: NgxPermissionsService;
  ngxPermissionsConfigurationService: NgxPermissionsConfigurationService;
  ngxRolesService: NgxRolesService;
  injector = AppInjector.getInjector();
  saleorderServices: SalesOrderrService;
  salesOrderDetail = {} as SaleOrderDetail;
  dataCheckDimension: Array<FlowTypeAndSaleChannalData> = [] as Array<FlowTypeAndSaleChannalData>;
  requestData = {} as RequestData;
  saleOrderNo: any;
  warehouse_code_view: any;
  actionData: ActionData;

  constructor(
    @Inject('menuCode') private menuCode: string,
    private renderer2: Renderer2,
    private route: ActivatedRoute
  ) {

    this.premissionService = this.injector.get(PremissionService);
    this.saleorderServices = this.injector.get(SalesOrderrService);
    this.ngxPermissionsService = this.injector.get(NgxPermissionsService);
    this.ngxRolesService = this.injector.get(NgxRolesService);
    this.ngxPermissionsConfigurationService = this.injector.get(NgxPermissionsConfigurationService);
    this.saleOrderNo = this.route.snapshot.paramMap.get('saleOrderNo');
    this.warehouse_code_view = route.snapshot.paramMap.get('warehouseCode');
    this.requestData.menuCode = this.menuCode;
    this.actionData = actionData;

    if (this.saleOrderNo == 'SOCreate' || this.saleOrderNo == null) {
      this.getPermission(this.requestData);

    } else {
      this.saleorderServices.getSaleOrderDetail(this.saleOrderNo, this.warehouse_code_view).subscribe((response: SaleOrderDetail) => {
        this.salesOrderDetail = response;

        this.dataCheckDimension.push(
          { dimensionCode: 'FT', dimensionValue: this.salesOrderDetail.movement_type_code },
          { dimensionCode: 'SC', dimensionValue: this.salesOrderDetail.sale_channel_code })
        this.requestData.dimensionData = this.dataCheckDimension;
        this.getPermission(this.requestData);
      });
    }
  }

  getPermission(requestData) {
    this.premissionService.getPermission(requestData).subscribe((res: PermissionModel) => {
      this.permissionData = res;
      this.ngxPermissionsService.addPermission(res.accActionFalse);
      this.ngxPermissionsConfigurationService.setDefaultOnUnauthorizedStrategy('disable');
      this.ngxPermissionsConfigurationService.setDefaultOnAuthorizedStrategy('enable');
    });

    this.ngxPermissionsConfigurationService.addPermissionStrategy('disable', (templateRef: TemplateRef<any>) => {

        if (this.permissionData.accActionFalse[0] != 'Not find false') {
          this.renderer2.addClass(templateRef.elementRef.nativeElement.nextSibling, 'disable-permission');
        } else {
          this.renderer2.removeClass(templateRef.elementRef.nativeElement.nextSibling, 'disable-permission');
        }
    });
  }

}
