import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { Observable } from 'rxjs';
import * as JWT from 'jwt-decode';
import { authModel } from '../../core/interfaces/authModel'
import { authDataModel } from '../../core/interfaces/authDataModel'
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private  key_token : string  = 'Token';
  private  key_data : string  = 'userData';
  expire:boolean;
  checkExpTime:any;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private router: Router
  ) {}
  
  public ckeckExpOrRefreshToken(){
    if(localStorage){
      let user = JSON.parse(localStorage.getItem(this.key_data)) as authDataModel
      let current = new Date();
      let refreshToken = new Date(user.refreshToken);
      let token = JWT(localStorage.getItem(this.key_token));
      this.checkExpToken(token.exp);
      if(current >= refreshToken && this.expire == false){
        this.doRefreshToken().subscribe((result)=>{
          this.removeToken();
          let tokenData = JWT(result.token);
          this.checkExpToken(tokenData.exp);
          this.setToken(result.token);
          this.doAuth().subscribe((result) => {
            this.removeAuthData()
            let authData = result as authDataModel;
            localStorage.setItem('userData', JSON.stringify(authData));
          })
        },(error) => {
          this.removeToken();
          this.removeAuthData()
          this.router.navigate(['/']);
        });
      }else if(this.expire){
        this.removeToken();
        this.removeAuthData()
        this.router.navigate(['/']);
      }
    }
  }

  public refreshtoKen(){
    this.doRefreshToken().subscribe((result)=>{
      this.removeToken();
      let tokenData = JWT(result.token);
      this.checkExpToken(tokenData.exp);
      this.setToken(result.token);
      this.doAuth().subscribe((result) => {
        this.removeAuthData()
        let authData = result as authDataModel;
        localStorage.setItem('userData', JSON.stringify(authData));
      })
    },(error) => {
      this.removeToken();
      this.removeAuthData()
      this.router.navigate(['/']);
    });
  }

  public removeToken() {
    localStorage.removeItem(this.key_token);
  };

  public removeAuthData() {
    localStorage.removeItem(this.key_data);
  };

  public doLogin(username,password): Observable<authModel>{
    this.removeToken();
    let URL = `signin`;
    return this.http.post<authModel>(`${this.apiService.getApiUrl()}${URL}`,      
    {
      password: password,
      usercode: username
    });
  }

  public doAuth(): Observable<authDataModel>{
    let URL = `userauthentication`;
    return this.http.get<authDataModel>(`${this.apiService.getApiUrl()}${URL}`, {headers: this.tokenHeader()});
  }

  public doRefreshToken(): Observable<authModel>{
    let URL = `refreshtoken`;
    return this.http.get<authModel>(`${this.apiService.getApiUrl()}${URL}`, {headers: this.tokenHeader()});
  }

  public doLogout(){
    let URL = `signout`;
    return this.http.get<authDataModel>(`${this.apiService.getApiUrl()}${URL}`, {headers: this.tokenHeader()});
  }

  public checkExpToken(expTime){
    this.checkExpTime = expTime;
    const date = new Date(0); 
    date.setUTCSeconds(expTime);
    if(date.valueOf() < new Date().valueOf()){
      this.expire = true;
    }else{
      this.expire = false;
    }
  };

  public getExpToken() {
    this.checkExpToken(this.checkExpTime);
    return this.expire;
  };

  public setToken(token) : void {
    localStorage.setItem(this.key_token,token);
  };

  public tokenHeader() : any {
    return {
      "Authorization": "Bearer " + this.getToken(),
      'Content-Type': 'application/json'
    };
  }

  public getToken() : string {
    return localStorage.getItem(this.key_token);
  };

  public changePwd(oldPwd,newPwd,confNewPwd){
    let URL = `user/changePassword`;
    return this.http.post(`${this.apiService.getApiUrl()}${URL}`,      
    {
      oldPassword: oldPwd,
      newPassword: newPwd,
      confirmNewPassword:confNewPwd,
    }, {headers: this.tokenHeader()});
  }
  public forgetUserCd(userCd){
    let URL = `email/userCd/`;
    return this.http.get(`${this.apiService.getApiUrl()}${URL}${userCd}`);
  }

}