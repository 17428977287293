import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Chart from 'chart.js';
import { element } from 'protractor';

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.scss']
})
export class PieChartComponent implements OnInit {

  @Input() dataChart: any;
  @Input() unit: string = 'DSHDSH000.M00000' as string;
  optionPie: any;

  constructor(private decimalPipe: DecimalPipe,private translateService: TranslateService) { }

  ngOnInit(): void {
    this.generateOption();
  }

  ngOnChanges() {
    this.generateOption();
  }

  generateOption() {
    let reset = true;
    let boxhtml = '';
    const percent = [];
    const perc = [];
    let listNum = [];
    let sumNum: number = 0 as number;
    const unit: string = this.translateService.instant(this.unit);
    if (this.dataChart !== undefined) {
      perc.push(this.dataChart);
      perc.forEach(element =>
        listNum = element.datasets[0].data);
      }
    let reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue);
    sumNum = (listNum.reduce(reducer, 0));
    listNum.forEach(element => {
      let val = (Number(element) / Number(sumNum)) * 100;
      let num = this.decimalPipe.transform(val, '1.2-2');
      percent.push(num)
    });
    Chart.defaults.global.defaultFontFamily = "kanitmedium";
    this.optionPie = {
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return data['labels'][tooltipItem[0]['index']];
          },
          label: function (tooltipItem, data) {
            return data['datasets'][0]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + [' ' + [unit] + ' ' + '('] + [percent][0][tooltipItem['index']] + ['%)'];
          }
        }
      },
      legend: {
        display: false,
        labels: {
          fontFamily:'kanitmedium',
          generateLabels(chart) {
            if (chart.data.datasets.length != 0) {
              const text = [];
              text.push('<div class="' + chart.id + '-legend " style="overflow:auto;width: 100%;cursor:Default; padding-left:10%;height: 270px" >');
              for (let i = 0; i < chart.data.datasets[0].data.length; i++) {
                text.push('<p><span class="ui-g-2 ui-sm-2" style="width: 25px;height: 10px;display: inline-block;margin-right: 10px;background-color:' + chart.data.datasets[0].backgroundColor[i] + '">');
                text.push('</span>');
                if (chart.data.labels[i]) {
                  text.push('<label class=" ui-g-10 ui-sm-10" style="padding:0 0 0 0 ">')
                  text.push(chart.data.labels[i]);
                  text.push('</label>')
                }
                text.push('</p>');
              }
              text.push('</div>');
              if (reset) {
                for (let k = 0; k < text.length; k++) {
                  boxhtml = boxhtml + text[k];
                }
              }
              reset = false;
              document.getElementById('js-legend').innerHTML = boxhtml;
              let html = document.getElementById('js-legend');
              this.legendItems = html.getElementsByTagName('p');
              for (let i = 0; i < this.legendItems.length; i += 1) {
                this.legendItems[i].addEventListener('click', (event) => {
                  event = event || window.event;
                  let target = event.target || event.srcElement;
                  while (target.nodeName !== 'P') {
                    target = target.parentElement;
                  }
                  const parent = target.parentElement;
                  const chartId = parseInt(parent.classList[0].split('-')[0], 10);
                  
                  const chart = Chart.instances[chartId];
                  const index = Array.prototype.slice.call(parent.children).indexOf(target);
                  const meta = chart.getDatasetMeta(0);
                  let item = meta.data[index];
                  if (item.hidden === null || item.hidden === false) {
                    item.hidden = true;
                    chart.update();
                    document.getElementById('js-legend').getElementsByTagName('label')[i].style.cssText = 'text-decoration: line-through; padding:0 0 0 0;';
                    boxhtml = document.getElementById('js-legend').innerHTML;
                  } else {
                    item.hidden = null;
                    chart.update();
                    document.getElementById('js-legend').getElementsByTagName('label')[i].style.cssText = 'padding:0 0 0 0;';
                    boxhtml = document.getElementById('js-legend').innerHTML;
                  }
                });
              }
              return null;
            }
          },
        },
      },
    };
  }


}
