import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class PriceSaleService {

  private FNPRISAL_URL: string = `${this.environmentService.getApiUrl()}/psa/pri` as string;

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private authenticationService: AuthenticationService
  ) { }


  saveOrUpdate(data) {
    const URL: string = `${this.FNPRISAL_URL}/saveOrUpdate` as string;
    return this.http.post(URL, data, { headers: this.authenticationService.tokenHeader() });
  }

  getPriceSaleData(prisalNo:number){
    const URL: string = `${this.FNPRISAL_URL}/getPriceSaleInfo/${prisalNo}` as string;
    return this.http.get(URL,{ headers: this.authenticationService.tokenHeader()});
  }



}


