import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { EmployeeAgentService } from 'src/app/shared/services/employee-agent.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';;
import { SalesOrderrService } from 'src/app/shared/services/sales-order-service';
import { AgentInfoDetail, CategoryTable } from 'src/app/shared/model/agent.model';
import { Agent200Model, DDLAgent200, DecimalData } from './model/empagt200.model';
import { PermissionComponent } from 'src/app/shared/components/permission/permission.component';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { DecimalPipe } from '@angular/common';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';

@Component({
  selector: 'app-empagt200',
  templateUrl: './empagt200.component.html',
  styleUrls: ['./empagt200.component.scss']
})
export class Empagt200Component extends PermissionComponent {

  ddlAgent200Model = {} as DDLAgent200;
  agent200Model = {} as Agent200Model;
  public user = {} as AuthDataModel;

  public decimalData:DecimalData = {} as DecimalData;
  typeDecimalRoundMethod: string = '3';
  typeDecimalRoundToValue: string = '4';
  decimal:number;
  dataTypeDecimalRound:any;
  roundToDecimal:number;
  method:string;
  decimalRound:number = 0;
  selectedRowData: any;
  checkDataInput: boolean;
  checkFlag: Array<CategoryTable> = [] as Array<CategoryTable>;
  submitted: boolean;

  agentform = new FormGroup({
      agentid: new FormControl(),
      agentCode: new FormControl(),
      agentname: new FormControl({ value: '', disabled: false }, Validators.required),
      agentsurname: new FormControl({ value: '', disabled: false }, Validators.required),
      agentlevel: new FormControl(),
      teamleader: new FormControl(),
      idcard: new FormControl(),
      birthdate: new FormControl(),
      username: new FormControl(),
      taxid: new FormControl(),
      phone: new FormControl({ value: '', disabled: false }, Validators.required),
      email: new FormControl(),
      facebook: new FormControl(),
      lineId: new FormControl(),
      instagram: new FormControl(),
      bookbank: new FormControl(),
      bankaccountname: new FormControl(),
      bank: new FormControl(),
      country: new FormControl({ value: null, disabled: false }, Validators.required),
      countryOrigin: new FormControl(),
      currency: new FormControl({ value: '',disabled: true}),
      paymenttype: new FormControl(),
      shoptype: new FormControl(),
      firstjoin: new FormControl(),
      gp: new FormControl(),
      shelfprice: new FormControl(),
      anyprice: new FormControl(),
      productage: new FormControl(),
      productTis: new FormControl(),
      labeleng: new FormControl(),
      anys: new FormControl(),
      address: new FormControl({ value: '', disabled: false }, Validators.required),
      province: new FormControl({ value: null, disabled: false }, Validators.required),
      district: new FormControl(),
      subDistrict: new FormControl(),
      post: new FormControl({ value: '', disabled: false }, Validators.required),
      status: new FormControl(),
      remark: new FormControl(),
      organization: new FormControl({ value: '',disabled: true})
    })

    lengthUsername:number;
    maxDate = new Date();
    translateLablePipe = new TranslateLablePipe();
    codeNullValue: number;
    nameNullValue: number;
    translateCalendarPipe = new TranslateCalendarPipe();
    translateCalendar:any = this.translateCalendarPipe.transform();

    constructor(private empagtService: EmployeeAgentService,
      private parameterService: ParameterService,
      route: ActivatedRoute,
      public router: Router,
      private messageService: MessageService,
      private formBuilder: FormBuilder,
      private autService: AuthenticationService,
      private parameterwms: ParameterWmsSerive,
      private translateService: TranslateService,
      private saleorderService: SalesOrderrService,
      private decimalService: DecimalService,
      private decimalPipe: DecimalPipe,
      renderer2:Renderer2) {
      super('P70001',renderer2);
      this.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel
      this.agent200Model.agentCode = route.snapshot.paramMap.get('agentCode');

      this.parameterwms.getParameterWms(105).subscribe(response => {
          this.ddlAgent200Model.optionCountry = JSON.parse(JSON.stringify(response));
          this.ddlAgent200Model.countryOrigin = JSON.parse(JSON.stringify(response));
        });

      this.parameterService.getParameterDetailToLableValueCode(1401).subscribe(response=>{
        this.ddlAgent200Model.shoptype = JSON.parse(JSON.stringify(response));
      });

      this.parameterService.getParameterDetailToLableValueCode(1400).subscribe(response=>{
        this.ddlAgent200Model.agentlevel = JSON.parse(JSON.stringify(response));
      });

      this.parameterService.getParameterDetailToLableValueCode(1403).subscribe(response=>{
        this.ddlAgent200Model.fda = JSON.parse(JSON.stringify(response));
      });

      this.parameterService.getParameterDetailToLableValueCode(4100).subscribe((response) => {
        this.ddlAgent200Model.paymentType = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });

      this.parameterService.getParameterDetailToLableValueCode(1402).subscribe((response) => {
        this.ddlAgent200Model.labelEnglish = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });

      this.parameterwms.getParameterCategory().subscribe((response) => {
        this.ddlAgent200Model.category = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });

      this.saleorderService.getparameterMasterAgent().subscribe(response => {
        this.ddlAgent200Model.teamleader = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });

    }

    ngOnInit() {

      this.agent200Model.categoryProduct = [] as Array<CategoryTable>;
      this.agent200Model.dataNotFound = true;
      this.agent200Model.isChecker = true;
      this.agent200Model.defaultAgentDetail = {} as AgentInfoDetail;
      this.agent200Model.bankDDL = 4302;

      if(this.agent200Model.agentCode){
          this.getAgentDetail();
      }
      setTimeout(() => {
        this.checkPermission();
      }, 1200);

      this.agent200Model.defaultAgentDetail.updateuser = this.user.userName ? this.user.userName : this.user.userDesc;
      this.agentform.get("username").valueChanges.subscribe(data => {
        if(this.lengthUsername != null){
          if(data.length < this.lengthUsername){
            this.agentform.controls['organization'].setValue(null);
          }
        }
      });
    }

    getOrganization(){
      this.empagtService.getOrgByUsername(this.agentform.get('username').value).subscribe((org:any) =>{
        this.agentform.controls['organization'].setValue(this.translateLablePipe.transform(org.orgEngDesc,org.orgLocalDesc))
        this.lengthUsername = this.agentform.controls['username'].value.length;
      })
    }

    changeCountryAddress(country){
      this.agentform.controls['district'].setValue(null);
      this.agentform.controls['subDistrict'].setValue(null);
      this.agentform.controls['province'].setValue(null);
      this.seachProvinceByCoutry(country);
      this.formatDecimal(country);
    }

    changeCountryBankAcc(country){

      this.parameterwms.getParameterWmsBytableNoAndCode(105, country.value).subscribe((responseParam) => {
        let respones = JSON.parse(JSON.stringify(responseParam));


        this.parameterwms.getParameterWmsBytableNoAndCode(9146, respones[0].parameterData.parameter_v6).subscribe((currencyResult) => {
          let currency = JSON.parse(JSON.stringify(currencyResult));
          this.agentform.controls['currency'].setValue(this.translateLablePipe.transform(currency[0].engDesc,currency[0].localDesc));

        });
      });
  this.parameterService.getvprmdtltolableByCountryV2(this.agent200Model.bankDDL, country.value).subscribe(bank =>{
    this.ddlAgent200Model.bank1 = JSON.parse(JSON.stringify(bank));
  });
    }
    seachProvinceByCoutry(page) {
      this.parameterwms.getAddressWms(102, page.value).subscribe(response => {
        this.ddlAgent200Model.province = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);

      });
    }
    changeProvince(province,type){
      if(type==1){//type=1 is changeDDL
        this.agentform.controls['district'].setValue(null);
        this.agentform.controls['subDistrict'].setValue(null);
      }
      this.parameterwms.getAddressWms(103, province.value).subscribe(response => {
        this.ddlAgent200Model.district = JSON.parse(JSON.stringify(response));
      }, err => {
        console.log(err);
      });
    }

    changeDistrict(district,type){
      if(type==1){//type=1 is changeDDL
        this.agentform.controls['subDistrict'].setValue(null);
      }

      this.parameterwms.getAddressWms(104, district.value).subscribe(response => {
        this.ddlAgent200Model.subDistrict = JSON.parse(JSON.stringify(response));

      }, err => {
        console.log(err);
      });

    }
    get valid() {
      return this.agentform.controls;
    }
    SaveUpdateAgent() {
      if(this.agentform.controls.agentname.value && this.agentform.controls.agentsurname.value &&
        this.agentform.controls.phone.value && this.agentform.controls.country.value &&
        this.agentform.controls.province.value && this.agentform.controls.address.value &&
        this.agentform.controls.post.value){
      const agentEditInterface: Partial<AgentInfoDetail> = {
        agentId: this.agentform.controls.agentid.value,
        agentCode: this.agentform.controls.agentCode.value,
        agentName: this.agentform.controls.agentname.value,
        agentSurname: this.agentform.controls.agentsurname.value,
        agentLevel: this.agentform.controls.agentlevel.value,
        agentTeamleader: this.agentform.controls.teamleader.value,
        agentPhone: this.agentform.controls.phone.value,
        agentEmail: this.agentform.controls.email.value,
        agentIdcard: this.agentform.controls.idcard.value,
        agentBirthdate: moment(this.agentform.controls.birthdate.value).format('DD/MM/YYYY'),
        agentUsername: this.agentform.controls.username.value,
        agentTaxid: this.agentform.controls.taxid.value,
        agentFacebook: this.agentform.controls.facebook.value,
        agentLine_id: this.agentform.controls.lineId.value,
        agentInstagram: this.agentform.controls.instagram.value,
        // agentCategory: this.agentform.controls.category.value,
        agentBookbank: this.agentform.controls.bookbank.value,
        agentBank: this.agentform.controls.bank.value,
        agentBankaccountname: this.agentform.controls.bankaccountname.value,
        agentPaymenttype: this.agentform.controls.paymenttype.value,
        agentGp: this.agentform.controls.gp.value,
        agentShoptype: this.agentform.controls.shoptype.value,
        agentFirstjoin: this.agentform.controls.firstjoin.value,
        agentShelfprice: this.agentform.controls.shelfprice.value,
        agentAnyprice: this.agentform.controls.anyprice.value,
        agentProductage: this.agentform.controls.productage.value,
        agentLabelEng: this.agentform.controls.labeleng.value,
        agentAnys: this.agentform.controls.anys.value,
        agentProductTis: this.agentform.controls.productTis.value,
        agentCurrency: this.agentform.controls.currency.value,
        agentCountry: this.agentform.controls.country.value,
        agentAddress: this.agentform.controls.address.value,
        agentProvince: this.agentform.controls.province.value,
        agentDistrict: this.agentform.controls.district.value,
        agentCanton: this.agentform.controls.subDistrict.value,
        agentPost: this.agentform.controls.post.value,
        agentStatus: this.agentform.controls.status.value,
        countryOrigin: this.agentform.controls.countryOrigin.value,
        agentDesc: this.agentform.controls.remark.value,
        updateuser: this.user.userName ? this.user.userName : this.user.userDesc,
        agentCategory:this.agent200Model.categoryProduct,
        checkflag: this.checkFlag
      }
      this.empagtService.saveAgentDetail(agentEditInterface).subscribe(res=>{
        let result = JSON.parse(JSON.stringify(res));
        if (result.status == 0) {
          this.messageService.add({ key: 'saveSuccess',sticky: true, severity: 'success', /*summary: 'EMPAGT200.M00101', */detail: 'EMPAGT200.M00102' });
          setTimeout(() => {
            let url = '/empagt/empagt200/' + result.data.agentCode;
            this.redirectTo(url);
            window.scroll(0, 0);
          }, 500);

        } else {
          this.messageService.add({ key: 'alert',sticky: true, severity: 'error', summary: 'EMPAGT200.M00103', detail: result.message });
        }

      });

      }else{
        this.messageService.add({ key: 'alert',sticky: true, severity: 'error', summary: 'EMPAGT200.M00103', detail: 'EMPAGT200.M00104' });
      }

    }
    redirectTo(uri: string) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate([uri]).then(() => {
          window.location.reload();
        }));
    }

    showConfirm() {
      this.messageService.clear();
      this.messageService.add({ key: 'e', sticky: true, severity: 'warn', summary: 'EMPAGT200.M00101', detail: 'EMPAGT200.M00105' });
    }
    getAgentDetail(){
      this.checkDataInput = true;
      this.empagtService.getAgentDetail(this.agent200Model.agentCode).subscribe(res=>{
        let agentDetail = JSON.parse(JSON.stringify(res));
        if(agentDetail.username != null){
          this.lengthUsername = agentDetail.username.length;
        }
        let birthdate = moment(agentDetail.birthdate).format('DD/MM/YYYY');
        this.agentform.setValue({
          agentid: agentDetail.agentid,
          agentCode: agentDetail.agentCode,
          agentname: agentDetail.agentname,
          agentsurname: agentDetail.agentsurname,
          agentlevel: agentDetail.agentlevel,
          teamleader: agentDetail.teamleader,
          idcard: agentDetail.idcard,
          birthdate: birthdate,
          username: agentDetail.username,
          taxid: agentDetail.taxid,
          phone: agentDetail.phone,
          email: agentDetail.email,
          facebook: agentDetail.facebook,
          lineId: agentDetail.lineId,
          instagram: agentDetail.instagram,
          bookbank: agentDetail.bookbank,
          bankaccountname: agentDetail.bankaccountname,
          countryOrigin:agentDetail.agentBankcountry,//
          subDistrict:agentDetail.canton,
          remark:agentDetail.agentDesc,
          organization:this.translateLablePipe.transform(agentDetail.organizDescEng,agentDetail.organizDescLocal),
          bank: agentDetail.bank,
          country: agentDetail.country,
          currency: agentDetail.currency,
          paymenttype: agentDetail.paymenttype,
          shoptype: agentDetail.shoptype,
          firstjoin: agentDetail.firstjoin,
          gp: agentDetail.gp,
          shelfprice: agentDetail.shelfprice,
          anyprice: agentDetail.anyprice,
          productage: agentDetail.productage,
          productTis: agentDetail.productTis,
          labeleng: agentDetail.labeleng,
          anys: agentDetail.anys,
          address: agentDetail.address,
          province: agentDetail.province,
          district: agentDetail.district,
          post: agentDetail.post,
          status: agentDetail.status,
        })
        this.agent200Model.defaultAgentDetail = agentDetail;
        let country = { value: agentDetail.country };
        let province = { value: agentDetail.province };
        let district = { value: agentDetail.district };
        let origin = { value: agentDetail.agentBankcountry};
        this.changeCountryBankAcc(origin);
        this.seachProvinceByCoutry(country);
        this.changeProvince(province,0);
        this.changeDistrict(district,0);
        this.agent200Model.categoryProduct = agentDetail.categoryList;
        if(agentDetail.categoryList != null){
          this.agent200Model.categoryProduct.forEach(x=>x.category_check = false);
        }
      })
    }

    onConfirm() {
      this.messageService.clear('e');
      this.router.navigateByUrl('/empagt/empagt100');
    }

    onReject() {
      this.messageService.clear();

    }

    public clearerrorY() {
      this.messageService.clear('e');
    }
    checkConfirm() {
      if(this.agentform.dirty){
        if(this.agent200Model.agentCode == null){
          if(this.agentform.controls.agentname.value || this.agentform.controls.agentsurname.value ||
            this.agentform.controls.phone.value || this.agentform.controls.country.value ||
            this.agentform.controls.province.value || this.agentform.controls.address.value ||
            this.agentform.controls.post.value){
              this.messageService.add({ key: 'backpage', sticky: true, severity: 'warn', summary: 'ย้อนกลับ', detail: 'EMPAGT200.M00105' });
            }else{
              this.router.navigateByUrl('/empagt/empagt100');
            }
        }else{
          if(this.agent200Model.defaultAgentDetail.agentName != this.agentform.controls.agentname.value ||
            this.agent200Model.defaultAgentDetail.agentSurname != this.agentform.controls.agentsurname.value ||
            this.agent200Model.defaultAgentDetail.agentPhone != this.agentform.controls.phone.value ||
            this.agent200Model.defaultAgentDetail.agentCountry != this.agentform.controls.country.value ||
            this.agent200Model.defaultAgentDetail.agentProvince != this.agentform.controls.province.value ||
            this.agent200Model.defaultAgentDetail.agentAddress != this.agentform.controls.address.value ||
            this.agent200Model.defaultAgentDetail.agentPost != this.agentform.controls.post.value){
              this.messageService.add({ key: 'backpage', sticky: true, severity: 'warn', summary: 'EMPAGT200.M00106', detail: 'EMPAGT200.M00105' });
            }else{
              this.router.navigateByUrl('/empagt/empagt100');
          }
        }
      }
      else{
        this.router.navigateByUrl('/empagt/empagt100');
      }
    }
    backpage(){
      this.router.navigateByUrl('/empagt/empagt100');
    }
    checked(isChecker: any, row) {
      this.agent200Model.row = row;
      if (isChecker) {
        this.agent200Model.categoryProduct[row].category_check = true;
      } else {
        this.agent200Model.categoryProduct[row].category_check = false;
      }

      for(let i = 0; i < this.agent200Model.categoryProduct.length; i++){
        let index = this.agent200Model.categoryProduct.findIndex(x => x.category_check == true);
        if(index !== -1){
          this.agent200Model.isChecker = false;
        }else{
          this.agent200Model.isChecker = true;
        }
      }
    }

    checkedAll(isChecker: any, row) {
      this.agent200Model.row = row;
      if (isChecker) {
        this.agent200Model.isChecker = false;
        this.agent200Model.categoryProduct.forEach(x => x.category_check = true);
      } else {
        this.agent200Model.isChecker = true;
        this.agent200Model.categoryProduct.forEach(x => x.category_check = false);
      }
      }

    AddCategory(){
      if(this.agent200Model.categoryProduct != null){
        this.codeNullValue = this.agent200Model.categoryProduct.findIndex(x => x.agentCatCode === null);
        this.nameNullValue = this.agent200Model.categoryProduct.findIndex(x => x.agentCatName === null);
        if ( this.codeNullValue !== -1 ||  this.nameNullValue !== -1) {
          this.messageService.add({ key: 'warning',sticky: true, severity: 'warn', summary: 'EMPAGT200.M00107', detail: 'EMPAGT200.M00104' });
        }else{
          this.agent200Model.categoryProduct.push({
            agentID: null,
            category_check: false,
            agentCatCode: null,
            agentCatName: null,
            categoryID: null,
          });
        }
      }else{
          this.agent200Model.categoryProduct = this.agent200Model.categoryProduct || [];
          this.agent200Model.categoryProduct.push({
            agentID: null,
            category_check: false,
            agentCatCode: null,
            agentCatName: null,
            categoryID: null,
          });
      }
      this.checkDataInput = false;
    }

    changeCategory(agentCatCode,index){
      let changeCategory = this.ddlAgent200Model.category.filter(act =>act.value == agentCatCode.value);
      this.agent200Model.categoryProduct[index].agentCatName = changeCategory[0].desc;
      this.checkDataInput = false;
    }

    DeleteCategory(){
      this.messageService.add({ key: 'delete', sticky: true, severity: 'warn', summary: 'EMPAGT200.M00109', detail: 'EMPAGT200.M00108' });
    }

    CategoryDelete(){
      if (this.agent200Model.row == 'all') {
        this.checkFlag = this.agent200Model.categoryProduct;
        this.agent200Model.categoryProduct = [];
      }else{
        let index = this.agent200Model.categoryProduct.length;
        while(index --){
            if(this.agent200Model.categoryProduct[index].category_check == true){
              this.checkFlag.push(this.agent200Model.categoryProduct[index]);
              this.agent200Model.categoryProduct.splice(index, 1);
            }
        }
      }
      this.selectedRowData = [];
      this.agent200Model.isChecker = true;
      this.messageService.clear('delete');
      this.checkDataInput = false;
    }

    checkPermission(){
      let checkPermission = this.permissionData.accActionFalse.filter(act =>act == "1");
      if(checkPermission.length > 0){
        this.agent200Model.disablePermission = true;
      }else{
        this.agent200Model.disablePermission =  false;
      }
    }

    formatDecimal(country){

      this.parameterService.getDiscoutPrice(104,country.value,this.typeDecimalRoundToValue).subscribe(dataRound=>{
      this.dataTypeDecimalRound = JSON.parse(JSON.stringify(dataRound));
      this.decimalRound = this.dataTypeDecimalRound.pmdtbdnumv1;
      });
      this.parameterService.getvprmdtltolableByCountry(104, country.value).subscribe(response => {
      let decimalData = JSON.parse(JSON.stringify(response));
      this.decimalData.dataTypeDecimalRoundMethod = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalRoundMethod);
      this.decimalData.dataTypeDecimalRoundToValue = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.typeDecimalRoundToValue);
      this.decimalData.dataTypeDecimalRound = decimalData.filter(data => data.value.parameterDetailPK.pmdtbdtbdentcd == this.dataTypeDecimalRound);
      this.method = this.decimalData.dataTypeDecimalRoundMethod[0].value.pmdtbdtxtv1
      this.roundToDecimal = this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1;
      this.decimal = Math.round(Math.pow(this.decimalData.dataTypeDecimalRoundToValue[0].value.pmdtbdnumv1,1/10));
    });

    }

    dicimalGP(){
      this.agentform.controls['gp'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.agentform.controls['gp'].value.toString().replace(/,/g, ""), this.method, this.roundToDecimal, this.decimal), '0.'+this.decimal+'-'+this.decimal, 'en'));
    }

    dicimalFirst(){
      this.agentform.controls['firstjoin'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.agentform.controls['firstjoin'].value.toString().replace(/,/g, ""), this.method, this.roundToDecimal, this.decimal), '0.'+this.decimal+'-'+this.decimal, 'en'));

    }

    dicimalAnyPrice(){
      this.agentform.controls['anyprice'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.agentform.controls['anyprice'].value.toString().replace(/,/g, ""), this.method, this.roundToDecimal, this.decimal), '0.'+this.decimal+'-'+this.decimal, 'en'));
    }

    decimalShelfPrice(){
      this.agentform.controls['shelfprice'].setValue(this.decimalPipe.transform(this.decimalService.getRoundingDecimal(this.agentform.controls['shelfprice'].value.toString().replace(/,/g, ""), this.method, this.roundToDecimal,this.decimal), '1.' + this.decimal + '-' + this.decimal, 'en'));
    }

    checkData(){
      this.checkDataInput = false
    }
}
