import { Injectable } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Observable } from "rxjs";
import { CriteriaFormGroup, FormModel, itm100Model, SearchFormGroup } from "src/app/itm/model/itm-model";
import { ItemByWhModel, ItemExpiryModel, ItemInquiryByWhRequest, ItemInquiryExpiryRequest, ItemInquiryRequest, ItemModel } from "src/app/shared/model/item.model";
import { ItemService } from "src/app/shared/services/item.service";



@Injectable({
  providedIn: 'root'
})
export class itm100Service {

   itm100: itm100Model = {} as itm100Model;

   constructor(private itemService:  ItemService ,
      private fb: FormBuilder
      ){
          this.inti();
      }

      inti(): void {

          this.itm100.form = {} as FormModel;
          this.itm100.form.searchFormGroup = {} as SearchFormGroup;
          this.itm100.form.searchFormGroup.form = this.fb.group({
              sellerCode: [null],
              klangOneCode:[null],
              itmNameEn:[null],
              itmBrand:[null],
              hierarchyCode: [null]
         });
         this.itm100.form.criteriaFormGroup = {} as CriteriaFormGroup;
         this.itm100.form.criteriaFormGroup.form = this.fb.group({
              seller: [null]
     });

        this.itm100.orignalData = {};
        this.itm100.orignalData.itemList = [];
        this.itm100.orignalData.itemList = this.itm100.itemList;
     }
     get load(): itm100Model{

        return this.itm100;
     }

    searchSubmit() :  Observable<Array<ItemModel>>{
        let itmInquiry:  ItemInquiryRequest = {} as  ItemInquiryRequest ;
        itmInquiry.sellerCode = this.itm100.form.searchFormGroup.form.get('sellerCode').value;
        itmInquiry.klangOneCode= this.itm100.form.searchFormGroup.form.get('klangOneCode').value;
        itmInquiry.itmNameEn= this.itm100.form.searchFormGroup.form.get('itmNameEn').value;
        itmInquiry.itmBrand=this.itm100.form.searchFormGroup.form.get('itmBrand').value;
        itmInquiry.hierarchyCode = this.itm100.form.searchFormGroup.form.get('hierarchyCode').value;
        itmInquiry.seller = this.itm100.form.criteriaFormGroup.form.get('seller').value;
        return this.itemService.getItemInquiry(itmInquiry);
    }

    searchByWhSubmit() :  Observable<Array<ItemByWhModel>>{
        let itmInquiry:  ItemInquiryByWhRequest = {} as  ItemInquiryByWhRequest ;
        itmInquiry.sellerCode = this.itm100.form.searchFormGroup.form.get('sellerCode').value;
        itmInquiry.klangOneCode= this.itm100.form.searchFormGroup.form.get('klangOneCode').value;
        itmInquiry.itmNameEn= this.itm100.form.searchFormGroup.form.get('itmNameEn').value;
        itmInquiry.itmBrand=this.itm100.form.searchFormGroup.form.get('itmBrand').value;
        itmInquiry.hierarchyCode = this.itm100.form.searchFormGroup.form.get('hierarchyCode').value;
        itmInquiry.seller = this.itm100.form.criteriaFormGroup.form.get('seller').value;
        return this.itemService.getItemInquiryByWh(itmInquiry);
    }

    searchProductExpiryGroupForm() :  Observable<Array<ItemExpiryModel>>{
        let itmInquiry:  ItemInquiryExpiryRequest = {} as  ItemInquiryExpiryRequest ;
        itmInquiry.sellerCode  = this.itm100.form.searchFormGroup.form.get('sellerCode').value;
        itmInquiry.klangOneCode= this.itm100.form.searchFormGroup.form.get('klangOneCode').value;
        itmInquiry.itmNameEn= this.itm100.form.searchFormGroup.form.get('itmNameEn').value;
        itmInquiry.itmBrand=this.itm100.form.searchFormGroup.form.get('itmBrand').value;
        itmInquiry.hierarchyCode = this.itm100.form.searchFormGroup.form.get('hierarchyCode').value;
        itmInquiry.seller = this.itm100.form.criteriaFormGroup.form.get('seller').value;
        return this.itemService.getItemInquiryExpiry(itmInquiry);
    }

}
