import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SerRoutingModule } from './ser-routing.module';
import { Serser100Component } from './serser/serser100/serser100.component';
import { Serser200Component } from './serser/serser200/serser200.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [Serser100Component, Serser200Component],
  imports: [
    CommonModule,
    SerRoutingModule,
    SharedModule
  ]
})
export class SerModule {}
