import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Serser100Component } from './serser/serser100/serser100.component';
import { Serser200Component } from './serser/serser200/serser200.component';


const routes: Routes = [
  {path: 'serser/serser100', component: Serser100Component},
  {path: 'serser/serser200/:sellerCode', component: Serser200Component}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SerRoutingModule { }
