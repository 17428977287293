import { ChangeDetectorRef, Component, Renderer2, ViewChild } from '@angular/core';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { DataTabelSaleOrderInq, CheckBoxValue, ExportSaleorderInq, OR100Search, TempDDL, ExportSaleorder, ResentPamApi, ExportSaleorderReqeust } from '../../model/ord100.model';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ExportExcelService } from 'src/app/shared/services/export-excel.service';
import { SalesOrderrService } from 'src/app/shared/services/sales-order-service';
import { PermissionDimensionComponent } from 'src/app/shared/components/permission-dimension/permission-dimension.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ParameterWmsSerive } from 'src/app/shared/services/parameterWms.service';
import { ParameterService } from 'src/app/shared/services/parameter.service';
import { DecimalService } from 'src/app/shared/services/decimal.service';
import { Big } from 'big.js';
import { Router } from '@angular/router';
import { TranslateLablePipe } from 'src/app/shared/pipes/translateLable.pipe';
import { AuthDataModel } from 'src/app/shared/model/authentication.model';
import { ThaiDatePipe } from 'src/app/shared/pipes/thaidate.pipe';
import { TranslateCalendarPipe } from 'src/app/shared/pipes/translate-calendar.pipe';
import { Table } from 'primeng/table';
import { VParameter } from 'src/app/shared/model/vparameter';


@Component({
  selector: 'app-ord100',
  templateUrl: './ord100.component.html',
  styleUrls: ['./ord100.component.scss']
})
export class Ord100Component extends PermissionDimensionComponent {

  @ViewChild('table',{static:true}) table: Table
  first:number=0;
  last:number=0;
  public optionCountry: TempDDL[];
  movementTypeData: TempDDL[];
  paymentMethodData: TempDDL[];
  orderStatus: TempDDL[];
  warehouseData: TempDDL[];
  paymentStatusData: TempDDL[];
  saleChannelCategory: TempDDL[];
  saleChannel: TempDDL[];
  status: boolean;
  checkboxStatusSo: any[] = [];
  checkboxStatusPayment: string[] = [];
  checkboxStatusTransport: string[] = [];
  checkboxConfirm:any[] = [];
  row: any=[];
  public Search: string;
  public disButtonSearch: boolean = true;
  statusShipment: SelectItem[];
  public dataTable: Array<DataTabelSaleOrderInq> = [] as Array<DataTabelSaleOrderInq>
  public exportSaleOrderBig: Array<ExportSaleorder> = [] as Array<ExportSaleorder>
  public exportSaleOrder : ExportSaleorderInq = {} as ExportSaleorderInq;
  public setDataTable: Array<DataTabelSaleOrderInq> = [] as Array<DataTabelSaleOrderInq>
  public IsSearchAdvanced: boolean = false as boolean;
  public resentPamApiModel: Array<ResentPamApi> = [] as Array<ResentPamApi>;
  public exportSaleorderReqeust: ExportSaleorderReqeust = {} as ExportSaleorderReqeust;
  public fieldExportSaleorder: Array<VParameter> = [] as Array<VParameter>
  translateLablePipe = new TranslateLablePipe();
  thaidate = new ThaiDatePipe();
  decimalSelling: string;
  typeDecimalSelling: string = '2';
  typeDecimalRoundMethod: string = '3';
  methodSelling:string = "0";
  typeDecimalRound: string = '4';
  roundSelling:number = 0;
  dataTypeDecimalRound:any;
  dataTypeDecimalSelling:any;
  dataTypeDecimalRoundMethod:any;
  warehouseDataDisFlag: boolean = false as boolean;
  saleChannelDisFlag: boolean = false as boolean;
  public searchAdvancedGroupForm = new FormGroup({
    warehouse_code: new FormControl(),
    seller_name: new FormControl(),
    flow_type_code: new FormControl(),
    sales_close_by: new FormControl(),
    sales_order_status: new FormControl(),
    sales_order_no: new FormControl(),
    salesOrderCreationDate: new FormControl(),
    payment_method: new FormControl(),
    sales_channels_category: new FormControl(),
    sales_channels: new FormControl(),
    expectedDeliveryDate: new FormControl(),
    id_klone: new FormControl(),
    customer_name: new FormControl(),
    referenceNumber: new FormControl(),
    statusSaleOrder: new FormControl(),
    statusPaymeny: new FormControl(),
    statusShipment:new FormControl(),
    last_mile_status :new FormControl(),
    check_refer_creditnote:new FormControl(),
    confirm_deli_flag:new FormControl(),
    country_code:new FormControl(),
    customer_order_no:new FormControl(),
    page_limit:new FormControl(),
    page_start:new FormControl(),
    payment_status:new FormControl(),
    product_owner_code:new FormControl()
  });
  criteriaSearchGrid = { country: ''};
  orderCreateDate: string;
  defaultData:any;
  searchShort:any;
  language:string;
  languageTH:string;
  disable :boolean = true;
  statusSaleOrder:Array<CheckBoxValue> = [] as Array<CheckBoxValue>;
  statusPaymeny:Array<CheckBoxValue> = [] as Array<CheckBoxValue>;
  statusTransport:Array<CheckBoxValue> = [] as Array<CheckBoxValue>;
  checkReferCreditNote: Array<CheckBoxValue> = [] as Array<CheckBoxValue>;
  toggleSearch:boolean;
  dataNotFound:boolean = true;
  country : string;
  showdialog:Boolean;
  dataSrc:any;
  dataTableSearchShort:any;
  disCountry:boolean;
  movementTypeFlag:boolean = false;
  soInquirySeach = {} as OR100Search;
  Country: string;
  countryUse: string;
  user: AuthDataModel;
  firstRow: number = 0 as number;
  saleChannelCategoryDisFlag:boolean = false;
  order: MenuItem[];
  translateCalendarPipe = new TranslateCalendarPipe();
  translateCalendar:any = this.translateCalendarPipe.transform();
  languageSent: string;
  disableExportReport: boolean;
  disableResentPAM: boolean;

  constructor(private parameterService:ParameterService,
              private parameterWmsSerive:ParameterWmsSerive,
              private exportExcelService: ExportExcelService,
              private saleorderService : SalesOrderrService,
              private autService: AuthenticationService,
              private translateService: TranslateService,
              private decimalService:DecimalService,
              private router: Router,
              private cdRef : ChangeDetectorRef,
              private messageService: MessageService,
              /* For Permission*/
              renderer2:Renderer2,
              route: ActivatedRoute) {
              super('P31101',renderer2,route);
              this.status = false;
              this.Country = localStorage.getItem('languageData') as string;
              this.user = JSON.parse(localStorage.getItem('userData')) as AuthDataModel;
              this.language = localStorage.getItem('languageData') as string;
              this.languageTH='TH';
              }

  ngOnInit() {
    this.checkValueCountry();
    setTimeout(() => {
      this.checkPermission();
      this.order = [
        { label: this.translateService.instant('ORDSOR100.M00080'), command: () => { this.exportSaleOreder(); }, disabled: this.disableExportReport},
        { label: this.translateService.instant('ORDSOR100.M00081'), command: () => { this.resendApiDate(); }, disabled: this.disableResentPAM}]
    }, 7000);

    this.saleorderService.getparameterDetailToLable(4100).subscribe((response) => {
      this.paymentMethodData = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.autService.getParameterWmsFlowTypeInquiry().subscribe((response) => {

      this.movementTypeData = JSON.parse(JSON.stringify(response));
      if(this.movementTypeData.length == 0){
        this.movementTypeFlag = true;
      }
    }, err => {
      console.log(err);
    });

    this.checkMobile();

    this.statusShipment = [
      { value: '', label: 'ORDSOR100.M00034'},
      { value: 0, label: 'ORDSOR100.M00036'},
      { value: 1, label: 'ORDSOR100.M00035'}
    ];

    this.parameterWmsSerive.getParameterWmsCheckbox(9141).subscribe((response) => {
      this.statusSaleOrder = JSON.parse(JSON.stringify(response));
      this.statusSaleOrder.sort((a,b) => Number(a.value) - Number(b.value));
      let indexOfPicked = this.statusSaleOrder.findIndex(i => i.value === '4');
      let indexOfPacked = this.statusSaleOrder.findIndex(i => i.value === '5');
      let indexOfWaitPick = this.statusSaleOrder.findIndex(i => i.value === '7');
      let indexOfWaitPack = this.statusSaleOrder.findIndex(i => i.value === '8');

      let waitPick = this.statusSaleOrder[indexOfWaitPick];
      this.statusSaleOrder[indexOfWaitPick] = this.statusSaleOrder[indexOfPicked];
      this.statusSaleOrder[indexOfPicked] = waitPick;

      let waitPack = this.statusSaleOrder[indexOfWaitPack];
      this.statusSaleOrder[indexOfWaitPack] = this.statusSaleOrder[indexOfPacked];
      this.statusSaleOrder[indexOfPacked] = waitPack;
    }, err => {
      console.log(err);
    });

    this.parameterWmsSerive.getParameterWmsCheckbox(9149).subscribe((response) => {
      this.statusPaymeny = JSON.parse(JSON.stringify(response));
    }, err => {
      console.log(err);
    });

    this.parameterWmsSerive.getParameterWms(9148).subscribe((response) => {
        this.statusTransport = JSON.parse(JSON.stringify(response));
    }, err => {
        console.log(err);
    });

    this.parameterService.getvprmdtltolableByPmhtbhtbnoAndTxtv2(4108, 'Y').subscribe(dataRound => {
      this.fieldExportSaleorder = JSON.parse(JSON.stringify(dataRound));
    });

    this.setDataTable = this.dataTable;
    this.checkReferCreditNote = [
      {label:'Yes', value:'true',checked: true, localDesc:'ใช่', engDesc:'Yes'},
      {label:'No', value:'false',checked: false, localDesc:'ไม่ใช่', engDesc:'No'}
    ];
 
    if (this.Country == 'TH') {
      this.languageSent = 'th';
    } else {
      this.languageSent = 'en';
    }
  }

  ngAfterViewChecked(){

    if(this.table._totalRecords ==0 ){
    } else {
      this.first = this.table._first+1;
      this.last = this .table._first+this.table._rows;
      if(this.last>this.dataTable.length ){
        this.last=this.dataTable.length
      }
    }
    this.cdRef.detectChanges();
  }

  checkPermission() {
    let checkPermissionExportReport = this.permissionData.accActionFalse.filter(act => act == "7");
    let checkPermissionReSentPAM = this.permissionData.accActionFalse.filter(act => act == "8");

    if (checkPermissionExportReport.length > 0) {
      this.disableExportReport = true;
    } else {
      this.disableExportReport = false;
    }

    if (checkPermissionReSentPAM.length > 0) {
      this.disableResentPAM = true;
    } else {
      this.disableResentPAM = false;
    }
  }

  getCountryCurrency(page){
    this.disButtonSearch = false;
    this.country = page.value;
    this.searchAdvancedGroupForm.controls['country_code'].setValue(this.country);
    this.formatDecimal(page.value);

    this.autService.getParameterSaleChannelInquiry(page.value).subscribe((response) => {
      this.saleChannelCategory = JSON.parse(JSON.stringify(response));
      if(this.saleChannelCategory.length == 0){
        this.saleChannelCategoryDisFlag = true;
      }else{
        this.saleChannelCategoryDisFlag = false;
      }

    }, err => {
      console.log(err);
    });

    this.autService.getParameterWmsWareHouse(page.value).subscribe(response =>{
      this.warehouseData = JSON.parse(JSON.stringify(response));
      if(this.warehouseData.length == 0){
        this.warehouseDataDisFlag = true;
      }else{
        this.warehouseDataDisFlag = false;
      }
    },err=>{
      console.log(err);

    });
  }

  formatDecimal(country){
    this.parameterService.getDiscoutPrice(104,country,this.typeDecimalSelling).subscribe(dataRound=>{
      this.dataTypeDecimalSelling = JSON.parse(JSON.stringify(dataRound));
      this.decimalSelling = this.dataTypeDecimalSelling.pmdtbdnumv1;
    });

    this.parameterService.getDiscoutPrice(104,country,this.typeDecimalRoundMethod).subscribe(dataRound=>{
      this.dataTypeDecimalRoundMethod = JSON.parse(JSON.stringify(dataRound));
      this.methodSelling = this.dataTypeDecimalRoundMethod.pmdtbdnumv1;
    });

    this.parameterService.getDiscoutPrice(104,country,this.typeDecimalRound).subscribe(dataRound=>{
      this.dataTypeDecimalRound = JSON.parse(JSON.stringify(dataRound));
      this.roundSelling = this.dataTypeDecimalRound.pmdtbdnumv1;
    });
  }

  getSaleOrderByCounty(){
    this.soInquirySeach.country_code = this.country;
    this.soInquirySeach.warehouse_code = this.searchAdvancedGroupForm.controls['warehouse_code'].value;
    this.soInquirySeach.seller_name = this.searchAdvancedGroupForm.controls['seller_name'].value;
    this.soInquirySeach.sales_close_by = this.searchAdvancedGroupForm.controls['sales_close_by'].value;
    this.soInquirySeach.sales_order_no = this.searchAdvancedGroupForm.controls['sales_order_no'].value;
    this.soInquirySeach.payment_method = this.searchAdvancedGroupForm.controls['payment_method'].value;
    this.soInquirySeach.flow_type_code = this.searchAdvancedGroupForm.controls['flow_type_code'].value;
    this.soInquirySeach.sales_channels = this.searchAdvancedGroupForm.controls['sales_channels'].value;
    this.soInquirySeach.sales_channels_category = this.searchAdvancedGroupForm.controls['sales_channels_category'].value;
    this.soInquirySeach.id_klone = this.searchAdvancedGroupForm.controls['id_klone'].value;
    this.soInquirySeach.customer_name = this.searchAdvancedGroupForm.controls['customer_name'].value;
    this.soInquirySeach.referenceNumber = this.searchAdvancedGroupForm.controls['referenceNumber'].value;
    this.soInquirySeach.check_refer_creditnote = this.searchAdvancedGroupForm.controls['check_refer_creditnote'].value;
    if(this.searchAdvancedGroupForm.controls['salesOrderCreationDate'].value != null){
      this.soInquirySeach.salesOrderCreationDateFrom = moment(this.searchAdvancedGroupForm.controls['salesOrderCreationDate'].value[0]).format('DD/MM/YYYY');
      this.soInquirySeach.salesOrderCreationDateTo = moment(this.searchAdvancedGroupForm.controls['salesOrderCreationDate'].value[1]).format('DD/MM/YYYY');
    }

    if(this.searchAdvancedGroupForm.controls['expectedDeliveryDate'].value != null){
      this.soInquirySeach.expectedDeliveryDateFrom = moment(this.searchAdvancedGroupForm.controls['expectedDeliveryDate'].value[0]).format('DD/MM/YYYY');
      this.soInquirySeach.expectedDeliveryDateTo = moment(this.searchAdvancedGroupForm.controls['expectedDeliveryDate'].value[1]).format('DD/MM/YYYY');
    }

    this.soInquirySeach.statusSaleOrder = this.checkboxStatusSo;
    this.soInquirySeach.statusPaymeny = this.checkboxStatusPayment;
    this.soInquirySeach.statusShipment = this.checkboxConfirm;

    if(this.searchAdvancedGroupForm.controls['last_mile_status'].value){
        this.checkboxStatusTransport = [];
        this.checkboxStatusTransport.push(this.searchAdvancedGroupForm.controls['last_mile_status'].value);
    }
    this.soInquirySeach.last_mile_status = this.checkboxStatusTransport[0];

    this.saleorderService.getAllSalesOrderInq(this.soInquirySeach).subscribe(res=>{
      this.dataTable = JSON.parse(JSON.stringify(res));
      this.defaultData = JSON.parse(JSON.stringify(res));
      if(this.dataTable.length == 0){
        this.dataNotFound = true;
      }else{
        this.dataNotFound = false;
      }

    }, err => {
      console.log(err);
    });
    this.disButtonSearch = true;
  }

  filterData() {
    if(this.country != null){
      this.disButtonSearch = false;
    }else{
      this.disButtonSearch = true;
    }
  }

  smartSearchShort(event) {
    this.dataTableSearchShort = this.defaultData;
    // this.criteriaSearchGrid.smartSearchShort = this.criteriaSearchGrid.smartSearchShort == null ? '' : this.criteriaSearchGrid.smartSearchShort;
    if(this.searchShort){
      this.dataTable = this.dataTableSearchShort.filter(data=>{
        if(this.Country == 'TH'){
          if(data.sales_order_date && data.sales_order_date.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.sales_order_no && data.sales_order_no.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.warehouse_desc_local && data.warehouse_desc_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.customer_name && data.customer_name.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.member_id_klangOne && data.member_id_klangOne.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.sale_closing_by && data.sale_closing_by.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.sales_order_ref && data.sales_order_ref.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.seller_desc_local && data.seller_desc_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.flow_type_desc_local && data.flow_type_desc_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.sales_channel_desc_local && data.sales_channel_desc_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              this.decimalService.getRoundingDecimal(new Big(Number(data.total_amount)),this.dataTypeDecimalRoundMethod.pmdtbdtxtv1,this.dataTypeDecimalRound.pmdtbdnumv1,this.dataTypeDecimalSelling.pmdtbdnumv1).toString().toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.total_amount.toFixed(this.dataTypeDecimalSelling.pmdtbdnumv1).replace(/\d(?=(\d{3})+\.)/g, '$&,') && data.total_amount.toFixed(this.dataTypeDecimalSelling.pmdtbdnumv1).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.expected_delivery_date && data.expected_delivery_date.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.payment_method_local && data.payment_method_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.sales_order_status_desc_local && data.sales_order_status_desc_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.payment_status_local && data.payment_status_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.last_mile_status_desc_local && data.last_mile_status_desc_local.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              this.thaidate.transform(data.last_mile_status_datetime, 'formatCommon') && this.thaidate.transform(data.last_mile_status_datetime.toLowerCase(), 'formatCommon').includes(this.searchShort.toLowerCase())
              ){
            return true;
          }
        } else{
          if(data.sales_order_date && data.sales_order_date.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.sales_order_no && data.sales_order_no.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.warehouse_desc_eng && data.warehouse_desc_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.customer_name && data.customer_name.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.member_id_klangOne && data.member_id_klangOne.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.sale_closing_by && data.sale_closing_by.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.sales_order_ref && data.sales_order_ref.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.seller_desc_eng && data.seller_desc_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.flow_type_desc_eng && data.flow_type_desc_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.sales_channel_desc_eng && data.sales_channel_desc_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              this.decimalService.getRoundingDecimal(new Big(Number(data.total_amount)),this.dataTypeDecimalRoundMethod.pmdtbdtxtv1,this.dataTypeDecimalRound.pmdtbdnumv1,this.dataTypeDecimalSelling.pmdtbdnumv1).toString().toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.total_amount.toFixed(this.dataTypeDecimalSelling.pmdtbdnumv1).replace(/\d(?=(\d{3})+\.)/g, '$&,') && data.total_amount.toFixed(this.dataTypeDecimalSelling.pmdtbdnumv1).replace(/\d(?=(\d{3})+\.)/g, '$&,').toString().toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.expected_delivery_date && data.expected_delivery_date.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.payment_method_eng && data.payment_method_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.sales_order_status_desc_eng && data.sales_order_status_desc_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.payment_status_eng && data.payment_status_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              data.last_mile_status_desc_eng && data.last_mile_status_desc_eng.toLowerCase().includes(this.searchShort.toLowerCase()) ||
              this.thaidate.transform(data.last_mile_status_datetime, 'formatCommon') && this.thaidate.transform(data.last_mile_status_datetime.toLowerCase(), 'formatCommon').includes(this.searchShort.toLowerCase())
              ){
            return true;
          }
        }
      return false;
    });
    }
    else{
      this.dataTable = this.dataTableSearchShort;
    }

    if(this.dataTable.length == 0){
      this.dataNotFound = true;
    }else{
      this.dataNotFound = false;
      this.firstRow = 0;
    }
  }

  ClearData() {
    if(this.dataTable){
      this.dataTable.forEach((element: any) => element.checked = false );
    }
    this.searchAdvancedGroupForm.reset();
    this.criteriaSearchGrid.country = null;
    this.disButtonSearch = true;
    this.dataTable = null;
    this.dataNotFound = true;
    this.warehouseData = null;
    this.country = null;
    this.checkboxStatusSo = [];
    this.checkboxStatusPayment = [];
    this.checkboxConfirm = [];

    this.soInquirySeach.country_code = null;
    this.soInquirySeach.warehouse_code = [];
    this.soInquirySeach.seller_name = null;
    this.soInquirySeach.sales_close_by = null;
    this.soInquirySeach.sales_order_no = null;
    this.soInquirySeach.salesOrderCreationDateFrom = null;
    this.soInquirySeach.salesOrderCreationDateTo = null;
    this.soInquirySeach.payment_method = null;
    this.soInquirySeach.flow_type_code = null;
    this.soInquirySeach.sales_channels = [];
    this.soInquirySeach.sales_channels_category = [];
    this.soInquirySeach.expectedDeliveryDateFrom = null;
    this.soInquirySeach.expectedDeliveryDateTo = null;
    this.soInquirySeach.id_klone = null;
    this.soInquirySeach.customer_name = null;
    this.soInquirySeach.referenceNumber = null;
    this.soInquirySeach.statusSaleOrder = [];
    this.soInquirySeach.statusPaymeny = [];
    this.soInquirySeach.statusShipment = [];
    this.statusSaleOrder.forEach((element: CheckBoxValue) => {
      element.checked = false;
    });
    this.statusPaymeny.forEach((element: CheckBoxValue) => {
      element.checked = false;
    });
    this.statusShipment.forEach((element: CheckBoxValue) => {
      element.checked = false;
    });
    this.checkValueCountry();

  }

  createOreder(){
    this.router.navigate(['/ordsor/ordsor200'])
  }

  exportReport() {
    let exportOrder = [];
    this.defaultData.forEach(dataTable=>{
        exportOrder.push({
          sales_order_date : this.thaidate.transform(dataTable.sales_order_date, 'shortCommonFullTime'),
          sales_order_no: dataTable.sales_order_no,
          warehouse_desc: this.translateLablePipe.transform(dataTable.warehouse_desc_eng,dataTable.warehouse_desc_local),
          seller_desc: this.translateLablePipe.transform(dataTable.seller_desc_eng,dataTable.seller_desc_local),
          customer_name: dataTable.customer_name,
          member_id_klangOne: dataTable.member_id_klangOne,
          sale_closing_by: dataTable.sale_closing_by,
          flow_type_desc: this.translateLablePipe.transform(dataTable.flow_type_desc_eng,dataTable.flow_type_desc_local),
          sales_channel_category_desc: this.translateLablePipe.transform(dataTable.sales_channel_category_desc_eng,dataTable.sales_channel_category_desc_local),
          sales_channel_desc: this.translateLablePipe.transform(dataTable.sales_channel_desc_eng,dataTable.sales_channel_desc_local),
          total_amount:this.decimalService.getRoundingDecimal(new Big(Number(dataTable.total_amount)),this.dataTypeDecimalRoundMethod.pmdtbdtxtv1,this.dataTypeDecimalRound.pmdtbdnumv1,this.dataTypeDecimalSelling.pmdtbdnumv1),
          expected_delivery_date:this.thaidate.transform(dataTable.expected_delivery_date, 'shortCommon'),
          payment_method: this.translateLablePipe.transform(dataTable.payment_method_eng,dataTable.payment_method_local),
          sales_order_ref: dataTable.sales_order_ref,
          sales_order_status_desc:  this.translateLablePipe.transform(dataTable.sales_order_status_desc_eng,dataTable.sales_order_status_desc_local),
          payment_status: this.translateLablePipe.transform(dataTable.payment_status_eng,dataTable.payment_status_local),
          confirm_ship: this.translateLablePipe.transform(dataTable.confirmed_ship_desc_eng, dataTable.confirmed_ship_desc_local),
          last_mile_status: this.translateLablePipe.transform(dataTable.last_mile_status_desc_eng, dataTable.last_mile_status_desc_local),
          last_mile_status_datetime:  this.thaidate.transform(dataTable.last_mile_status_datetime, 'shortCommonFullTime'),
          refer_creditnote: dataTable.refer_creditnote
        })
    });

    let subTitle = [];
    subTitle.push([this.translateService.instant('ORDSOR100.M00065')]);
    let header: any = {
      sales_order_date: this.translateService.instant('ORDSOR100.M00039'),
      sales_order_no: this.translateService.instant('ORDSOR100.M00011'),
      warehouse_desc: this.translateService.instant('ORDSOR100.M00008'),
      seller_desc: this.translateService.instant('ORDSOR100.M00009'),
      customer_name: this.translateService.instant('ORDSOR100.M00018'),
      member_id_klangOne: this.translateService.instant('ORDSOR100.M00017'),
      sale_closing_by: this.translateService.instant('ORDSOR100.M00010'),
      flow_type_desc: this.translateService.instant('ORDSOR100.M00014'),
      sales_channel_category_desc: this.translateService.instant('ORDSOR100.M00015'),
      sales_channel_desc: this.translateService.instant('ORDSOR100.M00073'),
      total_amount: this.translateService.instant('ORDSOR100.M00043'),
      expected_delivery_date: this.translateService.instant('ORDSOR100.M00044'),
      payment_method: this.translateService.instant('ORDSOR100.M00013'),
      sales_order_ref: this.translateService.instant('ORDSOR100.M00019'),
      sales_order_status_desc: this.translateService.instant('ORDSOR100.M00045'),
      payment_status: this.translateService.instant('ORDSOR100.M00021'),
      confirm_ship: this.translateService.instant('ORDSOR100.M00022'),
      last_mile_status: this.translateService.instant('ORDSOR100.M00070'),
      last_mile_status_datetime: this.translateService.instant('ORDSOR100.M00071'),
      refer_creditnote: this.translateService.instant('ORDSOR100.M00074')
    }
    this.exportExcelService.exportAsExcelFile(exportOrder, this.translateService.instant('ORDSOR100.M00065'), header, null, subTitle);
  }

  checkMobile(){
    if (window.screen.width >= 200 && window.screen.width < 1025) {
      this.toggleSearch= true;
    } else if (window.screen.width > 1024){
      this.toggleSearch= false;
    }
  }

  checked(isChecker: any, row) {
    if (isChecker) {
      this.row.push(row);
    } else {
      const index = this.row.findIndex(data => data === row);
      this.row.splice(index,1);
    }
  }

  checkedAll(isChecker: any, row) {
    if (isChecker) {
      for(let i = 0 ; i < this.dataTable.length ; i ++){
        this.row.push(i);
      }
    } else {
      this.row=[];
    }
  }

  loadfile(){
    if(this.Country == 'EN'){
      this.countryUse = 'en'
    }else{
      this.countryUse = 'lo'
    }
     let seleceData:any=[];
      this.row.forEach(element => {
        seleceData.push(this.dataTable[element]);
      });
          var groups = seleceData.reduce(function(obj,item){
          obj[item.warehouse_code] = obj[item.warehouse_code] || [];
          obj[item.warehouse_code].push(item.sales_order_no);
          return obj;
      }, {});
      var getReport = Object.keys(groups).map(function(key){
          return {warehouse_code: key, sales_order_no: groups[key]};
      });
    for(let i = 0 ; i < getReport.length ; i ++){
      this.saleorderService.getReport(this.countryUse,getReport[i].sales_order_no,this.user.userID, this.user.userName, getReport[i].warehouse_code).subscribe((resultBlob: Blob) => {
      var downloadURL = URL.createObjectURL(resultBlob);
      window.open(downloadURL);
    });
    }
  }

  onCheckChange(event,data){
    if(event){
      this.checkboxStatusSo.push(data);
    }else {
      let removeIndex = this.checkboxStatusSo.findIndex(itm => itm===data);
      if(removeIndex !== -1)
        this.checkboxStatusSo.splice(removeIndex,1);
    }
    this.filterData();
  }

  statusPayment(event,data){
    if(event){
      this.checkboxStatusPayment.push(data);
    }else {
      let removeIndex = this.checkboxStatusPayment.findIndex(itm => itm===data);
      if(removeIndex !== -1)
        this.checkboxStatusPayment.splice(removeIndex,1);
    }
    this.filterData();
  }

  deliveryConfirmation(event,data) {
    if(event){
      if(data === ''){
        this.statusShipment.forEach((element: CheckBoxValue) => {
          if(element.value == '0' ||  element.value == '1'  )
          element.checked = false;

        });
        this.checkboxConfirm.splice(0,2);
      }
      else{
        this.statusShipment.forEach((element: CheckBoxValue) => {
          if(element.value === ''  )
          element.checked = false;
          const index = this.checkboxConfirm.findIndex(res => res === '');
            if(index != -1){
              this.checkboxConfirm.splice(index,1);
            }
        });
      }
      this.checkboxConfirm.push(data);

    }else {
      let removeIndex = this.checkboxConfirm.findIndex(itm => itm===data);
      if(removeIndex !== -1)
        this.checkboxConfirm.splice(removeIndex,1);
    }
    this.filterData();
  }

  refershTable(dTable,dTabletho){
    if(this.dataTable){
      this.dataTable.forEach((element: any) => element.checked = false );
    }
    dTable.reset();
    dTabletho.reset();
  }

  checkValueCountry(){
    this.autService.getParameterWmsCountry().subscribe(response => {
      this.optionCountry = JSON.parse(JSON.stringify(response));
      if (this.optionCountry.length == 1) {
        this.disCountry = true;
        this.disButtonSearch = false;
        this.criteriaSearchGrid.country = this.optionCountry[0].value;
        this.country = this.optionCountry[0].value;
        this.formatDecimal(this.country);

        this.autService.getParameterSaleChannelInquiry(this.optionCountry[0].value).subscribe((response) => {
          this.saleChannelCategory = JSON.parse(JSON.stringify(response));
          if(this.saleChannelCategory.length == 0){
            this.saleChannelCategoryDisFlag = true;
          }
        }, err => {
          console.log(err);
        });

        this.autService.getParameterWmsWareHouse(this.optionCountry[0].value).subscribe(response =>{
          this.warehouseData = JSON.parse(JSON.stringify(response));
          if(this.warehouseData.length == 0){
            this.warehouseDataDisFlag = true;
          }
        },err=>{
          console.log(err);

        });
      }else if(this.optionCountry.length == 0){
        this.disCountry = true;
      }
    }, err => {
      console.log(err);
    });
  }

  getSaleChannelCategory(){
    this.parameterService.getvprmdtltolableByCountryTxtv1(4103,this.criteriaSearchGrid.country,this.searchAdvancedGroupForm.controls['sales_channels_category'].value).subscribe(response=>{
      this.saleChannel = JSON.parse(JSON.stringify(response));
      if(this.saleChannel.length == 0){
        this.saleChannelDisFlag = true;
      }else{
        this.saleChannelDisFlag = false;
      }
    },err=>{
      console.log(err);
    });
  }
  
  async exportSaleOreder(){
    let saleOrderIsSelect: string[] = [];
    this.row.forEach(element => {   
      saleOrderIsSelect.push(this.dataTable[element].sales_order_no);
    });
    
    if(saleOrderIsSelect.length == 0){
      this.dataTable.forEach(data => {
        saleOrderIsSelect.push(data.sales_order_no);
      });
    }
    if(saleOrderIsSelect.length <= 400){
      let header: any = {}
      this.fieldExportSaleorder.sort((a, b) => Number(a.parameterDetailPK.pmdtbdtbdentcd) - Number(b.parameterDetailPK.pmdtbdtbdentcd));
      for (const key in this.fieldExportSaleorder) {
        header[this.fieldExportSaleorder[key].pmdtbdtxtv1] = this.translateService.instant(this.fieldExportSaleorder[key].pmdtbdtxtv3);
      }

      let subTitle = [];
      subTitle.push([this.translateService.instant('ORDSOR100.M00146')]);

      this.exportSaleorderReqeust.language = this.languageSent;
      this.exportSaleorderReqeust.country = this.soInquirySeach.country_code;
      this.exportSaleorderReqeust.saleOrderNo = saleOrderIsSelect;
      
      await this.saleorderService.getSaleOrderExprotReport(this.exportSaleorderReqeust).subscribe(async res => {
        this.exportSaleOrderBig = JSON.parse(JSON.stringify(res));
        let reportSaleOrder = [];
        await this.exportSaleOrderBig .forEach(data => {
          let tempExportSaleOrder: any = {}
          for (const keyExport in data) {
            for (const key in this.fieldExportSaleorder) {
              if (this.fieldExportSaleorder[key].pmdtbdtxtv1 == keyExport) {
                tempExportSaleOrder[this.fieldExportSaleorder[key].pmdtbdtxtv1] = data[keyExport];
              }
            }
          }
          reportSaleOrder.push(tempExportSaleOrder);
        });
        reportSaleOrder = reportSaleOrder.sort((a,b) => new Date(b.sale_Order_Date_Time).getTime().valueOf() - new Date(a.sale_Order_Date_Time).getTime().valueOf());
        await this.exportExcelService.exportAsExcelFile(reportSaleOrder, this.translateService.instant('ORDSOR100.M00146'), header, null, subTitle);
      }, err => {
        console.log(err);
      });
    } else {
      this.messageService.add({ key: 'alert', severity: 'error', detail: 'ORDSOR100.M00147' });
    }
    
  }

  resendApiDate(){
    let saleOrderIsSelect:any=[];
    let checkOrderNotConfirm:Boolean = false;
    
      this.row.forEach(element => {
        saleOrderIsSelect.push(this.dataTable[element]);
      });

    /** Check Saleorder Status Confirm AND Do not sent Pam api**/
    if(saleOrderIsSelect){
      saleOrderIsSelect.forEach(saleOrder=>{
        if(saleOrder.sales_order_status == '1' || !saleOrder.saleOrderToPamFlag){
          checkOrderNotConfirm = true;
        }else{
          let resentPamApi: any = {
            warehouseCode: saleOrder.warehouse_code,
            salesOrderNo: saleOrder.sales_order_no,
            language: this.languageSent
          }
          this.resentPamApiModel.push(resentPamApi);
        }
      });

      if(checkOrderNotConfirm){
        saleOrderIsSelect = [];
        this.messageService.add({ key: 'alert', severity: 'error', detail: 'ORDSOR100.M00130' });
      }else{
        this.saleorderService.resentPamApi(this.resentPamApiModel).subscribe(res=>{
          this.messageService.add({ key: 'save', severity: 'success', detail: this.translateService.instant('ORDSOR100.M00131') });
        });
      }
    }
    
  }

  onReject() {
    this.messageService.clear();
  }
}

